import { Box } from "rebass";
import React, { useRef, useState } from "react";

import {
  IMoodFilter,
  useComposerState,
  useComposerActions,
  MOOD_FILTER_OPTIONS
} from "contextApi/composerContext";
import FilterOptions from "./FilterOptions";
import Popper from "components/common/Popper";
import { DropdownContainer, MoodFilterWrapper } from "./styles";

const MoodFilter: React.FC<{}> = () => {
  const ref = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const moodFilter = useComposerState(state => state.moodFilter);
  const setMoodFilter = useComposerActions(actions => actions.setMoodFilter);

  const toggleShowOptions = () => setShowOptions(!showOptions);

  const onOptionSelected = (mood: IMoodFilter) => {
    if (moodFilter !== mood) {
      setMoodFilter(mood);
    }
    setShowOptions(false);
  };

  return (
    <MoodFilterWrapper>
      <Box>
        <div ref={ref}>
          <DropdownContainer onClick={toggleShowOptions}>
            <i className="icon-smiley" />
            <span>{!!moodFilter ? moodFilter.label : "Mood"}</span>
            <i className="icon-select" />
          </DropdownContainer>
          {/* @ts-ignore */}
          <Popper
            placement="bottom-start"
            referenceElement={ref.current}
            visible={showOptions}
            onClose={() => setShowOptions(false)}
            exceptions={[ref.current]}
            offset={[-6, 0]}
          >
            <FilterOptions
              selected={moodFilter}
              options={MOOD_FILTER_OPTIONS}
              onOptionSelected={onOptionSelected}
            />
          </Popper>
        </div>
      </Box>
    </MoodFilterWrapper>
  );
};

export default MoodFilter;

import React from "react";
import Stepper from "react-stepper-horizontal";
import { Flex } from "rebass/styled-components";
import { colors } from "../../theme";

const ProgressBar = ({ activeStep, steps }) => {
  return (
    <Flex minWidth={400} alignItems="center">
      <Stepper
        steps={Object.values(steps).map(s => ({ title: s }))}
        activeStep={Object.keys(steps).indexOf(activeStep)}
        size={10}
        circleFontSize={0}
        titleFontSize={14}
        circleTop={0}
        defaultColor={colors.grey02}
        activeColor={colors.primary}
        completeColor={colors.primary}
        defaultBarColor={colors.grey02}
        completeBarColor={colors.primary}
      />
    </Flex>
  );
};

export default ProgressBar;

import styled from "styled-components";

export const SeeMoreWrapper = styled.div`
  height: 36px;
  display: inline-flex;
`;

export const SeeMoreContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${props => props.theme.colors.text02};
`;

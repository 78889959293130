import { Field, reduxForm } from "redux-form";
import { Box } from "rebass/styled-components";
import { useToaster } from "@hellocontento/maillard";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import React, { useCallback, useEffect, useState } from "react";

import { required } from "utils/Validation";
import Button from "components/common/Button";
import { Form } from "components/common/form/styles";
import { timezones, countries } from "constants/index";
import { PlainSelect } from "components/common/form/Select";
import { fetchIndustryCategories } from "services/onboarding";

const OnboardingThirdForm = ({
  state,
  handleSubmit,
  submitting,
  submitSucceeded
}) => {
  const addToast = useToaster();
  const [industries, setIndustries] = useState([]);

  const getIndustries = useCallback(async () => {
    try {
      const industryCategories = await fetchIndustryCategories();

      setIndustries(
        industryCategories.map(ic => ({
          key: ic,
          value: ic
        }))
      );
    } catch (error) {
      addToast(error.message, "error");
    }
  }, []);

  useEffect(() => {
    getIndustries();
  }, [getIndustries]);

  return (
    <Form
      isTransitioned={true}
      state={state}
      onSubmit={handleSubmit}
      id="willow_onboarding_step_2"
      name="willow_onboarding_step_2"
    >
      <div>
        <Field
          component={PlainSelect}
          helperText="This will be used to suggest relevant industry information"
          id="industry"
          key={"industry"}
          label="Company Industry"
          name="industry"
          options={industries}
          placeholder="e.g., Marketing"
          validate={[required]}
        />
        <Field
          component={PlainSelect}
          helperText="This will be used to set the timezone of your calendar"
          id="country"
          key={"country"}
          label="Country"
          name="billingCountry"
          options={countries}
          placeholder="e.g., Belgium"
          validate={[required]}
        />
        <Field
          component={PlainSelect}
          id="timezone"
          helperText="Please specify where exactly you are located"
          key={"timezone"}
          label="Timezone"
          name="timezone"
          options={timezones}
          validate={[required]}
        />
        <Box pt={8} pb={16}>
          <Button
            size="lg"
            disabled={submitting || submitSucceeded}
            width="100%"
          >
            Next <RightArrowAlt size={20} />
          </Button>
        </Box>
      </div>
    </Form>
  );
};

export default reduxForm({
  // a unique name for the form
  form: "onboardingThird",
  enableReinitialize: true
})(OnboardingThirdForm);

import React, { useCallback, useEffect, useState } from "react";
import { useToaster } from "@hellocontento/maillard";
import { connect } from "react-redux";
import { Box } from "rebass";
import InfluencersPage from "./influencers/InfluencersPage";
import { getFeedsByType } from "services/news";
import Shelve from "./shelve";
import Loader from "components/common/loading/Loader";

const ContentInfluencers = ({
  account,
  excludedTld,
  sourceType = "twitter"
}) => {
  const addToast = useToaster();

  const [isLoading, setIsLoading] = useState(false);

  const [allSources, setAllSources] = useState([]);

  const fetchSourceFeed = useCallback(async () => {
    try {
      setIsLoading(true);
      const results = await getFeedsByType("sources-and-domains", { limit: 6 });
      setAllSources(results);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      addToast(err.message, "error");
    }
  }, [addToast]);

  useEffect(() => {
    fetchSourceFeed();
  }, [fetchSourceFeed]);

  if (isLoading) {
    return <Loader location="center" size={64} />;
  }

  return (
    <>
      <InfluencersPage
        key="sectionInfluencers"
        contents={allSources}
        accountId={account.id}
        hasTopBorder={false}
        showFooter={false}
      />
      <Box marginTop={24}>
        {allSources.map(({ section, contents, sectionType }) => {
          if (contents.length < 1) return null;

          return (
            <Shelve
              key={section.id}
              section={section}
              type={sectionType}
              onRefresh={fetchSourceFeed}
              accountId={account.id}
              contents={contents.slice(0, 6)}
            />
          );
        })}
      </Box>
    </>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser,
    excludedTld: state.regionFilter.excludedTld
  };
};

export default connect(mapStateToProps, {})(ContentInfluencers);

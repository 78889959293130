import React, { useState, useEffect, useCallback } from "react";
import { useContentoApi } from "../../utils/useContentoApi";
import { useToaster } from "@hellocontento/maillard";
import ContentList from "./ContentList";
import { connect } from "react-redux";
import { Headline2 } from "../common/styles";
import EmptyState from "../common/EmptyState";
import { Box } from "rebass";
import BookmarkEmpty from "assets/images/contents/bookmark-empty.png";

import { EmptyImageWrapper } from "./styles";

const ContentHandpicked = ({ account }) => {
  const [savedArticles, setSavedArticles] = useState(null);
  const addToast = useToaster();

  const [fetchSavedArticles, cancelFetchSavedArticles] = useContentoApi(
    `accounts/${account.id}/contents/saved`
  );

  const refreshSavedArticles = useCallback(() => {
    fetchSavedArticles()
      .then(setSavedArticles)
      .catch(err => {
        if (err.name !== "RequestCancelled") {
          addToast(
            "There was an error fetching your suggested content. Please try again later or contact support.",
            "error"
          );
        }
      });
  }, [fetchSavedArticles, addToast]);

  useEffect(() => {
    refreshSavedArticles();
    return cancelFetchSavedArticles;
  }, [cancelFetchSavedArticles, refreshSavedArticles]);

  if (!savedArticles || !savedArticles.length) {
    return (
      <Box flex={"1"}>
        <EmptyState
          loading={!savedArticles}
          header={"No saved articles yet"}
          description={
            "As soon as you start saving articles for later they will appear here."
          }
          image={<EmptyImageWrapper src={BookmarkEmpty} />}
          cta={{
            text: "Learn More",
            variant: "secondary",
            onClick: () => {}
          }}
        />
      </Box>
    );
  }

  function handleDimissed(contentId, domain = null) {
    if (domain) {
      setSavedArticles(savedArticles.filter(item => item.domain !== domain));
    } else {
      setSavedArticles(savedArticles.filter(item => item.id !== contentId));
    }
  }

  return (
    <Box flex={"1"}>
      <Headline2>Saved for later</Headline2>
      <ContentList
        items={savedArticles}
        currentTab={"saved-for-later"}
        onScheduled={refreshSavedArticles}
        onDismiss={handleDimissed}
      />
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {})(ContentHandpicked);

import React from "react";
import { format } from "date-fns";
import { BasketLine, BasketLabel, BasketValue, BillingNotice } from "./styles";
import Price from "../common/Price";
import { FormBox } from "../common/form/styles";
import { Flex, Box } from "rebass/styled-components";
import Button from "../common/Button";

const cycleLabels = {
  YEARLY: "yearly",
  MONTHLY: "monthly"
};

const cycleLabelsSingular = {
  YEARLY: "Year",
  MONTHLY: "Month"
};

export default function CheckoutBasket({
  isLoading,
  estimate,
  cycle,
  onCheckout
}) {
  if (!estimate) {
    return null;
  }

  console.log(cycle);
  console.log(estimate);
  return (
    <FormBox maxWidth="380px">
      <BillingNotice>
        Your payment method will be charged {cycleLabels[cycle]} starting{" "}
        <strong>
          {format(
            estimate?.invoice_estimate?.date
              ? new Date(estimate.invoice_estimate.date * 1000)
              : new Date(),
            "MMMM dd, yyyy"
          )}
        </strong>
        .
      </BillingNotice>
      <Flex my={32} flexDirection="column">
        {estimate.invoice_estimate &&
          estimate.invoice_estimate.line_items.map(lineItem => (
            <BasketLine key={lineItem.id}>
              <BasketLabel>{lineItem.description}</BasketLabel>
              <BasketValue>
                <Price
                  price={lineItem.amount / 100}
                  currency={estimate.invoice_estimate.currency_code}
                />
              </BasketValue>
            </BasketLine>
          ))}

        {estimate.invoice_estimate &&
          estimate.invoice_estimate.discounts &&
          estimate.invoice_estimate.discounts.map(discount => (
            <BasketLine key={discount.object}>
              <BasketLabel>Discount ({discount.description})</BasketLabel>
              <BasketValue>
                <Price
                  price={-discount.amount / 100}
                  currency={estimate.invoice_estimate.currency_code}
                />
              </BasketValue>
            </BasketLine>
          ))}

        {estimate.invoice_estimate &&
          estimate.invoice_estimate.taxes &&
          estimate.invoice_estimate.taxes.map(taxItem => (
            <BasketLine key={taxItem.name}>
              <BasketLabel>{taxItem.description}</BasketLabel>
              <BasketValue>
                {" "}
                <Price
                  price={taxItem.amount / 100}
                  currency={estimate.invoice_estimate.currency_code}
                />
              </BasketValue>
            </BasketLine>
          ))}
        <Box my={16}>
          <hr />
        </Box>
        <BasketLine>
          <BasketLabel>Today's charge</BasketLabel>
          <BasketValue large>
            <Price
              price={
                estimate.invoice_estimate
                  ? estimate.invoice_estimate.amount_due / 100
                  : 0
              }
              currency={
                estimate.invoice_estimate
                  ? estimate.invoice_estimate.currency_code
                  : "EUR"
              }
            />
            <small>Total / {cycleLabelsSingular[cycle]}</small>
          </BasketValue>
        </BasketLine>
      </Flex>
      <Flex mb={16} flexDirection="column" width="100%">
        <Button
          variant="primary"
          size="lg"
          disabled={isLoading}
          onClick={() => onCheckout()}
        >
          Continue to payment
        </Button>
      </Flex>
    </FormBox>
  );
}

import React from "react";

import { DashboardGrid } from "./styles";
import TaskWidget from "./widgets/TaskWidget";
import AnalyticsWidgets from "./analyticsWidgets";

function DashboardWidgets() {
  return (
    <DashboardGrid>
      <TaskWidget />
      <AnalyticsWidgets />
    </DashboardGrid>
  );
}

export default DashboardWidgets;

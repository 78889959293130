import React from "react";
import { useSelector } from "react-redux";

import { POST_STATUS } from "constants/post";
import PublishedEntry from "./PublishedEntry";
import { CardWrapper, Author } from "./styles";
import CalendarEntry from "components/schedule/calendar/CalendarEntry";

export interface IFeedEntry {
  entry: any;
}

const FeedEntry: React.FC<IFeedEntry> = ({ entry }) => {
  const { type } = entry;
  const account = useSelector<any, any>(state => state.account.data);

  return (
    <>
      {type === "POST" && entry.post?.status === POST_STATUS.SENT ? (
        <CardWrapper>
          <PublishedEntry entry={entry} />
          {entry?.post?.scheduledByName && (
            <Author>
              Scheduled by
              <span className="author_name">{entry.post.scheduledByName}</span>
            </Author>
          )}
        </CardWrapper>
      ) : (
        <CardWrapper>
          <CalendarEntry
            accountId={account.id}
            key={entry.id}
            entry={entry}
            height="auto"
            autoHeight
          />
          {entry.type === "DRAFT_POST" && entry?.post?.scheduledByName && (
            <Author>
              Created by
              <span className="author_name">{entry.post.scheduledByName}</span>
            </Author>
          )}
        </CardWrapper>
      )}
    </>
  );
};

export default FeedEntry;

import React from "react";
import styled from "styled-components";

import { Headline5 } from "components/common/styles";
import poster from "assets/images/willow-extension-popup.png";

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-bottom: 32px;

  * {
    box-sizing: border-box;
  }

  ${Headline5} {
    font-weight: 400;
    line-height: 125%;
  }
`;

const Poster = styled.div`
  width: 700px;
  height: 527px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: url("${poster}") no-repeat center center;
  background-size: auto 100%;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  span {
    font-size: 16px;
    font-weight: 400;
    line-height: 125%;
    text-align: left;
    color: ${props => props.theme.colors.grey06};
  }
`;

const ExtensionIntro: React.FC<{}> = () => {
  return (
    <Wrapper>
      <Poster />
      <Content>
        <Headline5>Introducing Willow+</Headline5>
        <span>
          Now in Beta mode, you can track additional post data as well as save
          posts for inspiration in Willow.{" "}
        </span>
      </Content>
    </Wrapper>
  );
};

export default ExtensionIntro;

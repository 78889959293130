import React, { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import { Box, Flex } from "rebass";
import { Search } from "styled-icons/boxicons-regular";
import Button from "../../common/Button";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "utils/useQueryParams";

import qs from "query-string";

const SearchContainer = styled(Flex)`
  flex: 1;
  margin-left: 12px;
  align-items: center;
  background-color: ${props => props.theme.colors.grey01};
  border-radius: 32px;
  transition: 0.15s ease-out;

  border: 1px solid rgba(96, 116, 128, 0.08);
  padding: 6px 0px;
`;

const SearchInput = styled.input`
  appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
  display: block;
  flex: 1;
  font-family: ${props => props.theme.fonts.body};
  font-size: 16px;
  font-weight: 600;
  outline: none;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${props => props.theme.colors.text03};
    font-weight: 500;
  }
`;

const SearchOptions = styled(Box)`
  display: grid;
  grid-template-columns: repeat(5, max-content);
  column-gap: 12px;
  margin-left: 12px;
  /* padding: 10px 20px; */
  padding: 0px 8px;
  border-left: 1px solid rgba(126, 126, 126, 0.16);
`;

export default function ExploreSearch() {
  const history = useHistory();
  const queryParams = useQueryParams();

  const [searchType, setSearchType] = useState(queryParams.type || "all");
  const [searchText, setSearchText] = useState(queryParams.text || "");

  const [isFocused, setFocused] = useState(false);

  useEffect(() => {
    setSearchType(queryParams.type);
    setSearchText(queryParams.text);
  }, [queryParams.type, queryParams.text]);

  const handleContextClick = contextValue => {
    setSearchType(contextValue);

    const query = qs.stringify({
      type: contextValue,
      ...(!!searchText ? { text: searchText } : {})
    });

    history.push({
      search: `?${query}`
    });
  };

  const handleSearchTextChange = value => {
    setSearchText(value);
    const query = qs.stringify({
      type: searchType,
      ...(!!value ? { text: value } : {})
    });

    history.push({
      search: `?${query}`
    });
  };

  return (
    <SearchContainer isFocused={isFocused}>
      <Flex justifyContent={"center"} alignItems={"center"} pl={3} pr={2}>
        <Search size={20} />
      </Flex>
      <Flex flex={"1"}>
        <SearchInput
          type={"text"}
          name={"exploreSearchInput"}
          value={searchText || ""}
          onChange={e => {
            handleSearchTextChange(e.target.value);
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          placeholder={"Topic, website or RSS link"}
        />
      </Flex>
      <SearchOptions>
        <Button
          variant={searchType === "all" ? "black" : "secondary"}
          size={"xs"}
          onClick={() => handleContextClick("all")}
        >
          All
        </Button>
        <Button
          variant={searchType === "topics" ? "black" : "secondary"}
          size={"xs"}
          onClick={() => handleContextClick("topics")}
        >
          Topics
        </Button>
        <Button
          variant={searchType === "domains" ? "black" : "secondary"}
          size={"xs"}
          onClick={() => handleContextClick("domains")}
        >
          Websites
        </Button>
        <Button
          variant={searchType === "twitter-sources" ? "black" : "secondary"}
          size={"xs"}
          onClick={() => handleContextClick("twitter-sources")}
        >
          X
        </Button>
        <Button
          variant={searchType === "rss-sources" ? "black" : "secondary"}
          size={"xs"}
          onClick={() => handleContextClick("rss-sources")}
        >
          RSS
        </Button>
      </SearchOptions>
    </SearchContainer>
  );
}

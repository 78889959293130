// @ts-nocheck
import _ from "lodash";
import { Flex } from "rebass";
import moment from "moment-timezone";
import { useLocation } from "react-router-dom";
import { X } from "@styled-icons/boxicons-regular";
import { useToaster } from "@hellocontento/maillard";
import { useSelector, useDispatch } from "react-redux";
import { IAccount } from "@hellocontento/contento-common";
import React, { useState, useEffect, useCallback } from "react";

import {
  DraftHeader,
  SubHeadline,
  DraftsWrapper,
  DraftBodyWrapper,
  DraftFooterWrapper,
  DraftHeaderWrapper
} from "./styles";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import Button from "components/common/Button";
import IconButton from "../common/IconButton";
import { Headline5 } from "components/common/styles";
import Loader from "components/common/loading/Loader";
import EmptyCard from "components/settings/EmptyCard";
import { useContentoApi } from "utils/useContentoApi";
import { saveDrafts } from "state/actions/DraftActions";
import { saveActivities } from "state/actions/ActivityActions";
import CalendarEntry from "components/schedule/calendar/CalendarEntry";

const momentTZ = (date, accTimezone) => moment.tz(date, accTimezone);

const formatDate = date => {
  const diff = moment().add(1, "d").date() - date.date();
  const prefix =
    diff === 1 ? "Today" : diff === 0 ? "Tomorrow" : date.format("MMM M");

  return `${prefix} at ${date.format("HH:mm")}`;
};

const organizeDrafts = (drafts, accTimezone) => {
  const entries: any = [];

  drafts.forEach((post: any) => {
    let date =
      !post.postedAt && !post.scheduledAt
        ? null
        : post.status === "SENT"
          ? momentTZ(post.postedAt, accTimezone)
          : momentTZ(post.scheduledAt, accTimezone);
    let picture;
    if (post.attachment) {
      if (post.attachment.type === "photo") {
        picture = _.isArray(post.attachment.url)
          ? post.attachment.url[0]
          : post.attachment.url;
      } else if (post.attachment.type === "article") {
        picture = post.attachment.image;
      }
    }

    entries.push({
      id: `post-${post.id}`,
      dateTime: date,
      time: !!date ? formatDate(date) : null,
      channels: post.isDraft ? post.channels : [post.channel],
      text: post.caption[Object.keys(post.caption)[0]],
      picture: picture,
      title: post.attachment ? post.attachment.title : undefined,
      post: post
    });
  });

  return entries;
};

interface IDraftPanel {
  draftCount: number;
  onClose: () => void;
  fetchCount: () => void;
}

const DraftPanel = React.memo(
  ({ onClose, fetchCount, draftCount = 0 }: IDraftPanel): JSX.Element => {
    const addToast = useToaster();
    const dispatch = useDispatch();
    const location = useLocation();
    const account = useSelector<any, IAccount>(state => state.account.data);
    const activityParams = useSelector(state => state.activity.params);
    const onPostedEvent = useComposerState(state => state.events.onPostedEvent);
    const onReloadEvent = useComposerState(state => state.events.onReloadEvent);
    const onDeletedEvent = useComposerState(
      state => state.events.onDeletedEvent
    );
    const openComposer = useComposerActions(actions => actions.openComposer);
    const [entries, setEntries] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    const [fetchDrafts] = useContentoApi(`/accounts/${account.id}/draft-posts`);
    const [removeDraft] = useContentoApi(
      `accounts/${account.id}/draft-posts`,
      "delete"
    );
    const [fetchActivities] = useContentoApi(
      `/accounts/${account.id}/activities/calendar`
    );

    const isSchedulePage = location.pathname.includes("schedule");

    const reloadActivities = useCallback(() => {
      fetchActivities({ params: activityParams }).then((activities: any) => {
        dispatch(
          saveActivities({
            data: activities,
            params: activityParams
          })
        );
      });
    }, [dispatch, activityParams, fetchActivities]);

    const refreshDrafts = useCallback(() => {
      setIsFetching(true);
      fetchDrafts()
        .then((response: any) => {
          const drafts = response.map((draft: any) => {
            return {
              ...draft,
              isDraft: true
            };
          });

          setIsFetching(false);
          dispatch(saveDrafts(drafts));
          setEntries(organizeDrafts(drafts, account.timezone));
        })
        .catch((e: Error) => {
          setIsFetching(false);
          addToast("Couldn't get draft posts at the moment", "error");
        });
    }, [fetchDrafts, addToast, dispatch, account.timezone]);

    useEffect(() => {
      refreshDrafts();
    }, [refreshDrafts]);

    const handleDeleteDraft = useCallback(
      (entry: any) => {
        // delete draft by id
        removeDraft({ url: entry.id })
          .then((response: any) => {
            refreshDrafts();
            fetchCount();
            if (isSchedulePage) {
              reloadActivities();
            }
            addToast("Draft deleted successfully", "success");
          })
          .catch((e: Error) => {
            addToast("Unable to deleted draft", "error");
          });
      },
      [
        addToast,
        fetchCount,
        removeDraft,
        refreshDrafts,
        isSchedulePage,
        reloadActivities
      ]
    );

    const handleWriteDraft = useCallback(() => {
      openComposer(null, {
        isDraft: true
      });
    }, [openComposer]);

    useEffect(
      () => onPostedEvent.listen(refreshDrafts),
      [onPostedEvent, refreshDrafts]
    );

    useEffect(
      () => onReloadEvent.listen(refreshDrafts),
      [onReloadEvent, refreshDrafts]
    );

    useEffect(
      () => onDeletedEvent.listen(refreshDrafts),
      [onDeletedEvent, refreshDrafts]
    );

    return (
      <DraftsWrapper>
        <DraftHeaderWrapper>
          <DraftHeader>
            <Headline5>
              Draft
              <SubHeadline>{draftCount}</SubHeadline>
            </Headline5>
            <IconButton
              variant={"secondary"}
              size={"sm"}
              onClick={onClose}
              icon={X}
            />
          </DraftHeader>
        </DraftHeaderWrapper>
        {isFetching ? (
          <Loader location={"center"} />
        ) : entries.length === 0 ? (
          <EmptyCard
            title="No drafts created yet"
            message="This is the place where you will find all your ideas and drafts."
            support="Write a draft"
            action={handleWriteDraft}
            noBgStyle
            center
          />
        ) : (
          <DraftBodyWrapper>
            {entries.map((entry: any) => {
              return (
                <CalendarEntry
                  key={entry.id}
                  onDeleted={() => handleDeleteDraft(entry.post)}
                  entry={entry}
                  height={"278px"}
                  maxWidth={"298px"}
                  contextWithPortal={true}
                  parent={"draft-panel"}
                  attachmentHeight={140}
                />
              );
            })}
          </DraftBodyWrapper>
        )}
        <DraftFooterWrapper>
          <Button
            variant="primary"
            width={"100%"}
            size={"m"}
            onClick={handleWriteDraft}
          >
            <Flex justifyContent="center" alignItems="center">
              Write Draft
            </Flex>
          </Button>
        </DraftFooterWrapper>
      </DraftsWrapper>
    );
  }
);

export default DraftPanel;

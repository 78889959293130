import React from "react";
import { useHistory } from "react-router";
import Carousel from "../common/Carousel";
import {
  TopicCard,
  TopicCardName,
  TopicCardMeta,
  TopicCardTag
} from "./styles";
import { useSelector } from "react-redux";
import { topicLanguages } from "../../constants/content";

function TopicCarousel({ accountTopics }) {
  const history = useHistory();
  const account = useSelector(state => state.account.data);

  return (
    <Carousel items={accountTopics} defaultItemHeight={220}>
      {accountTopics.map(accountTopic => (
        <TopicCard
          key={`${accountTopic.id}-${accountTopic.language}`}
          bgImg={accountTopic.image}
          onClick={() =>
            history.push(
              `/accounts/${account.id}/content/topics/${accountTopic.id}/${accountTopic.language}`
            )
          }
        >
          <TopicCardName>{accountTopic.title}</TopicCardName>
          <TopicCardMeta>
            <TopicCardTag bold>
              {topicLanguages[accountTopic.language].label}
            </TopicCardTag>
            {accountTopic.parent && (
              <TopicCardTag>{accountTopic.parent.title}</TopicCardTag>
            )}
          </TopicCardMeta>
        </TopicCard>
      ))}
    </Carousel>
  );
}

export default TopicCarousel;

export const ADD_ACTIVITIES = "@@activities/ADD_ACTIVITIES";
export const UPDATE_ACTIVITY = "@@activities/UPDATE_ACTIVITY";
export const CREATE_PHANTOM_ACTIVITY = "@@activities/CREATE_PHANTOM_ACTIVITY";
export const REMOVE_PHANTOM_ACTIVITY = "@@activities/REMOVE_PHANTOM_ACTIVITY";

export const ADD_PREVIEWS = "@@activities/ADD_PREVIEWS";
export const REMOVE_PREVIEWS = "@@activities/REMOVE_PREVIEWS";

export const saveActivities = data => {
  return {
    type: ADD_ACTIVITIES,
    data
  };
};

export const updateActivity = data => {
  return {
    type: UPDATE_ACTIVITY,
    data
  };
};

export const createPhantomActivity = data => {
  return {
    type: CREATE_PHANTOM_ACTIVITY,
    data
  };
};

export const removePhantomActivity = () => {
  return {
    type: REMOVE_PHANTOM_ACTIVITY
  };
};

export const addPreviews = (data, taskId, taskGroupId) => {
  return {
    type: ADD_PREVIEWS,
    data,
    taskId,
    taskGroupId
  };
};

export const removePreviews = () => {
  return {
    type: REMOVE_PREVIEWS
  };
};

import styled from "styled-components";
import { Flex, Text } from "rebass/styled-components";

const illustrationPadding =
  "<svg width='1' height='203' xmlns='http://www.w3.org/2000/svg'><defs/><path d='M1 21.5H0m1 22H0' stroke='black' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='square'/></svg>";

export const ErrorLayout = styled(Flex)`
  align-items: center;
  flex-direction: column;
  height: 100%;
  margin: 0 24px;
  min-height: 100vh;
`;

export const ErrorHeader = styled(Flex)`
  height: 48px;
  justify-content: center;
  margin: calc(min(72px, 8vh)) 0;
  width: 100%;
  & > img {
    height: 100%;
  }
`;

export const ErrorTitle = styled(Text)`
  color: ${props => props.theme.colors.text01};
  font-size: 45px;
  letter-spacing: -0.75px;
  line-height: 1.3;
  font-weight: bold;
  text-align: center;
`;

export const ErrorDescription = styled(Text)`
  color: ${props => props.theme.colors.text02};
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: -0.2px;
  margin: 16px 0;
  text-align: center;
`;

export const ErrorFooter = styled(Text)`
  font-size: 16px;
  color: ${props => props.theme.colors.text02};
  line-height: 24px;
  text-align: center;
  max-width: 500px;
  margin: auto 0 40px;
  & a {
    font-weight: 600;
  }
`;

export const ErrorFigure = styled.figure`
  display: grid;
  grid-template-columns: 1fr minmax(325px, max-content) 1fr;
  margin: 10vh 0;
  user-select: none;
  width: 100vw;
  z-index: -1;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

export const FigurePadding = styled.div`
  background-image: url(${`"data:image/svg+xml;utf8,${illustrationPadding}"`});
  background-size: auto calc(min(calc(100% - 2px), 203px));
  transform: translateY(-0.5px);

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const FigureContent = styled.div`
  & img {
    object-fit: contain;
    max-width: calc(100vw - 32px);
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    & img {
      max-width: none;
      width: 100%;
    }
  }
`;

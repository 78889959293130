import styled from "styled-components";
import { useToaster } from "@hellocontento/maillard";
import React, { useMemo, useState, useCallback } from "react";

import { format } from "utils/date";
import Button from "components/common/Button";
import Loader from "components/common/loading/Loader";
import { Body7, Headline6 } from "components/common/styles";

const IdeaCardContainer = styled.div`
  width: 243px;
  height: 161px;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 223px;
  height: 141px;
  padding: 20px;
  overflow: hidden;

  border-radius: 20px;
  border: 1px solid ${props => props.theme.colors.grey07};
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.shadow04};
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const Title = styled(Headline6)`
  font-weight: 700;
  color: ${props => props.theme.colors.darkBlack};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 182px;

  > i {
    font-size: 14px;
    margin-right: 5px;
    vertical-align: middle;
    color: ${props => props.theme.colors.yellow};
  }
`;

const DateRange = styled(Body7)`
  display: flex;
  padding: 9px 0px;
  line-height: 125%;
  color: ${props => props.theme.colors.grey06};

  > i {
    font-size: 15px;
    margin-right: 5px;
    color: inherit;
    vertical-align: middle;
  }
`;

const Actions = styled.div`
  display: flex;
  margin-bottom: 19px;
  justify-content: center;
  width: 100%;
`;

interface ITopicalCalendarMiniCard {
  id: string;
  title: string;
  startDate: Date;
  endDate: Date;
  status: string;
  featured: boolean;
  isVoted: boolean;
  isSubscribed: boolean;
  handleButtonAction: (
    topicalCalendarId: string,
    calendarname: string,
    status: string,
    upvote: boolean,
    location: string
  ) => void;
}

const TopicalCalendarMiniCard: React.FC<ITopicalCalendarMiniCard> = ({
  id,
  title,
  startDate,
  endDate,
  status,
  featured,
  isVoted,
  isSubscribed,
  handleButtonAction
}) => {
  const addToast = useToaster();
  const [isActionRunning, setIsActionRunning] = useState<boolean>(false);

  const buttonStatus = useMemo(() => {
    if (status === "live" && !isSubscribed) {
      return "unsubscribed";
    } else if (status === "live" && isSubscribed) {
      return "subscribed";
    } else if (status === "voting" && isVoted) {
      return "voted";
    } else {
      return "unvoted";
    }
  }, [status, isVoted, isSubscribed]);

  const buttonState = useMemo(() => {
    if (buttonStatus === "unsubscribed") {
      return {
        variant: "primary",
        text: "Subscribe"
      };
    } else if (buttonStatus === "subscribed") {
      return {
        variant: "danger",
        text: "Unsubscribe"
      };
    } else if (buttonStatus === "voted") {
      return {
        variant: "success",
        text: "Vote Registered!"
      };
    } else {
      return {
        variant: "secondary",
        text: "Register Vote"
      };
    }
  }, [buttonStatus]);

  const handleAction = useCallback(async () => {
    if (buttonStatus !== "voted") {
      try {
        setIsActionRunning(true);

        await handleButtonAction(
          id,
          title,
          status,
          status === "voting" ? !isVoted : !isSubscribed,
          "onboarding"
        );
      } catch (error) {
        addToast((error as Error).message, "error");
      } finally {
        setIsActionRunning(false);
      }
    }
  }, [
    id,
    title,
    status,
    isVoted,
    addToast,
    buttonStatus,
    isSubscribed,
    handleButtonAction
  ]);

  return (
    <IdeaCardContainer>
      <Wrapper>
        <Details>
          <Title>
            {featured && <i className="icon-sparkle" />}
            {title}
          </Title>
          <DateRange>
            <i className="icon-calendar" />{" "}
            {status === "voting"
              ? `Coming Soon!`
              : `
            ${format(startDate, "topical_calendar")} - ${format(
              endDate,
              "topical_calendar"
            )}
            `}
          </DateRange>
        </Details>
        <Actions>
          {/* @ts-ignore */}
          <Button
            size="m"
            textSize="xl"
            variant={buttonState.variant}
            onClick={handleAction}
            disabled={isActionRunning}
          >
            {isActionRunning && (
              <span
                style={{
                  position: isActionRunning ? "absolute" : "unset",
                  left: "calc(50% - 12px)"
                }}
              >
                <Loader size={24} />
              </span>
            )}
            <span
              style={{
                visibility: isActionRunning ? "hidden" : "visible"
              }}
            >
              {buttonState.text}
            </span>
          </Button>
        </Actions>
      </Wrapper>
    </IdeaCardContainer>
  );
};

export default TopicalCalendarMiniCard;

import React from "react";
import { Box } from "rebass/styled-components";
import { Field, reduxForm } from "redux-form";
import { required, validWebsite } from "../../utils/Validation";
import TextInput from "../common/form/TextInput";
import { Form } from "../common/form/styles";
import Button from "../common/Button";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";

const OnboardingFirstForm = ({ state, handleSubmit, submitting }) => {
  return (
    <Form
      id="willow_onboarding_step_1"
      name="willow_onboarding_step_1"
      state={state}
      isTransitioned={true}
      onSubmit={handleSubmit}
    >
      <div>
        <Field
          autoFocus={true}
          component={TextInput}
          id="company"
          label="Company name"
          name="companyName"
          placeholder="Acme Industries"
          validate={[required]}
        />
        <Field
          component={TextInput}
          id="website"
          key={"companyWebsite"}
          label="Website"
          name="companyWebsite"
          placeholder="www.acme.com"
          validate={[required, validWebsite]}
        />
        <Field
          component={TextInput}
          id="numberofemployees"
          key={"numberOfEmployees"}
          label="Number of employees"
          name="numberOfEmployees"
          type="number"
          validate={[required]}
        />
        <Box pt={8} pb={16}>
          <Button size="lg" disabled={submitting} width="100%">
            Next <RightArrowAlt size={20} />
          </Button>
        </Box>
      </div>
    </Form>
  );
};

export default reduxForm({
  // a unique name for the form
  form: "onboardingFirst",
  enableReinitialize: true
})(OnboardingFirstForm);

import { callApi } from "utils/ContentoApi";

/**
 * GET task details by id
 *
 * @param {string} accountId
 * @param {string} id
 * @returns
 */
export const fetchTaskById = async (accountId, id) => {
  return await callApi({
    method: "GET",
    url: `/accounts/${accountId}/tasks/${id}`
  });
};

/**
 * GET task group details by id
 *
 * @param {string} accountId
 * @param {string} id
 * @returns
 */
export const fetchTaskGroupById = async (accountId, id) => {
  return await callApi({
    method: "GET",
    url: `/accounts/${accountId}/task-groups/${id}`
  });
};

/**
 * POST Create a new task instance
 *
 * @param {string} accountId
 * @param {object} data
 * @returns {Promise}
 */
export const createTaskInstance = async (accountId, data) => {
  return await callApi({
    method: "POST",
    url: `/accounts/${accountId}/tasks`,
    data: data
  });
};

/**
 * POST Create a new task group
 *
 * @param {string} accountId
 * @param {object} data
 * @returns {Promise}
 */
export const createTaskGroup = async (accountId, data) => {
  return await callApi({
    method: "POST",
    url: `/accounts/${accountId}/task-groups`,
    data: data
  });
};

/**
 * PUT Update the task instance by id
 *
 * @param {string} accountId
 * @param {string} id
 * @param {object} data
 * @returns {Promise}
 */
export const updateTaskInstance = async (accountId, id, update) => {
  return await callApi({
    method: "PUT",
    url: `/accounts/${accountId}/tasks/${id}`,
    data: update
  });
};

/**
 * PUT Update the task group by task group id
 *
 * @param {string} accountId
 * @param {string} id
 * @param {object} data
 * @returns {Promise}
 */
export const updateTaskGroup = async (accountId, id, update) => {
  return await callApi({
    method: "PUT",
    url: `/accounts/${accountId}/task-groups/${id}`,
    data: update
  });
};

/**
 * GET best posting times with respect to day and channels
 *
 * @param {string} accountId
 * @param {string} qry
 * @returns {Promise}
 */
export const fetchBestTimes = async (accountId, qry) => {
  return await callApi({
    method: "get",
    url: `/accounts/${accountId}/best-posting-times?${qry}`
  });
};

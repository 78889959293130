import React from "react";
import IconButton from "components/common/IconButton";

import {
  AttachmentContainer,
  AttachmentLink,
  AttachmentDetail,
  AttachmentDomain,
  AttachmentTitle,
  AttachmentDomainWrapper,
  AttachmentDomainIcon,
  PostImage,
  CloseIcon,
  AttachmentDescription,
  AttachmentTextWrapper
} from "./styles";

import EmptyArticleImage from "assets/images/article/empty-article-image.png";
import EmptySourceImage from "assets/images/article/empty-source.png";

const ArticleAttachment = ({ article, onRemove }) => {
  if (!article || (!article.title && !article.image)) {
    return null;
  }

  const { title, favicon, image, url, domain, description } = article;

  return (
    <AttachmentContainer $background={false} $shadow={true}>
      <AttachmentLink href={url} target={"_blank"}>
        <PostImage
          src={image || EmptyArticleImage}
          alt={title}
          className="article"
          onError={e => {
            e.target.onerror = null;
            e.target.src = EmptyArticleImage;
          }}
        />
        <AttachmentDetail>
          <AttachmentDomainWrapper>
            <AttachmentDomainIcon
              src={favicon || EmptySourceImage}
              alt={domain}
              className="article"
              onError={e => {
                e.target.onerror = null;
                e.target.src = EmptySourceImage;
              }}
            />
            <AttachmentDomain>{domain}</AttachmentDomain>
          </AttachmentDomainWrapper>
          <AttachmentTextWrapper>
            <AttachmentTitle className="underline">{title}</AttachmentTitle>
            {description && (
              <AttachmentDescription>{`${description.slice(
                0,
                70
              )}...`}</AttachmentDescription>
            )}
          </AttachmentTextWrapper>
        </AttachmentDetail>
      </AttachmentLink>
      <CloseIcon>
        <IconButton
          icon={"icon-cancel"}
          variant={"secondaryQuiet"}
          size={28}
          iconSize={18}
          onClick={e => {
            e.stopPropagation();
            onRemove();
          }}
        />
      </CloseIcon>
    </AttachmentContainer>
  );
};

export default ArticleAttachment;

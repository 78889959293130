import React, { useCallback, useRef, useState } from "react";
import { useToaster } from "@hellocontento/maillard";
import { MoreHorizontal } from "@styled-icons/fluentui-system-regular/MoreHorizontal";
import { Box, Flex } from "rebass/styled-components";
import { theme } from "theme";
import Popper from "components/common/Popper";
import { MenuContainer } from "components/navigation/Menu";
import SimpleBar from "simplebar-react";
import { useComposerActions } from "contextApi/composerContext";
import { useDispatch, useSelector } from "react-redux";
import { callApi } from "utils/ContentoApi";
import { type IUserIdea } from "state/reducers/IdeasReducer";
import { removeWhatsappAssetById } from "state/actions/IdeasActions";

const MenuItem = ({
  text,
  onClick,
  textColor = theme.colors.black,
  hoverBg,
  hoverTextColor
}: {
  text: string;
  textColor?: string;
  onClick: () => void;
  hoverBg?: string;
  hoverTextColor?: string;
}) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        borderBottom: "1px solid #EBEBEB",
        padding: "10px 15px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: hoverBg,
          color: hoverTextColor
        },
        ":last-child": {
          borderBottom: "none"
        }
      }}
      color={textColor}
    >
      <span>{text}</span>
    </Box>
  );
};

const MoreOptionsButton = ({ idea }: { idea: IUserIdea }) => {
  const ref = useRef(null);
  const addToast = useToaster();
  const accountId = useSelector((state: any) => state.account.data.id);
  const userId = useSelector((state: any) => state.auth.currentUser.id);
  const dispatch = useDispatch();

  const openPost = useComposerActions(actions => actions.openPost);
  const openComposer = useComposerActions(actions => actions.openComposer);
  const deleteDraft = useComposerActions(actions => actions.deletePost);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const toggleToolTipVisibility = () => setTooltipVisible(!tooltipVisible);

  const handleDeleteWhatsappAsset = useCallback(async () => {
    try {
      await callApi({
        url: `/users/${userId}/accounts/${accountId}/assets/${idea.id}`,
        method: "delete"
      });

      dispatch(removeWhatsappAssetById(idea.id));
      addToast("Whatsapp Asset Deleted Successfully", "success");
    } catch {
      addToast(
        "There was an error deleting whatsapp asset. Please try again later or contact support.",
        "error"
      );
    }
  }, [userId, accountId, idea.id]);

  const handleDeleteIdea = useCallback(() => {
    if (idea.isWhatsappAsset) {
      handleDeleteWhatsappAsset();
    } else {
      deleteDraft(idea);
    }
    setTooltipVisible(false);
  }, [setTooltipVisible]);

  const handleChangeCategory = useCallback(() => {
    setTooltipVisible(false);
  }, [setTooltipVisible]);

  const handleCreatePostFromIdea = useCallback(() => {
    if (idea.isWhatsappAsset) {
      openComposer(null, idea);
    } else {
      openPost(idea);
    }
    setTooltipVisible(false);
  }, [idea, setTooltipVisible, openPost]);

  return (
    <div ref={ref}>
      <MoreHorizontal
        size={20}
        color={theme.colors.black}
        cursor={"pointer"}
        onClick={toggleToolTipVisibility}
      />
      <Popper
        placement={"bottom-start"}
        referenceElement={ref.current}
        visible={tooltipVisible}
        onClose={() => setTooltipVisible(false)}
        exceptions={[ref.current]}
        offset={[-6, 0]}
      >
        <MenuContainer minWidth={150} style={{ margin: "0", padding: "0" }}>
          <SimpleBar
            style={{
              maxHeight: 220,
              width: 150,
              overflowX: "hidden",
              borderRadius: "8x"
            }}
          >
            <Flex flexDirection={"column"} sx={{}}>
              <MenuItem
                text="Edit Idea"
                onClick={() => {
                  handleCreatePostFromIdea();
                }}
                hoverBg="#0063FB"
                hoverTextColor="#FFFFFF"
              />
              <MenuItem
                text="Change Category"
                onClick={() => {
                  handleChangeCategory();
                }}
                hoverBg="#0063FB"
                hoverTextColor="#FFFFFF"
              />
              <MenuItem
                text="Delete Idea"
                onClick={() => {
                  handleDeleteIdea();
                }}
                textColor={theme.colors.red}
                hoverBg="#FFD3D3"
                hoverTextColor="#FF0E0E"
              />
            </Flex>
          </SimpleBar>
        </MenuContainer>
      </Popper>
    </div>
  );
};

export default MoreOptionsButton;

import React, { useState, useCallback, useRef } from "react";

import UrlTooltip from "../UrlTooltip";
import Popper from "components/common/Popper";

// The component we render when we encounter a url in the text
const ShortenableUrl = ({
  children,
  entityKey,
  urlHandler,
  contentState,
  decoratedText
}) => {
  let tooltipOnTimeout = useRef(null);
  let tooltipOffTimeout = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  const getTooltipUrl = useCallback(() => {
    const entity = contentState.getEntity(entityKey);
    const entityType = entity.getType();
    const entityData = entity.getData();
    return entityType === "UNSHORTENED_URL"
      ? entityData.shortenedUrl
      : entityData.unshortenedUrl;
  }, [contentState, entityKey]);

  const onTooltipButtonClick = useCallback(() => {
    const entity = contentState.getEntity(entityKey);
    const entityType = entity.getType();
    if (entityType === "SHORTENED_URL") {
      urlHandler.unshortenUrl(entityKey);
    } else {
      urlHandler.reshortenUrl(entityKey);
    }
  }, [contentState, entityKey, urlHandler]);

  const onMouseOver = useCallback(() => {
    clearTimeout(tooltipOffTimeout.current);
    tooltipOnTimeout.current = setTimeout(() => {
      setTooltipVisible(true);
    }, 300);
  }, []);

  const onMouseOut = useCallback(() => {
    clearTimeout(tooltipOnTimeout.current);
    tooltipOffTimeout.current = setTimeout(() => {
      setTooltipVisible(false);
    }, 70);
  }, []);

  const entity = contentState.getEntity(entityKey);
  const buttonText =
    entity.getType() === "SHORTENED_URL" ? "Unshorten Link" : "Shorten Link";
  const tooltipUrl = getTooltipUrl();
  //Tooltip shouldn't be visible if the url doesn't have an unshortened version.
  const hasTooltipUrl = !!tooltipUrl && tooltipUrl !== decoratedText;
  const isTooltipVisible = tooltipVisible && hasTooltipUrl;

  return (
    <span
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      ref={setReferenceElement}
      style={{ color: "blue", position: "relative" }}
    >
      {children}
      {hasTooltipUrl && (
        <Popper
          referenceElement={referenceElement}
          visible={tooltipVisible}
          exceptions={[referenceElement]}
        >
          <UrlTooltip
            text={tooltipUrl}
            buttonText={buttonText}
            isVisible={isTooltipVisible}
            entityType={entity.getType()}
            onClick={onTooltipButtonClick}
          />
        </Popper>
      )}
    </span>
  );
};

export default ShortenableUrl;

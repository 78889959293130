import React, { useCallback, useEffect, useState } from "react";
import { Flex } from "rebass/styled-components";
import { useToaster } from "@hellocontento/maillard";
import { connect } from "react-redux";
import { Headline2, InlineButton } from "../common/styles";
import { ContentHeader } from "./styles";
import TopicCarousel from "./TopicCarousel";
import { Box } from "rebass";
import { useHistory } from "react-router-dom";
import RecommendedTopics from "./recommendations/topics";
import Shelve from "./shelve";
import Loader from "components/common/loading/Loader";
import { getFeedsByType } from "services/news";

const ContentFollowed = ({ account, excludedTld, topics }) => {
  const addToast = useToaster();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const [followedContent, setFollowedContent] = useState(null);

  const refreshContentFeed = useCallback(async () => {
    try {
      setIsLoading(true);
      const contents = await getFeedsByType("followed-topics", { limit: 6 });
      setFollowedContent(contents);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err.name !== "RequestCancelled") {
        addToast(
          "There was an error fetching your content. Please try again later or contact support.",
          "error"
        );
      }
    }
  }, [addToast]);

  useEffect(() => {
    refreshContentFeed();
  }, [refreshContentFeed]);

  if (isLoading) {
    return <Loader location={"center"} size={64} />;
  }

  if (!topics || !topics.length) {
    return <RecommendedTopics showAll={true} />;
  }

  return (
    <Box flex={"1"}>
      <Flex mb={80} flexDirection="column">
        <ContentHeader>
          <Headline2>Topics</Headline2>
          <p>
            Your overview of followed topics.{" "}
            <InlineButton
              onClick={() =>
                history.push(
                  `/accounts/${account.id}/content/explore?type=topics`
                )
              }
            >
              Add more topics
            </InlineButton>
          </p>
        </ContentHeader>
        <TopicCarousel accountTopics={topics} />
      </Flex>
      {followedContent &&
        followedContent.map(contentTopic => {
          return (
            <div
              key={`${contentTopic.section.id}-${contentTopic.section.language}`}
            >
              <Shelve
                accountId={account.id}
                type={"topics"}
                section={contentTopic.section}
                contents={contentTopic.contents.slice(0, 6)}
                onRefresh={refreshContentFeed}
              />
            </div>
          );
        })}
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser,
    excludedTld: state.regionFilter.excludedTld,
    topics:
      state.content.sections.find(section => section.type === "topics")
        ?.details ?? []
  };
};

export default connect(mapStateToProps, {})(ContentFollowed);

import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { ITag } from "./types";

import { TagWrapper } from "./styles";

const Tag: React.FC<ITag> = ({ id, title, lang }) => {
  const history = useHistory();

  const account = useSelector((state: any) => state.account.data);

  const navigateTo = () => {
    history.push(`/accounts/${account.id}/content/topics/${id}/${lang}`);
  };

  return <TagWrapper onClick={navigateTo}>{title}</TagWrapper>;
};

export default Tag;

import React from "react";

import {
  TooltipTitle,
  StatListItem,
  TooltipContent,
  TooltipContainer
} from "../styles";
import { colors } from "theme";
import { getNumWithMaxDecimals } from "utils/numbers";
import ContentType from "components/common/contentTypes/data/content-types";

interface ITooltip {
  data: any;
}

const Tooltip: React.FC<ITooltip> = ({ data }) => {
  return (
    <TooltipContainer>
      <TooltipTitle>Content mix</TooltipTitle>
      <TooltipContent>
        {Object.entries(data)
          .sort((a, b) => {
            if (a[0].toLowerCase() === "other") {
              return 1;
            }
            return a[0].localeCompare(b[0]);
          })
          .map(([key, value]: any[]) => (
            <StatListItem
              key={key}
              bgcolor={
                Object.keys(ContentType).includes(key)
                  ? ContentType[key as keyof typeof ContentType].color
                  : colors.text04
              }
            >
              <div className="title">
                <span className="badge" />
                {key}
              </div>
              <div className="stats">{getNumWithMaxDecimals(value, 1)}%</div>
            </StatListItem>
          ))}
      </TooltipContent>
    </TooltipContainer>
  );
};

export default Tooltip;

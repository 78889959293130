import _ from "lodash";
import { ChartOptions } from "chart.js";

import {
  today,
  addDuration,
  getMonthForChart,
  subtractDuration
} from "utils/date";
import { colors } from "theme";

export const getLineChartOptions = (
  data: any[],
  overrides?: ChartOptions<"line">
): ChartOptions<"line"> => {
  const filteredData = data
    .map(item => item.total)
    .filter(value => typeof value === "number");
  const stepSize = filteredData.length
    ? Math.floor(_.sum(filteredData) / filteredData.length)
    : undefined;

  return _.merge<ChartOptions<"line">, ChartOptions<"line">>(
    {
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: "index",
        intersect: false
      },
      elements: {
        point: {
          radius: 0
        }
      },
      parsing: {
        xAxisKey: "date",
        yAxisKey: "total"
      },
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        }
      },
      scales: {
        y: {
          offset: true,
          beginAtZero: false,
          position: "right",
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            stepSize,
            maxTicksLimit: 3,
            precision: 0,
            color: colors.darkText03,
            maxRotation: 0,
            minRotation: 0,
            callback: value => {
              if (typeof value === "number") {
                return Math.floor(value).toString().length >= 4
                  ? `${(value / 1000).toFixed(1)}k`
                  : value;
              }
              return value;
            }
          }
        },
        x: {
          offset: true,
          beginAtZero: false,
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            autoSkip: false,
            color: colors.darkText03,
            maxRotation: 0,
            minRotation: 0,
            callback: function (val, index, ticks) {
              if (
                index === 0 ||
                index === ticks.length - 1 ||
                index === Math.ceil(ticks.length / 2 - 1)
              ) {
                let monthForChart = getMonthForChart(
                  new Date(this.getLabelForValue(val as number))
                );
                const existingLabels = ticks.reduce((acc: string[], tick) => {
                  acc.push(typeof tick.label === "string" ? tick.label : "");
                  return acc;
                }, [] as string[]);

                monthForChart =
                  monthForChart.toLowerCase() === "today"
                    ? monthForChart
                    : monthForChart.slice(0, 3);

                return existingLabels.includes(monthForChart)
                  ? ""
                  : monthForChart;
              }
              return "";
            }
          }
        }
      },
      datasets: {
        line: {
          borderColor: colors.white,
          backgroundColor: colors.white,
          tension: 0.25
        }
      }
    },
    overrides ?? {}
  );
};

export const getBarChartOptions = (
  overrides?: ChartOptions<"bar">
): ChartOptions<"bar"> => {
  return _.merge<ChartOptions<"bar">, ChartOptions<"bar">>(
    {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          borderColor: colors.text01,
          borderWidth: 2,
          borderCapStyle: "round"
        },
        point: {
          radius: 0,
          hoverRadius: 0
        },
        bar: {
          borderWidth: 8,
          borderRadius: Number.MAX_VALUE,
          borderSkipped: false
        }
      },
      parsing: {
        xAxisKey: "date",
        yAxisKey: "total"
      },
      plugins: {
        legend: {
          display: false
        },
        title: {
          display: false
        }
      },
      scales: {
        y: {
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            display: false
          }
        },
        x: {
          offset: true,
          beginAtZero: false,
          grid: {
            display: false,
            drawBorder: false
          },
          ticks: {
            autoSkip: false,
            color: colors.text04,
            maxRotation: 0,
            minRotation: 0,
            callback: function (val, index, ticks) {
              if (
                index === 0 ||
                index === ticks.length - 1 ||
                index === Math.ceil(ticks.length / 2 - 1)
              ) {
                let monthForChart = getMonthForChart(
                  new Date(this.getLabelForValue(val as number))
                );
                const existingLabels = ticks.reduce((acc: string[], tick) => {
                  acc.push(typeof tick.label === "string" ? tick.label : "");
                  return acc;
                }, [] as string[]);

                monthForChart =
                  monthForChart.toLowerCase() === "today"
                    ? monthForChart
                    : monthForChart.slice(0, 3);

                return existingLabels.includes(monthForChart)
                  ? ""
                  : monthForChart;
              }
              return "";
            }
          }
        }
      },
      datasets: {
        bar: {
          backgroundColor: "none"
        }
      }
    },
    overrides ?? {}
  );
};

export const getChartData = (
  dataPoints: any,
  nullEntry: boolean = true,
  minDataPoints: number = 9
) => {
  const chartData: {
    data: any[];
    dates: string[];
  } = (dataPoints as any[])
    .sort((a: any, b: any) => {
      return Date.parse(a.date) - Date.parse(b.date);
    })
    .reduce(
      (acc: { data: any[]; dates: string[] }, point) => {
        acc.data.push(point);
        acc.dates.push(point.date);

        return acc;
      },
      { data: [], dates: [] }
    );

  if (chartData.data.length < minDataPoints) {
    let date =
      dataPoints.length >= 1
        ? new Date(dataPoints[0].date)
        : addDuration(today(), { weeks: 1 });

    for (
      let count = chartData.data.length + 1;
      count <= minDataPoints;
      count++
    ) {
      date = subtractDuration(date, { weeks: 1 });
      chartData.data = [
        nullEntry
          ? { total: null, date: date.toISOString() }
          : { total: 0, date: date.toISOString() },
        ...chartData.data
      ];
      chartData.dates = [date.toISOString(), ...chartData.dates];
    }
  }

  return chartData;
};

import React from "react";
import { useDispatch } from "react-redux";

import { openModal } from "state/actions/ModalActions";

import {
  AddRSSWrapper,
  IconWrapper,
  InformationWrapper,
  ButtonContent
} from "./styles";

import RSSIcon from "assets/images/contents/RSS.png";
import { Headline1, Paragraph } from "components/common/styles";
import { Box } from "rebass";
import Button from "components/common/Button";

interface IAddRSSFeed {}

const AddRSSFeed: React.FC<IAddRSSFeed> = () => {
  const dispatch = useDispatch();

  return (
    <AddRSSWrapper>
      <IconWrapper src={RSSIcon}></IconWrapper>
      <InformationWrapper>
        <Headline1>Add your favorite RSS Feeds</Headline1>
        <Paragraph variant={"lightest"}>
          RSS is a simple, standardized content distribution method that can
          help you stay up-to-date with your favorite blogs, websites, and
          social media channels. Find the RSS feed on a website and read new
          posts in Willow.{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://support.willow.co/knowledge/how-to-add-an-rss-feed"}
          >
            Learn more
          </a>
        </Paragraph>
      </InformationWrapper>
      <Box>
        {/* @ts-ignore */}
        <Button
          onClick={() => dispatch(openModal("ADD_RSS_FEED"))}
          variant={"primary"}
        >
          <ButtonContent>
            <span className="icon-plus" />
            Add RSS feed
          </ButtonContent>
        </Button>
      </Box>
    </AddRSSWrapper>
  );
};

export default AddRSSFeed;

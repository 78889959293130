import twitterText from "twitter-text";
import { EditorState, Modifier } from "draft-js";
import {
  createSelectionFromRange,
  getEntityRange,
  replaceText
} from "../../../utils/editor";

export const findNewUrls = contentState => {
  const urls = [];
  contentState.getBlockMap().forEach(contentBlock => {
    const text = contentBlock.getText();
    const urlsWithIndices = twitterText.extractUrlsWithIndices(text);
    urlsWithIndices.forEach(urlWithIndices => {
      if (contentBlock.getEntityAt(urlWithIndices.indices[0]) !== null) {
        return;
      }
      urls.push({
        url: urlWithIndices.url,
        range: {
          start: urlWithIndices.indices[0],
          end: urlWithIndices.indices[1]
        },
        contentBlockKey: contentBlock.getKey()
      });
    });
  });
  return urls;
};

export const addUrlEntity = (
  editorState,
  selection,
  entityData,
  shortened = false
) => {
  let contentState = editorState.getCurrentContent();

  const type = shortened ? "SHORTENED_URL" : "UNSHORTENED_URL";
  const mutability = shortened ? "IMMUTABLE" : "MUTABLE";

  contentState = contentState.createEntity(type, mutability, entityData);

  const entityKey = contentState.getLastCreatedEntityKey();
  const currentContent = editorState.getCurrentContent();

  const contentStateWithEntity = Modifier.applyEntity(
    currentContent,
    selection,
    entityKey
  );

  return EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
    lastChangeType: "apply-entity"
  });
};

export const replaceUrlEntity = (
  editorState,
  blockKey,
  entityKey,
  oldUrl,
  newUrl,
  shortening = true
) => {
  const range = getEntityRange(editorState, entityKey, blockKey);
  if (!range || range.text !== oldUrl) {
    throw new Error("Url mismatch or entity not found.");
  }

  const selection = createSelectionFromRange(blockKey, range);
  const newEditorState = replaceText(editorState, selection, oldUrl, newUrl);

  const newRange = { start: range.start, end: range.start + newUrl.length };
  const newSelection = createSelectionFromRange(blockKey, newRange);

  const entityData = shortening
    ? { unshortenedUrl: oldUrl, shortenedUrl: newUrl }
    : { unshortenedUrl: newUrl, shortenedUrl: oldUrl };

  return addUrlEntity(newEditorState, newSelection, entityData, shortening);
};

import { ModalHeaderComposer, ModalBodyComposer } from "./styles";
import Modal from "react-modal";
import React from "react";
import { X } from "styled-icons/feather";
import { ModalTitle } from "../../common/styles";
import { ModalCloseIconWrapper, modalStyles } from "../styles";

export const PostDetailModal = ({ title, onClose, children }) => {
  const styles = modalStyles(550);
  styles.content.padding = 0;
  return (
    <>
      <Modal
        ariaHideApp={false}
        style={styles}
        isOpen={true}
        onRequestClose={onClose}
      >
        <ModalHeaderComposer>
          <ModalTitle>{title}</ModalTitle>
          <ModalCloseIconWrapper onClick={onClose}>
            <X size={32} />
          </ModalCloseIconWrapper>
        </ModalHeaderComposer>
        <ModalBodyComposer>{children}</ModalBodyComposer>
      </Modal>
    </>
  );
};

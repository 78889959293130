import styled from "styled-components";

export const EngagementRateInputContainer = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid #f0f2f5;
  border-radius: 4px;
  overflow: hidden;
`;

export const EngagementRateInput = styled.input`
  width: 35px;
  border: none;
  padding: 5px;
  text-align: right;
`;

export const EngagementRatePercent = styled.div`
  background-color: #f0f2f5;
  padding: 6px;
  color: #646769;
`;

export const ReshareButton = styled.button`
  padding: 8px 12px;
  border-radius: 20px;
  background-color: #007bff;
  font-weight: bold;
  color: white;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

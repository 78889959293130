import React from "react";
import ExploreTopics from "./ExploreTopics";

import EmptySearchState from "../EmptySearchState";

interface ITopicListProps {
  searchContext: string;
  topics: Array<any>;
  changeSearchContext: () => void;
}

const TopicList: React.FC<ITopicListProps> = ({
  searchContext,
  topics,
  changeSearchContext
}) => {
  const topicsGroupedByParent = topics.reduce((acc, value) => {
    const parent = acc.find(
      (a: any) => a.parent?.id === value.detail.parent?.id
    );

    if (!parent) {
      acc.push({
        parent: value.detail.parent ?? { label: value.detail.label },
        details: [value.detail]
      });
    } else {
      parent.details.push(value.detail);
    }

    return acc;
  }, []);

  return (
    <>
      {topicsGroupedByParent.length > 0 ? (
        topicsGroupedByParent.map((topicsByParent: any, idx: number) => {
          return (
            <ExploreTopics
              key={idx}
              title={topicsByParent.parent.label}
              changeSearchContext={changeSearchContext}
              topics={topicsByParent.details}
              searchContext={searchContext}
            />
          );
        })
      ) : (
        <EmptySearchState />
      )}
    </>
  );
};

export default TopicList;

import React from "react";
import styled, { css } from "styled-components";
import { ChevronDown } from "styled-icons/boxicons-regular";
import IconButton from "../../common/IconButton";

export const ScheduleButtonContainer = styled.button`
  color: ${props =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.text01};
  align-items: center;
  justify-content: center;
  border-radius: ${props => (props.radius ? props.radius : "48")}px;
  border: none;
  box-shadow: none;
  column-gap: 4px;
  cursor: pointer;
  display: inline-grid;
  grid-template-columns: ${props =>
    props.moreOptions ? "24px 1fr 20px" : "12px 1fr"};

  flex-direction: row-reverse;
  font-family: ${props => props.theme.fonts.body};
  font-size: 14px;
  font-weight: 500;
  outline: none;
  padding: 2px 10px;
  background-color: transparent;

  & i {
    font-size: 18px;
    width: ${props => props.size || "18"}px;
    height: ${props => props.size || "18"}px;
    color: ${props => props.theme.colors.text02};
  }

  .chevron {
    visibility: hidden;
  }

  &:hover {
    background-color: ${props => props.theme.colors.grey01};

    ${props =>
      props.boldOnHover &&
      css`
        color: ${props => props.theme.colors.text01};
        font-weight: 600;

        & i {
          color: ${props => props.theme.colors.text01};
          font-weight: 600;
        }
      `}
    .chevron {
      visibility: visible;
    }
  }
`;

const ScheduleChevronButton = styled(IconButton)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
`;

function ScheduleButton({ children, moreOptions = true, ...props }) {
  return (
    <ScheduleButtonContainer moreOptions={moreOptions} {...props}>
      <i className={props.icon} />
      {children}
      {moreOptions && (
        <ScheduleChevronButton
          className="chevron"
          padding={2}
          size={32}
          icon={ChevronDown}
        />
      )}
    </ScheduleButtonContainer>
  );
}

export default ScheduleButton;

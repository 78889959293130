import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { openModal } from "../../state/actions/ModalActions";
import {
  IdeaCard,
  IdeaCardAction,
  IdeaCardButton,
  IdeaCardThumbnail,
  IdeaCardImage,
  IdeaCardBody,
  IdeaCardTitle,
  IdeaCardDescription
} from "./styles";

export default function IdeaEntry({ idea }: {idea: any}) {
  const { title, subtitle, preview, postConcept } = idea;
  const dispatch = useDispatch();
  const [isThumbnailHovered, setThumbnailHovered] = useState(false);

  const handleClick = () => {
    dispatch(openModal("IDEA_DETAIL_MODAL", { idea: idea }));
  };

  return (
    <IdeaCard>
      <IdeaCardThumbnail
        $bgColor={postConcept.secondaryColor}
        onMouseEnter={() => setThumbnailHovered(true)}
        onMouseLeave={() => setThumbnailHovered(false)}
      >
        <IdeaCardImage $isVisible={!isThumbnailHovered}>
          <img src={preview[0].file.url} alt={title} />
        </IdeaCardImage>
        <IdeaCardAction $isVisible={isThumbnailHovered}>
          <IdeaCardButton onClick={() => handleClick()} marginRight={8}>
            Discover Idea
          </IdeaCardButton>
        </IdeaCardAction>
      </IdeaCardThumbnail>
      <IdeaCardBody $bgColor={postConcept.primaryColor}>
        <IdeaCardTitle>{title}</IdeaCardTitle>
        <IdeaCardDescription>{subtitle}</IdeaCardDescription>
      </IdeaCardBody>
    </IdeaCard>
  );
}

import React, { useMemo } from "react";

import {
  EngagementRateInput,
  EngagementRatePercent,
  EngagementRateInputContainer
} from "./styles";
import {
  TableTitle,
  LeftContainer,
  RightContainer,
  HeaderContainer
} from "../styles";
import ChannelToggle from "components/common/ChannelToggle";
import {
  useAnalyticsActions,
  useAnalyticsState
} from "contextApi/analyticsContext";
import PostCategorySelector from "../widgets/PostCategorySelector";
import { Flex, Text } from "rebass/styled-components";

interface PostsToRepurposeHeaderProps {
  minEngagementRate: number;
  setMinEngagementRate: (rate: number) => void;
  selectedChannels: string[];
  setSelectedChannels: (channels: string[]) => void;
  account: {
    id: string;
    channels: Array<{
      id: string;
      avatar: string;
      username: string;
      service: string;
    }>;
  };
}

const PostsToRepurposeHeader: React.FC<PostsToRepurposeHeaderProps> = ({
  minEngagementRate,
  setMinEngagementRate,
  selectedChannels,
  setSelectedChannels,
  account
}) => {

  const data = useAnalyticsState(state => state.repurposePosts.data);
  const selectedCategories = useAnalyticsState(
    state => state.repurposePosts.categories
  );
  const setSelectedCategories = useAnalyticsActions(
    state => state.setRepurposePostsCategories
  );

  const categories = useMemo(() => {
    let contentTypeIdSet: Set<string> = new Set(
      data.map(item => item.contentTypeId)
    );
    const contentTypeIds = Array.from(contentTypeIdSet);
    setSelectedCategories(contentTypeIds);
    return contentTypeIds;
  }, [data, setSelectedCategories]);

  const handleEngagementRateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = parseFloat(event.target.value);
    if (!isNaN(value) && value >= 0) {
      setMinEngagementRate(value);
    }
  };

  const handleChannelToggleChange = (updatedChannels: string[]) => {
    setSelectedChannels(updatedChannels);
  };

  return (
    <HeaderContainer>
      <LeftContainer>
        <TableTitle>Posts to Repurpose</TableTitle>
        <Text fontSize={"14px"}>
          These posts have stopped receiving new engagement and can be reshared.
          <br />
          You can read more about{" "}
          <a
            href="https://support.willow.co/knowledge/analytics-terminology"
            target="_blank"
            rel="noopener noreferrer"
          >
            how we calculate this here
          </a>
          .
        </Text>
      </LeftContainer>
      <RightContainer>
        <Flex flexDirection={"column"} sx={{ gap: "6px" }}>
          <div>
            <label>Show posts with engagement rate higher than: </label>
            <EngagementRateInputContainer>
              <EngagementRateInput
                type="number"
                value={minEngagementRate}
                onChange={handleEngagementRateChange}
                min="0"
              />
              <EngagementRatePercent>%</EngagementRatePercent>
            </EngagementRateInputContainer>
          </div>
          <Flex sx={{ gap: "12px" }}>
            <PostCategorySelector
              categories={categories}
              selectedCategories={selectedCategories}
              onChange={setSelectedCategories}
            />
            <ChannelToggle
              channels={account.channels}
              selectedIds={selectedChannels}
              onSave={handleChannelToggleChange}
              gap={5}
              onChange={() => {}}
            />
          </Flex>
        </Flex>
      </RightContainer>
    </HeaderContainer>
  );
};

export default PostsToRepurposeHeader;

// @ts-nocheck
import React, { useRef, useState, useEffect } from "react";

import {
  EntryStats,
  EntryContent,
  EntryWrapper,
  PublisherInfo,
  PublishedEntryInfo,
  PublishedEntryHeader,
  ImageAttachmentWrapper
} from "./styles";
import { IFeedEntry } from "./index";
import { ATTACHMENT_TYPES } from "constants/post";
import EntryText from "components/schedule/common/entryText";
import { useComposerActions } from "contextApi/composerContext";
import {
  PostContextMenuWithButton,
  RightClickPostContextMenu
} from "components/schedule/common/postContextMenu/PostContextMenu";
import { Avatar, ChannelAvatar, Service } from "components/common/styles";
import defaultAvatar from "assets/images/dashboard/avatar-empty-state.png";
import PostEntryArticle from "components/schedule/common/postEntryArticle";
import ContentTypeBadge from "components/common/contentTypes/ContentTypeBadge";
import PostAttachmentVideo from "components/schedule/common/postAttachmentVideo";
import PostAttachmentImage from "components/schedule/common/postAttachmentImage";

const PublishedEntry: React.FC<IFeedEntry> = ({ entry }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { post, time, text, title, picture } = entry;
  const openPost = useComposerActions(actions => actions.openPost);
  const [contextMenuPos, setContextMenuPos] = useState<number[]>([]);

  const hasAttachment = !!post?.attachment;

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    openPost(post);
  };

  const closeMenu = () => {
    setContextMenuPos([]);
  };

  // Context menu handler on right click
  useEffect(() => {
    const element = ref.current;

    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
      setContextMenuPos([e.clientX, e.clientY]);
    };

    if (!!element) {
      element.addEventListener("contextmenu", handleContextMenu);
    }

    return () => {
      if (!!element) {
        element.removeEventListener("contextmenu", handleContextMenu);
      }
    };
  }, []);

  // Remove context menu on scroll
  useEffect(() => {
    const element = ref.current;

    const handleScroll = () => {
      setContextMenuPos([]);
    };

    if (!!contextMenuPos.length) {
      document.addEventListener("scroll", handleScroll);
      // ? Move up the DOM to get to the scrollable timeline element
      element?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.addEventListener(
        "scroll",
        handleScroll
      );
    }

    return () => {
      document.removeEventListener("contextmenu", handleScroll);
      // ? Move up the DOM to get to the scrollable timeline element
      element?.parentNode?.parentNode?.parentNode?.parentNode?.parentNode?.removeEventListener(
        "contextmenu",
        handleScroll
      );
    };
  }, [contextMenuPos]);

  return (
    <EntryWrapper
      ref={ref}
      onClick={handleClick}
      isContextMenuOpen={contextMenuPos.length > 0}
    >
      <PublishedEntryHeader>
        <PublishedEntryInfo>
          <ChannelAvatar $enabled={true}>
            <Avatar
              src={post.channel.avatar ?? defaultAvatar}
              width={40}
              height={40}
              $noShadow={false}
              $isRounded={true}
              onError={event => {
                event.target.src = defaultAvatar;
                event.onerror = null;
              }}
            />
            <Service
              key={post.channel.id}
              title={post.channel.username}
              $type={post.channel.service}
              $size={20}
            />
          </ChannelAvatar>
          <PublisherInfo>
            <span className="username">{post.channel.username}</span>
            <span className="time">{time}</span>
          </PublisherInfo>
        </PublishedEntryInfo>
        {post.contentTypeId !== "other" && (
          <ContentTypeBadge
            variant={"dark-info"}
            contentTypeId={post.contentTypeId}
            title={post.postIdea?.title || post.contentTypeLabel}
          />
        )}
      </PublishedEntryHeader>
      <EntryContent>
        {text && (
          <EntryText
            text={text}
            hasAttachment={hasAttachment}
            service={post.channel.service}
          />
        )}
        {hasAttachment &&
          post.attachment.type === ATTACHMENT_TYPES.PHOTO &&
          !title && (
            <ImageAttachmentWrapper>
              <PostAttachmentImage
                attachment={post.attachment}
                height={200}
                maxHeight={
                  !post.attachment.metaData?.[0]?.height ||
                  !post.attachment.metaData?.[0]?.width
                    ? undefined
                    : (post.attachment.metaData[0].height /
                        post.attachment.metaData[0].width) *
                      498
                }
              />
            </ImageAttachmentWrapper>
          )}
        {hasAttachment &&
          post.attachment.type === ATTACHMENT_TYPES.PDF &&
          !title && (
            <ImageAttachmentWrapper>
              <PostAttachmentImage
                attachment={{
                  url: entry.post.attachment.metaData?.preview || ""
                }}
                height="unset"
                maxHeight={
                  !post.attachment.metaData?.[0]?.height ||
                  !post.attachment.metaData?.[0]?.width
                    ? undefined
                    : (post.attachment.metaData[0].height /
                        post.attachment.metaData[0].width) *
                      498
                }
              />
            </ImageAttachmentWrapper>
          )}
        {hasAttachment && post.attachment.type === ATTACHMENT_TYPES.VIDEO && (
          <PostAttachmentVideo attachment={post.attachment} />
        )}
        {hasAttachment && title && (
          <PostEntryArticle picture={picture} post={post} title={title} />
        )}
      </EntryContent>
      <EntryStats>
        <div className="stats">
          <i className="icon-analytics-likes">
            <i className="path1"></i>
            <i className="path2"></i>
            <i className="path3"></i>
          </i>
          <span>{post.stats?.likes ?? 0}</span>
        </div>
        <div className="stats">
          <i className="icon-analytics-comment" />
          <span>{post.stats?.comments ?? 0}</span>
        </div>
        <div className="stats">
          <i className="icon-analytics-share" />
          <span>{post.stats?.shares ?? 0}</span>
        </div>
        <div className="stats">
          <span>{post.stats?.impressions ?? 0}</span>
          <span>impressions</span>
        </div>
      </EntryStats>
      <RightClickPostContextMenu
        entry={entry}
        closeMenu={closeMenu}
        contextMenuPos={contextMenuPos}
      />
      <PostContextMenuWithButton entry={entry} contextWithPortal={true} />
    </EntryWrapper>
  );
};

export default PublishedEntry;

import styled, { css } from "styled-components";
import { Card, Text, Image, Box, Flex } from "rebass/styled-components";

import warningMessage from "../../../assets/images/warning-message.png";

export const ServiceCard = styled(Card)`
  border-radius: 8px;
  margin: 0 8px 16px 8px;
  flex-grow: 1;
  position: relative;
  width: calc(100% - 16px);
  z-index: 1;
  background: ${props => props.theme.colors.white};
  border: ${props =>
    props.connected ? `1px solid ${props.theme.colors.grey02}` : "none"};
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: ${props =>
    props.connected
      ? "none"
      : "0px 0px 0px 1px rgba(28, 30, 31, 0.05), 0px 3px 6px rgba(28, 30, 31, 0.05)"};
  ${props =>
    props.status === "next" &&
    css`
      border-radius: 0 0 8px 8px;
      box-shadow:
        0 -1px 0 rgba(252, 252, 252, 1),
        0 0 0 1px rgba(224, 224, 224, 1);
      border-top-color: transparent;
      margin-top: -16px;
      left: 4px;
      width: calc(100% - 24px);
      z-index: 0;
    `};
  ${props =>
    props.status === "toUrgentlyReconnect" &&
    css`
      outline: 1px solid ${props.theme.colors.red};
    `}
`;

export const ServiceNeedsReconnection = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: 8px 0;
  width: 100%;
`;

export const NextCard = styled(Card)``;

export const SocialAvatar = styled(Image)`
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 32px;
`;

export const AddChannel = styled(Box)`
  display: block;
  position: relative;
  cursor: pointer;
  ${props =>
    props.status === "disabled" &&
    css`
      & img {
        filter: grayscale(1);
        opacity: 0.5;
      }
    `}
`;

export const SocialIcon = styled(Image)`
  width: 20px;
  height: 20px;
  border-radius: 20px;
  box-sizing: content-box;
  position: absolute;
  top: -8px;
  right: -8px;
`;

export const Username = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  display: block;
  color: ${props => props.theme.colors.black};
  margin-bottom: 4px;
`;

export const Instruction = styled(Text)`
  font-size: 14px;
  max-width: 420px;
  color: ${props => props.theme.colors.text03};
  line-height: 20px;
`;

export const ChannelStatus = styled.span`
  color: ${props =>
    props.status === "connected"
      ? props.theme.colors.status.green
      : props.theme.colors.status.red};
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.24px;
  margin: 8px 0;
  text-transform: capitalize;

  &::before {
    background-color: ${props =>
      props.status === "connected"
        ? props.theme.colors.status.green
        : props.theme.colors.status.red};
    border-radius: 3px;
    content: "";
    display: inline-block;
    height: 6px;
    margin-right: 4px;
    width: 6px;
  }
`;

export const ServiceName = styled(Box)`
  flex-grow: 1;
  margin-left: 30px;
  ${props =>
    props.status === "disabled" &&
    css`
      opacity: 0.75;
    `}
`;

export const CardHead = styled(Box)`
  position: relative;
  border-radius: 50%;

  &:hover {
    background-color: ${props => props.theme.colors.grey02};
  }
  &:active {
    background-color: ${props => props.theme.colors.grey03};
  }
  &:focus {
    box-shadow:
      0 0 2px ${props => props.theme.colors.background1},
      0 0 0 2px ${props => props.theme.colors.background1},
      0 0 0 3px ${props => props.theme.colors.iconFill4} !important;
    transition: box-shadow 0.2s ease;
  }
`;

export const CardMoreMenu = styled(Box)`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const CardActionWrapper = styled(Flex)`
  flex-direction: row;
  gap: 8px;
`;

export const CardActions = styled(Box)`
  position: absolute;
  right: 12px;
  top: 26px;
  border-radius: 16px;
  border: 1px solid rgba(96, 116, 128, 0.12);
  -webkit-box-shadow: 3px 2px 5px 0px rgba(219, 219, 219, 0.58);
  -moz-box-shadow: 3px 2px 5px 0px rgba(219, 219, 219, 0.58);
  box-shadow: 0px 8px 16px -8px rgba(28, 30, 31, 0.12);
  z-index: 10;
  padding: 10px;
  background-color: white;
`;

export const CardAction = styled(Box)`
  display: block;
  padding: 8px;
  background-color: white;
  cursor: pointer;
  z-index: 10;
  white-space: nowrap;
  color: ${props => props.theme.colors.red};
  align-items: center;
  & span {
    margin-right: 8px;
  }
`;

export const WarningMessage = styled(Box)`
  color: ${props => props.theme.colors.red};
  background: #fed9cc url(${warningMessage}) no-repeat left 10px center;
  background-size: 15px 13px;
  border-radius: 4px;
  padding: 8px 12px 8px 34px;
  display: inline-block;
`;

export const AddServiceBox = styled(Box)`
  align-items: center;
  cursor: ${props => (!props.isConnected ? "pointer" : "default")};
  border-radius: 8px;
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-column-gap: 24px;
  padding: 16px;

  &:hover {
    background-color: ${props =>
      !props.isConnected ? props.theme.colors.grey01 : "none"};
  }
`;

export const AddServiceName = styled.h3`
  color: ${props =>
    !props.isConnected ? props.theme.colors.text01 : props.theme.colors.text02};
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: -0.16px;
`;

import styled from "styled-components";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Box } from "rebass/styled-components";
import { RouteComponentProps } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import AnalyticsRoutes from "./AnalyticsRoutes";
import { useAppState } from "contextApi/appContext";
import AnalyticsMenu from "components/analytics/AnalyticsMenu";
import { AnalyticsProvider } from "contextApi/analyticsContext";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { useNotificationActions } from "contextApi/notificationContext";
import { willowExtensionFeatureNotification } from "components/modals/pop-ups/featureNotifications";

const AnalyticsContainer = styled(Box)`
  background-color: ${props => props.theme.colors.background};
  min-height: calc(100vh - 72px);
`;

const PageContainer = styled(Box)`
  padding: 30px;
`;

const AnalyticsPage: React.FC<RouteComponentProps> = React.memo(props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector<any, any>(state => state.account.data);
  const showWillowExtension = useAppState(state => state.showWillowExtension);
  const addFeatureNotification = useNotificationActions(
    actions => actions.addFeatureNotification
  );

  useEffect(() => {
    dispatch(
      trackAnalyticsEvent("Analytics Viewed", {
        type:
          history.location.pathname.match(
            /^.*\/accounts\/[^/]*\/analytics\/([^/?]*).*$/
          )?.[1] ?? ""
      })
    );
  }, [dispatch, history.location.pathname]);

  useEffect(() => {
    if (!!account.id && showWillowExtension) {
      addFeatureNotification(willowExtensionFeatureNotification);
    }
  }, [account.id, addFeatureNotification, showWillowExtension]);

  return (
    <AnalyticsProvider>
      <AnalyticsContainer>
        <AnalyticsMenu />
        <PageContainer>
          <AnalyticsRoutes {...props} />
        </PageContainer>
      </AnalyticsContainer>
    </AnalyticsProvider>
  );
});

export default AnalyticsPage;

import { useContext } from "react";
import { useGranularCallback } from "granular-hooks";

import {
  IAlertContextPayload,
  FeatureAlertContext
} from "./FeatureAlertProvider";

const useAlertModal = () => {
  const context = useContext(FeatureAlertContext);

  const addFeatureAlert = useGranularCallback(
    (payload: IAlertContextPayload) => {
      context.dispatch({
        type: "ADD",
        payload
      });
    },
    [],
    [context]
  );

  return addFeatureAlert;
};

export type { IAlertContextPayload };

export default useAlertModal;

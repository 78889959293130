import styled from "styled-components";
import { ExternalLink } from "styled-icons/heroicons-outline";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  padding: 0;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`;

export const TableContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
`;

export const Table = styled.table`
  width: 100%;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border: 1px solid #ddd;
  border-collapse: separate;
  margin-bottom: 10px;
  min-width: 80vw;
`;

export const TableTitle = styled.h1`
  color: black;
  padding-top: 15px;
  padding-bottom: 10px;
  font-size: 22px;
`;

export const Th = styled.th<{
  isSorted: boolean;
  direction: string;
  showArrow: boolean;
  index: number;
}>`
  background-color: ${props => (props.isSorted ? "#F0F2F5" : "#F0F2F5")};
  color: ${props =>
    props.isSorted ? "#646769" : props.index < 3 ? "#646769" : "#a0a0a0"};
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  position: sticky;
  top: 0;
  z-index: 1;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-right: ${props => (props.showArrow ? "30px" : "10px")};
  font-family: ${props => props.theme.fonts.headline};
  min-width: ${props => (props.index === 1 ? "200px" : "auto")};

  &::after {
    content: "${props =>
      props.showArrow
        ? props.isSorted
          ? props.direction === "ascending"
            ? "▲"
            : "▼"
          : "▼"
        : ""}";
    margin-left: 5px;
    transform: translateY(-50%);
    font-size: 12px;
  }
`;

export const Td = styled.td<{
  isSorted: boolean;
  index: number;
  textAlign?: "left" | "right" | "center";
}>`
  background-color: ${props => (props.isSorted ? "#f7f7f7" : "white")};
  padding: 10px;
  font-size: 13px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: ${props => (props.textAlign ? props.textAlign : "center")};
  vertical-align: middle;
  color: black;
  border-bottom: solid #ddd;
  font-family: ${props => props.theme.fonts.body};
  width: auto;

  > i {
    font-size: 20px;
    color: ${props => props.theme.colors.text02};
  }
`;

export const PostText = styled.div<{
  maxLines?: number;
  color?: string;
}>`
  display: -webkit-box;
  -webkit-line-clamp: ${props => props.maxLines ?? 3};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  color: ${props => props.color ?? "inherit"};
`;

export const PostTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: 250px;
`;

export const PostLinkIcon = styled(ExternalLink)`
  flex-shrink: 0;
  cursor: pointer;
  color: #646769;
  height: 16px;
  width: 16px;
`;

export const NoDataForTheseCriteria = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const DownloadButton = styled.button`
  color: #646769;
  border: none;
  padding: 0px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background: none;

  &:hover:not(:disabled) {
    background: #f0f2f5;
  }
`;

export const NA = styled.span`
  color: #646769;
`;

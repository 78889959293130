import React from "react";

import HelperButton from "components/common/form/HelperButton";

import {
  SettingsWellEmpty,
  EmptyMessageWrapper,
  EmptyMessageTitle,
  EmptyMessage,
  EmptyInfoIconWrapper,
  EmptyInfoIcon
} from "./styles";

interface IEmptyCard {
  width?: number;
  size?: number;
  title: string;
  infoIcon?: Array<any>;
  message: string;
  support?: string;
  action?: () => void;
  showSupport?: boolean;
  noBgStyle?: boolean;
  center?: boolean;
  px?: null | number;
  py?: null | number;
}

const EmptyCard = ({
  title,
  width,
  action,
  message,
  support,
  infoIcon,
  size = 32,
  px = null,
  py = null,
  center = false,
  noBgStyle = false,
  showSupport = true
}: IEmptyCard) => {
  return (
    // @ts-ignore
    <SettingsWellEmpty noBgStyle={noBgStyle} px={px} py={py}>
      {infoIcon && infoIcon?.length > 0 ? (
        <EmptyInfoIconWrapper>
          {infoIcon?.map(icon => {
            return <EmptyInfoIcon key={icon} size={size} src={icon} />;
          })}
        </EmptyInfoIconWrapper>
      ) : null}

      {/* @ts-ignore */}
      <EmptyMessageWrapper center={center} width={width}>
        <EmptyMessageTitle>{title}</EmptyMessageTitle>
        {message && <EmptyMessage>{message}</EmptyMessage>}
        {showSupport && <HelperButton onClick={action} label={support} />}
      </EmptyMessageWrapper>
    </SettingsWellEmpty>
  );
};

export default EmptyCard;

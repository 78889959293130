import qs from "qs";
import ReactLoading from "react-loading";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useToaster } from "@hellocontento/maillard";
import React, { useEffect, useCallback } from "react";

import { callApi } from "utils/ContentoApi";
import { useAppState } from "contextApi/appContext";
import * as modalActions from "state/actions/ModalActions";
import * as accountActions from "state/actions/AccountActions";
import { getChannelConnectedRedirectPath } from "utils/channels";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

/**
 * On this page the user will select the specific facebook pages to connect to his contento account
 */
const ConnectFacebookPage = ({
  account,
  fbToken,
  history,
  redirect,
  openModal,
  fetchAccount
}) => {
  const addToast = useToaster();
  const allowedChannels = useAppState(
    state => state.clientSettings.allowedChannels
  );
  const dispatch = useDispatch();

  const connectPages = useCallback(
    async pages => {
      const createChannel = async page => {
        const channelData = {
          service: "facebook",
          serviceType: "page",
          username: page.name,
          slug: page.username ?? "",
          avatar: page.picture ? page.picture.data.url : "empty",
          connection: {
            connectionType: "facebook_page",
            connectionDetails: {
              pageId: page.id,
              accessToken: page.access_token
            }
          }
        };
        return callApi({
          method: "post",
          url: `/accounts/${account.id}/channels`,
          data: channelData
        });
      };

      try {
        const availableChannelSlots = Math.max(
          allowedChannels - account.channels.length,
          0
        );
        const allowedPages = pages.slice(0, availableChannelSlots);
        const pageLocation = history.location.pathname.includes("onboarding")
          ? "onboarding"
          : "settings";
        await Promise.all(
          pages.map(async page => {
            const result = await createChannel(page);
            dispatch(
              trackAnalyticsEvent("Channel Connected", {
                channelType: "facebook_page",
                location: pageLocation
              })
            );
            return result;
          })
        );
        //fetch account with new channels
        fetchAccount(account.id);

        history.push(getChannelConnectedRedirectPath(account, redirect));
      } catch (error) {
        addToast(
          "Something unexpected went wrong. Please refresh the page and try again.",
          "error"
        );
      }
    },
    [account, addToast, fetchAccount, history, redirect, dispatch]
  );

  useEffect(() => {
    callApi({
      url: `/accounts/${account.id}/connect/facebook/pages?token=${fbToken}`
    })
      .then(async pages => {
        if (pages.length === 1) {
          await connectPages(pages);
        } else {
          openModal("SELECT_FACEBOOK_PAGE_MODAL", { pages, connectPages });
        }
      })
      .catch(() => {
        addToast(
          "Something went wrong while trying to fetch your facebook pages. Please try again or contact support.",
          "error"
        );
      });
  }, [account.id, addToast, connectPages, fbToken, openModal]);

  return <ReactLoading color={"#bbb"} type={"cylon"} />;
};

const mapStateToProps = (state, props) => {
  const queryParams = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  });
  return {
    account: state.account.data,
    fbToken: queryParams.token,
    redirect: queryParams.redirect
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchAccount: accountActions.fetchAccount,
    openModal: modalActions.openModal
  })(ConnectFacebookPage)
);

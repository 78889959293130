import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { useToaster } from "@hellocontento/maillard";
import {
  MutedSourceList,
  MutedSourceItem,
  MutedSourceFavicon,
  MutedSourceContent,
  MutedSourceTitle,
  MutedSourceUrl
} from "./styles";
import { Trash } from "@styled-icons/boxicons-regular/Trash";
import { IconButton, Headline2 } from "../common/styles";
import { useContentoApi } from "../../utils/useContentoApi";
import { nanoid } from "nanoid";
import { callApi } from "../../utils/ContentoApi";
import { Box } from "rebass";

function MutedSources({ account }) {
  const [refresh] = useState(false);
  const [accountMuted, setAccountMuted] = useState([]);
  const [fetchAccountMuted, cancelFetchAccountMuted] = useContentoApi(
    `/accounts/${account.id}/news/settings/blocked-domains`
  );
  const addToast = useToaster();

  const refreshMutedSources = useCallback(() => {
    return fetchAccountMuted()
      .then(res => setAccountMuted(res))
      .catch(() => {});
  }, [fetchAccountMuted]);

  useEffect(() => {
    refreshMutedSources();
    return cancelFetchAccountMuted;
  }, [
    cancelFetchAccountMuted,
    fetchAccountMuted,
    refresh,
    refreshMutedSources
  ]);

  async function onDelete(domain) {
    try {
      await callApi({
        method: "delete",
        url: `/accounts/${account.id}/news/settings/blocked-domains`,
        data: {
          domain: domain
        }
      });

      await refreshMutedSources();
      addToast("Source unmuted", "success");
    } catch (_) {
      addToast("Unable to unmute source", "error");
    }
  }

  return (
    <>
      {accountMuted.length > 0 && (
        <Box>
          <Headline2>Sources that are muted</Headline2>
        </Box>
      )}

      <MutedSourceList>
        {accountMuted.map(({ domain, name, image }) => (
          <MutedSourceItem key={nanoid()}>
            <MutedSourceFavicon>
              {image && <img src={image} alt={name} />}
            </MutedSourceFavicon>
            <MutedSourceContent>
              <MutedSourceTitle>{name}</MutedSourceTitle>
              <MutedSourceUrl>{domain}</MutedSourceUrl>
            </MutedSourceContent>
            <IconButton onClick={() => onDelete(domain)}>
              <Trash size={20} />
            </IconButton>
          </MutedSourceItem>
        ))}
      </MutedSourceList>
    </>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {})(MutedSources);

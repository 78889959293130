import store from "state/store";
import { callApi } from "utils/ContentoApi";

export const fetchDashboardSocialHealth = async (
  periodInDays: number | string
): Promise<any> => {
  const account = store.getState().account.data;

  try {
    const params: { [key: string]: any } = {};
    if (periodInDays === "all") {
      params["all"] = true;
    } else {
      params["periodInDays"] = periodInDays;
    }

    const dashboardSocialHealth = await callApi({
      method: "get",
      url: `/accounts/${account.id}/social-health`,
      params
    });

    return dashboardSocialHealth;
  } catch (error) {
    console.error(error);
    throw new Error("Dashboard social health could not be fetched.");
  }
};

export const fetchDashboardStats = async (
  periodInDays: number | string
): Promise<any> => {
  const account = store.getState().account.data;

  try {
    const params: { [key: string]: any } = {};
    if (periodInDays === "all") {
      params["all"] = true;
    } else {
      params["periodInDays"] = periodInDays;
    }

    const dashboardStats = await callApi({
      method: "get",
      url: `/accounts/${account.id}/stats/dashboard`,
      params
    });

    return dashboardStats;
  } catch (error) {
    console.error(error);
    throw new Error("Dashboard stats could not be fetched.");
  }
};

import axios from "axios";

import { callApi, callStreamApi } from "utils/ContentoApi";

const nlpService = axios.create({
  baseURL: "https://willow-nlp-sedhgkt72a-ew.a.run.app"
});

export async function fetchCaptionCompletion(data, callbacks): Promise<void> {
  await callStreamApi(
    {
      method: "POST",
      url: `/nlp/generic-completion-stream`,
      data
    },
    callbacks
  );
}

export async function fetchGeneratedCaptions(data, callbacks): Promise<void> {
  await callStreamApi(
    {
      method: "POST",
      url: `/nlp/generate-captions-stream`,
      data
    },
    callbacks
  );
}

export async function fetchCaptionVariation(data, callbacks): Promise<void> {
  await callStreamApi(
    {
      method: "POST",
      url: `/nlp/create-caption-variation-stream`,
      data
    },
    callbacks
  );
}

// language detection is done on the backend
// not sure what the best way to do this is, because people who have Willow in Dutch might still post in English and vice versa
export async function getImagesForCaption(
  text: string,
  language: string = "en"
): Promise<string[]> {
  const data = await callApi({
    method: "POST",
    url: `/nlp/get-images-for-caption`,
    data: {
      caption: text
    }
  });

  return data.photos;
}

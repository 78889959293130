import React, { useEffect, useState } from "react";
// @ts-nocheck
import { useToaster } from "@hellocontento/maillard";
import { Link } from "react-router-dom";
import ChromeExtensionAuthScreenshotPng from "assets/images/chrome-extension-auth-screenshot.png";
import {
  ChromeExtensionAuthCodeContainer,
  ChromeExtensionAuthScreenshot,
  SettingsDescription,
  SettingsHeader,
  SettingsSection,
  SettingsSubtitle,
  SettingsTitle
} from "./styles";
import { Text } from "rebass/styled-components";
import { Copy } from "@styled-icons/boxicons-regular/Copy";
import { theme } from "theme";
import { callApi } from "utils/ContentoApi";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { willowExtensionFAQLink, willowExtensionLink } from "constants/index";

const ConnectChromeExtension = () => {
  const accountId = useSelector((state: any) => state.account.data.id);
  const userId = useSelector((state: any) => state.auth.currentUser.id);
  const [willowExtensionToken, setWillowExtensionToken] = useState<
    string | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);

  const addToast = useToaster();

  const copyCodeToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
    addToast("Copied to Clipboard!!.", "success");
  };

  useEffect(() => {
    setLoading(true);
    callApi({
      url: `/users/${userId}/accounts/${accountId}/willow-extension/token`
    })
      .then(res => {
        setWillowExtensionToken(res.token);
        setLoading(false);
      })
      .catch(() =>
        addToast(
          "There was an error connecting the channel. Please try again later or contact support.",
          "error"
        )
      );
  }, [accountId]);

  if (loading) {
    return <ReactLoading color="#bbb" type="cylon" />;
  }

  return (
    <>
      <SettingsHeader>
        <SettingsTitle>Connect Willow+ Extension</SettingsTitle>
        <SettingsDescription>
          Follow the steps below to integrate your Willow+ extension. Once
          connected, we’ll begin collecting data on your personal posts to track
          your progress.
        </SettingsDescription>
      </SettingsHeader>

      <SettingsSection>
        <SettingsSubtitle>Step 1</SettingsSubtitle>
        <p>
          Download the extension by visiting the{" "}
          <Link to={{ pathname: willowExtensionLink }} target="_blank">
            Chrome Web Store
          </Link>{" "}
          and returning to complete the authentication.
        </p>
      </SettingsSection>

      <SettingsSection>
        <SettingsSubtitle>Step 2</SettingsSubtitle>
        <p>
          Copy the below activation code. This code is unique to your account
          and will ensure the data is stored in the correct place.
        </p>
        <ChromeExtensionAuthCodeContainer
          onClick={() => copyCodeToClipboard(willowExtensionToken!)}
        >
          <Text>{willowExtensionToken}</Text>
          <Copy color={theme.colors.gray} size={24} />
        </ChromeExtensionAuthCodeContainer>
      </SettingsSection>

      <SettingsSection>
        <SettingsSubtitle>Step 3</SettingsSubtitle>
        <p>
          Paste the code into the Willow+ extension. If you’ve done it
          correctly, you’ll see a success message.
        </p>
        <ChromeExtensionAuthScreenshot src={ChromeExtensionAuthScreenshotPng} />

        <p>
          Having trouble?{" "}
          <Link
            to={{ pathname: willowExtensionFAQLink }}
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            Read our FAQs
          </Link>{" "}
          here Or{" "}
          <Link
            to={`#`}
            onClick={e => {
              e.preventDefault();
              window.location.href = "mailto:support@willow.co";
            }}
            style={{ textDecoration: "underline" }}
          >
            Contact an account manager
          </Link>
        </p>
      </SettingsSection>
    </>
  );
};

export default ConnectChromeExtension;

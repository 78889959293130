import styled from "styled-components";
import { Flex } from "rebass/styled-components";
import { StyledInput } from "../../common/form/styles";

export const TextInputContainer = styled.div`
  display: flex;
  align-items: "center";
`;

export const Chips = styled(Flex)`
  margin-top: 16px;
  margin-bottom: 36px;
  flex-wrap: wrap;
`;

export const Chip = styled(Flex)`
  border-radius: 4px;
  align-items: center;
  background: ${props => props.theme.colors.text01};
  color: ${props => props.theme.colors.text05};
  padding: 4px 4px 4px 12px;
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const RemoveChip = styled(Flex)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  &:hover {
    border-radius: 2px;
    background: rgba(235, 235, 245, 0.1);
  }
`;

export const TextInput = styled(StyledInput)`
  width: 100%;
  margin-top: 0px;
`;

import styled from "styled-components";
import React, { useEffect, useRef } from "react";

import { Headline2 } from "components/common/styles";
import poster from "assets/images/milli-popup/testimonial-video-poster.png";

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 56px;
  margin: 0 -56px;
  gap: 20px;

  * {
    box-sizing: border-box;
  }

  ${Headline2} {
    margin: 0;
  }
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.shadowS};
  border-radius: 16px;
  overflow: hidden;
`;

export const Video = styled.video`
  width: 100%;
  aspect-ratio: 16/9;

  &:focus {
    outline: transparent;
  }
`;

const videoUrl = `https://storage.googleapis.com/hellocontento-prod-media/static/Testimonial-Fien-EnjoyJobs.mp4`;

const TestimonialVideo: React.FC<{}> = () => {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);

  return (
    <Wrapper>
      <Headline2>Still not convinced?</Headline2>
      <Card>
        <Video ref={ref} poster={poster} controls autoPlay>
          <source src={videoUrl} type="video/mp4" />
        </Video>
      </Card>
    </Wrapper>
  );
};

export default TestimonialVideo;

import React from "react";
import { PostActionsWrapper } from "./styles";

import TASK_TYPES from "../constants/taskType";
import IconButton from "components/common/IconButton";
import { useComposerActions } from "contextApi/composerContext";

const CalendarEntryActions = ({ visible, entry }) => {
  const openPost = useComposerActions(actions => actions.openPost);
  const editTask = useComposerActions(actions => actions.editTask);
  const deleteEntry = useComposerActions(actions => actions.deleteEntry);

  if (!visible) return null;

  const handleDelete = e => {
    e.stopPropagation();
    deleteEntry(entry);
  };

  const handleEdit = e => {
    e.stopPropagation();

    if (entry.task) {
      editTask(entry.task, TASK_TYPES.INSTANCE);
    } else {
      openPost(entry.post);
    }
  };

  return (
    <PostActionsWrapper>
      {entry.task && (
        <IconButton
          variant={"secondaryLoud"}
          size={28}
          iconSize={14}
          onClick={handleEdit}
          icon={"icon-edit"}
        />
      )}
      <IconButton
        variant={"secondaryLoud"}
        size={28}
        iconSize={14}
        onClick={handleDelete}
        icon={"icon-delete"}
      />
    </PostActionsWrapper>
  );
};

export default CalendarEntryActions;

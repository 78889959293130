import React, { useRef, useEffect, useState } from "react";

import { MentionSuggestionEntryWrapper } from "./styles";

const MentionSuggestionEntry = ({
  index,
  mention,
  isFocused,
  onMentionFocus,
  onMentionSelect
}) => {
  const ref = useRef(null);
  const mouseDown = useRef(false);
  const [isImageBroken, setIsImageBroken] = useState(false);
  const { text, image, description } = mention;

  useEffect(() => {
    if (isFocused) {
      requestAnimationFrame(() =>
        ref.current?.scrollIntoView({ behavior: "smooth", block: "nearest" })
      );
    }
  }, [isFocused]);

  useEffect(() => {
    mouseDown.current = false;
  });

  const onImageError = () => {
    setIsImageBroken(true);
  };

  const onMouseUp = () => {
    if (mouseDown.current) {
      onMentionSelect(mention);
      mouseDown.current = false;
    }
  };

  const onMouseDown = event => {
    event.preventDefault();

    mouseDown.current = true;
  };

  const onMouseEnter = () => {
    onMentionFocus(index);
  };

  return (
    <MentionSuggestionEntryWrapper
      ref={ref}
      isFocused={isFocused}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
    >
      <div className="avatar">
        {!!image && !isImageBroken && (
          <img src={image} alt={text} onError={onImageError} />
        )}
      </div>
      <div className="title-section">
        <div className="title">{text}</div>
        <div className={`subtitle ${!description && "emphasize"}`}>
          {description ? description : "No description available"}
        </div>
      </div>
    </MentionSuggestionEntryWrapper>
  );
};

export default MentionSuggestionEntry;

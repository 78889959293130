import React from "react";
import styled from "styled-components";

import {
  IAttachment,
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import { ATTACHMENT_TYPES } from "constants/post";
import { useAppState } from "contextApi/appContext";
import IconButton from "components/common/IconButton";
import { AttachmentToolbar } from "components/composerv2/styles";
import { RoundedToolTip } from "components/schedule/common/styles";

const NewTag = styled.span`
  display: flex;
  position: absolute;
  top: -15px;
  left: -5px;
  color: ${props => props.theme.colors.primary};
  font-size: 10px;
  font-weight: 500;
`;

interface IAttachmentTools {
  size?: number;
  iconSize?: number;
  showBorder?: boolean;
}

const AttachmentTools: React.FC<IAttachmentTools> = ({
  size,
  iconSize,
  showBorder = false
}) => {
  const showPdfAttachment = useAppState(state => state.showPdfAttachment);
  const attachments = useComposerState(state => state.attachments);
  const setAttachmentType = useComposerActions(
    actions => actions.setAttachmentType
  );

  const { type } = attachments["all"];

  const changeAttachmentType = (attachmentType: IAttachment["type"]) => {
    if (type !== attachmentType) {
      // ? add channel as 2nd argument if attachments are added separately for each channel
      setAttachmentType(attachmentType);
    } else {
      // ? add channel as 2nd argument if attachments are added separately for each channel
      setAttachmentType(null);
    }
  };

  return (
    // @ts-ignore
    <AttachmentToolbar $showBorder={showBorder}>
      <IconButton
        // @ts-ignore
        size={size || 40}
        iconSize={iconSize || 24}
        icon={type === "photo" ? "icon-image-filled" : "icon-image"}
        variant="secondaryLoud"
        onClick={() => changeAttachmentType("photo")}
        data-tip="Add image"
        data-for="image-tooltip"
      />
      <RoundedToolTip id="image-tooltip" place="bottom" />
      <IconButton
        // @ts-ignore
        size={size || 40}
        iconSize={iconSize || 24}
        icon={
          type === ATTACHMENT_TYPES.VIDEO ? "icon-video-filled" : "icon-video"
        }
        variant="secondaryLoud"
        onClick={() => changeAttachmentType("video")}
        data-tip="Add video"
        data-for="video-tooltip"
      />
      <RoundedToolTip id="video-tooltip" place="bottom" />
      {showPdfAttachment && (
        <>
          <span style={{ position: "relative" }}>
            <IconButton
              // @ts-ignore
              size={size || 40}
              iconSize={iconSize || 24}
              icon={
                type === ATTACHMENT_TYPES.PDF ? "icon-pdf-filled" : "icon-pdf"
              }
              variant="secondaryLoud"
              onClick={() => changeAttachmentType("pdf")}
              data-tip="Add pdf"
              data-for="pdf-tooltip"
            />
            {/* <NewTag>
              <i className="icon-sparkle" /> New!
            </NewTag> */}
          </span>
          <RoundedToolTip id="pdf-tooltip" place="bottom" />
        </>
      )}
    </AttachmentToolbar>
  );
};

export default AttachmentTools;

import { connect } from "react-redux";
import React, { useState } from "react";
import styled from "styled-components";

import { ITopic } from "services/news";
import { Check, Plus, X } from "styled-icons/boxicons-regular";
import { theme } from "theme";

import { follow, unfollow } from "services/news";

const TopicButton = styled.button<{ checked?: boolean; hovered: boolean }>`
  align-items: center;
  appearance: none;
  background: ${props =>
    props.checked
      ? props.hovered
        ? props.theme.colors.rebSubtle
        : "white"
      : props.hovered
        ? props.theme.colors.grey02
        : "white"};
  border: none;
  box-shadow: none;
  border-radius: 24px;
  color: ${props =>
    props.checked
      ? "white"
      : props.hovered
        ? props.theme.colors.text01
        : props.theme.colors.text02};
  cursor: pointer;
  height: 36px;
  width: 36px;
`;

interface ISubscribeButton {
  data: ITopic;
  sections: Array<any>;
}

const TopicSubscribeButton: React.FC<ISubscribeButton> = ({
  data,
  sections
}) => {
  const [isToggleHovered, setToggleHovered] = useState<boolean>(false);

  const topics =
    sections.find((section: any) => section.type === "topics")?.details ?? [];

  const isTopicFollowed = !!topics.find(
    (topic: ITopic) => topic.id === data.id
  );

  const toggleTopic = async (id: string) => {
    try {
      if (isTopicFollowed) {
        await unfollow("topics", id);
      } else {
        await follow("topics", id);
      }
    } catch (_) {}
  };

  return (
    <TopicButton
      onMouseEnter={() => setToggleHovered(true)}
      onMouseLeave={() => setToggleHovered(false)}
      onClick={e => {
        e.preventDefault();
        toggleTopic(data.id);
      }}
      hovered={isToggleHovered}
      checked={isTopicFollowed}
    >
      {!isTopicFollowed ? (
        <Plus size={21} />
      ) : isToggleHovered ? (
        <X color={theme.colors.red} size={24} />
      ) : (
        <Check color={theme.colors.text01} size={24} />
      )}
    </TopicButton>
  );
};

const mapStateToProps = (state: any) => {
  return {
    sections: state.content.sections
  };
};

export default connect(mapStateToProps, {})(TopicSubscribeButton);

export const FETCH_SCHEDULED_POSTS = "@@scheduledPost/FETCH_SCHEDULED_POSTS";
export const SCHEDULED_POSTS_FETCHED =
  "@@scheduledPost/SCHEDULED_POSTS_FETCHED";
export const SCHEDULE_POST = "@@scheduledPost/SCHEDULE_POST";

export const fetchScheduledPosts = () => {
  return {
    type: FETCH_SCHEDULED_POSTS
  };
};

export const scheduledPostsFetched = scheduledPosts => {
  return {
    type: SCHEDULED_POSTS_FETCHED,
    scheduledPosts
  };
};

export const schedulePost = ({ post, time, channels }) => {
  return {
    type: SCHEDULE_POST,
    post,
    time,
    channels
  };
};

import * as accountActions from "../actions/AccountActions";

const initialState = {
  isUpdating: false,
  isFetching: false,
  data: null,
  error: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountActions.FETCH_ACCOUNT:
      return Object.assign({}, state, {
        isFetching: true,
        data: null
      });
    case accountActions.FETCH_ACCOUNT_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error.message
      });
    case accountActions.ACCOUNT_FETCHED:
      return Object.assign({}, state, {
        isFetching: false,
        data: action.account
      });
    case accountActions.UPDATE_ACCOUNT:
      return Object.assign({}, state, {
        isUpdating: true
      });
    case accountActions.UPDATE_ACCOUNT_ABOUT_ONBOARDING:
      return Object.assign({}, state, {
        data: action.values
      });
    case accountActions.ACCOUNT_UPDATED:
      return Object.assign({}, state, {
        isUpdating: false,
        data: action.account
      });
    case accountActions.CHARGEBEE_PORTAL:
      return Object.assign({}, state, {
        isUpdating: false,
        data: action.portal
      });
    default:
      return state;
  }
};

export default accountReducer;

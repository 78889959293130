import styled, { css, keyframes } from "styled-components";

export const LoaderWrapper = styled.div`
  > * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: row;
  padding: 8px;
  width: 100%;
  height: 56px;
  background: ${props => props.theme.colors.white};
  border-radius: 10px;
  box-sizing: border-box;
  transition: unset;
`;

const keyframesShimmer = keyframes`
  0% {
    background-position: -80vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
`;

const shimmerAnimation = css`
  background: linear-gradient(
    to right,
    ${props => props.theme.colors.grey01} 4%,
    ${props => props.theme.colors.grey04} 25%,
    ${props => props.theme.colors.grey01} 36%
  );
  background-size: 80vw 100%;
  animation: ${keyframesShimmer} 2s infinite linear;
`;

export const AvatarLoader = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 10px;
  display: flex;
  background: ${props => props.theme.colors.grey02};
  ${shimmerAnimation}
`;

export const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

export const TitleLoader = styled.div`
  height: 14px;
  max-width: 64px;
  border-radius: 4px;
  margin: 4px 0px;
  background: ${props => props.theme.colors.grey02};
  position: relative;
  ${shimmerAnimation}
`;

export const SubtitleLoader = styled.div`
  width: 100%;
  height: 12px;
  border-radius: 4px;
  background: ${props => props.theme.colors.grey02};
  position: relative;
  ${shimmerAnimation}
`;

import React from "react";
import { connect } from "react-redux";
import { openModal, closeModal } from "../../../state/actions/ModalActions";
import { PostDetailModal } from "./PostDetailModal";

const createComposerModal = ({ openModal, closeModal, ...props }) => {
  return (
    <PostDetailModal
      title={"Post Details"}
      onClose={closeModal}
    ></PostDetailModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  closeModal: closeModal,
  openModal: openModal
})(createComposerModal);

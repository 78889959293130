import styled from "styled-components";
import React, { useState } from "react";

const Stack = styled.div`
  display: flex;

  > div:not(:first-child) {
    margin-left: -10px;
    z-index: 9;
  }
`;

export const Avatar = styled.div`
  width: ${({ size }) => (!!size ? size : 28)}px;
  height: ${({ size }) => (!!size ? size : 28)}px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  background: url("/managers/none.png");
  background-size: contain;
  border: 1px solid ${props => props.theme.colors.white};

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
  }
`;

const StackedAvatars = ({ avatars, size }) => {
  const [isImageBroken, setIsImageBroken] = useState(false);

  const onImageError = () => {
    setIsImageBroken(true);
  };

  return (
    <Stack>
      {avatars.map((avatar, index) => (
        <Avatar key={index} size={size}>
          {!!avatar && !isImageBroken && (
            <img
              src={avatar}
              onError={onImageError}
              alt={"Stacked Social Avatars"}
            />
          )}
        </Avatar>
      ))}
    </Stack>
  );
};

export default StackedAvatars;

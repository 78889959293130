export default function findCaptionEntities(
  contentBlock,
  callback,
  contentState
) {
  //Trigger if it has an entity and it is one of the expected types.
  contentBlock.findEntityRanges(character => {
    const entityTypes = ["GENERATED_CAPTION"];
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      entityTypes.indexOf(contentState.getEntity(entityKey).getType()) !== -1
    );
  }, callback);
}

import * as layoutActions from "../actions/LayoutActions";

const initialState = {
  collapsed: window.matchMedia("(max-width: 1024px)").matches
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case layoutActions.EXPAND_MENU:
      return {
        collapsed: false
      };
    case layoutActions.COLLAPSE_MENU:
      return {
        collapsed: true
      };
    default:
      return state;
  }
};

export default layoutReducer;

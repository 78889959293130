import { Box, Flex } from "rebass";
import styled from "styled-components";
import queryString from "query-string";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import Popper from "components/common/Popper";
import { useToaster } from "@hellocontento/maillard";
import React, { useRef, useState, useCallback, useEffect } from "react";
import { getHours, getMinutes, parse, set, format, addHours } from "date-fns";

import { SERVICES } from "constants/services";
import { getRandomMinutes, num2time } from "utils/date";
import { fetchBestTimes } from "../services/taskServices";

import Rating from "../form/Rating";
import CustomTime from "../form/CustomTime";
import ScheduleButton from "../form/ScheduleButton";

import Divider from "components/common/Divider";
import { NativeLink } from "components/common/Link";
import { MenuContainer, MenuItem, MenuList } from "../../navigation/Menu";

const StyledTimeEntry = styled(Box)`
  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 64px 1fr;
`;

const MenuListWrapper = styled.div`
  height: 200px;
  overflow-y: scroll;
`;

const TimeInfo = styled(Box)`
  padding: 10px 20px;
  color: ${props => props.theme.colors.text03};
`;

const ReactLoader = styled(ReactLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const TimeEntry = ({ time, relevance }) => (
  <StyledTimeEntry>
    <Rating rating={relevance} />
    <span>{time}</span>
  </StyledTimeEntry>
);

const CustomTimeButton = styled(Flex)`
  gap: 14px;
  display: flex;
  align-items: center;
  font-weight: 600;

  span {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }
`;

const TIME_FORMAT = "HH:mm";

function TimeSelect({
  date,
  channels,
  onChange,
  settings,
  showInfo = true,
  selectedChannels
}) {
  const isRepeating = !!settings;

  const account = useSelector(state => state.account.data);

  const addToast = useToaster();

  const [times, setTimes] = useState([]);
  const [isFetching, setIsFetching] = useState(true);

  const [showCustomTimeInput, setShowCustomTimeInput] = useState(false);
  const toggleCustomTimeInput = () => {
    setShowCustomTimeInput(!showCustomTimeInput);
  };

  const referenceElement = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  const handleSelectTime = ({ time, custom = false }) => {
    let scheduleTime = time.split(" - ")[0];

    const parsedTime = parse(scheduleTime, TIME_FORMAT, date);
    const hours = getHours(parsedTime);
    const minutes = custom ? getMinutes(parsedTime) : getRandomMinutes();
    const update = set(date, {
      hours,
      minutes
    }).toISOString();

    if (isRepeating) {
      onChange({
        dateStart: update,
        settings: {
          ...settings,
          lockTime: custom
        }
      });
    } else {
      onChange({
        date: update
      });
    }
  };

  const getServices = useCallback(() => {
    const uniqueActiveChannels = channels.reduce(
      (acc, val) => {
        if (val.active && selectedChannels.includes(val.id)) {
          acc.add(val.service);
        }
        return acc;
      },
      new Set([SERVICES.LINKEDIN])
    );

    return queryString.stringify({ service: [...uniqueActiveChannels] });
  }, [channels, selectedChannels]);

  const day = format(date, "EEEE");

  const getDayOfWeek = useCallback(() => {
    if (
      !isRepeating ||
      !settings.daysOfTheWeek ||
      settings.daysOfTheWeek?.length < 1
    ) {
      return queryString.stringify({ dayOfTheWeek: day.toUpperCase() });
    } else {
      return queryString.stringify({ dayOfTheWeek: settings.daysOfTheWeek });
    }
  }, [settings, isRepeating, day]);

  const fetchFavourableTime = useCallback(async () => {
    setIsFetching(true);

    const serviceQry = getServices();
    const dayQry = getDayOfWeek();

    try {
      const data = await fetchBestTimes(account.id, `${serviceQry}&${dayQry}`);

      const bestTimes = data.map(({ hour, score }) => {
        const range = `${num2time(parseInt(hour))} - ${num2time(
          parseInt(hour + 1)
        )}`;

        return {
          value: hour,
          lower: hour,
          upper: hour + 1,
          time: range,
          relevance: Math.ceil(score / 25) / 4
        };
      });

      setTimes(bestTimes);
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      addToast("Something went wrong fetching best times.", "error");
    }
  }, [addToast, getServices, getDayOfWeek, account.id]);

  useEffect(() => {
    fetchFavourableTime();
  }, [fetchFavourableTime]);

  // cleanup
  useEffect(() => {
    return () => {
      setShowCustomTimeInput(false);
    };
  }, []);

  /*
  Calculate label and relevance

  not repeating: show actual time + relevance
  is repeating: show range based on dateStart + relevance
        when dateStart is 11:00 then the range is 11:00 - 12:00
  is repeating + lockTime: show actual time + relevance
   */
  const time = format(date, "HH:mm");
  let timeLabel = time;
  let timeRelevance = null;
  if (times.length > 0) {
    const customHour = parseInt(time.split(":")[0]);
    timeRelevance = times.find(time => time.lower === customHour)?.relevance;
  }

  if (isRepeating && !settings.lockTime) {
    const rangeEnd = addHours(date, 1);
    const hoursStart = format(date, "HH");
    const hoursEnd = format(rangeEnd, "HH");
    timeLabel = `${hoursStart}:00 - ${hoursEnd}:00`;
  }

  return (
    <Box ref={referenceElement}>
      <ScheduleButton icon={"icon-clock"} onClick={toggleShowOptions}>
        <Flex flexDirection="row" alignItems="center">
          <Box>{timeLabel}</Box>
          {timeRelevance > 0 ? (
            <Box marginLeft={1}>
              <Rating rating={timeRelevance} />
            </Box>
          ) : null}
        </Flex>
      </ScheduleButton>

      <Popper
        offset={[-120, 0]}
        referenceElement={referenceElement.current}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[referenceElement.current]}
      >
        <MenuContainer width={284}>
          {showCustomTimeInput ? (
            <CustomTime
              toggle={toggleCustomTimeInput}
              save={handleSelectTime}
              close={() => setShowOptions(false)}
            />
          ) : (
            <>
              {showInfo && (
                <>
                  <TimeInfo>
                    Willow will pick a random time in your preferred timeslot to
                    optimize reach.{" "}
                    <NativeLink
                      href="https://support.willow.co/knowledge/add-tasks"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Learn more
                    </NativeLink>
                  </TimeInfo>
                  <Divider my={2} />
                </>
              )}
              <MenuList>
                <MenuListWrapper>
                  {isFetching ? (
                    <ReactLoader color="#bbb" type="cylon" />
                  ) : (
                    times.map(item => (
                      <MenuItem
                        key={item.time}
                        label={
                          <TimeEntry
                            time={item.time}
                            relevance={item.relevance}
                          />
                        }
                        onClick={() => {
                          handleSelectTime(item);
                          setShowOptions(false);
                        }}
                      />
                    ))
                  )}
                </MenuListWrapper>
                <Divider my={2} mx={-7} />
                <MenuItem
                  variant="primary"
                  label={
                    <CustomTimeButton>
                      <span className="icon-plus"></span>
                      Add custom time
                    </CustomTimeButton>
                  }
                  onClick={toggleCustomTimeInput}
                />
              </MenuList>
            </>
          )}
        </MenuContainer>
      </Popper>
    </Box>
  );
}

export default TimeSelect;

import React from "react";
import { ErrorFigure, FigurePadding, FigureContent } from "./styles";
import illustration from "../../assets/images/illustration-maintenance.svg";

function ErrorIllustration() {
  return (
    <ErrorFigure>
      <FigurePadding></FigurePadding>
      <FigureContent>
        <img src={illustration} alt="Error" />
      </FigureContent>
      <FigurePadding></FigurePadding>
    </ErrorFigure>
  );
}

export default ErrorIllustration;

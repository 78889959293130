import styled from "styled-components";

export const TimelineLayout = styled.div`
  box-sizing: border-box;
  display: flex;
  position: relative;
  justify-content: center;
  height: calc(100vh - 79px);
  background-color: ${props => props.theme.colors.background2};
`;

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  margin-top: 64px;
  height: calc(100% - 64px);
  overflow: hidden;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    margin-top: unset;
    height: calc(100% - 10px);
    overflow-x: auto;
  }
`;

export const TimelineContentLayout = styled.div`
  box-sizing: border-box;
  gap: 30px;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.lg}) {
    max-width: 768px;
    margin: 0 auto 0;
  }

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    justify-content: center;
  }
`;

export const TimelineHeader = styled.div`
  box-sizing: border-box;
  top: 64px;
  z-index: 8;
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  padding: 14px 0px;
  box-shadow: ${props => props.theme.shadows.shadowXS};
  background-color: ${props => props.theme.colors.white};

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    display: none;
  }
`;

export const MenuWrapper = styled.div`
  box-sizing: border-box;
  width: 244px;
  display: flex;
  margin-top: 34px;
  margin-right: 34px;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.lg}) {
    display: none; /* Remove when making the menu responsive */
    flex-direction: row;
  }
`;

export const TimelineWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  width: 572px;
  display: flex;
  position: relative;
  justify-content: center;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    width: 100vw;
    max-width: 572px;
    min-width: 320px;
  }
`;

export const CalendarWrapper = styled.div`
  box-sizing: border-box;
  width: 248px;
  display: flex;
  margin-top: 34px;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    display: none;
  }
`;

import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Flex, Box } from "rebass/styled-components";
import React, { useCallback, useMemo, useRef, useState } from "react";

import {
  Small,
  Avatar,
  Service,
  AvatarWrapper,
  ChannelAvatar
} from "../common/styles";
import Popper from "components/common/Popper";
import FallbackImage from "../common/FallbackImage";
import { Plus } from "styled-icons/boxicons-regular";
import { connectChannel } from "../../utils/channels";
import { Check } from "styled-icons/boxicons-regular";
import { ChevronSmallRight } from "styled-icons/entypo";
import emptyProfileIcon from "../../assets/images/profile-icon.png";
import DefaultAvatar from "assets/images/dashboard/avatar-empty-state.png";
import ContentType from "components/common/contentTypes/data/content-types";

export const MenuList = styled(Flex)<{ $offset?: any[]; $maxHeight?: boolean|number }>`
  flex-direction: column;
  overflow-y: ${props => (props.$maxHeight ? "auto" : "unset")};
  position: relative;

  ${props =>
    props.$offset &&
    css`
      position: absolute;
      top: ${props.$offset[0]};
      left: ${props.$offset[1]};
    `}
`;

export const MenuItemContainer = styled.div`
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
`;

export const StyledMenuItem = styled.button<{
  $variant?: string;
  $hasImage?: boolean;
  $hasIcon?: boolean;
  $selected?: boolean;
  $hasArrow?: boolean;
}>`
  align-items: flex-start;
  width: 100%;
  appearance: none;
  background: transparent;
  border-radius: 12px;
  border: none;
  box-shadow: none;
  color: ${props =>
    props.color
      ? props.theme.colors[props.color]
      : props.$variant
        ? props.theme.variants[props.$variant].fontColor
        : props.theme.colors.text01};
  cursor: pointer;
  display: grid;
  font-size: 14px;
  font-family: ${props => props.theme.fonts.body};
  grid-column-gap: ${props => (props.$hasImage ? "10px" : "8px")};
  grid-template-columns: ${props =>
      props.$hasImage ? "24px" : props.$hasIcon ? "20px" : ""} 1fr ${props =>
      (props.$selected || props.$hasArrow) && `20px`};
  outline: none;
  padding: 10px 14px;

  ${props =>
    props.$selected &&
    css`
      background: ${props => props.theme.colors.grey01};
    `}

  &:hover {
    background-color: ${props => props.theme.colors.primary};
    color: white;

    & span,
    & svg {
      color: white;
    }

    & small {
      color: white;
    }
  }
`;

export const MenuEmptyState = styled(StyledMenuItem)`
  &:hover {
    color: inherit;
    background-color: inherit;
  }
`;

const MenuCheckState = styled(Check)<{ $checked?: boolean }>`
  color: ${props => props.theme.colors.blue};
  visibility: ${props => (props.$checked ? "visible" : "hidden")};
  transform: translateX(-6px);
`;

const StyledMenuLink = styled(StyledMenuItem)`
  color: ${props => props.theme.colors.primary};
`;

StyledMenuLink.defaultProps = {
  $hasIcon: true
};

export const MenuDivider = styled(Box)`
  flex: 1;
  border-bottom: ${props => `1px solid ${props.theme.colors.grey02}`};
  margin: 8px 0;
`;

const StyledMenuChannel = styled(StyledMenuItem)`
  box-sizing: border-box;
  grid-template-columns: 24px 1fr 100px;
  height: 44px;
  padding: 10px 12px;
  grid-column-gap: 8px;

  &:hover {
    background-color: transparent;
    color: inherit;
    cursor: default;

    & span {
      color: ${props => props.theme.colors.text01};
    }
  }
`;

const StyledMenuGrid = styled(StyledMenuItem)`
  box-sizing: border-box;
  grid-template-columns: 44px 1fr;
  padding: 10px 12px;
  grid-column-gap: 12px;
  width: 100%;

  &:hover * {
    color: white;
  }
`;

export const MenuContainer = styled(Box)`
  background: white;
  border-radius: 16px;
  box-shadow:
    inset 0 0 0 1px ${props => props.theme.colors.grey02},
    0px 30px 60px -30px rgba(0, 0, 0, 0.15),
    0px 50px 100px -20px rgba(0, 0, 0, 0.2),
    0px 0px 1px rgba(0, 0, 0, 0.08);
  padding: 8px 0;
  overflow: hidden;
  transition: 0.25s height;
  width: ${props => (props.width ? props.width + "px" : "max-content")};
`;

export const MenuHeader = styled.h3`
  align-items: center;
  box-sizing: border-box;
  color: ${props => props.theme.colors.text03};
  display: flex;
  font-weight: bold;
  font-size: 14px;
  height: 40px;
  letter-spacing: -0.12px;
  line-height: 18px;
  padding: 8px 12px;
`;

export const MenuFooter = styled(Flex)`
  border-top: 1px solid ${props => props.theme.colors.grey02};
  justify-content: space-between;
  margin-top: 8px;
  padding: 12px 16px 4px;
`;

export const MenuThumbnail = styled.figure`
  background-color: white;
  border-radius: 24px;
  height: 44px;
  overflow: hidden;
  position: relative;
  width: 44px;

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  &::after {
    box-shadow: inset 0 0 0 1px ${props => props.theme.colors.grey03};
    border-radius: 90px;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

export const MenuTitle = styled.h5`
  color: ${props => props.theme.colors.text01};
  font-weight: 700;
  margin-bottom: 4px;
`;

export const MenuDescription = styled.span`
  color: ${props => props.theme.colors.text04};
  display: block;
  font-size: 14px;
`;

const Icon = styled.span`
  font-size: 20px;
  opacity: 0.8;
  text-align: center;
  width: 20px;
`;

const Info = styled.span<{ $info?: string }>`
  width: 8px;
  height: 8px;
  margin: auto;
  border-radius: 50%;
  ${props =>
    props.$info &&
    css`
      background: ${ContentType[props.$info].color};
    `};

  border: 1px solid ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.shadowS};
`;

const Label = styled.div<{ $toReconnect?: boolean; $link?: string | boolean }>`
  color: ${props =>
    props.$toReconnect
      ? props.theme.colors.red
      : props.$link === "link"
        ? props.theme.colors.primary
        : "inherit"};
  font-weight: ${props => (props.$link ? "700" : "500")};
  height: 20px;
  letter-spacing: -0.16px;
  line-height: 18px;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
`;

const ReconnectLink = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.12px;
  line-height: 14px;
  padding: 8px 12px;

  &:hover {
    text-decoration: underline;
  }
`;

const MenuIcon = React.memo(({ icon }: { icon: any }) => {
  return typeof icon === "string" ? (
    <Icon className={`icon-${icon}`} />
  ) : (
    icon && <Icon as={icon} size={20} />
  );
});

interface MenuItemProps {
  icon?: any;
  info?: string;
  image?: string;
  value?: any;
  color?: string;
  variant?: string;
  selected?: any;
  hasArrow?: boolean;
  description?: string;
  subMenuItems?: any[];
  label?: string;
  trigger?: string;
  onClick?: (val?: any) => void;
}

export const MenuItem = React.memo(
  ({
    icon,
    info,
    image,
    value,
    color,
    variant,
    selected,
    hasArrow,
    description,
    subMenuItems,
    label = "Label",
    trigger = "click",
    onClick = () => {}
  }: MenuItemProps) => {
    const ref = useRef(null);
    const [showSubMenu, setShowSubMenu] = useState(false);

    const isSelected = useMemo(
      () =>
        !selected
          ? false
          : Array.isArray(selected)
            ? selected.includes(value)
            : selected === value,
      [selected, value]
    );

    const onClickHandler = useCallback(
      e => {
        e.stopPropagation();

        onClick(value);
      },
      [onClick, value]
    );

    const conditionalProps = useMemo(() => {
      if (trigger === "mouseup") {
        return { onMouseUp: onClickHandler };
      } else {
        return {
          onClick: onClickHandler
        };
      }
    }, [onClickHandler, trigger]);

    return (
      <MenuItemContainer
        ref={ref}
        onMouseOver={() => setShowSubMenu(true)}
        onMouseLeave={() => setShowSubMenu(false)}
      >
        <StyledMenuItem
          color={color}
          $hasIcon={!!icon}
          $variant={variant}
          $hasImage={!!image}
          $hasArrow={hasArrow}
          $selected={isSelected}
          {...conditionalProps}
        >
          {icon && <MenuIcon icon={icon} />}

          {image && (
            <Avatar
              src={image ?? DefaultAvatar}
              width={24}
              height={24}
              $isRounded={true}
              backgroundColor="white"
              onError={(event: any) => {
                event.target.src = DefaultAvatar;
                event.onerror = null;
              }}
            />
          )}
          <Flex alignItems={"flex-start"} justifyContent={"space-between"}>
            <Flex flexDirection={"column"} alignItems={"flex-start"}>
              <Label>
                {info && <Info $info={info}></Info>}
                {label}
              </Label>
              {description && <Small>{description}</Small>}
            </Flex>
          </Flex>
          {isSelected && <MenuCheckState size={20} $checked={true} />}
          {!isSelected && hasArrow && (
            <ChevronSmallRight size={20} opacity={0.8} />
          )}
          {!!subMenuItems && subMenuItems.length > 0 && (
            <Popper
              offset={[-8, 0]}
              placement="right-start"
              referenceElement={ref.current}
              visible={showSubMenu}
              onClose={() => setShowSubMenu(false)}
              exceptions={[ref.current]}
            >
              <MenuContainer minWidth={284}>
                <MenuList>
                  {subMenuItems.map(subItem => (
                    <MenuItem
                      icon={subItem.icon}
                      info={subItem.info}
                      key={subItem.label}
                      selected={selected}
                      value={subItem.value}
                      label={subItem.label}
                      description={subItem.description}
                      onClick={() => {
                        onClick({
                          contentTypeId: value,
                          postIdea: subItem.data
                        });
                      }}
                      trigger="mouseup"
                    />
                  ))}
                </MenuList>
              </MenuContainer>
            </Popper>
          )}
        </StyledMenuItem>
      </MenuItemContainer>
    );
  }
);

interface IMenuLinkProps {
  label?: string;
  onClick?: () => void;
}

export const MenuLink = React.memo(
  ({ label = "Label", onClick = () => {} }: IMenuLinkProps) => (
    <StyledMenuLink onClick={onClick}>
      <Plus size={20} />
      <Label $link>{label}</Label>
    </StyledMenuLink>
  )
);

interface IMenuAccountItemProps {
  title?: string;
  thumbnail?: string;
  description?: string;
  onClick: () => void;
}

export const MenuAccountItem = React.memo(
  ({
    title,
    thumbnail,
    description,
    onClick = () => {}
  }: IMenuAccountItemProps) => (
    <StyledMenuGrid onClick={onClick}>
      <MenuThumbnail>
        <FallbackImage
          alt={title}
          src={thumbnail}
          fallbackSrc={emptyProfileIcon}
        />
      </MenuThumbnail>
      <Flex flexDirection="column" alignItems="flex-start">
        <MenuTitle>{title}</MenuTitle>
        {description && <MenuDescription>{description}</MenuDescription>}
      </Flex>
    </StyledMenuGrid>
  )
);

interface IMenuChannelItemProps {
  account?: any;
  channel?: any;
  label?: string;
  toReconnect?: boolean;
}

export const MenuChannelItem = React.memo(
  ({
    account,
    channel = null,
    label = "username",
    toReconnect
  }: IMenuChannelItemProps) => {
    const handleChannelConnect = useCallback(
      e => {
        e.preventDefault();

        const service = `${channel.service}_${channel.serviceType}`;
        connectChannel(account, service);
      },
      [account, channel.service, channel.serviceType]
    );

    return (
      <StyledMenuChannel
        as={!toReconnect ? "button" : "div"}
        disabled={toReconnect}
      >
        <ChannelAvatar
          mb={-2}
          key={channel.id}
          $enabled={true}
          $clickable={false}
        >
          <AvatarWrapper $height={24}>
            <Avatar
              src={channel.avatar || DefaultAvatar}
              $isRounded={true}
              $noShadow
              onError={e => {
                e.currentTarget.onerror = null;
                e.currentTarget.src = DefaultAvatar;
              }}
            />
          </AvatarWrapper>
          <Service
            key={channel.id}
            title={channel.username}
            $type={channel.service}
            $size={16}
          />
        </ChannelAvatar>
        <Label $toReconnect={toReconnect}>{label}</Label>
        {toReconnect && (
          <ReconnectLink onClick={handleChannelConnect} to="#">
            Reconnect
          </ReconnectLink>
        )}
      </StyledMenuChannel>
    );
  }
);

interface IMenuItem<T> {
  icon: any;
  info?: string;
  image?: string;
  value: T;
  description?: string;
  subMenuItems?: any[];
  label?: string;
}

interface IMenuProps<T = any> {
  items: IMenuItem<T>[];
  selected?: T;
  onSelect?: (val: T) => void;
  hasArrow?: boolean;
}

const Menu = React.memo(
  ({ items, selected = null, onSelect, hasArrow }: IMenuProps) => (
    <MenuList>
      {items.map(item => (
        <MenuItem
          icon={item.icon}
          info={item.info}
          key={item.label}
          selected={selected}
          value={item.value}
          label={item.label}
          description={item.description}
          onClick={onSelect}
          subMenuItems={item.subMenuItems}
          hasArrow={hasArrow}
        />
      ))}
    </MenuList>
  )
);

export default Menu;

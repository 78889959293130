export const POST_STATUS = {
  SCHEDULED: "SCHEDULED",
  SENT: "SENT"
};

export const ATTACHMENT_TYPES = {
  PHOTO: "photo",
  VIDEO: "video",
  PDF: "pdf",
  ARTICLE: "article"
};

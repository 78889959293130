import React from "react";

import {
  TooltipTitle,
  StatListItem,
  TooltipContent,
  TooltipContainer
} from "../styles";
import { formatForDashboard } from "utils/date";
import { ICustomTooltip } from "../ExternalTooltipHandler";

const Tooltip: React.FC<ICustomTooltip> = ({ data }) => {
  const { date, variation, total } = data;

  return (
    <TooltipContainer>
      <TooltipTitle>{formatForDashboard(new Date(date))}</TooltipTitle>
      <TooltipContent>
        <StatListItem bgcolor="transparent">
          <div className="title">Total followers</div>
          <div className="stats">{total}</div>
        </StatListItem>
        <StatListItem bgcolor={"transparent"}>
          <div className="title">New followers</div>
          <div className="stats">{variation}</div>
        </StatListItem>
      </TooltipContent>
    </TooltipContainer>
  );
};

export default Tooltip;

import styled, { css } from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { MagicWand } from "@styled-icons/boxicons-solid";
import { InfoCircle } from "@styled-icons/bootstrap/InfoCircle";

export const CaptionWrapper = styled(Flex)`
  display: ${props => (props.isVisible ? "flex" : "none")};
  background: transparent;
  padding: 0;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`;

export const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-bottom: 16px;

  ${props =>
    props.inProMode &&
    css`
      padding-right: 10px;
      scrollbar-gutter: stable;
    `};
`;

export const EditorWrapper = styled.div`
  box-sizing: border-box;
  ${props =>
    !props.attachmentsInsideContent &&
    css`
      min-height: 96px;
    `}
  width: 100%;
  position: relative;
  color: #444;
  line-height: 20px;
  font-size: 16px;
  padding-right: 5px;
  margin-bottom: ${props => props.marginBottom || 16}px;
`;

export const CaptionHeader = styled(Flex)`
  font-weight: 600;
  margin-bottom: 10px;
  color: #666;
  justify-content: space-between;
`;

export const CaptionAI = styled(Flex)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;

  color: #646769;
  margin-bottom: 10px;

  > span {
    &.error {
      color: ${props => props.theme.colors.red};
    }
  }
`;

export const AIwand = styled(MagicWand)`
  width: 16px;
  margin-right: 4px;
`;

export const ImageAttachmentWrapper = styled.div`
  display: flex;
  max-width: 550px;
  flex: 1;
  margin-bottom: 8px;
`;

export const CharacterCountContainer = styled(Box)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-weight: normal;
  line-height: 16px;
  font-size: 12px;
  text-align: right;
  letter-spacing: 0.4px;
  color: ${props =>
    props.error ? props.theme.colors.red : props.theme.colors.gray};
`;

export const HashTagWrapper = styled(Flex)`
  position: absolute;
  flex-direction: column;
  top: 11px;
  left: 0;
  min-width: 150px;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 30px 0 gainsboro;
  max-height: 220px;
  overflow-y: auto;
`;

export const HashTagButton = styled(Flex)`
  padding: 15px 20px;
  color: #8b93a6;
  cursor: pointer;
  &:hover {
    background: #dee8fb;
  }
`;

export const HashTagEmpty = styled(Flex)`
  padding: 15px 20px;
  color: #8b93a6;
  font-style: italic;
  white-space: nowrap;
`;

export const HashTagList = styled.div`
  position: relative;
`;

export const CharacterCount = styled(Box)``;

export const CaptionToolbar = styled(Flex)`
  justify-content: flex-end;
  align-items: center;
  height: 32px;

  .stick-left {
    margin-right: auto;
  }
`;

export const Toolbox = styled(Flex)`
  margin-left: 4px;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;

export const InfoIcon = styled(InfoCircle)`
  width: 16px;
  margin-right: 4px;
`;

export const ToolbarButton = styled(Box)`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;

  &.emoji {
    font-size: unset;

    &:before {
      font-size: 20px;
    }

    > div {
      width: 32px;
      height: 32px;
      position: absolute;

      button {
        width: 32px;
        height: 32px;
      }

      > div {
        margin-top: 12px;
        top: ${props => props.offset + 32}px;
      }
    }
  }

  border-radius: 50%;
  background: ${props => props.theme.colors.primaryTransparent};
  color: ${props => props.theme.colors.text02};
  &:hover {
    background: ${props => props.theme.colors.grey02};
    color: ${props => props.theme.colors.text02};
  }
  &:active {
    background: ${props => props.theme.colors.grey03};
    color: ${props => props.theme.colors.text02};
  }
`;

export const ChannelSplitter = styled(Flex)`
  gap: 8px;
  width: 147px;
  height: 24px;
  position: relative;
  align-items: center;
  border-right: 1px solid ${props => props.theme.colors.grey04};

  .label {
    color: ${props => props.theme.colors.text02};
  }
`;

export const PopupPluginWrapper = styled.div`
  z-index: 99;
  position: fixed;
`;

export const PdfCarousel = styled(Flex)`
  overflow: visible;
  padding-bottom: 24px;

  .react-pdf__Document {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid ${props => props.theme.colors.backdrop};

    > div {
      > div:not([hidden]) {
        margin: 0px;

        > div {
          > div {
            grid-template-columns: repeat(
              auto-fill,
              ${props => props.itemWidth}px
            );
            justify-content: space-between;
          }
        }
      }
    }
  }

  /* .react-pdf__Page {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  }

  .react-pdf__message {
    color: white;
  } */
`;

import React, { useState } from "react";
import { Box } from "rebass/styled-components";
import { Field, reduxForm } from "redux-form";
import TextInput from "../common/form/TextInput";
import {
  ButtonLoading,
  Form,
  FormBox,
  FormDescription,
  FormTitle
} from "../common/form/styles";
import { FormContainer, FormLogo } from "./styles";
import {
  required,
  validPassword,
  repeatPassword
} from "../../utils/Validation";
import Button from "../common/Button";
import willowLogo from "../../assets/images/willow-text-color@2x.png";
import lockIcon from "../../assets/images/icon-lock.svg";

const NewPasswordForm = ({ handleSubmit, accountEmail }) => {
  const [isSending, setIsSending] = useState(false);

  const onSubmit = values => {
    const result = handleSubmit(values);
    setIsSending(result && result.type);
  };

  return (
    <FormContainer>
      <FormLogo>
        <img src={willowLogo} alt="Willow" height="40" />
      </FormLogo>
      <Box mb={32}>
        <img src={lockIcon} width="35" height="40" alt="lock icon" />
      </Box>
      <FormTitle>Set new password</FormTitle>
      <FormDescription>
        Enter a new password for your {accountEmail} account.
      </FormDescription>
      <FormBox>
        <Form onSubmit={onSubmit}>
          <div>
            <Field
              type="password"
              validate={[required, validPassword]}
              label="New password"
              component={TextInput}
              name="password"
            />
            <Field
              type="password"
              validate={[required, repeatPassword]}
              label="Repeat new password"
              component={TextInput}
              name="repeat_password"
            />
            <Box pt={8} pb={16}>
              <Button size="lg" width="100%" type="submit" disabled={isSending}>
                {isSending && (
                  <ButtonLoading width={20} height={20} type="spin" />
                )}
                Set new password
              </Button>
            </Box>
          </div>
        </Form>
      </FormBox>
      <Box marginTop="auto" height={80} />
    </FormContainer>
  );
};

export default reduxForm({
  // a unique name for the form
  form: "newPasswordForm",
  enableReinitialize: true
})(NewPasswordForm);

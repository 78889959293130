import { tomorrow, today as thisDay, getNearestMonday } from "utils/date";
import { format, parseISO } from "date-fns";
import {
  getDayInWords,
  getTheWeekADateBelongsTo
} from "../common/timelineDatePicker/utils/dateUtils";
import numeral from "numeral";
import { capitalize } from "lodash";

const BAD_DAY_TO_POST = {
  5: "FRIDAY",
  6: "SATURDAY",
  7: "SUNDAY"
};

export const getDefaultTaskStartDate = () => {
  return BAD_DAY_TO_POST[thisDay().getDay()]
    ? getNearestMonday().toISOString()
    : tomorrow().toISOString();
};

export const getDefaultTask = account => ({
  contentTypeId: "educational",
  periodicity: "weekly",
  channels: account.channels.map(channel => channel.id),
  frequency: 1,
  slots: [{ dayOfWeek: "auto", timeOfDay: "auto" }]
});

export const getDefaultCalendarTask = () => ({
  contentTypeId: "educational",
  date: getDefaultTaskStartDate()
});

const repetitionMap = {
  WEEKLY: "Weekly",
  BIWEEKLY: "Bi-Weekly",
  MONTHLY: "Monthly",
  YEARLY: "Yearly"
};

export const dateButtonLabel = taskProperties => {
  const { settings: repetitionSettings } = taskProperties;

  if (!repetitionSettings)
    return `Once on ${format(parseISO(taskProperties.date), "LLLL d, yyyy")}`;

  const mode = repetitionSettings.repetitionMode.split("_")[0];

  switch (mode) {
    case "WEEKLY":
    case "BIWEEKLY":
      if (repetitionSettings.daysOfTheWeek?.length < 2) {
        const day = capitalize(repetitionSettings.daysOfTheWeek[0]);
        return `${repetitionMap[mode]} on ${day}`;
      } else {
        const days = repetitionSettings.daysOfTheWeek
          .map(day => capitalize(day.slice(0, 3)))
          .join(", ")
          .replace(/, ([^,]*)$/, " and $1");
        return `${repetitionMap[mode]} on ${days}`;
      }
    case "MONTHLY": {
      if (repetitionSettings.repetitionMode.split("_").length > 1) {
        const nthWeek = getTheWeekADateBelongsTo(
          new Date(taskProperties.dateStart)
        );
        let nthWeekFormat = numeral(nthWeek).format("0o");

        const day = getDayInWords(new Date(taskProperties.dateStart));

        return `${repetitionMap[mode]} on ${nthWeekFormat} ${capitalize(day)}`;
      }
      return `${repetitionMap[mode]} on ${numeral(
        format(new Date(taskProperties.dateStart), "d")
      ).format("0o")} day`;
    }
    case "YEARLY":
    default:
      return `${repetitionMap[mode]} on ${format(
        new Date(taskProperties.dateStart),
        "LLLL do"
      )}`;
  }
};

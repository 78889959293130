import React from "react";

import {
  ClickStats,
  EntryArticle,
  EntryThumbnail,
  EntryArticleText,
  ThumbnailWrapper,
  EntryArticleSource,
  EntryArticleSourceIcon
} from "./styles";
import EmptySourceImage from "assets/images/article/empty-source.png";
import EmptyArticleImage from "assets/images/article/empty-article-image.png";

const shortenedUrlRegex =
  /(http|https):\/\/(cnto\.io|localhost:4004|wllw\.co)/i;
interface IPostEntryArticle {
  title: string;
  picture: string;
  post: any;
}

const PostEntryArticle: React.FC<IPostEntryArticle> = ({
  post,
  title,
  picture
}) => {
  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const { url } = post.attachment;
    if (url) {
      e.stopPropagation();
      window.open(url, "_blank");
    }
  };

  const showClicks =
    !!post.attachment?.url &&
    shortenedUrlRegex.test(post.attachment.url) &&
    !!post.stats?.clicks &&
    post.stats.clicks > 0;

  return (
    <EntryArticle onClick={handleClick}>
      <ThumbnailWrapper>
        <EntryThumbnail imgSrc={picture || EmptyArticleImage} />
        {showClicks && (
          <ClickStats>
            Clicks:
            <span>{post.stats?.clicks}</span>
          </ClickStats>
        )}
      </ThumbnailWrapper>
      <EntryArticleText>
        <p>{title}</p>
        <EntryArticleSource>
          <EntryArticleSourceIcon
            src={post.attachment?.favicon || EmptySourceImage}
            alt={post.attachment.domain}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = EmptySourceImage;
            }}
          />
          <span>{post.attachment.domain}</span>
        </EntryArticleSource>
      </EntryArticleText>
    </EntryArticle>
  );
};

export default PostEntryArticle;

import React, { useState, useEffect, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { reset } from "redux-form";
import { useToaster } from "@hellocontento/maillard";
import { callApi } from "../../utils/ContentoApi";
import * as accountActions from "../../state/actions/AccountActions";
import * as authActions from "../../state/actions/AuthActions";
import * as modalActions from "state/actions/ModalActions";

import { InviteMember } from "./forms";
import Media from "components/common/Media";
import ReactLoading from "react-loading";
import {
  SettingsTitle,
  SettingsDescription,
  SettingsHeader,
  SettingsSection,
  SettingsSubtitle,
  SettingsWell,
  SettingsBrick,
  SettingsSubtitleWrapper,
  AccountCount
} from "./styles";

import UserAvatarDefault from "assets/images/defaults/user-picture-skeleton.png";
import EmptyCard from "./EmptyCard";
import { useHistory } from "react-router";
import { OnboardingStepsEnum } from "types/Onboarding.types";

function Team({ account, currentUser, openModal }) {
  const addToast = useToaster();
  const history = useHistory();
  const dispatch = useDispatch();
  const [invites, setInvites] = useState([]);
  const [accountUsers, setAccountUsers] = useState([]);

  const handleSubmitInvite = values => {
    callApi({
      method: "post",
      url: `/accounts/${account.id}/invites`,
      data: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email
      }
    })
      .then(() => {
        refreshList();
        addToast("Invite sent successfully", "success");

        openModal("ONBOARDING_INFO_MODAL", {
          id: OnboardingStepsEnum.INVITE_TEAM_MEMBER,
          triggeredBy: OnboardingStepsEnum.INVITE_TEAM_MEMBER
        });
        dispatch(reset("settingsInviteMember"));
      })
      .catch(err => {
        addToast(
          err.name === "ValidationError"
            ? err.message
            : "Could not send invite. Please try again later.",
          "error"
        );
      });
  };

  const handleRevokeInvite = email => {
    callApi({
      method: "delete",
      url: `/accounts/${account.id}/invites`,
      data: {
        email: email
      }
    })
      .then(() => {
        refreshList();
        addToast("Team member removed", "success");
      })
      .catch(err => {
        addToast(
          err.name === "ValidationError"
            ? err.message
            : "Could not remove member. Please try again later.",
          "error"
        );
      });
  };

  const handleRemoveExistingUser = userId => {
    callApi({
      method: "delete",
      url: `/accounts/${account.id}/users/${userId}`
    })
      .then(() => {
        refreshList();
        addToast("Team member removed", "success");
      })
      .catch(err => {
        addToast(
          err.name === "ValidationError"
            ? err.message
            : "Could not remove member. Please try again later.",
          "error"
        );
      });
  };

  const getInvites = useCallback(
    () =>
      callApi({
        url: `/accounts/${account.id}/invites`
      }).then(res => {
        setInvites(res);
      }),
    [account.id]
  );

  const getAccountUsers = useCallback(
    () =>
      callApi({
        url: `/accounts/${account.id}/users`
      }).then(res => {
        setAccountUsers(res);
      }),
    [account.id]
  );

  const refreshList = useCallback(() => {
    Promise.all([getInvites(), getAccountUsers()]).catch(() => {
      addToast("Error loading users. Please try again later.", "error");
    });
  }, [getInvites, getAccountUsers, addToast]);

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  const userList = accountUsers
    ? accountUsers.map(user => {
        const isSameUser = currentUser.email === user.email;
        return (
          <SettingsWell key={user.email}>
            <Media
              title={`${user.fullName}${isSameUser ? " (You)" : ""}`}
              description={`${user.email}`}
              thumbnail={user.picture}
              subDescription={`Invitation accepted`}
              subDescriptionVariant={"primary"}
              buttonLabel={"Revoke access"}
              buttonVariant={"dangerLite"}
              buttonAction={() => handleRemoveExistingUser(user.id)}
              thumbnailRound
            />
          </SettingsWell>
        );
      })
    : null;

  const handleUpgrade = e => {
    e.preventDefault();
    history.push(`/accounts/${account.id}/settings/billing`);
  };

  const inviteList =
    invites.length > 0 ? (
      invites.map(invite => (
        <SettingsWell key={invite.email}>
          <Media
            title={`${invite.firstName} ${invite.lastName}`}
            thumbnail={UserAvatarDefault}
            description={`${invite.email}`}
            subDescription={`Invitation sent`}
            subDescriptionVariant={"danger"}
            buttonLabel={"Revoke access"}
            buttonVariant={"dangerLite"}
            buttonAction={() => handleRevokeInvite(invite.email)}
            thumbnailRound
          />
        </SettingsWell>
      ))
    ) : (
      <EmptyCard
        key="team-empty-card"
        title="No pending invitations"
        message={
          account.plan === "PERSONAL_BRANDING"
            ? "In order to invite team members you need to upgrade your plan."
            : null
        }
        support="Upgrade"
        showSupport={account.plan === "PERSONAL_BRANDING"}
        action={handleUpgrade}
      />
    );

  return (
    <>
      <SettingsHeader>
        <SettingsTitle>Team Members</SettingsTitle>
        <SettingsDescription>
          Willow lets you access your team members to share content on their
          channels. That way they can advocate for your business and also become
          thought leaders.
        </SettingsDescription>
      </SettingsHeader>
      <SettingsSection>
        <SettingsSubtitle>Invite a member</SettingsSubtitle>
        <SettingsBrick>
          <InviteMember account={account} onSubmit={handleSubmitInvite} />
        </SettingsBrick>
      </SettingsSection>
      <SettingsSection>
        <SettingsSubtitleWrapper>
          <SettingsSubtitle justifyContent={"flex-start"}>
            Pending Invites
            {invites?.length > 0 ? (
              <AccountCount>{invites.length}</AccountCount>
            ) : null}
          </SettingsSubtitle>
          {inviteList ? (
            [inviteList]
          ) : (
            <ReactLoading color="#bbb" type="cyclon" />
          )}
        </SettingsSubtitleWrapper>
        <SettingsSubtitleWrapper marginTop={"64px"}>
          <SettingsSubtitle justifyContent={"flex-start"}>
            Team Members<AccountCount>{accountUsers?.length}</AccountCount>
          </SettingsSubtitle>
          {userList ? [userList] : <ReactLoading color="#bbb" type="cyclon" />}
        </SettingsSubtitleWrapper>
      </SettingsSection>
    </>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {
  openModal: modalActions.openModal,
  updateAccount: accountActions.updateAccount,
  updateUser: authActions.updateUser
})(Team);

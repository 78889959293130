import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useToaster } from "@hellocontento/maillard";
import { Twitter } from "styled-icons/boxicons-logos";
import React, { useState, useEffect, useCallback, useMemo } from "react";

import { EmptyImageWrapper } from "./styles";
import { getSourceById } from "services/news";
import EmptyState from "../common/EmptyState";
import { SOURCE_TYPE } from "services/news.types";
import InfoBlock from "components/common/InfoBlock";
import ScrollableContentList from "./ScrollableContentList";
import ArticleEmpty from "assets/images/contents/article-empty.png";
import KeywordDetailBanner from "./common/detail/KeywordDetailBanner";

const PAGE_SIZE = 9;
const FIRST_PAGE = 1;

const ContentKeywordDetail = React.memo(({ sourceType }) => {
  const params = useParams();
  const addToast = useToaster();
  const excludedTld = useSelector(state => state.regionFilter.excludedTld);
  const [pageData, setPageData] = useState(null);

  const fetchContents = useCallback(
    async pageNumber => {
      try {
        const response = await getSourceById(params.sourceId, "keywords", {
          page: pageNumber,
          limit: PAGE_SIZE
        });
        setPageData(data => ({
          items:
            pageNumber > 1
              ? [...data.items, ...response.contents]
              : response.contents,
          source: response.section,
          page: pageNumber,
          isFinished: response.contents.length < PAGE_SIZE,
          pageSize: PAGE_SIZE,
          excludedTld
        }));
      } catch (err) {
        if (err.name !== "RequestCancelled") {
          addToast(
            "There was an error fetching your content. Please try again later or contact support.",
            "error"
          );
        }
      }
    },
    [addToast, excludedTld, params.sourceId]
  );

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, [params.sourceId]);

  const refreshContent = useCallback(async () => {
    fetchContents(FIRST_PAGE);
  }, [fetchContents]);

  useEffect(() => {
    refreshContent();
  }, [refreshContent]);

  const emptyState = useMemo(() => {
    return sourceType === SOURCE_TYPE.TWITTER ? (
      <EmptyState
        loading={!pageData}
        header={"We couldn’t find any articles"}
        description={
          "Unfortunately the feed you are following isn’t returning any articles."
        }
        icon={{
          component: Twitter,
          width: "24px",
          height: "24px"
        }}
      />
    ) : (
      <EmptyState
        center={false}
        header={"We couldn’t find any articles"}
        description={
          "Unfortunately the feed you are following isn’t returning any articles."
        }
        image={<EmptyImageWrapper src={ArticleEmpty} />}
      />
    );
  }, [pageData, sourceType]);

  return (
    <>
      {pageData && (
        <>
          <KeywordDetailBanner pageData={pageData} />
          {pageData.items.length < 6 && (
            <InfoBlock
              icon="icon-info-20"
              text="Willow is still looking for more results. This can take a couple of minutes. Come back later or refresh"
              variant="info"
            />
          )}
        </>
      )}
      <ScrollableContentList
        pageData={pageData}
        onScheduled={refreshContent}
        onDismiss={refreshContent}
        onNextPage={fetchContents}
        emptyState={emptyState}
        sourceId={params.sourceId}
      />
    </>
  );
});

export default ContentKeywordDetail;

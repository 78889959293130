import React from "react";
import { Flex, Box } from "rebass/styled-components";
import { Check } from "styled-icons/feather";
import {
  BigCheckboxIcon,
  BigCheckboxTitle,
  BigCheckBoxWrapper,
  CheckCircle
} from "./styles";
import { Caption2 } from "../styles";

export const BigCheckBoxGroup = ({
  label,
  name,
  options,
  input,
  onChange,
  value,
  multi,
  small
}) => {
  //support use in <Field> and standalone
  let inputValue = input ? input.value : value;
  if (multi && !inputValue) {
    inputValue = [];
  }
  const inputOnChange = input ? input.onChange : onChange;

  const handleSelect = value => {
    if (multi) {
      if (inputValue.includes(value)) {
        //remove from array
        inputOnChange(inputValue.filter(item => item !== value));
      } else {
        inputOnChange([...inputValue, value]);
      }
    } else {
      inputOnChange(value);
    }
  };

  return (
    <Box width={1} mt={12}>
      {label && <Caption2 fontWeight="bold">{label}</Caption2>}
      <Box
        sx={{
          display: "grid",
          gridGap: "10px",
          gridTemplateColumns:
            options.length > (small ? 3 : 2)
              ? "1fr"
              : `repeat( auto-fit, minmax(${small ? "100px" : "250px"}, 1fr) )`
        }}
        my={20}
      >
        {options.map(option => (
          <CheckBox
            key={option.value}
            name={name}
            icon={option.icon ? option.icon : null}
            title={option.title}
            onSelect={() => handleSelect(option.value)}
            selected={
              multi
                ? inputValue.includes(option.value)
                : inputValue === option.value
            }
            small={small}
          />
        ))}
      </Box>
    </Box>
  );
};

const CheckBox = ({ name, icon, title, onSelect, selected, small }) => {
  return (
    <BigCheckBoxWrapper
      small={small}
      icon={icon}
      selected={selected}
      onClick={onSelect}
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex flex={1} />
      <Flex flexDirection="column" justifyContent="center">
        {icon && <BigCheckboxIcon src={icon} />}
        <BigCheckboxTitle mb={0}>{title}</BigCheckboxTitle>
      </Flex>
      <Flex flex={1} justifyContent="flex-end" pr={10}>
        {selected && (
          <CheckCircle>
            <Check size={14} strokeWidth={5} color="#ffffff" />
          </CheckCircle>
        )}
      </Flex>
      <input
        name={name}
        onChange={() => onSelect()}
        type="radio"
        checked={selected}
        style={{ visibility: "hidden", display: "none" }}
      />
    </BigCheckBoxWrapper>
  );
};

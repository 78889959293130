import styled, { css } from "styled-components";
import { MagicWand } from '@styled-icons/boxicons-solid/MagicWand';
import { Canva } from "@styled-icons/simple-icons/Canva";
import { Image } from "@styled-icons/evaicons-solid/Image";

export const ToolkitContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const Toolbar = styled.div`
  display: flex;
  width: 36px;
  flex-direction: column;
  gap: 16px;
  padding-top: 16px;
  margin-left: -6px;
`;

export const ToolContent = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 100%;
  border-radius: 16px;
  background-color: ${props => props.theme.colors.grey05};
`;

export const ToolContentHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 32px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-right: 16px;
  padding: 16px 16px 0;
`;

export const ToolContentBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-left: 16px;
  overflow-y: auto;
  scrollbar-gutter: stable;
`;

export const FeatureTag = styled.div<{ isLoading?: boolean }>`
  display: flex;
  align-items: center;

  > i {
    font-size: 16px;
    color: ${props => props.theme.colors.text02};
  }

  span {
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    line-height: 15.6px;
    margin-left: ${props => (props.isLoading ? 8 : 4)}px;
    color: ${props =>
      props.isLoading ? props.theme.colors.text03 : props.theme.colors.text02};
  }
`;

export const StyledMagicWand = styled(MagicWand)`
  color: currentColor;
`;

export const WhiteMagicWand = styled(MagicWand)`
  color: ${props => props.theme.colors.white};
`;

export const StyledCanvaIcon = styled(Canva)`
  color: currentColor;
`;

export const WhiteCanvaIcon = styled(Canva)`
  color: ${props => props.theme.colors.white};
`;

export const StyledImageIcon = styled(Image)`
  color: currentColor;
`;

export const WhiteImageIcon = styled(Image)`
  color: ${props => props.theme.colors.white};
`;

export const ToolIcon = styled.div<{ isActive: boolean }>`
  position: relative;
  width: ${props => (props.isActive ? '40px' : '36px')};
  height: ${props => (props.isActive ? '40px' : '36px')};
  background-color: ${props => (props.isActive ? props.theme.colors.black : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.3s ease;

  ${StyledMagicWand}, ${StyledCanvaIcon}, ${StyledImageIcon} {
    color: ${props => (props.isActive ? props.theme.colors.white : props.theme.colors.text02)};
    width: ${props => (props.isActive ? '24px' : '20px')};
    height: ${props => (props.isActive ? '24px' : '20px')};
    transition: color 0.3s ease;
  }
`;

export default ToolIcon;

import React from "react";
import { Link } from "react-router-dom";
import Carousel from "react-grid-carousel";
import { Box, Flex } from "rebass/styled-components";

import {
  IdeaPageTitle,
  IdeaPageHeader,
  IdeaPageDescription
} from "components/ideas/styles";
import useSavedPosts from "utils/useSavedPosts";
import { Headline5 } from "components/common/styles";
import { willowExtensionFAQLink, willowExtensionLink } from "constants/index";
import Loader from "components/common/loading/Loader";
import SavedPostCard from "components/ideas/savedPosts/SavedPostCard";
import bookmarkSkeleton from "assets/images/defaults/bookmark-skeleton.png";

const SavedPosts: React.FC<{}> = () => {
  const {
    savedPosts,
    createFromSavedPosts,
    removeSavedPost,
    isFetchingSavedPosts
  } = useSavedPosts();

  return (
    <>
      <IdeaPageHeader>
        <IdeaPageTitle mb={12}>Willow+ Posts</IdeaPageTitle>
        <IdeaPageDescription>
          Inspire your next idea with saved posts from the Willow+ extension.
          Add to your saved posts bank by{" "}
          <Link to={{ pathname: willowExtensionLink }} target="_blank">
            downloading our Chrome Extension
          </Link>{" "}
          and visiting LinkedIn. Read more about our Extension{" "}
          <Link to={{ pathname: willowExtensionFAQLink }} target="_blank">
            here
          </Link>
          .
        </IdeaPageDescription>
      </IdeaPageHeader>
      {!isFetchingSavedPosts && savedPosts.length === 0 && (
        <Flex style={{ marginBottom: "35px" }}>
          <Headline5>
            You haven’t saved any posts yet. Once you save posts, they’ll appear
            here.
          </Headline5>
        </Flex>
      )}
      <Box className="carousel-box saved-posts">
        <Carousel
          cols={isFetchingSavedPosts ? 1 : 4}
          rows={1}
          gap={0}
          hideArrow={isFetchingSavedPosts || savedPosts.length === 0}
          mobileBreakpoint={0}
          containerStyle={{ margin: "0 -30px" }}
          responsiveLayout={[
            {
              breakpoint: 990,
              cols: isFetchingSavedPosts ? 1 : 3,
              rows: isFetchingSavedPosts || savedPosts.length <= 3 ? 1 : 3,
              gap: 0
            }
          ]}
        >
          {isFetchingSavedPosts ? (
            <Carousel.Item>
              <Flex justifyContent={"center"}>
                <Loader />
              </Flex>
            </Carousel.Item>
          ) : savedPosts.length === 0 ? (
            [...Array(4).fill(0)].map((_, index) => (
              <Carousel.Item key={index}>
                <div style={{ width: "275px", padding: "15px" }}>
                  <img
                    width="227px"
                    src={bookmarkSkeleton}
                    alt={`bookmark skeleton ${index}`}
                  />
                </div>
              </Carousel.Item>
            ))
          ) : (
            savedPosts.map(post => (
              <Carousel.Item key={post.urn}>
                <SavedPostCard
                  post={post}
                  removeSavedPost={removeSavedPost}
                  createFromSavedPosts={createFromSavedPosts}
                />
              </Carousel.Item>
            ))
          )}
        </Carousel>
      </Box>
    </>
  );
};

export default SavedPosts;

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { GlobalStyle, theme } from "theme";
import React, { ComponentType } from "react";
import { ThemeProvider } from "styled-components";

import store from "state/store";

export interface ICustomTooltip {
  data: any;
}

export const externalTooltipHandler =
  (CustomTooltip: any, position: "right" | "left" = "right") =>
  (context: any) => {
    const { chart, tooltip } = context;

    // Tooltip Element
    let tooltipEl = document.getElementById("chartjs-tooltip");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "chartjs-tooltip";
      document.body.appendChild(tooltipEl);
    }

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = "0";
      return;
    }

    if (tooltip.body && tooltip.dataPoints.length > 0) {
      const canvasPos = chart.canvas.getBoundingClientRect();

      tooltipEl.style.opacity = "1";
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.top =
        canvasPos.top + window.pageYOffset + tooltip.caretY + "px";
      tooltipEl.style.transition = "all 0.2s ease";

      if (position === "left") {
        tooltipEl.style.left =
          canvasPos.left +
          window.pageXOffset +
          tooltip.caretX -
          220 -
          30 +
          "px"; // TODO: Figure out a better solution by using `right` property
      } else {
        tooltipEl.style.left =
          canvasPos.left + window.pageXOffset + tooltip.caretX + 5 + "px";
      }

      ReactDOM.render(
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <CustomTooltip data={tooltip.dataPoints[0].raw} />
          </ThemeProvider>
        </Provider>,
        document.getElementById("chartjs-tooltip") as HTMLElement
      );
    }
  };

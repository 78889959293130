import {
  SET_CHANNELS_IS_LOADING,
  SET_CHANNELS_PERIOD,
  SET_CHANNELS_DATA,
  SET_CHANNELS_EMPLOYEES_DATA,
  SET_CHANNELS_PER_CHANNEL_DATA,
  SET_CHANNELS_CHANNEL_IDS,
  SET_ALL_POSTS_IS_LOADING,
  SET_ALL_POSTS_PERIOD,
  SET_ALL_POSTS_DATA,
  SET_ALL_POSTS_CATEGORIES,
  SET_REPURPOSE_POSTS_IS_LOADING,
  SET_REPURPOSE_POSTS_PERIOD,
  SET_REPURPOSE_POSTS_ENGAGEMENT_RATE,
  SET_REPURPOSE_POSTS_DATA,
  SET_REPURPOSE_POSTS_CHANNEL_IDS,
  SET_ALL_POSTS_CHANNEL_IDS,
  SET_LINKS_IS_LOADING,
  SET_LINKS_PERIOD,
  SET_LINKS_DATA,
  SET_LINKS_CHANNEL_IDS,
  SET_LINKS_CATEGORIES,
  SET_REPURPOSE_POSTS_CATEGORIES
} from "./actions";
import { IAnalyticsState } from "./reducer";

export interface ISetChannelsIsLoadingAction {
  type: typeof SET_CHANNELS_IS_LOADING;
  payload: IAnalyticsState["channels"]["isLoading"];
}

export const setChannelsIsLoadingAction = (
  payload: IAnalyticsState["channels"]["isLoading"]
): ISetChannelsIsLoadingAction => ({
  type: SET_CHANNELS_IS_LOADING,
  payload
});

export interface ISetChannelsPeriodAction {
  type: typeof SET_CHANNELS_PERIOD;
  payload: IAnalyticsState["channels"]["period"];
}

export const setChannelsPeriodAction = (
  payload: IAnalyticsState["channels"]["period"]
): ISetChannelsPeriodAction => ({
  type: SET_CHANNELS_PERIOD,
  payload
});

export interface ISetChannelsDataAction {
  type: typeof SET_CHANNELS_DATA;
  payload: IAnalyticsState["channels"]["data"];
}

export const setChannelsDataAction = (
  payload: IAnalyticsState["channels"]["data"]
): ISetChannelsDataAction => ({
  type: SET_CHANNELS_DATA,
  payload
});

export interface ISetChannelsEmployeesDataAction {
  type: typeof SET_CHANNELS_EMPLOYEES_DATA;
  payload: IAnalyticsState["channels"]["employeesData"];
}

export const setChannelsEmployeesDataAction = (
  payload: IAnalyticsState["channels"]["employeesData"]
): ISetChannelsEmployeesDataAction => ({
  type: SET_CHANNELS_EMPLOYEES_DATA,
  payload
});

export interface ISetChannelsPerChannelDataAction {
  type: typeof SET_CHANNELS_PER_CHANNEL_DATA;
  payload: IAnalyticsState["channels"]["perChannelData"];
}

export const setChannelsPerChannelDataAction = (
  payload: IAnalyticsState["channels"]["perChannelData"]
): ISetChannelsPerChannelDataAction => ({
  type: SET_CHANNELS_PER_CHANNEL_DATA,
  payload
});

export interface ISetChannelsChannelIdsAction {
  type: typeof SET_CHANNELS_CHANNEL_IDS;
  payload: IAnalyticsState["channels"]["channelIds"];
}

export const setChannelsChannelIdsAction = (
  payload: IAnalyticsState["channels"]["channelIds"]
): ISetChannelsChannelIdsAction => ({
  type: SET_CHANNELS_CHANNEL_IDS,
  payload
});

export interface ISetAllPostsIsLoadingAction {
  type: typeof SET_ALL_POSTS_IS_LOADING;
  payload: IAnalyticsState["allPosts"]["isLoading"];
}

export const setAllPostsIsLoadingAction = (
  payload: IAnalyticsState["allPosts"]["isLoading"]
): ISetAllPostsIsLoadingAction => ({
  type: SET_ALL_POSTS_IS_LOADING,
  payload
});

export interface ISetAllPostsPeriodAction {
  type: typeof SET_ALL_POSTS_PERIOD;
  payload: IAnalyticsState["allPosts"]["period"];
}

export const setAllPostsPeriodAction = (
  payload: IAnalyticsState["allPosts"]["period"]
): ISetAllPostsPeriodAction => ({
  type: SET_ALL_POSTS_PERIOD,
  payload
});

export interface ISetAllPostsDataAction {
  type: typeof SET_ALL_POSTS_DATA;
  payload: IAnalyticsState["allPosts"]["data"];
}

export const setAllPostsDataAction = (
  payload: IAnalyticsState["allPosts"]["data"]
): ISetAllPostsDataAction => ({
  type: SET_ALL_POSTS_DATA,
  payload
});

export interface ISetAllPostsChannelIdsAction {
  type: typeof SET_ALL_POSTS_CHANNEL_IDS;
  payload: IAnalyticsState["allPosts"]["channelIds"];
}

export const setAllPostsChannelIdsAction = (
  payload: IAnalyticsState["allPosts"]["channelIds"]
): ISetAllPostsChannelIdsAction => ({
  type: SET_ALL_POSTS_CHANNEL_IDS,
  payload
});

export interface ISetAllPostsCategoriesAction {
  type: typeof SET_ALL_POSTS_CATEGORIES;
  payload: IAnalyticsState["allPosts"]["categories"];
}

export const setAllPostsCategoriesAction = (
  payload: IAnalyticsState["allPosts"]["categories"]
): ISetAllPostsCategoriesAction => ({
  type: SET_ALL_POSTS_CATEGORIES,
  payload
});

export interface ISetRepurposePostsIsLoadingAction {
  type: typeof SET_REPURPOSE_POSTS_IS_LOADING;
  payload: IAnalyticsState["repurposePosts"]["isLoading"];
}

export const setRepurposePostsIsLoadingAction = (
  payload: IAnalyticsState["repurposePosts"]["isLoading"]
): ISetRepurposePostsIsLoadingAction => ({
  type: SET_REPURPOSE_POSTS_IS_LOADING,
  payload
});

export interface ISetRepurposePostsPeriodAction {
  type: typeof SET_REPURPOSE_POSTS_PERIOD;
  payload: IAnalyticsState["repurposePosts"]["period"];
}

export const setRepurposePostsPeriodAction = (
  payload: IAnalyticsState["repurposePosts"]["period"]
): ISetRepurposePostsPeriodAction => ({
  type: SET_REPURPOSE_POSTS_PERIOD,
  payload
});

export interface ISetRepurposePostsEngagementRateAction {
  type: typeof SET_REPURPOSE_POSTS_ENGAGEMENT_RATE;
  payload: IAnalyticsState["repurposePosts"]["engagementRate"];
}

export const setRepurposePostsEngagementRateAction = (
  payload: IAnalyticsState["repurposePosts"]["engagementRate"]
): ISetRepurposePostsEngagementRateAction => ({
  type: SET_REPURPOSE_POSTS_ENGAGEMENT_RATE,
  payload
});

export interface ISetRepurposePostsDataAction {
  type: typeof SET_REPURPOSE_POSTS_DATA;
  payload: IAnalyticsState["repurposePosts"]["data"];
}

export const setRepurposePostsDataAction = (
  payload: IAnalyticsState["repurposePosts"]["data"]
): ISetRepurposePostsDataAction => ({
  type: SET_REPURPOSE_POSTS_DATA,
  payload
});

export interface ISetRepurposePostsChannelIdsAction {
  type: typeof SET_REPURPOSE_POSTS_CHANNEL_IDS;
  payload: IAnalyticsState["repurposePosts"]["channelIds"];
}

export const setRepurposePostsChannelIdsAction = (
  payload: IAnalyticsState["repurposePosts"]["channelIds"]
): ISetRepurposePostsChannelIdsAction => ({
  type: SET_REPURPOSE_POSTS_CHANNEL_IDS,
  payload
});

export interface ISetRepurposePostsCategoriesAction {
  type: typeof SET_REPURPOSE_POSTS_CATEGORIES;
  payload: IAnalyticsState["repurposePosts"]["categories"];
}

export const setRepurposePostsCategoriesAction = (
  payload: IAnalyticsState["repurposePosts"]["categories"]
): ISetRepurposePostsCategoriesAction => ({
  type: SET_REPURPOSE_POSTS_CATEGORIES,
  payload
});

export interface ISetLinksIsLoadingAction {
  type: typeof SET_LINKS_IS_LOADING;
  payload: IAnalyticsState["links"]["isLoading"];
}

export const setLinksIsLoadingAction = (
  payload: IAnalyticsState["links"]["isLoading"]
): ISetLinksIsLoadingAction => ({
  type: SET_LINKS_IS_LOADING,
  payload
});

export interface ISetLinksPeriodAction {
  type: typeof SET_LINKS_PERIOD;
  payload: IAnalyticsState["links"]["period"];
}

export const setLinksPeriodAction = (
  payload: IAnalyticsState["links"]["period"]
): ISetLinksPeriodAction => ({
  type: SET_LINKS_PERIOD,
  payload
});

export interface ISetLinksDataAction {
  type: typeof SET_LINKS_DATA;
  payload: IAnalyticsState["links"]["data"];
}

export const setLinksDataAction = (
  payload: IAnalyticsState["links"]["data"]
): ISetLinksDataAction => ({
  type: SET_LINKS_DATA,
  payload
});

export interface ISetLinksChannelIdsAction {
  type: typeof SET_LINKS_CHANNEL_IDS;
  payload: IAnalyticsState["links"]["channelIds"];
}

export const setLinksChannelIdsAction = (
  payload: IAnalyticsState["links"]["channelIds"]
): ISetLinksChannelIdsAction => ({
  type: SET_LINKS_CHANNEL_IDS,
  payload
});

export interface ISetLinksCategoriesAction {
  type: typeof SET_LINKS_CATEGORIES;
  payload: IAnalyticsState["links"]["categories"];
}

export const setLinksCategoriesAction = (
  payload: IAnalyticsState["links"]["categories"]
): ISetLinksCategoriesAction => ({
  type: SET_LINKS_CATEGORIES,
  payload
});

export type IActions =
  | ISetChannelsIsLoadingAction
  | ISetChannelsPeriodAction
  | ISetChannelsDataAction
  | ISetChannelsEmployeesDataAction
  | ISetChannelsPerChannelDataAction
  | ISetChannelsChannelIdsAction
  | ISetAllPostsIsLoadingAction
  | ISetAllPostsPeriodAction
  | ISetAllPostsDataAction
  | ISetAllPostsChannelIdsAction
  | ISetAllPostsCategoriesAction
  | ISetRepurposePostsIsLoadingAction
  | ISetRepurposePostsPeriodAction
  | ISetRepurposePostsEngagementRateAction
  | ISetRepurposePostsDataAction
  | ISetRepurposePostsChannelIdsAction
  | ISetRepurposePostsCategoriesAction
  | ISetLinksIsLoadingAction
  | ISetLinksPeriodAction
  | ISetLinksDataAction
  | ISetLinksChannelIdsAction
  | ISetLinksCategoriesAction;

import React, { useState } from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";
import { MenuContainer, MenuList } from "../../navigation/Menu";
import Checkbox from "../../common/form/Checkbox";
import Popper from "components/common/Popper";

import { FilterButton } from "./styles";

const SearchSelectItem = styled(Box)`
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 12px;
  margin: 0 8px;
  padding: 8px;

  &:hover {
    background-color: ${props => props.theme.colors.grey01};
  }
`;

const ButtonContentWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: 4px;

  span {
    font-size: 18px;
    transform: rotate(90deg);
  }
`;

const languageOptions = [
  {
    label: "English",
    value: "en"
  },
  {
    label: "Dutch",
    value: "nl"
  }
];

function SearchBarLanguages({ selected = [], languages, onChange }) {
  const handleChangeTopics = language => {
    if (selected.includes(language))
      onChange(selected.filter(t => t !== language));
    else onChange([...selected, language]);
  };

  return (
    <MenuContainer>
      <MenuList>
        {languages.map(language => (
          <SearchSelectItem
            key={language.value}
            onClick={() => handleChangeTopics(language)}
          >
            <Checkbox
              onChange={() => handleChangeTopics(language)}
              checked={selected.find(l => l.value === language.value)}
            />
            <span>{language.label}</span>
          </SearchSelectItem>
        ))}
      </MenuList>
    </MenuContainer>
  );
}

const SearchByLanguage = ({ selectedLanguages, onSetLanguages }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  return (
    <div ref={setReferenceElement}>
      <FilterButton
        variant={selectedLanguages.length > 0 ? "primary" : "secondary"}
        onClick={toggleShowOptions}
        minWidth={"140px"}
      >
        <ButtonContentWrapper>
          {selectedLanguages.length === 0
            ? "All languages"
            : selectedLanguages.length === 1
              ? selectedLanguages[0].label
              : `${selectedLanguages[0].label} +${selectedLanguages.length - 1}`}

          <span className="icon-arrowright" />
        </ButtonContentWrapper>
      </FilterButton>
      <Popper
        referenceElement={referenceElement}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[referenceElement]}
      >
        <SearchBarLanguages
          selected={selectedLanguages}
          languages={languageOptions}
          onChange={onSetLanguages}
        />
      </Popper>
    </div>
  );
};

export default SearchByLanguage;

import React from "react";
import PropTypes from "prop-types";

import { Option, OptionLabel } from "./styles";
import { Headline6 } from "components/common/styles";

const SchedulerOption = ({
  label,
  onClick,
  description,
  iconClassName,
  isActive = false
}) => {
  return (
    <Option isActive={isActive} onClick={onClick}>
      <OptionLabel>
        {iconClassName && <i className={iconClassName} />}
        <div className="label">
          <Headline6>{label}</Headline6>
          {description && <div className="description">{description}</div>}
        </div>
      </OptionLabel>
      {isActive && <i className="icon-check" />}
    </Option>
  );
};

SchedulerOption.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  iconClassName: PropTypes.string,
  label: PropTypes.string.isRequired
};

export default SchedulerOption;

import React, { useState } from "react";

import {
  ConditionOptions,
  Condition,
  ConditionContent,
  CategoryLabel,
  Label
} from "../styles";

import IconButton from "components/common/IconButton";

import Popper from "components/common/Popper";
import ContextMenu from "components/common/contextMenu";

import { KEYWORD_CATEGORY, CategoryData } from "../constants/category";

interface ICategorySelectionProps {
  id: string;
  isFirst: boolean;
  onRemove: (id: string) => void;
  category: CategoryData;
  setCategory: (id: string, category: CategoryData) => void;
}

const CategorySelection: React.FC<ICategorySelectionProps> = ({
  id,
  isFirst,
  onRemove,
  category,
  setCategory
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  return (
    <Condition>
      <ConditionContent>
        <ConditionOptions
          // @ts-ignore
          ref={setReferenceElement}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            toggleShowOptions();
          }}
        >
          <span className={`${category.icon} icon`} />
          <CategoryLabel>{category.label}</CategoryLabel>
          <span className="icon-arrowright rotate icon" />
        </ConditionOptions>
        {/* @ts-ignore */}
        <Popper
          referenceElement={referenceElement}
          visible={showOptions}
          onClose={() => setShowOptions(false)}
          exceptions={[referenceElement]}
        >
          <ContextMenu
            close={() => setShowOptions(false)}
            options={KEYWORD_CATEGORY.map((cat: CategoryData) => ({
              ...cat,
              onClick: () => setCategory(id, cat),
              active: category.label === cat.label,
              disabled: cat.value === "language" && isFirst
            }))}
          />
        </Popper>

        <Label>contains</Label>
      </ConditionContent>
      {!isFirst && (
        <IconButton
          // @ts-ignore
          icon="icon-delete"
          variant={"secondary"}
          size={36}
          iconSize={18}
          iconColor={"#646769"}
          onClick={() => onRemove(id)}
        />
      )}
    </Condition>
  );
};

export default CategorySelection;

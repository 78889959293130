import numeral from "numeral";
import React from "react";

import {
  PostStatisticsWrapper,
  PostStatWrapper,
  PostStatistic,
  PostStatValue
} from "./styles";

const Stats = ({ icon, value, fill }) => {
  return (
    <PostStatWrapper>
      <PostStatistic className={icon} $fill={fill} />
      <PostStatValue>{value}</PostStatValue>
    </PostStatWrapper>
  );
};

const PostStatistics = ({ stats }) => {
  if (!stats) return null;

  const likeValue = parseInt(stats.likes) ?? 0;

  return (
    <PostStatisticsWrapper>
      <Stats
        icon={"icon-heart-filled"}
        value={numeral(likeValue).format("0a")}
        fill={"red"}
      />
    </PostStatisticsWrapper>
  );
};

export default PostStatistics;

import { Flex } from "rebass";

import styled, { css } from "styled-components";

export const CollapsableWrapper = styled(Flex)<{ collapsed: boolean }>`
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0;
`;

export const CollapsableButton = styled.button<{
  collapsed: boolean;
  rotate?: number;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  border: 0;
  outline: none;
  color: ${props => props.theme.colors.white};
  background: none;
  cursor: pointer;
  gap: 12px;

  padding: 10px 8px;
  font-weight: 600;
  font-size: 13px;
  line-height: 125%;

  span {
    font-size: 20px;
    color: ${props => props.theme.colors.text03};
  }

  .label {
    font-size: 14px;
    margin-bottom: 0;
    color: ${props => props.theme.colors.text01};
  }

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.blue};
    border-radius: 8px;
    .label {
      color: ${props => props.theme.colors.white};
    }
    span {
      color: ${props => props.theme.colors.white} !important;
    }
  }

  ${props => {
    const degree = `${props.rotate || 180}deg`;

    if (props.collapsed) {
      return css`
        transform-origin: center;
        transform: rotate(${degree});
        -webkit-transform: rotate(${degree});
        -ms-transform: rotate(${degree});
      `;
    }
  }}
`;

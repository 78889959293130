import { Box, Flex } from "rebass/styled-components";
import { useToaster } from "@hellocontento/maillard";
import { IAccount } from "@hellocontento/contento-common";
import React, { useCallback, useEffect, useState } from "react";
import { Field, reduxForm, InjectedFormProps } from "redux-form";

import { RequiredIndicator } from "./styles";
import Button from "components/common/Button";
import { useAppState } from "contextApi/appContext";
import Loader from "components/common/loading/Loader";
import { timezones, countries } from "constants/index";
import TextInput from "components/common/form/TextInput";
import { PlainSelect } from "components/common/form/Select";
import { fetchIndustryCategories } from "services/onboarding";
import { required, validWebsite, isTelenetCustomerId } from "utils/Validation";

const AccountForm: React.FC<InjectedFormProps<IAccount>> = ({
  submitting,
  handleSubmit
}) => {
  const addToast = useToaster();
  const [industries, setIndustries] = useState<string[]>([]);
  const client = useAppState(state => state.clientSettings.client);

  const getIndustries = useCallback(async () => {
    try {
      const industryCategories = await fetchIndustryCategories();

      setIndustries(
        industryCategories.map(ic => ({
          key: ic,
          value: ic
        }))
      );
    } catch (error) {
      addToast((error as Error).message, "error");
    }
  }, []);

  useEffect(() => {
    getIndustries();
  }, [getIndustries]);

  return (
    <form name="setupAccount" onSubmit={handleSubmit}>
      <div>
        <RequiredIndicator>
          <Field
            autoFocus={true}
            component={TextInput}
            id="company"
            label="Company name"
            name="companyName"
            placeholder="Acme Industries"
            validate={[required]}
          />
        </RequiredIndicator>
        <RequiredIndicator>
          <Field
            component={TextInput}
            id="website"
            key={"companyWebsite"}
            label="Website"
            name="companyWebsite"
            placeholder="www.acme.com"
            validate={[required, validWebsite]}
          />
        </RequiredIndicator>
        <RequiredIndicator>
          <Field
            component={PlainSelect}
            helperText="This will be used to suggest relevant industry information"
            id="industry"
            key={"industry"}
            label="Company Industry"
            name="industry"
            options={industries}
            placeholder="e.g., Marketing"
            validate={[required]}
          />
        </RequiredIndicator>
        <RequiredIndicator>
          <Field
            component={PlainSelect}
            helperText="This will be used to set the timezone of your calendar"
            id="country"
            key={"country"}
            label="Country"
            name="billingCountry"
            options={countries}
            placeholder="e.g., Belgium"
            validate={[required]}
          />
        </RequiredIndicator>
        <RequiredIndicator>
          <Field
            component={PlainSelect}
            id="timezone"
            helperText="Please specify where exactly you are located"
            key={"timezone"}
            label="Timezone"
            name="timezone"
            options={timezones}
            validate={[required]}
          />
        </RequiredIndicator>
        {!!client && client === "telenet" && (
          <>
            <Field
              component={TextInput}
              id="phoneNumber"
              label="Phone number"
              name="phone"
              helperText="Please provide your phone number"
              validate={[required]}
            />
            <Field
              component={TextInput}
              id="telenetAccountManager"
              label="Telenet Account Manager"
              name="telenetAccountManager"
              helperText="Who is your contact person at Telenet?"
            />
            <Field
              component={TextInput}
              id="telenetCustomerId"
              label="Telenet Customer ID"
              name="telenetCustomerId"
              helperText="Please provide your Telenet Customer ID (klantennummer)"
              validate={[isTelenetCustomerId]}
            />
          </>
        )}
        <Box pt={8} pb={16}>
          {/* @ts-ignore */}
          <Button size="lg" disabled={submitting} width="100%">
            {submitting ? (
              <Flex justifyContent={"center"}>
                <Loader size={24} />
              </Flex>
            ) : (
              "Save Account"
            )}
          </Button>
        </Box>
      </div>
    </form>
  );
};

export default reduxForm<IAccount>({
  form: "setupAccount",
  enableReinitialize: true
})(AccountForm);

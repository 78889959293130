import React, { useState } from "react";

import CalendarTask from "./CalendarTask";
import CalendarPost from "./CalendarPost";
import { CalendarEntry as Entry } from "./styles";

function CalendarEntry({
  entry,
  height,
  accountId,
  maxWidth = "",
  parent = null,
  autoHeight = false,
  dashboardEntry = false,
  contextWithPortal = true,
  attachmentHeight = null,
  fullAttachmentHeight = false
}) {
  const [hover, setHover] = useState(false);

  return (
    <Entry
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      $height={height}
      $maxWidth={maxWidth}
    >
      {entry.post ? (
        <CalendarPost
          hover={hover}
          entry={entry}
          contextWithPortal={contextWithPortal}
          parent={parent}
          autoHeight={autoHeight}
          dashboardEntry={dashboardEntry}
          attachmentHeight={attachmentHeight}
          fullAttachmentHeight={fullAttachmentHeight}
        />
      ) : (
        <CalendarTask accountId={accountId} hover={hover} entry={entry} />
      )}
    </Entry>
  );
}

export default CalendarEntry;

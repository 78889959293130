export enum FEATURE {
  NEW = "NEW",
  ACTIVE = "ACTIVE",
  COMING_SOON = "COMING_SOON"
}

export interface IBlock {
  image: any;
  title: string;
  description: string;
  action: () => void;
  active: FEATURE;
}

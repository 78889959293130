import { CHANNEL_SERVICES } from "./channels";

const USAGE_LIMITS = {
  PERSONAL_BRANDING: {
    teamMembers: 1,
    socialSets: 1
  },
  SMALL_BIZ: {
    teamMembers: null,
    socialSets: null
  }
};

export function channelLimitReached(account, serviceName) {
  const [service, serviceType] = serviceName.split("_");
  const limits = USAGE_LIMITS[account.billing?.willowPlan];

  if (limits) {
    const connectedChannels = account.channels.filter(
      c => c.service === service && c.serviceType === serviceType
    ).length;
    if (limits.socialSets !== null && connectedChannels >= limits.socialSets) {
      return true;
    }
  }
  return false;
}

export function teamMemberLimitReached(account) {
  return false;
}

export function usageLimitsInfo(account, accountUsers) {
  const limits = USAGE_LIMITS[account.billing?.willowPlan];

  const teamMemberCount = accountUsers ? accountUsers.length : 0;
  const connectedChannelsCount = account.channels.length;

  return {
    channels: {
      used: connectedChannelsCount,
      remaining: limits.socialSets
        ? limits.socialSets * CHANNEL_SERVICES.length - connectedChannelsCount
        : null
    },
    socialSets: {
      available: limits.teamMembers
    },
    teamMembers: {
      used: teamMemberCount,
      remaining: limits.teamMembers - teamMemberCount,
      available: limits.teamMembers
    }
  };
}

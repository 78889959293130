import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Field, reduxForm, reset } from "redux-form";
import PhoneInput from "react-phone-number-input";
import {
  required,
  validEmail,
  validWebsite,
  validPassword,
  repeatPassword
} from "utils/Validation";
import { timezones } from "../../constants";
import Button from "components/common/Button";
import { handleOpenChat } from "utils/AppActions";
import { NativeLink } from "components/common/Link";
import { SettingsForm, SettingsGrid } from "./styles";
import TextInput from "components/common/form/TextInput";
import { PlainSelect } from "components/common/form/Select";
import PasswordInput from "components/common/form/PasswordInput";
import {
  SettingsSubTitle,
  WhatsappForm,
  WhatsappGrid,
  WhatsappPhoneInputContainer,
  WhatsappSubGrid,
  WhatsappSubText,
  WhatsappWrapper
} from "./styles/notifications";
import WhatsappLogo from "assets/images/whatsapp-image-icon.png";
// TODO: Every single form component should be under forms domain
import "react-phone-number-input/style.css";

const afterSubmit = formName => (result, dispatch) => dispatch(reset(formName));

export const ConnectionSettingsForm = reduxForm({
  form: "settingsWhatsappForm",
  enableReinitialize: true
})(({ handleSubmit, initialValues, values }) => {
  const [country, setCountry] = useState("BE");

  return (
    <WhatsappWrapper>
      <WhatsappForm onSubmit={handleSubmit}>
        <SettingsSubTitle>Connect to Willow for WhatsApp</SettingsSubTitle>
        <WhatsappSubText>
          Once saved, Willow for WhatsApp will begin a chat with you. If you
          don’t receive an onboarding message within a day of submitting your
          number please contact customer support. We ask that you attach the
          number to a team member so we can attribute submissions. We do not use
          this data for marketing purposes.
        </WhatsappSubText>
        <WhatsappGrid>
          <WhatsappSubGrid>
            <img
              src={WhatsappLogo}
              width={40}
              height={40}
              alt={"Whatsapp Integration"}
            />
            <WhatsappSubText color={"black"}>Phone Number</WhatsappSubText>
          </WhatsappSubGrid>
          <Field
            validate={[required]}
            name="phone"
            defaultValue={""}
            component={props => (
              <WhatsappPhoneInputContainer>
                <PhoneInput
                  {...props}
                  smartCaret={true}
                  international
                  className="phone-input"
                  countryCallingCodeEditable={false}
                  defaultCountry={country}
                  onCountryChange={c => setCountry(c)}
                  onChange={v => {
                    if (props.input.onChange) {
                      props.input.onChange(v);
                    }
                  }}
                  withCountryCallingCode={true}
                />
              </WhatsappPhoneInputContainer>
            )}
          />
        </WhatsappGrid>
      </WhatsappForm>
      <Button
        mt={32}
        width={"100%"}
        variant="primary"
        size="lg"
        type="button"
        onClick={() => handleSubmit(values)}
      >
        Save changes
      </Button>
    </WhatsappWrapper>
  );
});

export const UserSettings = reduxForm({
  form: "settingsAccountForm",
  enableReinitialize: true
})(({ handleSubmit, initialValues }) => {
  return (
    <SettingsForm>
      <SettingsGrid>
        <Field
          validate={[required]}
          label="First Name"
          defaultValue={initialValues.firstName}
          component={TextInput}
          name="firstName"
        />
        <Field
          validate={[required]}
          label="Last Name"
          defaultValue={initialValues.lastName}
          component={TextInput}
          name="lastName"
        />
      </SettingsGrid>

      <Field
        validate={[required, validEmail]}
        label="Email address"
        helperText={React.createElement(() => (
          <div>
            This email will be associated with your Willow account.
            <br />
            To update the email for your billing please{" "}
            <button onClick={handleOpenChat}>contact support.</button>
          </div>
        ))}
        defaultValue={initialValues.email}
        component={TextInput}
        name="email"
      />

      <Button mt={32} width={"100%"} variant="primary" size="lg" type="submit">
        Save changes
      </Button>
    </SettingsForm>
  );
});

export const CompanySettings = reduxForm({
  form: "settingsCompanyForm",
  enableReinitialize: true
})(({ handleSubmit, initialValues }) => {
  return (
    <SettingsForm onSubmit={handleSubmit}>
      <SettingsGrid>
        <Field
          validate={[required]}
          label="Company name"
          defaultValue={initialValues.companyName}
          component={TextInput}
          name="companyName"
        />
        <Field
          validate={[required, validWebsite]}
          label="Website"
          defaultValue={initialValues.companyWebsite}
          component={TextInput}
          name="companyWebsite"
        />
      </SettingsGrid>
      <Field
        validate={[required]}
        label="Timezone"
        helperText="This will be used for your calendar."
        options={timezones}
        component={PlainSelect}
        name="timezone"
      />
      <Button mt={32} width={"100%"} variant="primary" size="lg" type="submit">
        Save changes
      </Button>
    </SettingsForm>
  );
});

export const UserPassword = reduxForm({
  form: "settingsPasswordForm",
  enableReinitialize: true,
  onSubmitSuccess: afterSubmit("settingsPasswordForm")
})(({ handleSubmit }) => {
  return (
    <SettingsForm onSubmit={handleSubmit}>
      <Field
        type="password"
        validate={[required]}
        label="Current Password"
        supportLink={React.createElement(() => (
          <span>
            <NativeLink
              $underline={true}
              href="/forgot-password"
              target="_blank"
            >
              Forgot your password?
            </NativeLink>
          </span>
        ))}
        component={PasswordInput}
        name="currentPassword"
      />
      <Field
        type="password"
        helperText="Your password must contain letters and numbers and be at least 6 characters long."
        validate={[required, validPassword]}
        label="New password"
        component={PasswordInput}
        name="password"
      />
      <Field
        type="password"
        validate={[required, repeatPassword]}
        label="Confirm new password"
        component={PasswordInput}
        name="repeat_password"
      />
      <Button mt={32} width={"100%"} variant="primary" size="lg" type="submit">
        Save changes
      </Button>
    </SettingsForm>
  );
});

export const InviteMember = reduxForm({
  form: "settingsInviteMember",
  enableReinitialize: true
})(({ account, handleSubmit }) => {
  const history = useHistory();
  const plan = account.billing?.willowPlan;

  const handleUpgrade = e => {
    e.preventDefault();
    history.push(`/accounts/${account.id}/settings/billing`);
  };

  return (
    <SettingsForm onSubmit={handleSubmit}>
      <SettingsGrid>
        <Field
          validate={[required]}
          label="First name"
          placeholder={"John"}
          component={TextInput}
          name="firstName"
        />
        <Field
          validate={[required]}
          label="Last name"
          placeholder={"Doe"}
          component={TextInput}
          name="lastName"
        />
      </SettingsGrid>
      <Field
        validate={[required, validEmail]}
        type="email"
        label="Email"
        placeholder={"team@company.com"}
        component={TextInput}
        name="email"
      />
      {plan === "PERSONAL_BRANDING" ? (
        <Button
          mt={32}
          width={"100%"}
          variant={"secondary"}
          onClick={handleUpgrade}
          size="lg"
        >
          Upgrade to invite
        </Button>
      ) : (
        <Button
          mt={32}
          variant="primary"
          width={"100%"}
          size="lg"
          type="submit"
        >
          Invite
        </Button>
      )}
    </SettingsForm>
  );
});

export const ReferralForm = reduxForm({
  form: "settingsReferralForm",
  enableReinitialize: true
})(({ handleSubmit }) => {
  return (
    <SettingsForm onSubmit={handleSubmit}>
      <SettingsGrid>
        <Field
          validate={[required]}
          label="First name"
          placeholder={"John"}
          component={TextInput}
          name="firstName"
        />
        <Field
          validate={[required]}
          label="Last name"
          placeholder={"Doe"}
          component={TextInput}
          name="lastName"
        />
      </SettingsGrid>
      <Field
        validate={[required, validEmail]}
        type="email"
        label="Email"
        placeholder={"team@company.com"}
        component={TextInput}
        name="email"
      />
      <Button mt={32} variant="primary" width={"100%"} size="lg" type="submit">
        Refer willow
      </Button>
    </SettingsForm>
  );
});

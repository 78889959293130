export const WILLOW_PLAN = {
  SMALL_BIZ: "SMALL_BIZ",
  PERSONAL_BRANDING: "PERSONAL_BRANDING"
};

export const BILLING_STATUS = {
  IN_TRIAL: "in_trial"
};

export const DEFAULT_MAX_CHANNELS = 20;

import { Flex, Image } from "rebass";
import styled, { css } from "styled-components";

export const TooltipOption = styled.div<{
  variant?: string;
  size?: number;
  disabled?: boolean;
  center?: boolean;
  isSelected?: boolean;
}>`
  padding: 8px 8px;
  font-weight: 500;
  list-style: none;
  cursor: pointer;
  margin: 0px 8px;
  color: ${props => props.theme.colors.text01};
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.center &&
    css`
      justify-content: center;
    `}

  ${props =>
    props.variant &&
    ["primary", "danger"].includes(props.variant) &&
    css`
      color: ${props.theme.variants[props.variant].fontColor};
    `};

  ${props =>
    !props.disabled &&
    !props.isSelected &&
    css`
      :hover {
        color: ${props.theme.colors.white};
        border-radius: 10px;
        background-color: ${props.theme.colors.blue};
        span {
          color: ${props.theme.colors.white};
        }
      }
    `}

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      color: ${props => props.theme.colors.text04};
    `}

    ${props =>
    props.isSelected &&
    css`
        border-radius: 10px;
        background-color: ${props.theme.colors.grey01};
      }
    `}

  span {
    font-size: ${props => (props.size ? props.size : 16)}px;
    ${props =>
      props.variant &&
      ["primary", "danger"].includes(props.variant) &&
      css`
        color: ${props.theme.variants[props.variant].fontColor};
      `};
  }
`;

export const TooltipOptionLeft = styled(Flex)`
  gap: 10px;
  align-items: center;
`;

export const TooltipMenu = styled.div<{ radius?: number; float?: boolean }>`
  background-color: white;
  border-radius: ${props => props.radius || 8}px;
  box-shadow:
    0px 8px 16px -8px rgba(28, 30, 31, 0.12),
    0px 18px 27px -5px rgba(28, 30, 31, 0.15),
    0px 0px 0px 1px rgba(28, 30, 31, 0.05);
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  z-index: 5;
`;

export const TooltipMenuWrapper = styled.div<{
  minWidth?: number;
}>`
  min-width: ${props => props.minWidth || 240}px;
`;

export const IconWrapper = styled(Flex)<{ iconBg?: string }>`
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  span {
    font-size: 20px;
  }

  ${props =>
    props.iconBg === "inverse" &&
    css`
      background: ${props.theme.colors.black};
      color: ${props.theme.colors.white};
      span {
        font-size: 12px;
      }
    `}
`;

export const ImageIcon = styled(Image)`
  width: 20px;
  height: 20px;
  border-radius: 8px;
`;

import React from "react";
import styled from "styled-components";
import {
  StyledInputFieldset,
  ErrorMessage,
  StyledInputLabel,
  StyledSelect,
  HelperText
} from "./styles";
import { ChevronDown } from "styled-icons/feather";

// TODO: Agree on a single style of inputs and replace all
export function PlainSelect({
  input,
  label,
  meta,
  options,
  type,
  helperText,
  ...props
}) {
  const { touched, error } = meta;

  return (
    <StyledInputFieldset width={1} mb={16} {...props}>
      <StyledInputLabel fontWeight="bold">{label}</StyledInputLabel>
      {helperText && <HelperText>{helperText}</HelperText>}
      <StyledSelect type={type} {...input}>
        <option key={"select"} value={""} defaultChecked>
          Select
        </option>

        {options.map(item => (
          <option key={item.key} value={item.key}>
            {item.value}
          </option>
        ))}
      </StyledSelect>
      {error && touched && <ErrorMessage>{error}</ErrorMessage>}
    </StyledInputFieldset>
  );
}

const sizes = {
  sm: {
    borderRadius: "4px",
    fontSize: "14px",
    px: "10px",
    py: "8px"
  },
  m: {
    borderRadius: "6px",
    fontSize: "16px",
    px: "12px",
    py: "8px"
  },
  lg: {
    borderRadius: "8px",
    fontSize: "16px",
    px: "16px",
    py: "12px"
  }
};

const StyledNativeSelect = styled.select`
  appearance: none;
  background-color: ${props =>
    !props.variant ? "white" : props.theme.variants[props.variant].bg};
  border-radius: ${props => sizes[props.size].borderRadius};
  box-sizing: border-box;
  border: ${props =>
    !props.variant ? `1px solid ${props.theme.colors.grey03}` : "none"};
  color: ${props => props.theme.colors.text01};
  flex: 1;
  font-size: ${props => sizes[props.size].fontSize};
  padding: ${props => `${sizes[props.size].py} ${sizes[props.size].px}`};
  width: 100%;

  &:hover {
    background-color: ${props =>
      !props.variant ? "white" : props.theme.variants[props.variant].hover.bg};
    border: ${props =>
      !props.variant ? `1px solid ${props.theme.colors.primary}` : "none"};
  }
`;

const NativeSelectContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  margin: 0 4px;
  position: relative;
`;

const NativeSelectChevron = styled(ChevronDown).attrs(() => ({
  size: 16
}))`
  position: absolute;
  right: 12px;
`;

export function NativeSelect({
  name,
  onChange,
  variant,
  size = "m",
  children
}) {
  return (
    <NativeSelectContainer>
      <StyledNativeSelect
        name={name}
        onChange={onChange}
        variant={variant}
        size={size}
      >
        {children}
      </StyledNativeSelect>
      <NativeSelectChevron />
    </NativeSelectContainer>
  );
}

import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import { isValidEmail } from "../../utils/Validation";

const components = {
  DropdownIndicator: null
};

const selectStyles = {
  container: styles => ({ ...styles, width: "100%" })
};

const MultiEmailInput = ({ value, onChange, ...props }) => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = val => {
    onChange(val ? val : []);
  };

  const createOption = label => ({
    label,
    value: label
  });

  const handleKeyDown = event => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
      case " ":
        if (
          isValidEmail(inputValue) &&
          !value.map(v => v.value).includes(inputValue)
        ) {
          //validate email before adding
          setInputValue("");
          onChange([...value, createOption(inputValue)]);
        }
        event.preventDefault();
        break;
      default:
        break;
    }
  };
  return (
    <CreatableSelect
      components={components}
      inputValue={inputValue}
      isClearable
      isMulti
      menuIsOpen={false}
      onChange={handleChange}
      onInputChange={val => setInputValue(val)}
      onKeyDown={handleKeyDown}
      placeholder="Enter 5 emails with spaces"
      value={value}
      styles={selectStyles}
      {...props}
    />
  );
};

export default MultiEmailInput;

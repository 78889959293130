import styled from "styled-components";

export const WelcomeScreenContainer = styled.div`
  display: flex;
  height: 100vh;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
  padding: 10px;
  gap: 20px;
  border-radius: 1861px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 99, 251, 0.6) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 2396px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(255, 109, 49, 0.25) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-radius: 1630px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(123, 97, 255, 0.6) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }
`;

export const GreetingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  flex: 1 0 0;
  z-index: 999;

  @keyframes fadeInBottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      pointer-events: unset;
    }
  }

  > img {
    width: 98px;
    padding: 10px;
    object-fit: contain;
    background-color: transparent;
    margin-bottom: 20px;
    opacity: 0;
    animation-name: fadeInBottom;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }

  .get-started-btn {
    width: 233px;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.blue};
    font-size: 24px;
    line-height: 20px;
    opacity: 0;
    animation-name: fadeInBottom;
    animation-duration: 1s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    pointer-events: none;
  }

  > iframe {
    width: 850px;
    height: 700px;

    @media screen and (max-width: ${props => props.theme.breakpoints[2]}) {
      width: 450px;
      height: 700px;
    }
  }
`;

export const Title = styled.span`
  font-size: 40px;
  font-weight: 700;
  line-height: 125%;
  color: ${props => props.theme.colors.white};
  margin-bottom: 8px;
  opacity: 0;
  animation-name: fadeInBottom;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;

  &.meeting-title {
    font-size: 24px;
    color: ${props => props.theme.colors.black};
  }
`;

export const Subtitle = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 125%;
  color: ${props => props.theme.colors.white};
  margin-bottom: 27px;
  opacity: 0;
  animation-name: fadeInBottom;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
`;

import styled from "styled-components";
import { Box, Image, Flex } from "rebass/styled-components";
import ProgressCurrentIcon from "../../assets/images/blog/progress-current.png";
import ProgressDoneIcon from "../../assets/images/blog/progress-done.png";
import ProgressUpcomingIcon from "../../assets/images/blog/progress-upcoming.png";
import ProgressLine from "../../assets/images/blog/progress-line.png";
import ProgressLineDone from "../../assets/images/blog/progress-line-done.png";
import clockIcon from "../../assets/images/blog/clock.png";

export const BlogPageWrapper = styled(Flex)`
  width: 75%;
  max-width: 1160px;
  margin: 22px auto 0 auto;

  @media screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
`;

export const BlogSideColumnWrapper = styled(Flex)`
  flex-grow: 0;
  flex-shrink: 0;
  width: 250px;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const BlogMainColumnWrapper = styled(Flex)`
  margin-left: 40px;

  @media screen and (max-width: 1024px) {
    margin-left: 0;
    width: 100%;
  }
`;

export const BlogSideColumn = styled(Box)`
  width: 100%;
  color: #1c1e21;
`;

export const SidePicture = styled(Image)`
  width: 100%;
  height: auto;
  display: block;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const SidePictureSmall = styled(Image)`
  width: 100%;
  height: auto;
  display: block;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const MainTitleSide = styled.h2`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  margin-top: 20px;
  margin-bottom: 0;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

export const DeliveryTime = styled(Box)`
  margin-top: 18px;
  font-size: 14px;
  font-weight: 600;
  color: #1c1e21;
  background: url("${clockIcon}") no-repeat left top;
  background-size: 16px 16px;
  height: 16px;
  padding-left: 26px;
  line-height: 20px;
`;

export const Price = styled(Flex)`
  margin-top: 36px;
  justify-content: space-between;
  width: 100%;

  & em {
    font-weight: bold;
    display: block;
    line-height: 1.43;
    margin-bottom: 2px;
  }

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
`;

export const Workflow = styled(Box)`
  margin-top: 36px;

  & em {
    font-weight: bold;
    display: block;
  }

  & ul {
    margin-top: 13px;
  }

  & li {
    color: #1c1e21;
    padding-left: 27px;
    padding-bottom: 30px;
    background:
      url("${ProgressUpcomingIcon}") no-repeat left 2px top,
      url("${ProgressLine}") no-repeat top left 8px;
    background-size:
      14px 14px,
      auto;

    & span {
      position: relative;
      top: -2px;
    }

    &.done {
      background-image: url("${ProgressDoneIcon}"), url("${ProgressLineDone}");
    }

    &.current {
      background-image: url("${ProgressCurrentIcon}"), url("${ProgressLine}");
    }
  }

  & li:last-child {
    background:
      url("${ProgressUpcomingIcon}") no-repeat left 2px top,
      none;
    background-size: 14px 14px;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const BlogMainColumn = styled(Box)`
  width: 100%;
  color: #1c1e21;
`;

export const MainTitle = styled.h2`
  font-size: 28px;
  font-weight: 900;
  line-height: 1.25;
  margin-bottom: 16px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const MainSection = styled.section`
  border-top: 1px solid #dadde1;

  @media screen and (max-width: 1024px) {
    margin-top: 32px;
  }
`;

export const Description = styled.p`
  margin-top: 32px;
  color: #1c1e21;
  margin-bottom: 32px;
`;

export const Features = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;

  & li:nth-child(2n + 1) {
    margin-right: 16px;
  }
`;

export const Feature = styled.li`
  color: #1c1e21;
  background: #f8f8fa;
  padding: 25px 22px;
  margin-bottom: 16px;
  box-sizing: border-box;
  width: calc(50% - 8px);

  @media screen and (max-width: 1280px) {
    width: 100%;
    margin-right: 0 !important;
  }
`;

export const FeatureName = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  margin-bottom: 4px;
`;

export const FeatureDescription = styled.div`
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.16px;
`;

export const Examples = styled.section`
  border-top: 1px solid #dadde1;
  padding-top: 10px;
`;

export const ExamplesTitle = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  margin-bottom: 6px;
`;

export const ExampleList = styled.ul`
  flex-wrap: wrap;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
`;

export const Example = styled.li`
  width: 49%;
  margin-bottom: 16px;

  @media screen and (max-width: 1280px) {
    width: 100%;
  }
`;

export const ExampleImage = styled(Image)`
  width: 100%;
  height: auto;
`;

export const SentMessage = styled.div`
  font-size: 14px;
  margin: 10px 0 30px 0;
  text-align: center;

  @media screen and (max-width: 1024px) {
    text-align: left;
  }
`;

import React from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import { Flex } from "rebass/styled-components";
import { Link, useHistory } from "react-router-dom";

import {
  WidgetCard,
  WidgetBody,
  WidgetTitle,
  WidgetHeader,
  WidgetFooter,
  PostCardWrapper,
  WidgetEmptyIcon,
  WidgetEmptyTitle,
  WidgetEmptyContent,
  WidgetEmptyDescription
} from "./styles";
import EmptyTaskList from "../emptyTaskList";
import Button from "components/common/Button";
import MonthPost from "components/schedule/month/MonthPost";
import MonthTask from "components/schedule/month/MonthTask";
import emptyIcon from "assets/images/dashboard-tasks-empty.svg";
import { useDashboardState } from "contextApi/dashboardContext";

function TaskEmptyState({ channels, accountId }) {
  const history = useHistory();

  return channels.length !== 0 ? (
    <>
      <WidgetEmptyContent>
        <WidgetEmptyIcon>
          <img src={emptyIcon} width="64" alt="No tasks" />
        </WidgetEmptyIcon>
        <WidgetEmptyTitle>No tasks ahead</WidgetEmptyTitle>
        <WidgetEmptyDescription>
          Planning your calendar ahead helps you stay consistent.
        </WidgetEmptyDescription>
      </WidgetEmptyContent>
      <Box marginTop="auto">
        <Button
          marginBottom={8}
          width="100%"
          onClick={() =>
            history.push(`/accounts/${accountId}/schedule/month?taskId=new`)
          }
        >
          Create task
        </Button>
        <Button
          width="100%"
          variant="tertiary"
          onClick={() => history.push(`/accounts/${accountId}/ideas`)}
        >
          Get inspired
        </Button>
      </Box>
    </>
  ) : (
    <WidgetEmptyContent>
      <WidgetEmptyIcon>
        <img src={emptyIcon} width="64" alt="No tasks" />
      </WidgetEmptyIcon>
      <WidgetEmptyTitle>No tasks ahead</WidgetEmptyTitle>
      <WidgetEmptyDescription>
        Please{" "}
        <Link to={`/accounts/${accountId}/settings/channels`}>
          connect a channel
        </Link>{" "}
        in order to start creating tasks and planning your calendar
      </WidgetEmptyDescription>
    </WidgetEmptyContent>
  );
}

function TaskWidget() {
  const history = useHistory();
  const account = useSelector(state => state.account.data);
  const todos = useDashboardState(state => state.activities.entries.todos);
  const isFetching = useDashboardState(state => state.activities.isFetching);

  const handleViewSchedule = () => {
    history.push(`/accounts/${account.id}/schedule`);
  };

  return (
    <WidgetCard>
      <WidgetHeader>
        <WidgetTitle>Your to-do’s</WidgetTitle>
      </WidgetHeader>
      {isFetching ? (
        <WidgetBody>
          <Flex justifyContent="center" alignItems="center" flex="1">
            <ReactLoading color="#bbb" type="spin" width={20} />
          </Flex>
        </WidgetBody>
      ) : account.channels.length < 1 ? (
        <WidgetBody fullContainer>
          <EmptyTaskList />
        </WidgetBody>
      ) : todos.length > 0 ? (
        <WidgetBody>
          {todos.map(item => (
            <div key={item.id || item.task.id}>
              {item.type === "TASK" ? (
                <MonthTask
                  entry={item}
                  dashboardEntry
                  day={{ isOtherMonth: false }}
                />
              ) : (
                <PostCardWrapper>
                  <MonthPost entry={item} dashboardEntry />
                </PostCardWrapper>
              )}
            </div>
          ))}
        </WidgetBody>
      ) : (
        <TaskEmptyState channels={account.channels} accountId={account.id} />
      )}
      {account.channels.length < 1 ||
        (todos.length > 0 && (
          <WidgetFooter>
            <Button
              size="xs"
              variant="secondaryQuietAlt"
              onClick={handleViewSchedule}
            >
              View calendar
            </Button>
          </WidgetFooter>
        ))}
    </WidgetCard>
  );
}

export default TaskWidget;

import { Box, Flex } from "rebass";
import styled, { css } from "styled-components";

export const ItemWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LeftSection = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;

export const SourceWrapper = styled(Box)`
  position: relative;
`;

export const SourceImageAlternative = styled.div<{ borderStyle: string }>`
  background: ${props => props.theme.colors.grey03};
  border-radius: ${props => {
    switch (props.borderStyle) {
      case "square":
        return "4px";
      default:
        return "50%";
    }
  }};
  width: 20px;
  height: 20px;
`;

export const SourceImage = styled.img<{ borderStyle: string }>`
  box-sizing: border-box;
  border: 1px solid rgba(96, 116, 128, 0.12);
  background: ${props => props.theme.colors.grey03};
  border-radius: ${props => {
    switch (props.borderStyle) {
      case "square":
        return "4px";
      default:
        return "50%";
    }
  }};
  width: 20px;
  height: 20px;
`;

export const SourceType = styled.div<{ source?: string }>`
  box-sizing: border-box;
  ${props =>
    props.source &&
    css`
      background: url(${props.source}) no-repeat center center;
      background-size: cover;
    `}
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(45%, 0%);
`;

export const Label = styled.div<{ isCurrent?: boolean }>`
  display: flex;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 13px;
  line-height: 125%;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const ShowMoreWrapper = styled(Flex)<{ collapsed: boolean }>`
  flex-direction: row;
  gap: 12px;
  justify-content: flex-start;
  align-items: center;
`;

export const ShowMoreButton = styled.button<{
  collapsed: boolean;
  rotate?: number;
}>`
  display: inline-flex;
  margin-left: 32px;
  align-items: center;
  justify-content: flex-start;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
  gap: 12px;
  font-size: 18px;
  padding: 4px 8px;
  line-height: 125%;
  color: ${props => props.theme.colors.blue};

  .label {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 0;
  }

  .icon {
    background: rgba(102, 119, 128, 0.16);
    border-radius: 4px;
  }

  &:hover {
    text-decoration: underline;
  }

  .rotate-90 {
    transform-origin: center;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }
`;

export const SourceKeywordImage = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(96, 116, 128, 0.12);
  background: ${props => props.theme.colors.black};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transform-origin: center;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);

  > span {
    color: white;
    font-size: 14px;
    font-weight: 600;
  }
`;

import _ from "lodash";

import store from "state/store";
import { callApi } from "utils/ContentoApi";
import { FeatureNotificationStatus } from "contextApi/notificationContext";
import { accountUpdated, fetchAccount } from "state/actions/AccountActions";

export const updateFeatureNotificationEntry = async (
  id: string,
  status: FeatureNotificationStatus
): Promise<any> => {
  const account = store.getState().account.data;

  try {
    const { accountMetadata } = await callApi({
      method: "post",
      url: `/accounts/${account.id}/metadata`,
      data: {
        id,
        status
      }
    });

    store.dispatch(
      accountUpdated({
        ...account,
        accountMetadata
      })
    );
  } catch (error) {
    throw new Error(`There was an error saving the feature notifications.`);
  }
};

export const startCoachingTrial = async (): Promise<string> => {
  const account = store.getState().account.data;

  try {
    const { hubspotCoachingLink } = await callApi({
      method: "get",
      url: `/billing/${account.id}/coaching-trial`
    });

    store.dispatch(fetchAccount(account.id));
    return hubspotCoachingLink;
  } catch (error) {
    throw new Error(`There was an error saving the feature notifications.`);
  }
};

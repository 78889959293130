import React, { useState } from "react";
import { Label, LabelContainer, PanelWrapper, MinimizedIcon } from "./styles";
import { AccordionButton, NavigationItem, ExploreButton } from "../../styles";
import Popper from "components/common/Popper";
import ContextMenu from "components/common/contextMenu";

interface IPanelItemProps {
  label: string;
  match: any;
  isExpanded: boolean;
  collapsed: boolean;
  to: string;
  title: string;
  icon: {
    default: string;
    active: string;
  };
  contextMenu: Array<any>;
  expand?: () => void;
  onAddClick: () => void;
  showAddButton?: boolean;
  children?: JSX.Element[] | JSX.Element;
}

const PanelTitle: React.FC<IPanelItemProps> = props => {
  const [onHover, setOnHover] = useState<boolean>(false);

  const isCurrent =
    `${props.match.url}/${props.to}` === window.location.pathname;

  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  return (
    <NavigationItem
      to={`${props.match.url}/${props.to}`}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      exact={true}
      px={6}
    >
      <PanelWrapper>
        {props.collapsed ? (
          <MinimizedIcon
            className={isCurrent ? props.icon.active : props.icon.default}
          />
        ) : (
          <>
            <LabelContainer>
              <AccordionButton
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  props.expand && props.expand();
                }}
                variant={"secondary"}
                collapsed={props.isExpanded}
                rotate={90}
                hover={onHover}
                isCurrent={isCurrent}
                isContextMenuActive={showOptions}
              >
                <span className="icon-arrowright" />
              </AccordionButton>
              <Label>{props.label}</Label>
            </LabelContainer>
            {props.showAddButton && (
              <>
                <ExploreButton
                  // @ts-ignore
                  ref={setReferenceElement}
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    toggleShowOptions();
                  }}
                  hover={onHover}
                  isCurrent={isCurrent}
                  isContextMenuActive={showOptions}
                >
                  <span className="icon-plus"></span>
                </ExploreButton>
                <Popper
                  offset={[-220, 8]}
                  placement="bottom-end"
                  referenceElement={referenceElement}
                  visible={showOptions}
                  onClose={() => setShowOptions(false)}
                  exceptions={[referenceElement]}
                >
                  <ContextMenu
                    close={() => setShowOptions(false)}
                    options={props.contextMenu}
                  />
                </Popper>
              </>
            )}
          </>
        )}
        {props.children}
      </PanelWrapper>
    </NavigationItem>
  );
};

export default PanelTitle;

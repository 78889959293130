import { Flex } from "rebass";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Headline1 } from "components/common/styles";

import { likeCounts, requestForRecommendedContents } from "services/news";

import * as accountActions from "state/actions/AccountActions";

import Button from "components/common/Button";
import { ItemGrid, SmallHeadline, ButtonContentWrapper } from "./style";
import LikeArticle from "./steps/LikeArticle";
import FollowSourceCard from "./steps/FollowSourceCard";
import DailyRecommendation from "./steps/DailyRecommendation";

// @ts-ignore
import { useToaster } from "@hellocontento/maillard";

import OnboardingCard from "./OnboardingCard";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import Loader from "components/common/loading/Loader";
import VideoPlayerModal from "components/modals/videoPlayerModal";

const NewsOnboarding: React.FC<{
  hasRecommendedContent: boolean;
  updateAccount: (data: any) => void;
}> = ({ hasRecommendedContent, updateAccount }) => {
  const history = useHistory();
  const addToast = useToaster();
  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const [likedArticles, setLikedArticles] = useState(0);

  const account = useSelector((state: any) => state.account.data);

  const follow_section = account.onboardingProgress.includes(
    OnboardingStepsEnum.FOLLOW_SECTION
  );
  const like_article = account.onboardingProgress.includes(
    OnboardingStepsEnum.LIKE_ARTICLE
  );
  const get_personalized_suggestions = account.onboardingProgress.includes(
    OnboardingStepsEnum.GET_PERSONALIZED_SUGGESTIONS
  );

  const requestRecommendation = async () => {
    try {
      setIsRequesting(true);
      await requestForRecommendedContents();
      updateAccount({
        ...account,
        onboardingProgress: [
          ...new Set([
            ...account.onboardingProgress.concat(
              OnboardingStepsEnum.GET_PERSONALIZED_SUGGESTIONS as string
            )
          ])
        ]
      });
      setTimeout(() => {
        setIsRequesting(false);
      }, 5000);
    } catch (_) {
      setIsRequesting(false);
    }
  };

  useEffect(() => {
    async function fetchCounts() {
      try {
        const data = await likeCounts();
        setLikedArticles(data.length);
      } catch (_) {}
    }
    fetchCounts();
  }, []);

  if (hasRecommendedContent && follow_section && like_article) return null;

  const steps = [
    {
      id: 1,
      isCompleted: follow_section,
      instruction: "Follow topics & sources",
      children: <FollowSourceCard />,
      action: (
        // @ts-ignore
        <Button
          variant="primary"
          onClick={() =>
            history.push(`/accounts/${account.id}/content/explore?type=topics`)
          }
        >
          Explore
        </Button>
      )
    },
    {
      id: 2,
      isCompleted: like_article || likedArticles,
      instruction: "Like or bookmark 3 articles",
      children: <LikeArticle />,
      // @ts-ignore
      action: <Button variant="primaryLite">Learn More</Button>
    },
    {
      id: 3,
      isCompleted: hasRecommendedContent,
      instruction: "Get daily recommendation",
      children: <DailyRecommendation />,
      action: get_personalized_suggestions ? (
        // @ts-ignore
        <Button variant="primary" onClick={() => history.go(0)}>
          See results → Refresh page
        </Button> // @ts-ignore
      ) : (
        // @ts-ignore
        <Button variant="primary" onClick={requestRecommendation}>
          <Flex flexDirection={"row"} alignItems="center">
            {isRequesting && <Loader size={18} />}
            Request
          </Flex>
        </Button>
      )
    }
  ];

  const upNext = (() => {
    const nextStep = steps.find(step => !step.isCompleted);
    if (nextStep) {
      return nextStep.id;
    } else return null;
  })();

  const layout = (() => {
    let gridLayout = Array(3).fill("1fr");
    if (upNext) gridLayout[upNext - 1] = "1.5fr";
    return gridLayout.join(" ");
  })();

  return (
    <>
      <VideoPlayerModal
        videoUrl={`https://www.youtube.com/embed/BCmIDKv_ZzI?autoplay=1&mute=1&start=90`}
        isOpen={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
      />
      <SmallHeadline>GET STARTED</SmallHeadline>
      <Flex maxWidth={"500px"} mb={"10px"}>
        <Headline1>
          Setup your news feed and get personalized recommendations
        </Headline1>
      </Flex>
      {/* @ts-ignore */}
      <Button variant={"secondary"} onClick={() => setIsVideoOpen(true)}>
        <ButtonContentWrapper>
          <span className="icon-play-button icon">
            <span className="path1" />
            <span className="path2" />
          </span>
          Learn more
        </ButtonContentWrapper>
      </Button>
      <ItemGrid layout={layout}>
        {steps.map(step => {
          return (
            <OnboardingCard
              key={step.id}
              isCompleted={step.isCompleted}
              isNext={step.id === upNext}
              item={step}
              action={step.action}
            >
              {step.children}
            </OnboardingCard>
          );
        })}
      </ItemGrid>
    </>
  );
};

export default connect(null, {
  updateAccount: accountActions.updateAccountAboutOnboarding
})(NewsOnboarding);

import * as activityActions from "../actions/ActivityActions";

const initialState = {
  activities: [],
  previews: [],
  params: {},
  draftCount: 0
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case activityActions.ADD_ACTIVITIES:
      return Object.assign({}, state, {
        activities: action.data.data,
        params: action.data.params
      });
    case activityActions.UPDATE_ACTIVITY:
      const updatedActivities = state.activities.map(activity => {
        if (activity.id !== action.data.id) return activity;

        if (action.data.type === "task") {
          return {
            ...activity,
            time: action.data.time,
            task: {
              ...activity.task,
              date: action.data.time
            }
          };
        } else if (["post", "draft"].includes(action.data.type)) {
          return {
            ...activity,
            time: action.data.time,
            [action.data.type]: {
              ...activity[action.data.type],
              isStuck: false,
              scheduledAt: action.data.time
            }
          };
        } else if (action.data.type === "post_group") {
          return {
            ...activity,
            time: action.data.time,
            group: {
              ...activity.group,
              isStuck: false,
              scheduledAt: action.data.time
            }
          };
        } else {
          return activity;
        }
      });

      return Object.assign({}, state, {
        ...state,
        activities: updatedActivities
      });
    case activityActions.CREATE_PHANTOM_ACTIVITY:
      return Object.assign({}, state, {
        activities: state.activities.concat(action.data)
      });
    case activityActions.REMOVE_PHANTOM_ACTIVITY:
      return Object.assign({}, state, {
        activities: state.activities.filter(activity => !activity.isPhantom)
      });
    case activityActions.ADD_PREVIEWS:
      //hide activities that have a link with the preview
      return Object.assign({}, state, {
        previews: action.data,
        hideTaskId: action.taskId,
        hideTaskGroupId: action.taskGroupId
      });
    case activityActions.REMOVE_PREVIEWS: {
      return Object.assign({}, state, {
        previews: [],
        hideTaskId: null,
        hideTaskGroupId: null
      });
    }
    default:
      return state;
  }
};

export default accountReducer;

import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useLayoutEffect
} from "react";

import {
  today,
  difference,
  startOfDay,
  formatForTimeline,
  parseStringToDate
} from "utils/date";
import FeedEntry from "./feedEntry";
import DayContextMenu from "./dayContextMenu";
import EmptyDayCard from "./feedEntry/EmptyDayCard";
import { FeedHeader, FeedContent, DayFeedLayout } from "./styles";
import { useTimelineState } from "components/schedule/timeline/context";

interface IDayFeed {
  date: string;
  dayEntries: any[];
}

const DayFeed: React.FC<IDayFeed> = ({ date, dayEntries }) => {
  const [isHoveredOver, setIsHoveredOver] = useState<boolean>(false);
  const timelineState = useTimelineState();
  const { postType } = timelineState;
  const ref = useRef<HTMLDivElement>(null);

  const onMouseEnter = useCallback(() => {
    setIsHoveredOver(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHoveredOver(false);
  }, []);

  useLayoutEffect(() => {
    if (!!postType) {
      Object.assign(timelineState.dateRefs[postType], { [date]: ref });
    }
  }, [timelineState.dateRefs, postType, date]);

  const dateDiffFromToday = useMemo(
    () =>
      difference(
        startOfDay(parseStringToDate(date, "yyyy/M/d")),
        startOfDay(today()),
        "days"
      ),
    [date]
  );

  const headerLabel = useMemo(() => formatForTimeline(new Date(date)), [date]);

  const isValidForMenu = useMemo(
    () => dateDiffFromToday >= 0,
    [dateDiffFromToday]
  );

  const showAddButton = isHoveredOver && isValidForMenu;

  const emptyLabel = useMemo(() => {
    if (
      dateDiffFromToday < 0 &&
      (postType === "published" || postType === "drafts")
    ) {
      return "Nothing posted for this week";
    } else if (dateDiffFromToday > 0 && postType === "drafts") {
      return "Nothing planned for next 7 days";
    } else if (dateDiffFromToday === -1) {
      return "Nothing posted yesterday";
    } else if (dateDiffFromToday === 0) {
      return "Nothing planned for today";
    } else if (dateDiffFromToday === 1) {
      return "Nothing planned for tomorrow";
    } else if (dateDiffFromToday < -1) {
      return "Nothing posted on this day";
    } else if (dateDiffFromToday > 1) {
      return "Nothing planned on this day";
    }
  }, [postType, dateDiffFromToday]);

  return (
    <DayFeedLayout
      ref={ref}
      data-date={date}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <FeedHeader>
        <span>{headerLabel}</span>
        {showAddButton && <DayContextMenu date={date} />}
      </FeedHeader>
      <FeedContent
        isEmpty={
          dayEntries.length < 1 &&
          (postType === "published" || dateDiffFromToday !== 0)
        }
      >
        {dayEntries.length < 1 ? (
          <>
            {postType !== "published" && dateDiffFromToday === 0 ? (
              <EmptyDayCard />
            ) : (
              <span className="empty">{emptyLabel}</span>
            )}
          </>
        ) : (
          dayEntries.map(entry => <FeedEntry key={entry.id} entry={entry} />)
        )}
      </FeedContent>
    </DayFeedLayout>
  );
};

export default DayFeed;

import { IClientSettings } from "contextApi/appContext/reducer";

import telenetOnboardingThumbnail from "assets/images/dashboard/telenet-onboarding-thumbnail.png";

export const clients: { [key: string]: Partial<IClientSettings> } = {
  telenet: {
    client: "telenet",
    allowedChannels: 3,
    chargebeePackage: "",
    onboardingVideoSettings: {
      linkId: "S-l-UUJb3Kw",
      thumbnail: telenetOnboardingThumbnail,
      timeStamps: {
        channel_connection: 29,
        content_calendar: 66,
        schedule_post: 133
      }
    }
  }
};

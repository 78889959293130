import styled from "styled-components";

import {
  ModalBodyComposer,
  ModalTitleComposer,
  ModalHeaderComposer
} from "components/modals/composerModal/styles";

export const ModalBody = styled(ModalBodyComposer)`
  padding: 20px 0;
  display: flex;
  justify-content: center;
`;

export const ModalTitle = styled(ModalTitleComposer)``;

export const ModalHeader = styled(ModalHeaderComposer)``;

export const RequiredIndicator = styled.div`
  label {
    justify-content: flex-start;

    &::after {
      content: "*";
      margin-left: 5px;
      color: ${props => props.theme.colors.red};
    }
  }
`;

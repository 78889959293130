import React from "react";
import styled from "styled-components";

import { colors } from "theme";
import { Body6, Headline2, Headline6 } from "components/common/styles";

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 56px;
  margin: 0 -56px;
  gap: 20px;

  * {
    box-sizing: border-box;
  }

  ${Headline2} {
    margin: 0;
  }
`;

const CardGrid = styled.div`
  display: grid;
  padding: 20px 0;
  grid-gap: 16px;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
`;

const Card = styled.div<{ iconBgcolor: string }>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 12px;
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.shadowS};
  border-radius: 16px;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    width: 58px;
    height: 58px;
    background: ${props => props.iconBgcolor};
    border-radius: 50%;
  }

  i {
    font-size: 42px;
    color: ${props => props.theme.colors.white};
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    gap: 8px;

    ${Headline6} {
      font-weight: 700;
    }
  }
`;

const FeatureGrid: React.FC<{}> = () => {
  return (
    <Wrapper>
      <Headline2>Our coaching clients have</Headline2>
      <CardGrid>
        <Card iconBgcolor={colors.orange}>
          <div className="icon">
            <i className="icon-calendar" />
          </div>
          <div className="content">
            <Headline6>Packed content calendars</Headline6>
            <Body6>
              Regular posting creates a bigger following. Our experts help you
              create a busy, relevant content calendar.
            </Body6>
          </div>
        </Card>
        <Card iconBgcolor={colors.yellow}>
          <div className="icon">
            <i className="icon-combined-content" />
          </div>
          <div className="content">
            <Headline6>More diverse content</Headline6>
            <Body6>
              Diverse content showcases your brand's expertise and value across
              formats. Elevate your brand's image and perception.
            </Body6>
          </div>
        </Card>
        <Card iconBgcolor={colors.brandBlue}>
          <div className="icon">
            <i className="icon-combined-social-media" />
          </div>
          <div className="content">
            <Headline6>Perform better on social media</Headline6>
            <Body6>
              Engage your audience with high-performing social media content.
              Boost interaction and resonance.
            </Body6>
          </div>
        </Card>
        <Card iconBgcolor={colors.leafGreen}>
          <div className="icon">
            <i className="icon-combined-stats" />
          </div>
          <div className="content">
            <Headline6>A longer term, annual strategy</Headline6>
            <Body6>
              Achieving significant results in growing your socials requires
              consistent effort, and our yearly plan is designed to deliver just
              that.
            </Body6>
          </div>
        </Card>
      </CardGrid>
    </Wrapper>
  );
};

export default FeatureGrid;

import {
  RESET_STATE,
  UPDATE_STATS,
  SET_FILTER_RANGE,
  UPDATE_ACTIVITIES,
  UPDATE_SOCIAL_HEALTH,
  UPDATE_STATS_FETCHING_STATE,
  UPDATE_ACTIVITIES_FETCHING_STATE,
  UPDATE_SOCIAL_HEALTH_FETCHING_STATE
} from "./actions";
import { IStats, IActivities, IFilterRange, ISocialHealth } from "./reducer";

export interface IResetStateAction {
  type: typeof RESET_STATE;
}

export const resetStateAction = (): IResetStateAction => ({
  type: RESET_STATE
});

export interface ISetFilterRangeAction {
  type: typeof SET_FILTER_RANGE;
  payload: IFilterRange;
}

export const setFilterRangeAction = (
  payload: IFilterRange
): ISetFilterRangeAction => ({
  type: SET_FILTER_RANGE,
  payload
});

export interface IUpdateActivitiesAction {
  type: typeof UPDATE_ACTIVITIES;
  payload: IActivities;
}

export const updateActivitiesAction = (
  payload: IActivities
): IUpdateActivitiesAction => ({
  type: UPDATE_ACTIVITIES,
  payload
});

export interface IUpdateActivitiesFetchingStateAction {
  type: typeof UPDATE_ACTIVITIES_FETCHING_STATE;
  payload: boolean;
}

export const updateActivitiesFetchingStateAction = (
  payload: boolean
): IUpdateActivitiesFetchingStateAction => ({
  type: UPDATE_ACTIVITIES_FETCHING_STATE,
  payload
});

export interface IUpdateSocialHealthAction {
  type: typeof UPDATE_SOCIAL_HEALTH;
  payload: ISocialHealth;
}

export const updateSocialHealthAction = (
  payload: ISocialHealth
): IUpdateSocialHealthAction => ({
  type: UPDATE_SOCIAL_HEALTH,
  payload
});

export interface IUpdateSocialHealthFetchingStateAction {
  type: typeof UPDATE_SOCIAL_HEALTH_FETCHING_STATE;
  payload: boolean;
}

export const updateSocialHealthFetchingStateAction = (
  payload: boolean
): IUpdateSocialHealthFetchingStateAction => ({
  type: UPDATE_SOCIAL_HEALTH_FETCHING_STATE,
  payload
});

export interface IUpdateStatsAction {
  type: typeof UPDATE_STATS;
  payload: IStats;
}

export const updateStatsAction = (payload: IStats): IUpdateStatsAction => ({
  type: UPDATE_STATS,
  payload
});

export interface IUpdateStatsFetchingStateAction {
  type: typeof UPDATE_STATS_FETCHING_STATE;
  payload: boolean;
}

export const updateStatsFetchingStateAction = (
  payload: boolean
): IUpdateStatsFetchingStateAction => ({
  type: UPDATE_STATS_FETCHING_STATE,
  payload
});

export type IActions =
  | IResetStateAction
  | ISetFilterRangeAction
  | IUpdateActivitiesAction
  | IUpdateActivitiesFetchingStateAction
  | IUpdateSocialHealthAction
  | IUpdateSocialHealthFetchingStateAction
  | IUpdateStatsAction
  | IUpdateStatsFetchingStateAction;

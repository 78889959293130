import React from "react";
import Modal, { Styles } from "react-modal";
import { X } from "styled-icons/feather";

import {
  ModalHeader,
  modalStyles,
  ModalDescription,
  ModalTitleWrapper
} from "./styles";
import IconButton from "../common/IconButton";

interface IContentoModal extends React.PropsWithChildren {
  title?: string;
  description?: string;
  onClose: Function;
  borderRadius?: number;
  maxWidth?: number;
  showFooter?: boolean;
  showHeader?: boolean;
}

export const ContentoModal: React.FC<IContentoModal> = ({
  title,
  description,
  onClose,
  children,
  borderRadius = 16,
  maxWidth = 550,
  showFooter = false,
  showHeader = "true"
}) => {
  const styles = modalStyles(maxWidth, showFooter) as Styles;
  styles.overlay!.background = "rgba(0,0,0,0.4)";
  styles.content!.borderRadius = `${borderRadius}px`;

  const onModalClose = (
    event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => {
    event.preventDefault();

    onClose();
  };

  return (
    <Modal
      ariaHideApp={false}
      isOpen={true}
      style={styles}
      onRequestClose={onModalClose}
    >
      {showHeader && (
        <>
          <ModalHeader>
            <ModalTitleWrapper>
              <span>{title}</span>
              <IconButton
                // @ts-ignore
                variant={"secondary"}
                size={"sm"}
                onClick={onClose}
                icon={X}
              />
            </ModalTitleWrapper>
          </ModalHeader>
          {description && <ModalDescription>{description}</ModalDescription>}
        </>
      )}
      {children}
    </Modal>
  );
};

import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  GridWrapper,
  SearchInput,
  SearchOptions,
  SearchInputLeft,
  SearchBarWrapper,
  SearchInputRight,
  SearchInputWrapper
} from "./styles";
import {
  useNewsState,
  ILanguageData,
  setSearchAction,
  useNewsDispatch
} from "contextApi/newsContext";
import IconButton from "components/common/IconButton";
import { useQueryParams } from "utils/useQueryParams";
import SearchByTopics from "../widgets/SearchByTopics";
import SearchByLanguage from "../widgets/SearchByLanguage";

const ArticleSearchBar: React.FC<{}> = React.memo(() => {
  const history = useHistory();
  const dispatch = useNewsDispatch();
  const { query } = useQueryParams();
  const topics = useNewsState(state => state.search.topics);
  const selectedTopics = useNewsState(state => state.search.selectedTopics);
  const selectedLanguages = useNewsState(
    state => state.search.selectedLanguages
  );

  useEffect(() => {
    dispatch(
      setSearchAction({
        searchValue: query as string
      })
    );
  }, [query, dispatch]);

  const setSearchQuery = useCallback(
    (value: string) => {
      history.push({
        search: `?query=${value}`
      });
    },
    [history]
  );

  return (
    <SearchBarWrapper>
      <GridWrapper>
        <SearchInputWrapper>
          <SearchInputLeft>
            <span className="icon-search" />
            <SearchInput
              type={"text"}
              value={(query as string) || ""}
              onChange={e => setSearchQuery(e.target.value)}
              placeholder={"Search for articles"}
            />
          </SearchInputLeft>
          <SearchInputRight>
            <IconButton
              // @ts-ignore
              icon={"icon-cancel"}
              variant={"secondaryQuiet"}
              size={32}
              iconSize={20}
              onClick={() => {
                setSearchQuery("");
              }}
            />
          </SearchInputRight>
        </SearchInputWrapper>
        <SearchOptions items={2}>
          <SearchByLanguage
            selectedLanguages={selectedLanguages}
            onSetLanguages={(data: Array<ILanguageData>) => {
              dispatch(
                setSearchAction({
                  selectedLanguages: data
                })
              );
            }}
          />
          <SearchByTopics
            selectedTopics={selectedTopics}
            topics={topics}
            onSetTopics={(topics: Array<string>) => {
              dispatch(
                setSearchAction({
                  selectedTopics: topics
                })
              );
            }}
          />
        </SearchOptions>
      </GridWrapper>
    </SearchBarWrapper>
  );
});

export default ArticleSearchBar;

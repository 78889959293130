import { Box } from "rebass";
import React, { useCallback } from "react";

import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import { Categories } from "./styles";
import ContentTypeSelect from "components/common/contentTypes/ContentTypeSelectV2";

const CategorySelector: React.FC<{}> = () => {
  const postData = useComposerState(state => state.postData);
  const setPostData = useComposerActions(actions => actions.setPostData);

  const changeContentType = useCallback(
    (newValue: string | { [key: string]: any }) => {
      let contentTypeId;
      let postIdea;

      if (typeof newValue === "string") {
        contentTypeId = newValue;
        postIdea = null;
      } else {
        contentTypeId = newValue.contentTypeId;
        postIdea = newValue.postIdea;
      }

      setPostData({
        contentTypeId,
        postIdea
      });
    },
    [setPostData]
  );

  return (
    <Categories>
      <Box>
        <ContentTypeSelect
          position="top-start"
          selectedPostConcept={postData.contentTypeId}
          selectedPostIdea={postData.postIdea}
          onChange={changeContentType}
        />
      </Box>
    </Categories>
  );
};

export default CategorySelector;

export type CategoryData = {
  label: string;
  value: string;
  icon: string;
  type?: string;
};

export const KEYWORD_CATEGORY: Array<CategoryData> = [
  {
    label: "Article",
    value: "text",
    icon: "icon-task"
  },
  {
    label: "Title",
    value: "title",
    icon: "icon-text"
  },
  {
    label: "",
    value: "",
    icon: "",
    type: "divider"
  },
  {
    label: "Website",
    value: "website",
    icon: "icon-source"
  },
  {
    label: "Language",
    value: "language",
    icon: "icon-language"
  }
];

import React from "react";

function withHookHOC(Component, useHook) {
  return function WrappedComponent(props) {
    const hookValue = useHook();
    return <Component {...props} defaultHook={hookValue} />;
  };
}

export default withHookHOC;

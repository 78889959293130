import * as regionFilterActions from "../actions/RegionFilterActions.js";

const STORAGE_KEY = "regionfilter.excludedTld";

const initialState = {
  excludedTld: localStorage.getItem(STORAGE_KEY) || ""
};

const regionFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case regionFilterActions.UPDATE_REGION_FILTER:
      if (action.excludedTld) {
        localStorage.setItem(STORAGE_KEY, action.excludedTld);
      } else {
        localStorage.removeItem(STORAGE_KEY);
      }
      return {
        excludedTld: action.excludedTld || ""
      };
    default:
      return state;
  }
};

export default regionFilterReducer;

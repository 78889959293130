import React from "react";
import styled from "styled-components";
import IdeaCarousel from "./IdeaCarousel";
import ContentfulDocument from "../common/ContentfulDocument";
import { Box, Flex } from "rebass/styled-components";
import Button from "../common/Button";
import { Headline1, Paragraph } from "../common/styles";
import { CalendarPlus } from "@styled-icons/boxicons-regular";

const IdeaContainer = styled(Box)`
  padding: 48px 24px 0;
`;

const IdeaPreviewContainer = styled(Box)`
  background-color: ${props => props.bgColor};
  box-sizing: border-box;
  margin: -20px -20px 0;
`;

const IdeaBody = styled(Box)`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-column-gap: 38px;
`;

const IdeaMetaItem = styled(Paragraph)`
  color: ${props => props.theme.colors.text01};
  font-size: 13px;
  font-weight: bold;
  line-height: 15px;
  margin-bottom: 24px;

  & > label {
    display: block;
    opacity: 0.42;
    margin: 4px 0;
  }
`;

const IdeaFooter = styled(Flex)`
  background: white;
  border-top: 1px solid ${props => props.theme.colors.grey01};
  justify-content: space-between;
  margin-top: 16px;
  padding: 16px 0;
  z-index: 2;
  position: sticky;
  bottom: 0;
`;

function IdeaDetail({ idea, onClose, showScheduleButton, onSchedule }) {
  const {
    title,
    description,
    shortDescription,
    postConcept,
    preview,
    time,
    difficulty
  } = idea;

  return (
    <Box>
      <IdeaPreviewContainer bgColor={postConcept.secondaryColor}>
        <IdeaCarousel photos={preview} maxWidth={840} />
      </IdeaPreviewContainer>
      <IdeaContainer>
        <IdeaBody>
          <Box>
            <Headline1 marginBottom={16} lineHeight={1}>
              {title}
            </Headline1>
            <Box maxWidth={450}>
              <ContentfulDocument src={shortDescription} />
            </Box>
          </Box>
          <Box>
            <IdeaMetaItem>
              <label>Time</label>
              {time}
            </IdeaMetaItem>
            <IdeaMetaItem>
              <label>Goals</label>
              {postConcept.title}
            </IdeaMetaItem>
            <IdeaMetaItem>
              <label>Difficulty</label>
              {difficulty}
            </IdeaMetaItem>
          </Box>
        </IdeaBody>
        <Box>
          <ContentfulDocument src={description} />
        </Box>

        <IdeaFooter>
          <Button variant="secondary" size="lg" onClick={onClose} isRounded>
            Go back
          </Button>
          {showScheduleButton && (
            <Button
              variant="primary"
              size="lg"
              onClick={onSchedule}
              isRounded
              icon={CalendarPlus}
            >
              Add to your schedule
            </Button>
          )}
        </IdeaFooter>
      </IdeaContainer>
    </Box>
  );
}

export default IdeaDetail;

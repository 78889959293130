import React from "react";
import {
  BlogMainColumn,
  MainTitle,
  MainSection,
  Description,
  Features,
  Feature,
  FeatureName,
  FeatureDescription,
  Examples,
  ExamplesTitle,
  ExampleList,
  Example,
  ExampleImage
} from "./styles";

import exampleImage1 from "../../assets/images/blog/example-1.jpg";
import exampleImage2 from "../../assets/images/blog/example-2.jpg";

function BlogMain() {
  return (
    <BlogMainColumn>
      <MainTitle>Articles & Blog Writing</MainTitle>
      <MainSection>
        <Description>
          A blog is a powerful tool for any business. Not only does it allow you
          to connect with and engage your audience, but it has some very
          important benefits when it comes to SEO and building brand reputation
          as well. Willow takes blogging out of your hands. We match you with a
          talented copywriter with expertise in your sector.
        </Description>
        <Features>
          <Feature>
            <FeatureName>Improves your SEO</FeatureName>
            <FeatureDescription>
              Rank higher in Google and other search engines
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureName>Lead generation</FeatureName>
            <FeatureDescription>
              Attract potential customers to your website
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureName>Thought leadership</FeatureName>
            <FeatureDescription>
              Build your audience’s trust in your expertise
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureName>Social media content</FeatureName>
            <FeatureDescription>
              Create original content for your socials
            </FeatureDescription>
          </Feature>
        </Features>
        <Examples>
          <ExamplesTitle>Examples</ExamplesTitle>
          <p>
            This is a sample of some real blog posts created by our writers.
          </p>
          <ExampleList>
            <Example>
              <a
                href={
                  "https://www.memo.be/nl/nieuws/dank-voor-uw-bezoek-en-uw-data"
                }
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <ExampleImage src={exampleImage1} alt={"Example 1"} />
              </a>
            </Example>
            <Example>
              <a
                href={
                  "https://ethernetextender.com/minirov-ocean-exploration-enabled"
                }
                target={"_blank"}
                rel="noopener noreferrer"
              >
                <ExampleImage src={exampleImage2} alt={"Example 2"} />
              </a>
            </Example>
          </ExampleList>
        </Examples>
      </MainSection>
    </BlogMainColumn>
  );
}

export default BlogMain;

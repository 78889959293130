import React from "react";
import { useGoogleLogin } from "@react-oauth/google";

import {
  SocialLogo,
  SocialButtonContent,
  SocialButtonWrapper,
  SocialButtonContainer
} from "../styles";
import { callApi } from "utils/ContentoApi";
import GoogleLogo from "assets/images/google-logo.png";
import { onSignInCallback, onSignInErrorCallback } from "./types";

export const GoogleButton = ({
  text,
  onSignIn,
  onSignInError,
  redirectUri
}: {
  text: string;
  onSignIn: onSignInCallback;
  onSignInError: onSignInErrorCallback;
  redirectUri?: string;
}) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      const userInfo = await callApi({
        method: "get",
        url: "https://www.googleapis.com/oauth2/v3/userinfo",
        headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
      });

      const { email, given_name, family_name } = userInfo;
      onSignIn({
        email,
        firstName: given_name,
        lastName: family_name,
        jwt: tokenResponse.access_token
      });
    },
    onError: errorResponse => {
      console.log(errorResponse);
      onSignInError(errorResponse);
    }
  });

  return (
    <SocialButtonContainer>
      {/* @ts-ignore */}
      <SocialButtonWrapper size="lg" width="100%" onClick={googleLogin}>
        <SocialButtonContent>
          <SocialLogo src={GoogleLogo} />
          {text}
        </SocialButtonContent>
      </SocialButtonWrapper>
    </SocialButtonContainer>
  );
};

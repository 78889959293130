import React from "react";
import * as authActions from "../../state/actions/AuthActions";
import connect from "react-redux/es/connect/connect";

class Logout extends React.Component {
  async componentDidMount() {
    this.props.logout();
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  logout: authActions.logout
})(Logout);

import React from "react";
import Divider from "../Divider";
import {
  TooltipOption,
  TooltipMenu,
  TooltipMenuWrapper,
  IconWrapper,
  ImageIcon,
  TooltipOptionLeft
} from "./styles";

import { ToolTip } from "components/schedule/common/styles";
import Loader from "../loading/Loader";
import { Box } from "rebass";

interface IContextMenuProps {
  close: () => void;
  options: Array<any>;
  isLoading?: boolean;
  minWidth?: number;
}

const ContextMenu: React.FC<IContextMenuProps> = ({
  close,
  options,
  isLoading,
  minWidth
}) => {
  const getIcon = (option: any) => {
    if (option.image) {
      return <ImageIcon src={option.image} />;
    } else if (option.icon) {
      return (
        <IconWrapper iconBg={option.iconBg}>
          <span className={option.icon}></span>
        </IconWrapper>
      );
    } else {
      return null;
    }
  };

  if (isLoading) {
    return (
      <TooltipMenu onClick={close} radius={12}>
        <TooltipMenuWrapper minWidth={minWidth}>
          <Box
            height={32}
            sx={{
              position: "relative"
            }}
          >
            <Loader size={20} location="center" />
          </Box>
        </TooltipMenuWrapper>
      </TooltipMenu>
    );
  }

  return (
    <TooltipMenu onClick={close} radius={12}>
      <TooltipMenuWrapper minWidth={minWidth}>
        {options.length < 1 ? (
          <TooltipOption disabled={true} center={true}>
            Your search returned no results
          </TooltipOption>
        ) : (
          options
            .filter(option => !option.hidden)
            .map((option, index) => {
              if (option.type === "divider") {
                return <Divider key={index} my={-2} />;
              }

              return (
                <React.Fragment key={index}>
                  <TooltipOption
                    data-tip
                    data-for={option.label}
                    variant={option.variant}
                    onClick={(e: any) => {
                      e.preventDefault();
                      if (option.disabled) return;

                      option.onClick();
                      close();
                    }}
                    disabled={option.disabled}
                    isSelected={option.active}
                  >
                    <TooltipOptionLeft>
                      {getIcon(option)}
                      {option.label}
                    </TooltipOptionLeft>
                    {option.active && <span className="icon-check" />}
                  </TooltipOption>
                  {/* @ts-ignore */}
                  {option.helperElement && (
                    <ToolTip
                      id={option.label}
                      place="right"
                      size={"large"}
                      delayHide={1000}
                    >
                      {option.helperElement}
                    </ToolTip>
                  )}
                </React.Fragment>
              );
            })
        )}
      </TooltipMenuWrapper>
    </TooltipMenu>
  );
};

export default ContextMenu;

import ReactDOM from "react-dom";
import React, { useState } from "react";
import { usePopper } from "react-popper";
import OutsideClickDetector from "components/common/OutsideClickDetector";

const Popper = ({
  referenceElement,
  placement,
  offset,
  visible,
  children,
  strategy = "absolute",
  onClose = () => {},
  exceptions = [],
  zIndex = 11,
  usePortal = true,
  arrowBg = null
}) => {
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const modifiers = [{ name: "arrow", options: { element: arrowElement } }];

  if (offset) {
    modifiers.push({
      name: "offset",
      options: {
        offset
      }
    });
  }

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: placement || "bottom-start",
    strategy,
    modifiers
  });

  if (!visible) return null;

  const PopperComponent = (
    <div
      ref={setPopperElement}
      {...attributes.popper}
      style={{
        ...styles.popper,
        zIndex
      }}
      className="popper-element"
    >
      <OutsideClickDetector onClose={onClose} exceptions={exceptions}>
        {children}
      </OutsideClickDetector>
      {arrowBg && (
        <div
          ref={setArrowElement}
          style={{
            ...styles.arrow,
            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.25)",
            background: arrowBg
          }}
          className="popper-arrow"
        />
      )}
    </div>
  );

  if (usePortal) {
    return ReactDOM.createPortal(
      PopperComponent,
      document.querySelector("#popper")
    );
  } else {
    return PopperComponent;
  }
};

export default Popper;

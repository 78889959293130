import { IPostDraft } from "@hellocontento/contento-common";
import { AnyAction } from "redux";

import * as ideasActions from "../actions/IdeasActions";

export interface IUserIdea extends IPostDraft {
  isWhatsappAsset?: boolean;
  isDraft?: boolean;
  createdAt: string;
}

export interface IIdeasState {
  whatsappAssets: IUserIdea[];
}

const initialState: IIdeasState = {
  whatsappAssets: []
};

const ideasReducer = (state = initialState, action: AnyAction): IIdeasState => {
  switch (action.type) {
    case ideasActions.SET_WHATSAPP_ASSETS:
      return {
        ...state,
        whatsappAssets: action.payload
      };

    case ideasActions.UPDATE_WHATSAPP_ASSET:
    case ideasActions.ADD_WHATSAPP_ASSET: {
      return  {
        ...state,
        whatsappAssets: [
          ...state.whatsappAssets.filter(
            asset => asset.id !== action.payload.id
          ),
          action.payload
        ]
      }
    }

    case ideasActions.REMOVE_WHATSAPP_ASSET_BY_ID:
      return {
        ...state,
        whatsappAssets: state.whatsappAssets.filter(
          asset => asset.id !== action.payload
        )
      }

    default:
      return state;
  }
};

export default ideasReducer;

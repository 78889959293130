import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  EmptyChannelWrapper,
  SocialSetWrapper,
  SocialSetContainer,
  EmptyChannelInfoWrapper,
  EmptyChannelTitle,
  EmptyChannelDescription,
  AddChannelButton
} from "./styles";

import IconLinkedin from "../../assets/images/icon-linkedin-112.png";
import IconFacebook from "assets/images/icon-facebook.png";
import IconTwitter from "assets/images/icon-twitter.png";
import IconInstagram from "assets/images/icon-instagram@24.png";

const SocialSets = () => {
  return (
    <SocialSetWrapper>
      <SocialSetContainer src={IconLinkedin}></SocialSetContainer>
      <SocialSetContainer src={IconTwitter}></SocialSetContainer>
      <SocialSetContainer src={IconInstagram}></SocialSetContainer>
      <SocialSetContainer src={IconFacebook}></SocialSetContainer>
    </SocialSetWrapper>
  );
};

const EmptyChannelState = ({ callback = () => {} }) => {
  const history = useHistory();
  const account = useSelector(state => state.account.data);

  return (
    <EmptyChannelWrapper>
      <SocialSets />
      <EmptyChannelInfoWrapper>
        <EmptyChannelTitle>Connect your channels first</EmptyChannelTitle>
        <EmptyChannelDescription>
          Connect your social media channels to create a task
        </EmptyChannelDescription>
        <AddChannelButton
          onClick={() => {
            history.push(`/accounts/${account.id}/settings/channels`);
            callback();
          }}
        >
          Connect Channels
        </AddChannelButton>
      </EmptyChannelInfoWrapper>
    </EmptyChannelWrapper>
  );
};

export default EmptyChannelState;

import store from "state/store";
import { callApi } from "utils/ContentoApi";
import { OnboardingStepsEnum } from "types/Onboarding.types";

import { updateAccount } from "state/actions/AccountActions";

export const skipStep = async (step: OnboardingStepsEnum) => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "get",
      url: `/accounts/${account.id}/onboarding/${step}/skip`
    });

    store.dispatch(
      updateAccount({
        ...account,
        onboardingProgress: [
          ...new Set([...account.onboardingProgress.concat(step as string)])
        ]
      })
    );

    return results;
  } catch (error) {
    throw new Error(`There was an error skipping the step.`);
  }
};

export const fetchQuoteDetails = async (
  quoteId: string
): Promise<{ [key: string]: any }> => {
  try {
    const quoteDetails = await callApi({
      method: "get",
      url: `/quotes/${quoteId}`
    });

    if (
      !quoteDetails ||
      !quoteDetails.dbQuote ||
      !quoteDetails.chargebeeQuote ||
      Object.keys(quoteDetails.dbQuote).length === 0 ||
      Object.keys(quoteDetails.chargebeeQuote).length === 0
    ) {
      throw new Error("Quote details unavailable.");
    }
    return quoteDetails;
  } catch (_) {
    throw new Error(`There was an error fetching the quote details.`);
  }
};

export const createCheckout = async (
  quoteId: string
): Promise<{ [key: string]: any }> => {
  try {
    const checkoutObj = await callApi({
      method: "post",
      url: `/quotes/checkout`,
      data: {
        quoteId
      }
    });

    return checkoutObj;
  } catch (_) {
    throw new Error(`There was an error in the checkout process.`);
  }
};

export const updateQuoteAsPaid = async (
  quoteId: string
): Promise<{ [key: string]: any }> => {
  try {
    const updatedQuote = await callApi({
      method: "post",
      url: `/quotes/${quoteId}/pay`
    });

    return {
      accessToken: updatedQuote.access_token,
      dbQuote: updatedQuote.paid_quote
    };
  } catch (_) {
    throw new Error(`There was an error with the payment update process.`);
  }
};

export const fetchIndustryCategories = async () => {
  try {
    const industryCategories = await callApi({
      method: "get",
      url: `/industry-categories`
    });

    return industryCategories;
  } catch (error) {
    throw new Error(`There was an error fetching the categories.`);
  }
};

import React from "react";
import { HashTagButton, HashTagWrapper, HashTagEmpty } from "./styles";

export default ({ selectedTags, tags, onSelect }) => {
  const filteredTags = tags.filter(t => !selectedTags.includes(t.tag));

  return (
    <HashTagWrapper>
      {filteredTags.map(tag => (
        <HashTagButton
          key={tag.id}
          onClick={e => {
            e.preventDefault();
            onSelect(`#${tag.tag}`);
          }}
        >
          #{tag.tag}
        </HashTagButton>
      ))}
      {filteredTags.length === 0 && (
        <HashTagEmpty>No tags available</HashTagEmpty>
      )}
    </HashTagWrapper>
  );
};

import React from "react";
import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { Headline2 } from "../common/styles";

const AnalyticsView = styled(Flex)`
  flex-direction: column;
  margin: 16px 24px;
  max-width: 1128px;
  width: 100%;
`;

const AnalyticsSection = styled(Box)`
  flex: 1;
  margin: 24px 0;
`;

function AnalyticsChannels() {
  return (
    <AnalyticsView>
      <AnalyticsSection>
        <Flex flex="1" alignItems="center" height={40}>
          <Headline2>Channels</Headline2>
        </Flex>
      </AnalyticsSection>
      <AnalyticsSection />
    </AnalyticsView>
  );
}

export default AnalyticsChannels;

import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback
} from "react";
import { Box } from "rebass";
import { useSelector } from "react-redux";
import { useToaster } from "@hellocontento/maillard";

import NewsOnboarding from "./onboarding";
import { getFeedsByType } from "services/news";
import FollowedContent from "./FollowedContent";
import Loader from "components/common/loading/Loader";
import RecommendedTopics from "./recommendations/topics";
import SuggestedContents from "./forYou/SuggestedContents";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import RecommendationTopicShelve from "./forYou/RecommendationTopicShelve";

const ContentForYou = () => {
  const addToast = useToaster();
  const account = useSelector(state => state.account.data);
  const [isLoading, setIsLoading] = useState(false);
  const [followedContent, setFollowedContent] = useState({});
  const rootRef = useRef(null);

  const refreshContentFeed = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getFeedsByType("your-feed", { limit: 6 });
      setFollowedContent(response);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if (err.name !== "RequestCancelled") {
        addToast(
          "There was an error fetching your content. Please try again later or contact support.",
          "error"
        );
      }
    }
  }, [addToast]);

  useEffect(() => {
    refreshContentFeed();
  }, [refreshContentFeed]);

  const hasFollowedContent =
    (followedContent.followedTopicsFeed ||
      followedContent.sourcesAndDomainsFeed) &&
    (followedContent.followedTopicsFeed.length > 0 ||
      followedContent.sourcesAndDomainsFeed.length > 0);

  const hasSuggestedContent =
    followedContent.suggestedContents &&
    followedContent.suggestedContents.length > 0;

  const hasRecommendedContent =
    followedContent.recommendedTopicsFeed &&
    followedContent.recommendedTopicsFeed.length > 0;

  const isOnboardingActive = useMemo(() => {
    return !(
      account.onboardingComplete ||
      account.onboardingProgress.includes(
        OnboardingStepsEnum.PENDING_ONBOARDING
      )
    );
  }, [account]);

  if (isLoading) {
    return <Loader size={64} location="center" />;
  }

  return (
    <>
      {isLoading ? (
        <Loader size={64} location="center" />
      ) : (
        <Box flex={"1"} ref={rootRef}>
          {isOnboardingActive && (
            <NewsOnboarding hasRecommendedContent={hasSuggestedContent} />
          )}
          {hasSuggestedContent && (
            <SuggestedContents
              contents={followedContent.suggestedContents}
              refreshContentFeed={refreshContentFeed}
            />
          )}
          {hasFollowedContent && (
            <>
              {[
                ...followedContent.followedTopicsFeed,
                ...followedContent.sourcesAndDomainsFeed
              ].map(({ section, contents, sectionType }, index) =>
                contents.length > 0 ? (
                  <FollowedContent
                    key={`${section.id}_${
                      section.tld ?? section.type ?? index
                    }`}
                    id={section.id}
                    section={section}
                    contents={contents}
                    sectionType={sectionType}
                    refreshContentFeed={refreshContentFeed}
                  />
                ) : null
              )}
            </>
          )}
          <RecommendedTopics />
          {!hasFollowedContent && hasRecommendedContent && (
            <>
              {followedContent.recommendedTopicsFeed.map(
                ({ section, contents, sectionType }) => (
                  <RecommendationTopicShelve
                    key={section.id}
                    section={section}
                    contents={contents}
                    sectionType={sectionType}
                    refreshContentFeed={refreshContentFeed}
                  />
                )
              )}
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default ContentForYou;

import {
  Context,
  useContext,
  createContext,
  useContextSelector
} from "use-context-selector";
import React, { useMemo, useReducer } from "react";

import {
  INewsState,
  newsReducer,
  INewsDispatch,
  initialValues
} from "./reducer";

interface ProviderProps {
  children: React.ReactNode;
}

type RefObj = { [key: string]: React.MutableRefObject<HTMLDivElement> };

export interface INewsValues {
  sectionRefs: RefObj;
}

const NewsStateContext = createContext<(INewsState & INewsValues) | undefined>(
  undefined
);
NewsStateContext.displayName = "NewsStateContext";

const NewsDispatchContext = createContext<INewsDispatch | undefined>(undefined);
NewsDispatchContext.displayName = "NewsDispatchContext";

export const NewsProvider: React.FC<ProviderProps> = React.memo(
  ({ children }) => {
    const sectionRefs: RefObj = {};
    const [state, dispatch] = useReducer(newsReducer, {
      ...initialValues
    });

    const values = useMemo(
      () => ({
        ...state,
        sectionRefs
      }),
      [state, sectionRefs]
    );

    return (
      <NewsStateContext.Provider value={values}>
        <NewsDispatchContext.Provider value={dispatch}>
          {children}
        </NewsDispatchContext.Provider>
      </NewsStateContext.Provider>
    );
  }
);

export const useNewsState = <T,>(
  selector: (state: INewsState & INewsValues) => T
): T => {
  try {
    return useContextSelector(
      NewsStateContext as Context<INewsState & INewsValues>,
      selector
    );
  } catch (_) {
    throw new Error("useNewsState must be used within a NewsProvider");
  }
};

export const useNewsDispatch = (): INewsDispatch => {
  try {
    return useContext<INewsDispatch>(
      NewsDispatchContext as Context<INewsDispatch>
    );
  } catch (_) {
    throw new Error("useNewsState must be used within a NewsProvider");
  }
};

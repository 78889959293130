import qs from "qs";
import React, { useEffect, useState } from "react";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { SwitchTransition, Transition } from "react-transition-group";

import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { callApi } from "utils/ContentoApi";
import { useDispatch, useSelector } from "react-redux";
import {
  startOnboarding,
  startOnboardingFailed
} from "state/actions/AuthActions";
import { base64Decode } from "utils/string";
import SignUpUser from "containers/auth/signup/SignUpUser";
import SignUpPassword from "containers/auth/signup/SignUpPassword";
import { generatePassword } from "utils/password";
import { IGoogleErrorEvent } from "components/auth/google/types";
import { useAppState } from "contextApi/appContext";

interface IInviteData {
  firstName: string;
  lastName: string;
  email: string;
  code?: string;
}

export default function SignUpForm({ location }: any) {
  const client = useAppState(state => state.clientSettings.client);
  const dispatch = useDispatch();
  const addToast = useToaster();
  const signupError = useSelector(
    (state: any) => state.auth.onboardingStartError
  );

  const [isSigned, setIsSigned] = useState<boolean>(false);
  const [isErrorOnAgreement, setIsErrorOnAgreement] = useState<boolean>(false);

  const [userStep, setUserStep] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [invite, setInvite] = useState<null | IInviteData>(null);
  const [isInviteError, setInviteError] = useState(false);
  const [authType, setAuthType] = useState<string | undefined>(undefined);

  const queryParams = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  const { code } = queryParams;

  const initialValues = invite
    ? {
        firstName: `${invite.firstName || ""}`,
        lastName: `${invite.lastName || ""}`,
        email: invite.email
      }
    : {};

  const handleNextStep = (values: any) => {
    callApi(
      // @ts-ignore
      {
        url: `/auth/exists?q=${values.email}`,
        method: "get"
      },
      false
    ).then(exists => {
      if (!exists) {
        if (!isSigned) {
          setIsErrorOnAgreement(true);
        } else {
          setFormValues(values);
          setUserStep(false);
        }
      } else {
        addToast("The email already exists", "error");
      }
    });
  };

  const handleSubmit = (values: any) => {
    const submitValues = {
      ...formValues,
      ...values
    };
    dispatch(
      startOnboarding({
        ...submitValues,
        inviteCode: invite?.code,
        isGoogle: false,
        ...(!!client ? { client } : {})
      })
    );
  };

  const handleProviderSignIn = ({
    email,
    firstName,
    lastName,
    ...params
  }: any) => {
    // generates a random password for provider users
    const password = generatePassword(5, 5, 5);

    dispatch(
      startOnboarding({
        email,
        firstName,
        lastName,
        password,
        inviteCode: invite?.code,
        ...(!!client ? { client } : {}),
        ...params
      })
    );
  };

  const handleGoogleSignIn = ({ email, firstName, lastName }: any) => {
    handleProviderSignIn({
      email,
      firstName,
      lastName,
      isGoogle: true
    });
  };

  const handleGoogleSignInError = (e: IGoogleErrorEvent) => {
    if (e.error === "popup_closed_by_user") return;
    addToast(
      "We were unable to create your account using Google. Please manually enter your details on the form.",
      "error"
    );
  };

  useEffect(() => {
    // Handler to call on window resize
    function fetchInviteInfo() {
      if (code) {
        callApi(
          // @ts-ignore
          {
            url: `invites/${code}`,
            method: "get"
          },
          false
        )
          .then(invite => {
            setInvite(invite);
          })
          .catch(error => {
            setInviteError(true);
          });
      }
    }
    fetchInviteInfo();
  }, [code]);

  useEffect(() => {
    if (signupError) {
      addToast(signupError, "error");
      dispatch(startOnboardingFailed(null));
    }
  }, [signupError, addToast, dispatch]);

  if (
    queryParams.authType &&
    queryParams.firstName &&
    queryParams.lastName &&
    queryParams.email &&
    authType !== queryParams.authType
  ) {
    // linkedin login
    // @ts-ignore
    setAuthType(queryParams.authType);
    let inviteCode: null | string = null;
    const encodedInviteCode = /signUp\/(.*)/i.exec(
      typeof queryParams.redirectUrl === "string" ? queryParams.redirectUrl : ""
    );

    if (!!encodedInviteCode && !!encodedInviteCode[1]) {
      const decodedInviteCode = qs.parse(
        base64Decode(encodedInviteCode[1]).slice(1)
      );

      if (decodedInviteCode.code) {
        inviteCode = decodedInviteCode.code as string;
      }
    }

    handleProviderSignIn({
      email: queryParams.email,
      firstName: queryParams.firstName,
      lastName: queryParams.lastName,
      isLinkedin: true,
      ...(inviteCode ? { inviteCode } : {}),
      ...(!!client ? { client } : {})
    });
    return null;
  }

  return (
    <SwitchTransition
      mode="out-in"
      // @ts-ignore
      addEndListener={(node, done) => {
        node.addEventListener("transitionend", done, false);
      }}
    >
      <Transition
        key={userStep ? "user" : "password"}
        unmountOnExit
        mountOnEnter
        timeout={250}
      >
        {state =>
          userStep ? (
            <SignUpUser
              // @ts-ignore
              state={state}
              defaultValues={formValues}
              isEmailDisabled={!!invite}
              initialValues={initialValues}
              isInviteError={isInviteError}
              invite={invite}
              onGoogleSignIn={handleGoogleSignIn}
              onGoogleSignInError={handleGoogleSignInError}
              onSubmit={(values: any) => handleNextStep(values)}
              isSigned={isSigned}
              setIsSigned={setIsSigned}
              isErrorOnAgreement={isErrorOnAgreement}
            />
          ) : (
            <SignUpPassword
              // @ts-ignore
              email={formValues?.email}
              state={state}
              goBack={() => {
                setUserStep(true);
                setIsErrorOnAgreement(false);
              }}
              onSubmit={(values: any) => handleSubmit(values)}
            />
          )
        }
      </Transition>
    </SwitchTransition>
  );
}

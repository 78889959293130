export const onboardingStepHeirarchy = [
  "connect_channel",
  "create_content_calendar",
  "follow_section",
  "like_article",
  "get_personalized_suggestions",
  "create_post",
  "invite_team_member"
];

export enum OnboardingStepsEnum {
  CONNECT_CHANNEL = "connect_channel",
  CREATE_CONTENT_CALENDAR = "create_content_calendar",
  FOLLOW_SECTION = "follow_section",
  LIKE_ARTICLE = "like_article",
  GET_PERSONALIZED_SUGGESTIONS = "get_personalized_suggestions",
  CREATE_POST = "create_post",
  INVITE_TEAM_MEMBER = "invite_team_member",
  PENDING_ONBOARDING = "pending_onboarding"
}

import { type IUserIdea } from "state/reducers/IdeasReducer";

export const ADD_WHATSAPP_ASSET = "@@ideas/ADD_WHATSAPP_ASSET";
export const SET_WHATSAPP_ASSETS = "@@ideas/SET_WHATSAPP_ASSETS";
export const REMOVE_WHATSAPP_ASSET_BY_ID =
  "@@ideas/REMOVE_WHATSAPP_ASSET_BY_ID";
export const UPDATE_WHATSAPP_ASSET = "@@ideas/UPDATE_WHATSAPP_ASSET";

export const setWhatsappAssets = (payload: IUserIdea[]) => {
  return {
    type: SET_WHATSAPP_ASSETS,
    payload
  };
};

export const addWhatsappAsset = (payload: IUserIdea) => {
  return {
    type: ADD_WHATSAPP_ASSET,
    payload
  };
};

export const removeWhatsappAssetById = (id: string) => {
  return {
    type: REMOVE_WHATSAPP_ASSET_BY_ID,
    payload: id
  };
};

export const updateWhatsappAsset = (payload: IUserIdea) => {
  return {
    type: UPDATE_WHATSAPP_ASSET,
    payload
  };
};

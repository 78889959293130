import { Flex } from "rebass/styled-components";
import React from "react";
import IdeaTypeFilters, { IdeaTypeFilter } from "./IdeaTypeFilters";
import PostCategorySelector from "components/analytics/widgets/PostCategorySelector";
import PeriodSelect from "components/analytics/widgets/PeriodSelect";

interface IdeasFiltersProps {
  ideaTypeFiler: IdeaTypeFilter;
  onAttachmentTypeChange: (val: IdeaTypeFilter) => void;
  periodFilter?: 7 | 30 | 90 | 180 | 365;
  onPeriodFilterChange: (val?: 7 | 30 | 90 | 180 | 365) => void;
  categories: string[];
  selectedCategories?: string[];
  onCategoryFilterChange: (val: string[]) => void;
}

const IdeasFilters: React.FC<IdeasFiltersProps> = ({
  ideaTypeFiler,
  onAttachmentTypeChange,
  periodFilter,
  onPeriodFilterChange,
  categories,
  selectedCategories,
  onCategoryFilterChange
}) => {
  return (
    <Flex justifyContent="space-between">
      <IdeaTypeFilters
        currFilter={ideaTypeFiler}
        onChange={onAttachmentTypeChange}
      />
      <Flex
        sx={{
          gap: "14px"
        }}
      >
        <PeriodSelect
          selected={periodFilter}
          onSelect={onPeriodFilterChange}
          border={"1px solid #606E801F"}
          background={"#F8F8F8"}
          borderRadius={"10px"}
        />
        <PostCategorySelector
          categories={categories}
          selectedCategories={selectedCategories}
          onChange={onCategoryFilterChange}
          border={"1px solid #606E801F"}
          background={"#F8F8F8"}
          borderRadius={"8px"}
          withIcon={true}
        />
      </Flex>
    </Flex>
  );
};

export default IdeasFilters;

import _ from "lodash";
// @ts-ignore
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import React, { useCallback } from "react";

import {
  today,
  startOfDay,
  difference,
  endOfMonth,
  addDuration,
  getDateLabel,
  startOfMonth,
  parseStringToISO,
  parseStringToDate
} from "utils/date";
import DayFeed from "./dayFeed";
import { useTimelineState } from "../../context";

interface IMonthFeed {
  monthKey: string;
  monthEntries: any[];
}

const MonthFeed: React.FC<IMonthFeed> = ({ monthKey, monthEntries }) => {
  const { postType } = useTimelineState();
  const account = useSelector<any, any>(state => state.account.data);
  const draftCount = useSelector<any, any>(state => state.draft.count);

  const momentTZ = useCallback(
    date => {
      return moment.tz(date, account.timezone);
    },
    [account.timezone]
  );

  let groupByDay: { [key: string]: any } = {};
  const startDate = startOfMonth(parseStringToDate(monthKey, "yyyy/M"));
  const endDate = endOfMonth(parseStringToDate(monthKey, "yyyy/M"));

  if (
    (postType === "published" || postType === "drafts") &&
    difference(startOfMonth(today()), startDate, "months") === 0
  ) {
    const key = getDateLabel(today());

    groupByDay[key] = [];
  } else if (postType === "timeline" || postType === "scheduled") {
    for (
      let date = startDate;
      date <= endDate;
      date = addDuration(date, { days: 1 })
    ) {
      if (difference(startOfDay(date), startOfDay(today()), "days") < 0) {
        continue;
      }
      const key = getDateLabel(date);

      groupByDay[key] = [];
    }
  }

  groupByDay = monthEntries.reduce((acc, entry) => {
    const key = getDateLabel(parseStringToISO(entry.time));

    const post =
      entry.type === "TASK"
        ? {
            ...entry.task
          }
        : entry.type === "POST"
          ? {
              ...entry.post
            }
          : entry.type === "DRAFT_POST" && entry.draft.scheduledAt !== null
            ? {
                ...entry.draft,
                isDraft: true
              }
            : entry.type === "POST_GROUP"
              ? {
                  ...entry.group,
                  isPostGroup: true
                }
              : null;

    if (post === null) {
      return acc;
    }

    let date =
      post.status === "SENT"
        ? momentTZ(post.postedAt)
        : entry.type === "TASK"
          ? momentTZ(post.date)
          : momentTZ(post.scheduledAt);
    let picture;
    if (post.attachment) {
      if (post.attachment.type === "photo") {
        picture = _.isArray(post.attachment.url)
          ? post.attachment.url[0]
          : post.attachment.url;
      } else if (post.attachment.type === "article") {
        picture = post.attachment.image;
      }
    }

    return {
      ...acc,
      [key]: [
        ...(acc[key] ?? []),
        entry.type === "TASK"
          ? {
              id: `slot-${post.id}`,
              cardId: post.id,
              type: "TASK",
              dateTime: date,
              time: date.format("HH:mm"),
              channels: [...post.channels],
              task: post,
              isPast: !date.isAfter(momentTZ(today())),
              isPhantom: post.isPhantom
            }
          : {
              id: `post-${post.id}`,
              cardId: post.id,
              type: post.isDraft
                ? "DRAFT_POST"
                : `POST${post.channels?.length > 1 ? "_GROUP" : ""}`,
              dateTime: date,
              time: date.format("HH:mm"),
              channels:
                post.isDraft || post.isPostGroup
                  ? post.channels
                  : [post.channel],
              text:
                post.isDraft || post.isPostGroup
                  ? Object.values(post.caption).sort((a, b) => {
                      return (b as string).length - (a as string).length;
                    })[0]
                  : post.caption,
              displayedService:
                post.isDraft || post.isPostGroup
                  ? Object.entries(post.caption).sort((a, b) => {
                      return (b[1] as string).length - (a[1] as string).length;
                    })[0][0]
                  : post.channel.service,
              picture: picture,
              title: post.attachment ? post.attachment.title : undefined,
              post: post,
              isPhantom: post.isPhantom
            }
      ]
    };
  }, groupByDay);

  if (postType !== "scheduled") {
    let count = 0;

    for (
      let date = startDate;
      date <= endDate;
      date = addDuration(date, { days: 1 })
    ) {
      const key = getDateLabel(date);

      if (!groupByDay[key]) {
        count++;
      } else {
        count = 0;
      }
      if (
        count === 7 &&
        (postType !== "published" ||
          difference(startOfDay(date), startOfDay(today()), "days") < 0)
      ) {
        groupByDay[key] = [];
        count = 0;
      }
    }
  } else if (postType === "scheduled") {
    groupByDay = _.omitBy(groupByDay, (_, key) => {
      const diff = difference(
        startOfDay(parseStringToDate(key, "yyyy/M/d")),
        startOfDay(today()),
        "days"
      );

      return diff < 0;
    });
  }

  groupByDay = Object.entries(groupByDay).sort();

  if (postType === "published") {
    groupByDay = groupByDay.reverse();
  }

  const keyForToday = getDateLabel(today());

  // TODO: add loading states
  return (
    <>
      {postType === "drafts" && draftCount === 0 ? (
        <DayFeed key={keyForToday} date={keyForToday} dayEntries={[]} />
      ) : (
        groupByDay.map(([key, value]: any[]) => (
          <DayFeed key={key} date={key} dayEntries={value.reverse()} />
        ))
      )}
    </>
  );
};

export default MonthFeed;

import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Flex } from "rebass/styled-components";

import { ConjunctionData } from "../constants/types";

import Popper from "components/common/Popper";
import ContextMenu from "components/common/contextMenu";

const ConjunctionWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Button = styled.button<{ variant?: string }>`
  border-radius: 4px;
  padding: 3px 8px;
  cursor: pointer;
  color: ${props => props.theme.colors.white};
  font-weight: 600;
  border: none;
  outline: none;
  margin: 0;
  background: ${props => {
    switch (props.variant) {
      case "danger":
        return props.theme.colors.danger;
      case "primary":
      default:
        return props.theme.colors.primary;
    }
  }};

  :hover {
    ${props => {
      switch (props.variant) {
        case "danger":
          return css`
            background: #e60d0d;
          `;
        case "primary":
        default:
          return css`
            background: #004fc9;
          `;
      }
    }}
  }

  .icon {
    font-size: 16px;
  }

  .rotate {
    transform-origin: center;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }
`;

const Label = styled.span`
  text-transform: uppercase;
`;

interface IConjunctionProps {
  id: string;
  data: ConjunctionData;
  onChange: (id: string, data: ConjunctionData) => void;
}

const ConjuctionOption: React.FC<IConjunctionProps> = ({
  id,
  data,
  onChange
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  return (
    <ConjunctionWrapper>
      <Button
        // @ts-ignore
        ref={setReferenceElement}
        onClick={(e: React.MouseEvent) => {
          e.preventDefault();
          toggleShowOptions();
        }}
        variant={data === ConjunctionData.NOT ? "danger" : "primary"}
      >
        <Flex alignItems={"center"}>
          <Label>{data}</Label>
          <span className="icon-arrowright icon rotate" />
        </Flex>
      </Button>
      {/* @ts-ignore */}
      <Popper
        referenceElement={referenceElement}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[referenceElement]}
      >
        <ContextMenu
          minWidth={160}
          close={() => setShowOptions(false)}
          options={[
            {
              label: "And",
              onClick: () => onChange(id, ConjunctionData.AND),
              active: data === ConjunctionData.AND
            },
            {
              label: "Not",
              onClick: () => onChange(id, ConjunctionData.NOT),
              active: data === ConjunctionData.NOT
            }
          ]}
        />
      </Popper>
    </ConjunctionWrapper>
  );
};

export default ConjuctionOption;

import { stopSubmit } from "redux-form";
import { push } from "connected-react-router";
import { takeLatest, put, select, call } from "redux-saga/effects";

import Auth from "utils/Auth";
import { callApi } from "utils/ContentoApi";
import * as accountActions from "../actions/AccountActions";

function* fetchAccount(action) {
  try {
    const account = yield callApi({
      method: "get",
      url: `/accounts/${action.accountId}`
    });
    if (process.env.NODE_ENV === "production") {
      if (
        account.isTelenetCustomer &&
        window.location.href.includes("//app.")
      ) {
        yield call(Auth.logout);
        yield (window.location.href = window.location.href.replace(
          "app",
          "telenet"
        ));
      }
      if (
        !account.isTelenetCustomer &&
        window.location.href.includes("//telenet.")
      ) {
        yield call(Auth.logout);
        yield (window.location.href = window.location.href.replace(
          "telenet",
          "app"
        ));
      }
    }
    yield put(accountActions.accountFetched(account));
  } catch (error) {
    yield put(accountActions.fetchAccountError(action.accountId, error));
  }
}

function* createChargeBeePortal(action) {
  try {
    yield callApi({
      method: "get",
      url: `/accounts/${action.accountId}/billing/portal`
    });
  } catch (error) {
    console.error(error);
  }
}

const getAccount = state => {
  return state.account.data;
};

function* updateAccount(action) {
  const account = yield select(getAccount);
  try {
    const updatedAccount = yield callApi({
      method: "put",
      url: `accounts/${account.id}`,
      data: { ...action.values }
    });
    yield put(accountActions.accountUpdated(updatedAccount));

    if (action.res) {
      action.res();
    }
  } catch (error) {
    console.error(error);
    yield put(accountActions.updateAccountError(error));

    if (action.rej) {
      action.rej();
    }
  }
}

function* updateAccountBilling(action) {
  const account = yield select(getAccount);
  try {
    yield callApi({
      method: "post",
      url: `accounts/${account.id}/billing/sync`,
      data: { ...action.values }
    });
    yield put(accountActions.fetchAccount(account.id));

    if (action.cb) {
      action.cb();
    }
  } catch (error) {
    console.error(error);
    yield put(accountActions.updateAccountError(error));
  }
}

function* subscribeAccount(action) {
  const account = yield select(getAccount);
  try {
    const updatedAccount = yield callApi({
      method: "post",
      url: `accounts/${account.id}/subscribe`,
      data: { ...action.values }
    });

    yield put(accountActions.accountUpdated(updatedAccount));
    yield put(stopSubmit("accountSubscribe"));
    yield put(push(`/accounts/${updatedAccount.id}/subscribe/finished`));
  } catch (error) {
    console.log(error);
    yield put(
      stopSubmit("accountSubscribe", {
        _error: `There was an error subscribing: ${error.message}. Please try again or contact ludwig@willow.co if you keep getting errors.`
      })
    );
  }
}

export default function* root() {
  yield takeLatest(accountActions.FETCH_ACCOUNT, fetchAccount);
  yield takeLatest(accountActions.UPDATE_ACCOUNT, updateAccount);
  yield takeLatest(accountActions.UPDATE_ACCOUNT_BILLING, updateAccountBilling);
  yield takeLatest(accountActions.SUBSCRIBE, subscribeAccount);
  yield takeLatest(accountActions.CHARGEBEE_PORTAL, createChargeBeePortal);
}

import PropTypes from "prop-types";
import queryString from "query-string";
import React, { useState, useCallback, useEffect } from "react";
import { useToaster } from "@hellocontento/maillard";

import {
  OptionGroup,
  DeleteButton,
  SchedulerButton,
  SchedulerWrapper,
  SchedulerOptions,
  SchedulerDropdown
} from "./styles";
import {
  today,
  getTime,
  setDate,
  isToday,
  tomorrow,
  getDayOfWeek,
  dayAfterTomorrow,
  getRandomMinutes,
  formatForScheduler
} from "utils/date";
import SchedulerModal from "./SchedulerModal";
import SchedulerOption from "./SchedulerOption";
import Loader from "components/common/loading/Loader";
import OutsideClickDetector from "components/common/OutsideClickDetector";
import { fetchBestTimes } from "components/schedule/services/taskServices";
import ConfirmationModal from "components/schedule/form/TaskConfirmationModal";
import CloseComposerModal from "components/modals/closeComposerModal";

const Scheduler = ({
  post,
  isDraft,
  account,
  isPosting,
  isDeleting,
  onCloseModal,
  handlePostDeletion,
  isSchedulerDisabled,
  initialScheduleTime,
  handlePostSubmission,
  confirmationDialogVisible,
  setConfirmationDialogVisible
}) => {
  const addToast = useToaster();
  const [isLoading, setIsLoading] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [wasScheduleChanged, setWasScheduleChanged] = useState(false);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isSchedulerModalVisible, setIsSchedulerModalVisible] = useState(false);
  const [pickedDate, setPickedDate] = useState(
    !!post.scheduledAt
      ? new Date(post.scheduledAt)
      : !!initialScheduleTime
        ? new Date(initialScheduleTime)
        : tomorrow()
  );
  const toggleShowOptions = () => setIsOptionsVisible(!isOptionsVisible);

  const getServices = useCallback(() => {
    const uniqueActiveChannels = account.channels.reduce((acc, val) => {
      if (val.active && post.channels.includes(val.id)) {
        acc.add(val.service);
      }
      return acc;
    }, new Set());

    return queryString.stringify({ service: [...uniqueActiveChannels] });
  }, [account.channels, post.channels]);

  const fetchFavourableTime = useCallback(
    async date => {
      const serviceQry = getServices();
      const dayQry = queryString.stringify({
        dayOfTheWeek: getDayOfWeek(date).toUpperCase()
      });

      try {
        const data = await fetchBestTimes(
          account.id,
          `${serviceQry}&${dayQry}`
        );

        if (isToday(date)) {
          const { hour } = data.find(({ hour }) => hour > getTime(date).hours);

          return setDate(date, { hours: hour, minutes: getRandomMinutes() });
        } else {
          return setDate(date, {
            hours: data[0].hour,
            minutes: getRandomMinutes()
          });
        }
      } catch (err) {
        addToast("Something went wrong fetching best times.", "error");
        setPickedDate(tomorrow());
        throw err;
      }
    },
    [account.id, addToast, getServices]
  );

  const getOptionById = useCallback(
    id => {
      const SCHEDULER_OPTIONS = [
        {
          id: "set_date_time",
          label: "Set date & time",
          activeLabel: "Change date & time",
          optionIconClassName: "icon-calendar",
          callback: () => setIsSchedulerModalVisible(true)
        },
        {
          id: "auto_schedule",
          label: isDraft ? "Auto-schedule Draft" : "Auto-schedule",
          optionIconClassName: "icon-autopilot",
          description:
            "Finds the next empty task or the best time within 72 hours."
        },
        {
          id: "best_time_today",
          label: "Best time Today",
          optionIconClassName: isDraft ? "icon-draft-clock" : "icon-clock",
          callback: async () => {
            try {
              const date = await fetchFavourableTime(today());
              setPickedDate(date);
            } catch (_) {}
          }
        },
        {
          id: "best_time_tomorrow",
          label: "Best time Tomorrow",
          optionIconClassName: isDraft ? "icon-draft-clock" : "icon-clock",
          callback: async () => {
            try {
              const date = await fetchFavourableTime(tomorrow());
              setPickedDate(date);
            } catch (_) {}
          }
        },
        {
          id: "best_time_day_after_tomorrow",
          optionIconClassName: isDraft ? "icon-draft-clock" : "icon-clock",
          label: `Best time ${getDayOfWeek(dayAfterTomorrow())}`,
          callback: async () => {
            try {
              const date = await fetchFavourableTime(dayAfterTomorrow());
              setPickedDate(date);
            } catch (_) {}
          }
        },
        {
          id: "post_now",
          label: isDraft ? "Save Draft" : "Post now",
          optionIconClassName: isDraft ? "icon-draft" : "icon-send",
          buttonIconClassName: isDraft
            ? "icon-draft-filled"
            : "icon-send-filled"
        }
      ];

      if (Array.isArray(id)) {
        return SCHEDULER_OPTIONS.filter(option => id.includes(option.id));
      } else {
        return SCHEDULER_OPTIONS.find(option => option.id === id);
      }
    },
    [isDraft, fetchFavourableTime]
  );

  const SCHEDULER_OPTION_GROUPS = [
    getOptionById(["set_date_time", "auto_schedule"]),
    getOptionById([
      "best_time_today",
      "best_time_tomorrow",
      "best_time_day_after_tomorrow"
    ]),
    getOptionById(["post_now"])
  ];

  const isNewPost = !post.id;

  const [selectedOption, setSelectedOption] = useState(
    getOptionById(
      !post.id && post.contentId
        ? "auto_schedule"
        : !!post.scheduledAt || !!initialScheduleTime
          ? "set_date_time"
          : "post_now"
    )
  );

  const setSchedulerOption = useCallback(
    optionId => {
      setSelectedOption(getOptionById(optionId ?? selectedOption.id));
      if (!wasScheduleChanged && optionId && selectedOption.id !== optionId) {
        setWasScheduleChanged(true);
      }
    },
    [getOptionById, selectedOption.id, wasScheduleChanged]
  );

  useEffect(() => {
    setSchedulerOption();
  }, [isDraft, setSchedulerOption]);

  const setCustomDate = date => {
    setPickedDate(date);
    setSchedulerOption("set_date_time");
  };

  const onOptionSelect = async option => {
    toggleShowOptions();

    if (option.callback) {
      setIsLoading(true);
      await option.callback();
      setIsLoading(false);
    }
    if (option.id !== "set_date_time") {
      setSchedulerOption(option.id);
    }
  };

  const handleDelete = () => {
    setIsConfirmationVisible(false);
    handlePostDeletion(post);
  };

  const handleSubmit = (fromModal = false) => {
    const scheduleTime =
      selectedOption.id === "post_now"
        ? "NOW"
        : selectedOption.id === "auto_schedule"
          ? "AUTO_SCHEDULE"
          : "CUSTOM";
    const scheduledAt = selectedOption.id !== "post_now" ? pickedDate : null;
    handlePostSubmission(scheduleTime, scheduledAt, fromModal);
  };

  const buttonIcon = (() => {
    if (["post_now", "auto_schedule"].includes(selectedOption.id)) {
      return selectedOption.buttonIconClassName
        ? selectedOption.buttonIconClassName
        : selectedOption.optionIconClassName;
    } else {
      return "icon-clock-filled";
    }
  })();

  const buttonLabel = (() => {
    if (["post_now", "auto_schedule"].includes(selectedOption.id)) {
      return selectedOption.label;
    } else {
      if (!wasScheduleChanged && !isNewPost) {
        return `Save ${isDraft ? "draft changes" : "post"}`;
      }
      return `${isDraft ? `Save draft` : `Schedule`} for ${formatForScheduler(
        pickedDate
      )}`;
    }
  })();

  const isDisabled =
    isLoading || isSchedulerDisabled || isPosting || isDeleting;
  const canBeDeleted = !isNewPost && post.status === "SCHEDULED";

  return (
    <>
      <CloseComposerModal
        isEdit={!!post.id}
        isOpen={confirmationDialogVisible}
        onClose={onCloseModal}
        onSave={() => handleSubmit(true)}
        isPostEmpty={!post.caption?.all && !post.attachment}
        onCancel={() => setConfirmationDialogVisible(false)}
      />
      <OutsideClickDetector
        style={{ flexGrow: 1 }}
        onClose={() => setIsOptionsVisible(false)}
      >
        <>
          <SchedulerWrapper>
            {canBeDeleted && (
              <DeleteButton
                variant="danger"
                disabled={isDisabled}
                onClick={() => setIsConfirmationVisible(true)}
              >
                {isDeleting ? (
                  <Loader size={24} />
                ) : (
                  <i className="icon-delete" />
                )}
              </DeleteButton>
            )}
            <SchedulerButton
              size="m"
              isDraft={isDraft}
              disabled={isDisabled}
              onClick={() => handleSubmit()}
              variant={isDraft ? "primary" : "success"}
            >
              <>
                {isPosting && <Loader size={24} />}
                <i className={buttonIcon} />
                {buttonLabel}
              </>
            </SchedulerButton>
            <SchedulerDropdown
              size="m"
              disabled={isDisabled}
              onClick={toggleShowOptions}
              isOptionsVisible={isOptionsVisible}
              variant={isDraft ? "primary" : "success"}
            >
              <i className="icon-select" />
            </SchedulerDropdown>
            {isOptionsVisible && (
              <SchedulerOptions>
                {SCHEDULER_OPTION_GROUPS.map((optionGroup, index) => (
                  <OptionGroup key={index}>
                    {optionGroup.map(option => {
                      if (!isNewPost && option.id === "auto_schedule") {
                        return null;
                      }

                      return (
                        <SchedulerOption
                          key={option.id}
                          label={
                            selectedOption.id === option.id &&
                            !!option.activeLabel
                              ? option.activeLabel
                              : option.label
                          }
                          description={option.description}
                          onClick={() => onOptionSelect(option)}
                          isActive={selectedOption.id === option.id}
                          iconClassName={option.optionIconClassName}
                        />
                      );
                    })}
                  </OptionGroup>
                ))}
              </SchedulerOptions>
            )}
            {isSchedulerModalVisible && (
              <SchedulerModal
                post={post}
                account={account}
                pickedDate={pickedDate}
                setCustomDate={setCustomDate}
                onClose={() => setIsSchedulerModalVisible(false)}
              />
            )}
          </SchedulerWrapper>
          <ConfirmationModal
            isOpen={isConfirmationVisible}
            title={`Delete ${isDraft ? "Draft" : "Post"}`}
            description={`Are you sure you want to delete this ${
              isDraft ? "draft" : "post"
            }?`}
            toggle={() => setIsConfirmationVisible(false)}
            buttonProps={{
              variant: "danger",
              label: "Delete",
              action: handleDelete
            }}
          />
        </>
      </OutsideClickDetector>
    </>
  );
};

Scheduler.propTypes = {
  post: PropTypes.object.isRequired,
  isDraft: PropTypes.bool.isRequired,
  account: PropTypes.object.isRequired,
  initialScheduleTime: PropTypes.string,
  handlePostDeletion: PropTypes.func.isRequired,
  isSchedulerDisabled: PropTypes.bool.isRequired,
  handlePostSubmission: PropTypes.func.isRequired
};

export default Scheduler;

import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { Box } from "rebass/styled-components";

import Button from "components/common/Button";
import { modalStyles } from "components/modals/styles";
import { splitChannelsVideoLink } from "constants/index";
import { Headline3, SingleParagraph } from "components/common/styles";

const styles = modalStyles(420);
styles.overlay.zIndex = 11;
styles.content.padding = "0px";

const SplitConfirmation = ({ isOpen, onCancel, onConfirm }) => {
  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onCancel}
    >
      <Box
        sx={{
          width: "100%",
          height: "210px"
        }}
      >
        <video width="100%" height="100%" autoPlay loop muted>
          <source type="video/mp4" src={splitChannelsVideoLink} />
        </video>
      </Box>
      <Box p={24} textAlign="center">
        <Headline3 marginBottom="8px">
          Adding a mention?
          <br />
          Your caption will be split.
        </Headline3>
        <SingleParagraph variant="light">
          We recommend you write your caption first before adding mentions. When
          adding a mention your caption will need to be different for every
          platform.
        </SingleParagraph>
        <Box
          sx={{
            marginTop: 24,
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: 8
          }}
        >
          <Button size="m" variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button size="m" variant="primary" onClick={onConfirm}>
            Split Caption
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

SplitConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default SplitConfirmation;

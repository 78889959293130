import styled, { css } from "styled-components";

import IconButton from "components/common/IconButton";
import { MenuItem as StyledMenuItem } from "components/navigation/Menu";

interface IPostContextMenuWrapper {
  isVisible?: boolean;
}

export const PostContextMenuWrapper = styled.div<IPostContextMenuWrapper>`
  position: absolute;
  right: 9px;
  top: 9px;
  width: 40px;
  height: 40px;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey02};
  box-sizing: border-box;
  box-shadow: ${props => props.theme.shadows.shadowS};
  border-radius: 10px;
  padding: 4px;
  display: none;
  justify-content: center;
  align-items: center;

  ${props =>
    props.isVisible &&
    css`
      display: flex;
    `}
`;

export const PostContextMenuButton = styled(IconButton)<{ [key: string]: any }>`
  border-radius: 8px;
`;

export const MenuItem = styled(StyledMenuItem)`
  height: 36px;
`;

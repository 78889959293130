import React, { useState } from "react";
import {
  OuterFrame,
  InnerFrame,
  TitleSection,
  IconFrame,
  Icon,
  Title,
  BetaLabel,
  Divider,
  InfoText,
  ResponseSection,
  PlaceholderText,
  ButtonSection,
  Button,
  GenerateButtonIcon,
  TryAgainButtonIcon,
  PasteToComposerButtonIcon,
  PromptSection,
  PromptItem,
  PromptText,
  PromptIcon,
  SelectedPrompt,
  SelectedPromptIcon,
  InputField,
  OtherOptions,
  OtherOptionsText,
  OtherOptionsIcon,
  SelectedPromptContainer
} from "./styles";
import { callApi } from "utils/ContentoApi";
import {
  useComposerActions,
  useComposerState
} from "../../../../../contextApi/composerContext/useComposerContext";

const AskWillowAI = () => {
  const pasteCaptionToComposer = useComposerActions(
    actions => actions.pasteCaptionToComposer
  );
  const composerText = useComposerState(s => s.caption);

  const [selectedPrompt, setSelectedPrompt] = useState<string | null>(null);
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isTwinkling, setIsTwinkling] = useState(false);
  const [error, setError] = useState("");
  const [isGenerated, setIsGenerated] = useState(false);

  const prompts = [
    "Give me some post ideas on a theme",
    "Generate me a caption",
    "Make my caption shorter",
    "Make my caption longer",
    "Improve my writing",
    "Check for spelling and syntax errors"
  ];

  const handlePromptClick = async (prompt: string) => {
    console.log("Prompt clicked:", prompt);
    setSelectedPrompt(prompt);
    setResponse("");
    setInputValue("");
    setError("");
    setIsGenerated(false);

    if (
      prompt === "Make my caption shorter" ||
      prompt === "Make my caption longer" ||
      prompt === "Improve my writing" ||
      prompt === "Check for spelling and syntax errors"
    ) {
      await handleImmediateGenerateClick(prompt);
    }
  };

  const generatePromptText = (prompt: string) => {
    if (prompt === "Give me some post ideas on a theme") {
      return `A user will submit a short thematic prompt. 
        You must return 4 ideas on this theme that can be turned into social media captions. 
        Do not write the social media caption - simply return the idea. 
        Be creative. 2 of the ideas should be conformist, 2 should be contrarian - but do not tell the user this. 
        Return them in regular text format with line breaks between each idea. Do not bullet point or number point the ideas. 
        
        The input from the user is: ${inputValue}`;
    } else if (prompt === "Generate me a caption") {
      return `A user will provide you with an idea. Create a professional, engaging, and optimized LinkedIn caption following the below rules. 
      - Start with an engaging hook. A hook can be a stat, a question or something that sparks emotions. 
      - Never start the post with an emoji. 
      - Avoid bold and italics. 
      - Use between 0 and 6 emojis. 
      - Limit paragraphs to a maximum of 4 lines. 
      - Use between 6 and 20 lines in total. 
      - Ensure hashtags are relevant. 
      - Organize posts with lists. 
      - Vary paragraph length. 
      - Use steps or subtitles. 
      - Maintain a balance between a professional and casual tone 
      - Make the captions approachable yet polished. 
      - Avoid repetition. 
      - Choose shorter words. (use instead of utilize) 
      - Write from the reader's perspective. 
      - Avoid adverbs when possible. 
      - Write actively. 
      - Use the present simple tense as often as possible. 
      - Keep it simple but not do not oversimplify things. 
      - Avoid filler words such as actually, literally and like. 
      - Avoid modal verbs such as may, could and would. 
      - Use affirmative language. 
      - Use the rule of three in enumerations. 
      - Use examples and be specific. 
      - Tell the reader what's in it for them. 
      - Write for one person. 
      
      The input from the user is: ${inputValue}`;
    } else if (prompt === "Make my caption shorter") {
      return `A user will provide a caption written for social media. You must make it about 20% shorter than the total characters. 
        Do not ignore any of the points mentioned in the post. Do not lose the flow of the post. 
        Captions will be shared in English, Dutch, French and German - you must return the caption in the same language as provided. 
        The caption from the user is: "${composerText}"`;
    } else if (prompt === "Make my caption longer") {
      return `A user will provide a caption written for social media. You must make it 40% longer. Do not ignore any of the points mentioned in the post. Do not lose the flow of the post. 
        Do not add any irrelevant points, only expand on existing ones. 
        Captions will be shared in English, Dutch, French and German - you must return the caption in the same language as provided. 
        The caption from the user is: "${composerText}"`;
    } else if (prompt === "Improve my writing") {
      return `A user will provide a caption for you. You must check it for spelling, grammar and syntax errors. 
        You must return a correct version of the caption. 
        Captions will be shared in English, Dutch, French and German - you must return the caption in the same language as provided. 
        The caption from the user is: "${composerText}"`;
    } else if (prompt === "Check for spelling and syntax errors") {
      return `The user will provide a social media post caption. 
        You must improve the writing. Do this by using grade 9 language, by fixing spelling or syntax errors, by using active voice, by making changes to clarity, conciseness, and overall flow. 
        Do not use more words than the submitted caption. 
        Requests will be made in English, Dutch, French and German. The response you provide must be in the same language as submitted. 
        The caption from the user is: "${composerText}"`;
    }
    return "";
  };

  const handleGenerateClick = async () => {
    if (
      !inputValue.trim() &&
      selectedPrompt === "Give me some post ideas on a theme"
    )
      return;
    if (!inputValue.trim() && selectedPrompt === "Generate me a caption")
      return;

    const promptText = generatePromptText(selectedPrompt!);

    try {
      setIsTwinkling(true);
      setLoading(true);
      setError("");

      const response = await callApi({
        method: "post",
        url: `/chats/completion`,
        data: { prompt: promptText }
      });

      setResponse(response);
      setIsGenerated(true);
    } catch (err) {
      setError("Failed to generate response. Please try again.");
    } finally {
      setLoading(false);
      setIsTwinkling(false);
    }
  };

  const handleImmediateGenerateClick = async (prompt: string) => {
    const promptText = generatePromptText(prompt);

    try {
      setIsTwinkling(true);
      setLoading(true);
      setError("");

      const response = await callApi({
        method: "post",
        url: `/chats/completion`,
        data: { prompt: promptText }
      });

      setResponse(response);
      setIsGenerated(true);
    } catch (err) {
      setError("Failed to generate response. Please try again.");
    } finally {
      setLoading(false);
      setIsTwinkling(false);
    }
  };

  const handleTryAgainClick = () => {
    setIsGenerated(false);
    handleGenerateClick();
  };

  const handlePasteClick = () => {
    pasteCaptionToComposer(response.trim());
  };

  const handleOtherOptionsClick = () => {
    setSelectedPrompt(null);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "Enter" &&
      (selectedPrompt === "Give me some post ideas on a theme" ||
        selectedPrompt === "Generate me a caption") &&
      inputValue.trim()
    ) {
      handleGenerateClick();
    }
  };

  return (
    <OuterFrame>
      <InnerFrame>
        <TitleSection>
          <IconFrame>
            <Icon />
          </IconFrame>
          <Title>Ask Willow AI</Title>
          <BetaLabel>Beta Version</BetaLabel>
        </TitleSection>
        <Divider />
        <InfoText>
          Responses are not saved when swapping tasks or when leaving the
          composer.
        </InfoText>
        {selectedPrompt && (
          <SelectedPromptContainer>
            <SelectedPrompt>
              <SelectedPromptIcon />
              <span>{selectedPrompt}</span>
            </SelectedPrompt>
            {(selectedPrompt === "Give me some post ideas on a theme" ||
              selectedPrompt === "Generate me a caption") && (
              <InputField
                placeholder={
                  selectedPrompt === "Give me some post ideas on a theme"
                    ? "Explain the theme here..."
                    : "Explain what you want here..."
                }
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={
                  selectedPrompt !== "Give me some post ideas on a theme" &&
                  selectedPrompt !== "Generate me a caption"
                }
              />
            )}
            {!isGenerated && (
              <ButtonSection>
                <Button
                  $primary
                  $disabled={
                    loading ||
                    (!inputValue.trim() &&
                      (selectedPrompt ===
                        "Give me some post ideas on a theme" ||
                        selectedPrompt === "Generate me a caption")) ||
                    (!composerText.trim() &&
                      selectedPrompt !== "Give me some post ideas on a theme" &&
                      selectedPrompt !== "Generate me a caption")
                  }
                  onClick={
                    selectedPrompt === "Make my caption shorter" ||
                    selectedPrompt === "Make my caption longer" ||
                    selectedPrompt === "Improve my writing" ||
                    selectedPrompt === "Check for spelling and syntax errors"
                      ? () => handleImmediateGenerateClick(selectedPrompt)
                      : handleGenerateClick
                  }
                  aria-busy={loading}
                >
                  <GenerateButtonIcon
                    $primary={!inputValue.trim()}
                    className={isTwinkling ? "twinkle" : ""}
                  />
                  {loading ? "Generating..." : "Generate"}
                </Button>
              </ButtonSection>
            )}
            {response && (
              <>
                <ResponseSection $hasResponse={!!response}>
                  {loading ? (
                    <PlaceholderText>Loading...</PlaceholderText>
                  ) : (
                    <PlaceholderText>{response}</PlaceholderText>
                  )}
                </ResponseSection>
                <ButtonSection>
                  <Button $primary onClick={handlePasteClick}>
                    <PasteToComposerButtonIcon $primary />
                    Paste to Composer
                  </Button>
                  <Button onClick={handleTryAgainClick}>
                    <TryAgainButtonIcon />
                    Try Again
                  </Button>
                </ButtonSection>
              </>
            )}
            {error && <InfoText style={{ color: "red" }}>{error}</InfoText>}
            <OtherOptions onClick={handleOtherOptionsClick}>
              <OtherOptionsIcon />
              <OtherOptionsText>Other options</OtherOptionsText>
            </OtherOptions>
          </SelectedPromptContainer>
        )}
        {!selectedPrompt && (
          <PromptSection>
            {prompts.map(prompt => (
              <PromptItem
                key={prompt}
                onClick={() => handlePromptClick(prompt)}
                style={{
                  opacity:
                    !composerText && prompts.indexOf(prompt) >= 2 ? 0.5 : 1,
                  pointerEvents:
                    !composerText && prompts.indexOf(prompt) >= 2
                      ? "none"
                      : "auto"
                }}
              >
                <PromptIcon />
                <PromptText>{prompt}</PromptText>
              </PromptItem>
            ))}
          </PromptSection>
        )}
      </InnerFrame>
    </OuterFrame>
  );
};

export default AskWillowAI;

import { Flex } from "rebass";
import React, { useState, useEffect, useRef } from "react";

import {
  Title,
  Flair,
  LeftSection,
  Description,
  RightSection,
  TitleWrapper,
  CompletedMark,
  RemainingSteps,
  CompletionTime,
  CompletedWrapper,
  OnboardingAccordion,
  OnboardingBoxWrapper,
  AccordionRightSection,
  OnboardingItemWrapper
} from "./styles";
import Divider from "components/common/Divider";
import { useAppState } from "contextApi/appContext";
import { VideoWrapper, VideoThumbnail } from "./styles";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import VideoPlayerModal from "components/modals/videoPlayerModal";

interface IOnboardingItemProps {
  item: {
    id: string;
    cta?: JSX.Element | JSX.Element[];
    title: string;
    description: JSX.Element | JSX.Element[];
    tutorialUrl?: string;
    conditions: Array<OnboardingStepsEnum>;
    timeToCompleteInMinutes: number;
    completed: number;
    tutorialTimestamp?: number;
  };
  isFirst: boolean;
  isActive: boolean;
  isNextStep: boolean;
  isCompleted: boolean;
  setActiveItem: (id: string) => void;
}

const OnboardingItem: React.FC<IOnboardingItemProps> = ({
  item,
  isFirst,
  isActive,
  isNextStep,
  isCompleted,
  setActiveItem
}) => {
  const ref = useRef(null);
  const thumbnail = useAppState(
    state => state.clientSettings.onboardingVideoSettings.thumbnail
  );
  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);
  const [maxHeight, setMaxHeight] = useState<number | string>(0);

  const { id, description, cta, title } = item;

  useEffect(() => {
    if (isActive && ref?.current) {
      // @ts-ignore
      setMaxHeight(`${ref.current.scrollHeight}px`);
    } else {
      setMaxHeight(0);
    }
  }, [isActive]);

  return (
    <>
      {!isFirst && <Divider my={-8} />}
      <VideoPlayerModal
        videoUrl={`https://www.youtube.com/embed/${
          item.tutorialUrl
        }?autoplay=1&mute=1${
          item.tutorialTimestamp ? `&start=${item.tutorialTimestamp}` : ""
        }`}
        isOpen={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
      />
      <OnboardingBoxWrapper
        onClick={() => {
          isActive ? setActiveItem("") : setActiveItem(id);
        }}
      >
        <OnboardingItemWrapper key={id}>
          <LeftSection>
            <CompletedMark isCompleted={isCompleted}>
              {isCompleted && <span className="icon-check" />}
            </CompletedMark>
            <Flex flexDirection={"column"}>
              <TitleWrapper>
                <Title>{title}</Title>
                {isNextStep && (
                  <Flair variant={"orange"} size={"sm"}>
                    Next Up
                  </Flair>
                )}
              </TitleWrapper>
            </Flex>
          </LeftSection>
          <RightSection>
            {isCompleted ? (
              <CompletedWrapper>
                <span className="icon icon-check" />
                Completed
              </CompletedWrapper>
            ) : (
              <>
                <RemainingSteps>{`${item.completed} ${
                  item.completed > 1 ? "steps" : "step"
                } left`}</RemainingSteps>
                <CompletionTime>{`About ${item.timeToCompleteInMinutes} min`}</CompletionTime>
              </>
            )}
          </RightSection>
        </OnboardingItemWrapper>
        <OnboardingAccordion
          className={isActive ? "active" : ""}
          ref={ref}
          maxHeight={maxHeight}
        >
          <LeftSection>
            <CompletedMark isPhantom={true} />
            <Flex flexDirection={"column"}>
              <Description>{description}</Description>
              {/* @ts-ignore */}
              {cta ? cta : null}
            </Flex>
          </LeftSection>
          <AccordionRightSection>
            <VideoWrapper
              key={item.tutorialUrl}
              className={"video"}
              onClick={e => {
                e.stopPropagation();
                setIsVideoOpen(true);
              }}
            >
              <VideoThumbnail bgImg={thumbnail} />
              <span className="icon-play-button">
                <span className="path1" />
                <span className="path2" />
              </span>
            </VideoWrapper>
          </AccordionRightSection>
        </OnboardingAccordion>
      </OnboardingBoxWrapper>
    </>
  );
};

export default OnboardingItem;

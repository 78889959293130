import React from "react";
import styled from "styled-components";

import LinksTable from "./linksTable";

const Container = styled.div`
  padding: 20px;
`;

const LinksPage: React.FC = () => {
  return (
    <Container>
      <LinksTable></LinksTable>
    </Container>
  );
};

export default LinksPage;

import { Box } from "rebass";
import qs from "query-string";
import { useHistory } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";

import { searchByCategory } from "services/news";
import { useQueryParams } from "utils/useQueryParams";

import {
  SECTION_TYPES,
  SOURCE_TYPE,
  EXPLORE_SELECTION
} from "services/news.types";
import SourceList from "./sources/SourceList";
import TopicList from "./topics/TopicList";
import Loader from "components/common/loading/Loader";
import ExploreRecommendations from "./ExploreRecommendations";
import AllSearchResults from "./all";

const ContentExplore = () => {
  const history = useHistory();
  const queryParams = useQueryParams();

  const [isLoading, setLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);

  const [searchValue, setSearchValue] = useState(queryParams.text || "");
  const [searchContext, setSearchContext] = useState(queryParams.type || "all");

  useEffect(() => {
    setSearchContext(queryParams.type ?? "all");
    setSearchValue(queryParams.text ?? "");
  }, [queryParams.type, queryParams.text]);

  const refreshSearch = useCallback(async () => {
    if (!queryParams.text) return;

    try {
      setLoading(true);
      const results = await searchByCategory(queryParams.type, {
        searchTerm: queryParams.text
      });
      setSearchResults(results);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  }, [queryParams.text, queryParams.type]);

  useEffect(() => {
    setLoading(true);
    const searchDelay = setTimeout(() => {
      if (searchValue.length !== 0) refreshSearch();
    }, 200);

    return () => clearTimeout(searchDelay);
  }, [searchValue, refreshSearch]);

  const changeSearchContext = type => {
    const query = qs.stringify({ ...queryParams, type });

    history.push({
      search: `?${query}`
    });
  };

  const topics =
    searchResults.filter(result => result.type === SECTION_TYPES.TOPICS) ?? [];

  const resultForSources = {
    domains: {
      type: SOURCE_TYPE.DOMAINS,
      title: "Websites",
      sources:
        searchResults.filter(result => result.type === SECTION_TYPES.DOMAINS) ??
        []
    },
    "twitter-sources": {
      type: SOURCE_TYPE.TWITTER,
      title: "X Influencers",
      sources:
        searchResults.filter(
          result =>
            result.type === SECTION_TYPES.SOURCES &&
            result.detail.type === SOURCE_TYPE.TWITTER
        ) ?? []
    },
    "rss-sources": {
      type: SOURCE_TYPE.RSS,
      title: "RSS Feeds",
      sources:
        searchResults.filter(
          result =>
            result.type === SECTION_TYPES.SOURCES &&
            result.detail.type === SOURCE_TYPE.RSS
        ) ?? []
    }
  };

  const renderResultsBasedOnContext = () => {
    switch (queryParams.type) {
      case EXPLORE_SELECTION.ALL: {
        return (
          <AllSearchResults
            topics={topics}
            domains={resultForSources.domains.sources}
            influencers={resultForSources[EXPLORE_SELECTION.TWITTER].sources}
            otherSources={resultForSources[EXPLORE_SELECTION.RSS].sources}
            hasResults={searchResults.length > 0}
            changeSearchContext={changeSearchContext}
          />
        );
      }
      case EXPLORE_SELECTION.TOPICS:
        return (
          <TopicList
            topics={topics}
            searchContext={searchContext}
            changeSearchContext={() =>
              changeSearchContext(EXPLORE_SELECTION.TOPICS)
            }
          />
        );
      case EXPLORE_SELECTION.WEBSITE:
      case EXPLORE_SELECTION.TWITTER:
      case EXPLORE_SELECTION.RSS:
        return (
          <SourceList
            {...resultForSources[queryParams.type]}
            searchContext={searchContext}
            changeSearchContext={() => changeSearchContext(queryParams.type)}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Box flex={"1"}>
      {searchValue === "" ? (
        <ExploreRecommendations type={queryParams.type} />
      ) : isLoading ? (
        <Loader location="center" />
      ) : (
        renderResultsBasedOnContext()
      )}
    </Box>
  );
};

export default ContentExplore;

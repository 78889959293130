import styled from "styled-components";

import { Display02, Headline6 } from "components/common/styles";
import banner from "assets/images/banner/news-discovery-banner.jpg";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 479px;
  background: url(${banner}) no-repeat center center;
  background-size: cover;
  border-radius: 24px;
  padding: 112px 81px;
`;

export const Subtitle = styled(Headline6)`
  font-weight: 700;
`;

export const Title = styled(Display02)`
  width: 468px;
  margin: 8px 0 48px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;

  > button:hover {
    opacity: 0.9;
  }
`;

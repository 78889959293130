import React from "react";
import PropTypes from "prop-types";

import Button from "components/common/Button";
import { TooltipWrapper, Tooltip, Link, ButtonWrapper } from "./styles";

const UrlTooltip = ({ isVisible, text, buttonText, onClick, entityType }) => {
  const variant = entityType === "SHORTENED_URL" ? "secondary" : "primary";

  return (
    <TooltipWrapper isVisible={isVisible}>
      <Tooltip>
        <Link>
          <i className="icon-clicks" />
          <span>{text}</span>
        </Link>
        <ButtonWrapper onMouseDown={e => e.preventDefault()}>
          <Button variant={variant} size="xs" onClick={onClick}>
            {buttonText}
          </Button>
        </ButtonWrapper>
      </Tooltip>
    </TooltipWrapper>
  );
};

UrlTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  buttonText: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired
};

export default UrlTooltip;

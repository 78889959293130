import { Dispatch } from "react";

import {
  RESET_STATE,
  SET_IS_FETCHING,
  SET_POST_TYPE,
  SET_FOCUSED_DATE,
  SET_DATE_TO_BE_FOCUSED,
  UPDATE_TIMELINE_ACTIVITIES,
  SET_DATE_RANGE_TO_BE_FETCHED
} from "./actions";
import { IActions } from "./actionCreators";
import { today, getDateLabel } from "utils/date";

export type PostType = "timeline" | "scheduled" | "published" | "drafts";

export interface IActivities {
  [month: string]: {
    [postType in PostType]: { entries: any[]; lastUpdated: Date };
  };
}

export type DateRange = {
  start: Date;
  end: Date;
  override?: boolean;
};

export interface ITimelineState {
  postType: PostType | null;
  focusedDate: string | null;
  dateToBeFocused: string | null;
  activities: IActivities;
  isFetching: boolean;
  dateRangeToBeFetched: DateRange | null;
}

export type ITimelineDispatch = Dispatch<IActions>;

export const initialValues: ITimelineState = {
  postType: null,
  focusedDate: getDateLabel(today()),
  dateToBeFocused: null,
  activities: {},
  isFetching: false,
  dateRangeToBeFetched: null
};

export const timelineReducer = (
  state: ITimelineState = initialValues,
  action: IActions
): ITimelineState => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...initialValues
      };

    case SET_IS_FETCHING: {
      return {
        ...state,
        isFetching: action.payload
      };
    }

    case SET_POST_TYPE: {
      return {
        ...state,
        postType: action.payload
      };
    }

    case SET_FOCUSED_DATE: {
      return {
        ...state,
        focusedDate: action.payload
      };
    }

    case SET_DATE_TO_BE_FOCUSED: {
      return {
        ...state,
        dateToBeFocused:
          typeof action.payload === "undefined"
            ? state.focusedDate
            : action.payload
      };
    }

    case UPDATE_TIMELINE_ACTIVITIES: {
      const { month, entries, postType, lastUpdated } = action.payload;

      return {
        ...state,
        activities: {
          ...state.activities,
          [month]: {
            ...state.activities[month],
            [postType]: {
              entries,
              lastUpdated
            }
          }
        }
      };
    }

    case SET_DATE_RANGE_TO_BE_FETCHED: {
      return {
        ...state,
        dateRangeToBeFetched: action.payload
      };
    }

    default: {
      throw new Error("Unhandled action type");
    }
  }
};

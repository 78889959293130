import { Flex } from "rebass";
import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../common/Button";
import Popper from "components/common/Popper";

import Menu, { MenuContainer } from "../../navigation/Menu";

const repetitionMap = {
  WEEKLY: `Week`,
  BIWEEKLY: `Two Weeks`,
  MONTHLY: `Month`,
  YEARLY: `Year`
};

const repetitionPeriods = [
  { value: "WEEKLY", label: "Week" },
  { value: "BIWEEKLY", label: "Two Weeks" },
  { value: "MONTHLY", label: "Month" },
  { value: "YEARLY", label: "Year" }
];

const SelectChevron = styled.span`
  font-size: 24px;
  width: 20px;
  height: 20px;
`;

const RepetitionOptions = ({ settings, handleRepetitionModeChange }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  return (
    <div ref={setReferenceElement}>
      <Button size={"xs"} variant={"secondary"} onClick={toggleShowOptions}>
        <Flex>
          {repetitionMap[settings.repetitionMode.split("_")[0]]}
          <SelectChevron className="icon-select"></SelectChevron>
        </Flex>
      </Button>
      <Popper
        placement={"bottom"}
        referenceElement={referenceElement}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[referenceElement]}
      >
        <MenuContainer id="rep__exception">
          <Menu
            items={repetitionPeriods}
            onSelect={value => {
              handleRepetitionModeChange(value);
              setShowOptions(false);
            }}
            selected={settings.repetitionMode.split("_")[0]}
          />
        </MenuContainer>
      </Popper>
    </div>
  );
};

export default RepetitionOptions;

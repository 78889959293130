import styled from "styled-components";

interface ITimelineFeedWrapper {
  isPublishedView: boolean;
}

export const TimelineFeedWrapper = styled.div<ITimelineFeedWrapper>`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  -ms-overflow-style: none;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .content-wrapper {
    padding-top: 20px;
  }
`;

export const FeedLoadingState = styled.div<{ fullHeight?: boolean }>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${props => (props.fullHeight ? "calc(100vh - 96px)" : "72px")};
  width: 100%;
`;

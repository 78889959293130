import React from "react";
import { Box } from "rebass";

import { PreviewInfo } from "./styles";
import ContentItem from "../ContentItem";
import { Headline3 } from "components/common/styles";
import Loader from "components/common/loading/Loader";
import EmptyCard from "components/settings/EmptyCard";
import SearchGrayIcon from "assets/images/contents/Search-Gray.png";

interface IKeywordContentPreviewProps {
  isLoading: boolean;
  previews: Array<any>;
}

const KeywordContentPreview: React.FC<IKeywordContentPreviewProps> = React.memo(
  ({ isLoading, previews }) => (
    <div>
      <Headline3>Preview</Headline3>
      <PreviewInfo>
        <span className="icon-sparkle" />
        More and better results will show up once you create the feed.
      </PreviewInfo>
      {isLoading ? (
        <Box
          height={150}
          sx={{
            position: "relative"
          }}
        >
          <Loader size={32} location="center" />
        </Box>
      ) : previews.length < 1 ? (
        <Box mt={80}>
          <EmptyCard
            noBgStyle={true}
            size={48}
            width={60}
            {...{
              title: "No articles found",
              infoIcon: [SearchGrayIcon],
              showSupport: false,
              message:
                "We might be able to find articles once you create the feed. You can also try to make the filters you use less narrow."
            }}
          />
        </Box>
      ) : (
        previews.slice(0, 20).map((item: any) => (
          <ContentItem
            key={item.id}
            // @ts-ignore
            content={item}
            viewOnly={true}
            orientation={"landscape"}
            thumbnailHeight={192}
            showDescription={false}
          />
        ))
      )}
    </div>
  )
);

export default KeywordContentPreview;

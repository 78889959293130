import { useIntersection } from "hooks/useIntersection";
import React, { useState, useRef } from "react";

import { ThumbnailImage } from "./styles";

interface IThumbnailProps {
  src: string;
  [key: string]: any;
}

const Thumbnail: React.FC<IThumbnailProps> = ({ src, ...props }) => {
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef();
  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  return (
    // @ts-ignore
    <ThumbnailImage
      ref={imgRef}
      className="lazy"
      thumbnail={isInView ? src : null}
      {...props}
    />
  );
};

export default Thumbnail;

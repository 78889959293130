import { Dispatch } from "react";
import { DEFAULT_MAX_CHANNELS } from "constants/plan";

import {
  SET_WINDOW_SIZE,
  SET_IS_DRAFT_PANEL_OPEN,
  SET_CLIENT_SETTINGS
} from "./actions";
import { IActions } from "./actionCreators";
import onboardingThumbnail from "assets/images/dashboard/onboarding-thumbnail.jpg";

export enum WindowSize {
  LG = "large",
  M = "medium",
  S = "small",
  XS = "xsmall"
}

export interface IClientSettings {
  client: null | string;
  onboardingVideoSettings: {
    linkId: string;
    thumbnail: string;
    timeStamps: { [key: string]: number };
  };
  allowedChannels: null | number;
  chargebeePackage: null | string;
}

export interface IAppState {
  windowSize: WindowSize;
  isDraftPanelOpen: boolean;
  clientSettings: IClientSettings;
}

export type IAppDispatch = Dispatch<IActions>;

export const initialValues: IAppState = {
  windowSize: WindowSize.LG,
  isDraftPanelOpen: false,
  clientSettings: {
    client: null,
    onboardingVideoSettings: {
      linkId: "BCmIDKv_ZzI",
      thumbnail: onboardingThumbnail,
      timeStamps: {
        channel_connection: 37,
        content_calendar: 245,
        news_feed: 90,
        schedule_post: 303,
        invite_members: 68
      }
    },
    allowedChannels: DEFAULT_MAX_CHANNELS,
    chargebeePackage: null
  }
};

export const appReducer = (
  state: IAppState = initialValues,
  action: IActions
): IAppState => {
  switch (action.type) {
    case SET_WINDOW_SIZE: {
      return {
        ...state,
        windowSize: action.payload
      };
    }

    case SET_IS_DRAFT_PANEL_OPEN: {
      return {
        ...state,
        isDraftPanelOpen: action.payload
      };
    }

    case SET_CLIENT_SETTINGS: {
      return {
        ...state,
        clientSettings: {
          ...state.clientSettings,
          ...action.payload
        }
      };
    }

    default: {
      throw new Error("Unhandled action type");
    }
  }
};

import { Dispatch } from "react";

import { IActions } from "./actionCreators";
import { RESET_STATE, SET_CONTENT, SET_SEARCH } from "./actions";

export interface ITopicData {
  value: string;
  total: number;
}

export interface ILanguageData {
  label: string;
  value: string;
}

export interface ISearch {
  searchValue: string;
  topics: Array<ITopicData>;
  selectedTopics: Array<string>;
  selectedLanguages: Array<ILanguageData>;
}

export interface IContent {
  meta?: any;
  type: string;
  title: string;
  id: string | null;
  isFollowing: boolean;
}

export interface INewsState {
  search: ISearch;
  content: IContent;
}

export type INewsDispatch = Dispatch<IActions>;

export const initialValues: INewsState = {
  search: {
    topics: [],
    searchValue: "",
    selectedTopics: [],
    selectedLanguages: []
  },
  content: {
    id: null,
    type: "",
    title: "",
    meta: null,
    isFollowing: false
  }
};

export const newsReducer = (
  state: INewsState = initialValues,
  action: IActions
): INewsState => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...initialValues
      };

    case SET_CONTENT: {
      return {
        ...state,
        content: {
          ...state.content,
          ...action.payload
        }
      };
    }

    case SET_SEARCH: {
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload
        }
      };
    }

    default: {
      throw new Error("Unhandled action type");
    }
  }
};

import React from "react";
import styled from "styled-components";

import { Text } from "rebass/styled-components";
import { SOURCE_TYPE } from "services/news.types";

const SourceRootText = styled.span`
  font-weight: 600;
  color: ${props => props.theme.colors.white};
`;

const SourceRoot = styled.a`
  font-weight: 600;
  color: ${props => props.theme.colors.white};
  cursor: pointer;

  :hover {
    color: ${props => props.theme.colors.white};
    text-decoration: underline;
  }
`;

interface ISourceDetailProps {
  source: {
    type: string;
    title: string;
    handle?: string;
    domain?: string;
  };
}

const SourceDetail: React.FC<ISourceDetailProps> = ({ source }) => {
  const decorText = (() => {
    switch (source.type) {
      case SOURCE_TYPE.TWITTER:
        return {
          description: "The latest articles from the X profile ",
          label: `@${source.handle}`,
          url: `https://twitter.com/${source.handle}`
        };
      case SOURCE_TYPE.RSS:
        return {
          description: "The latest articles from the RSS Feed: ",
          label: source.title
        };
      default:
        return {
          description: "The latest articles from ",
          url: `https://${source.domain}`,
          label: source.domain
        };
    }
  })();

  return (
    <Text fontSize="14px" marginTop={"4px"} color="white">
      {decorText.description}
      {decorText.url ? (
        <SourceRoot
          href={decorText.url}
          target={"_blank"}
          rel="noopener noreferrer"
        >
          {decorText.label}
        </SourceRoot>
      ) : (
        <SourceRootText>{decorText.label}</SourceRootText>
      )}
    </Text>
  );
};

export default SourceDetail;

import { EditorState, RichUtils } from "draft-js";
import { getSelectedBlock, getSelectionEntity } from "draftjs-utils";

import {
  createSelectionFromRange,
  getEntityRange,
  getEntityBlocks
} from "../../utils/editor";
import { addUrlEntity, findNewUrls, replaceUrlEntity } from "./utils/urlEntity";

class UrlHandler {
  constructor(store, callbacks) {
    this.store = store;
    this.callbacks = callbacks;
  }

  processUrls = disableShortening => {
    //Sometimes the content isn't immediately ready so let's wait to the next iteration of the event loop.
    setImmediate(() => {
      const contentState = this.store.getEditorState().getCurrentContent();
      findNewUrls(contentState).forEach(async urlData => {
        const entityData = {
          unshortenedUrl: urlData.url,
          shortenedUrl: undefined
        };

        const isShortened = /^https:\/\/(cnto\.io|wllw\.co)/.test(urlData.url);

        //Always get the last one.
        const editorState = this.store.getEditorState();
        const selection = createSelectionFromRange(
          urlData.contentBlockKey,
          urlData.range
        );
        let newEditorState = addUrlEntity(
          editorState,
          selection,
          entityData,
          isShortened
        );

        this.store.setEditorState(newEditorState);

        if (isShortened || disableShortening) {
          return;
        }

        const urlEntityKey = newEditorState
          .getCurrentContent()
          .getLastCreatedEntityKey();

        this.callbacks
          .onUrlAdded(urlData.url)
          .then(shortenedUrl => {
            newEditorState = replaceUrlEntity(
              this.store.getEditorState(),
              urlData.contentBlockKey,
              urlEntityKey,
              urlData.url,
              shortenedUrl
            );
            this.store.setEditorState(newEditorState);
          })
          .catch(err => {
            //An error can happen when the user modifies a url before receiving a response from the shortening cb.
            console.error(err);
          });
      });
    });
  };

  handleDelete = () => {
    const editorState = this.store.getEditorState();
    const originalSelection = editorState.getSelection();

    const entityKey = getSelectionEntity(editorState);
    if (!entityKey) {
      return;
    }

    const entityType = editorState
      .getCurrentContent()
      .getEntity(entityKey)
      .getType();
    if (entityType !== "UNSHORTENED_URL") {
      return;
    }

    const selectedBlock = getSelectedBlock(editorState);
    const entityRange = getEntityRange(editorState, entityKey);
    const rangeSelection = createSelectionFromRange(
      selectedBlock.getKey(),
      entityRange
    );

    //Remove entity in current cursor position.
    let newEditorState = RichUtils.toggleLink(
      editorState,
      rangeSelection,
      null
    );

    //Return cursor to its original position.
    newEditorState = EditorState.set(newEditorState, {
      selection: originalSelection
    });

    this.store.setEditorState(newEditorState);

    return "handled";
  };

  unshortenUrl = entityKey => {
    const editorState = this.store.getEditorState();
    const contentState = editorState.getCurrentContent();
    const entityData = contentState.getEntity(entityKey).getData();
    const entityBlock = getEntityBlocks(editorState, entityKey);

    const newEditorState = replaceUrlEntity(
      editorState,
      entityBlock[0].getKey(),
      entityKey,
      entityData.shortenedUrl,
      entityData.unshortenedUrl,
      false
    );
    this.store.setEditorState(newEditorState);
  };

  reshortenUrl = entityKey => {
    const editorState = this.store.getEditorState();
    const contentState = editorState.getCurrentContent();
    const entityData = contentState.getEntity(entityKey).getData();
    const entityBlock = getEntityBlocks(editorState, entityKey);

    const newEditorState = replaceUrlEntity(
      editorState,
      entityBlock[0].getKey(),
      entityKey,
      entityData.unshortenedUrl,
      entityData.shortenedUrl,
      true
    );
    this.store.setEditorState(newEditorState);
  };
}

export default UrlHandler;

import store from "state/store";
import { callApi } from "utils/ContentoApi";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

import { IKeywordData } from "./keyword.types";

import {
  followSection,
  unfollowSection,
  updateSection
} from "state/actions/ContentActions";

export enum KeywordEvent {
  CREATED = "Created Keyword",
  UPDATED = "Updated Keyword",
  DELETED = "Deleted Keyword"
}

export const getAllKeywords = async (): Promise<any> => {
  try {
    const account = store.getState().account.data;

    const data = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/keyword-feeds`
    });

    return data;
  } catch (error) {
    throw new Error(`There was an error when fetching the keyword feeds.`);
  }
};

export const getKeywordById = async (keywordId: string): Promise<any> => {
  try {
    const account = store.getState().account.data;

    const data = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/keyword-feeds/${keywordId}`
    });

    return data;
  } catch (error) {
    throw new Error(`There was an error when fetching the keyword.`);
  }
};

export const createKeyword = async (rules: IKeywordData): Promise<any> => {
  try {
    const account = store.getState().account.data;

    const data = await callApi({
      method: "post",
      url: `/accounts/${account.id}/news/keyword-feeds`,
      data: { ...rules }
    });

    store.dispatch(trackAnalyticsEvent(KeywordEvent.CREATED));

    store.dispatch(
      followSection({ type: "keywords", detail: { ...data, type: "keywords" } })
    );

    return data;
  } catch (error) {
    throw new Error(`There was an error when creating the keyword feed.`);
  }
};

export const updateKeywordById = async (
  keywordId: string,
  keyword: IKeywordData
): Promise<any> => {
  try {
    const account = store.getState().account.data;

    const data = await callApi({
      method: "put",
      url: `/accounts/${account.id}/news/keyword-feeds/${keywordId}`,
      data: { ...keyword }
    });
    store.dispatch(trackAnalyticsEvent(KeywordEvent.UPDATED));

    store.dispatch(
      updateSection({ type: "keywords", detail: { ...data, type: "keywords" } })
    );

    return data;
  } catch (error) {
    throw new Error(`There was an error when updating the keyword.`);
  }
};

export const deleteKeyword = async (keywordId: string): Promise<any> => {
  try {
    const account = store.getState().account.data;

    const data = await callApi({
      method: "delete",
      url: `/accounts/${account.id}/news/keyword-feeds/${keywordId}`
    });

    store.dispatch(trackAnalyticsEvent(KeywordEvent.DELETED));
    store.dispatch(unfollowSection({ type: "keywords", id: keywordId }));

    return data;
  } catch (error) {
    throw new Error(`There was an error when creating the keyword feed.`);
  }
};

export const previewContentsForKeyword = async (
  rules: IKeywordData
): Promise<any> => {
  try {
    const account = store.getState().account.data;

    const data = await callApi({
      method: "post",
      url: `/accounts/${account.id}/news/keyword-feeds/preview`,
      data: { ...rules }
    });

    return data;
  } catch (error) {
    throw new Error(`There was an error when creating preview for keywords.`);
  }
};

export const suggestDomain = async (search: string): Promise<any> => {
  try {
    const account = store.getState().account.data;

    const data = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/domain-suggestions`,
      params: { q: search }
    });

    return data;
  } catch (error) {
    throw new Error(`There was an error on suggesting.`);
  }
};

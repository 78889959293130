import React from "react";
import styled from "styled-components";
import { parseISO, format } from "date-fns";
import { Box, Flex } from "rebass/styled-components";

import {
  Brick,
  Label,
  Small,
  Avatar,
  Service,
  Paragraph,
  ChannelAvatar
} from "components/common/styles";
import PostStats from "./PostStats";
import { WidgetMetrics } from "./Metrics";
import Tooltip from "components/common/Tooltip";
import { getExternalPostUrl } from "utils/channels";
import postTypeText from "assets/images/analytics-post-text.png";
import postTypePhoto from "assets/images/analytics-post-photo.png";
import postTypeVideo from "assets/images/analytics-post-video.png";
import postTypeArticle from "assets/images/analytics-post-link.png";
import defaultAvatar from "assets/images/dashboard/avatar-empty-state.png";
import postPlaceholderImage from "assets/images/post-text-placeholder.png";
import ContentTypeBadge from "components/common/contentTypes/ContentTypeBadge";
import PostAttachmentVideo from "components/schedule/common/postAttachmentVideo";
import PostAttachmentImage from "components/schedule/common/postAttachmentImage";

export const PostContainer = styled(Brick)`
  margin-bottom: 16px;
`;

const PostGrid = styled(Box)`
  display: grid;
  grid-template-columns: minmax(auto, 340px) 1fr;
  grid-column-gap: 16px;
  padding: 16px;
`;

const PostThumbnail = styled.figure`
  background-color: ${props => props.theme.colors.grey01};
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
  position: relative;
  max-height: 200px;
  width: 100%;

  & img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const PostThumbnailPlaceholder = styled(Box)`
  background-color: ${props => props.theme.colors.grey02};
  background-image: url(${postPlaceholderImage});
  background-size: cover;
  background-position: center;
  height: 100%;
`;

const PostThumbnailType = styled(Box)`
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  background-image: url(${props =>
    props.type === "photo"
      ? postTypePhoto
      : props.type === "video"
        ? postTypeVideo
        : props.type === "article"
          ? postTypeArticle
          : postTypeText});
  background-repeat: no-repeat;
  background-size: 40px 40px;
  backdrop-filter: blur(2px);
  border-radius: 20px;
`;

const PostHeader = styled(Flex)`
  justify-content: space-between;
`;

const PostCaption = styled(Box)`
  max-height: 40px;
  max-width: 640px;
  overflow: hidden;
  margin: 16px 0;
`;

const PostBody = styled(Flex)`
  flex-direction: column;
`;

const PostMetrics = styled(WidgetMetrics)`
  border-top: 1px solid ${props => props.theme.colors.grey02};
  margin-top: auto;
  padding: 8px 0 0;
`;

const PostExternalButton = styled(Flex)`
  align-items: center;
  border-radius: 24px;
  color: ${props => props.theme.colors.text02};
  cursor: pointer;
  font-size: 16px;
  margin-left: 8px;
  justify-content: center;
  width: 28px;
  height: 28px;

  &:hover {
    background-color: ${props => props.theme.colors.grey02};
    color: ${props => props.theme.colors.text03};
  }

  & span {
    line-height: 21px;
  }
`;

const ImageAttachmentWrapper = styled.div`
  flex: 1;
  display: flex;
  max-width: 340px;
  height: 200px;
`;

function PostEntry({ data, ...props }) {
  if (!data) return null;

  const {
    attachment,
    caption,
    channel,
    postedAt,
    contentTypeId,
    contentTypeLabel,
    postIdea,
    stats
  } = data;

  const openExternalPost = () => {
    const externalUrl = getExternalPostUrl(data);
    window.open(externalUrl, "_blank");
  };

  const getThumbnailComponent = () => {
    switch (attachment.type) {
      case "photo": {
        return (
          <ImageAttachmentWrapper>
            <PostAttachmentImage
              attachment={attachment}
              height={100}
              maxHeight={200}
            />
          </ImageAttachmentWrapper>
        );
      }
      case "video":
        return <PostAttachmentVideo attachment={attachment} height={200} />;
      case "article":
        return attachment.image ? (
          <PostAttachmentImage
            attachment={{ url: attachment.image }}
            height={200}
          />
        ) : (
          <PostThumbnailPlaceholder />
        );
      default:
        return <PostThumbnailPlaceholder />;
    }
  };

  return (
    <PostContainer {...props}>
      <PostGrid>
        <PostThumbnail onClick={() => {}}>
          {attachment ? getThumbnailComponent() : <PostThumbnailPlaceholder />}
          <PostThumbnailType type={attachment?.type} />
        </PostThumbnail>
        <PostBody>
          <PostHeader>
            <Flex>
              <ChannelAvatar
                key={channel.id}
                $enabled={true}
                mr={12}
                $clickable={false}
              >
                <Avatar
                  $isRounded={true}
                  src={channel.avatar ?? defaultAvatar}
                  width={32}
                  height={32}
                  $noShadow
                  onError={event => {
                    event.target.src = defaultAvatar;
                    event.onerror = null;
                  }}
                />
                <Service
                  key={channel.id}
                  title={channel.username}
                  $type={channel.service}
                />
              </ChannelAvatar>
              <Box>
                <Label>{channel.username}</Label>
                <Small>{format(parseISO(postedAt), "MMMM d HH:mm")}</Small>
              </Box>
            </Flex>
            <Flex alignItems="center">
              <ContentTypeBadge
                contentTypeId={contentTypeId}
                title={postIdea?.title ?? contentTypeLabel}
                variant="dark"
              />
              <Tooltip title={"Open original post"}>
                <PostExternalButton onClick={openExternalPost}>
                  <span className={"icon-external"} />
                </PostExternalButton>
              </Tooltip>
            </Flex>
          </PostHeader>
          <PostCaption>
            <Paragraph>{caption}</Paragraph>
          </PostCaption>
          <PostMetrics>
            <PostStats stats={stats} channel={channel} />
          </PostMetrics>
        </PostBody>
      </PostGrid>
    </PostContainer>
  );
}

export default PostEntry;

export const SET_DRAGGING_ITEM = "@@ui/dnd/SET_DRAGGING_ITEM";
export const REMOVE_DRAGGING_ITEM = "@@ui/dnd/REMOVE_DRAGGING_ITEM";

export const setDraggingItem = data => {
  return {
    type: SET_DRAGGING_ITEM,
    data
  };
};

export const removeDraggingItem = () => {
  return {
    type: REMOVE_DRAGGING_ITEM
  };
};

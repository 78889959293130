import React, { createContext, useReducer } from "react";

export interface IAlertStep {
  title: string;
  description: string;
  banner?: string;
  helpUrl?: string;
  onProceed?: () => void;
  returnButtonText?: string;
  proceedButtonText?: string;
}

interface AlertContext {
  id: string | null;
  persist: boolean;
  steps: IAlertStep[];
  currentStep: number;
}

type NonEmptySteps = IAlertStep[] & { 0: IAlertStep };

export interface IAlertContextPayload {
  id: string;
  persist?: boolean;
  steps: NonEmptySteps;
}

const initialContext: AlertContext = {
  id: null,
  persist: false,
  steps: [],
  currentStep: 0
};

const FeatureAlertContext = createContext<{
  state: AlertContext;
  dispatch: React.Dispatch<any>;
}>({
  state: initialContext,
  dispatch: () => null
});

const featureAlertReducer = (
  state: AlertContext = initialContext,
  action: any
): AlertContext => {
  const { payload, type } = action;
  switch (type) {
    case "ADD":
      return { ...payload, currentStep: 0 };
    case "REMOVE":
      return initialContext;
    case "NEXT_STEP":
      return { ...state, currentStep: state.currentStep + 1 };
    case "PREV_STEP":
      return { ...state, currentStep: state.currentStep - 1 };
    default:
      return state;
  }
};

interface IFeatureAlertProviderProps {
  children: JSX.Element[] | JSX.Element;
}

const FeatureAlertProvider: React.FC<IFeatureAlertProviderProps> = ({
  children
}) => {
  const [state, dispatch] = useReducer(featureAlertReducer, initialContext);

  return (
    <FeatureAlertContext.Provider value={{ state, dispatch }}>
      {children}
    </FeatureAlertContext.Provider>
  );
};

export { FeatureAlertContext };
export default FeatureAlertProvider;

import React from "react";
import config from "../../../config";
import { signInWithLinkedin } from "../../../utils/channels";

import {
  SocialButtonWrapper,
  SocialButtonContent,
  SocialLogo,
  SocialButtonContainer
} from "../styles";

import LinkedInLogo from "assets/images/linkedin-official-logo.png";

export const LinkedinButton = ({
  redirectPath,
  isSignUp,
  text
}: {
  text: string;
  redirectPath: string;
  isSignUp?: boolean | undefined;
}) => {
  const handleConnectLinkedinProfile = () => {
    signInWithLinkedin(`${config.rootUrl}${redirectPath}`, isSignUp);
  };

  return (
    <SocialButtonContainer>
      {/* @ts-ignore */}
      <SocialButtonWrapper
        size="lg"
        width="100%"
        onClick={handleConnectLinkedinProfile}
      >
        <SocialButtonContent>
          <SocialLogo src={LinkedInLogo} />
          {text}
        </SocialButtonContent>
      </SocialButtonWrapper>
    </SocialButtonContainer>
  );
};

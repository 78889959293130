import React, { useRef, useState, useEffect } from "react";

import {
  ReadMore,
  StyledEntryText,
  EntryTextWrapper,
  IStyledEntryText
} from "./styles";
import { SERVICES } from "constants/services";

interface IEntryText extends IStyledEntryText {
  text: string;
}

const EntryText: React.FC<IEntryText> = ({ hasAttachment, text, service }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [content, setContent] = useState<string>(text);
  const [isTruncated, setIsTruncated] = useState<boolean>(true);

  const readMore = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();

    setIsTruncated(false);
    setContent(text);
  };

  useEffect(() => {
    setContent(text);
    if (service === SERVICES.TWITTER) {
      setIsTruncated(false);
    } else if (service === SERVICES.INSTAGRAM) {
      if (text.length <= 120) {
        setIsTruncated(false);
      } else {
        setContent(`${text.slice(0, 120)}...`);
      }
    } else if (ref?.current) {
      const offsetHeight = ref.current.offsetHeight;
      const scrollHeight = ref.current.scrollHeight;

      if (offsetHeight >= scrollHeight) {
        setIsTruncated(false);
      }
    }
  }, [text, service]);

  return (
    <EntryTextWrapper>
      <StyledEntryText
        ref={ref}
        service={service}
        isTruncated={isTruncated}
        hasAttachment={hasAttachment}
      >
        {content}
      </StyledEntryText>
      {isTruncated && <ReadMore onClick={readMore}>Read more</ReadMore>}
    </EntryTextWrapper>
  );
};

export default EntryText;

import { Box } from "rebass";
import React, { useRef, useState } from "react";

import {
  IFilterRange,
  useDashboardState,
  useDashboardDispatch,
  FILTER_RANGE_OPTIONS,
  setFilterRangeAction
} from "contextApi/dashboardContext";
import FilterOptions from "./FilterOptions";
import Popper from "components/common/Popper";
import { DropdownContainer, DashboardFilterWrapper } from "./styles";

const DashboardFilter: React.FC<{}> = () => {
  const ref = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const filterRange = useDashboardState(state => state.filterRange);
  const dispatch = useDashboardDispatch();

  const toggleShowOptions = () => setShowOptions(!showOptions);

  const onOptionSelected = (value: IFilterRange) => {
    dispatch(setFilterRangeAction(value));
    setShowOptions(false);
  };

  return (
    <DashboardFilterWrapper>
      <Box>
        <div ref={ref}>
          <DropdownContainer onClick={toggleShowOptions}>
            <span>{filterRange.label}</span>
            <i className="icon-select" />
          </DropdownContainer>
          {/* @ts-ignore */}
          <Popper
            placement="bottom-start"
            referenceElement={ref.current}
            visible={showOptions}
            onClose={() => setShowOptions(false)}
            exceptions={[ref.current]}
            offset={[-6, 0]}
          >
            <FilterOptions
              selected={filterRange}
              options={FILTER_RANGE_OPTIONS}
              onOptionSelected={onOptionSelected}
            />
          </Popper>
        </div>
      </Box>
    </DashboardFilterWrapper>
  );
};

export default DashboardFilter;

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGranularCallback, useGranularEffect } from "granular-hooks";

import {
  LeftPanel,
  RightPanel,
  CenterPanel,
  ComposerBody,
  ComposerFooter,
} from "./styles";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import Toolkit from "./components/toolkit";

import { formatForScheduler } from "utils/date";
import Properties from "./components/properties";
import { openModal } from "state/actions/ModalActions";
import Scheduler from "./components/postComposer/scheduler";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import Attachment from "./components/postComposer/attachment";
import ComposerNotifications from "./components/postComposer/composerNotifications";
import ComposerModal from "components/modals/composerV2Modal";
import { TimeDisplay } from "components/modals/composerV2Modal/styles";
import PostComposer from "components/composerv2/components/postComposer";
import ConfirmationModal from "components/schedule/form/TaskConfirmationModal";
import AttachmentTools from "./components/postComposer/attachment/AttachmentToolbar";

import { useAppState } from "contextApi/appContext";

const Composer = () => {
  const postDataId = useComposerState(state => state.postData.id);
  const inDraftMode = useComposerState(state => state.inDraftMode);
  const isComposerOpen = useComposerState(state => state.isComposerOpen);
  const confirmationModalProps = useComposerState(
    state => state.confirmationModalProps
  );
  const showWillowAIRevamp = useAppState(state => state.showWillowAIRevamp);
  const inProMode = useComposerState(state => state.inProMode);
  const onPostedEvent = useComposerState(state => state.events.onPostedEvent);
  const toggleProMode = useComposerActions(actions => actions.toggleProMode);
  const setConfirmationModalProps = useComposerActions(
    actions => actions.setConfirmationModalProps
  );
  const setIsComposerCloseConfirmationOpen = useComposerActions(
    actions => actions.setIsComposerCloseConfirmationOpen
  );
  const account = useSelector((state: any) => state.account.data);
  const reduxDispatch = useDispatch();

  const useComposerV2 = !account?.isTelenetCustomer;

  const accountOnboardingProgress = account?.onboardingProgress;

  const handlePosted = useGranularCallback(
    res => {
      const post = (res.posts ? res.posts[0] : undefined) || res.post || res;

      try {
        if (!res.isDraft && !!accountOnboardingProgress) {
          reduxDispatch(
            openModal("ONBOARDING_INFO_MODAL", {
              id: OnboardingStepsEnum.CREATE_POST,
              variant: "success",
              title: res.isPostedNow
                ? "Your post has been published."
                : "Your post has been scheduled.",
              subTitle: !res.isPostedNow ? (
                <>
                  Your post has been scheduled for <br />
                  <TimeDisplay>
                    {formatForScheduler(
                      new Date(post.scheduledAt.split(".")[0])
                    )}
                  </TimeDisplay>
                </>
              ) : (
                "Your post has been sent and will appear on your social media."
              ),
              forOnboarding: !accountOnboardingProgress.includes(
                OnboardingStepsEnum.CREATE_POST
              ),
              ...(!accountOnboardingProgress.includes(
                OnboardingStepsEnum.CREATE_POST
              )
                ? { triggeredBy: OnboardingStepsEnum.CREATE_POST }
                : { secondaryAction: false })
            })
          );
        }
      } catch (_) {}
    },
    [accountOnboardingProgress],
    [reduxDispatch]
  );

  useEffect(
    () => onPostedEvent.listen(handlePosted),
    [onPostedEvent, handlePosted]
  );

  const title = `${postDataId ? "Edit" : "New"} ${
    inDraftMode ? "Draft" : "Post"
  }`;

  return (
    <>
      <ConfirmationModal
        isOpen={!!confirmationModalProps}
        {...(confirmationModalProps ?? {})}
        toggle={() => setConfirmationModalProps(null)}
      />
      <ComposerModal
        title={title}
        proMode={inProMode || showWillowAIRevamp}
        isOpen={isComposerOpen}
        toggleProMode={
          useComposerV2 && !showWillowAIRevamp ? toggleProMode : null
        }
        onClose={() => setIsComposerCloseConfirmationOpen(true)}
      >
        {isComposerOpen && (
          <ComposerBody>
            {(inProMode || showWillowAIRevamp) && (
              <LeftPanel>
                <Properties />
                <Scheduler />
              </LeftPanel>
            )}
            <CenterPanel $inProMode={inProMode || showWillowAIRevamp}>
              <PostComposer />
              {(inProMode || showWillowAIRevamp) && <ComposerNotifications />}
              {(inProMode || showWillowAIRevamp) && <Attachment />}
              {!inProMode && !showWillowAIRevamp && (
                <ComposerFooter>
                  <AttachmentTools showBorder={true} />
                  <Scheduler />
                </ComposerFooter>
              )}
            </CenterPanel>
            {(inProMode || showWillowAIRevamp) && (
              <RightPanel>
                <Toolkit />
              </RightPanel>
            )}
          </ComposerBody>
        )}
      </ComposerModal>
    </>
  );
};

export default Composer;

// @ts-nocheck
import React from "react";

import {
  MenuList,
  MenuDivider,
  MenuContainer
} from "components/navigation/Menu";
import { MenuItem } from "./styles";

interface IStuckPostMenu {
  deletePost: (e: React.MouseEvent) => void;
  reschedulePost: (e: React.MouseEvent) => void;
}

const StuckPostMenu: React.FC<IStuckPostMenu> = ({
  deletePost,
  reschedulePost
}) => {
  return (
    <MenuContainer width={240}>
      <MenuList>
        <MenuItem
          icon="clock"
          color="green"
          label="Reschedule post"
          onClick={reschedulePost}
        />
      </MenuList>
      <MenuDivider my={2} />
      <MenuList>
        <MenuItem
          icon="delete"
          color="danger"
          label="Delete post"
          onClick={deletePost}
        />
      </MenuList>
    </MenuContainer>
  );
};

export default StuckPostMenu;

import React from "react";
import { Flex } from "rebass/styled-components";
import { ErrorTitle, ErrorDescription } from "./styles";

export default function ErrorContent({ title, description }) {
  return (
    <Flex flexDirection="column" maxWidth={500}>
      <ErrorTitle>{title}</ErrorTitle>
      <ErrorDescription>{description}</ErrorDescription>
    </Flex>
  );
}

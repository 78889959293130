import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 18px;
  line-height: 22.5px;
  letter-spacing: -0.005em;
  color: ${props => props.theme.colors.text01};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

export const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 16px;
  background: ${props => props.theme.colors.grey02};
  display: flex;
  justify-content: center;
  align-items: center;

  > i {
    font-size: 20px;
    color: ${props => props.theme.colors.text04};
  }
`;

import React from "react";

import { AnalyticsGrid } from "./styles";
import EmployeeAnalytics from "./employeeAnalytics";
import FollowersAnalytics from "./followersAnalytics";
import ContentMixAnalytics from "./contenMixAnalytics";
import PostStreakAnalytics from "./postStreakAnalytics";
import EngagementAnalytics from "./engagementAnalytics";
import ImpressionsAnalytics from "./impressionsAnalytics";

const AnalyticsWidgets: React.FC<{}> = () => {
  return (
    <AnalyticsGrid>
      <EngagementAnalytics />
      <ImpressionsAnalytics />
      <FollowersAnalytics />
      <ContentMixAnalytics />
      <PostStreakAnalytics />
      <EmployeeAnalytics />
    </AnalyticsGrid>
  );
};

export default AnalyticsWidgets;

import React from "react";

import {
  Card,
  FlairWrapper,
  CardFooter,
  Numbering,
  StepInfo,
  Label
} from "./style";

import { Flair } from "components/dashboard/onboarding/styles";

const OnboardingCard: React.FC<{
  isNext?: boolean;
  isCompleted?: boolean;
  children: JSX.Element | JSX.Element[];
  action?: JSX.Element | JSX.Element[];
  item: {
    id: number;
    instruction: string;
  };
}> = ({ isNext, isCompleted, children, item, action }) => {
  const flair = (() => {
    if (isCompleted) {
      return (
        <Flair size="sm" variant="success">
          COMPLETED
        </Flair>
      );
    } else if (isNext) {
      return (
        <Flair size="sm" variant="primary">
          NEXT UP
        </Flair>
      );
    } else {
      return null;
    }
  })();

  return (
    <Card>
      <FlairWrapper>{flair}</FlairWrapper>
      {children}
      <CardFooter disabled={!isNext}>
        <StepInfo>
          <Numbering isDisabled={!isNext}>{item.id}</Numbering>
          <Label isDisabled={!isNext}>{item.instruction}</Label>
        </StepInfo>
        {isNext ? action : null}
      </CardFooter>
    </Card>
  );
};

export default OnboardingCard;

import styled from "styled-components";
import { Link } from "react-router-dom";
import { Box } from "rebass/styled-components";

export const ForgotBox = styled(Box)`
  text-align: center;
`;

export const ForgotLink = styled(Link)`
  color: ${props => props.theme.colors.blue};
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const ForgotNativeLink = styled.a`
  color: ${props => props.theme.colors.blue};
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const FormFooter = styled(Box)`
  text-align: center;
  margin-top: 32px;
`;

import { useEffect } from "react";

export default function useOutsideAlerter(ref, cb) {
  useEffect(() => {
    let _ref = Array.isArray(ref) ? ref : [ref];

    function handleClickOutside(event) {
      if (_ref.every(r => r.current && !r.current.contains(event.target))) {
        cb();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [ref]);
}

import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { useToaster } from "@hellocontento/maillard";
import ScrollableContentList from "./ScrollableContentList";
import { getSourceById } from "services/news";
import { Box, Flex } from "rebass/styled-components";
import lodash from "lodash";

import ArticleEmpty from "assets/images/contents/article-empty.png";
import EmptyState from "../common/EmptyState";
import { Twitter } from "styled-icons/boxicons-logos";

import { useParams } from "react-router";

import { EmptyImageWrapper } from "./styles";
import ReactLoading from "react-loading";
import {
  LoadingContent,
  RestrictedRegionsText,
  RestrictedRegionsNavigationItem
} from "./styles";

import { useContentoApi } from "../../utils/useContentoApi";
import { regionOptions } from "./constants";
import { SECTION_TYPES, SOURCE_TYPE } from "services/news.types";
import InfluencerDetailBanner from "./common/detail/InfluencerDetailBanner";

const PAGE_SIZE = 9;
const FIRST_PAGE = 1;
const languageCodeMap = {
  nl: "Dutch",
  en: "English"
};

const ContentInfluencerDetail = ({ account, excludedTld, ...restProps }) => {
  const params = useParams();
  const addToast = useToaster();
  const [pageData, setPageData] = useState(null);

  const contextSource =
    restProps.sourceType === SECTION_TYPES.DOMAINS
      ? restProps.sourceType
      : SECTION_TYPES.SOURCES;

  const fetchContents = async pageNumber => {
    try {
      const response = await getSourceById(params.sourceId, contextSource, {
        page: pageNumber,
        limit: PAGE_SIZE
      });
      setPageData(data => ({
        items:
          pageNumber > 1
            ? [...data.items, ...response.contents]
            : response.contents,
        source: response.section,
        page: pageNumber,
        isFinished: response.contents.length < PAGE_SIZE,
        pageSize: PAGE_SIZE,
        excludedTld
      }));
    } catch (err) {
      if (err.name !== "RequestCancelled") {
        addToast(
          "There was an error fetching your content. Please try again later or contact support.",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, [params.sourceId]);

  const refreshContent = useCallback(async () => {
    fetchContents(FIRST_PAGE);
  }, [excludedTld, params.sourceId, addToast]);

  useEffect(() => {
    refreshContent();
  }, [refreshContent, params.sourceId]);

  const emptyState =
    restProps.sourceType === SOURCE_TYPE.TWITTER ? (
      <EmptyState
        loading={!pageData}
        header={"We couldn’t find any articles"}
        description={
          "Unfortunately the feed you are following isn’t returning any articles."
        }
        icon={{
          component: Twitter,
          width: "24px",
          height: "24px"
        }}
      />
    ) : (
      <EmptyState
        center={false}
        header={"We couldn’t find any articles"}
        description={
          "Unfortunately the feed you are following isn’t returning any articles."
        }
        image={<EmptyImageWrapper src={ArticleEmpty} />}
      />
    );

  return (
    <>
      {pageData && (
        <InfluencerDetailBanner
          pageData={pageData}
          sourceId={params.sourceId}
          sourceType={restProps.sourceType}
        />
      )}
      <div>
        {pageData && pageData.items?.length < 1 && pageData.source.language && (
          <LanguageTextBox
            language={pageData.source.language}
            account={account}
          />
        )}
      </div>
      <ScrollableContentList
        pageData={pageData}
        onScheduled={refreshContent}
        onDismiss={refreshContent}
        onNextPage={fetchContents}
        emptyState={emptyState}
        sourceId={params.sourceId}
      />
    </>
  );
};

const LanguageTextBox = ({ language, account }) => {
  const to = `/accounts/${account.id}/content/preferences`;
  const [onHover, setOnHover] = useState(false);
  const [restrictedRegions, setRestrictedRegions] = useState([]);
  const [fetchRegionSettings, cancelFetchRegionSettings] = useContentoApi(
    `/accounts/${account.id}/news/settings/regions`
  );
  const [isLoading, setIsLoading] = useState(false);
  const addToast = useToaster();

  const fetchContents = async language => {
    try {
      setIsLoading(true);
      const res = await fetchRegionSettings();

      const updatedRegionData = regionOptions.map(region => ({
        ...region,
        enabled: res.some(
          r => r.language === region.language && r.region === region.region
        )
      }));

      const restrictedRegions = updatedRegionData.filter(
        region => region.enabled === false && region.language === language
      );

      // console.log("Restricted Regions:: ", restrictedRegions);
      setRestrictedRegions(restrictedRegions);
    } catch (err) {
      if (err.name !== "RequestCancelled") {
        addToast(
          "There was an error fetching your content. Please try again later or contact support.",
          "error"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const refreshContent = useCallback(async () => {
    fetchContents(language);
  }, []);

  useEffect(() => {
    refreshContent();

    return () => cancelFetchRegionSettings();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingContent>
          <ReactLoading color="#bbb" type="cylon" />
        </LoadingContent>
      ) : (
        restrictedRegions && (
          <Flex alignItems={"center"} justifyContent={"center"}>
            <Box
              m="0 auto"
              backgroundColor="grey"
              padding={12}
              alignSelf="center"
              justifySelf="center"
              display="inline-block"
              css={{ borderRadius: "10px" }}
            >
              <RestrictedRegionsNavigationItem
                to={to}
                onMouseEnter={() => setOnHover(true)}
                onMouseLeave={() => setOnHover(false)}
                p={12}
                data-tip
              >
                <RestrictedRegionsText
                  sx={{
                    textTransform: "capitalize",
                    textAlign: "center"
                  }}
                >
                  This website/Rss feed is in {languageCodeMap[language]} -
                  would you like to enable the following disabled regions{" "}
                  {restrictedRegions.map(region => region.label).join(", ")} ?
                </RestrictedRegionsText>
              </RestrictedRegionsNavigationItem>
            </Box>
          </Flex>
        )
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser,
    excludedTld: state.regionFilter.excludedTld
  };
};

export default connect(mapStateToProps, {})(ContentInfluencerDetail);

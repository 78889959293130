import _ from "lodash";
import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import ReactRotatingText from "react-rotating-text";
import { Box, Flex } from "rebass/styled-components";
import { useToaster } from "@hellocontento/maillard";

import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import { useAppState } from "contextApi/appContext";
import PdfAttachment from "./PdfAttachment";
import ImageAttachment from "./ImageAttachment";
import VideoAttachment from "./VideoAttachment";
import ArticleAttachment from "./ArticleAttachment";
import { SystemMessage } from "components/composerv2/styles";
import { PostAttachment, AttachmentLoading } from "./styles";

const HELP_CENTER_URL = "https://support.willow.co/en/knowledge/how-do-i-reduce-the-size-of-an-image-video-or-pdf";

// Define size limits for different attachment types
const SIZE_LIMITS = {
  photo: 5 * 1024 * 1024, // 5MB for images
  video: 500 * 1024 * 1024, // 500MB for videos
  pdf: 10 * 1024 * 1024 // 10MB for PDFs (assuming a limit)
};

const Attachment = () => {
  const addToast = useToaster();
  const [systemMessage, setSystemMessage] = useState(null);

  const inProMode = useComposerState(state => state.inProMode);
  const attachments = useComposerState(state => state.attachments);
  const showWillowAIRevamp = useAppState(state => state.showWillowAIRevamp);
  const removeAttachment = useComposerActions(
    actions => actions.removeAttachment
  );
  const reorderAttachment = useComposerActions(
    actions => actions.reorderAttachment
  );
  const addUploadedAttachments = useComposerActions(
    actions => actions.addUploadedAttachments
  );
  const removeArticleAttachment = useComposerActions(
    actions => actions.removeArticleAttachment
  );
  const setIsUploadingAttachments = useComposerActions(
    actions => actions.setIsUploadingAttachments
  );

  const channelAttachments = attachments["all"];
  const validationMessages = channelAttachments.validationErrors;

  const waitingMessages = [
    "Retrieving website contents...",
    "Still working...",
    "Still working...",
    ..._.shuffle([
      "“Patience is a conquering virtue.”",
      "“The two most powerful warriors are patience and time.”",
      "“To lose patience is to lose the battle.”",
      "“At the bottom of patience one finds heaven.”",
      "“The salt of patience seasons everything.”",
      "“Time is the wisest of all counselors.”",
      "“Patience is bitter, but its fruit is sweet.”",
      "“Our patience will achieve more than our force.”",
      "“Rivers know this: there is no hurry. We shall get there some day.”",
      "“Be patient and tough; someday this pain will be useful to you.”",
      "“Patience is a key element of success.”",
      "“All great achievements require time.”",
      "“One minute of patience, ten years of peace.”"
    ])
  ];

  const setIsUploading = status => {
    setIsUploadingAttachments(status, channelAttachments.type);
  };

  const handleAttachmentAddition = attachments => {
    addUploadedAttachments(attachments, channelAttachments.type);
  };

  const handleAttachmentRemoval = index => {
    if (channelAttachments.type === "article") {
      removeArticleAttachment();
    } else {
      removeAttachment(index, channelAttachments.type);
    }
  };

  const handleImageReorder = (sourceIndex, destinationIndex) => {
    reorderAttachment(sourceIndex, destinationIndex, channelAttachments.type);
  };

  const onError = validationError => {
    const type = channelAttachments.type;
    const limit = SIZE_LIMITS[type] ? SIZE_LIMITS[type] / (1024 * 1024) : "unknown"; // Convert to MB

    addToast(
      `There was an error uploading the ${type}: "${validationError.message}"`,
      "error"
    );

    if (validationError.message.toLowerCase().includes("size")) {
      setSystemMessage({
        type: "error",
        message: `Your ${type} is too large. The limit is ${limit}MB. To resize your attachment, follow `,
        link: true
      });
    }
  };

  useEffect(() => {
    setSystemMessage(null);
  }, [channelAttachments.type]);

  const isScrapping =
    channelAttachments.type === "article" &&
    channelAttachments.articleAttachments.isScrapping;

  return (
    <PostAttachment $inProMode={inProMode || showWillowAIRevamp}>
      <Box mb={inProMode || showWillowAIRevamp ? 0 : 12} mt={10}>
        {systemMessage && (
          <Box mb={12}>
            <SystemMessage type={systemMessage.type}>
              <div>{systemMessage.message}
                <a href={HELP_CENTER_URL} target="_blank" rel="noopener noreferrer">
                  this link
                </a>.
              </div>
            </SystemMessage>
          </Box>
        )}

        {channelAttachments.type === "video" &&
          channelAttachments.videoAttachments.attachments.length < 1 && (
            <Box mb={12}>
              <SystemMessage type="info">
                Only mp4 H.264 format videos are currently accepted
              </SystemMessage>
            </Box>
          )}

        {validationMessages.map(notification => (
          <Box key={notification.message} mb={12}>
            <SystemMessage
              type={notification.type}
              infoUrl={notification.infoUrl}
              service={notification.service}
              actionComponent={notification.actionComponent}
            >
              {notification.message}
            </SystemMessage>
          </Box>
        ))}

        {isScrapping && (
          <AttachmentLoading>
            <Flex>
              <ReactLoading color="#1e62e0" type="spin" width={16} />
              &nbsp;&nbsp;&nbsp;
              <p>
                <ReactRotatingText pause={1000} items={waitingMessages} />
              </p>
            </Flex>
          </AttachmentLoading>
        )}

        {channelAttachments.type === "article" && (
          <ArticleAttachment
            onRemove={handleAttachmentRemoval}
            article={channelAttachments.articleAttachments.attachment}
          />
        )}

        {channelAttachments.type === "photo" && (
          <ImageAttachment
            onError={onError}
            onReorder={handleImageReorder}
            setIsUploading={setIsUploading}
            onRemove={handleAttachmentRemoval}
            onUpload={handleAttachmentAddition}
            isUploading={channelAttachments.photoAttachments.isUploading}
            images={channelAttachments.photoAttachments.attachments.map(
              image => image.url
            )}
          />
        )}

        {channelAttachments.type === "video" && (
          <VideoAttachment
            onError={onError}
            setIsUploading={setIsUploading}
            onRemove={handleAttachmentRemoval}
            onUpload={handleAttachmentAddition}
            isUploading={channelAttachments.videoAttachments.isUploading}
            videos={channelAttachments.videoAttachments.attachments.map(
              video => video.url
            )}
          />
        )}

        {channelAttachments.type === "pdf" && (
          <PdfAttachment
            onError={onError}
            setIsUploading={setIsUploading}
            onRemove={handleAttachmentRemoval}
            onUpload={handleAttachmentAddition}
            isUploading={channelAttachments.pdfAttachments.isUploading}
            pdfs={channelAttachments.pdfAttachments.attachments}
          />
        )}
      </Box>
    </PostAttachment>
  );
};

export default Attachment;

import React from "react";
import styled from "styled-components";

import AllPostsTable from "./allPostsTable";
import PostsToRepurpose from "./postsToRepurposeTable";
import { Flex } from "rebass/styled-components";

const Container = styled(Flex)`
  flex-direction: column;
  gap: 60px;
`;

const PostsPage: React.FC = () => {
  return (
    <Container>
      <AllPostsTable />
      <PostsToRepurpose />
    </Container>
  );
};

export default PostsPage;

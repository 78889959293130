import React from "react";
import ReactSwitch from "react-switch";
import styled from "styled-components";

const SwitchWrap = styled.div`
  border: none;
  border-radius: 12.5px;
  padding: 4px;
  padding-bottom: 2px;
  display: inline-block;
`;

const Switch = ({ ...props }) => {
  const { wrap, size } = props;

  const Wrap = ({ isWrapped, children }) => {
    return isWrapped === "false" ? (
      <>{children}</>
    ) : (
      <SwitchWrap>{children}</SwitchWrap>
    );
  };

  return (
    <Wrap isWrapped={wrap}>
      <ReactSwitch
        onColor="#0061FF"
        uncheckedIcon={false}
        checkedIcon={false}
        height={size ? size : 15}
        width={size ? size * 2 : 30}
        {...props}
      />
    </Wrap>
  );
};

export default Switch;

import React from "react";
import ExploreSources from "./ExploreSources";

import EmptySearchState from "../EmptySearchState";
import { IDomain, ISource } from "services/news";

interface ISourceListProps {
  type: string;
  title: string;
  searchContext: string;
  sources: Array<IDomain | ISource>;
  changeSearchContext: () => void;
}

const SourceList: React.FC<ISourceListProps> = ({
  type,
  title,
  searchContext,
  sources,
  changeSearchContext
}) => {
  return sources.length > 0 ? (
    <ExploreSources
      type={type}
      title={title}
      sources={sources}
      searchContext={searchContext}
      changeSearchContext={changeSearchContext}
    />
  ) : (
    <EmptySearchState />
  );
};

export default SourceList;

import ReactLoading from "react-loading";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { useAppState } from "contextApi/appContext";

const WelcomePage = React.memo(({ history }) => {
  const client = useAppState(state => state.clientSettings.client);
  const { accounts } = useSelector(state => state.auth.currentUser);

  useEffect(() => {
    if (accounts && accounts.length >= 1) {
      let selectedAccount = accounts[0];
      if (!!client && client === "telenet") {
        const telenetAccounts = accounts.filter(
          account => account.isTelenetCustomer
        );

        if (telenetAccounts.length >= 1) {
          selectedAccount = telenetAccounts[0];
        }
      }

      history.push(`/accounts/${selectedAccount.id}/dashboard`);
    } else {
      history.push(`/onboarding`);
    }
  }, [accounts, client, history]);

  return <>{accounts.length >= 1 ? <ReactLoading /> : null}</>;
});

export default withRouter(WelcomePage);

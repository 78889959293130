import styled from "styled-components";
import Button from "../common/Button";
import { Headline1 } from "../common/styles";
import { Text, Flex, Box } from "rebass/styled-components";

export const IdeaGridContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media screen and (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const IdeaPageContainer = styled(Flex)`
  justify-content: center;
  margin-bottom: 120px;
`;

export const IdeaPageHeader = styled(Box)`
  padding: 40px 0;
  max-width: 765px;
`;

export const IdeaPageTitle = styled(Headline1)`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 105%;
  letter-spacing: -0.36px;
`;

export const IdeaPageDescription = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.16px;
  color: ${props => props.theme.colors.text02};

  > a {
    text-decoration-line: underline;
    color: ${props => props.theme.colors.blue};
  }
`;

export const IdeaWrapper = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  max-width: 1128px;
  padding: 0 24px;
  width: 100%;

  .carousel-box {
    overflow: visible;
    padding-bottom: 24px;
    border-bottom: 1px solid ${props => props.theme.colors.grey02};

    > div {
      > div:not([hidden]) {
        > div {
          > div {
            grid-template-columns: repeat(auto-fill, 306px);
            justify-content: space-between;
          }
        }
      }
    }

    &.saved-posts {
      > div {
        > div:not([hidden]) {
          > div {
            > div {
              grid-template-columns: repeat(auto-fill, 257px);
            }
          }
        }
      }
    }
  }
`;

export const IdeaNav = styled(Flex)`
  background-color: white;
  border-bottom: 1px solid ${props => props.theme.colors.grey02};
  margin-bottom: 24px;
  overflow-x: auto;
  padding: 16px 0;

  & button {
    min-width: fit-content;
  }
`;

export const IdeaCard = styled(Box)`
  border-radius: 8px;
  box-shadow: 0px 1px 2px 0px #1c1e1f0a;
  box-shadow: 0px 4px 24px 0px #1c1e1f14;
  border: 1px solid #606E801F;
  overflow: hidden;
`;

export const IdeaCardThumbnail = styled.figure<{ $bgColor?: string }>`
  background-color: ${props =>
    props.$bgColor ? props.$bgColor : props.theme.colors.gray};
  box-sizing: border-box;
  height: 344px;
  padding: 48px;
  overflow: hidden;
  position: relative;
`;

export const IdeaCardImage = styled(Flex)<{ $isVisible?: boolean}>`
  justify-content: center;
  object-fit: contain;
  height: 100%;
  width: 100%;
  opacity: ${props => (props.$isVisible ? 1 : 0.5)};
  transform: scale(${props => (props.$isVisible ? 1 : 0.99)});
  transition: all 0.1s ease-in;

  & img {
    border-radius: 4px;
    height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
`;

export const IdeaCardAction = styled(Flex)<{ $isVisible?: boolean }>`
  align-items: center;
  background-color: ${props => props.theme.colors.text02};
  box-sizing: border-box;
  height: 100%;
  left: 0;
  justify-content: center;
  opacity: ${props => (props.$isVisible ? 1 : 0)};
  position: absolute;
  transition: all 0.15s ease-in;
  top: 0;
  transform: scale(${props => (props.$isVisible ? 1 : 1.03)});
  width: 100%;
`;

export const IdeaCardButton = styled(Button)`
  background-color: white;

  &:hover {
    background-color: ${props => props.theme.colors.gray};
  }

  &:focus,
  &:active {
    background-color: ${props => props.theme.colors.lightGray};
  }
`;

IdeaCardButton.defaultProps = {
  variant: "secondary",
  size: "lg",
  isRounded: true
};

export const IdeaCardBody = styled(Box)<{ $bgColor?: string }>`
  background-color: ${props =>
    props.$bgColor ? props.$bgColor : props.theme.colors.darkgray};
  padding: 32px 24px;
  text-align: center;
  height: 100%;
`;

export const IdeaCardTitle = styled(Text)`
  color: white;
  font-size: 19px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: -0.57px;
  margin: 6px 0;
`;

export const IdeaCardDescription = styled(Text)`
  color: white;
  font-size: 14px;
  line-height: 19px;
  opacity: 0.66;
`;
export const IdeasBody = styled.div`
  width: 100%;
  display: flex;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  width: 100%;
  position: relative;
`;

export const SecondaryHeader = styled(Flex)`
  background-color: white;
  justify-content: center;
  position: sticky;
  top: 64px;
  z-index: 4;

  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(20px);
`;

export const SecondaryHeaderContainer = styled(Flex)`
  align-items: center;
  flex: 1;
  margin: 0 32px;
  justify-content: space-between;
  padding: 12px 0px;
  max-width: 1288px;
  height: 64px;

  .right-menu {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    > i {
      font-size: 20px;
      color: ${props => props.theme.colors.text03};
    }
  }
`;

export const SecondaryHeaderSearch = styled(Box)`
  display: grid;
  align-items: center;
  flex: 1;
  margin: 0 24px;
  height: 56px;
  max-width: 680px;
`;
export const ContentSectionWrapper = styled(Flex)`
  flex: 1;
  justify-content: center;
  border-radius: 16px;
  background: ${props => props.color ?? props.theme.colors.text01};
  /* min-height: 242px; */
  padding: 32px 0px;
`;
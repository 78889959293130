import React from "react";
import styled from "styled-components";

import DailyRecommendationBanner from "assets/images/contents/Onboarding-News-Recommendations.png";

import { SourceIconWrapper } from "../style";

const Image = styled.img`
  width: 150px;
`;

const DailyRecommendation: React.FC<{}> = () => {
  return (
    <SourceIconWrapper>
      <Image
        src={DailyRecommendationBanner}
        alt="daily recommendation banner"
      />
    </SourceIconWrapper>
  );
};

export default DailyRecommendation;

import { useSelector } from "react-redux";
import React, { useState, useEffect, useMemo } from "react";

import {
  useAnalyticsState,
  useAnalyticsActions
} from "contextApi/analyticsContext";
import { NoDataForTheseCriteria } from "../styles";
import PostsToRepurposeTable from "./PostsToRepurposeContent";
import PostsToRepurposeHeader from "./PostsToRepurposeHeader";
import Loader from "../widgets/Loader";

interface Channel {
  id: string;
  avatar: string;
  username: string;
  service: string;
}

interface AccountState {
  data: {
    id: string;
    channels: Channel[];
  };
}

interface RootState {
  account: AccountState;
}

const PostsToRepurpose: React.FC = () => {
  const account = useSelector((state: RootState) => state.account.data);
  const data = useAnalyticsState(state => state.repurposePosts.data);
  const setData = useAnalyticsActions(state => state.setRepurposePostsData);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  } | null>(null);
  const isLoading = useAnalyticsState(state => state.repurposePosts.isLoading);
  const selectedChannels = useAnalyticsState(
    state => state.repurposePosts.channelIds
  );
  const engagementRate = useAnalyticsState(
    state => state.repurposePosts.engagementRate
  );
  const setEngagementRate = useAnalyticsActions(
    state => state.setRepurposePostsEngagementRate
  );
  const setSelectedChannels = useAnalyticsActions(
    state => state.setRepurposePostsChannelIds
  );
  const refreshRepurposePosts = useAnalyticsActions(
    state => state.refreshRepurposePosts
  );

  const selectedCategories = useAnalyticsState(state => state.repurposePosts.categories);

  const filteredData = useMemo(
    () => data.filter(p => selectedCategories.includes(p.contentTypeId)),
    [data, selectedCategories]
  );

  useEffect(() => {
    refreshRepurposePosts();
  }, [refreshRepurposePosts]);

  const sortData = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }

    const sortedData = [...data].sort((a, b) => {
      const aValue =
        key === "postedAt"
          ? new Date(a[key])
          : a.stats.raw[key] ?? a.stats.calculated[key];
      const bValue =
        key === "postedAt"
          ? new Date(b[key])
          : b.stats.raw[key] ?? b.stats.calculated[key];

      if (aValue < bValue) {
        return direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  return (
    <div>
      <PostsToRepurposeHeader
        minEngagementRate={engagementRate}
        setMinEngagementRate={setEngagementRate}
        selectedChannels={selectedChannels}
        setSelectedChannels={setSelectedChannels}
        account={account}
      />
      {isLoading && <Loader />}
      {/* {error && <div>{error}</div>} */}
      {!isLoading && /* !error && */ filteredData.length === 0 && (
        <NoDataForTheseCriteria>
          No posts available. Please adjust your filters or search criteria.
        </NoDataForTheseCriteria>
      )}
      {!isLoading && /*  !error && */ filteredData.length > 0 && (
        <PostsToRepurposeTable
          data={filteredData}
          sortConfig={sortConfig}
          sortData={sortData}
        />
      )}
    </div>
  );
};

export default PostsToRepurpose;

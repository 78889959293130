import React from "react";
import { Flex } from "rebass";
import { Check } from "@styled-icons/fa-solid";

import {
  PlanName,
  PlanPrice,
  PlanHeader,
  ColorButton,
  PlanFeature,
  PlanFeatures,
  PlanDescription
} from "./styles";
import { FormBox } from "components/common/form/styles";
import {
  telenetCoachingAddons,
  telenetCoachingAddonMapping
} from "constants/index";

const BillingCoachingAddon = ({ addon, price, onSelect }) => {
  return (
    <FormBox variant="black" mx={20} my={20}>
      <PlanHeader borderColor="white">
        <PlanName color={addon.color}>{addon.title}</PlanName>
        <PlanPrice currency={price.currency} period={"once"} color="white">
          {parseInt(price.amount) / 100}
        </PlanPrice>
        <PlanDescription color="white">{addon.subTitle}</PlanDescription>
      </PlanHeader>
      <PlanFeatures>
        {addon.features.map((feature, index) => (
          <PlanFeature key={index} color="white">
            <Check size={12} />
            <span>{feature}</span>
          </PlanFeature>
        ))}
      </PlanFeatures>
      <Flex width="100%" flexDirection="column" mb={16}>
        <ColorButton
          backgroundColor={addon.color}
          variant="primary"
          size="lg"
          onClick={() => onSelect()}
        >
          Select
        </ColorButton>
      </Flex>
    </FormBox>
  );
};

export default function BillingCoachingAddOn({ cbAddons, currency, onSelect }) {
  return (
    <Flex style={{ justifyContent: "center" }} width="100%">
      {Object.entries(telenetCoachingAddons).map(([addonId, addon]) => {
        const internalAddonId = telenetCoachingAddonMapping[currency][addonId];
        const cbAddon = cbAddons.find(p => p.id === internalAddonId);
        if (!cbAddon) return null;
        const price = {
          currency: cbAddon.currency_code,
          amount: cbAddon.price,
          period: cbAddon.period_unit
        };

        return (
          <BillingCoachingAddon
            key={addonId}
            addon={addon}
            price={price}
            onSelect={() => onSelect(addonId)}
          />
        );
      })}
    </Flex>
  );
}

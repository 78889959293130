import _ from "lodash";
import React from "react";
import { Flex } from "rebass";

import Button from "components/common/Button";
import Loader from "components/common/loading/Loader";

interface IKeywordActionsProps {
  isEdit: boolean;
  isProcessing: boolean;
  createFeed: () => void;
  updateFeed: () => void;
}

const KeywordActions: React.FC<IKeywordActionsProps> = ({
  isEdit,
  createFeed,
  updateFeed,
  isProcessing
}) => (
  <Flex justifyContent={"flex-end"} my={2}>
    {isEdit ? (
      // @ts-ignore
      <Button variant={"primary"} onClick={updateFeed} disabled={isProcessing}>
        <Flex
          flexDirection={"row"}
          justifyContent="center"
          alignItems={"center"}
        >
          {isProcessing && <Loader size={16} />}
          Update Feed
        </Flex>
      </Button>
    ) : (
      // @ts-ignore
      <Button variant={"primary"} onClick={createFeed} disabled={isProcessing}>
        <Flex
          flexDirection={"row"}
          justifyContent="center"
          alignItems={"center"}
        >
          {isProcessing && <Loader size={16} />}
          Create Feed
        </Flex>
      </Button>
    )}
  </Flex>
);

export default KeywordActions;

import * as contentActions from "../actions/ContentActions";

const initialState = {
  sections: []
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case contentActions.ADD_SECTIONS:
      return Object.assign({}, state, {
        sections: action.data
      });
    case contentActions.FOLLOW_SECTION: {
      const newSection = action.data;
      newSection.type = ["domains", "keywords"].includes(newSection.type)
        ? "sources"
        : newSection.type;

      const sections = state.sections.map(section => {
        if (section.type === newSection.type) {
          section.details.push(newSection.detail);
        }

        return section;
      });

      return Object.assign({}, state, {
        sections
      });
    }
    case contentActions.UNFOLLOW_SECTION: {
      const type = ["domains", "keywords"].includes(action.data.type)
        ? "sources"
        : action.data.type;

      const sections = state.sections.map(section => {
        if (section.type === type) {
          section.details = section.details.filter(
            detail => detail.id !== action.data.id
          );

          return section;
        }

        return section;
      });

      return Object.assign({}, state, {
        sections
      });
    }
    case contentActions.UPDATE_SECTION: {
      const type = ["domains", "keywords"].includes(action.data.type)
        ? "sources"
        : action.data.type;

      const sections = state.sections.map(section => {
        if (section.type === type) {
          section.details = section.details.map(detail => {
            if (detail.id === action.data.detail.id) return action.data.detail;
            else return detail;
          });
          return section;
        }

        return section;
      });
      return Object.assign({}, state, {
        sections
      });
    }

    default:
      return state;
  }
};

export default accountReducer;

import Modal, { Styles } from "react-modal";
import React, { useCallback, useMemo, useRef, useState } from "react";

import {
  Header,
  Footer,
  Description,
  MenuContainer,
  ActionsWrapper,
  HelpMenuWrapper,
  HeaderIconWrapper
} from "./styles";
import Button from "components/common/Button";
import Popper from "components/common/Popper";
import { modalStyles } from "components/modals/styles";
import { MenuList, MenuItem } from "components/navigation/Menu";
import { Headline4, Service, serviceIconMap } from "components/common/styles";

interface IHelpLinks {
  url: string;
  label: string;
}

interface IChannelsConnectionModal extends React.PropsWithChildren {
  headerIcon: keyof typeof serviceIconMap | React.ReactElement;
  headerTitle: string;
  description: string;
  helpLinks?: IHelpLinks[];
  onClose?: Function;
  cancelButtonLabel?: string;
  successButtonLabel?: string;
  onSuccess?: Function;
}

export const ChannelsConnectionModal: React.FC<IChannelsConnectionModal> = ({
  children,
  headerIcon,
  headerTitle,
  description,
  helpLinks = [],
  onClose,
  cancelButtonLabel = "Cancel",
  successButtonLabel = "Close",
  onSuccess
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const helpButtonRef = useRef<HTMLDivElement | null>(null);

  const styles = modalStyles(479, true) as Styles;
  styles.overlay!.background = "rgba(28, 30, 31, 0.40)";
  styles.overlay!.backdropFilter = "blur(2px)";
  styles.content!.display = "flex";
  styles.content!.flexDirection = "column";
  styles.content!.gap = "10px";
  styles.content!.padding = "20px 10px";
  styles.content!.borderRadius = "8px";

  const HeaderIcon = useMemo(() => {
    if (typeof headerIcon === "string") {
      return (
        <Service
          $size={16}
          $inline={true}
          $type={headerIcon}
        />
      );
    } else {
      return headerIcon;
    }
  }, [headerIcon]);

  const toggleShowOptions = useCallback((value?: boolean) => {
    if (typeof value === "boolean") {
      setShowOptions(value);
    } else {
      setShowOptions(value => !value);
    }
  }, []);

  const handleOpenChat = useCallback(() => {
    // @ts-ignore
    const hubspot = window.HubSpotConversations;

    if (hubspot) {
      if (hubspot) {
        window.history.pushState({}, "hs_support", "?hs_support=true");
        hubspot.widget.refresh({
          openToNewThread: true
        });
        hubspot.widget.open();
      }
    }

    toggleShowOptions(false);
  }, [toggleShowOptions]);

  return (
    <Modal ariaHideApp={false} isOpen={true} style={styles}>
      <Header>
        <HeaderIconWrapper>{HeaderIcon}</HeaderIconWrapper>
        <Headline4>{headerTitle}</Headline4>
      </Header>
      <Description>
        <p>{description}</p>
      </Description>
      {children}
      <Footer>
        <HelpMenuWrapper ref={helpButtonRef}>
          {/* @ts-ignore */}
          <Button
            size="xl"
            isRounded={false}
            variant="secondary"
            onClick={toggleShowOptions}
          >
            <i className="icon-support-filled" />
            Need Help?
          </Button>
          <Popper
            placement="bottom"
            offset={({ reference, popper }) => [
              (popper.width - reference.width) / 2,
              6
            ]}
            referenceElement={helpButtonRef.current}
            visible={showOptions}
            onClose={() => toggleShowOptions(false)}
            exceptions={[helpButtonRef.current]}
          >
            <MenuContainer>
              <MenuList>
                {helpLinks.map((link, index) => (
                  <React.Fragment key={index}>
                    <MenuItem
                      label={link.label}
                      onClick={() => {
                        window.open(link.url, "_blank");
                      }}
                    />
                  </React.Fragment>
                ))}
                <MenuItem
                  label="Contact support"
                  onClick={handleOpenChat}
                />
              </MenuList>
            </MenuContainer>
          </Popper>
        </HelpMenuWrapper>
        <ActionsWrapper>
          {onClose && (
            // @ts-ignore
            <Button
              size="xl"
              isRounded={false}
              variant="secondaryQuietAlt"
              onClick={onClose}
            >
              {cancelButtonLabel}
            </Button>
          )}
          {onSuccess && (
            // @ts-ignore
            <Button
              size="xl"
              isRounded={false}
              variant="primary"
              onClick={onSuccess}
            >
              {successButtonLabel}
            </Button>
          )}
        </ActionsWrapper>
      </Footer>
    </Modal>
  );
};

export default ChannelsConnectionModal;

import { Box } from "rebass";
import React, { useState } from "react";

import { dateButtonLabel } from "../utils/taskUtils";

import DateSelect from "./DateSelect";
import Popper from "components/common/Popper";
import ScheduleButton from "../form/ScheduleButton";

const Schedule = ({ taskProperties, isRepeating, updateTaskProperties }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  let exceptions = [referenceElement, "rep__exception"];

  return (
    <Box ref={setReferenceElement}>
      <ScheduleButton
        icon={isRepeating ? "icon-repeat" : "icon-calendar"}
        color={isRepeating ? "green" : "text01"}
        variant={"tertiary"}
        onClick={toggleShowOptions}
      >
        {dateButtonLabel(taskProperties)}
      </ScheduleButton>
      <Popper
        offset={[-120, 0]}
        referenceElement={referenceElement}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={exceptions}
      >
        <DateSelect
          date={taskProperties.date}
          dateStart={taskProperties.dateStart}
          dateEnd={taskProperties.dateEnd}
          settings={taskProperties.settings}
          onChange={updates => updateTaskProperties(updates)}
        />
      </Popper>
    </Box>
  );
};

export default Schedule;

import _ from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { ThemeProvider } from "styled-components";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { ToastProvider, Toaster } from "@hellocontento/maillard";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import { GlobalStyle, theme } from "./theme";

import "./assets/css/fonts.css";
import "simplebar/dist/simplebar.min.css";
import "./assets/css/date-picker-override.css";
import "@material/checkbox/dist/mdc.checkbox.css";
import "@material/textfield/dist/mdc.textfield.css";
import "react-datepicker/dist/react-datepicker.css";
import "@material/form-field/dist/mdc.form-field.css";

import "./assets/css/styles.css";
import "./assets/css/animations.css";
import "./assets/css/mdc-override.css"; //TODO: Override from scss

import Logout from "./components/auth/Logout";
import Quote from "containers/quote/QuotePage";
import ErrorPage from "./containers/ErrorPage";
import SignUpPage from "./containers/auth/signup";
import GoToPage from "./containers/auth/GoToPage";
import WelcomePage from "./containers/WelcomePage";
import AppContainer from "./containers/AppContainer";
import LoginPage from "./containers/auth/login/LoginPage";
import SecureRoute from "./components/common/SecureRoute";
import NewPasswordPage from "./containers/auth/NewPasswordPage";
import OnboardingPage from "./containers/onboarding/OnboardingPage";
import ForgotPasswordPage from "./containers/auth/forgot-password/ForgotPasswordPage";

import { AppProvider } from "./contextApi/appContext";
import * as authActions from "./state/actions/AuthActions";
import { ComposerProvider } from "./contextApi/composerContext";
import SetupAccountModal from "components/modals/setupAccountModal";
import OnboardingInfoModal from "components/modals/onboardingInfoModal";
import FeatureAlertModal from "components/modals/pop-ups/FeatureAlertModal";
import FeatureAlertProvider from "components/modals/pop-ups/FeatureAlertProvider";

class App extends Component {
  state = {
    hasError: false,
    errorEventId: null,
    ldContext: null
  };

  componentDidMount() {
    if (
      this.props.hasValidToken &&
      !this.props.location.pathname.startsWith("/goto/")
    ) {
      this.props.renewToken();
    }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    if (process.env.REACT_APP_ENV === "production") {
      Sentry.withScope(scope => {
        scope.setExtras(info);
        const errorEventId = Sentry.captureException(error);
        this.setState({ errorEventId: errorEventId });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.account?.id &&
      prevProps.account?.id !== this.props.account?.id
    ) {
      const { account, currentUser } = this.props;

      const ldContext = {
        kind: "multi",
        user: {
          key: currentUser.id,
          ..._.pick(currentUser, [
            "email",
            "fullName",
            "id",
            "roles",
            "verified"
          ])
        },
        account: {
          key: account.id,
          accountManager: account.accountManagerData?.fullName ?? "None",
          channels: (account.channels ?? []).map(
            c => `${c.service}_${c.serviceType}`
          ),
          users: (account.users ?? []).map(u => u.fullName),
          isTelenetCustomer: !!account.isTelenetCustomer,
          ..._.pick(account, [
            "email",
            "companyName",
            "features",
            "id",
            "industry",
            "onboardingComplete",
            "setupComplete",
            "status"
          ])
        },
        plan: {
          key: account.billing?.plan ?? "None",
          name: account.billing?.plan ?? "None",
          currency: account.billing?.currencyCode ?? "None",
          coachingPlan: account.billing?.willowCoachingPlan ?? "None",
          period: account.billing?.billingPeriodUnit ?? "None",
          status: account.billing?.billingStatus ?? "None",
          billingCountry: account.billingCountry ?? "None"
        }
      };

      if (account.status === "Demo") {
        ldContext.plan = _.mapValues(ldContext.plan, () => "Demo");
      }

      this.setState({
        ldContext
      });
    }
  }

  render() {
    if (this.state.hasError) {
      const handleSentryReport = () => {
        Sentry.showReportDialog({ eventId: this.state.errorEventId });
      };

      return (
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <ErrorPage onErrorReport={handleSentryReport} />
        </ThemeProvider>
      );
    }

    return (
      <LDProvider
        clientSideID={process.env.REACT_APP_LAUNCHDARLY_SDK_ID}
        deferInitialization={true}
        context={this.state.ldContext}
      >
        <AppProvider>
          <ThemeProvider theme={theme}>
            <ToastProvider>
              <FeatureAlertProvider>
                <>
                  <GlobalStyle />
                  <Toaster
                    options={{
                      position: "bottom-center"
                    }}
                  />
                  <OnboardingInfoModal />
                  <FeatureAlertModal />
                  <ComposerProvider>
                    <Switch>
                      <Route path="/login" component={LoginPage} />
                      <Route path="/signup" component={SignUpPage} />
                      <Route
                        path="/forgot-password"
                        component={ForgotPasswordPage}
                      />
                      <Route
                        path="/goto/:action/:nonce/:ref?"
                        component={GoToPage}
                      />
                      <Route path="/logout" component={Logout} />
                      <Route exact path="/quote/:quoteId" component={Quote} />
                      <Route path="/">
                        <SetupAccountModal />
                        <Switch>
                          <SecureRoute path="/" exact component={WelcomePage} />
                          <SecureRoute
                            path="/onboarding"
                            exact
                            component={OnboardingPage}
                          />
                          <SecureRoute
                            path="/accounts/:accountId"
                            component={AppContainer}
                          />
                          <SecureRoute
                            path="/new-password"
                            component={NewPasswordPage}
                          />
                        </Switch>
                      </Route>
                      <Redirect to={"/"} />
                    </Switch>
                  </ComposerProvider>
                </>
              </FeatureAlertProvider>
            </ToastProvider>
          </ThemeProvider>
        </AppProvider>
      </LDProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser,
    isAuthenticated: !!state.auth.currentUser,
    hasValidToken: state.auth.hasValidToken
  };
};

export default withRouter(
  connect(mapStateToProps, {
    renewToken: authActions.renewToken
  })(App)
);

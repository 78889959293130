import { Box } from "rebass";
import { useSelector } from "react-redux";
import { useToaster } from "@hellocontento/maillard";
import React, { useCallback, useEffect, useState } from "react";

import ShowOnRoutes from "hoc/ShowOnRoutes";
import ContentRouter from "./ContentRouter";
import ContentHeader from "./ContentHeader";
import Sidebar from "components/content/sidebar";
import { NewsBody, ContentWrapper } from "./styles";
import { quickSearchByCategory } from "services/news";
import { NewsProvider } from "contextApi/newsContext/useNewsContext";
import { MainContainer, MainWrapper } from "components/common/styles";
import { useLocation } from "react-router-dom";
import ArticleSearchBar from "components/content/articleSearch/ArticleSearchBar";

const ContentPage = React.memo(({ match }) => {
  const addToast = useToaster();
  const account = useSelector(state => state.account.data);
  const location = useLocation();

  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchForSources = useCallback(async () => {
    try {
      const results = await quickSearchByCategory({
        searchTerm: searchValue
      });

      setSearchSuggestions(results);
    } catch (e) {
      addToast("There was an error searching for the sources", "error");
    }
  }, [searchValue, addToast]);

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (searchValue !== "") searchForSources();
      else setSearchSuggestions([]);
    }, 200);
    return () => clearTimeout(searchDelay);
  }, [searchValue, searchForSources]);

  const shouldHideContentHeaderAndSearchBar =
  location.pathname.endsWith("/your-ideas") ||
  location.pathname.endsWith("/whatsapp");

  return (
    <NewsBody>
      <Sidebar match={match} accountId={account.id} />
      <NewsProvider>
        <ContentWrapper>
          {/* Conditionally render the ContentHeader and ArticleSearchBar based on the URL */}
          {!shouldHideContentHeaderAndSearchBar && (
            <>
              <ContentHeader
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                searchSuggestions={searchSuggestions}
              />
              <ShowOnRoutes
                component={ArticleSearchBar}
                allowedRoutes={["/search"]}
              />
            </>
          )}
          <MainContainer>
            <MainWrapper>
              <Box flex={"1"}>
                <ContentRouter />
              </Box>
            </MainWrapper>
          </MainContainer>
        </ContentWrapper>
      </NewsProvider>
    </NewsBody>
  );
});

export default ContentPage;

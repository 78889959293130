export const SERVICE_LIST = {
  facebook_page: "Facebook Page",
  twitter_profile: "Twitter Profile",
  linkedin_business: "LinkedIn Business",
  linkedin_profile: "LinkedIn Profile",
  linkedin_page: "LinkedIn Page",
  instagram_profile: "Instagram Profile",
  instagram_business: "Instagram Business"
};

export const SERVICES = {
  TWITTER: "twitter",
  LINKEDIN: "linkedin",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram"
};

import Divider from "components/common/Divider";
import React from "react";
import { Flex } from "rebass";
import { CollapsableWrapper, CollapsableButton } from "./styles";

interface ICollapsableProps {
  collapsed: boolean;
  onClick: (e: any) => void;
}

const Collapsable: React.FC<ICollapsableProps> = ({ onClick, collapsed }) => {
  return (
    <Flex width={"100%"} flexDirection="column" px={"8px"}>
      {/* @ts-ignore */}
      <Divider mx={-8} my={-10} />
      <CollapsableWrapper collapsed={collapsed}>
        {/* @ts-ignore */}
        <CollapsableButton onClick={onClick} collapsed={collapsed}>
          <span className="icon-block-collapse" />
          {!collapsed && <span className="label">Collapse</span>}
        </CollapsableButton>
      </CollapsableWrapper>
    </Flex>
  );
};

export default Collapsable;

import styled, { css } from "styled-components";
import { Box, Flex } from "rebass/styled-components";

export const WidgetCard = styled(Flex)`
  align-items: stretch;
  background-color: white;
  border-radius: 24px 16px 16px;
  flex-direction: column;
  padding: 28px 16px 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.08);
  height: 468px;
`;

export const WidgetHeader = styled(Box)`
  align-items: center;
  display: flex;
  gap: 12px;
  margin: 8px 0;
`;

export const WidgetIcon = styled(Flex)`
  color: ${props => props.theme.colors.grey03};
  justify-content: flex-end;
`;

export const WidgetTitle = styled.h3`
  color: ${props => props.theme.colors.text01};
  font-family: ${props => props.theme.fonts.headline};
  font-size: 18px;
  font-weight: bold;
`;

export const WidgetBody = styled(Flex)`
  flex-direction: column;
  flex: 1;
  max-height: 348px;
  overflow-y: auto;
  position: relative;
  gap: 4px;

  ${props =>
    props.fullContainer &&
    css`
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    `}
`;

export const PostCardWrapper = styled.div`
  display: flex;
`;

export const WidgetFooter = styled(Flex)`
  justify-content: center;
  margin-top: auto;
  padding-top: 6px;
  position: static;
  bottom: 0;
  box-shadow: 0px -1px ${props => props.theme.colors.grey03};
`;

export const WidgetEmptyContent = styled(Flex)`
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const WidgetEmptyIcon = styled(Box)`
  margin: 24px 0;
  height: 64px;
  width: 64px;

  & img {
    width: 64px;
    height: 64px;
    object-fit: cover;
  }
`;

export const WidgetEmptyTitle = styled.h5`
  color: ${props => props.theme.colors.text02};
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 8px 0;
`;

export const WidgetEmptyDescription = styled.p`
  color: ${props => props.theme.colors.text03};
  font-size: 14px;
  text-align: center;
`;

export const WidgetTopPost = styled(Box)`
  max-width: 240px;
  position: absolute;
  width: 100%;
`;

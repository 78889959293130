import React from "react";
import {
  ErrorMessage,
  HelperText,
  StyledInputWrapper,
  StyledInput,
  StyledInputLabel,
  StyledInputFieldset,
  StyledInputIconWrapper,
  SuccessIndicatorWrapper,
  SuccessIndicator
} from "./styles";

const TextInput = ({
  input,
  label,
  placeholder,
  meta,
  type,
  helperText,
  autofocus = false,
  disabled,
  autocomplete = null,
  mb = 16,
  showSuccessMark = false,
  defaultValue,
  ...props
}) => {
  const { touched, error } = meta;
  const hasError = error && touched;

  return (
    <StyledInputFieldset width={1} mb={mb} {...props}>
      <StyledInputLabel>{label}</StyledInputLabel>
      {helperText && <HelperText>{helperText}</HelperText>}
      <StyledInputWrapper>
        <StyledInput
          type={type}
          hasError={hasError}
          placeholder={placeholder}
          autoFocus={autofocus}
          disabled={disabled}
          autoComplete={autocomplete}
          defaultValue={defaultValue}
          {...input}
        />
        {!hasError && showSuccessMark && touched ? (
          <StyledInputIconWrapper>
            <SuccessIndicatorWrapper>
              <SuccessIndicator size={8} />
            </SuccessIndicatorWrapper>
          </StyledInputIconWrapper>
        ) : null}
      </StyledInputWrapper>
      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </StyledInputFieldset>
  );
};

export default TextInput;

import { connect } from "react-redux";
import { Redirect } from "react-router";
import React, { useEffect } from "react";
import ReactLoading from "react-loading";

import { Loading } from "components/common/styles";
import * as authActions from "state/actions/AuthActions";

const GoToPage = React.memo(
  ({ match, location, loginFail, nonceLogin, currentUser, loginSuccess }) => {
    useEffect(() => {
      nonceLogin(match.params.nonce);
    }, [match.params.nonce, nonceLogin]);

    if (loginFail) {
      //TODO: Respond according to action
      return <Redirect to={"/login"} />;
    }

    if (loginSuccess) {
      let redirect = "/login";
      switch (match.params.action) {
        case "subscribe":
        case "dashboard":
          const accountId =
            match.params.ref ||
            (currentUser.accounts.length ? currentUser.accounts[0].id : null);

          if (accountId) {
            redirect = `/accounts/${accountId}/${match.params.action}${location.search}`;
          }
          break;
        case "reset-password":
          redirect = "/new-password";
          break;
        case "home":
          redirect = "/";
          break;
        default:
          break;
      }
      return <Redirect to={redirect} />;
    }

    return (
      <Loading>
        <ReactLoading color="#bbb" type="spin" />
      </Loading>
    );
  }
);

const mapStateToProps = (state, props) => {
  return {
    loginFail: state.auth.nonceLoginFailed,
    loginSuccess: state.auth.nonceLoginSuccess,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {
  nonceLogin: authActions.nonceLogin
})(GoToPage);

import React from "react";
import styled from "styled-components";

import expert1 from "assets/images/milli-popup/expert1.png";
import expert2 from "assets/images/milli-popup/expert2.png";
import { Body5, Headline2, Headline6 } from "components/common/styles";
import CustomerTestimonials from "components/common/customerTestimonials";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 56px;
  margin: 0 -56px;
  gap: 20px;

  .dot {
    &.selected {
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .carousel-root {
    width: calc(100% + (56px * 2));
  }
`;

const Expert = styled.div<{ bgcolor: string }>`
  position: relative;
  flex: 1;
  max-width: 201px;
  max-height: 201px;
  aspect-ratio: 1;
  border-radius: 8.375px;
  background-color: ${props => props.bgcolor};
  overflow: hidden;

  .expert-whoami {
    position: absolute;
    position: absolute;
    width: 100%;
    height: 27.09px;
    left: 0px;
    top: 7.68px;
    display: flex;
    flex-direction: column;
    align-items: center;

    > ${Headline6} {
      font-weight: 700;
      color: ${props => props.theme.colors.white};
    }

    > ${Body5} {
      color: ${props => props.theme.colors.white};
    }
  }
`;

const ExpertsContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .experts {
    display: flex;
    gap: 7.4px;
    flex-grow: 1;
  }

  > ${Headline2} {
    width: 224.4px;
    text-align: left;
    margin: 0;
  }
`;

const ExpertsAndTestimonials: React.FC<{}> = () => {
  return (
    <Wrapper>
      <ExpertsContainer>
        <Headline2>Experts to make your social media feel alive.</Headline2>
        <div className="experts">
          <Expert bgcolor="#FFBA62">
            <img alt="Expert" height="201px" src={expert1} />
            <div className="expert-whoami">
              <Headline6>Virginie</Headline6>
              <Body5>Social Media Expert BE NL</Body5>
            </div>
          </Expert>
          <Expert bgcolor="#BB521E">
            <img alt="Expert" height="201px" src={expert2} />
            <div className="expert-whoami">
              <Headline6>Laura</Headline6>
              <Body5>Social Media Expert BE NL</Body5>
            </div>
          </Expert>
        </div>
      </ExpertsContainer>
      <CustomerTestimonials />
    </Wrapper>
  );
};

export default ExpertsAndTestimonials;

import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { format, isWeekend } from "date-fns";
import { Droppable } from "react-beautiful-dnd";
import React, { useEffect, useState } from "react";
import { Plus } from "@styled-icons/boxicons-regular";

import {
  GridBody,
  GridItem,
  GridLoading,
  GridItemTop,
  GridAddButton,
  GridContainer,
  GridItemHeader,
  GridItemNumber,
  GridCalendarHeader
} from "./styles";
import MonthGroup from "./MonthGroup";
import Popper from "components/common/Popper";
import { getCalendarDays } from "../utils/dateUtils";
import { useComposerActions } from "contextApi/composerContext";
import { TooltipOption, TooltipMenu, TooltipMenuWrapper } from "../styles";

function MonthItem({ day }) {
  const isDragging = useSelector(state => state.dragDrop.isDragging);
  const { isOutsideMonth, isToday, isPast, entries } = day;
  const [isAddEnabled, setAddEnabled] = useState(false);
  const createTask = useComposerActions(actions => actions.createTask);
  const createPostByDate = useComposerActions(
    actions => actions.createPostByDate
  );

  const handleItemHover = value => {
    if (!isPast) setAddEnabled(value);
  };

  const handleAddPost = () => {
    createPostByDate(day.date);
  };

  const handleAddDraftPost = () => {
    createPostByDate(day.date, true);
  };

  const handleAddSlot = () => {
    createTask(day.date);
  };

  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  const [referenceElement, setReferenceElement] = useState(null);

  return (
    <GridItem
      isToday={isToday}
      onMouseEnter={() => handleItemHover(true)}
      onMouseLeave={() => {
        handleItemHover(false);
        setShowOptions(false);
      }}
    >
      <GridItemTop>
        <GridItemNumber
          isPast={isPast}
          isToday={isToday}
          isOutsideMonth={isOutsideMonth}
          onMouseOver={e => e.stopPropagation()}
        >
          {day.dayOfMonth}
        </GridItemNumber>
        {isAddEnabled && !isDragging && (
          <div ref={setReferenceElement}>
            <GridAddButton isVisible={isAddEnabled} onClick={toggleShowOptions}>
              <Plus size={18} />
            </GridAddButton>
            <Popper
              referenceElement={referenceElement}
              visible={showOptions}
              exceptions={[referenceElement]}
              onClose={() => setShowOptions(false)}
            >
              <TooltipMenu onClick={() => setShowOptions(false)} $radius={16}>
                <TooltipMenuWrapper $minWidth={180}>
                  <TooltipOption onClick={handleAddPost} $variant="primary">
                    <span className="icon-write"></span>
                    Write a post
                  </TooltipOption>
                  <TooltipOption onClick={handleAddDraftPost}>
                    <span className="icon-draft"></span>
                    Write draft
                  </TooltipOption>
                  <TooltipOption onClick={handleAddSlot} $size={18}>
                    <span className="icon-task-circular" />
                    Add task
                  </TooltipOption>
                </TooltipMenuWrapper>
              </TooltipMenu>
            </Popper>
          </div>
        )}
      </GridItemTop>
      <MonthGroup day={day} entries={entries} />
    </GridItem>
  );
}

export default function MonthGrid({ entries, isLoading, currentDate }) {
  const timezone = useSelector(state => state.account.timezone);
  const daysInWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const [days, setDays] = useState(
    getCalendarDays(currentDate, [], { timezone })
  );

  useEffect(() => {
    setDays(getCalendarDays(currentDate, entries, { timezone }));
  }, [currentDate, entries, timezone]);

  const layout = (() => {
    let gridLayout = `repeat(7, calc((100% - 24px)/7))`;

    const weekends = {
      Saturday: 0,
      Sunday: 0
    };

    days.forEach(day => {
      if (isWeekend(day.date)) {
        const entries = Object.values(day.entries).flat();
        weekends[format(day.date, "EEEE")] += entries.length;
      }
    });

    if (!weekends.Saturday > 0 && weekends.Sunday > 0) {
      gridLayout = `repeat(5, calc((100% - 24px - 80px)/6)) 80px repeat(1, calc((100% - 24px - 80px)/6))`;
    } else if (weekends.Saturday > 0 && !weekends.Sunday > 0) {
      gridLayout = `repeat(6, calc((100% - 24px - 80px)/6)) 80px`;
    } else if (!weekends.Saturday > 0 && !weekends.Sunday > 0) {
      gridLayout = `repeat(5, calc((100% - 24px - 160px)/5)) 80px 80px`;
    }

    return gridLayout;
  })();

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "rgba(96, 116, 128, 0.08)" : "white"
  });

  return (
    <GridContainer>
      {isLoading && (
        <GridLoading>
          <ReactLoading color="#bbb" type="spin" height={64} width={64} />
        </GridLoading>
      )}
      <GridCalendarHeader layout={layout}>
        {daysInWeek.map(day => (
          <GridItemHeader key={day}>{day}</GridItemHeader>
        ))}
      </GridCalendarHeader>
      <GridBody layout={layout}>
        {days.map(day => (
          <Droppable
            key={day.key}
            droppableId={format(day.date, "yyyy-MM-dd")}
            isDropDisabled={day.isPast}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <MonthItem day={day} />
                <div style={{ height: 0, width: 0 }}>
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        ))}
      </GridBody>
    </GridContainer>
  );
}

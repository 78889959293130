import styled from "styled-components";
import { Flex, Box } from "rebass/styled-components";
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from "react";

import {
  useNewsDispatch,
  resetStateAction,
  setContentAction
} from "contextApi/newsContext";
import {
  ContentTopicImage,
  ContentSectionWrapper
} from "components/content/styles";
import SourceDetail from "../SourceDetail";
import useOnScreen from "hooks/useOnScreen";
import SubscribeButton from "../SubscribeButton";
import { Headline1 } from "components/common/styles";
import { SECTION_TYPES, SOURCE_TYPE } from "services/news.types";
import { follow, unfollow, followExternalSources } from "services/news";

const Title = styled(Headline1)`
  color: ${props => props.theme.colors.white};
`;

interface IContentDetailBannerProps {
  pageData: any;
  sourceId: string;
  sourceType: string;
}

const InfluencerDetailBanner: React.FC<IContentDetailBannerProps> = React.memo(
  ({ pageData, sourceId, sourceType }) => {
    const [isProcessing, setIsProcessing] = useState(false);

    const ref = useRef();
    const isVisible = useOnScreen(ref);
    const dispatch = useNewsDispatch();

    const [isLocalFollowed, setIsLocalFollowed] = useState(
      pageData.source.isFollowed
    );

    const contextSource = useMemo(
      () =>
        sourceType === SECTION_TYPES.DOMAINS
          ? sourceType
          : SECTION_TYPES.SOURCES,
      [sourceType]
    );

    useEffect(() => {
      if (!isVisible) {
        dispatch(
          setContentAction({
            id: pageData.source.id,
            type: sourceType,
            title: pageData.source.title,
            isFollowing: isLocalFollowed
          })
        );
      } else {
        dispatch(resetStateAction());
      }
    }, [
      dispatch,
      isVisible,
      sourceType,
      isLocalFollowed,
      pageData.source.id,
      pageData.source.title
    ]);

    const addTwitterSource = useCallback(async (source: any) => {
      const twitterSubscribeData = {
        type: SOURCE_TYPE.TWITTER,
        handle: source.handle
      };

      try {
        setIsProcessing(true);
        await followExternalSources(twitterSubscribeData);
        setIsLocalFollowed(true);
        setIsProcessing(false);
      } catch (_) {
        setIsProcessing(false);
      }
    }, []);

    const addSource = useCallback(
      async (source: any) => {
        try {
          setIsProcessing(true);
          await follow(contextSource, source.id);
          setIsLocalFollowed(true);
          setIsProcessing(false);
        } catch (_) {
          setIsProcessing(false);
        }
      },
      [contextSource]
    );

    const deleteSource = useCallback(
      async (id: string) => {
        try {
          setIsProcessing(true);
          await unfollow(contextSource, id);
          setIsLocalFollowed(false);
          setIsProcessing(false);
        } catch (_) {
          setIsProcessing(false);
        }
      },
      [contextSource]
    );

    const followLabel = useMemo(() => {
      if (pageData) {
        switch (pageData.source.type) {
          case SOURCE_TYPE.TWITTER:
            return "Follow Influencer";
          case SOURCE_TYPE.RSS:
            return "Follow";
          default:
            return "Follow";
        }
      }
    }, [pageData]);

    return (
      <ContentSectionWrapper mb={40}>
        <Flex flexDirection="column" alignItems="center" margin={"auto"}>
          <ContentTopicImage
            ref={ref}
            src={pageData.source.image}
            // @ts-ignore
            borderRadius="50%"
          />
          <Title sx={{ textTransform: "capitalize", textAlign: "center" }}>
            {pageData.source.title}
          </Title>
          {pageData?.source ? <SourceDetail source={pageData.source} /> : null}

          <Box mt={32}>
            <SubscribeButton
              id={pageData.source.id}
              isProcessing={isProcessing}
              type={SECTION_TYPES.SOURCES}
              followLabel={followLabel}
              color={pageData.source.color}
              onFollow={() => {
                pageData.source.type === SOURCE_TYPE.TWITTER
                  ? addTwitterSource(pageData.source)
                  : addSource(pageData.source);
              }}
              onUnfollow={() => deleteSource(sourceId)}
            />
          </Box>
        </Flex>
      </ContentSectionWrapper>
    );
  }
);

export default InfluencerDetailBanner;

import { colors } from "theme";

export enum Status {
  APPROVED = "approved",
  DRAFT = "draft"
}

export interface IStatusProperties {
  icon: string;
  fontColor: string;
  background: string;
  description?: string;
}

export const STATUS_PROPERTIES: { [key in Status]: IStatusProperties } = {
  approved: {
    icon: "icon-published",
    fontColor: colors.green,
    background: colors.greenSubtle
  },
  draft: {
    icon: "icon-task-circle",
    fontColor: colors.orange,
    background: colors.orangeSubtle,
    description: "The post will not be published until it has been approved."
  }
};

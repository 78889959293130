import Dropzone from "react-dropzone";
import { DropZoneContainer, UploadArea } from "./styles";

import "react-sweet-progress/lib/style.css";
import React, { createRef, useEffect, useState } from "react";

export default function UploadZone({
  isUploading,
  accept = "image/*",
  onDrop,
  openFileModalByDefault = false,
  onCancel,
  type = "image"
}) {
  const dropzoneRef = createRef();
  const [modalOpenFlag, setModalOpenFlag] = useState(false);

  useEffect(() => {
    if (!modalOpenFlag && dropzoneRef.current && openFileModalByDefault) {
      dropzoneRef.current.open();
      setModalOpenFlag(true);
    }
  }, [dropzoneRef, modalOpenFlag, openFileModalByDefault]);

  return (
    <Dropzone
      ref={dropzoneRef}
      accept={accept}
      onDrop={onDrop}
      onFileDialogCancel={onCancel}
      multiple={type === "video" || type === "pdf" ? false : true}
    >
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
      }) => {
        return (
          <DropZoneContainer
            {...getRootProps({ isDragAccept, isDragReject })}
            $isDragActive={isDragActive}
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
            height="120px"
            width="120px"
            minHeight="120px"
            type={type}
            disabled={isUploading}
          >
            <input {...getInputProps()} disabled={isUploading} />

            {
              <UploadArea>
                <i className="icon-plus-rounded nohover" />
                <i className="icon-plus-rounded-filled hover" />
                <div className="content">
                  <em>Upload {type}</em>
                  <span>or drag & drop</span>
                </div>
              </UploadArea>
            }
          </DropZoneContainer>
        );
      }}
    </Dropzone>
  );
}

// @ts-nocheck
import React from "react";

import { MenuItem } from "./styles";
import { MenuList, MenuContainer } from "components/navigation/Menu";

interface ICalendarEventMenu {
  writePost: (e: React.MouseEvent) => void;
  writeDraft: (e: React.MouseEvent) => void;
}

const CalendarEventMenu: React.FC<ICalendarEventMenu> = ({
  writePost,
  writeDraft
}) => {
  return (
    <MenuContainer width={240}>
      <MenuList>
        <MenuItem
          icon="write"
          color="green"
          label="Write post"
          onClick={writePost}
        />
        <MenuItem label="Write draft" icon="draft" onClick={writeDraft} />
      </MenuList>
    </MenuContainer>
  );
};

export default CalendarEventMenu;

import React from "react";
import styled from "styled-components";

const DescriptionInput = styled.textarea`
  background-color: rgba(255, 176, 32, 0.13);
  border-radius: 16px;
  border: none;
  box-shadow: none;
  color: ${props => props.theme.colors.darkYellow};
  font-family: ${props => props.theme.fonts.body};
  height: 100px;
  outline: none;
  padding: 20px 16px 24px;
  resize: none;

  &::placeholder {
    color: ${props => props.theme.colors.yellow};
  }
`;

function TaskDescriptionInput({ ...restProps }) {
  return (
    <DescriptionInput placeholder={"Write a description"} {...restProps} />
  );
}

export default TaskDescriptionInput;

import styled from "styled-components";

export const ChannelGrid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(175px, 2fr));
  grid-gap: 8px;
`;

export const ChannelSelectorContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const AllChannelsToggle = styled.div<{ inProMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.inProMode ? "flex-start" : "flex-end")};
  margin-bottom: 4px;
  gap: 8px;

  .label {
    color: ${props => props.theme.colors.text02};
  }
`;

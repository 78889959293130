import React from "react";

import {
  TooltipTitle,
  StatListItem,
  TooltipContent,
  TooltipContainer
} from "../styles";
import { formatForDashboard } from "utils/date";
import { ICustomTooltip } from "../ExternalTooltipHandler";

const Tooltip: React.FC<ICustomTooltip> = ({ data }) => {
  const { date, variation } = data;

  return (
    <TooltipContainer>
      <TooltipTitle>{formatForDashboard(new Date(date))}</TooltipTitle>
      <TooltipContent>
        <StatListItem bgcolor="transparent">
          <div className="title">Impressions</div>
          <div className="stats">{variation ?? 0}</div>
        </StatListItem>
      </TooltipContent>
    </TooltipContainer>
  );
};

export default Tooltip;

import PropTypes from "prop-types";
import React, { useState } from "react";

import iconDone from "assets/images/done.svg";
import { ChannelWrapper, Avatar, ChannelIcon, ChannelUsername } from "./styles";

const Channel = ({ checked, channel, handleChannelToggle }) => {
  const { id, service, avatar, username } = channel;
  const [isImageBroken, setIsImageBroken] = useState(false);

  const onImageError = () => {
    setIsImageBroken(true);
  };

  return (
    <ChannelWrapper checked={checked} onClick={() => handleChannelToggle(id)}>
      <Avatar>
        {!!avatar && !isImageBroken && (
          <img src={avatar} alt={username} onError={onImageError} />
        )}
      </Avatar>
      <ChannelIcon channel={service} />
      <ChannelUsername checked={checked}>
        <span>{username}</span>
        <img src={iconDone} alt={service} />
      </ChannelUsername>
    </ChannelWrapper>
  );
};

Channel.propTypes = {
  checked: PropTypes.bool.isRequired,
  channel: PropTypes.object.isRequired,
  handleChannelToggle: PropTypes.func.isRequired
};

export default Channel;

import React from "react";
import { connect, useSelector } from "react-redux";

import {
  SettingsTitle,
  SettingsHeader,
  SettingsSection,
  SettingsDescription
} from "./styles";
import { connectChannel } from "utils/channels";
import { NativeLink } from "components/common/Link";
import { EnclosedContent } from "components/common/styles";
import ConnectSocialProfiles from "components/channelConnect/ChannelConnectionList";


const SocialMediaChannels: React.FC = ()=> {
  const account = useSelector((state: any) => state.account.data);

  const handleConnectChannel = service => {
    connectChannel(account, service);
  };

  return (
    <EnclosedContent>
      <SettingsHeader>
        <SettingsTitle>Social Media channels</SettingsTitle>
        <SettingsDescription>
          Looking for step-by-step instructions on how to connect your social
          media channels?
          <br />
          Visit our{" "}
          <NativeLink
            $underline={true}
            href="https://support.willow.co/knowledge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Help Center</strong>
          </NativeLink>{" "}
          or talk to us through the chat.
        </SettingsDescription>
      </SettingsHeader>
      <SettingsSection>
        <ConnectSocialProfiles
          account={account}
          onConnect={handleConnectChannel}
        />
      </SettingsSection>
    </EnclosedContent>
  );
}


export default SocialMediaChannels;

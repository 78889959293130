import React from "react";

import {
  BlogPageWrapper,
  BlogMainColumnWrapper,
  BlogSideColumnWrapper
} from "components/blog/styles";
import BlogMain from "components/blog/BlogMain";
import BlogSide from "components/blog/BlogSide";

const BlogPage = React.memo(() => {
  return (
    <BlogPageWrapper>
      <BlogSideColumnWrapper>
        <BlogSide />
      </BlogSideColumnWrapper>
      <BlogMainColumnWrapper>
        <BlogMain />
      </BlogMainColumnWrapper>
    </BlogPageWrapper>
  );
});

export default BlogPage;

import React, { useCallback, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {
  Page,
  PageName,
  PageHeader,
  SearchInput,
  ConnectionList,
  ConnectionListWrapper
} from "./styles";
import { Headline4 } from "components/common/styles";
import Checkbox from "components/common/form/Checkbox";
import { ChannelAvatar, Avatar } from "components/common/styles";
import DefaultAvatar from "assets/images/dashboard/avatar-empty-state.png";

interface IFacebookPage {
  id: string;
  name: string;
  picture: {
    data: {
      url: string;
      width: number;
      height: number;
      is_silhouette: boolean;
    };
  };
  access_token: string;
  profile_picture_url: string;
}

interface IFacebookPageRow {
  page: IFacebookPage;
  selected: boolean;
  onToggle: VoidFunction;
}

const FacebookPageRow: React.FC<IFacebookPageRow> = ({
  page,
  onToggle,
  selected
}) => {
  return (
    <Page checked={selected}>
      <PageHeader>
        <ChannelAvatar
          key={page.id}
          mr={0}
          $enabled={true}
          $clickable={false}
        >
          <Avatar
            src={
              page.profile_picture_url
                ? page.profile_picture_url
                : DefaultAvatar
            }
            
            $isRounded={true}
            width={40}
            height={40}
            $noShadow
            onError={e => {
              e.currentTarget.onerror = null;
              e.currentTarget.src = DefaultAvatar;
            }}
          />
        </ChannelAvatar>
        <PageName>
          <label htmlFor={`checkbox-${page.id}`}>{page.name}</label>
        </PageName>
      </PageHeader>
      <Checkbox
        name={`checkbox-${page.id}`}
        checked={selected}
        onChange={onToggle}
      />
    </Page>
  );
};

interface IFacebookPageList {
  pages: IFacebookPage[];
  selectedPageIds: string[];
  setSelectedPageIds: (ids: string[]) => void;
}

const FacebookPageList: React.FC<RouteComponentProps & IFacebookPageList> = ({
  pages,
  selectedPageIds,
  setSelectedPageIds
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = useCallback(e => {
    setSearchTerm(e.currentTarget.value);
  }, []);

  const handleToggle = useCallback(
    pageId => {
      let newSelectedPages: string[] = [];
      if (selectedPageIds.includes(pageId)) {
        newSelectedPages = selectedPageIds.filter(
          selectedPageId => selectedPageId !== pageId
        );
      } else {
        newSelectedPages = selectedPageIds.concat([pageId]);
      }
      setSelectedPageIds(newSelectedPages);
    },
    [selectedPageIds]
  );

  return (
    <ConnectionListWrapper>
      {pages.length < 1 ? (
        <Headline4>Facebook pages not available for the profile</Headline4>
      ) : (
        <>
          <SearchInput
            type="text"
            value={searchTerm}
            placeholder="Search accounts"
            onChange={handleSearchChange}
          />
          <ConnectionList>
            {pages
              .filter(page =>
                page.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
              .map(page => (
                <FacebookPageRow
                  key={page.id}
                  page={page}
                  selected={selectedPageIds.includes(page.id)}
                  onToggle={() => handleToggle(page.id)}
                />
              ))}
          </ConnectionList>
        </>
      )}
    </ConnectionListWrapper>
  );
};

export default withRouter(FacebookPageList);

import { Flex } from "rebass/styled-components";
import styled, { css } from "styled-components";

import { IStatusProperties } from "./constants";
import IconButton from "components/common/IconButton";

export const StatusDropdown = styled.div`
  display: flex;
  width: 100%;
`;

interface IDropdownContainer {
  highlighted: boolean;
}

export const DropdownContainer = styled(Flex)<IDropdownContainer>`
  background-color: ${props =>
    props.highlighted ? props.theme.colors.grey02 : "transparent"};
  border-radius: 48px;
  flex-direction: row;
  gap: 7px;
  padding: 4px 10px;

  .chevron {
    visibility: hidden;
  }

  &:hover {
    background-color: ${props => props.theme.colors.grey02};
    cursor: pointer;

    .chevron {
      visibility: visible;
    }
  }
`;

export const DropdownToggleButton = styled(IconButton)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
`;

interface IStatusBadgeWrapper {
  fontColor: string;
  background: string;
}

export const StatusBadgeWrapper = styled.div<IStatusBadgeWrapper>`
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px;
  padding: 4px 12px 4px 4px;
  background: ${props => props.background};
  border: 1px solid ${props => props.theme.colors.grey01};

  i {
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: ${props => props.fontColor};
  }

  span {
    font-weight: 600;
    line-height: 15.6px;
    text-transform: capitalize;
    color: ${props => props.fontColor};
  }
`;

interface IMenuEntry extends Partial<IStatusProperties> {
  selected: boolean;
}

export const MenuEntry = styled.div<IMenuEntry>`
  padding: 8px;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  align-self: stretch;
  justify-content: space-between;

  ${props =>
    props.selected &&
    css`
      background: ${props => props.theme.colors.grey01};
    `}

  &:hover {
    background: ${props => props.theme.colors.primary};

    .status > i,
    .status > .label > .title {
      color: ${props => props.theme.colors.white};
    }

    .status > .label > .description {
      color: ${props => props.theme.colors.darkText03};
    }

    > i {
      color: ${props => props.theme.colors.white};
    }
  }

  .status {
    gap: 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    i {
      width: 20px;
      height: 20px;
      font-size: 20px;
      color: ${props => props.fontColor};
    }

    .label {
      gap: 4px;
      display: flex;
      max-width: 191px;
      flex-direction: column;

      .title {
        line-height: 17.5px;
        text-transform: capitalize;
        color: ${props => props.theme.colors.text01};
        font-weight: ${props => (props.selected ? 600 : 500)};
      }

      .description {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        color: ${props => props.theme.colors.text03};
      }
    }
  }

  i {
    font-size: 20px;
  }
`;

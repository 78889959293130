import React from "react";

import {
  WidgetTitle,
  WidgetStatsLabel,
  WidgetHeaderContent,
  AnalyticsWidgetHeader,
  AnalyticsContentWrapper,
  AnalyticsWidgetContainer
} from "../styles";
import { colors } from "theme";
import Loader from "components/common/loading/Loader";
import EmployeeAvatarBlock from "./EmployeeAvatarBlock";
import { useDashboardState } from "contextApi/dashboardContext";

const tipUrl =
  "https://www.willow.co/course-content/how-to-leverage-your-employees-social-network";

const EmployeeAnalytics: React.FC<{}> = () => {
  const stats = useDashboardState(state => state.socialHealth.data);
  const isFetching = useDashboardState(state => state.socialHealth.isFetching);
  const statsLabel = (() => {
    if (
      !stats?.employeeAdvocacy ||
      Number.isNaN(
        stats.employeeAdvocacy.score || stats.employeeAdvocacy.score < 0
      )
    ) {
      return "No data";
    } else if (stats.employeeAdvocacy.score < 30) {
      return "Bad";
    } else if (stats.employeeAdvocacy.score >= 70) {
      return "Good";
    } else {
      return "Average";
    }
  })();

  const handleTipClick = () => {
    window.open(tipUrl, "_blank");
  };

  const isScoreLessthanHalf = stats?.employeeAdvocacy?.score < 50;

  return (
    <AnalyticsWidgetContainer colspan={2} rowspan={1} bgColor={colors.white}>
      <AnalyticsWidgetHeader>
        <WidgetTitle>Employee advocacy</WidgetTitle>
        {!isFetching && (
          <WidgetHeaderContent>
            <WidgetStatsLabel>{statsLabel}</WidgetStatsLabel>
            {!!stats?.employeeAdvocacy?.score && isScoreLessthanHalf && (
              <WidgetStatsLabel variant="highlight" onClick={handleTipClick}>
                <i className="icon-sparkle" />
                Tip
              </WidgetStatsLabel>
            )}
          </WidgetHeaderContent>
        )}
      </AnalyticsWidgetHeader>
      <AnalyticsContentWrapper className="employee-advocacy">
        {isFetching ? (
          <Loader size={36} />
        ) : (
          (stats?.employeeAdvocacy?.data ?? [])
            .sort((a: any, b: any) => b.score - a.score)
            .map((item: any, index: number) => (
              <EmployeeAvatarBlock
                key={item.id}
                employeeDetails={{ ...item, rank: index + 1 }}
              />
            ))
        )}
      </AnalyticsContentWrapper>
    </AnalyticsWidgetContainer>
  );
};

export default EmployeeAnalytics;

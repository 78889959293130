import React, { useState, useEffect } from "react";
import { callApi } from "../../../utils/ContentoApi";
import { useToaster } from "@hellocontento/maillard";
import {
  TextInput,
  Chip,
  RemoveChip,
  TextInputContainer,
  Chips
} from "./styles";
import ReactLoading from "react-loading";
import { Text } from "rebass/styled-components";
import { Check, X } from "styled-icons/feather";
import { EnclosedContent } from "../styles";

const ChipWidget = ({
  children,
  accountId,
  path,
  fieldName,
  prefix = "",
  allowSpaces = true
}) => {
  const [chips, setAllChips] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const addToast = useToaster();

  useEffect(() => {
    callApi({
      method: "get",
      url: `/accounts/${accountId}/${path}`
    })
      .then(chips => setAllChips(chips))
      .catch(err => console.error(err));
  }, [accountId, path]);

  const removeChip = async chipId => {
    //Don't wait for server response to remove
    let remainingChips;
    if (Number.isInteger(chipId)) {
      remainingChips = chips.slice(0, chipId).concat(chips.slice(chipId + 1));
    } else {
      remainingChips = chips.filter(chip => chip.id !== chipId);
    }
    setAllChips(remainingChips);
    return callApi({
      method: "delete",
      url: `/accounts/${accountId}/${path}/${chipId}`
    });
  };

  const submitValue = () => {
    if (inputValue.trim() === "") {
      return;
    }

    callApi({
      method: "post",
      url: `/accounts/${accountId}/${path}`,
      data: { [fieldName]: inputValue.trim() }
    })
      .then(value => {
        Array.isArray(value)
          ? setAllChips(value)
          : setAllChips(chips.concat([value]));
      })
      .catch(err => {
        console.error(err);
        addToast(
          "There was an error adding this. Please reload the page and try again.",
          "error"
        );
      });

    setInputValue("");
  };

  const handleKeyDown = async event => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    submitValue();
  };

  return (
    <EnclosedContent>
      {children}
      <TextInputContainer>
        <TextInput
          type="text"
          value={inputValue}
          onChange={e =>
            setInputValue(allowSpaces ? e.target.value : e.target.value.trim())
          }
          onKeyDown={handleKeyDown}
          placeholder={`Type a ${fieldName} and press enter`}
        />
      </TextInputContainer>
      <ChipSet chips={chips} prefix={prefix} removeChip={removeChip} />
    </EnclosedContent>
  );
};

function ChipComponent({ chip, prefix, index, handleRemoveChip }) {
  const [onHover, isOnHover] = useState(false);

  return (
    <Chip
      onMouseOver={() => isOnHover(true)}
      onMouseOut={() => isOnHover(false)}
    >
      <Text mr={4} lineHeight="24px" fontSize="16px" fontWeight="bold">
        {prefix}
        {chip.tag ? chip.tag : chip}
      </Text>

      <RemoveChip
        alignItems="center"
        onClick={() => handleRemoveChip(chip.id ? chip.id : index)}
      >
        {!onHover && <Check size={12} strokeWidth={5} color="#ffffff" />}
        {onHover && <X size={12} strokeWidth={5} color="#ffffff" />}
      </RemoveChip>
    </Chip>
  );
}

function ChipSet({ chips, removeChip, prefix = "" }) {
  const addToast = useToaster();

  const handleRemoveChip = async chipId => {
    try {
      removeChip(chipId);
    } catch (error) {
      addToast(
        "There was an error removing this tag or keyword. Please try again later.",
        "error"
      );
      console.error(error);
    }
  };

  if (chips === false) {
    return <ReactLoading color="#bbb" type="cylon" />;
  }

  return (
    <Chips>
      {chips.map((chip, index) => (
        <ChipComponent
          key={chip.id ? chip.id : chip + index}
          chip={chip}
          index={index}
          prefix={prefix}
          handleRemoveChip={handleRemoveChip}
        />
      ))}
    </Chips>
  );
}

export default ChipWidget;

import React from "react";
import { useHistory } from "react-router-dom";

const ShowOnRoutes = ({
  component: Component,
  allowedRoutes,
  ...rest
}: any) => {
  const history = useHistory();

  let flag = false;

  for (let index = 0; index < allowedRoutes.length; index++) {
    flag = flag || history.location.pathname.includes(allowedRoutes[index]);
  }

  if (!flag) return null;

  return <Component {...rest} />;
};
export default ShowOnRoutes;

import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const SidebarNavigationLayout = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.lg}) {
    flex-direction: column;
  }
`;

export const NavigationLayout = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.lg}) {
    display: none; /* Remove when making the menu responsive */
    flex-direction: row;
  }
`;

export const NavItem = styled(NavLink)`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  min-height: 40px;
  padding: 10px 14px;
  align-items: center;
  border-radius: 10px;
  color: ${props => props.theme.colors.text01};

  * {
    box-sizing: border-box;
  }

  > i {
    font-size: 20px;
    margin-right: 12px;
    color: ${props => props.theme.colors.text02};
  }

  > span {
    font-size: 14px;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: -0.005em;
  }

  &.active {
    color: ${props => props.theme.colors.text01};
    background-color: ${props => props.theme.colors.grey01};

    > i {
      color: ${props => props.theme.colors.text01};
    }

    > span {
      font-weight: 600;
    }
  }

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.blue};

    > i {
      color: ${props => props.theme.colors.white};
    }
  }
`;

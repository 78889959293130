import React from "react";
import {
  MiniCal,
  MiniCalNav,
  MiniCalMonth,
  MiniCalDayGrid,
  MiniCalDayName,
  MiniCalDay,
  MiniCalIndicators,
  IndicatorDot
} from "./styles";
import {
  getOffsetDate,
  getFormattedDate,
  getCalendarDays,
  getDayInWords,
  getTheWeekADateBelongsTo,
  getWeekOfTheLastXDayOfAMonth,
  getNextDayOfTheWeek,
  isDayBefore
} from "./utils/dateUtils";
import IconButton from "components/common/IconButton";
import {
  isAfter,
  addDays,
  isBefore,
  isToday,
  differenceInDays,
  set
} from "date-fns";

import { theme } from "theme";
import { difference, startOfDay, today } from "utils/date";
import { REPETITION_TYPE } from "../../constants/repetitions";

function TimelineDatePicker({
  account,
  previewDate,
  focusDate,
  feedEntries = [],
  onDateChange,
  onDatePreview,
  disablePastDate = false,
  disableFutureDate = false,
  ...restProps
}) {
  const monthString = getFormattedDate(previewDate, "MMMM yyyy");
  const days = getCalendarDays(previewDate, feedEntries, {
    timezone: account.timezone
  });
  const formatDate = date => getFormattedDate(date, "EEEE, d MMMM");
  const daysInWeek = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"];

  const BIWEEKLY_CYCLE_DAYS = 14;

  const isDatePast = date => {
    return difference(startOfDay(date), startOfDay(today()), "days") < 0;
  };

  const isDateFuture = date => {
    return difference(startOfDay(date), startOfDay(today()), "days") > 0;
  };

  const handleDateClick = date => {
    if (disablePastDate) {
      if (isDatePast(date)) return;
    }
    if (disableFutureDate) {
      if (isDateFuture(date)) return;
    }

    onDateChange(date);
  };

  const handleMonthShift = val => {
    let date = getOffsetDate(previewDate, { months: val });
    onDatePreview(date);
  };

  const nthDayOfTheMonth = (date, nth, day) => {
    let defaultNth = nth;

    if (nth >= 5) defaultNth = getWeekOfTheLastXDayOfAMonth(date, day);

    const week = getTheWeekADateBelongsTo(date);

    return week === defaultNth && getDayInWords(date) === day;
  };

  // Finds the nearest future dates of the slected days
  // When the day of focusDate is ahead of some selectedDays, set the reference date to 7 days ahead
  let biWeeklyRepetitionRefDates = [];
  if (restProps.repetitionMode === REPETITION_TYPE.BIWEEKLY) {
    restProps.weeklyRange.forEach(dayName => {
      const dateOfDay = getNextDayOfTheWeek(
        dayName,
        false,
        new Date(+focusDate)
      );

      if (isDayBefore(dayName, focusDate)) {
        biWeeklyRepetitionRefDates.push(addDays(dateOfDay, 7));
      } else {
        biWeeklyRepetitionRefDates.push(dateOfDay);
      }
    });
  }

  const shouldHighlight = day => {
    if (
      !restProps.weeklyRange ||
      day.isPast ||
      (restProps.dateEnd && !isBefore(day.date, restProps.dateEnd)) ||
      (!isAfter(day.date, focusDate) && !isToday(day.date))
    ) {
      return false;
    }

    switch (restProps.repetitionMode) {
      case REPETITION_TYPE.WEEKLY:
        return restProps.weeklyRange.includes(day.dayOfWeek.toUpperCase());
      case REPETITION_TYPE.BIWEEKLY: {
        let isBiWeekly = false;
        for (let i = 0; i < biWeeklyRepetitionRefDates.length; i++) {
          const selectedDate = set(biWeeklyRepetitionRefDates[i], {
            hours: 0,
            minutes: 0
          });
          const diff = differenceInDays(day.date, selectedDate);

          if (diff % BIWEEKLY_CYCLE_DAYS === 0) {
            isBiWeekly = true;
            break;
          }
        }
        return isBiWeekly;
      }
      case REPETITION_TYPE.MONTHLY:
        if (restProps.weeklyRange.length > 0) {
          return (
            restProps.weeklyRange.includes(day.dayOfWeek.toUpperCase()) &&
            nthDayOfTheMonth(
              day.date,
              restProps.nthWeek,
              restProps.weeklyRange[0]
            )
          );
        } else {
          return focusDate.getDate() === day.date.getDate();
        }
      case REPETITION_TYPE.YEARLY:
        return (
          focusDate.getDate() === day.date.getDate() &&
          focusDate.getMonth() === day.date.getMonth()
        );
      default:
        return false;
    }
  };

  return (
    <MiniCal {...restProps}>
      <MiniCalNav>
        <IconButton
          variant={"secondary"}
          icon={"icon-arrowleft"}
          onClick={() => handleMonthShift(-1)}
          iconSize={20}
          iconColor={theme.colors.text01}
          size={28}
        />
        <MiniCalMonth>{monthString}</MiniCalMonth>
        <IconButton
          variant={"secondary"}
          icon={"icon-arrowright"}
          onClick={() => handleMonthShift(1)}
          iconSize={20}
          iconColor={theme.colors.text01}
          size={28}
        />
      </MiniCalNav>
      <MiniCalDayGrid size={restProps.size ?? "md"}>
        {daysInWeek.map(day => (
          <MiniCalDayName key={day}>{day}</MiniCalDayName>
        ))}
        {days.map(day => (
          <MiniCalDay
            key={day.key}
            isOutside={day.isOutsideMonth}
            isToday={day.isToday}
            isFocus={formatDate(day.date) === formatDate(focusDate)}
            isHighlighted={shouldHighlight(day)}
            isPast={isDatePast(day.date)}
            isFuture={disableFutureDate && isDateFuture(day.date)}
            onClick={() => handleDateClick(day.date)}
          >
            <span>{day.dayOfMonth}</span>
            <MiniCalIndicators>
              {Object.entries(
                Object.entries(day.entries).reduce((acc, [entry, values]) => {
                  if (entry === "grouped" || entry === "scheduled") {
                    const stuck = values.filter(entry => entry.post?.isStuck);
                    const unstuck = values.filter(
                      entry => !entry.post?.isStuck
                    );
                    if (unstuck.length > 0) {
                      acc.scheduled = [...(acc.scheduled ?? []), ...unstuck];
                    }
                    if (stuck.length > 0) {
                      acc.stuck = [...(acc.stuck ?? []), ...stuck];
                    }
                  } else {
                    acc[entry] = [...(acc[entry] ?? []), ...values];
                  }
                  return acc;
                }, {})
              ).map(([entry, values]) => {
                return (
                  values.length > 0 && <IndicatorDot key={entry} type={entry} />
                );
              })}
            </MiniCalIndicators>
          </MiniCalDay>
        ))}
      </MiniCalDayGrid>
    </MiniCal>
  );
}

export default TimelineDatePicker;

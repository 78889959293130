import React from "react";
import { Flex } from "rebass";
import styled from "styled-components";

import Button from "components/common/Button";
import Divider from "components/common/Divider";
import IconButton from "components/common/IconButton";

import {
  StyledInputWrapper,
  StyledInputLabel,
  StyledInputFieldset
} from "components/common/form/styles";

const Title = styled(Flex)`
  padding: 5px 20px;
  color: ${props => props.theme.colors.text01};
  flex-direction: row;
  gap: 4px;
  align-items: center;
  font-weight: 600;
`;

const TimePicker = styled.input`
  margin-top: 10px;
  border: 1px solid
    ${props =>
      props.hasError ? props.theme.colors.danger : props.theme.colors.grey02};
  border-radius: 8px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 12px;
  line-height: 140%;
  font-family: ${props => props.theme.fonts.body};
  font-size: 14px;
  transition: border-color 0.2s ease-in-out 0s;

  &::placeholder {
    color: ${props => props.theme.colors.text04};
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.grey01};
    color: ${props => props.theme.colors.text02};
  }
  a {
    top: 56%;
    transform: translateY(-50%);
  }
`;

const CustomTime = ({ toggle, save, close }) => {
  const [time, setTime] = React.useState("");

  const onChange = e => {
    e.stopPropagation();
    setTime(e.target.value);
  };

  const handleSubmit = () => {
    if (!time) return;

    save({ time, relevance: null, custom: true });
    close();
  };

  return (
    <>
      <Title>
        <IconButton
          variant={"secondary"}
          size={"sm"}
          onClick={e => {
            e.stopPropagation();
            toggle();
          }}
          icon={"icon-return"}
        />
        Custom Time
      </Title>
      <Divider my={1} />
      <StyledInputFieldset width={1} py={10} px={20}>
        <StyledInputLabel>Time</StyledInputLabel>
        <StyledInputWrapper>
          <TimePicker
            type="time"
            onClick={e => e.stopPropagation()}
            onChange={onChange}
          />
        </StyledInputWrapper>
        <Button
          mt={16}
          width={"100%"}
          variant="primary"
          size="m"
          onClick={handleSubmit}
        >
          Save Time
        </Button>
      </StyledInputFieldset>
    </>
  );
};

export default CustomTime;

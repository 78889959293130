import React from "react";

import {
  TitleLoader,
  AvatarLoader,
  LoaderWrapper,
  LoaderContent,
  SubtitleLoader
} from "./styles";

const DropdownProfileEntryLoader = () => {
  return (
    <LoaderWrapper>
      <AvatarLoader />
      <LoaderContent>
        <TitleLoader />
        <SubtitleLoader />
      </LoaderContent>
    </LoaderWrapper>
  );
};

export default DropdownProfileEntryLoader;

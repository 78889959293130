import qs from "qs";
import React, { useEffect } from "react";
import ReactLoading from "react-loading";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useToaster } from "@hellocontento/maillard";

import { callApi } from "utils/ContentoApi";
import { useAppState } from "contextApi/appContext";
import * as modalActions from "state/actions/ModalActions";
import * as accountActions from "state/actions/AccountActions";
import { getChannelConnectedRedirectPath } from "utils/channels";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

/**
 * On this page the user will select the specific facebook pages to connect to his contento account
 */
const ConnectInstagramBusinessPage = React.memo(
  ({ account, fbToken, history, redirect, openModal, fetchAccount }) => {
    const addToast = useToaster();
    const allowedChannels = useAppState(
      state => state.clientSettings.allowedChannels
    );
    const dispatch = useDispatch();

    useEffect(() => {
      callApi({
        url: `/accounts/${account.id}/connect/instagram/businesses?token=${fbToken}`
      })
        .then(async pages => {
          const connectPages = async selectedPages => {
            try {
              const availableChannelSlots = Math.max(
                allowedChannels - account.channels.length,
                0
              );
              const allowedPages = selectedPages.slice(
                0,
                availableChannelSlots
              );
              const pageLocation = history.location.pathname.includes(
                "onboarding"
              )
                ? "onboarding"
                : "settings";
              await Promise.all(
                selectedPages.map(async page => {
                  const result = await createChannel(page);
                  dispatch(
                    trackAnalyticsEvent("Channel Connected", {
                      channelType: "instagram_business",
                      location: pageLocation
                    })
                  );
                  return result;
                })
              );
              //fetch account with new channels
              fetchAccount(account.id);
              history.push(getChannelConnectedRedirectPath(account, redirect));
            } catch (error) {
              addToast(
                "Something unexpected went wrong. Please refresh the page and try again.",
                "error"
              );
            }
          };

          const createChannel = async page => {
            const channelData = {
              service: "instagram",
              serviceType: "business",
              username: page.username,
              avatar: page.profile_picture_url,
              connection: {
                connectionType: "instagram_business",
                connectionDetails: {
                  instagramUserId: page.id,
                  accessToken: fbToken //instagram needs the user token instead of the page token
                }
              }
            };
            return callApi({
              method: "post",
              url: `/accounts/${account.id}/channels`,
              data: channelData
            });
          };

          if (pages.length === 1) {
            await connectPages(pages);
          } else {
            openModal("SELECT_INSTAGRAM_BUSINESS_MODAL", {
              pages,
              connectPages
            });
          }
        })
        .catch(() => {
          addToast(
            "Something went wrong while trying to fetch your instagram businesses. Please try again or contact support.",
            "error"
          );
        });
    }, [
      account.id,
      addToast,
      fbToken,
      openModal,
      fetchAccount,
      history,
      account,
      redirect,
      dispatch
    ]);

    return <ReactLoading color={"#bbb"} type={"cylon"} />;
  }
);

const mapStateToProps = (state, props) => {
  const queryParams = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  });
  return {
    account: state.account.data,
    fbToken: queryParams.token,
    redirect: queryParams.redirect
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchAccount: accountActions.fetchAccount,
    openModal: modalActions.openModal
  })(ConnectInstagramBusinessPage)
);

import React from "react";

import { Option, OptionLabel } from "./styles";
import { Headline6 } from "components/common/styles";

interface SchedulerOptionProp {
  onClick: (e: any) => void;
  isActive?: boolean;
  iconClassName?: string;
  description;
  label: string;
};

const SchedulerOption = ({
  label,
  onClick,
  description,
  iconClassName,
  isActive = false
}: SchedulerOptionProp) => {
  return (
    <Option $isActive={isActive} onClick={onClick}>
      <OptionLabel>
        {iconClassName && <i className={iconClassName} />}
        <div className="label">
          <Headline6>{label}</Headline6>
          {description && <div className="description">{description}</div>}
        </div>
      </OptionLabel>
      {isActive && <i className="icon-check" />}
    </Option>
  );
};

export default SchedulerOption;

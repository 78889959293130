/**
 * Returns the string with the first letter capitalized
 *
 * @param {string} str
 *
 * @returns {string}
 */
export const capitalize = ([firstLetter, ...rest]: string) => {
  return [firstLetter.toUpperCase(), ...rest].join("");
};

/**
 * Returns the base64 string for the provided string
 *
 * @param {string} data
 *
 * @returns {string}
 */
export const base64Encode = (data: string) => {
  return btoa(data);
};

/**
 * Returns the decoded string for the provided encode string
 *
 * @param {string} data
 *
 * @returns {string} Decoded string
 */
export const base64Decode = (data: string) => {
  return atob(data);
};

import _ from "lodash";
import React from "react";
import { Question } from "@styled-icons/bootstrap/Question";

import {
  Th,
  Td,
  Table,
  TableContainer,
  PostText,
  PostLinkIcon,
  PostTextContainer,
  NA
} from "../styles";
import { ReshareButton } from "./styles";
import Tooltip from "components/common/Tooltip";
import { getExternalPostUrl } from "utils/channels";
import { useComposerActions } from "contextApi/composerContext";
import { ChannelAvatar, Avatar, Service } from "components/common/styles";
import defaultAvatar from "assets/images/dashboard/avatar-empty-state.png";
import { IPostWithMetrics } from "@hellocontento/contento-common";

interface SortConfig {
  key: string;
  direction: "ascending" | "descending";
}

interface PostsToRepurposeTableProps {
  data: IPostWithMetrics[];
  sortConfig: SortConfig | null;
  sortData: (key: string) => void;
}

const PostsToRepurposeTable: React.FC<PostsToRepurposeTableProps> = ({
  data,
  sortConfig,
  sortData
}) => {
  const openPublishedPostInComposer = useComposerActions(
    actions => actions.openPublishedPostInComposer
  );

  const renderAttachment = (attachment: any) => {
    if (!attachment) return <NA>N/A</NA>;

    const attachmentType =
      typeof attachment === "string" ? attachment : attachment.type;

    switch (attachmentType) {
      case "photo":
        return <i className="icon-image" />;
      case "video":
        return <i className="icon-video" />;
      case "pdf":
        return <i className="icon-pdf" />;
      case "article":
        return <i className="icon-task" />;
      default:
        return <Question size={20} color="#646769" />;
    }
  };

  const formatDate = (dateString: string) => {
    if (!dateString) return <NA>N/A</NA>;
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      weekday: "short",
      day: "2-digit",
      month: "short",
      year: "numeric"
    }).format(date);
  };

  const resharePost = (post: any) => {
    openPublishedPostInComposer(post);
  };

  return (
    <TableContainer>
      <Table>
        <thead>
          <tr>
            <Th
              isSorted={false}
              direction="ascending"
              showArrow={false}
              index={0}
            >
              Account
            </Th>
            <Th
              isSorted={false}
              direction="ascending"
              showArrow={false}
              index={1}
            >
              Post
            </Th>
            <Th
              isSorted={false}
              direction="ascending"
              showArrow={false}
              index={2}
            >
              Attachments
            </Th>
            <Th
              isSorted={sortConfig?.key === "postedAt"}
              direction={
                sortConfig?.key === "postedAt"
                  ? sortConfig.direction
                  : "ascending"
              }
              onClick={() => sortData("postedAt")}
              showArrow={true}
              index={3}
            >
              Posted At
            </Th>
            <Th
              isSorted={sortConfig?.key === "impressions"}
              direction={
                sortConfig?.key === "impressions"
                  ? sortConfig.direction
                  : "ascending"
              }
              onClick={() => sortData("impressions")}
              showArrow={true}
              index={4}
            >
              Views
            </Th>
            <Th
              isSorted={sortConfig?.key === "engagements"}
              direction={
                sortConfig?.key === "engagements"
                  ? sortConfig.direction
                  : "ascending"
              }
              onClick={() => sortData("engagements")}
              showArrow={true}
              index={5}
            >
              Total Engagement
            </Th>
            <Th
              isSorted={sortConfig?.key === "engagementRate"}
              direction={
                sortConfig?.key === "engagementRate"
                  ? sortConfig.direction
                  : "ascending"
              }
              onClick={() => sortData("engagementRate")}
              showArrow={true}
              index={6}
            >
              Engagement Rate
            </Th>
            <Th
              isSorted={sortConfig?.key === "lastEngagedWith"}
              direction={
                sortConfig?.key === "lastEngagedWith"
                  ? sortConfig.direction
                  : "ascending"
              }
              onClick={() => sortData("lastEngagedWith")}
              showArrow={true}
              index={7}
            >
              Last Engaged With
            </Th>
            <Th
              isSorted={sortConfig?.key === "actions"}
              direction={
                sortConfig?.key === "actions"
                  ? sortConfig.direction
                  : "ascending"
              }
              showArrow={false}
              index={8}
            >
              Actions
            </Th>
          </tr>
        </thead>
        <tbody>
          {data.map((post, index) => (
            <tr key={index}>
              <Td isSorted={false} index={0}>
                <ChannelAvatar
                  key={post.channel.id}
                  mr={0}
                  style={{ opacity: 1, cursor: "default" }}
                >
                  <Avatar
                    src={post.channel.avatar ?? defaultAvatar}
                    width={28}
                    height={28}
                    style={{
                      boxShadow: "none",
                      filter: "none",
                      opacity: 1,
                      border: "1px solid transparent"
                    }}
                    onError={event => {
                      (event.target as HTMLImageElement).src = defaultAvatar;
                      (event.target as HTMLImageElement).onerror = null;
                    }}
                  />
                  <Service
                    $size={16}
                    key={post.channel.id}
                    title={post.channel.username}
                    $type={post.channel.service}
                  />
                </ChannelAvatar>
              </Td>
              <Td isSorted={false} index={1} textAlign="left">
                <PostTextContainer>
                  <Tooltip title="Open Original Post" placement="bottom">
                    <PostLinkIcon
                      onClick={() =>
                        window.open(getExternalPostUrl(post) ?? "", "_blank")
                      }
                    />
                  </Tooltip>
                  <PostText>{post.caption || <NA>N/A</NA>}</PostText>
                </PostTextContainer>
              </Td>
              <Td isSorted={false} index={2}>
                {renderAttachment(post.attachment)}
              </Td>
              <Td isSorted={sortConfig?.key === "postedAt"} index={3}>
                {formatDate(post.postedAt)}
              </Td>
              <Td isSorted={sortConfig?.key === "impressions"} index={4}>
                {post.stats.raw.impressions ?? <NA>N/A</NA>}
              </Td>
              <Td isSorted={sortConfig?.key === "engagements"} index={5}>
                {post.stats.calculated.engagements ?? <NA>N/A</NA>}
              </Td>
              <Td isSorted={sortConfig?.key === "engagementRate"} index={6}>
                {post.stats.calculated.engagementRate != null ? (
                  `${post.stats.calculated.engagementRate}%`
                ) : (
                  <NA>N/A</NA>
                )}
              </Td>
              <Td isSorted={sortConfig?.key === "lastEngagedWith"} index={7}>
                {post.stats.calculated.lastEngagementAt != null ? (
                  formatDate(`${post.stats.calculated.lastEngagementAt}`)
                ) : (
                  <NA>N/A</NA>
                )}
              </Td>
              <Td isSorted={false} index={8}>
                <ReshareButton onClick={() => resharePost(post)}>
                  Reshare Post
                </ReshareButton>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default PostsToRepurposeTable;

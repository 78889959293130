import styled, { css } from "styled-components";
import { Box } from "rebass";

export const InfluenersWrapper = styled(Box)<{ isFirst?: boolean }>`
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  flex: 1;

  ${props =>
    props.isFirst &&
    css`
      &::before {
        content: "";
        background-color: ${props => props.theme.colors.grey01};
        display: block;
        width: 100%;
        height: 420px;
        position: absolute;
        left: 0;
        z-index: -1;
      }
    `}
`;
InfluenersWrapper.defaultProps = {
  as: "section"
};

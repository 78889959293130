import React from "react";

import { CaptionBubble, GenerateButton, UseButton } from "../styles";

class CaptionTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.tooltip = React.createRef();
  }

  render() {
    return (
      <CaptionBubble
        ref={this.tooltip}
        contentEditable={false}
        onClick={e => e.stopPropagation()}
      >
        <GenerateButton onClick={this.props.onGenerate}>
          Generate New
        </GenerateButton>
        <UseButton onClick={this.props.onClose}>Use Caption</UseButton>
      </CaptionBubble>
    );
  }
}

export default CaptionTooltip;

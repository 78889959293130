import { Dispatch } from "react";

import { IActions } from "./actionCreators";
import {
  RESET_STATE,
  UPDATE_STATS,
  SET_FILTER_RANGE,
  UPDATE_ACTIVITIES,
  UPDATE_SOCIAL_HEALTH,
  UPDATE_STATS_FETCHING_STATE,
  UPDATE_ACTIVITIES_FETCHING_STATE,
  UPDATE_SOCIAL_HEALTH_FETCHING_STATE
} from "./actions";

export interface IFilterRange {
  label: string;
  periodInDays: number | string;
}

export const FILTER_RANGE_OPTIONS: IFilterRange[] = [
  {
    label: "1 month",
    periodInDays: 30
  },
  {
    label: "3 months",
    periodInDays: 90
  },
  {
    label: "6 months",
    periodInDays: 180
  },
  {
    label: "1 year",
    periodInDays: 365
  }
];

export interface IActivities {
  scheduled: any[];
  published: any[];
  todos: any[];
}

export type ISocialHealth = any; // TODO: define interface of social health or import from backend

export type IStats = any; // TODO: define interface of stats or import from backend

export interface IDashboardState {
  filterRange: IFilterRange;
  activities: {
    entries: IActivities;
    isFetching: boolean;
  };
  socialHealth: {
    data: null | any;
    isFetching: boolean;
  };
  stats: {
    data: null | any;
    isFetching: boolean;
  };
}

export type IDashboardDispatch = Dispatch<IActions>;

export const initialValues: IDashboardState = {
  filterRange:
    FILTER_RANGE_OPTIONS.find(range => range.periodInDays === 90) ||
    FILTER_RANGE_OPTIONS[0],
  activities: {
    entries: {
      scheduled: [],
      published: [],
      todos: []
    },
    isFetching: false
  },
  socialHealth: {
    data: null,
    isFetching: false
  },
  stats: {
    data: null,
    isFetching: false
  }
};

export const dashboardReducer = (
  state: IDashboardState = initialValues,
  action: IActions
): IDashboardState => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...initialValues
      };

    case SET_FILTER_RANGE: {
      return {
        ...state,
        filterRange: action.payload
      };
    }

    case UPDATE_ACTIVITIES: {
      return {
        ...state,
        activities: {
          ...state.activities,
          entries: action.payload
        }
      };
    }

    case UPDATE_ACTIVITIES_FETCHING_STATE: {
      return {
        ...state,
        activities: {
          ...state.activities,
          isFetching: action.payload
        }
      };
    }

    case UPDATE_SOCIAL_HEALTH: {
      return {
        ...state,
        socialHealth: {
          ...state.socialHealth,
          data: action.payload
        }
      };
    }

    case UPDATE_SOCIAL_HEALTH_FETCHING_STATE: {
      return {
        ...state,
        socialHealth: {
          ...state.socialHealth,
          isFetching: action.payload
        }
      };
    }

    case UPDATE_STATS: {
      return {
        ...state,
        stats: {
          ...state.stats,
          data: action.payload
        }
      };
    }

    case UPDATE_STATS_FETCHING_STATE: {
      return {
        ...state,
        stats: {
          ...state.stats,
          isFetching: action.payload
        }
      };
    }

    default: {
      throw new Error("Unhandled action type");
    }
  }
};

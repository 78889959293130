import React from "react";
import { useRouteMatch } from "react-router-dom";

import { NavItem, NavigationLayout } from "./styles";

export type menuItem = {
  to: string;
  icon: string;
  label: string;
  activeIcon: string;
};

interface IMenuItem extends Omit<menuItem, "to"> {
  url: string;
}

const MenuItem: React.FC<IMenuItem> = ({ url, icon, label, activeIcon }) => {
  const route = useRouteMatch(url);

  return (
    <>
      <i className={!!route && route.isExact ? activeIcon : icon} />
      <span>{label}</span>
    </>
  );
};

interface ISidebarNavigation {
  currentUrl: string;
  menuItems: menuItem[];
}

const SidebarNavigation: React.FC<ISidebarNavigation> = ({
  menuItems,
  currentUrl
}) => {
  return (
    <NavigationLayout>
      {menuItems.map(({ to, icon, label, activeIcon }) => (
        <NavItem
          key={`${label}-${to}`}
          activeClassName="active"
          to={`${currentUrl}${to}`}
        >
          <MenuItem
            icon={icon}
            label={label}
            activeIcon={activeIcon}
            url={`${currentUrl}${to}`}
          />
        </NavItem>
      ))}
    </NavigationLayout>
  );
};

export default SidebarNavigation;

import store from "state/store";
import { callApi } from "utils/ContentoApi";
import { ISavedPost } from "utils/useSavedPosts";

const parseSavedPost = (savedPost): ISavedPost => {
  const { createdAt, updatedAt, ...rest } = savedPost;

  return {
    ...rest,
    id: rest.id ?? rest.urn,
    createdAt: new Date(createdAt),
    updatedAt: new Date(updatedAt)
  };
};

export const fetchSavedPosts = async (): Promise<ISavedPost[]> => {
  const account = store.getState().account.data;

  try {
    const savedPosts: ISavedPost[] = await callApi({
      method: "get",
      url: `/accounts/${account.id}/willow-extension/posts/list`
    });

    return savedPosts.map(sp => parseSavedPost(sp));
  } catch (error) {
    throw new Error(`Saved posts could not be fetched.`);
  }
};

export const deleteSavedPost = async (urn: string): Promise<void> => {
  const account = store.getState().account.data;
  const currentUser = store.getState().auth.currentUser;

  try {
    await callApi({
      method: "delete",
      url: `/users/${currentUser.id}/accounts/${account.id}/willow-extension/posts/delete`,
      data: {
        urn
      }
    });
  } catch (error) {
    throw new Error(`Post could not be deleted.`);
  }
};

import React from "react";
import { useSelector } from "react-redux";

import { CreateButton, CalendarAddOptionsWrapper } from "./styles";
import OutsideClickDetector from "components/common/OutsideClickDetector";
import NewActivityOptions from "components/schedule/common/NewActivityOptions";

const ColumnContextMenu = ({
  day,
  isVisible,
  showContentMenu,
  toggleShowContextMenu
}) => {
  const { isDragging } = useSelector(state => state.dragDrop);

  if (!isVisible) return null;

  return (
    <>
      {showContentMenu && (
        <CalendarAddOptionsWrapper>
          <OutsideClickDetector onClose={toggleShowContextMenu}>
            <NewActivityOptions
              float={true}
              date={day.date.toDate()}
              close={toggleShowContextMenu}
            />
          </OutsideClickDetector>
        </CalendarAddOptionsWrapper>
      )}
      {!isDragging && (
        <CreateButton
          size="lg"
          variant="secondary"
          onClick={() => {
            toggleShowContextMenu();
          }}
        >
          <span className="icon-plus"></span>
          Add New
        </CreateButton>
      )}
    </>
  );
};

export default ColumnContextMenu;

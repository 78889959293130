export const statsAggregator = res => {
  if (res.length === 0) return null;

  const metrics = ["engagements", "impressions", "followers"];
  let agg = {};

  for (let metric of metrics) {
    let total = null;
    let period = null;
    let previous = null;
    let previousTotal = null;
    let points = [];

    let sortedChannels = res.sort(
      (a, b) =>
        b.stats.engagements.points.length - a.stats.engagements.points.length
    );

    for (let channel of sortedChannels) {
      total += channel.stats[metric].total;
      previousTotal += channel.stats[metric].previousTotal;
      previous += channel.stats[metric].previous;
      period += channel.stats[metric].period;

      if (
        channel.service === "linkedin" &&
        channel.serviceType === "profile" &&
        metric === "followers"
      )
        continue;
      else {
        let reversed = channel.stats[metric].points.reverse();

        for (let i = 0; i < reversed.length; i++) {
          let total = null;
          let variation = null;
          let date;

          if (points[i]) {
            total = points[i].total;
            variation = points[i].variation;
          }

          date = reversed[i].date;
          total += reversed[i].total;
          variation += reversed[i].variation;

          points[i] = { date, total, variation };
        }
      }
    }

    agg[metric] = {
      total,
      period,
      previous,
      previousTotal,
      points: points.reverse()
    };
  }

  return agg;
};

function calculateMetricRate(period, previous) {
  let rate = 0.0;

  if (previous == null || period == null) {
    // not enough data to calculate the rate
    rate = null;
  } else if (period && !previous) {
    // when the last period value is 0 and the current one is not
    // set 100% increase to avoid variations like +30000000%
    rate = 100;
  } else {
    const diff = period - previous;
    if (diff !== 0) {
      // absolute variation
      const variation = (Math.abs(diff) / (previous || 1)) * 100;
      // adds the sign
      rate = diff >= 0 ? variation : -variation;
    }
  }

  return rate;
}

export const calculateGrowthRate = stats => {
  let summary = {};

  for (let [key, value] of Object.entries(stats)) {
    if (["engagementRate"].includes(key)) continue;

    // followers and posts are calculated based on the totals as their period / previous
    // values could be negative or null and mess up the calculations
    const rate =
      key === "followers" || key === "posts"
        ? calculateMetricRate(value.total, value.previousTotal)
        : calculateMetricRate(value.period, value.previous);

    summary[key] = rate !== null ? rate.toFixed(2) : null;

    if (Number.isNaN(rate)) {
      console.log(
        JSON.stringify({
          key,
          value: { ...value, points: "hidden" },
          rate
        })
      );
    }
  }

  return summary;
};

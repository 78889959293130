import React from "react";
import { useSelector } from "react-redux";
import { Box, Flex } from "rebass/styled-components";

import Button from "components/common/Button";
import ErrorContent from "components/errors/ErrorContent";
import logo from "assets/images/willow-text-color@2x.png";
import ErrorIllustration from "components/errors/ErrorIllustration";
import { ErrorLayout, ErrorHeader } from "components/errors/styles";

const defaultError = {
  title: "Oops!",
  description:
    "Something went wrong there. Please help us improve Willow by reporting this error."
};

const ErrorPage = React.memo(
  ({
    onErrorReport,
    title = defaultError.title,
    description = defaultError.description
  }) => {
    const account = useSelector(state => state.account.data);

    const handleGoBack = () => {
      window.location.href = `/accounts/${account.id}/dashboard`;
    };

    return (
      <ErrorLayout>
        <ErrorHeader>
          <img src={logo} height={40} alt="Willow" />
        </ErrorHeader>
        <Box>
          <ErrorContent title={title} description={description} />
          <Flex flexDirection="row" justifyContent="center" mt={16}>
            <Button
              onClick={handleGoBack}
              size="lg"
              variant="secondary"
              mr={12}
            >
              Go back
            </Button>
            <Button onClick={onErrorReport} size="lg">
              Report error
            </Button>
          </Flex>
        </Box>
        <ErrorIllustration />
      </ErrorLayout>
    );
  }
);

export default ErrorPage;

import { connect } from "react-redux";
import React, { useState } from "react";
import styled from "styled-components";

import { Flex } from "rebass";

import Button from "components/common/Button";

import { ISource, IDomain } from "services/news";
import Loader from "components/common/loading/Loader";

const ButtonContent = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const SourceButton = styled(Button)<{ checked?: boolean; hovered?: boolean }>`
  align-items: center;
  appearance: none;
  background: ${props =>
    props.checked
      ? props.hovered
        ? props.theme.colors.red
        : props.theme.colors.blue
      : props.hovered
        ? props.theme.colors.grey04
        : props.theme.colors.gre02};
  border: none;
  box-shadow: none;
  border-radius: 24px;
  cursor: pointer;
`;

interface ISubscribeButton {
  data: any;
  type: string;
  sections: Array<any>;
  onUnfollowSource: (data: ISource | IDomain) => void;
  onFollowSource: (data: ISource | IDomain) => void;
}

const SourceSubscribeButton: React.FC<ISubscribeButton> = ({
  data,
  sections,
  onUnfollowSource,
  onFollowSource
}) => {
  const [isToggleHovered, setToggleHovered] = useState<boolean>(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const sources =
    sections.find((section: any) => section.type === "sources")?.details ?? [];

  const isFollowed = !!sources.find(
    (source: any) =>
      source.id === data.id ||
      (source.type === "twitter" && source.handle === data.handle)
  );

  const onSubscription = async () => {
    setIsProcessing(true);
    try {
      if (isFollowed) {
        await onUnfollowSource(data);
      } else {
        await onFollowSource(data);
      }
      setIsProcessing(false);
    } catch (_) {
      setIsProcessing(false);
    }
  };

  return (
    <Flex mt={"auto"} flexDirection={"column"} width={"100%"}>
      {/* @ts-ignore */}
      <SourceButton
        onMouseEnter={() => setToggleHovered(true)}
        onMouseLeave={() => setToggleHovered(false)}
        onClick={() => {
          if (!isProcessing) onSubscription();
        }}
        variant={
          !isFollowed ? "secondary" : isToggleHovered ? "dangerLite" : "primary"
        }
      >
        <ButtonContent>
          {isProcessing ? (
            <Loader size={16} />
          ) : (
            <span
              className={
                !isFollowed
                  ? "icon-plus"
                  : isToggleHovered
                    ? "icon-cancel"
                    : "icon-check"
              }
            />
          )}
          {!isFollowed ? "Follow" : isToggleHovered ? "Unfollow" : "Following"}
        </ButtonContent>
      </SourceButton>
    </Flex>
  );
};

const mapStateToProps = (state: any) => {
  return {
    sections: state.content.sections
  };
};

export default connect(mapStateToProps, {})(SourceSubscribeButton);

import moment from "moment";
import * as fns from "date-fns";

const DEFAULT_DATE_FORMAT = "LLL d, yyyy";

export const formatDate = (date, displayFormat = DEFAULT_DATE_FORMAT) => {
  return fns.format(new Date(date * 1000), displayFormat);
};

/**
 * Returns the datetime for the provided unix timestamp
 *
 * @param {number} date
 * @returns {Date}
 */
export const fromUnixTime = date => {
  return fns.fromUnixTime(date);
};

export const num2time = num => {
  var h,
    m = "00";
  if (num < 100) h = num;
  else {
    h = Math.floor(num / 100);
    m = ("0" + (num % 100)).slice(-2);
  }
  h = h % 24;
  return ("0" + h).slice(-2) + ":" + m;
};

/**
 * Returns the current datetime
 *
 * @returns {Date}
 */
export function today() {
  return new Date();
}

/**
 * Returns the datetime for 24hrs in the future
 *
 * @returns {Date}
 */
export function tomorrow() {
  let tomorrow = new Date();

  tomorrow.setDate(today().getDate() + 1);

  return tomorrow;
}

/**
 * Returns the datetime for nearest Monday with the current time
 *
 * @returns {Date}
 */
export function getNearestMonday() {
  const date = new Date();
  date.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7 || 7));

  return date;
}

/**
 * Returns the datetime for 48hrs in the future
 *
 * @returns {Date}
 */
export function dayAfterTomorrow() {
  return fns.add(today(), { days: 2 });
}

/**
 * Returns the day of the week for the provided date
 *
 * @param {Date} date
 * @returns {string}
 */
export function getDayOfWeek(date) {
  return fns.format(date, "EEEE");
}

/**
 * Returns the provided date in the following format:
 * - Nov 30, 2021
 *
 * @param {Date} date
 * @returns {string}
 */
export function formatToFormalDate(date) {
  return fns.format(date, "MMM dd, yyyy");
}

/**
 * Returns the date, month and year in number for the provided date
 *
 * @param {Date} value
 * @returns {{date: number, month: number, year: number}}
 */
export function getDate(value) {
  const date = fns.getDate(value);
  const month = fns.getMonth(value);
  const year = fns.getYear(value);

  return { date, month, year };
}

/**
 * Returns a string for the date to add as a label/key
 * Format: 2022/02/22
 *
 * @param {Date} value
 * @returns {string}
 */
export function getDateLabel(value) {
  let date = fns.getDate(value).toString();
  date = date.length === 1 ? `0${date}` : date;

  let month = (fns.getMonth(value) + 1).toString();
  month = month.length === 1 ? `0${month}` : month;

  const year = fns.getYear(value);

  return `${year}/${month}/${date}`;
}

/**
 * Returns the hours, minutes and seconds in number for the provided date
 *
 * @param {Date} date
 * @returns {{hours: number, minutes: number, seconds: number}}
 */
export function getTime(date) {
  const hours = fns.getHours(date);
  const minutes = fns.getMinutes(date);
  const seconds = fns.getSeconds(date);

  return { hours, minutes, seconds };
}

/**
 * Checks if the provided date is Yesterday's date or not
 *
 * @param {Date} date
 * @returns {boolean}
 */
export function isYesterday(date) {
  return fns.isYesterday(date);
}

/**
 * Checks if the provided date is Today's date or not
 *
 * @param {Date} date
 * @returns {boolean}
 */
export function isToday(date) {
  return fns.isToday(date);
}

/**
 * Checks if the provided date is Tomorrows's date or not
 *
 * @param {Date} date
 * @returns {boolean}
 */
export function isTomorrow(date) {
  return fns.isTomorrow(date);
}

/**
 * Returns a new date after modifying the provided date with the provided options
 *
 * @param {Date} date
 * @param {object} option
 * @param {number} [option.year]
 * @param {number} [option.month]
 * @param {number} [option.date]
 * @param {number} [option.hours]
 * @param {number} [option.minutes]
 * @param {number} [option.seconds]
 * @param {number} [option.milliseconds]
 * @returns {Date}
 */
export function setDate(date, option) {
  return fns.set(date, option);
}

/**
 * Returns the provided date in either the following formats:
 * - Today at 12:15
 * - Tomorrow at 17:12
 * - Nov 30 at 09:45
 *
 * @param {Date} date
 * @returns {string}
 */
export function formatForScheduler(date) {
  const day = isToday(date)
    ? "Today"
    : isTomorrow(date)
      ? "Tomorrow"
      : fns.format(date, "MMM dd");

  return `${day} at ${fns.format(date, "HH:mm")}`;
}

/**
 * Returns the provided date in either the following formats:
 * - November
 * - Today (if it is current date)
 *
 * @param {Date} date
 * @returns {string}
 */
export function getMonthForChart(date) {
  return isToday(date) ? "Today" : fns.format(date, "MMM d");
}

/**
 * Returns a number between 0 and 45 for generating random minutes.
 *
 * @returns {number}
 */
export function getRandomMinutes() {
  const MIN_MINUTES = 0;
  const MAX_MINUTES = 45;

  return Math.floor(Math.random() * MAX_MINUTES + MIN_MINUTES);
}

/**
 * Returns the provided date without the timezone information
 *
 * @param {Date} date
 * @returns {string}
 */
export function removeTimezoneFromDate(date) {
  return fns.format(date, "yyyy-MM-dd HH:mm:ss");
}

/**
 * Returns the start date of the month that the provided date is a part of
 *
 * @param {Date} date
 * @returns {Date}
 */
export function startOfMonth(date) {
  return fns.startOfMonth(date);
}

/**
 * Returns the start of the day for the given date
 *
 * @param {Date} date
 * @returns {Date}
 */
export function startOfDay(date) {
  return fns.startOfDay(date);
}

/**
 * Returns the start of the interval type for the given date
 *
 * @param {Date} date
 * @param {'month'|'week'|'day'} intervalType
 * @returns {Date}
 */
export function startOf(date, intervalType, options = {}) {
  switch (intervalType) {
    case "month":
      return fns.startOfMonth(date);

    case "week":
      return fns.startOfWeek(date, options);

    default:
      return fns.startOfDay(date);
  }
}

/**
 * Returns the end date of the month that the provided date is a part of
 *
 * @param {Date} date
 * @returns {Date}
 */
export function endOfMonth(date) {
  return fns.endOfMonth(date);
}

/**
 * Returns the end of the interval type for the given date
 *
 * @param {Date} date
 * @param {'month'|'week'|'day'} intervalType
 * @returns {Date}
 */
export function endOf(date, intervalType, options = {}) {
  switch (intervalType) {
    case "month":
      return fns.endOfMonth(date);

    case "week":
      return fns.endOfWeek(date, options);

    default:
      return fns.endOfDay(date);
  }
}

/**
 * Returns the week number for hte provided date
 *
 * @param {string} date
 * @returns {Number}
 */

export function getWeekNumber(date) {
  return fns.getWeek(date);
}

/**
 * Returns the provided date in either the following formats:
 * - Yesterday
 * - Today
 * - Tomorrow
 * - Friday, 18th February (when shortDate is false) / Feb 18 (when shortDate is true)
 *
 * @param {Date} date
 * @param {boolean} shortDate
 * @returns {string}
 */
export function formatForTimeline(date, shortDate = false) {
  if (isYesterday(date)) {
    return "Yesterday";
  } else if (isToday(date)) {
    return "Today";
  } else if (isTomorrow(date)) {
    return "Tomorrow";
  } else {
    return shortDate
      ? fns.format(date, "MMM d")
      : fns.format(date, "EEEE, do MMMM");
  }
}

/**
 * Returns the provided date in the following format:
 * - Mar 14 (when form is short)
 * - Tue, March 14 (when form is normal)
 * - Tue, Mar 14 2022 (when form is extended)
 *
 * @param {Date} date
 * @param {'short'|'normal'|'extended'} form
 *
 * @returns {string}
 */
export function formatForDashboard(date, form = "normal") {
  if (form === "short") {
    return fns.format(date, "MMM d");
  } else if (form === "normal") {
    return fns.format(date, "eee, MMMM dd");
  } else {
    return fns.format(date, "eee, MMM dd YYYY");
  }
}

/**
 * Returns the provided date in the following format:
 * - 14:45:08
 *
 * @param {Date} date
 * @returns {string}
 */
export function formatTime(date) {
  return fns.format(date, "kk:mm:ss");
}

/**
 * Returns the provided date in the provided format
 *
 * @param {Date} date
 * @param {string} format
 * @returns {string}
 */
export function customFormat(date, format) {
  return fns.format(date, format);
}

/**
 * Returns a date object for the provided date string
 *
 * @param {string} strDate
 * @returns {Date}
 */
export function parseStringToISO(strDate) {
  return fns.parseISO(strDate);
}

/**
 * Returns a date object for the provided date string of a given format
 *
 * @param {string} strDate
 * @param {string} format
 * @returns {Date}
 */
export function parseStringToDate(strDate, format) {
  return fns.parse(strDate, format, new Date());
}

/**
 * Returns a new date with the duration added
 *
 * @param {Date} date
 * @param {Object} duration
 * @param {number} [duration.years]
 * @param {number} [duration.months]
 * @param {number} [duration.weeks]
 * @param {number} [duration.days]
 * @param {number} [duration.hours]
 * @param {number} [duration.minutes]
 * @param {number} [duration.seconds]
 * @returns {Date}
 */
export function addDuration(date, duration) {
  return fns.add(date, duration);
}

/**
 * Returns a new date with the duration subtracted
 *
 * @param {Date} date
 * @param {Object} duration
 * @param {number} [duration.years]
 * @param {number} [duration.months]
 * @param {number} [duration.weeks]
 * @param {number} [duration.days]
 * @param {number} [duration.hours]
 * @param {number} [duration.minutes]
 * @param {number} [duration.seconds]
 * @returns {Date}
 */
export function subtractDuration(date, duration) {
  return fns.sub(date, duration);
}

/**
 * Returns the differnce in days between the provided dates
 *
 * @param {Date} primaryDate
 * @param {Date} secondaryDate
 * @param {'months'|'weeks'|'days'|'hours'|'minutes'|'seconds'} differenceIn
 * @returns {number}
 */
export function difference(primaryDate, secondaryDate, differenceIn) {
  switch (differenceIn) {
    case "months":
      return fns.differenceInMonths(primaryDate, secondaryDate);

    case "weeks":
      return fns.differenceInWeeks(primaryDate, secondaryDate);

    case "days":
      return fns.differenceInDays(primaryDate, secondaryDate);

    case "hours":
      return fns.differenceInHours(primaryDate, secondaryDate);

    case "minutes":
      return fns.differenceInMinutes(primaryDate, secondaryDate);

    case "seconds":
    default:
      return fns.differenceInSeconds(primaryDate, secondaryDate);
  }
}

moment.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: function (number, withoutSuffix) {
      return withoutSuffix ? "now" : "a few seconds";
    },
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: function (number) {
      if (number < 7) {
        return number + "d"; // Moment uses "d" when it's just 1 day.
      } else {
        var weeks = Math.round(number / 7);
        return weeks + "w";
      }
    },
    M: "1mon",
    MM: "%dmon",
    y: "1y",
    yy: "%dy"
  }
});

/**
 * Returns relative date from now}
 *
 * @param {string} date
 * @returns {moment}
 */

export function getRelativeDate(date) {
  return moment(date).fromNow();
}

/**
 * Returns the provided date in the following format:
 * - 14th Mar 23 (when isFor is topical_calendar)
 *
 * @param {Date} date
 * @param {'topical_calendar'} [isFor]
 *
 * @returns {string}
 */
export function format(date, isFor) {
  if (isFor === "topical_calendar") {
    return fns.format(date, "do MMM yy");
  } else {
    return fns.format(date, "do MMM yyyy");
  }
}

/**
 * Returns the distance between the provided date and now in words.
 *
 * @param {Date} date
 *
 * @returns {string}
 */
export const formatDistanceToNow = date => {
  return fns.formatDistanceToNow(date);
};

import React from "react";
import { IBlock, FEATURE } from "./buildingBlocks.types";
import {
  BlockDescription,
  BlockTitle,
  BlockImage,
  BlockLayout,
  BlockTitleWrapper,
  BlockReturnIcon,
  DefaultBlockImage,
  ComingSoonInfo,
  NewInfo
} from "./styles";

interface IBlockProps extends IBlock {}

const Block: React.FC<IBlockProps> = ({
  image,
  title,
  action,
  description,
  active = FEATURE.ACTIVE
}) => {
  const blockLabel = () => {
    switch (active) {
      case FEATURE.COMING_SOON:
        return <ComingSoonInfo>Coming Soon</ComingSoonInfo>;
      case FEATURE.NEW:
        return <NewInfo>New</NewInfo>;
      case FEATURE.ACTIVE:
      default:
        return null;
    }
  };

  return (
    <BlockLayout
      onClick={() => {
        if (active !== FEATURE.COMING_SOON) action();
      }}
      active={active !== FEATURE.COMING_SOON}
    >
      {image ? <BlockImage src={image} alt={title} /> : <DefaultBlockImage />}
      <BlockTitleWrapper>
        <BlockTitle>{title}</BlockTitle>
        <BlockReturnIcon className="icon-return" />
      </BlockTitleWrapper>
      <BlockDescription>{description}</BlockDescription>
      {blockLabel()}
    </BlockLayout>
  );
};

export default Block;

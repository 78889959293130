import React from "react";
import { Flex } from "rebass";
import { FormBox, FormBoxImage } from "../common/form/styles";
import {
  PlanName,
  PlanPrice,
  PlanDescription,
  PlanHeader,
  PlanFeatures,
  PlanFeature,
  PrimaryWhiteButton,
  SecondaryWhiteButton
} from "./styles";
import { Check } from "@styled-icons/fa-solid";
import coachAvatars from "../../assets/images/willow-coaching-avatars.png";

const features = [
  "Monthly 30 minute session with your online social media expert",
  "Actionable advice & tips",
  "Create a social media strategy",
  "24/7 chat and mail support"
];

export default function BillingCoachingAddon({ currency, onSelect, onSkip }) {
  return (
    <FormBox variant="black" mx={20} my={20} maxWidth="380px">
      <FormBoxImage>
        <img src={coachAvatars} width="105" height="44" alt="Willow Coach" />
      </FormBoxImage>
      <PlanHeader borderColor="white">
        <PlanName>Monthly Coaching</PlanName>
        <PlanPrice currency={"EUR"} color="white">
          149
        </PlanPrice>
        <PlanDescription color="white">
          Learn everything about Social Media
        </PlanDescription>
      </PlanHeader>
      <PlanFeatures>
        {features.map((feature, index) => (
          <PlanFeature key={index} color="white">
            <Check size={12} />
            <span>{feature}</span>
          </PlanFeature>
        ))}
      </PlanFeatures>
      <Flex width="100%" flexDirection="column" mb={16}>
        <PrimaryWhiteButton size="lg" mb={8} onClick={() => onSelect()}>
          Yes, I want this!
        </PrimaryWhiteButton>
        <SecondaryWhiteButton size="lg" onClick={() => onSkip()}>
          Let's skip this for now
        </SecondaryWhiteButton>
      </Flex>
    </FormBox>
  );
}

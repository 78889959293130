import { STORAGE_KEY } from "../reducer";
import * as sessionStorageService from "utils/sessionStorage";

export const saveToSessionStorage = storageItem => {
  const sessionStorageData = sessionStorageService.get(STORAGE_KEY);
  const featureNotificationsInStorage = Array.isArray(sessionStorageData)
    ? sessionStorageData
    : [];

  if (featureNotificationsInStorage.length > 0) {
    const storedIds = featureNotificationsInStorage.map(item => item.id);

    let data;
    if (storedIds.includes(storageItem.id)) {
      data = featureNotificationsInStorage.map(item => {
        if (item.id === storageItem.id) {
          return storageItem;
        } else {
          return item;
        }
      });
    } else {
      data = [...featureNotificationsInStorage, storageItem];
    }

    sessionStorageService.set(STORAGE_KEY, data);
  } else {
    sessionStorageService.set(STORAGE_KEY, [storageItem]);
  }
};

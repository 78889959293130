import styled, { css } from "styled-components";

import { colors } from "../../../../theme";

const indicatorColorMap = {
  slot: "#8d9497",
  scheduled: "#ffc312",
  posted: "#009432",
  stuck: colors.red,
  drafted: colors.orange
};

export const MiniCal = styled.div`
  width: ${props => (props.size === "sm" ? "auto" : 290)}px;

  ${props =>
    !props.inline &&
    css`
      left: calc(50vw - (524px / 2 + 290px) - 32px);
      margin: 24px 0 32px;
      position: fixed;

      @media screen and (max-width: ${props.theme.stdBreakpoints.lg}) {
        position: sticky;
        top: 64px;
        padding: 0 16px;
        left: 0;
      }

      @media screen and (max-width: ${props.theme.stdBreakpoints.m}) {
        width: 194px;
      }

      @media screen and (max-width: ${props.theme.stdBreakpoints.sm}) {
        display: none;
      }
    `}
`;

export const MiniCalNav = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 8px;
`;

export const MiniCalArrow = styled.div`
  cursor: pointer;
  flex: 0;
  width: 24px;
  text-align: center;

  &:hover {
    opacity: 0.5;
  }
`;

export const MiniCalMonth = styled.div`
  color: ${props => props.theme.colors.text01};
  flex: 1;
  font-weight: 600;
  font-size: 14px;
  line-height: 17.5px;
  letter-spacing: -0.09px;
  text-align: center;
`;

export const MiniCalDayGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 32px);
  grid-gap: ${props => (props.size === "sm" ? 4 : 8)}px;
  grid-auto-rows: 32px;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.m}) {
    grid-gap: 4px 2px;
    grid-template-columns: repeat(7, 24px);
    grid-auto-rows: 24px;
  }
`;

export const MiniCalDayName = styled.div`
  color: ${props => props.theme.colors.text02};
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.32px;
  line-height: 32px;
  text-align: center;
  width: 32px;
`;

export const MiniCalDay = styled.div`
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;

  span {
    margin-top: auto;
    color: ${props =>
      props.isOutside
        ? props.theme.colors.text03
        : props.isToday
          ? props.theme.colors.blue
          : props.isPast
            ? props.theme.colors.text03
            : props.theme.colors.text01};
    font-size: 13px;
    font-weight: 600;
    padding-top: 2px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.grey01};
  }

  ${props =>
    props.isToday &&
    css`
      box-shadow: inset 0 0 0 1px ${props.theme.colors.blue};
    `}

  ${props =>
    props.isHighlighted &&
    css`
      background-color: ${props.theme.colors.primaryLight};
    `}

  ${props =>
    props.isFocus &&
    css`
      background-color: ${props.theme.colors.blue};
      font-weight: 700;
      box-shadow:
        0px 1px 3px -1px rgba(28, 30, 31, 0.3),
        0px 2px 5px -1px rgba(28, 30, 31, 0.25);

      & span {
        color: white;
      }
      &:hover {
        background-color: ${props => props.theme.colors.darkBlue};
      }
    `}
`;

export const MiniCalIndicators = styled.div`
  display: flex;
  margin: 1px 0;
  padding-bottom: 2px;
  height: 5px;
`;

export const IndicatorDot = styled.div`
  background-color: ${props => indicatorColorMap[props.type]};
  border-radius: 5px;
  height: 6px;
  margin: 0 1px 1px;
  transform: scale(calc(5 / 6));
  width: 6px;
`;

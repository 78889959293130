import styled from "styled-components";
import { Flex, Box } from "rebass/styled-components";
import { Paragraph as StyledParagraph } from "../../components/common/styles";

export const Paragraph = styled(StyledParagraph)`
  font-size: 16px;
  line-height: 24px;

  & li {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const AvatarWrapper = styled(Flex)`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: #606770;
  align-items: center;
`;

export const UserAvatar = styled(Box)`
  background-color: ${props => props.theme.colors.grey01};
  border-radius: 36px;
  align-self: center;
  height: 36px;
  width: 36px;
  min-width: 36px;
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden;

  & img {
    height: 36px;
  }
`;

export const AccountName = styled(StyledParagraph)`
  color: ${props => props.theme.colors.text02};
`;

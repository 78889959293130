import { Flex } from "rebass";
import Modal from "react-modal";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { X } from "styled-icons/boxicons-regular";
import React, { useEffect, useMemo } from "react";
import styled, { css, keyframes } from "styled-components";

import { closeModal } from "state/actions/ModalActions";
import * as accountActions from "state/actions/AccountActions";

import Button from "components/common/Button";

import { Headline3 } from "components/common/styles";

import IconButton from "components/common/IconButton";
import { OnboardingStepsEnum } from "types/Onboarding.types";

const IconWrapper = styled.div<{ variant: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: auto;

  border-radius: 12px;

  ${props => {
    switch (props.variant) {
      case "primary":
        return css`
          background: ${props => props.theme.colors.primarySubtle};
        `;
      case "success":
        return css`
          background: ${props => props.theme.colors.greenSubtle};
        `;
      default: {
        return css`
          background: ${props => props.theme.colors.grey01};
        `;
      }
    }
  }}
  > span {
    font-size: 20px;

    ${props => {
      switch (props.variant) {
        case "primary":
          return css`
            color: ${props => props.theme.colors.blue};
          `;
        case "success":
          return css`
            color: ${props => props.theme.colors.green};
          `;
        default: {
          return css`
            color: ${props => props.theme.colors.text01};
          `;
        }
      }
    }}
  }
`;

const InfoWrapper = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  text-align: center;
  justify-content: center;
`;

const keyframesExpand = keyframes`
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
`;

const Timer = styled.div<{ variant: string }>`
  height: 6px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${keyframesExpand} 20s ease;

  ${props => {
    switch (props.variant) {
      case "primary":
        return css`
          background: ${props => props.theme.colors.blue};
        `;
      case "success":
        return css`
          background: ${props => props.theme.colors.green};
        `;
      default: {
        return css`
          background: ${props => props.theme.colors.grey01};
        `;
      }
    }
  }}
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: 10px;
  right: 8px;
`;

const InfoPara = styled.p`
  margin-top: 8px;
  line-height: 140%;
  font-weight: 400;
  color: ${props => props.theme.colors.text02};
`;

const ButtonGroup = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
`;

const ContentWrapper = styled.div`
  max-width: 272px;
  margin: auto;
`;

const MOTIVATION_WORDS = [
  "Awesome",
  "Amazing",
  "Excellent",
  "Great",
  "Good job"
];

const modalStyles = (maxWidth = 550, showFooter = false) => {
  return {
    // dark background behind all modals
    overlay: {
      background: "transparent",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-start",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      //overflowY: 'visible',
      overflowX: "hidden",
      zIndex: 10,
      padding: "15px 24px 15px 24px"
    },
    // modal root
    content: {
      overflow: "auto",
      maxHeight: "95vh",
      boxSizing: "border-box",
      background: "#F7F8F9",
      backgroundClip: "padding-box",
      borderRadius: "16px",
      position: "relative",
      border: "1px",
      borderColor: "#EAEBEB",
      width: "100%",
      maxWidth: `${maxWidth}px`,
      top: "auto",
      bottom: "auto",
      left: "auto",
      right: "auto",
      backgroundColor: "white",
      boxShadow:
        "0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)",
      padding: showFooter ? "20px 20px 0" : "24px"
    }
  };
};

const OnboardingInfoModal: React.FC<{
  closeModal: (modalName?: string) => void;
  updateAccount: any;
}> = ({ closeModal, updateAccount }) => {
  const account = useSelector((state: any) => state.account.data);
  const { modalProps, isOpen } = useSelector((state: any) => state.modals);
  const user = useSelector((state: any) => state.auth.currentUser);
  const styles = modalStyles(320);
  const history = useHistory();

  useEffect(() => {
    let timeout: any = null;
    if (modalProps?.id) {
      timeout = setTimeout(() => {
        onClose();
      }, 20000);
    }

    return () => {
      if (!!timeout) {
        clearTimeout(timeout);
      }
    };
  }, [modalProps?.id]);

  const isTrialOrSmallBiz = useMemo(
    () =>
      account?.billing?.willowPlan === "SMALL_BIZ" ||
      account?.billing?.billingStatus === "in_trial",
    [account]
  );

  const onboardingStepsTotal = account?.isTelenetCustomer
    ? 3
    : isTrialOrSmallBiz
      ? 5
      : 4;

  if (!modalProps?.id) return null;

  const onClose = () => {
    updateAccount({
      ...account,
      ...(!!triggeredBy
        ? {
            onboardingProgress: [
              ...new Set([...account.onboardingProgress.concat(triggeredBy)])
            ]
          }
        : {})
    });
    if (onCloseCallback) onCloseCallback();
    closeModal("ONBOARDING_INFO_MODAL");
  };

  const {
    title,
    variant,
    subTitle,
    forOnboarding = true,
    triggeredBy,
    secondaryAction = true,
    onCloseCallback
  } = modalProps;

  const alreadyDone =
    account?.onboardingProgress?.includes(triggeredBy) && forOnboarding;

  if (!modalProps.id || alreadyDone) {
    return null;
  }

  const icon = (() => {
    switch (triggeredBy) {
      case OnboardingStepsEnum.CREATE_POST: {
        return (
          <IconWrapper variant={variant || "primary"}>
            <span className="icon-clock" />
          </IconWrapper>
        );
      }
      default: {
        return (
          <IconWrapper variant={variant || "primary"}>
            <span className="icon-check" />
          </IconWrapper>
        );
      }
    }
  })();

  const motivatingLabel = (() => {
    const motivate =
      MOTIVATION_WORDS[Math.floor(Math.random() * MOTIVATION_WORDS.length)];

    return `${motivate}, ${user.firstName}`;
  })();

  const completed = (() => {
    let count = new Set();

    if (triggeredBy) {
      if (
        [
          OnboardingStepsEnum.FOLLOW_SECTION,
          OnboardingStepsEnum.LIKE_ARTICLE,
          OnboardingStepsEnum.GET_PERSONALIZED_SUGGESTIONS
        ].includes(triggeredBy as OnboardingStepsEnum)
      ) {
        count.add("news");
      } else {
        count.add(triggeredBy);
      }
    }

    if (account && account.onboardingProgress) {
      account?.onboardingProgress?.forEach((process: string) => {
        if (
          [
            OnboardingStepsEnum.FOLLOW_SECTION,
            OnboardingStepsEnum.LIKE_ARTICLE,
            OnboardingStepsEnum.GET_PERSONALIZED_SUGGESTIONS
          ].includes(process as OnboardingStepsEnum)
        ) {
          count.add("news");
        } else {
          count.add(process);
        }
      });
    }

    return count.size;
  })();

  const showNextStep =
    forOnboarding && !!Math.max(onboardingStepsTotal - completed, 0);

  return (
    <Modal
      // @ts-ignore
      name={`close-modal-${modalProps.id}`}
      ariaHideApp={false}
      isOpen={isOpen}
      // @ts-ignore
      style={styles}
      onRequestClose={onClose}
    >
      <Timer variant={variant || "primary"} />
      <CloseButton
        // @ts-ignore
        variant={"secondaryQuiet"}
        icon={X}
        onClick={onClose}
      />

      <InfoWrapper>
        {icon}
        <ContentWrapper>
          <Headline3>{motivatingLabel}</Headline3>
          <Headline3>
            {title ||
              `You completed ${completed} out of ${onboardingStepsTotal}`}
          </Headline3>
          <InfoPara>
            {subTitle ||
              `You're on your way to setup Willow like a pro. Keep up the good work.`}
          </InfoPara>
        </ContentWrapper>
        <ButtonGroup>
          {/* @ts-ignore */}
          <Button
            variant={variant || "primary"}
            onClick={() => {
              if (showNextStep) {
                if (
                  [
                    OnboardingStepsEnum.FOLLOW_SECTION,
                    OnboardingStepsEnum.LIKE_ARTICLE
                  ].includes(triggeredBy)
                ) {
                  history.push(`/accounts/${account.id}/content/for-you`);
                } else {
                  history.push(`/accounts/${account.id}/dashboard`);
                }
              }
              onClose();
            }}
          >
            {showNextStep ? "View next step" : "Done"}
          </Button>
          {secondaryAction && (
            // @ts-ignore
            <Button variant="secondary" onClick={() => onClose()}>
              {secondaryAction?.label || "Cancel"}
            </Button>
          )}
        </ButtonGroup>
      </InfoWrapper>
    </Modal>
  );
};

export default connect(null, {
  closeModal: closeModal,
  updateAccount: accountActions.updateAccountAboutOnboarding
})(OnboardingInfoModal);

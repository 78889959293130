import React from "react";

import {
  TooltipTitle,
  StatListItem,
  TooltipContent,
  TooltipContainer
} from "../styles";
import { colors } from "theme";
import Divider from "components/common/Divider";

interface ITooltip {
  rank: number;
  posts: number;
  username: string;
  engagement: number;
}

const Tooltip: React.FC<ITooltip> = ({ rank, posts, username, engagement }) => {
  return (
    <TooltipContainer>
      <TooltipTitle>{username}</TooltipTitle>
      <TooltipContent>
        <StatListItem bgcolor={colors.text01}>
          <div className="title">
            <span className="badge" />
            Posts
          </div>
          <div className="stats">{posts}</div>
        </StatListItem>
        {typeof engagement === "number" && (
          <StatListItem bgcolor={colors.blue}>
            <div className="title">
              <span className="badge" />
              Engagement
            </div>
            <div className="stats">{engagement}</div>
          </StatListItem>
        )}
        {typeof rank === "number" && (
          <>
            <Divider my={0} mx={-12} />
            <StatListItem bgcolor="transparent">
              <div className="title alternate">
                <span className="badge" />
                Ranking
              </div>
              <div className="stats">#{rank}</div>
            </StatListItem>
          </>
        )}
      </TooltipContent>
    </TooltipContainer>
  );
};

export default Tooltip;

import React, { useState } from "react";
import {
  IArticleAttachment,
  IAttachment,
  IPdfAttachment,
  IPhotoAttachment,
  IVideoAttachment
} from "@hellocontento/contento-common";
import { Video } from "@styled-icons/fluentui-system-regular";

import { ATTACHMENT_TYPES } from "constants/post";
import EmptyArticleImage from "assets/images/article/empty-article-image.png";
import PostAttachmentImage from "components/schedule/common/postAttachmentImage";
import PostAttachmentVideo from "components/schedule/common/postAttachmentVideo";
import {
  CalendarEntryArticle,
  CalendarEntryArticleText,
  CalendarEntryThumbnail
} from "components/schedule/calendar/styles";
import { Box } from "rebass/styled-components";

const IdeaEntryAttachment = ({
  attachment,
  attachmentHeight,
  fullAttachmentHeight,
  videoLess
}: {
  attachment: IAttachment;
  videoLess: boolean;
  attachmentHeight?: number;
  fullAttachmentHeight?: boolean;
}) => {
  let content: React.ReactNode;

  if (attachment.type === ATTACHMENT_TYPES.PHOTO) {
    attachment = attachment as IPhotoAttachment;
    content = (
      <PostAttachmentImage
        attachment={attachment}
        maxHeight={attachmentHeight}
        height={fullAttachmentHeight ? "100%" : attachmentHeight}
      />
    );
  }

  if (attachment.type === ATTACHMENT_TYPES.PDF) {
    attachment = attachment as IPdfAttachment;
    content = (
      <PostAttachmentImage
        attachment={{
          url: (attachment as IPdfAttachment).metaData?.preview || ""
        }}
        maxHeight={attachmentHeight}
        height="unset"
      />
    );
  }

  if (attachment.type === ATTACHMENT_TYPES.VIDEO) {
    attachment = attachment as IVideoAttachment;
    content = (
      <PostAttachmentVideo
        height={attachmentHeight}
        attachment={attachment}
        videoLess={videoLess}
      />
    );
  }

  if (attachment.type === ATTACHMENT_TYPES.ARTICLE) {
    attachment = attachment as IArticleAttachment;
    content = (
      <CalendarEntryArticle sx={{ height: `${attachmentHeight}px` }}>
        <CalendarEntryThumbnail
          $imgSrc={
            (attachment as IArticleAttachment).image || EmptyArticleImage
          }
          $height={"100%"}
          $maxHeight={fullAttachmentHeight ? `100%` : undefined}
        />
        <CalendarEntryArticleText>
          <p>{(attachment as IArticleAttachment).title || ""}</p>
        </CalendarEntryArticleText>
      </CalendarEntryArticle>
    );
  }

  return <>{content}</>;
};

const IdeaAttachmentWrapper = ({
  attachment
}: {
  attachment?: IAttachment;
}) => {
  const isVideo = attachment?.type === "video";
  const [isThumbnailHovered, setThumbnailHovered] = useState(false);

  return (
    <>
      {attachment && (
        <Box
          maxWidth={"359px"}
          maxHeight={"162px"}
          onMouseEnter={() => setThumbnailHovered(true)}
          onMouseLeave={() => setThumbnailHovered(false)}
          sx={{
            borderRadius: "4px",
            border: "1px solid #606E801F",
            position: "relative"
          }}
        >
          <IdeaEntryAttachment
            attachment={attachment}
            videoLess={true}
            attachmentHeight={162}
            fullAttachmentHeight={true}
          />
          {isVideo && (
            <Box
              sx={{
                transform: `scale(${isThumbnailHovered ? 1.04 : 1})`,
                position: "absolute",
                top: "12px",
                left: "8px"
              }}
            >
              <Video size={18} color="#3C3F4254" />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default IdeaAttachmentWrapper;

import React from "react";
import styled from "styled-components";

interface IAddConditionButtonProps {
  onClick: () => void;
}

const ConditionButton = styled.button`
  display: flex;
  border: none;
  border-radius: 6px;
  padding: 13px 16px;

  background: none;
  border: 1px solid ${props => props.theme.colors.grey03};
  color: ${props => props.theme.colors.text04};

  :hover {
    border: 1px solid transparent;
    background-color: ${props => props.theme.colors.primaryLight};
    color: ${props => props.theme.colors.primary};
  }

  outline: none;
  width: 100%;
  font-weight: 600;
  gap: 4px;
  margin-top: 6px;
  align-items: center;
  cursor: pointer;
  > span {
    font-size: 16px;
  }
`;

const AddConditionButton: React.FC<IAddConditionButtonProps> = ({
  onClick
}) => {
  return (
    <ConditionButton onClick={onClick}>
      <span className="icon-plus" />
      Add Condition
    </ConditionButton>
  );
};

export default AddConditionButton;

import {
  Title,
  Legend,
  Tooltip,
  ChartData,
  LinearScale,
  LineElement,
  ChartOptions,
  PointElement,
  CategoryScale,
  Chart as ChartJS
} from "chart.js";
import { Line } from "react-chartjs-2";
import React, { useState, useEffect } from "react";

import {
  WidgetTitle,
  PrimaryStatsInfo,
  SecondaryStatsInfo,
  WidgetHeaderContent,
  AnalyticsWidgetHeader,
  AnalyticsContentWrapper,
  AnalyticsWidgetContainer
} from "../styles";
import { colors } from "theme";
import EngagementTooltip from "./Tooltip";
import Loader from "components/common/loading/Loader";
import { getNumWithMaxDecimals } from "utils/numbers";
import { useDashboardState } from "contextApi/dashboardContext";
import { getChartData, getLineChartOptions } from "utils/charts";
import { today, difference, subtractDuration } from "utils/date";
import { externalTooltipHandler } from "../ExternalTooltipHandler";

ChartJS.register(
  Title,
  Legend,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale
);

interface IChartConfig {
  chartData: ChartData<"line">;
  chartOptions: ChartOptions<"line">;
}

const EngagementAnalytics: React.FC<{}> = () => {
  const filterRange = useDashboardState(state => state.filterRange);
  const stats = useDashboardState(state => state.stats.data);
  const isFetching = useDashboardState(state => state.stats.isFetching);
  const minDataPoints: null | number =
    typeof filterRange.periodInDays === "number"
      ? difference(
          today(),
          subtractDuration(today(), {
            days: filterRange.periodInDays
          }),
          "weeks"
        )
      : null;
  const [{ chartData, chartOptions }, setChartConfig] = useState<IChartConfig>({
    chartData: { labels: [], datasets: [] },
    chartOptions: getLineChartOptions([], {
      scales: { y: { display: false }, x: { display: false } }
    })
  });

  useEffect(() => {
    if (!isFetching && !!stats?.engagements?.points) {
      const formattedData = stats.engagements.points.map((point: any) => ({
        ...point,
        total: point.variation
      }));
      const { data, dates } = getChartData(
        formattedData,
        true,
        formattedData.length >= 1 && !!minDataPoints ? minDataPoints : 9
      );
      const options = getLineChartOptions(data, {
        ...(stats.engagements.points.length < 1
          ? {
              scales: {
                y: {
                  beginAtZero: true,
                  grace: 1,
                  ticks: {
                    callback: function (_, index) {
                      return `${index}k`;
                    }
                  }
                }
              }
            }
          : {}),
        elements: {
          point: { radius: stats.engagements.points.length === 1 ? 3 : 0 }
        },
        plugins: {
          tooltip: {
            enabled: false,
            external: externalTooltipHandler(EngagementTooltip)
          }
        }
      });

      setChartConfig({
        chartData: {
          labels: dates,
          datasets: [
            {
              data
            }
          ]
        },
        chartOptions: options
      });
    }
  }, [isFetching, minDataPoints, stats]);

  const progressPercentage = (() => {
    if (
      !stats?.engagements ||
      typeof stats.engagements.previous !== "number" ||
      typeof stats.engagements.period !== "number"
    ) {
      return null;
    } else {
      if (stats.engagements.previous === 0) return null;

      const diffPercentage =
        ((stats.engagements.period - stats.engagements.previous) /
          stats.engagements.previous) *
        100;

      if (Number.isFinite(diffPercentage)) {
        return getNumWithMaxDecimals(diffPercentage, 1);
      } else {
        return 0;
      }
    }
  })();

  return (
    <AnalyticsWidgetContainer
      colspan={2}
      rowspan={1}
      bgColor={colors.brandBlue}
    >
      <AnalyticsWidgetHeader>
        <WidgetTitle className="alternate">Engagement</WidgetTitle>
        {!isFetching && (
          <WidgetHeaderContent>
            <PrimaryStatsInfo>
              {!!stats?.engagements &&
              typeof stats.engagements.period === "number" &&
              !!stats?.engagements?.period &&
              stats.engagements.points.length >= 1
                ? stats.engagements.period.toString().length >= 5
                  ? `${getNumWithMaxDecimals(
                      stats.engagements.period / 1000,
                      1
                    )}k`
                  : stats.engagements.period
                : "-"}
            </PrimaryStatsInfo>
            {progressPercentage !== null &&
              !!stats?.engagements?.points &&
              stats.engagements.points.length >= 1 && (
                <SecondaryStatsInfo>
                  {progressPercentage < 0 ? "-" : "+"}
                  {Math.abs(progressPercentage)}%<br />
                  <span className="sub-content">
                    from prev. {filterRange.label}
                  </span>
                </SecondaryStatsInfo>
              )}
          </WidgetHeaderContent>
        )}
      </AnalyticsWidgetHeader>
      <AnalyticsContentWrapper>
        {isFetching ? (
          <Loader size={36} />
        ) : (
          <Line
            width="100%"
            height="100%"
            data={chartData}
            options={chartOptions}
          />
        )}
        {!isFetching &&
          (!stats?.engagements?.points ||
            stats.engagements.points.length < 1) && (
            <span className="no-data">Not enough data</span>
          )}
      </AnalyticsContentWrapper>
    </AnalyticsWidgetContainer>
  );
};

export default EngagementAnalytics;

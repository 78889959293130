import { Flex, Box, Text } from "rebass/styled-components";
import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  useNewsDispatch,
  resetStateAction,
  setContentAction
} from "contextApi/newsContext";
import {
  ContentTopicImage,
  ContentSectionWrapper
} from "components/content/styles";
import styled from "styled-components";
import useOnScreen from "hooks/useOnScreen";
import { follow, unfollow } from "services/news";
import { Headline2 } from "components/common/styles";
import SubscribeButton from "components/content/common/SubscribeButton";

interface IContentDetailBannerProps {
  pageData: any;
}

const Title = styled(Headline2)`
  color: ${props => props.theme.colors.white};
`;

const TopicDetailBanner: React.FC<IContentDetailBannerProps> = React.memo(
  ({ pageData }) => {
    const ref = useRef();
    const isVisible = useOnScreen(ref);
    const dispatch = useNewsDispatch();
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLocalFollowed, setIsLocalFollowed] = useState(
      pageData.topic.isFollowed
    );

    useEffect(() => {
      if (!isVisible) {
        dispatch(
          setContentAction({
            id: pageData.topic.id,
            type: "topics",
            title: pageData.topic.title,
            isFollowing: isLocalFollowed
          })
        );
      } else {
        dispatch(resetStateAction());
      }
    }, [
      dispatch,
      isVisible,
      isLocalFollowed,
      pageData.topic.id,
      pageData.topic.title
    ]);

    const toggleTopic = useCallback(async (id: string, isFollowed: boolean) => {
      try {
        setIsProcessing(true);
        if (isFollowed) {
          await unfollow("topics", id);
          setIsLocalFollowed(false);
        } else {
          await follow("topics", id);
          setIsLocalFollowed(true);
        }
        setIsProcessing(false);
      } catch (_) {
        setIsProcessing(false);
      }
    }, []);

    return (
      <ContentSectionWrapper mb={40} color={pageData.topic.color}>
        <Flex flexDirection="column" alignItems="center" margin={"auto"}>
          <ContentTopicImage src={pageData.topic.image} ref={ref} />
          {/* @ts-ignore */}
          <Title sx={{ textTransform: "capitalize" }}>
            {pageData.topic.title}
          </Title>
          <Text fontSize="14px" marginTop={"4px"} color="white">
            {`The latest articles from around the web about ${pageData.topic.title}`}
          </Text>
          <Box mt={32}>
            <SubscribeButton
              id={pageData.topic.id}
              type={"topics"}
              isProcessing={isProcessing}
              followLabel={"Follow Topic"}
              color={pageData.topic.color}
              // @ts-ignore
              toggleTopic={toggleTopic}
              onFollow={() => toggleTopic(pageData.topic.id, false)}
              onUnfollow={() => toggleTopic(pageData.topic.id, true)}
            />
          </Box>
        </Flex>
      </ContentSectionWrapper>
    );
  }
);

export default TopicDetailBanner;

import React, { SyntheticEvent, useEffect, useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import { Field, reduxForm } from "redux-form";
import { required, validPassword } from "../../../utils/Validation";

import {
  Form,
  FormTitle,
  FormDescription
} from "../../../components/common/form/styles";

import {
  FormBox,
  FormMessage,
  FieldWrapper,
  RoundedButton,
  CheckboxWrapper
} from "../styles";

import { NativeLink } from "components/common/Link";
import PasswordInput from "components/common/form/PasswordInput";
import Checkbox from "components/common/form/Checkbox";

interface ISignUpFormProps {
  email: string;
  state: string;
  handleSubmit: (values: SyntheticEvent) => any;
  sent: boolean;
  hasError: boolean;
  goBack: () => void;
}

const SignUpForm: React.FC<ISignUpFormProps> = ({
  email,
  state,
  handleSubmit,
  sent,
  hasError,
  goBack
}) => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState<boolean>(true);

  useEffect(() => {
    if (sent === true || hasError) {
      setIsSending(false);
    }
  }, [sent, hasError]);

  const onSubmit = (values: SyntheticEvent) => {
    const result = handleSubmit(values);
    setIsSending(!!(result && result.type));
  };

  return (
    <>
      {/* @ts-ignore */}
      <Form onSubmit={onSubmit} isTransitioned={true} state={state} fullWidth>
        <Flex flexDirection="column" alignItems="center">
          {/* @ts-ignore */}
          <FormTitle size={32}>Setup your password</FormTitle>
          <FormDescription>
            Chose a strong password for your new account. Avoid common words or
            patterns.
          </FormDescription>
          <FormBox maxWidth={"400px"}>
            <Box width="100%">
              <FieldWrapper>
                <input hidden={true} id="username" type="email" value={email} />
                <Field
                  validate={[required, validPassword]}
                  autocomplete="new-password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  component={PasswordInput}
                  className="dim-input__field"
                  autofocus={true}
                />
              </FieldWrapper>
              <Box pt={8} pb={20}>
                {/* @ts-ignore */}
                <RoundedButton
                  size="lg"
                  type="submit"
                  disabled={isSending}
                  width="100%"
                >
                  Set Password
                </RoundedButton>
              </Box>
            </Box>
            <Box>
              <CheckboxWrapper>
                <Checkbox
                  name="loginSession"
                  checked={keepLoggedIn}
                  onChange={() => setKeepLoggedIn(!keepLoggedIn)}
                />
                Keep me logged in
              </CheckboxWrapper>
              <FormMessage mt={30}>
                <NativeLink onClick={goBack} $bold={true} $underline={true}>
                  Go back to Sign Up
                </NativeLink>
              </FormMessage>
            </Box>
          </FormBox>
        </Flex>
      </Form>
    </>
  );
};

export default reduxForm({
  form: "signUpPassword",
  enableReinitialize: true
  // @ts-ignore
})(SignUpForm);

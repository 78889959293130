import React, { useMemo } from "react";
import { Parser } from "@json2csv/plainjs";
import { Text } from "rebass/styled-components";
import { format } from "date-fns";
import { useToaster } from "@hellocontento/maillard";

import {
  TableTitle,
  LeftContainer,
  RightContainer,
  DownloadButton,
  HeaderContainer
} from "../styles";
import {
  useAnalyticsState,
  useAnalyticsActions
} from "contextApi/analyticsContext";
import Tooltip from "components/common/Tooltip";
import ChannelToggle from "components/common/ChannelToggle";
import PeriodSelect, { IPeriodMenuItem } from "../widgets/PeriodSelect";
import PostCategorySelector from "../widgets/PostCategorySelector";

interface AllPostsTableHeaderProps {
  period: IPeriodMenuItem["value"];
  setPeriod: (period: IPeriodMenuItem["value"]) => void;
  loading: boolean;
  selectedChannels: string[];
  setSelectedChannels: (channels: string[]) => void;
  account: {
    id: string;
    channels: Array<{
      id: string;
      avatar: string;
      username: string;
      service: string;
    }>;
  };
  extractTableData: () => any[];
}

const AllPostsTableHeader: React.FC<AllPostsTableHeaderProps> = ({
  period,
  setPeriod,
  loading,
  selectedChannels,
  setSelectedChannels,
  account,
  extractTableData
}) => {

  const addToast = useToaster();
  const data = useAnalyticsState(state => state.allPosts.data);
  const selectedCategories = useAnalyticsState(
    state => state.allPosts.categories
  );
  const setSelectedCategories = useAnalyticsActions(
    state => state.setAllPostsCategories
  );

    const handleSave = (updatedChannels: string[]) => {
      setSelectedChannels(updatedChannels);
    };
  const categories = useMemo(() => {
    let contentTypeIdSet: Set<string> = new Set(
      data.map(item => item.contentTypeId)
    );
    const contentTypeIds = Array.from(contentTypeIdSet);
    setSelectedCategories(contentTypeIds);
    return contentTypeIds;
  }, [data, setSelectedCategories]);

  const handleDownload = () => {
    try {
      const data = extractTableData();
    const parser = new Parser();
    const csv = parser.parse(data);

    const currentDate = format(new Date(), "dd-MM-yyyy");

    const filename = `WillowPostsData-${currentDate}.csv`;

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    } catch (err) {
      console.error("Error while trying to download analytics:: ", err);
      addToast("Data could not be downloaded.", "error");
    }
  };

  return (
    <HeaderContainer>
      <LeftContainer>
        <TableTitle>All Posts</TableTitle>
        <Text fontSize={"14px"}>
          A breakdown of the data behind all your posts. You can read more about{" "}
          <a
            href="https://support.willow.co/knowledge/analytics-terminology"
            target="_blank"
            rel="noopener noreferrer"
          >
            what each metric means here
          </a>
          .
        </Text>
      </LeftContainer>
      <RightContainer>
        <Tooltip title="Download report" placement="bottom">
          <DownloadButton onClick={handleDownload} disabled={loading}>
            <i className="icon-download" />
          </DownloadButton>
        </Tooltip>
        <PeriodSelect selected={period} onSelect={setPeriod} />
        <PostCategorySelector
          categories={categories}
          selectedCategories={selectedCategories}
          onChange={setSelectedCategories}
        />
        <ChannelToggle
          channels={account.channels}
          selectedIds={selectedChannels}
          onSave={handleSave}
          gap={5}
          onChange={() => {}}
        />
      </RightContainer>
    </HeaderContainer>
  );
};

export default AllPostsTableHeader;

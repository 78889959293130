import React from "react";
import styled from "styled-components";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { NavLink } from "react-router-dom";
import { Image, Flex, Box } from "rebass/styled-components";

import { IStyledHeaderProps, ILogoProps } from "./styles.type";

export const StyledHeader = styled.header`
  background: ${(props: IStyledHeaderProps) =>
    props.isTransparent ? "rgb(241, 241, 241)" : "white"};
  box-sizing: border-box;
  box-shadow: ${props =>
    props.isTransparent
      ? "0 1px 0px rgba(0, 0, 0, 0)"
      : "0 1px 2px rgba(0, 0, 0, 0.13)"};

  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  transition: 0.25s;
  width: 100%;
  z-index: 10;

  > div.main {
    display: grid;
    grid-template-areas: "brand nav meta";
    grid-template-columns: 1fr auto 1fr;
    padding: 8px 16px;
  }

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    > div.main {
      grid-template-areas: "brand meta" "nav nav";
      grid-template-columns: auto auto;
    }
  }
`;

export const SecondaryHeader = styled(Flex)`
  background-color: white;
  justify-content: center;
  position: sticky;
  top: 64px;
  z-index: 4;

  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(20px);
`;

export const SecondaryHeaderContainer = styled(Flex)`
  align-items: center;
  flex: 1;
  margin: 0 32px;
  justify-content: space-between;
  padding: 12px 0px;
  max-width: 1288px;
  height: 64px;

  .right-menu {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;

    > i {
      font-size: 20px;
      color: ${props => props.theme.colors.text03};
    }
  }
`;

export const SecondaryHeaderSearch = styled(Box)`
  display: grid;
  align-items: center;
  flex: 1;
  margin: 0 24px;
  height: 56px;
  max-width: 680px;
`;

export const HeaderBrand = styled(Flex)`
  align-items: center;
  grid-area: brand;

  > a {
    display: flex;
    align-items: center;
  }
`;

export const HeaderNav = styled.div`
  align-self: center;
  grid-area: nav;
  justify-self: center;

  @media only screen and (max-width: ${props =>
      props.theme.stdBreakpoints.sm}) {
    max-width: 375px;
    text-align: center;
    width: 100%;
  }
`;

export const HeaderMeta = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-area: meta;
  gap: 8px;
  justify-self: flex-end;
  margin-right: 5px;
`;

export const NavItemList = styled(Flex)`
  @media only screen and (max-width: ${props =>
      props.theme.stdBreakpoints.sm}) {
    margin: 8px 0;
    max-width: calc(100vw - 32px);
    overflow: scroll;
    width: 100%;
  }
`;

export const NavItem = styled(NavLink)`
  align-items: center;
  border-radius: 20px;
  box-sizing: border-box;
  color: ${props => props.theme.colors.text03};
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  letter-spacing: -0.16px;
  margin: 0 8px;
  min-width: 72px;
  padding: 8px;

  &:hover,
  &.active {
    color: ${props => props.theme.colors.blue};
  }

  & label {
    font-size: 13px;
    line-height: 14px;
  }

  @media only screen and (max-width: ${props => props.theme.stdBreakpoints.m}) {
    padding: 8px 20px;
    margin: 0 2px;
  }
`;

export const StyledNavItemIcon = styled.span`
  font-size: 14px;
  margin-bottom: 4px;
`;

export const Logo = styled(Image)`
  background: ${props => props.theme.colors.background};
  animation: ${(props: ILogoProps) =>
    props.isSpinning ? "spin 5s linear infinite" : "none"};

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @media screen and (min-width: ${props => props.theme.breakpoints[0]}) {
    position: absolute;
    margin-top: 38px;
    border-top: 1px solid #eaebeb;
  }
`;

export const AvatarWrapper = styled(Flex)`
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: #606770;
  align-items: center;
`;

export const UserAvatar = styled(Box)`
  background-color: ${props => props.theme.colors.grey01};
  border-radius: 36px;
  align-self: center;
  height: 36px;
  width: 36px;
  min-width: 36px;
  object-fit: cover;
  object-position: 50% 50%;
  overflow: hidden;

  & img {
    height: 36px;
  }
`;

export const UserMenuHeader = styled(Flex)`
  align-items: center;
  padding: 0 8px;
  margin-bottom: 16px;
`;

export const AccountChannelList = styled(Flex)`
  flex-direction: column;
  max-height: 363px;
  overflow-y: scroll;
`;

export const AccountChannel = styled(Flex)`
  align-items: center;
  padding: 14px 8px;
  min-height: 66px;
`;

export const MobileAvatar = styled(Box)`
  border-radius: 50%;
  height: 36px;
  width: 36px;
`;

export const AvatarMenu = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  z-index: 10;
  padding: 20px 18px;
  background: #ffffff;
  border-radius: 24px;
  box-shadow: 0 9px 46px rgba(0, 0, 0, 0.16);
  width: 420px;
`;

export const AvatarMenuButton = styled(Flex)`
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 2px;
  margin-top: 2px;
  padding: 12px;

  &:hover {
    background-color: ${props => props.theme.colors.grey01};
  }

  span {
    color: #111111;
    font-size: 14px;
    font-weight: 600;
    margin-left: 16px;
  }

  svg {
    color: ${props => props.theme.colors.primary};
    margin-left: auto;
  }
`;

export const UserMenuBigButton = styled(Flex)`
  cursor: pointer;
  background-color: ${props => props.theme.colors.grey01};
  border-radius: 7px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.21;
  letter-spacing: -0.32px;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  min-width: 120px;
  :hover {
    background-color: ${props => props.theme.colors.grey02};
  }
`;

export const MenuLogo = styled(Image)`
  cursor: pointer;
  height: 40px;
`;

export const StyledSideNavigation = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const SideNavigationLabel = styled.span<{ strong?: boolean }>`
  font-size: 13px;
  font-weight: 600;
  color: ${props => props.theme.colors.text01};
`;

export const SideNavigationItem = styled(NavLink)<{
  px?: number;
  py?: number;
  strong?: boolean;
}>`
  align-items: center;
  border-radius: 8px;
  color: ${props =>
    props.strong ? props.theme.colors.text03 : props.theme.colors.text02};
  display: flex;
  padding: ${props => props.py || 10}px ${props => props.px || 16}px;
  min-height: 20px;
  justify-content: space-between;

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.blue};
    ${SideNavigationLabel} {
      color: ${props => props.theme.colors.white};
    }
  }
  &.active {
    color: ${props => props.theme.colors.text01};
    background-color: ${props => props.theme.colors.grey01};
    font-weight: bold;
    ${SideNavigationLabel} {
      color: ${props => props.theme.colors.text01};
    }
  }
`;

export const SideNavigationIcon = styled.span`
  margin-right: 12px;
  fill: ${props => props.color};
  font-size: 20px;
  min-width: 20px;
`;

export const SideNavigationChevron = styled(
  ({ component, isExpanded, ...props }) => React.cloneElement(component, props)
)`
  fill: ${props => props.color};
  transform: ${props => props.isExpanded && "rotate(180deg)"};
`;

export const SideNavigationSection = styled.span`
  color: ${props => props.theme.colors.text03};
  font-size: 13px;
  font-weight: 600;
  margin: 14px 0 8px;
  padding: 6px 4px;
  text-transform: uppercase;
`;

export const StyledNavigationDropdown = styled.a`
  align-items: center;
  background-color: ${props => props.theme.colors.text01};
  border-radius: 8px;
  color: white;
  cursor: pointer;
  display: flex;
  padding: 10px 16px;
  margin-bottom: 24px;

  span {
    flex: 1;
  }
  &:hover {
    background-color: ${props => props.theme.colors.text02};
    color: white;
  }
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const DraftMenuWrapper = styled.div<{ isOpen: boolean }>`
  position: relative;
  box-shadow: ${props =>
    props.isOpen
      ? `0 0 0 2px white, 0 0 0 4px ${props.theme.colors.primary}`
      : `0 0 0 0 white, 0 0 0 -2px ${props.theme.colors.primary}`};
  border-radius: 50%;
`;

export const DraftsIndicatorWrapper = styled.div`
  background: ${props => props.theme.colors.red};
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  height: 18px;
  width: 18px;
  position: absolute;
  font-weight: bold;
  font-size: 10px;
  top: -5px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.white};
`;

// @ts-ignore
export const TooltipInfo = styled(ReactTooltip)`
  background: ${props => props.theme.colors.black};
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  color: white;
  line-height: 1;
  font-size: 14px;
  padding: 6px 8px;
  text-align: center;
  min-width: max-content;
`;

import styled, { css } from "styled-components";

import { SERVICES } from "constants/services";

export const EntryTextWrapper = styled.div`
  position: relative;
  height: auto;
`;

type Keys = keyof typeof SERVICES;
export interface IStyledEntryText {
  isTruncated?: boolean;
  hasAttachment: boolean;
  service: (typeof SERVICES)[Keys];
}

export const StyledEntryText = styled.div<IStyledEntryText>`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  align-items: flex-start;
  letter-spacing: -0.015em;
  white-space: break-spaces;
  color: ${props => props.theme.colors.black};

  ${props =>
    props.isTruncated &&
    (props.service === "all" ||
      props.service === SERVICES.LINKEDIN ||
      props.service === SERVICES.FACEBOOK) &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: ${props.hasAttachment ? 3 : 5};
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: calc(19.6px * 3);
      text-overflow: none;
    `}
`;

export const ReadMore = styled.div`
  /* position: absolute;
  bottom: 0px;
  right: 0px; */
  line-height: 19.6px;
  background-color: ${props => props.theme.colors.white};
  z-index: 1;

  &:hover {
    text-decoration: underline;
  }
`;

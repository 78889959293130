import { CategoryData } from "./category";

export enum ConjunctionData {
  AND = "and",
  NOT = "not"
}

export interface IKeywordAlertState {
  id: string;
  keywords: Array<{
    id: string;
    keyword: string;
  }>;
  category: CategoryData;
  conjunction: ConjunctionData;
}

import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "components/common/Button";
import { Wrapper, Subtitle, Title, ButtonWrapper } from "./styles";

const EmptyNewsSection: React.FC<{}> = () => {
  const history = useHistory();
  const account = useSelector<any, any>(state => state.account.data);

  const handleClick = () => {
    history.push(`/accounts/${account.id}/content/for-you`);
  };

  return (
    <Wrapper>
      <Subtitle>GET STARTED</Subtitle>
      <Title>
        Setup your newsroom. Start sharing. <br />
        Be a thought leader.
      </Title>
      <ButtonWrapper>
        {/* @ts-ignore */}
        <Button size="lg" variant="black" onClick={handleClick}>
          Setup now
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default EmptyNewsSection;

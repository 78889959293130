import { useLocation } from "react-router-dom";
import { useMemo } from "react";
import qs from "qs";

export function useQueryParams() {
  const location = useLocation();
  return useMemo(() => {
    return qs.parse(location.search, { ignoreQueryPrefix: true });
  }, [location.search]);
}

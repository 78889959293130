import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { ITopic } from "services/news";
import { Box } from "rebass";

import { ExploreSectionHeader } from "../styles";

import Button from "components/common/Button";
import { Headline3 } from "components/common/styles";
import ExploreTopic from "../../widgets/ExploreTopic";

import { RecommendationGrid } from "../../recommendations/styles";

interface IExploreTopicsProps {
  title: string;
  topics: Array<ITopic>;
  searchContext: string;
  changeSearchContext: () => void;
}

const ExploreTopics: React.FC<IExploreTopicsProps> = ({
  title,
  topics,
  changeSearchContext,
  searchContext
}) => {
  const account = useSelector((state: any) => state.account.data);
  const history = useHistory();

  return (
    <Box my={32}>
      <ExploreSectionHeader>
        <Headline3 $capitalize>{title}</Headline3>
        {searchContext === "all" && (
          <Box>
            <Button
              size={"xs"}
              variant={"tertiary"}
              onClick={changeSearchContext}
            >
              Discover all
            </Button>
          </Box>
        )}
      </ExploreSectionHeader>
      <RecommendationGrid>
        {(searchContext !== "all" ? topics : topics.slice(0, 6)).map(
          (detail: ITopic) => {
            return (
              <ExploreTopic
                data={detail}
                key={detail.id + detail.language}
                onClick={() =>
                  history.push(
                    `/accounts/${account.id}/content/topics/${detail.id}/${detail.language}`
                  )
                }
              />
            );
          }
        )}
      </RecommendationGrid>
    </Box>
  );
};

export default ExploreTopics;

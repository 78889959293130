import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Brick, Headline3, Paragraph } from "../common/styles";
import { Box, Flex } from "rebass";
import Checkbox from "../common/form/Checkbox";
import { useContentoApi } from "../../utils/useContentoApi";
import Button from "../common/Button";
import MutedSources from "./MutedSources";

import Preferences from "./preferences";
import { regionOptions } from "./constants";

const PreferencesGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr;
  }
`;

const PreferencesBrick = styled(Brick)`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  &::before {
    background-color: ${props =>
      props.accentColor ?? props.theme.colors.text01};
    content: "";
    display: block;
    height: 120px;
    position: relative;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    padding: 0;
  }
`;

const LanguagePreferenceGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 16px;
  margin: auto 0;
  padding: 24px 0;
`;

const LanguagePreference = styled(Flex)`
  align-items: center;
  column-gap: 12px;
  display: grid;
  grid-template-columns: 20px 1fr;

  & span {
    font-weight: 500;
  }
`;

const ContentPreferences = ({ account }) => {
  const [regionSettings, setRegionSettings] = useState(regionOptions);
  const [fetchRegionSettings, cancelFetchRegionSettings] = useContentoApi(
    `/accounts/${account.id}/news/settings/regions`
  );

  const handleRegionChange = region => {
    const update = region.enabled
      ? regionSettings
          .filter(
            r =>
              (r.language !== region.language || r.region !== region.region) &&
              r.enabled
          )
          .map(r => ({ language: r.language, region: r.region }))
      : [
          ...regionSettings
            .filter(r => r.enabled)
            .map(r => ({ language: r.language, region: r.region })),
          { region: region.region, language: region.language }
        ];

    fetchRegionSettings({
      method: "POST",
      data: update
    })
      .then(() => refreshRegionSettings())
      .catch(console.error);
  };

  const handleFeedbackClick = () => {
    if (window.HubSpotConversations) {
      window.history.pushState(
        {},
        "hs_feature_feedback",
        "?hs_feature_feedback=true"
      );
      window.HubSpotConversations.widget.refresh({
        openToNewThread: true
      });
      window.HubSpotConversations.widget.open();
    }
  };

  const refreshRegionSettings = useCallback(() => {
    fetchRegionSettings()
      .then(res => {
        setRegionSettings(
          regionOptions.map(region => ({
            ...region,
            enabled: res.some(
              r => r.language === region.language && r.region === region.region
            )
          }))
        );
      })
      .catch(console.error);
  }, [fetchRegionSettings]);

  useEffect(() => {
    refreshRegionSettings();

    return () => cancelFetchRegionSettings();
  }, [refreshRegionSettings, cancelFetchRegionSettings]);

  return (
    <Box flex={"1"}>
      <PreferencesGrid>
        <PreferencesBrick accentColor={"#FF5F00"}>
          <Box p={["24px", "32px"]}>
            <Headline3>Language & Region</Headline3>
            <Paragraph variant={"lightest"}>
              Pick the region and language you want to see content and topics
              from.
            </Paragraph>
            <LanguagePreferenceGrid>
              {regionSettings.map(region => (
                <LanguagePreference key={`${region.language}_${region.region}`}>
                  <Checkbox
                    checked={region.enabled}
                    onChange={() => handleRegionChange(region)}
                  />
                  <span>{region.label}</span>
                </LanguagePreference>
              ))}
            </LanguagePreferenceGrid>
          </Box>
        </PreferencesBrick>
        <PreferencesBrick accentColor={"#061616"}>
          <Flex flexDirection={"column"} flex={"1"} p={["24px", "32px"]}>
            <Headline3>Request a Topic</Headline3>
            <Paragraph variant={"lightest"}>
              Can’t find what you are looking for? Have a great topic idea? Just
              send your idea and we’ll have a look at what we can do for you.
            </Paragraph>
            <Box mt={"auto"}>
              <Button
                size={"sm"}
                variant={"secondary"}
                onClick={handleFeedbackClick}
              >
                Request a Topic
              </Button>
            </Box>
          </Flex>
        </PreferencesBrick>
        <PreferencesBrick accentColor={"#FFB02B"}>
          <Flex flexDirection={"column"} flex={"1"} p={["24px", "32px"]}>
            <Headline3>Recommendation Engine</Headline3>
            <Paragraph variant={"lightest"}>
              Based on what you save, post and like we’ll tune our
              recommendation system.
            </Paragraph>
            <Box mt={"auto"}>
              <Button
                size={"sm"}
                variant={"secondary"}
                onClick={() =>
                  window.open(
                    "https://support.willow.co/knowledge/recommended-content",
                    "_blank"
                  )
                }
              >
                Learn more
              </Button>
            </Box>
          </Flex>
        </PreferencesBrick>
      </PreferencesGrid>

      <Preferences />
      <MutedSources />
    </Box>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {})(ContentPreferences);

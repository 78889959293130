import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const generatePdf = async (
  containerId: string,
  pageClassName: string
) => {
  const doc = new jsPDF("p", "px", "a4");
  const pageWidth = doc.internal.pageSize.getWidth() - 20;
  const pageHeight = doc.internal.pageSize.getHeight() - 20;
  let marginY = 10;

  const reportContainer = document.getElementById(containerId);

  if (!(reportContainer instanceof HTMLElement)) {
    throw new Error("Error in generatePdf: Container element not found.");
  }

  const originalClassName = reportContainer.className;
  reportContainer.className = "report";

  const pages = reportContainer.getElementsByClassName(pageClassName);

  if (pages.length < 1) {
    throw new Error("Error in generatePdf: No pages found.");
  }

  // Iterate over pages and add them to the PDF
  for (let i = 0; i < pages.length; i++) {
    const page = pages[i];
    try {
      const canvas = await html2canvas(page as HTMLElement, { useCORS: true });
      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;
      const marginX = (pageWidth - canvasWidth) / 2 + 10;

      const image = canvas.toDataURL("image/png");
      if (i > 0) {
        doc.addPage("a4", "p");
      }
      doc.addImage(image, "PNG", marginX, marginY, canvasWidth, canvasHeight);
    } catch (error) {
      console.error("Error generating PDF for page", i + 1, ":", error);
    }
  }

  // Restore original class name and save the PDF
  reportContainer.className = originalClassName;
  doc.save("report.pdf");
};

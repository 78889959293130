import React from "react";
import { connect } from "react-redux";
import { Flex } from "rebass";
import { closeModal } from "../../../state/actions/ModalActions";
import { EmojiModal } from "../EmojiModal";
import Button from "../../common/Button";
import { WidgetMetrics } from "../../analytics/widgets/Metrics";
import HealthDials from "../../analytics/HealthDials";
import { ModalTitle } from "../styles";
import Metric from "./Metric";

const SocialHealthModal = ({ health = {}, closeModal }) => {
  const handleDontShow = () => {
    const storage = window.localStorage;

    if (storage) {
      storage.setItem("skip_social_health_modal", true);
    }
    closeModal();
  };

  return (
    <EmojiModal maxWidth={840} title="" emoji="🎊" onClose={closeModal}>
      <ModalTitle>
        Amazing! <br /> Your post has been scheduled
      </ModalTitle>
      <Flex
        flexDirection="column"
        alignItems="center"
        width="100%"
        marginY={24}
      >
        <HealthDials data={health.after} previous={health.before} />
        <WidgetMetrics flex="1" maxWidth="312px" width="100%">
          <Metric
            metric={"contentMix"}
            value={health.after.contentMix}
            label={"Content Mix"}
            description={
              "Content mix is based on the variety of content categories you have posted in the last two weeks, this week and next week."
            }
          />
          {health.after.employeeEngagement !== null && (
            <Metric
              metric={"employeeEngagement"}
              value={health.after.employeeEngagement}
              label={"Employee Eng."}
              description={
                "Employee engagement is calculated on how often your connected employees (LinkedIn Profiles) posted this month."
              }
            />
          )}
        </WidgetMetrics>
      </Flex>
      <Flex flexDirection="column" width="100%" maxWidth="400px">
        <Button
          variant="primary"
          flex="1"
          size="lg"
          mb={8}
          onClick={closeModal}
        >
          Continue
        </Button>
        <Button variant="secondary" size="lg" onClick={handleDontShow}>
          Don't show again
        </Button>
      </Flex>
    </EmojiModal>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(SocialHealthModal);

import { useSelector } from "react-redux";
import { useHistory, RouteComponentProps } from "react-router-dom";
import React, { useRef, useState, useEffect, useLayoutEffect } from "react";

import {
  today,
  endOfMonth,
  addDuration,
  getDateLabel,
  startOfMonth,
  parseStringToDate
} from "utils/date";
import {
  PostType,
  setPostType,
  setFocusedDate,
  ITimelineValues,
  useTimelineState,
  setDateToBeFocused,
  useTimelineDispatch,
  setDateRangeToBeFetched
} from "../context";
import MonthFeed from "./monthFeed";
import Loader from "components/common/loading/Loader";
import { WindowSize } from "contextApi/appContext/reducer";
import { FeedLoadingState, TimelineFeedWrapper } from "./styles";
import { useAppState } from "contextApi/appContext/useAppContext";

interface ITimelineFeed extends RouteComponentProps {}

const TimelineFeed: React.FC<ITimelineFeed> = ({ match }) => {
  const history = useHistory();
  const windowSize = useAppState(state => state.windowSize);
  const draftCount = useSelector<any, any>(state => state.draft.count);
  const pathPostType = match.path.split("/").reverse()[0] as PostType;
  const timelineRef = useRef<HTMLDivElement>(null);
  const focusedDateObserver = useRef<IntersectionObserver | null>(null);
  const scrollObserver = useRef<IntersectionObserver | null>(null);
  const timelineState = useTimelineState();
  const { postType, activities, focusedDate, dateToBeFocused } = timelineState;
  const timelineDispatch = useTimelineDispatch();
  const [dateToBeScrolledTo, setDateToBeScrolledTo] = useState<string | null>(
    null
  );
  const latestDateRefs: {
    current: ITimelineValues["dateRefs"]["scheduled"];
  } = { current: {} };

  const cleanObservers = () => {
    if (focusedDateObserver.current) {
      focusedDateObserver.current.disconnect();
    }
    if (scrollObserver.current) {
      scrollObserver.current.disconnect();
    }
  };

  useLayoutEffect(() => {
    cleanObservers();

    if (!!postType && Object.keys(timelineState.dateRefs[postType]).length) {
      latestDateRefs.current = { ...timelineState.dateRefs[postType] };
    }
  });

  useLayoutEffect(() => {
    if (windowSize !== WindowSize.LG && pathPostType !== "timeline") {
      const url = [...match.path.split("/").slice(0, -1), "timeline"].join("/");

      history.replace(url);
    }
  }, [
    history,
    postType,
    windowSize,
    match.path,
    pathPostType,
    timelineDispatch
  ]);

  useEffect(() => {
    if (pathPostType !== postType) {
      timelineDispatch(setPostType(pathPostType));
    } else if (pathPostType === postType) {
      timelineDispatch(
        setDateRangeToBeFetched({
          start: startOfMonth(today()),
          end: endOfMonth(today()),
          override: true
        })
      );

      setDateToBeScrolledTo(getDateLabel(today()));
    }
  }, [pathPostType, postType, timelineDispatch]);

  // Used for calendar date change
  useEffect(() => {
    if (dateToBeFocused) {
      setDateToBeScrolledTo(dateToBeFocused);
      timelineDispatch(setDateToBeFocused(null));
    }
  }, [dateToBeFocused, timelineDispatch]);

  // Used for changing focusedDate on scroll
  useEffect(() => {
    cleanObservers();

    if (!!focusedDate && postType === pathPostType) {
      // Observer for changing the focused date
      focusedDateObserver.current = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const newFocusDate = entry.target.getAttribute("data-date");

              if (!!newFocusDate && newFocusDate !== focusedDate) {
                timelineDispatch(setFocusedDate(newFocusDate));
              }
            }
          });
        },
        {
          root: timelineRef.current,
          rootMargin: "-20.5% 0px -79.4% 0px"
        }
      );

      // Observer for infinite scrolling
      scrollObserver.current = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const newFocusDate = entry.target.getAttribute("data-date");

              if (!!newFocusDate && newFocusDate !== focusedDate) {
                const dateOfMonth = parseInt(newFocusDate.slice(-2));
                const dateOfFocus = parseStringToDate(newFocusDate, "yyyy/M/d");
                if (dateOfMonth < 15) {
                  const prevMonthDate = addDuration(startOfMonth(dateOfFocus), {
                    months: -1
                  });

                  timelineDispatch(
                    setDateRangeToBeFetched({
                      start: startOfMonth(prevMonthDate),
                      end: endOfMonth(prevMonthDate)
                    })
                  );
                } else if (dateOfMonth > 15) {
                  const dateOfFocus = parseStringToDate(
                    newFocusDate,
                    "yyyy/M/d"
                  );
                  const nextMonthDate = addDuration(startOfMonth(dateOfFocus), {
                    months: postType === "published" ? -1 : 1
                  });

                  timelineDispatch(
                    setDateRangeToBeFetched({
                      start: startOfMonth(nextMonthDate),
                      end: endOfMonth(nextMonthDate)
                    })
                  );
                }
              }
            }
          });
        },
        {
          root: timelineRef.current
        }
      );

      Object.values(latestDateRefs.current)
        .sort((prev, next) => {
          if (prev.current && next.current) {
            const prevDate = prev.current?.getAttribute("data-date");
            const nextDate = next.current?.getAttribute("data-date");

            if (!!prevDate && !!nextDate && prevDate < nextDate) {
              return -1;
            } else if (!!prevDate && !!nextDate && prevDate > nextDate) {
              return 1;
            } else {
              return 0;
            }
          } else {
            return 0;
          }
        })
        .forEach((el, index) => {
          if (focusedDateObserver.current && el.current) {
            focusedDateObserver.current.observe(el.current);
          }
          if (
            (index === 0 ||
              (postType !== "published" &&
                index === Object.keys(latestDateRefs.current).length - 1)) &&
            scrollObserver.current &&
            el.current
          ) {
            scrollObserver.current.observe(el.current);
          }
        });
    }

    return () => {
      cleanObservers();
    };
  }, [focusedDate, postType, pathPostType, latestDateRefs, timelineDispatch]);

  // Used for scrolling
  useEffect(() => {
    if (timelineRef?.current && !!dateToBeScrolledTo) {
      const defaultFocusedDate = getDateLabel(today());

      if (latestDateRefs.current[dateToBeScrolledTo]?.current) {
        timelineRef.current.scrollTo({
          // behavior: "smooth",
          top:
            (latestDateRefs.current[dateToBeScrolledTo]?.current?.offsetTop ??
              0) - 20
        });
        setDateToBeScrolledTo(null);
      } else if (latestDateRefs.current[defaultFocusedDate]?.current) {
        timelineRef.current.scrollTo({
          // behavior: "smooth",
          top:
            (latestDateRefs.current[defaultFocusedDate]?.current?.offsetTop ??
              0) - 20
        });
        setDateToBeScrolledTo(null);
      }
    }
  }, [dateToBeScrolledTo, latestDateRefs, timelineDispatch]);

  return (
    <TimelineFeedWrapper
      ref={timelineRef}
      isPublishedView={postType === "published"}
    >
      {!postType || Object.keys(activities).length < 1 ? (
        <FeedLoadingState fullHeight={true}>
          <Loader color="#0061FF" type="spin" size={30} />
        </FeedLoadingState>
      ) : (
        <div className="content-wrapper">
          {postType === "drafts" && draftCount === 0 ? (
            <MonthFeed
              monthKey={getDateLabel(today()).slice(0, 7)}
              monthEntries={[]}
            />
          ) : (
            (postType === "published"
              ? Object.entries(activities).sort().reverse()
              : Object.entries(activities).sort()
            ).map(([key, value]: any) => (
              <div key={key}>
                {value[postType]?.entries && (
                  <MonthFeed
                    monthKey={key}
                    monthEntries={value[postType].entries}
                  />
                )}
              </div>
            ))
          )}
        </div>
      )}
    </TimelineFeedWrapper>
  );
};

export default TimelineFeed;

import { Flex } from "rebass";
import styled from "styled-components";

export const PanelWrapper = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const LabelContainer = styled(Flex)`
  flex-direction: row;
  gap: 9px;
  justify-content: center;
  align-items: center;
`;

export const Label = styled(Flex)`
  font-weight: 600;
  font-size: 13px;
  line-height: 125%;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const MinimizedIcon = styled.span`
  font-size: 18px;
`;

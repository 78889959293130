import styled, { css } from "styled-components";
import { Box, Flex } from "rebass/styled-components";

import { ModalHeader } from "../styles";
import { ModalTitle } from "components/common/styles";

export const ModalHeaderComposer = styled(ModalHeader)`
  margin-bottom: 0;
  padding: 9.5px 16px 9.5px 24px;
  border-radius: 14px 14px 0px 0px;
  border-bottom: #e5e7e9 1px solid;

  .action-btn {
    margin-right: 12px;

    > i {
      margin-right: 4px;
    }
  }
`;

export const ModalBodyComposer = styled(Box)`
  padding: 0px;
  overflow-x: auto;
  height: calc(95vh - 56px);
  box-sizing: border-box;

  ${props =>
    !!props.screenHeight &&
    props.proMode &&
    css`
      height: ${props.screenHeight}px;
    `};
  ${props =>
    !!props.screenHeight &&
    !props.proMode &&
    css`
      height: unset;
      max-height: ${props.screenHeight}px;
    `};
`;

export const ModalTitleComposer = styled(ModalTitle)`
  margin-bottom: 0;
`;

export const ModalHeaderActionContainer = styled(Flex)`
  margin-right: 28px;
  align-items: center;
`;

export const TimeDisplay = styled.span`
  font-weight: 600;
`;

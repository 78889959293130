// @ts-nocheck
import React from "react";

import {
  MenuList,
  MenuDivider,
  MenuContainer
} from "components/navigation/Menu";
import { MenuItem } from "./styles";

interface IPostGroupMenu {
  editPost: (e: React.MouseEvent) => void;
  deletePost: (e: React.MouseEvent) => void;
  ungroupPost: (e: React.MouseEvent) => void;
  duplicatePost: (e: React.MouseEvent) => void;
  postGroupToDraft: (e: React.MouseEvent) => void;
}

const PostGroupMenu: React.FC<IPostGroupMenu> = ({
  editPost,
  deletePost,
  ungroupPost,
  // duplicatePost,
  postGroupToDraft
}) => {
  return (
    <MenuContainer width={240}>
      <MenuList>
        <MenuItem label="Edit post" icon="edit" onClick={editPost} />
        <MenuItem label="Ungroup post" icon="ungroup" onClick={ungroupPost} />
        {/* <MenuItem label="Duplicate post" icon="copy" onClick={duplicatePost} /> */}
      </MenuList>
      <MenuDivider my={2} />
      <MenuList>
        <MenuItem
          icon="draft"
          label="Turn into draft"
          onClick={postGroupToDraft}
        />
      </MenuList>
      <MenuDivider my={2} />
      <MenuList>
        <MenuItem
          color="danger"
          onClick={deletePost}
          icon="delete"
          label="Delete post"
        />
      </MenuList>
    </MenuContainer>
  );
};

export default PostGroupMenu;

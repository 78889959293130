import { EditorState, Modifier, SelectionState } from "draft-js";
import { getSelectedBlock } from "draftjs-utils";

export const replaceText = (editorState, selection, oldText, newText) => {
  let contentState = editorState.getCurrentContent();
  const originalSelectionBefore = contentState.getSelectionBefore();
  const originalSelection = editorState.getSelection();

  contentState = Modifier.replaceText(contentState, selection, newText);

  // If current selection is after the replaced range of text, offset it
  // accordingly to the length of text we've added/removed
  let newSelection = originalSelection;

  const isSelectionInTargetBlock =
    originalSelection.getAnchorKey() === selection.getAnchorKey();

  const isCurrentSelectionAfterReplacedRange =
    originalSelection.getAnchorOffset() >= selection.getFocusOffset();

  if (isSelectionInTargetBlock && isCurrentSelectionAfterReplacedRange) {
    const offset = oldText.length - newText.length;

    newSelection = newSelection.merge({
      anchorOffset: originalSelection.getAnchorOffset() - offset,
      focusOffset: originalSelection.getFocusOffset() - offset
    });
  }

  // Set selectionBefore/After for proper undo/redo behavior.
  contentState = contentState.merge({
    selectionBefore: originalSelectionBefore,
    selectionAfter: newSelection
  });

  /**
   * Instead of using EditorState.push() which normally should be used for pushing
   * new actions to the editor, we update the editor's state directly in order
   * to keep the undo stack as is. The original SelectionState is also preserved
   * for the process to be completely seamless to users, and lastChangeType is
   * updated to 'apply-text' so that future keystrokes/changes are considered
   * boundary states for undo/redo.
   */
  return EditorState.set(editorState, {
    currentContent: contentState,
    lastChangeType: "apply-text",
    selection: newSelection
  });
};

export const createSelectionFromRange = (blockKey, range) => {
  return new SelectionState({
    anchorKey: blockKey,
    anchorOffset: range.start,
    focusKey: blockKey,
    focusOffset: range.end
  });
};

export const getEntityRange = (editorState, entityKey, blockKey) => {
  const block = blockKey
    ? editorState.getCurrentContent().getBlockForKey(blockKey)
    : getSelectedBlock(editorState);
  if (!block) {
    return;
  }
  let entityRange;
  block.findEntityRanges(
    value => value.get("entity") === entityKey,
    (start, end) => {
      entityRange = {
        start,
        end,
        text: block.get("text").slice(start, end)
      };
    }
  );
  return entityRange;
};

export const getEntityBlocks = (editorState, entityKey) => {
  const contentState = editorState.getCurrentContent();
  let entityBlocks = [];
  contentState.getBlockMap().forEach(contentBlock => {
    contentBlock.findEntityRanges(
      value => value.get("entity") === entityKey,
      (start, end) => {
        entityBlocks.push(contentBlock);
      }
    );
  });
  return entityBlocks;
};

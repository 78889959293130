import React from "react";

import {
  MenuList,
  MenuContainer,
  MenuItemContainer
} from "components/navigation/Menu";
import { MenuEntry } from "./styles";
import { Status, STATUS_PROPERTIES } from "./constants";

interface IMenu {
  status: Status;
  onStatusSelected: (value: Status) => void;
}

const Menu: React.FC<IMenu> = ({ status, onStatusSelected }) => (
  <MenuContainer minWidth={280}>
    <MenuList>
      {Object.entries(STATUS_PROPERTIES).map(
        ([key, { icon, fontColor, description }]) => {
          const isSelected = status === (key as Status);

          return (
            <MenuItemContainer>
              <MenuEntry
                key={key}
                selected={isSelected}
                fontColor={fontColor}
                onClick={() => onStatusSelected(key as Status)}
              >
                <div className="status">
                  <i className={icon} />
                  <div className="label">
                    <div className="title">{key}</div>
                    {description && (
                      <div className="description">{description}</div>
                    )}
                  </div>
                </div>
                {isSelected && <i className="icon-check" />}
              </MenuEntry>
            </MenuItemContainer>
          );
        }
      )}
    </MenuList>
  </MenuContainer>
);

export default Menu;

// Hook
import { useRef, useState, useEffect, useCallback } from "react";

import { SIZES } from "../theme";

const useWindowSize = () => {
  const timeoutRef = useRef(null);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    size: undefined
  });

  const getSize = useCallback(width => {
    for (const [size, dim] of Object.entries(SIZES)) {
      if (width <= dim.max) {
        return size;
      }
    }
  }, []);

  // Handler to call on window resize
  const handleResize = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        size: getSize(window.innerWidth)
      });
    }, 250);
  }, [getSize]);

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export default useWindowSize;

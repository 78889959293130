import styled, { css } from "styled-components";
import { Flex, Button } from "rebass/styled-components";

import BaseButton from "components/common/Button";
import iconTwitter from "assets/images/icon-twitter.png";
import iconFacebook from "assets/images/icon-facebook.png";
import iconLinkedIn from "assets/images/icon-linkedIn-circ.png";
import iconInstagram from "assets/images/icon-instagram-132.png";
import CustomizeIcon from "assets/images/composer/customize.png";

export const CaptionAreaWrapper = styled(Flex)`
  height: 100%;
  flex-direction: column;
`;

export const CustomizedCaptionTabs = styled(Flex)`
  height: 36px;
  padding: 2px;
  margin-bottom: 16px;
  border-radius: 24px;
  box-sizing: border-box;
  max-width: fit-content;
  background-color: ${props => props.theme.colors.grey01};

  .button-content {
    display: flex;
    align-items: center;
  }

  .service-name {
    margin-left: 6px;
  }
`;

export const PillButton = styled(BaseButton).attrs(props => ({
  size: "sm",
  variant: props.isActive ? "tertiary" : "secondary"
}))`
  line-height: 18px;
  border-radius: 16px;

  ${props =>
    !props.isActive
      ? css`
          padding: 6px 8px 6px 8px;
          background-color: transparent;
          color: ${props.theme.colors.text03};

          &:hover {
            color: ${props.theme.colors.text01};
          }
        `
      : css`
          background-color: white;
          padding: 6px 12px 6px 8px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
          color: ${props => props.theme.colors.text01};

          &:hover {
            background-color: white;
          }
        `};
`;

export const CaptionTab = styled.div`
  background-color: ${props => (props.selected ? "#dee8fb" : "white")};
  color: ${props => (props.selected ? "#1e62e0" : "#606770")};
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  padding: 4px 8px;
  cursor: pointer;
  margin-right: 16px;
`;

export const CaptionFooter = styled(Flex)`
  padding-bottom: 10px;
  padding-top: 14px;
  justify-content: flex-end;
`;

export const CustomizeButton = styled(Button)`
  cursor: pointer;
  color: #8d9497;
  font-weight: normal;
  font-size: 12px;
  background: url("${CustomizeIcon}") no-repeat transparent;
  background-size: 20px;
  height: 20px;
  padding: 0;
  padding-left: 28px;
`;

export const ChannelIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background: ${props => {
    switch (props.channel.toUpperCase()) {
      case "TWITTER":
        return `url(${iconTwitter})`;
      case "FACEBOOK":
        return `url(${iconFacebook})`;
      case "LINKEDIN":
        return `url(${iconLinkedIn})`;
      case "INSTAGRAM":
        return `url(${iconInstagram})`;
      default:
        return `url("/managers/none.png")`;
    }
  }};
  background-size: contain;
`;

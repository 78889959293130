import styled from "styled-components";

import { Headline6, Body6 } from "components/common/styles";
import socialMediaGroup from "assets/images/social/social-media-group.png";

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 347px;
`;

export const BannerImage = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 28px;
  background: url(${socialMediaGroup}) no-repeat center center;
  background-size: contain;
  margin-bottom: 24px;
`;

export const Title = styled(Headline6)`
  width: 100%;
  text-align: center;
`;

export const Subtitle = styled(Body6)`
  text-align: center;
  width: 100%;
  margin: 5px 0 16px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

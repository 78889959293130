import { Box, Flex } from "rebass";
import styled from "styled-components";
import { useToaster } from "@hellocontento/maillard";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  useNewsState,
  setSearchAction,
  useNewsDispatch
} from "contextApi/newsContext";
import Button from "../common/Button";
import ContentItem from "./ContentItem";
import { EmptyImageWrapper } from "./styles";
import EmptyState from "../common/EmptyState";
import Divider from "components/common/Divider";
import { searchArticlesByOptions } from "services/news";
import ArticleEmpty from "assets/images/contents/article-empty.png";

const TotalResultLabel = styled.span`
  color: ${props => props.theme.colors.text03};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
`;

const ContentSearch = React.memo(() => {
  const addToast = useToaster();
  const dispatch = useNewsDispatch();
  const [isLoading, setLoading] = useState(true);
  const searchValue = useNewsState(state => state.search.searchValue);
  const selectedTopics = useNewsState(state => state.search.selectedTopics);
  const selectedLanguages = useNewsState(
    state => state.search.selectedLanguages
  );

  const [searchResult, setSearchResult] = useState({
    records: [],
    tags: [],
    total: 0,
    page: 1
  });

  const refreshSearchResults = useCallback(async () => {
    if (!searchValue) {
      setSearchResult({
        records: [],
        tags: [],
        total: 0,
        page: 1
      });
      setLoading(false);
      return;
    }
    setLoading(true);
    try {
      const tags =
        selectedTopics.length > 0
          ? selectedTopics.map(topic => topic.toLowerCase()).join(",")
          : null;

      const response = await searchArticlesByOptions({
        text: searchValue,
        tags,
        language: selectedLanguages?.length
          ? selectedLanguages.map(language => language.value)
          : undefined,
        limit: 20,
        page: 1
      });

      setSearchResult(s => ({
        ...s.searchResult,
        records: response.records,
        tags: response.facets.predicted_tags,
        total: response.totalRecords
      }));

      dispatch(
        setSearchAction({
          topics: response.facets.predicted_tags
        })
      );

      setLoading(false);
    } catch (e) {
      setLoading(false);
      addToast(e.message, "error");
    }
  }, [searchValue, selectedTopics, selectedLanguages, addToast, dispatch]);

  const handleLoadMore = useCallback(async () => {
    try {
      const tags =
        selectedTopics.length > 0
          ? selectedTopics.map(topic => topic.toLowerCase()).join(",")
          : null;

      const response = await searchArticlesByOptions({
        text: searchValue,
        tags,
        language: selectedLanguages?.length
          ? selectedLanguages.map(language => language.value)
          : undefined,
        limit: 20,
        page: searchResult.page + 1
      });

      setSearchResult(result => ({
        ...result,
        records: [...searchResult.records, ...response.records],
        page: searchResult.page + 1
      }));
    } catch (e) {
      addToast(e.message, "error");
    }
  }, [
    addToast,
    searchValue,
    selectedTopics,
    searchResult.page,
    selectedLanguages,
    searchResult.records
  ]);

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      refreshSearchResults();
    }, 300);

    return () => clearTimeout(searchDelay);
  }, [refreshSearchResults]);

  const emptyStateMessage = useMemo(
    () =>
      !searchValue
        ? {
            header: "You can search for articles",
            description: "Please enter search terms to find related articles."
          }
        : {
            header: "We couldn’t find any articles",
            description:
              "Please check your spelling or try again with different search terms."
          },
    [searchValue]
  );

  return (
    <Box flex={"1"}>
      {isLoading || searchResult.records?.length === 0 ? (
        <EmptyState
          loading={isLoading}
          center={false}
          image={<EmptyImageWrapper src={ArticleEmpty} />}
          {...emptyStateMessage}
        />
      ) : (
        <>
          <Box maxWidth={928} mx={"auto"}>
            <TotalResultLabel>
              {searchResult?.total} results found
            </TotalResultLabel>
            <Divider my={2} />
            {searchResult?.records?.map(item => (
              <ContentItem
                key={item.id}
                content={item}
                orientation={"landscape"}
                thumbnailHeight={192}
              />
            ))}
          </Box>
          {searchResult.total > searchResult.records.length && (
            <Flex justifyContent={"center"}>
              <Button size={"lg"} variant={"primary"} onClick={handleLoadMore}>
                View more articles
              </Button>
            </Flex>
          )}
        </>
      )}
    </Box>
  );
});

export default ContentSearch;

import qs from "qs";
import config from "../config";

export const CHANNEL_SERVICES = [
  "linkedin_business",
  "linkedin_profile",
  "facebook_page",
  "instagram_business",
  "twitter_profile"
];

export const connectChannel = (account, service, redirect) => {
  const [serviceName, serviceType] = service.split("_");

  if (service === "facebook_page") {
    window.location = `${config.apiUrl}/accounts/${account.id}/connect/facebook`;
  }

  const connectCallback = {
    facebook_page: "connect/facebook-page",
    linkedin_business: "connect/linkedin-business",
    instagram_business: "connect/instagram-business"
  };

  const defaultQueryParams = (() => {
    try {
      return qs.stringify(
        {
          ...(qs.parse(new URL(redirect).search, {
            ignoreQueryPrefix: true
          }) ?? {}),
          channelType: service
        },
        {
          addQueryPrefix: true
        }
      );
    } catch (_) {
      return qs.stringify(
        {
          channelType: service
        },
        {
          addQueryPrefix: true
        }
      );
    }
  })();

  let redirectUrl = redirect
    ? `${new URL(redirect).origin}${
        new URL(redirect).pathname
      }${defaultQueryParams}`
    : `${config.rootUrl}/accounts/${account.id}/connect/profile${defaultQueryParams}`;

  if (connectCallback[service]) {
    redirectUrl = `${config.rootUrl}/accounts/${account.id}/${
      connectCallback[service]
    }${qs.stringify({ redirect: redirectUrl }, { addQueryPrefix: true })}`;
  }

  console.log("Debugging:: ", redirectUrl);

  const queryParams = qs.stringify(
    {
      redirect: redirectUrl,
      serviceType: serviceType
    },
    { addQueryPrefix: true }
  );
  window.location = `${config.apiUrl}/accounts/${account.id}/connect/${serviceName}${queryParams}`;
};

export const signInWithLinkedin = (redirect, signup) => {
  const queryParams = qs.stringify(
    {
      redirect,
      signup,
      guest: true
    },
    { addQueryPrefix: true }
  );
  window.location = `${config.apiUrl}/connect/linkedin-profile-guest${queryParams}`;
};

export const openPostOnChannel = post => {
  const url = getExternalPostUrl(post);
  if (url) {
    window.open(url, "_blank");
  }
};

export const getChannelConnectedRedirectPath = (account, redirect) => {
  const url = new URL(
    redirect || `/accounts/${account.id}/settings/channels`,
    config.rootUrl
  );
  return url.pathname + url.search;
};

export const getExternalPostUrl = post => {
  let url = null;

  switch (post.channel.service) {
    case "twitter":
      url = `https://twitter.com/${post.channel.username}/status/${post.sourceId}`;
      break;
    case "facebook":
      const [, postId] = post.sourceId.split("_");
      url = `https://www.facebook.com/${postId}`;
      break;
    case "linkedin":
      url = `https://www.linkedin.com/feed/update/${post.sourceId}`;
      break;
    case "instagram":
      url = `https://www.instagram.com/${post.channel.username}`;
      break;
    default:
      break;
  }
  return url;
};

import React from "react";

import {
  EntryContent,
  EntryWrapper,
  EmptyEntryCover,
  EmptyContentBlock
} from "./styles";
import { today } from "utils/date";
import Button from "components/common/Button";
import { useComposerActions } from "contextApi/composerContext";
import { useTimelineState } from "components/schedule/timeline/context";

const EmptyDayCard: React.FC<{}> = () => {
  const { postType } = useTimelineState();
  const createPostByDate = useComposerActions(
    actions => actions.createPostByDate
  );
  const createTask = useComposerActions(actions => actions.createTask);

  return (
    <EntryWrapper noHover>
      <EntryContent>
        <EmptyEntryCover postType={postType} />
        <EmptyContentBlock>
          <div className="empty-title">
            {postType === "drafts"
              ? `No drafts created yet.`
              : `You haven't planned anything.`}
          </div>
          <div className="empty-subtitle">
            {postType === "drafts"
              ? `As soon as you start creating drafts they will appear here.`
              : `As soon as you start scheduling social media posts they will appear here.`}
          </div>
          <div className="button-grp">
            {postType === "drafts" ? (
              <>
                {/* @ts-ignore */}
                <Button
                  variant="primary"
                  onClick={() => createPostByDate(today(), true)}
                >
                  Write draft
                </Button>
              </>
            ) : (
              <>
                {/* @ts-ignore */}
                <Button
                  variant="primary"
                  onClick={() => createPostByDate(today())}
                >
                  Write post
                </Button>
                {/* @ts-ignore */}
                <Button variant="secondary" onClick={() => createTask(today())}>
                  Create task
                </Button>
              </>
            )}
          </div>
        </EmptyContentBlock>
      </EntryContent>
    </EntryWrapper>
  );
};

export default EmptyDayCard;

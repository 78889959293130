import React from "react";
import SeeMore from "components/common/seeMore";

import { HeaderWrapper, IconWrapper, Header, HeaderTitle } from "./styles";

interface IDashboardSectionHeader {
  title: string;
  iconClass?: string;
  buttonText?: string;
  hideSeeMore?: boolean;
  handleSeeMore?: VoidFunction;
}

const DashboardSectionHeader: React.FC<IDashboardSectionHeader> = ({
  title,
  iconClass,
  buttonText,
  hideSeeMore,
  handleSeeMore
}) => {
  return (
    <HeaderWrapper>
      <Header>
        {iconClass && (
          <IconWrapper>
            <i className={iconClass} />
          </IconWrapper>
        )}
        <HeaderTitle onClick={handleSeeMore}>{title}</HeaderTitle>
      </Header>
      {!hideSeeMore && handleSeeMore && (
        <SeeMore handleClick={handleSeeMore} text={buttonText} />
      )}
    </HeaderWrapper>
  );
};

export default DashboardSectionHeader;

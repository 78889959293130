import styled from "styled-components";
import { Box } from "rebass/styled-components";

export const Header = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
`;

export const HeaderIconWrapper = styled.span`
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
`;

export const Description = styled.div`
  display: flex;
  padding: 0px 10px;
  align-items: center;
  align-self: stretch;

  p {
    margin: 0;
    padding: 0;
    flex: 1 0 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    font-style: normal;
    letter-spacing: -0.16px;
    color: ${props => props.theme.colors.black};
  }
`;

export const Footer = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

export const HelpMenuWrapper = styled.div`
  i {
    font-size: 20px;
    margin-right: 6px;
    color: ${props => props.theme.colors.black};
  }
`;

export const MenuContainer = styled(Box)`
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  width: auto;
  min-width: 200px;
  max-width: 400px;
  transition: 10s height;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.grey03};
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.shadowM};

  > div {
    width: 100%;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  > button {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
`;

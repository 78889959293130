import { all, fork } from "redux-saga/effects";

import authSaga from "./AuthSaga";
import signupSaga from "./SignupSaga";
import accountSaga from "./AccountSaga";
import scheduledPostSaga from "./ScheduledPostSaga";
import analyticsSaga from "./AnalyticsSaga";

export default function* root() {
  yield all([
    fork(authSaga),
    fork(signupSaga),
    fork(accountSaga),
    fork(scheduledPostSaga),
    fork(analyticsSaga)
  ]);
}

import React from "react";

import { useQueryParams } from "utils/useQueryParams";

import { Search } from "styled-icons/boxicons-regular";
import EmptyState from "components/common/EmptyState";

const CONTEXT_MAP: any = {
  topics: "topics",
  domains: "websites",
  "twitter-sources": "accounts",
  "rss-sources": "RSS"
};

const EmptySearchState: React.FC<{}> = () => {
  const queryParams = useQueryParams();

  const { type, text } = queryParams;

  return (
    // @ts-ignore
    <EmptyState
      header={
        text !== ""
          ? `No ${CONTEXT_MAP[String(type)] ?? "results"} for "${text}"`
          : "Start your search"
      }
      description={
        text !== ""
          ? "Check the spelling or try again with broader terms"
          : "You can search for topics, keywords related to topics or influencers and publishers on X."
      }
      icon={{
        component: Search,
        width: "24px",
        height: "24px"
      }}
    />
  );
};

export default EmptySearchState;

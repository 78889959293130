export const regionOptions = [
  {
    language: "nl",
    region: "be",
    label: "Dutch 🇧🇪",
    enabled: false
  },
  {
    language: "nl",
    region: "nl",
    label: "Dutch 🇳🇱",
    enabled: false
  },
  {
    language: "en",
    region: "us",
    label: "English 🇺🇸",
    enabled: false
  },
  {
    language: "en",
    region: "gb",
    label: "English 🇬🇧",
    enabled: false
  }
];

import React from "react";
import ReactLoading from "react-loading";
import { Flex } from "rebass/styled-components";

const Loader = () => {
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ gap: "10px" }}
      flexDirection="column"
      my={24}
    >
      <ReactLoading type={"spin"} color={"#bbb"} width={24} height={24} />
      <div>Loading Content...</div>
    </Flex>
  );
};

export default Loader;

import React from "react";
import ReactLoading from "react-loading";

import {
  EmptyTitle,
  EmptyBody,
  ContentWrapper,
  EmptyContent,
  LoadingContent
} from "./styles";
import ContentItem from "./ContentItem";

function ContentList({
  items,
  currentTab = "for-you",
  onScheduled,
  onDismiss,
  onSaveChange,
  type,
  sourceId,
  viewOnly = false
}) {
  if (items === null) {
    return (
      <LoadingContent>
        <ReactLoading color="#bbb" type="cylon" />
      </LoadingContent>
    );
  }

  const messages = {
    title: {
      new: "No new suggestions",
      saved: "No saved articles"
    },
    body: {
      new: "You have reviewed all our suggestions. You will receive new suggestions soon. Please contact your account manager if you have any question.",
      saved:
        "Bookmarks let you give articles a special status to use them for later. To save an article, click on the Save icon on any article."
    }
  };

  if (items.length === 0) {
    return (
      <EmptyContent type={type}>
        <EmptyTitle>{messages.title[type]}</EmptyTitle>
        <EmptyBody>{messages.body[type]}</EmptyBody>
      </EmptyContent>
    );
  }

  return (
    <>
      <ContentWrapper>
        {items.map(item => {
          return (
            <ContentItem
              key={item.id}
              content={item}
              currentTab={currentTab}
              onScheduled={onScheduled}
              onDismiss={onDismiss}
              onSaveChange={onSaveChange}
              sourceId={sourceId}
              viewOnly={viewOnly}
            />
          );
        })}
      </ContentWrapper>
    </>
  );
}

export default ContentList;

import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import { Flex } from "rebass/styled-components";

const TabMenuContainer = styled(Flex)`
  height: 100%;
  box-sizing: border-box;
  gap: 10px;
  padding: 15px 0px;

  * {
    box-sizing: border-box;
  }
`;

interface ITab {
  "data-active": boolean;
}

const Tab = styled(Link)<ITab>`
  appearance: none;
  background: ${props =>
    props["data-active"]
      ? props.theme.colors.black
      : props.theme.colors.grey02};
  border: 1px solid
    ${props =>
      props["data-active"]
        ? props.theme.colors.text03
        : props.theme.colors.grey02};
  border-radius: 25px;
  box-shadow: none;
  color: ${props =>
    props["data-active"] ? props.theme.colors.white : props.theme.colors.black};
  cursor: pointer;
  font-family: ${props => props.theme.fonts.body};
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.08px;
  overflow: hidden;
  outline: none;
  padding: 10px 20px;
  position: relative;

  &:hover,
  &:focus {
    color: ${props =>
      props["data-active"]
        ? props.theme.colors.white
        : props.theme.colors.black};
  }
`;

export interface ITabOption {
  label: string;
  to: string;
}

interface ITabMenu {
  options: ITabOption[];
}

const TabMenu: React.FC<ITabMenu> = ({ options }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <TabMenuContainer>
      {options.map(option => (
        <Tab
          to={option.to}
          key={option.to}
          data-active={window.location.pathname.endsWith(option.to)}
        >
          {option.label}
        </Tab>
      ))}
    </TabMenuContainer>
  );
};

export default TabMenu;

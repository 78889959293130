import React from "react";
import { connect } from "react-redux";
import ComposerModal from "./composerModal";
import SocialHealthModal from "./socialHealthModal";
import CheckoutSuccessModal from "./checkoutSuccessModal";
import IdeaDetailModal from "./ideaDetailModal";
import PostDetailModal from "./postDetailModal";
import SelectFacebookPageModal from "./selectFacebookPageModal";
import SelectInstagramBusinessModal from "./selectInstagramBusinessModal";
import SelectLinkedInBusinessModal from "./selectLinkedInBusinessModal";
import ConfirmationModal from "./confirmationModal";
import ChannelReconnectModal from "./channelReconnectModal";
import AddRSSFeedModal from "./addRSSFeedModal";

import SetupAccountModal from "./setupAccountModal";
import OnboardingInfoModal from "./onboardingInfoModal";

const MODAL_COMPONENTS = {
  COMPOSER_MODAL: ComposerModal,
  SOCIAL_HEALTH_MODAL: SocialHealthModal,
  CHANNEL_RECONNECT_MODAL: ChannelReconnectModal,
  CHECKOUT_SUCCESS_MODAL: CheckoutSuccessModal,
  IDEA_DETAIL_MODAL: IdeaDetailModal,
  POST_DETAIL_MODAL: PostDetailModal,
  SELECT_INSTAGRAM_BUSINESS_MODAL: SelectInstagramBusinessModal,
  SELECT_FACEBOOK_PAGE_MODAL: SelectFacebookPageModal,
  SELECT_LINKEDIN_BUSINESS_MODAL: SelectLinkedInBusinessModal,
  CONFIRMATION_MODAL: ConfirmationModal,
  ADD_RSS_FEED: AddRSSFeedModal,
  ONBOARDING_INFO_MODAL: OnboardingInfoModal,
  SETUP_ACCOUNT_MODAL: SetupAccountModal
};

/*
 Takes a modalType and modalProps to dynamically return the
 modal component we imported above
 */
const modalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};

const mapStateToProps = state => ({
  modalProps: state.modals.modalProps,
  modalType: state.modals.modalType
});

export default connect(mapStateToProps)(modalRoot);

import React from "react";
import styled from "styled-components";
import { Box } from "rebass";

const TitleInputContainer = styled(Box)`
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 14px;
`;

const TitleTaskCircle = styled.span`
  border-radius: 16px;
  box-shadow: inset 0 0 0 1px ${props => props.theme.colors.text03};
  display: flex;
  height: 18px;
  margin: 2px;
  width: 18px;
  justify-content: center;
  align-items: center;
`;

const TitleInput = styled.input`
  appearance: none;
  border: none;
  box-shadow: none;
  outline: none;
  padding: 0;
  font-family: ${props => props.theme.fonts.body};
  font-size: 18px;
  font-weight: 600;
  color: ${props => props.theme.colors.text01};

  &::placeholder {
    color: ${props => props.theme.colors.text04};
  }
`;

function ScheduleTitleInput({ ...restProps }) {
  return (
    <TitleInputContainer>
      <TitleTaskCircle className="icon-check"></TitleTaskCircle>
      <TitleInput autoFocus placeholder={"Write a title"} {...restProps} />
    </TitleInputContainer>
  );
}

export default ScheduleTitleInput;

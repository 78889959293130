import React, { RefObject, useRef, useState } from "react";
import Hls from "hls.js";

import {
  PostAttachmentVideo,
  AttachmentVideo,
  PostVideo,
  VideoOverlay,
  VideoPlay
} from "./styles";
import { useToaster } from "@hellocontento/maillard";
import { IVideoAttachment } from "@hellocontento/contento-common";

const PostAttachmentVideoComponent = function ({
  attachment,
  height,
  videoLess = false
}: {attachment: IVideoAttachment, height?: number, videoLess?: boolean}) {
  const videoRef = useRef<HTMLVideoElement|null>(null);
  const [readyToPlay, setReadyToPlay] = useState(false);

  const addToast = useToaster();

  function play() {
    if (videoRef.current) {
      videoRef.current.controls = true;
      videoRef.current.play().catch(() => {
        addToast(
          "There was an error trying to play the video. Please try again later or contact support.",
          "error"
        );
      });
    }
  }

  function handlePlay(e) {
    e.stopPropagation();
    if (!videoRef.current) {
      addToast("The video is loading, please wait.");
      return;
    }

    if (attachment.isStream && Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(attachment.url);
      hls.attachMedia(videoRef.current);
      hls.on(Hls.Events.MANIFEST_PARSED, play);
    } else {
      play();
    }

    setReadyToPlay(true);
  }

  if (!attachment.url && attachment.thumbnail) {
    // twitter api v2 onl returns the video's thumbnail
    // so we show the thumbnail but send the user to twitter on click

    return (
      <PostAttachmentVideo>
        <AttachmentVideo $height={height}>
          <VideoOverlay onClick={() => {}}>
            <VideoPlay />
          </VideoOverlay>
          <PostVideo width={300} height={170} poster={attachment.thumbnail} />
        </AttachmentVideo>
      </PostAttachmentVideo>
    );
  }

  return (
    <PostAttachmentVideo>
      <AttachmentVideo $height={height}>
        {!readyToPlay && (
          <VideoOverlay onClick={!videoLess ? handlePlay : () => {}}>
            <VideoPlay />
          </VideoOverlay>
        )}
        <PostVideo width={300} height={170} ref={videoRef}>
          {<source src={attachment.url} type={"video/mp4"} />}
        </PostVideo>
      </AttachmentVideo>
    </PostAttachmentVideo>
  );
};

export default PostAttachmentVideoComponent;

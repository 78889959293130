import styled from "styled-components";

import iconTwitter from "assets/images/icon-twitter.png";
import iconFacebook from "assets/images/icon-facebook.png";
import iconLinkedIn from "assets/images/icon-linkedIn-circ.png";
import iconInstagram from "assets/images/icon-instagram-132.png";

export const ChannelWrapper = styled.div`
  display: flex;
  padding: 4px;
  height: 33px;
  align-items: center;
  background: ${props =>
    props.checked ? "#e6efff" : "rgba(96, 116, 128, 0.08)"};
  border-radius: 18px;
  position: relative;
  cursor: pointer;

  &:hover {
    background: ${props =>
      props.checked ? "#cce0fe" : "rgba(102, 119, 128, 0.2)"};
  }
`;

export const Avatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  background: url("/managers/none.png");
  background-size: contain;
  border: 1px solid #d4d7d8;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
  }
`;

export const ChannelIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  flex-shrink: 0;
  overflow: hidden;
  border: 1px solid #ffffff;
  position: absolute;
  top: 14px;
  left: 22px;
  background: ${props => {
    switch (props.channel.toUpperCase()) {
      case "TWITTER":
        return `url(${iconTwitter})`;
      case "FACEBOOK":
        return `url(${iconFacebook})`;
      case "LINKEDIN":
        return `url(${iconLinkedIn})`;
      case "INSTAGRAM":
        return `url(${iconInstagram})`;
      default:
        return `url("/managers/none.png")`;
    }
  }};
  background-size: contain;
`;

export const ChannelUsername = styled.div`
  display: flex;
  flex-grow: 1;
  min-width: 0px;
  margin-right: 10px;
  justify-content: space-between;

  span {
    margin: 0px 11px 0 11px;
    font-weight: 500;
    font-size: 13px;
    line-height: 1.3;
    display: block;
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
  }

  img {
    width: 12px;
    height: auto;
    visibility: ${props => (props.checked ? "visibile" : "hidden")};
  }
`;

import styled from "styled-components";
import { Flex, Box, Text } from "rebass/styled-components";

import xIcon from "../../assets/images/x.png";
import searchIcon from "../../assets/images/search-icon.png";
import plusIcon from "../../assets/images/plus-symbol.png";
import { Headline5, SubSectionHeader } from "../common/styles";
import { StyledInput } from "../common/form/styles";

export const TextInput = styled.input`
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  width: 280px;
  padding: 6px 12px;
  color: ${props => props.theme.colors.black};
  font-size: 14px;
  line-height: 20px;
  margin-right: 5px;
  &:focus {
    border: #0061ff 1px solid;
    outline: none;
  }
  &::placeholder {
    color: #a8acb9;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints[3]}) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const InfluencerList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 80px;
`;

export const InfluencerItem = styled.li`
  padding: 8px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #b9bbbd;
  line-height: 14px;
  display: flex;
  margin-bottom: 8px;
  width: 47%;
  min-width: 280px;
  flex-shrink: 0;
  flex-grow: 0;
  @media screen and (max-width: ${props => props.theme.breakpoints[3]}) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const InfluencerInfo = styled(Box)`
  margin-left: 17px;
  flex-grow: 1;
`;

// common title style
const titleStyle = `
  font-size: 16px;
  line-height: 19px;
  padding-top: 2px;
  display: block;
  font-weight: bold;
`;

export const InfluencerName = styled.a`
  color: ${props => props.theme.colors.black};
  ${titleStyle}
`;

export const InfluencerHandle = styled(Text)`
  font-size: 13px;
  line-height: 16px;
  display: block;
  color: ${props => props.theme.colors.lightGray};
`;

export const InfluencerRemoveButton = styled(Box)`
  height: 32px;
  width: 32px;
  background: url(${xIcon}) no-repeat center center;
  background-size: 8px 8px;
  border-radius: 8px;
  align-self: center;
  &:hover {
    background-color: #dbdde1;
  }
  cursor: pointer;
`;

export const SearchInput = styled.input`
  position: relative;
  z-index: 2;
  border-radius: 12px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background: ${props => props.theme.colors.grey01} url(${searchIcon}) no-repeat
    24px center;
  background-size: 16px 16px;
  padding: 12px 12px 12px 46px;
  color: ${props => props.theme.colors.black};
  font-size: 15px;
  line-height: 20px;
  border: none;
  margin-top: 4px;
  transition: width 0.5s;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${props => props.theme.colors.blue};
    outline: none;
  }

  &::placeholder {
    color: #9494a0;
  }
`;

export const SearchWrapper = styled(Box)`
  position: relative;
  margin-bottom: 36px;
`;

export const SearchSection = styled(Box)`
  position: absolute;
  top: 0;
  right: -12px;
  left: -12px;
  border-radius: 12px;
  padding: 12px;
  background: white;
  &.focused {
    box-shadow:
      0 1.2px 3.6px rgba(0, 0, 0, 0.108),
      0 6.4px 14.4px rgba(0, 0, 0, 0.132);
  }
`;

export const SwitchState = styled.span`
  display: inline-block;
  color: #888;
  font-size: 14px;
  margin-right: 8px;
  position: relative;
  top: -2px;

  &.on {
    color: ${props => props.theme.colors.blue};
  }
`;

export const CancelSearchButton = styled.button`
  position: absolute;
  z-index: 0;
  right: 12px;
  top: 27px;
  border: none;
  background: none;
  display: none;
  font-size: 15px;
  color: #646464;
  cursor: pointer;
  &.focused {
    display: block;
  }
`;

export const SearchResults = styled.ul`
  margin-top: 8px;
  overflow: hidden;
  height: 0;
  transition: height 0.5s;
  &.focused {
    height: auto;
  }
`;

export const AddButton = styled.div`
  display: none;
  color: ${props => props.theme.colors.blue};
  font-size: 16px;
  padding-left: 20px;
  padding-right: 8px;
  align-items: center;
  background: url(${plusIcon}) no-repeat left center;
  background-size: 12px 12px;
`;

export const SearchResult = styled(InfluencerItem)`
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  background: none;
  &:hover {
    background: #e9eef3;
  }
  &:hover ${AddButton} {
    display: flex;
  }
`;

export const StyledForm = styled.form`
  margin: 10px 16px;
  padding: 10px 10px;
`;

export const CTADisclaimer = styled.p`
  color: ${props => props.theme.colors.text01};
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 8px;
`;

export const PreviewContainer = styled(Flex)`
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.1),
    0 6px 18px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background-color: white;
  height: 100%;
  justify-content: center;
  padding: 16px;
`;
export const CTAContainer = styled(Flex)`
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 0 1px ${props => props.theme.colors.grey02};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: auto 1;
  max-width: 275px;
  width: 275px;
`;

export const CTAHeader = styled(Flex)`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #555555;
  margin: 24px 24px 0 24px;
`;
export const CTAContent = styled(Flex)`
  margin: 24px;
`;

export const CTATitle = styled(Text)`
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: #111111;
  margin-bottom: 8px;
`;

export const CTAParagraph = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.14px;
  color: #888888;
`;

export const CTAEmailInput = styled.input`
  margin-top: 20px;
  border: 1px solid #b9bbbd;
  border-radius: 4px;
  padding: 4px;
  line-height: 26px;
  font-size: 14px;
  ::placeholder {
    color: #999999;
  }
`;

export const CTAAction = styled.a`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #356af6;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.14px;
  color: #ffffff;
  :hover {
    color: #ffffff;
  }
`;

export const CTASubSectionHeader = styled(SubSectionHeader)`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const MyCTAHeader = styled(Headline5)`
  width: 50%;
`;

// Reuse influencername style
export const RssFeedURL = styled.span`
  color: ${props => props.theme.colors.black};
  ${titleStyle}
`;

export const RssFeedInput = styled(StyledInput)`
  &:disabled {
    color: #bbb;
  }
`;
export const RssFeedList = styled(InfluencerList)`
  margin-top: 20px;
  margin-bottom: 30px;
`;

export const RssFeedItem = styled(InfluencerItem)``;
export const RssFeedItemRemove = styled(InfluencerRemoveButton)``;
export const RssFeedTitle = styled(InfluencerHandle)``;
export const RssFeedInfo = styled(InfluencerInfo)``;

export const TopicList = styled(InfluencerList)`
  margin-top: 10px;
  margin-bottom: 35px;
`;

export const TopicInfo = styled(InfluencerInfo)``;
export const TopicItem = styled(InfluencerItem)``;
export const TopicName = styled(RssFeedURL)``;
export const TopicLanguage = styled(InfluencerHandle)``;

import {
  Context,
  useContext,
  createContext,
  useContextSelector
} from "use-context-selector";
import React, { useMemo, useEffect, useReducer, useCallback } from "react";

import {
  initialValues,
  IAnalyticsState,
  analyticsReducer,
  IAnalyticsDispatch
} from "./reducer";
import {
  setLinksDataAction,
  setLinksPeriodAction,
  setChannelsDataAction,
  setAllPostsDataAction,
  setAllPostsPeriodAction,
  setChannelsPeriodAction,
  setLinksIsLoadingAction,
  setLinksChannelIdsAction,
  setLinksCategoriesAction,
  setAllPostsIsLoadingAction,
  setChannelsIsLoadingAction,
  setAllPostsChannelIdsAction,
  setChannelsChannelIdsAction,
  setAllPostsCategoriesAction,
  setRepurposePostsDataAction,
  setRepurposePostsPeriodAction,
  setChannelsEmployeesDataAction,
  setChannelsPerChannelDataAction,
  setRepurposePostsIsLoadingAction,
  setRepurposePostsChannelIdsAction,
  setRepurposePostsCategoriesAction,
  setRepurposePostsEngagementRateAction,
} from "./actionCreators";
import { useSelector } from "react-redux";
import { callApi } from "utils/ContentoApi";
import { statsAggregator } from "utils/stats";

const AnalyticsStateContext = createContext<IAnalyticsState | undefined>(
  undefined
);
AnalyticsStateContext.displayName = "AnalyticsStateContext";

const AnalyticsDispatchContext = createContext<IAnalyticsDispatch | undefined>(
  undefined
);
AnalyticsDispatchContext.displayName = "AnalyticsDispatchContext";

export interface IAnalyticsActions {
  setChannelsIsLoading: (
    value: IAnalyticsState["channels"]["isLoading"]
  ) => void;
  setChannelsPeriod: (period: IAnalyticsState["channels"]["period"]) => void;
  setChannelsChannelIds: (
    channelIds: IAnalyticsState["channels"]["channelIds"]
  ) => void;
  setAllPostsIsLoading: (
    value: IAnalyticsState["allPosts"]["isLoading"]
  ) => void;
  setAllPostsPeriod: (period: IAnalyticsState["allPosts"]["period"]) => void;
  setAllPostsChannelIds: (
    channelIds: IAnalyticsState["allPosts"]["channelIds"]
  ) => void;
  setAllPostsCategories: (
    categories: IAnalyticsState["allPosts"]["categories"]
  ) => void;
  setAllPostsData: (data: IAnalyticsState["allPosts"]["data"]) => void;
  setRepurposePostsIsLoading: (
    value: IAnalyticsState["repurposePosts"]["isLoading"]
  ) => void;
  setRepurposePostsPeriod: (
    period: IAnalyticsState["repurposePosts"]["period"]
  ) => void;
  setRepurposePostsChannelIds: (
    channelIds: IAnalyticsState["repurposePosts"]["channelIds"]
  ) => void;
  setRepurposePostsCategories: (
    categories: IAnalyticsState["repurposePosts"]["categories"]
  ) => void;
  setRepurposePostsEngagementRate: (
    data: IAnalyticsState["repurposePosts"]["engagementRate"]
  ) => void;
  setRepurposePostsData: (
    data: IAnalyticsState["repurposePosts"]["data"]
  ) => void;
  setLinksIsLoading: (value: IAnalyticsState["links"]["isLoading"]) => void;
  setLinksPeriod: (period: IAnalyticsState["links"]["period"]) => void;
  setLinksChannelIds: (
    channelIds: IAnalyticsState["links"]["channelIds"]
  ) => void;
  setLinksCategories: (
    categories: IAnalyticsState["links"]["categories"]
  ) => void;
  setLinksData: (data: IAnalyticsState["links"]["data"]) => void;

  refreshOverviewStats: () => Promise<void>;
  refreshAllPosts: () => Promise<void>;
  refreshRepurposePosts: () => Promise<void>;
  refreshLinkPosts: () => Promise<void>;
}

const AnalyticsActionContext = createContext<IAnalyticsActions | undefined>(
  undefined
);
AnalyticsActionContext.displayName = "AnalyticsActionContext";

export const AnalyticsProvider: React.FC<any> = React.memo(({ children }) => {
  const account = useSelector<any, any>(state => state.account.data);
  const [state, dispatch] = useReducer(analyticsReducer, {
    ...initialValues
  });

  const initSelectedChannelIds = useCallback(() => {
    const ids = account.channels.map(channel => channel.id);

    dispatch(setChannelsChannelIdsAction(ids));
    dispatch(setAllPostsChannelIdsAction(ids));
    dispatch(setRepurposePostsChannelIdsAction(ids));
    dispatch(setLinksChannelIdsAction(ids));
  }, [account.channels]);

  useEffect(() => {
    initSelectedChannelIds();
  }, [initSelectedChannelIds]);

  // * <---- STATE MODIFIERS
  const setChannelsIsLoading: IAnalyticsActions["setChannelsIsLoading"] =
    useCallback(value => {
      dispatch(setChannelsIsLoadingAction(value));
    }, []);

  const setChannelsPeriod: IAnalyticsActions["setChannelsPeriod"] = useCallback(
    period => {
      dispatch(setChannelsPeriodAction(period));
    },
    []
  );

  const setChannelsChannelIds: IAnalyticsActions["setChannelsChannelIds"] =
    useCallback(channelIds => {
      dispatch(setChannelsChannelIdsAction(channelIds));
    }, []);

  const setAllPostsIsLoading: IAnalyticsActions["setAllPostsIsLoading"] =
    useCallback(value => {
      dispatch(setAllPostsIsLoadingAction(value));
    }, []);

  const setAllPostsPeriod: IAnalyticsActions["setAllPostsPeriod"] = useCallback(
    period => {
      dispatch(setAllPostsPeriodAction(period));
    },
    []
  );

  const setAllPostsChannelIds: IAnalyticsActions["setAllPostsChannelIds"] =
    useCallback(channelIds => {
      dispatch(setAllPostsChannelIdsAction(channelIds));
    }, []);

  const setAllPostsCategories: IAnalyticsActions["setAllPostsCategories"] =
    useCallback(categories => {
      dispatch(setAllPostsCategoriesAction(categories));
    }, []);

  const setAllPostsData: IAnalyticsActions["setAllPostsData"] = useCallback(
    data => {
      dispatch(setAllPostsDataAction(data));
    },
    []
  );

  const setRepurposePostsIsLoading: IAnalyticsActions["setRepurposePostsIsLoading"] =
    useCallback(value => {
      dispatch(setRepurposePostsIsLoadingAction(value));
    }, []);

  const setRepurposePostsPeriod: IAnalyticsActions["setRepurposePostsPeriod"] =
    useCallback(period => {
      dispatch(setRepurposePostsPeriodAction(period));
    }, []);

  const setRepurposePostsChannelIds: IAnalyticsActions["setRepurposePostsChannelIds"] =
    useCallback(channelIds => {
      dispatch(setRepurposePostsChannelIdsAction(channelIds));
    }, []);

    const setRepurposePostsCategories: IAnalyticsActions["setRepurposePostsCategories"] =
      useCallback(categories => {
        dispatch(setRepurposePostsCategoriesAction(categories));
      }, []);

  const setRepurposePostsEngagementRate: IAnalyticsActions["setRepurposePostsEngagementRate"] =
    useCallback(data => {
      dispatch(setRepurposePostsEngagementRateAction(data));
    }, []);

  const setRepurposePostsData: IAnalyticsActions["setRepurposePostsData"] =
    useCallback(data => {
      dispatch(setRepurposePostsDataAction(data));
    }, []);

  const setLinksIsLoading: IAnalyticsActions["setLinksIsLoading"] = useCallback(
    value => {
      dispatch(setLinksIsLoadingAction(value));
    },
    []
  );

  const setLinksPeriod: IAnalyticsActions["setLinksPeriod"] = useCallback(
    period => {
      dispatch(setLinksPeriodAction(period));
    },
    []
  );

  const setLinksChannelIds: IAnalyticsActions["setLinksChannelIds"] =
    useCallback(channelIds => {
      dispatch(setLinksChannelIdsAction(channelIds));
    }, []);

    const setLinksCategories: IAnalyticsActions["setLinksCategories"] =
      useCallback(categories => {
        dispatch(setLinksCategoriesAction(categories));
      }, []);

  const setLinksData: IAnalyticsActions["setLinksData"] = useCallback(data => {
    dispatch(setLinksDataAction(data));
  }, []);

  // * STATE MODIFIERS ---->

  // * <---- COMMON FUNCTIONS
  const refreshOverviewStats: IAnalyticsActions["refreshOverviewStats"] =
    useCallback(async () => {
      try {
        dispatch(setChannelsIsLoadingAction(true));

        const stats = await callApi({
          url: `/accounts/${account.id}/stats/channels-new`,
          params: { periodInDays: state.channels.period }
        });

        const filteredStats = (stats || []).filter(c =>
          state.channels.channelIds.includes(c.id)
        );

        if (account.channels.length > 0) {
          dispatch(setChannelsDataAction(statsAggregator(filteredStats)));
        }

        dispatch(setChannelsPerChannelDataAction(filteredStats));

        dispatch(
          setChannelsEmployeesDataAction(
            filteredStats.filter(
              c => `${c.service}_${c.serviceType}` === "linkedin_profile"
            )
          )
        );
      } finally {
        setTimeout(() => {
          dispatch(setChannelsIsLoadingAction(false));
        }, 200);
      }
    }, [
      account.id,
      state.channels.period,
      account.channels.length,
      state.channels.channelIds
    ]);

  const refreshAllPosts: IAnalyticsActions["refreshAllPosts"] =
    useCallback(async () => {
      try {
        dispatch(setAllPostsIsLoadingAction(true));

        let postsUrl = `/accounts/${account.id}/stats/posts-new`;

        const params: any = {
          periodInDays: state.allPosts.period.toString(),
          sort: "engagements",
          channelId: state.allPosts.channelIds
        };

        const posts = await callApi({
          url: postsUrl,
          params: params
        });
        dispatch(setAllPostsDataAction(posts));
      } finally {
        dispatch(setAllPostsIsLoadingAction(false));
      }
    }, [account.id, state.allPosts.period, state.allPosts.channelIds]);

  const refreshRepurposePosts = useCallback(async () => {
    try {
      dispatch(setRepurposePostsIsLoadingAction(true));

      const postsToReshareUrl = `/accounts/${account.id}/stats/posts/reshare`;
      const params = {
        periodInDays: state.repurposePosts.period,
        sort: "date",
        channelId: state.repurposePosts.channelIds
      };

      const posts = await callApi({ url: postsToReshareUrl, params });
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

      const filteredPosts = posts.filter(
        post =>
          post.stats.calculated.engagementRate >=
            state.repurposePosts.engagementRate &&
          new Date(post.postedAt) < threeMonthsAgo
      );

      dispatch(setRepurposePostsDataAction(filteredPosts));
    } finally {
      dispatch(setRepurposePostsIsLoadingAction(false));
    }
  }, [
    account.id,
    state.repurposePosts.period,
    state.repurposePosts.channelIds,
    state.repurposePosts.engagementRate
  ]);

  const refreshLinkPosts = useCallback(async () => {
    try {
      dispatch(setLinksIsLoadingAction(true));

      const linksStatsUrl = `/accounts/${account.id}/stats/posts/links`;
      const params = {
        periodInDays: state.links.period,
        sort: "date",
        channelId: state.links.channelIds
      };

      const linksStats = await callApi({ url: linksStatsUrl, params });

      dispatch(setLinksDataAction(linksStats));
    } finally {
      dispatch(setLinksIsLoadingAction(false));
    }
  }, [account.id, state.links.period, state.links.channelIds]);

  // * COMMON FUNCTIONS ---->

  const values = useMemo(
    () => ({
      ...state
    }),
    [state]
  );

  const actions = useMemo(
    () => ({
      setChannelsIsLoading,
      setChannelsPeriod,
      setChannelsChannelIds,
      setAllPostsIsLoading,
      setAllPostsPeriod,
      setAllPostsChannelIds,
      setAllPostsCategories,
      setAllPostsData,
      setRepurposePostsIsLoading,
      setRepurposePostsPeriod,
      setRepurposePostsChannelIds,
      setRepurposePostsCategories,
      setRepurposePostsEngagementRate,
      setRepurposePostsData,
      setLinksIsLoading,
      setLinksPeriod,
      setLinksData,
      setLinksChannelIds,
      setLinksCategories,

      refreshOverviewStats,
      refreshAllPosts,
      refreshRepurposePosts,
      refreshLinkPosts
    }),
    [
      setChannelsIsLoading,
      setChannelsPeriod,
      setChannelsChannelIds,
      setAllPostsIsLoading,
      setAllPostsPeriod,
      setAllPostsChannelIds,
      setAllPostsCategories,
      setAllPostsData,
      setRepurposePostsIsLoading,
      setRepurposePostsPeriod,
      setRepurposePostsChannelIds,
      setRepurposePostsCategories,
      setRepurposePostsEngagementRate,
      setRepurposePostsData,
      setLinksIsLoading,
      setLinksPeriod,
      setLinksData,
      setLinksChannelIds,
      setLinksCategories,

      refreshOverviewStats,
      refreshAllPosts,
      refreshRepurposePosts,
      refreshLinkPosts
    ]
  );

  return (
    <AnalyticsStateContext.Provider value={values}>
      <AnalyticsDispatchContext.Provider value={dispatch}>
        <AnalyticsActionContext.Provider value={actions}>
          {children}
        </AnalyticsActionContext.Provider>
      </AnalyticsDispatchContext.Provider>
    </AnalyticsStateContext.Provider>
  );
});

export const useAnalyticsState = <T,>(
  selector: (state: IAnalyticsState) => T
): T => {
  try {
    return useContextSelector(
      AnalyticsStateContext as Context<IAnalyticsState>,
      selector
    );
  } catch (_) {
    throw new Error(
      "useAnalyticsState must be used within an AnalyticsProvider"
    );
  }
};

export const useAnalyticsDispatch = () => {
  const context = useContext(AnalyticsDispatchContext);

  if (context === undefined) {
    throw new Error(
      "useAnalyticsDispatch must be used within an AnalyticsProvider"
    );
  }
  return context;
};

export const useAnalyticsActions = <T,>(
  selector: (state: IAnalyticsActions) => T
): T => {
  const context = useContextSelector(
    AnalyticsActionContext as Context<IAnalyticsActions>,
    selector
  );

  if (context === undefined) {
    throw new Error(
      "useAnalyticsActions must be used within an AnalyticsProvider"
    );
  }
  return context;
};

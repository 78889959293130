export interface ICustomEvent {
  dispatch: (data?: any, metaData?: any) => void;
  listen: (callback: Function) => VoidFunction;
}

const createEvent = (eventName: string) => {
  const event = new CustomEvent<{ data: any; metaData: any }>(eventName, {
    detail: {
      data: null,
      metaData: null
    }
  });
  const target = new EventTarget();

  const dispatch = (data?: any, metaData?: any) => {
    event.detail.data = data;
    event.detail.metaData = metaData;
    target.dispatchEvent(event);
  };

  const listen = (callback: Function) => {
    const cb = () => {
      callback(event.detail.data, event.detail.metaData);
    };

    target.addEventListener(eventName, cb);

    return () => {
      target.removeEventListener(eventName, cb);
    };
  };

  return { dispatch, listen };
};

export default createEvent;

import axios from "axios";
import { pdfjs } from "react-pdf";
import { callApi } from "utils/ContentoApi";
import React, { useState, useEffect, useRef, useCallback } from "react";

import UploadZone from "./UploadZone";
import Loader from "components/common/loading/Loader";
import { useContentoApi } from "utils/useContentoApi";
import { ImageWrapper, DropZoneContainer } from "./styles";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/es5/build/pdf.worker.min.js`;

const CancelToken = axios.CancelToken;

const PdfUpload = ({ onError, onUpload, isUploading, setIsUploading }) => {
  const [progress, setProgress] = useState(0);

  const [getSignedUrl, cancelGetSignedUrl] = useContentoApi(
    "pdfs/upload-url",
    "post"
  );
  const [uploadTarget, setUploadTarget] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [pdfMetaData, setPdfMetaData] = useState(null);

  const cancelTokenRef = useRef(null);
  const cancelUpload = useCallback(() => {
    if (cancelTokenRef.current !== null) {
      cancelTokenRef.current.cancel();
      cancelTokenRef.current = null;
      console.log("Upload cancelled");
    }
  }, [cancelTokenRef]);

  //Cancel upload on unmount
  useEffect(() => {
    return cancelUpload;
  }, [cancelUpload]);

  const uploadPreview = async (image, fileName) => {
    const formData = new FormData();
    formData.append("file", image, fileName);
    formData.append("timestamp", (Date.now() / 1000) | 0);

    return await callApi({
      method: "post",
      url: "images/upload",
      data: formData,
      headers: [{ "Content-Type": "multipart/form-data" }],
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 90) / progressEvent.total
        );
        setProgress(percentCompleted);
      }
    });
  };

  const onDrop = pdfs => {
    if (isUploading) {
      cancelUpload();
    }

    if (pdfs.length === 0) {
      onError(new Error("Make sure file attached is a pdf"));
      return;
    }

    const pdf = pdfs[0];
    if (pdf.size > 100 * 1024 * 1024) {
      onError(new Error("The pdf file size cannot exceed 100MB"));
      return;
    }

    setIsUploading(true);

    const fileReader = new FileReader();
    fileReader.onload = async file => {
      try {
        const pdfData = new Uint8Array(file.currentTarget.result);
        const pdfFile = await pdfjs.getDocument({ data: pdfData }).promise;
        if (pdfFile.numPages > 300) {
          onError(new Error("The pdf file size cannot exceed 300 pages"));
          return;
        }
        const page = await pdfFile.getPage(1);
        const viewport = page.getViewport({ scale: 1.5 });

        const canvas = document.createElement("canvas");

        // Prepare canvas using PDF page dimensions
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        const renderContext = { canvasContext: context, viewport: viewport };

        const renderTask = page.render(renderContext);
        await renderTask.promise;
        canvas.toBlob(async blob => {
          const preview = await uploadPreview(
            blob,
            `${pdf.name.split(".")[0]}_preview.png`
          );
          setPdfMetaData({
            size: pdf.size,
            fileName: pdf.name,
            preview: preview[0].path
          });
          setPdf(pdf);
        });
      } catch (e) {
        console.error("Pdf load error", e);
      }
    };
    fileReader.readAsArrayBuffer(pdf);
  };

  useEffect(() => {
    if (pdf === null) {
      return;
    }
    getSignedUrl({
      params: {
        name: pdf.name
      }
    }).then(result => setUploadTarget(result));
    return cancelGetSignedUrl;
  }, [pdf, getSignedUrl, cancelGetSignedUrl]);

  useEffect(() => {
    if (uploadTarget === null || pdf === null) {
      return;
    }

    cancelTokenRef.current = CancelToken.source();

    axios({
      method: "put",
      url: uploadTarget.url,
      data: pdf,
      headers: { "Content-Type": "application/pdf" },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = Math.max(
          90,
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        );
        setProgress(percentCompleted);
      },
      cancelToken: cancelTokenRef.current.token
    })
      .then(response => {
        cancelTokenRef.current = null;
        setIsUploading(false);
        onUpload([{ path: uploadTarget.path, metaData: pdfMetaData }]);
      })
      .catch(err => {
        setIsUploading(false);
        cancelTokenRef.current = null;
        if (!axios.isCancel(err)) {
          setUploadTarget(null);
          onError(err);
        } else {
          //Don't show any error if this is a cancellation.
          err = null;
        }
      });
  }, [uploadTarget, pdf, onUpload, isUploading, cancelUpload, pdfMetaData]);

  return (
    <>
      {isUploading ? (
        <DropZoneContainer
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          height="120px"
          width="120px"
          minHeight="120px"
          type={"pdf"}
          disabled={true}
        >
          <ImageWrapper $progress={0}>
            <Loader
              location="center"
              size={24}
              color="#0063FB"
              progress={progress}
            />
          </ImageWrapper>
        </DropZoneContainer>
      ) : (
        <UploadZone
          accept="application/pdf"
          isUploading={isUploading}
          onDrop={onDrop}
          type={"pdf"}
        />
      )}
    </>
  );
};

export default PdfUpload;

import styled from "styled-components";
import { Box, Text } from "rebass/styled-components";

export const BackButton = styled(Box)`
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.grey01};
`;

export const ArticleContentWrapper = styled(Box)`
  p {
    font-size: 16px;
    letter-spacing: -0.02em;
    line-height: 24px;
    text-align: left;
    color: ${props => props.theme.colors.text01};
  }

  img {
    width: 100%;
    border-radius: 12px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    font-size: 25px;
    letter-spacing: -0.03em;
    line-height: 32.5px;
    text-align: left;
    color: ${props => props.theme.colors.text01};
  }

  figure {
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: center;
  }
`;

export const ArticleMetaInfo = styled(Text)`
  font-size: 13px;
  font-weight: 500;
  line-height: 1.85;
  letter-spacing: -0.26px;
  text-align: left;
  margin-bottom: 32px;
  color: ${props => props.theme.colors.text03};
`;

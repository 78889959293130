export default function findUrlEntities(contentBlock, callback, contentState) {
  //Trigger if it has an entity and it is one of the expected types.
  contentBlock.findEntityRanges(character => {
    const entityTypes = ["UNSHORTENED_URL", "SHORTENED_URL"];
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      entityTypes.indexOf(contentState.getEntity(entityKey).getType()) !== -1
    );
  }, callback);
}

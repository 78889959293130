import { Tooltip as ReactTooltip } from "react-tooltip";
import styled, { css } from "styled-components";

import { PostContextMenuWrapper } from "../common/postContextMenu/styles";
import { ScheduleLayout, ScheduleBody, ScheduleSidebar } from "../styles";

export const MonthLayout = styled(ScheduleLayout)`
  max-height: calc(100vh - 72px);
`;

export const MonthBody = styled(ScheduleBody)`
  position: relative;
  overflow-y: scroll;
`;

export const MonthSidebar = styled(ScheduleSidebar)`
  position: relative;
  width: 440px;
  z-index: 9;

  transition: width 0.5s;
`;

export const MonthGroup = styled.div`
  min-height: 140px;
  ${props =>
    props.isOutside &&
    css`
      opacity: 0.5;
    `};
`;

export const GridContainer = styled.div`
  position: relative;
`;

export const GridCalendarHeader = styled.div`
  display: grid;
  /* grid-template-columns: repeat(7, calc((100% - 24px) / 7)); */
  grid-template-columns: ${props => props.layout};
  grid-gap: 4px;
  padding: 0 14px;
  position: sticky;
  top: 64px;
  z-index: 5;
  padding: 16px 16px 8px 16px;
  background: ${props => props.theme.colors.white};
`;

export const GridBody = styled.div`
  box-sizing: border-box;
  display: grid;
  /* grid-template-columns: repeat(7, calc((100% - 24px) / 7)); */
  grid-template-columns: ${props => props.layout};

  grid-gap: 4px;
  min-height: calc(100vh - 72px);
  padding: 0 16px;
`;

export const GridLoading = styled.div`
  align-items: center;
  background: rgba(253, 253, 253, 0.8);
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 72px;
  width: 100%;
  z-index: 1;
`;

export const GridItemHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: flex-end;
  letter-spacing: -0.16px;
  line-height: 16px;
  margin: 0 4px 0px;
`;

export const GridItem = styled.div`
  box-sizing: border-box;
  text-align: right;
  padding: 4px 0 2px;
  border-top: 1px solid
    ${props =>
      props.isToday ? props.theme.colors.blue : props.theme.colors.grey02};
`;

export const GridItemTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-bottom: 2px;
`;

export const GridItemNumber = styled.div`
  display: inline-block;
  font-size: 12px;
  color: ${props =>
    props.isToday
      ? props.theme.colors.white
      : props.isOutsideMonth
        ? props.theme.colors.text03
        : props.isPast
          ? props.theme.colors.text04
          : props.theme.colors.text01};
  font-weight: 600;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: right;
  border-radius: 12px;

  ${props =>
    props.isToday &&
    css`
      background: ${props => props.theme.colors.blue};
      color: ${props => props.theme.colors.white};
      border-radius: 4px;
      text-align: center;
      font-weight: 700;
    `}
`;

export const GridAddButton = styled.button`
  appearance: none;
  border-radius: 6px;
  box-shadow: none;
  border: none;
  color: ${props => props.theme.colors.text02};
  background-color: ${props => props.theme.colors.grey01};
  cursor: pointer;
  padding: 3px;
  font-size: 10px;

  &:hover {
    background-color: ${props => props.theme.colors.grey02};
  }
`;

export const PostGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const PostList = styled.div`
  width: 100%;
`;

export const PostEntry = styled.div`
  background-color: white;
  box-sizing: border-box;
  box-shadow: ${props => props.theme.shadows.shadowXS};
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  flex: 1 auto;
  padding: 8px;
  margin: 4px 0;
  overflow: hidden;
  position: relative;

  ${props =>
    props.dashboardEntry &&
    css`
      box-shadow: unset;
      background-color: ${props => props.theme.colors.grey01};
      border: unset;
    `};

  ${props =>
    props.isGroup &&
    css`
      box-shadow:
        0 2px 5px 0 rgba(0, 0, 0, 0.14),
        0 8px 0 -4px #ffffff,
        0 9px 2px -3px rgba(0, 0, 0, 0.12),
        0 14px 0 -6px #ffffff,
        0 16px 4px -7px rgba(0, 0, 0, 0.16),
        0 4px 8px 0 rgba(0, 0, 0, 0.13);
      margin-bottom: 12px;
    `};

  ${props =>
    props.borderColor &&
    !props.dashboardEntry &&
    css`
      &:after {
        background-color: ${props.borderColor};
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 4px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }
    `};

  ${props =>
    props.isDragging &&
    css`
      opacity: 1;
      box-shadow:
        0px 4px 24px rgba(28, 30, 31, 0.08),
        0px 1px 2px rgba(28, 30, 31, 0.04);
      outline: 1px solid ${props => props.theme.colors.black};
    `}

  ${props =>
    props.isContextMenuOpen &&
    css`
      outline: 1px solid ${props => props.theme.colors.black};
    `}

  ${props =>
    props.isPhantom &&
    css`
      outline: 1px solid ${props => props.theme.colors.black};
      opacity: 0.5;
    `}

  &:hover {
    box-shadow: ${props =>
      props.isGroup ? "auto" : props.theme.shadows.shadow02};

    ${props =>
      props.dashboardEntry &&
      css`
        background-color: ${props => props.theme.colors.grey02};
      `};

    ${PostContextMenuWrapper} {
      display: flex;
    }
  }
`;

export const SlotEntry = styled.div`
  background: ${props =>
    props.saved ? props.bg : props.isFilled ? "#ddd" : props.color};

  &::before {
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    width: 4px;
    left: 0;
    background: ${props => props.color};
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:hover {
    ${PostContextMenuWrapper} {
      display: flex;
    }
  }

  color: ${props => (props.saved ? "inherit" : "#fff")};

  ${props =>
    props.preview &&
    css`
      background: ${props.previewColor};
      color: ${props.theme.colors.white};
    `}

  border-radius: 6px;
  font-size: 12px;
  text-align: left;
  padding: 8px 7px;
  margin: 4px 0;
  opacity: ${props => (props.isOtherMonth || props.isPast ? "0.4" : "1")};
  &:hover {
    opacity: 1;
  }

  cursor: ${props => (props.isPast ? "default" : "pointer")};
  position: relative;

  .action__create-post {
    visibility: hidden;
    margin-left: -10px;
    transition: transform 250ms;
  }

  .post__content-type {
    ${props =>
      !props.preview &&
      !props.isPast &&
      css`
        margin-left: -14px;
      `}

    transition: transform 250ms;
  }

  ${props =>
    !props.preview &&
    !props.isPast &&
    css`
      &:hover {
        .post__content-type {
          transform: translateX(20px);
        }
        .action__create-post {
          visibility: visible;
          transform: translateX(10px);
        }
        background: ${props =>
          props.saved ? props.hover : props.isFilled ? "#ddd" : props.color};
      }
    `}

  ${props =>
    !props.saved &&
    !props.isFilled &&
    css`
      & span {
        color: white;
      }
    `}

  ${props =>
    props.isDragging &&
    css`
      opacity: 1;
      box-shadow:
        0px 4px 24px rgba(28, 30, 31, 0.08),
        0px 1px 2px rgba(28, 30, 31, 0.04);
      outline: 1px solid ${props => props.theme.colors.black};
    `}

  ${props =>
    props.isContextMenuOpen &&
    css`
      outline: 1px solid ${props => props.color};
    `}

  ${props =>
    props.isPhantom &&
    css`
      outline: 1px solid ${props => props.theme.colors.black};
      opacity: 0.5;
    `}
`;

export const EntryTop = styled.div`
  align-items: flex-start;
  display: flex;
  color: ${props => (!props.filled && !props.saved ? "white" : "inherit")};
  justify-content: space-between;
  margin-bottom: 5px;

  & > div:first-child {
    margin-right: 10px;
  }

  & > div:last-child {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }
`;

export const EntryBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${props =>
    props.ml &&
    css`
      margin-left: ${props.ml}px;
    `}
`;

export const EntryTime = styled.span`
  color: ${props =>
    !props.preview ? props.theme.colors.text01 : props.theme.colors.white};
  font-size: 13px;
  font-weight: 600;
  margin: 2px 4px 0px 4px;

  display: inline-flex;
  align-items: center;
  gap: 7px;

  & small {
    color: ${props =>
      !props.preview ? props.theme.colors.text02 : props.theme.colors.white};
    padding-left: 2px;
    font-weight: 400;
  }

  & .draft {
    font-size: 12px;
    font-weight: 600;
    line-height: 15.6px;
    color: ${props => props.theme.colors.orange};
  }

  .date {
    font-size: 12px;
    font-weight: 600;
    line-height: 15.6px;
    color: ${props => props.theme.colors.text03};
  }
`;

export const EntryChannels = styled.div`
  ${props =>
    props.adjustWidth &&
    css`
      width: 43%;
    `}
`;

export const EntryCaption = styled.p`
  color: ${props => props.theme.colors.text01};
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  margin-bottom: 0;
  overflow: hidden;
  text-align: left;
  text-indent: 2px;
  position: relative;
  white-space: break-spaces;
  width: 100%;

  ${props =>
    !props.dashboardEntry &&
    css`
      &:after {
        content: "";
        display: block;
        width: 16px;
        height: 13px;
        right: 0;
        top: 2px;

        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1)
        );
        position: absolute;
      }
    `}
`;

export const SlotNotes = styled.span`
  color: ${props => props.theme.colors.text01};
  padding: 0 4px;
`;

export const ToolTip = styled(ReactTooltip)`
  background: ${props => props.theme.colors.text01};
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  color: white;
  line-height: 1.25;
  font-size: 14px;
  padding: 6px 8px;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 999;

  ${props => {
    switch (props.variant) {
      case "info":
        return css`
          max-width: 150px;
        `;
      default:
        return null;
    }
  }}
`;

export const PostCreateButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  & span {
    font-size: 24px;

    &:hover::before {
      color: ${props => props.theme.colors.green};
    }
  }
`;

export const TopicalEntry = styled.div`
  background: ${props => props.theme.colors.yellow};
  box-shadow: ${props => props.theme.shadows.shadowXS};
  &:hover {
    ${PostContextMenuWrapper} {
      display: flex;
    }
  }

  color: ${props => props.theme.colors.white};

  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  padding: 8px;
  opacity: ${props => (props.isOtherMonth || props.isPast ? "0.4" : "1")};
  &:hover {
    opacity: 1;
  }

  cursor: ${props => (props.isPast ? "default" : "pointer")};
  position: relative;

  ${props =>
    props.isContextMenuOpen &&
    css`
      outline: 1px solid ${props => props.theme.colors.deepYellow};
    `}
`;

export const TopicalContent = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;

  > i {
    font-size: 16px;
  }
`;

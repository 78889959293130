import React from "react";
import { nanoid } from "nanoid";
import Button from "./Button";
import {
  MediaObject,
  MediaThumbnail,
  MediaContent,
  MediaTitle,
  MediaDescription,
  MediaSubDescription
} from "./styles";

function Media({
  thumbnail,
  thumbnailSize = 64,
  thumbnailRound,
  title,
  description,
  buttonLabel,
  buttonAction,
  subDescription,
  subDescriptionVariant = "primary",
  buttonVariant = "tertiary"
}) {
  return (
    <MediaObject>
      {thumbnail && (
        <MediaThumbnail
          $size={thumbnailSize}
          $radius={thumbnailRound ? thumbnailSize : 12}
        >
          <img src={thumbnail} alt={title} />
        </MediaThumbnail>
      )}
      <MediaContent>
        <MediaTitle>{title}</MediaTitle>
        <MediaDescription>
          {Array.isArray(description) ? (
            description.map(line => <span key={nanoid()}>{line}</span>)
          ) : (
            <span>{description}</span>
          )}
        </MediaDescription>
        {subDescription && (
          <MediaSubDescription $variant={subDescriptionVariant}>
            {subDescription}
          </MediaSubDescription>
        )}
      </MediaContent>
      {buttonLabel && (
        <Button variant={buttonVariant} onClick={buttonAction}>
          {buttonLabel}
        </Button>
      )}
    </MediaObject>
  );
}

export default Media;

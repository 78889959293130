export const emails = [
  {
    id: "weekly_digest",
    name: "Weekly Digest",
    description:
      "Receive your weekly achievements and to-do-list straight in your mailbox."
  },
  {
    id: "task_notification",
    name: "Tasks Reminder",
    description:
      "Receive an email notification every day with the pending tasks for the next 24 hours."
  },
  {
    id: "disconnected_channel",
    name: "Disconnected Channels",
    description:
      "Receive an email notification every time you need to reconnect one of your social media accounts."
  },
  {
    id: "content_suggestions",
    name: "New Content Suggestions",
    description: "Receive an email when there are new article suggestions."
  }
];

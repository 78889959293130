import React from "react";
import styled, { css } from "styled-components";
import DatePicker from "react-datepicker";
import { StyledInput } from "./form/styles";
import {
  removeTimezoneFromDate,
  removeTimezoneOffsetFromDate
} from "../schedule/utils/dateUtils";

const DatePickerInput = styled(StyledInput)`
  ${props =>
    props.variant === "secondary"
      ? css`
          border: none;
          background: ${props => props.theme.colors.grey02};
        `
      : null}
`;

export function TimezoneDatePicker(props) {
  const { selected, onChange } = props;

  function handlePickDate(date) {
    if (!date) {
      onChange(null);
      return;
    }

    // the date picket returns dates on the browser timezone
    // we make sure to set the selected hours as UTC to send them
    // correctly when transforming into ISO
    onChange(removeTimezoneOffsetFromDate(date));
  }

  const CustomInput = React.forwardRef((props, ref) => {
    return <DatePickerInput ref={ref} size={"sm"} {...props} />;
  });

  // transforms the date to the browser timezone
  const selectedDAte = new Date(removeTimezoneFromDate(selected));

  return (
    <DatePicker
      customInput={<CustomInput variant={props.variant} />}
      {...props}
      selected={selectedDAte}
      onChange={handlePickDate}
    />
  );
}

export function CustomDatePicker(props) {
  const { size = "m" } = props;

  const CustomInput = ({ value, onClick }) => {
    return (
      <DatePickerInput
        type="button"
        onClick={onClick}
        value={value}
        size={size}
        hasIcon={true}
      />
    );
  };

  return <DatePicker {...props} customInput={<CustomInput />} />;
}

export { TimezoneDatePicker as default };

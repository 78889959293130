import React from "react";

import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot
} from "react-beautiful-dnd";

interface IDraggableWrapperProps {
  id: string;
  index: number;
  children: JSX.Element;
  isDisabled: boolean;
}

const DraggableWrapper = ({
  id,
  children,
  index,
  isDisabled
}: IDraggableWrapperProps) => {
  // @ts-ignore
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    return {
      // some basic styles to make the items look a bit nicer
      ...draggableStyle,
      boxShadow: isDragging
        ? "0px 4px 24px rgba(28, 30, 31, 0.08), 0px 1px 2px rgba(28, 30, 31, 0.04)"
        : "none"
    };
  };

  return (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
      isDragDisabled={isDisabled}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
};

export default DraggableWrapper;

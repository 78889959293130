import React, { useMemo } from "react";
import { Text } from "rebass/styled-components";
import { Parser } from "@json2csv/plainjs";
import { format } from "date-fns";
import { useToaster } from "@hellocontento/maillard";

import {
  TableTitle,
  LeftContainer,
  DownloadButton,
  RightContainer,
  HeaderContainer
} from "../styles";
import Tooltip from "components/common/Tooltip";
import ChannelToggle from "components/common/ChannelToggle";
import PeriodSelect, { IPeriodMenuItem } from "../widgets/PeriodSelect";
import {
  useAnalyticsActions,
  useAnalyticsState
} from "contextApi/analyticsContext";
import PostCategorySelector from "../widgets/PostCategorySelector";

interface Channel {
  id: string;
  avatar: string;
  username: string;
  service: string;
}

interface Account {
  id: string;
  channels: Channel[];
}

interface LinksTableHeaderProps {
  loading: boolean;
  period: IPeriodMenuItem["value"];
  setPeriod: (period: IPeriodMenuItem["value"]) => void;
  selectedChannels: string[];
  setSelectedChannels: (channels: string[]) => void;
  account: Account;
  extractTableData: () => any[];
}

const LinksTableHeader: React.FC<LinksTableHeaderProps> = ({
  loading,
  period,
  setPeriod,
  selectedChannels,
  setSelectedChannels,
  account,
  extractTableData
}) => {
  const addToast = useToaster();
  const data = useAnalyticsState(state => state.links.data);
  const selectedCategories = useAnalyticsState(state => state.links.categories);
  const setSelectedCategories = useAnalyticsActions(
    state => state.setLinksCategories
  );

  const categories = useMemo(() => {
    let contentTypeIdSet: Set<string> = new Set(
      data.map(item => item.contentTypeId)
    );
    const contentTypeIds = Array.from(contentTypeIdSet);
    setSelectedCategories(contentTypeIds);
    return contentTypeIds;
  }, [data, setSelectedCategories]);

  const handleChannelToggleChange = (updatedChannels: string[]) => {
    setSelectedChannels(updatedChannels);
  };

  const handleDownload = () => {
    try {
      const data = extractTableData();
      const parser = new Parser();
      const csv = parser.parse(data);

      const currentDate = format(new Date(), "dd-MM-yyyy");

      const filename = `WillowLinksData-${currentDate}.csv`;

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error("Error while trying to download analytics:: ", err);
      addToast("Data could not be downloaded.", "error");
    }
  };

  return (
    <HeaderContainer>
      <LeftContainer>
        <TableTitle>All Shared Links</TableTitle>
        <Text fontSize={"14px"}>
          A breakdown of the click data behind posted links. You can read more
          about
          <br />
          <a
            href="https://support.willow.co/knowledge/analytics-terminology"
            target="_blank"
            rel="noopener noreferrer"
          >
            how we track this data here
          </a>
          .
        </Text>
      </LeftContainer>
      <RightContainer>
        <Tooltip title="Download report" placement="bottom">
          <DownloadButton onClick={handleDownload} disabled={loading}>
            <i className="icon-download" />
          </DownloadButton>
        </Tooltip>
        <PeriodSelect selected={period} onSelect={setPeriod} />
        <PostCategorySelector
          categories={categories}
          selectedCategories={selectedCategories}
          onChange={setSelectedCategories}
        />
        <ChannelToggle
          channels={account.channels}
          selectedIds={selectedChannels}
          onSave={handleChannelToggleChange}
          gap={5}
          onChange={() => {}}
        />
      </RightContainer>
    </HeaderContainer>
  );
};

export default LinksTableHeader;

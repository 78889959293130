import { connect } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";
import { useToaster } from "@hellocontento/maillard";
import ScrollableContentList from "./ScrollableContentList";

import { getTopicById } from "services/news";

import EmptyState from "../common/EmptyState";

import ArticleEmpty from "assets/images/contents/article-empty.png";

import { EmptyImageWrapper } from "./styles";
import { handleOpenChat } from "utils/AppActions";
import TopicDetailBanner from "./common/detail/TopicDetailBanner";

const PAGE_SIZE = 9;
const FIRST_PAGE = 1;

const ContentTopicDetail = ({ account, match, excludedTld }) => {
  const addToast = useToaster();
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    window.scrollTo({
      top: 0
    });
  }, [match.params.sourceId]);

  const fetchContents = async pageNumber => {
    try {
      const response = await getTopicById(match.params.topicId, {
        page: pageNumber,
        limit: PAGE_SIZE
      });

      setPageData(data => ({
        items:
          pageNumber > 1
            ? [...data.items, ...response.contents]
            : response.contents,
        topic: response.section,
        page: pageNumber,
        pageSize: PAGE_SIZE,
        isFinished: response.contents.length < PAGE_SIZE,
        excludedTld
      }));
    } catch (err) {
      if (err.name !== "RequestCancelled") {
        addToast(
          "There was an error fetching your content. Please try again later or contact support.",
          "error"
        );
      }
    }
  };

  const refreshContent = useCallback(async () => {
    fetchContents(FIRST_PAGE);
  }, [excludedTld, match.params.topicId]);

  useEffect(() => {
    refreshContent();
  }, [refreshContent, match.params.topicId]);

  const emptyState = (
    <EmptyState
      center={false}
      header={"We couldn’t find any articles"}
      description={
        "Unfortunately the feed you are following isn’t returning any articles."
      }
      image={<EmptyImageWrapper src={ArticleEmpty} />}
      helper={{
        text: "Report this page",
        onClick: handleOpenChat
      }}
    />
  );

  return (
    <>
      {pageData && <TopicDetailBanner pageData={pageData} />}

      <ScrollableContentList
        pageData={pageData}
        onNextPage={fetchContents}
        emptyState={emptyState}
        onScheduled={refreshContent}
        onDismiss={refreshContent}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser,
    excludedTld: state.regionFilter.excludedTld
  };
};

export default connect(mapStateToProps, {})(ContentTopicDetail);

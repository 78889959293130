import React from "react";
import styled, { css } from "styled-components";
import { Button as RebassButton, Text } from "rebass/styled-components";

import { theme } from "../../theme";

const sizes = {
  xs: {
    ...theme.textStyles.h6,
    borderRadius: "6px",
    lineHeight: "22px",
    px: "12px",
    py: "4px"
  },
  sm: {
    ...theme.textStyles.h6,
    borderRadius: "6px",
    lineHeight: "24px",
    px: "14px",
    py: "6px"
  },
  m: {
    ...theme.textStyles.h5,
    borderRadius: "6px",
    lineHeight: "24px",
    px: "18px",
    py: "8px"
  },
  lg: {
    ...theme.textStyles.h5,
    borderRadius: "8px",
    lineHeight: "24px",
    px: "22px",
    py: "12px"
  },
  xl: {
    ...theme.textStyles.h4,
    borderRadius: "20px",
    lineHeight: "20px",
    px: "20px",
    py: "10px"
  }
};

export const StyledButton = styled(RebassButton)<{
  $border?: string;
  $variant: string;
  $cursor?: string;
  $isRounded?: boolean;
  $size: string;
  $fontWeight?: number;
  $custom?: boolean;
  $transform?: string;
  $position?: string;
}>`
  position: relative;
  font-family: Inter;
  display: ${props => props.display || "flex"};
  align-items: center;
  outline: none;
  border: ${props => props.$border || "none"};
  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth}px;
    `}
  ${props =>
    ["primaryLite", "neutralLite", "successLite"].includes(props.$variant) &&
    css`
      border: 1.5px solid ${prop => theme.variants[props.$variant].borderColor};
    `};
  cursor: ${props => props.$cursor || "pointer"};
  font-weight: ${props => props.$fontWeight || 700};
  line-height: 24px;
  border-radius: ${props =>
    props.$isRounded ? "48px" : sizes[props.$size].borderRadius};
  ${props =>
    !props.$custom &&
    css`
      &:hover {
        background-color: ${prop => theme.variants[props.$variant].hover.bg};
        ${props =>
          props.$transform &&
          css`
            transform: ${props.$transform};
          `}
        ${props =>
          [
            "primaryLite",
            "neutralLite",
            "successLite",
            "secondaryQuiet",
            "secondaryQuietAlt"
          ].includes(props.$variant) &&
          css`
            color: ${theme.variants[props.$variant].hover.color};
            border-color: ${theme.variants[props.$variant].hover.borderColor};
          `};
        transition: background-color 300ms ease;
      }
    `}
  &:active {
    ${props => {
      if (!!theme.variants[props.$variant]?.active?.color) {
        return css`
          color: ${theme.variants[props.$variant].active.color};
        `;
      }
    }};
    background-color: ${props => theme.variants[props.$variant].active.bg};
  }
  ${props =>
    props.$position &&
    css`
      position: ${props.$position};
    `}
  ${props =>
    props.$transform &&
    css`
      transform: ${props.$transform};
    `}
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `}
  ${props =>
    [
      "primary",
      "black",
      "danger",
      "primaryLite",
      "dangerLite",
      "success"
    ].includes(props.$variant) &&
    css`
      &:focus {
        box-shadow:
          0 0 1px ${props => props.theme.colors.background1},
          0 0 0 1px ${props => props.theme.colors.background1},
          0 0 0 2px ${props => props.theme.colors.iconFill4} !important;
        color: ${props =>
          theme.variants[props.$variant].hover.color ||
          theme.variants[props.$variant].color};
        background-color: ${props => theme.variants[props.$variant].hover.bg};
        transition: box-shadow 0.2s ease;
      }
      &:disabled {
        pointer-events: none;
        background-color: ${props => props.theme.colors.grey01};
        color: ${props => props.theme.colors.textDisabled};
      }
    `}
  ${props =>
    props.$variant === "secondary" &&
    css`
      &:focus {
        background-color: ${props => props.theme.colors.grey03};
      }
    `}
  ${props =>
    ["secondary", "tertiary"].includes(props.$variant) &&
    css`
      &:active {
        transform: scale(0.96);
        transition:
          -ms-transform 0.2s ease 0s,
          -webkit-transform 0.2s ease 0s,
          transform 0.2s ease 0s !important;
      }
    `}
`;

StyledButton.defaultProps = {
  $isRounded: true
};
  
/**
 * variants: primary, secondary, tertiary, black, danger, success
 * size: sm, m, l, super
 */
const ButtonIcon = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  margin-right: 6px;
  fill: ${props => props.color};
`;

const Button = React.memo(
  React.forwardRef<HTMLButtonElement, any>(
    (
      { variant = "primary", size = "sm", textSize, icon, children, ...props },
      ref
    ) => {
      let iconElement: React.ReactNode | null = null;
      if (icon) {
        iconElement = (
          <ButtonIcon
            component={React.createElement(icon)}
            color={theme.variants[variant].color}
            size={24}
            strokeWidth="2.5px"
          />
        );
      }

      return (
        <StyledButton
          $variant={variant}
          $size={size}
          ref={ref}
          px={sizes[size].px}
          py={sizes[size].py}
          lineHeight={sizes[size].lineHeight}
          bg={props.backgroundColor ?? theme.variants[variant].bg}
          color={theme.variants[variant].color}
          {...props}
        >
          {iconElement}
          <Text
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontSize={sizes[textSize ?? size].fontSize}
            fontWeight={sizes[textSize ?? size].fontWeight}
            letterSpacing={sizes[textSize ?? size].letterSpacing}
          >
            {children}
          </Text>
        </StyledButton>
      );
    }
  )
);

export default Button;

// @ts-nocheck
import React from "react";

import {
  MenuList,
  MenuDivider,
  MenuContainer
} from "components/navigation/Menu";
import { MenuItem } from "./styles";

interface IDraftMenu {
  editDraft: (e: React.MouseEvent) => void;
  deleteDraft: (e: React.MouseEvent) => void;
  approveDraft: (e: React.MouseEvent) => void;
  duplicateDraft: (e: React.MouseEvent) => void;
}

const DraftMenu: React.FC<IDraftMenu> = ({
  editDraft,
  deleteDraft,
  approveDraft
  // duplicateDraft
}) => {
  return (
    <MenuContainer width={240}>
      <MenuList>
        <MenuItem
          icon="check"
          color="green"
          label="Approve draft"
          onClick={approveDraft}
        />
      </MenuList>
      <MenuDivider my={2} />
      <MenuList>
        <MenuItem label="Edit draft" icon="edit" onClick={editDraft} />
        {/* <MenuItem
          icon="copy"
          label="Duplicate draft"
          onClick={duplicateDraft}
        /> */}
      </MenuList>
      <MenuDivider my={2} />
      <MenuList>
        <MenuItem
          icon="delete"
          color="danger"
          label="Delete draft"
          onClick={deleteDraft}
        />
      </MenuList>
    </MenuContainer>
  );
};

export default DraftMenu;

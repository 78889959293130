import Carousel from "react-grid-carousel";
import { Flex } from "rebass/styled-components";
import styled, { css } from "styled-components";
import { pdfjs, Document, Page } from "react-pdf";
import React, { useEffect, useState } from "react";
import { useToaster } from "@hellocontento/maillard";

import { Headline5 } from "components/common/styles";
import Loader from "components/common/loading/Loader";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/es5/build/pdf.worker.min.js`;

const options = {
  cMapUrl: `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/cmaps/`,
  cMapPacked: true,
  standardFontDataUrl:
    "https://cdn.jsdelivr.net/npm/pdfjs-dist@4.2.67/standard_fonts"
};

interface IArrowBtn {
  direction: "right" | "left";
}

const Arrow = styled.span<IArrowBtn>`
  display: inline-block;
  position: absolute;
  top: 50%;
  transform: ${({ direction }) =>
    `translateY(-50%) rotate(${direction === "right" ? "45deg" : "-135deg"})`};
  width: 16px;
  height: 16px;
  cursor: pointer;
  border-top: 2px solid ${props => props.theme.colors.grey06};
  border-right: 2px solid ${props => props.theme.colors.grey06};
`;

const ArrowWrapper = styled.div<IArrowBtn>`
  display: flex;
  position: absolute;
  top: 0;
  z-index: 9;
  width: 30px;
  height: 100%;
  cursor: pointer;

  ${({ direction }) =>
    direction === "left"
      ? css`
          left: 0px;
          justify-content: flex-end;
        `
      : css`
          right: 0px;
          justify-content: flex-start;
        `};

  &:hover {
    background-color: ${props => props.theme.colors.grey01};

    ${Arrow} {
      border-color: ${props => props.theme.colors.black};
    }
  }
`;

const ArrowBtn = ({ direction }: IArrowBtn) => (
  <ArrowWrapper direction={direction}>
    <Arrow direction={direction} />
  </ArrowWrapper>
);

const PageNumber = styled.span<{ page: number; totalPages: number }>`
  position: static;

  &:before {
    position: absolute;
    top: 5px;
    left: 5px;
    content: "${({ page, totalPages }) => `${page} of ${totalPages}`}";
    font-size: 12px;
    color: ${props => props.theme.colors.text04};
    background: transparent;
  }
`;

interface IPdfViewer {
  inProMode: boolean;
  file: string | File | null;
}

const PdfViewer = ({ file, inProMode }: IPdfViewer) => {
  const addToast = useToaster();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [totalPages, setTotalPages] = useState<number>(0);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    setTotalPages(0);
  }, [file]);

  const onLoadSuccess = ({ numPages }) => {
    setTotalPages(numPages);
    setIsLoading(false);
  };

  const onLoadError = e => {
    console.error(e);
    setError(e);
    addToast("Pdf could not be loaded.", "error");
  };

  return (
    <>
      {!error && (
        <Document
          file={file}
          loading={<Loader location="center" />}
          onLoadSuccess={onLoadSuccess}
          onLoadError={onLoadError}
          options={options}
        >
          <Carousel
            cols={1}
            rows={1}
            gap={0}
            hideArrow={isLoading}
            mobileBreakpoint={0}
            arrowLeft={<ArrowBtn direction="left" />}
            arrowRight={<ArrowBtn direction="right" />}
          >
            {isLoading ? (
              <Carousel.Item>
                <Flex justifyContent={"center"}>
                  <Loader location="center" />
                </Flex>
              </Carousel.Item>
            ) : totalPages === 0 ? (
              <Carousel.Item>
                <Flex justifyContent={"center"}>
                  <Headline5>Sorry! Pdf could not be loaded.</Headline5>
                </Flex>
              </Carousel.Item>
            ) : (
              [...Array(totalPages)].fill(0).map((_, index) => (
                <Carousel.Item key={`page_${index + 1}`}>
                  <>
                    <Page
                      loading={<Loader location="center" />}
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      width={inProMode ? 500 : 483}
                    >
                      <PageNumber page={index + 1} totalPages={totalPages} />
                    </Page>
                  </>
                </Carousel.Item>
              ))
            )}
          </Carousel>
        </Document>
      )}
    </>
  );
};

export default PdfViewer;

import { Box } from "rebass";

import styled, { css } from "styled-components";
import { Body6 } from "components/common/styles";

export const CaptionCard = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  padding: 8px;
  width: 100%;
  height: fit-content;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey01};
  box-shadow: ${props => props.theme.shadows.shadowXS};
  border-radius: 16px;
  white-space: pre-wrap;
  cursor: pointer;

  .regenerate-btn {
    position: absolute;
    display: none;
    top: 8px;
    right: 8px;
    z-index: 1;

    div[class*="icon-"] {
      background-color: rgb(237, 239, 240);

      &:hover {
        background-color: rgb(232, 234, 236);
      }

      &:active {
        background-color: rgb(227, 230, 231);
      }
    }
  }

  &:hover {
    .regenerate-btn {
      display: block;
    }
  }

  ${Body6} {
    color: ${props => props.theme.colors.text02};
  }
`;

export const CaptionList = styled.div<{ isEmpty: boolean }>`
  display: grid;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
  scrollbar-gutter: stable;
  margin: 16px -16px 0;
  padding: 2px 16px 16px;
  box-sizing: border-box;
  width: 350px;
  height: auto;

  ${props =>
    props.isEmpty &&
    css`
      flex-grow: 1;
      scrollbar-gutter: unset;
    `}
`;

export const SearchContainer = styled(Box)`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 70px;
  margin-bottom: 8px !important;
  margin-right: 16px !important;
  padding: 13px 16px;
  border-radius: 8px;
  color: ${props => props.theme.colors.iconFill1};
  background-color: ${props => props.theme.colors.grey01};
`;

export const SearchInput = styled.textarea`
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: ${props => props.theme.colors.text01};
  font-family: ${props => props.theme.fonts.body};
  outline: none;
  font-size: 16px;
  line-height: 140%;
  width: 100%;

  &::placeholder {
    color: ${props => props.theme.colors.text04};
  }
`;

export const ActionContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  margin-right: 16px;
  height: 36px;
  align-items: center;
  justify-content: space-between;
`;

export const EmptyState = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      display: flex;
      font-size: 16px;
      font-weight: 700;
      line-height: 125%;
      text-align: center;
      align-items: center;
      color: ${props => props.theme.colors.text01};
    }

    .subtitle {
      display: flex;
      font-size: 14px;
      font-weight: 400;
      line-height: 140%;
      text-align: center;
      align-items: center;
      letter-spacing: -0.015em;
      color: ${props => props.theme.colors.text03};
    }
  }

  > img {
    width: 100%;
    height: 143px;
    object-fit: contain;
  }
`;

export const CaptionCardShimmer = styled.div`
  box-sizing: border-box;
  float: left;
  margin-bottom: 10px;
  width: 100%;
  height: 178px;
  border: 1px solid ${props => props.theme.colors.grey01};
  border-radius: 16px;

  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: white;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;

  @keyframes shimmer {
    0% {
      background-position: -1200px 0;
    }
    100% {
      background-position: 1200px 0;
    }
  }
`;

import { Box, Flex, Image } from "rebass";
import { Headline2 } from "components/common/styles";

import styled, { css } from "styled-components";
import Button from "components/common/Button";

export const FocusInfo = styled(Headline2)`
  margin-bottom: 0;
`;

export const OnboardingLayout = styled.div`
  display: grid;
  grid-template-columns: 65fr 35fr;
  grid-column-gap: 20px;
  margin-bottom: 42px;
`;

export const OnboardingListWrapper = styled(Flex)`
  overflow: hidden;
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.shadowS};
  border-radius: 16px;
  flex-direction: column;
`;

export const SessionWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  background: #faeee6;
  border-radius: 16px;
  padding: 32px;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  :hover {
    background: #faeee660;

    img {
      transform: scale(1.1);
    }
  }
`;

export const SessionImage = styled(Image)`
  height: 79px;
  width: 176px;
`;

export const FlairWrapper = styled(Flex)`
  flex-direction: row;
  gap: 10px;
`;

export const Flair = styled.span<{ variant: string; size?: string }>`
  border-radius: 6px;

  font-weight: 600;
  font-size: 12px;
  line-height: 125%;

  ${props => {
    switch (props.size) {
      case "sm":
        return css`
          padding: 4px 8px;
        `;
      case "lg":
      default:
        return css`
          padding: 6px 12px;
        `;
    }
  }}

  ${props => {
    switch (props.variant) {
      case "primary":
        return css`
          color: ${props.theme.colors.white};
          background: ${props.theme.colors.black};
        `;
      case "success":
        return css`
          color: ${props.theme.colors.white};
          background: ${props.theme.colors.green};
        `;
      case "secondary":
        return css`
          backdrop-filter: blur(4px);
          color: ${props.theme.colors.text02};
          background: ${props.theme.colors.grey02};
        `;
      case "orange":
        return css`
          color: ${props.theme.colors.white};
          background: ${props.theme.colors.orange};
        `;
      default:
        return css`
          color: ${props.theme.colors.text02};
          background: ${props.theme.colors.grey02};
        `;
    }
  }}
`;

export const OnboardingBoxWrapper = styled(Box)`
  padding: 28px;
  cursor: pointer;
  :hover {
    background: ${props => props.theme.colors.grey01};
  }
`;

export const OnboardingItemWrapper = styled.div`
  display: grid;
  cursor: pointer;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 16px;
  align-items: center;
`;

export const OnboardingAccordion = styled.div<{ maxHeight: number | string }>`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 16px;
  margin-top: 16px;
  margin-bottom: -12px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-in;

  &.active {
    max-height: ${props => props.maxHeight};
  }
`;

export const ActionButton = styled(Button)`
  width: fit-content;
`;

export const ActionButtonGroup = styled(Flex)`
  flex-direction: row;
  gap: 10px;
`;

export const CompletedMark = styled.span<{
  isCompleted?: boolean;
  isPhantom?: boolean;
}>`
  width: 24px;
  height: 24px;
  ${props =>
    !props.isPhantom &&
    css`
      box-shadow: 0px 0px 0px 3px rgba(102, 119, 128, 0.2) inset;
      background-color: white;
    `}
  border-radius: 100%;

  ${props =>
    props.isCompleted &&
    css`
      display: inline-flex;
      color: ${props => props.theme.colors.white};
      align-items: center;
      justify-content: center;
      border: none;
      background: ${props => props.theme.colors.black};
    `}
`;

export const LeftSection = styled(Flex)`
  flex-shrink: 0;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 36px;
`;

export const RightSection = styled(Flex)`
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TitleWrapper = styled(Flex)`
  flex-direction: row;
  align-items: center;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  margin-right: 16px;

  color: ${props => props.theme.colors.text01};
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: ${props => props.theme.colors.text03};
  max-width: 380px;
`;

export const RemainingSteps = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  color: ${props => props.theme.colors.text01};
  padding-right: 12px;
  border-right: 1px solid ${props => props.theme.colors.text04};
`;

export const CompletionTime = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  margin-left: 12px;
  color: ${props => props.theme.colors.text04};
`;

export const AccordionRightSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
`;

export const VideoWrapper = styled.div`
  position: relative;
  min-width: 100%;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #ccc;
  cursor: pointer;
  box-sizing: border-box;

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
  }
`;

export const VideoThumbnail = styled.div<{ bgImg?: string }>`
  background-image: ${props => `url('${props.bgImg}')`};
  box-sizing: border-box;
  padding-bottom: 56.25%;
  background-size: cover;
  transition: 0.5s ease all;

  :hover {
    transform: scale(1.1);
  }
`;

export const Video = styled.iframe`
  width: 100%;
  height: 150px;
  border-radius: 8px;
  border: solid #ccc 1px;
`;

export const CompletedWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  color: ${props => props.theme.colors.green};
  font-weight: 600;

  > .icon {
    font-size: 18px;
  }
`;

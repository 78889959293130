import React from "react";
import { IComposerNotification } from "contextApi/composerContext/reducer";

const TWITTER_PHOTO_LIMIT = 4;
const LINKEDIN_PHOTO_LIMIT = 9;
const TWITTER_VIDEO_DURATION_LIMIT = 140; // Seconds
const INSTAGRAM_VIDEO_DURATION_LIMIT = 90; // Seconds

// Supported image types for each platform
const TWITTER_SUPPORTED_IMAGE_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp"
];
const LINKEDIN_SUPPORTED_IMAGE_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/gif",
  "image/webp"
];
const FACEBOOK_SUPPORTED_IMAGE_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/bmp",
  "image/gif",
  "image/tiff"
];
const INSTAGRAM_SUPPORTED_IMAGE_TYPES = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/bmp"
];

const platformAspectRatios = {
  facebook: [1, 9 / 16, 16 / 9, 4 / 5],
  instagram: [9 / 16, 4 / 5],
  x: [1, 9 / 16, 16 / 9],
  linkedin: [1, 9 / 16, 16 / 9]
};

function isValidAspectRatio(
  width: number,
  height: number,
  platform: string
): boolean {
  const aspectRatio = width / height;
  const validRatios = platformAspectRatios[platform] || [];
  return validRatios.some(ratio => Math.abs(aspectRatio - ratio) < 0.01); // Allow for small floating-point inaccuracies
}

export default function validateAttachments(
  account,
  attachment,
  setAttachmentOptions,
  enabledServices,
  isDraft = false
) {
  const {
    type: attachmentType,
    options: attachmentOptions,
    photoAttachments,
    videoAttachments,
    articleAttachments
  } = attachment;
  const photoCount = photoAttachments.length;
  const videoCount = videoAttachments.length;

  const hasPhoto = attachmentType === "photo" && photoCount > 0;
  const hasVideo = attachmentType === "video" && videoCount > 0;
  const notifications: IComposerNotification[] = [];

  // Article CTA validation
  if (
    attachmentType === "article" &&
    attachment &&
    !!articleAttachments &&
    !articleAttachments.embeddable &&
    (account.features ?? []).includes("cta")
  ) {
    notifications.push({
      type: "info",
      message: `It won't be possible to show your call-to-action popup on this site.`
    });
  }

  // Twitter photo limit
  if (
    enabledServices.includes("twitter") &&
    hasPhoto &&
    photoCount > TWITTER_PHOTO_LIMIT
  ) {
    notifications.push({
      type: "warning",
      service: "twitter",
      message: `Only the first ${TWITTER_PHOTO_LIMIT} photos will be shared on X.`
    });
  }

  // Twitter image type validation
  if (enabledServices.includes("twitter") && hasPhoto === true) {
    photoAttachments
      .map((att: any) => att.metaData)
      .forEach((metadata: any) => {
        if (metadata && metadata.mime) {
          if (!TWITTER_SUPPORTED_IMAGE_TYPES.includes(metadata.mime)) {
            notifications.push({
              type: "error",
              service: "twitter",
              message: `The image you uploaded cannot be used on X because it is of the type ${(
                metadata.ext as string
              ).toUpperCase()}. To continue scheduling your post, you have to remove the image first. When adding a new image, please ensure it is one of the following types: JPG, JPEG, PNG, GIF, or WEBP.`
            });
          }
        }
      });
  }

  // LinkedIn image type validation
  if (enabledServices.includes("linkedin") && hasPhoto === true) {
    photoAttachments
      .map((att: any) => att.metaData)
      .forEach((metadata: any) => {
        if (metadata && metadata.mime) {
          if (!LINKEDIN_SUPPORTED_IMAGE_TYPES.includes(metadata.mime)) {
            notifications.push({
              type: "error",
              service: "linkedin",
              message: `The image you uploaded cannot be used on LinkedIn because it is of the type ${(
                metadata.ext as string
              ).toUpperCase()}. To continue scheduling your post, you have to remove the image first. When adding a new image, please ensure it is one of the following types: JPG, JPEG, PNG, GIF, or WEBP.`
            });
          }
        }
      });
  }

  // Facebook image type validation
  if (enabledServices.includes("facebook") && hasPhoto === true) {
    photoAttachments
      .map((att: any) => att.metaData)
      .forEach((metadata: any) => {
        if (metadata && metadata.mime) {
          if (!FACEBOOK_SUPPORTED_IMAGE_TYPES.includes(metadata.mime)) {
            notifications.push({
              type: "error",
              service: "facebook",
              message: `The image you uploaded cannot be used on Facebook because it is of the type ${(
                metadata.ext as string
              ).toUpperCase()}. To continue scheduling your post, you have to remove the image first. When adding a new image, please ensure it is one of the following types: JPG, JPEG, PNG, BMP, GIF, or TIFF.`
            });
          }
        }
      });
  }

  // Instagram image type validation
  if (enabledServices.includes("instagram") && hasPhoto === true) {
    photoAttachments
      .map((att: any) => att.metaData)
      .forEach((metadata: any) => {
        if (metadata && metadata.mime) {
          if (!INSTAGRAM_SUPPORTED_IMAGE_TYPES.includes(metadata.mime)) {
            notifications.push({
              type: "error",
              service: "instagram",
              message: `The image you uploaded cannot be used on Instagram because it is of the type ${(
                metadata.ext as string
              ).toUpperCase()}. To continue scheduling your post, you have to remove the image first. When adding a new image, please ensure it is one of the following types: JPG, JPEG, PNG, or BMP.`
            });
          }
        }
      });
  }

  // Instagram video duration validation
  if (
    enabledServices.includes("instagram") &&
    hasVideo &&
    (videoAttachments[0]?.duration || videoAttachments[0]?.metaData?.duration) >
      INSTAGRAM_VIDEO_DURATION_LIMIT
  ) {
    notifications.push({
      type: "error",
      service: "instagram",
      message: `Sorry, Instagram only accepts videos with a duration of up to ${INSTAGRAM_VIDEO_DURATION_LIMIT} seconds. Please deselect all Instagram channels below or upload a different video.`
    });
  }

  // LinkedIn photo limit validation
  if (
    enabledServices.includes("linkedin") &&
    hasPhoto &&
    photoCount > LINKEDIN_PHOTO_LIMIT
  ) {
    notifications.push({
      type: "warning",
      service: "linkedin",
      message: `Only the first ${LINKEDIN_PHOTO_LIMIT} photos will be shared on LinkedIn.`
    });
  }

  // LinkedIn video aspect ratio
  if (enabledServices.includes("linkedin") && hasVideo) {
    const metadata = videoAttachments[0]?.metaData;

    if (
      metadata?.videoWidth &&
      metadata?.videoHeight &&
      !isValidAspectRatio(metadata.videoWidth, metadata.videoHeight, "linkedin")
    ) {
      notifications.push({
        type: "warning",
        service: "linkedin",
        message: (
          <span>
            Your video is currently sized at {metadata.videoWidth}x
            {metadata.videoHeight}. This format is unusual for posting videos on
            LinkedIn and may result in a failed or improperly formatted post.
            Please review the recommended dimensions{" "}
            <a
              href="https://support.willow.co/en/knowledge/how-to-resize-your-videos-for-social-media"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{" "}
            to ensure the best results.
          </span>
        )
      });
    }
  }

  // Facebook video aspect ratio
  if (enabledServices.includes("facebook") && hasVideo) {
    const metadata = videoAttachments[0]?.metaData;

    if (
      metadata?.videoWidth &&
      metadata?.videoHeight &&
      !isValidAspectRatio(metadata.videoWidth, metadata.videoHeight, "facebook")
    ) {
      notifications.push({
        type: "warning",
        service: "facebook",
        message: (
          <span>
            Your video is currently sized at {metadata.videoWidth}x
            {metadata.videoHeight}. This format is unusual for posting videos on
            Facebook and may result in a failed or improperly formatted post.
            Please review the recommended dimensions{" "}
            <a
              href="https://support.willow.co/en/knowledge/how-to-resize-your-videos-for-social-media"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>{" "}
            to ensure the best results.
          </span>
        )
      });
    }
  }

  // Instagram no media error
  if (
    enabledServices.includes("instagram") &&
    !hasPhoto &&
    !hasVideo &&
    !isDraft
  ) {
    notifications.push({
      type: "error",
      service: "instagram",
      message: `Please make sure to upload a photo or video when posting to Instagram.`
    });
  }

  // Twitter video duration and aspect ratio validation
  if (enabledServices.includes("twitter") && hasVideo) {
    const metadata = videoAttachments[0]?.metaData;
    const duration = metadata?.duration;

    if (duration > TWITTER_VIDEO_DURATION_LIMIT) {
      notifications.push({
        type: "error",
        service: "twitter",
        message: `Your video is too long for X. The limit is ${TWITTER_VIDEO_DURATION_LIMIT} seconds. Deselect X or upload another video.`
      });
    }

    if (metadata?.videoWidth && metadata?.videoHeight) {
      if (!isValidAspectRatio(metadata.videoWidth, metadata.videoHeight, "x")) {
        notifications.push({
          type: "warning",
          service: "twitter",
          message: (
            <span>
              Your video is currently sized at {metadata.videoWidth}x
              {metadata.videoHeight}. This format is unusual for posting videos
              on X and may result in a failed or improperly formatted post.
              Please review the recommended dimensions{" "}
              <a
                href="https://support.willow.co/en/knowledge/how-to-resize-your-videos-for-social-media"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{" "}
              to ensure the best results.
            </span>
          )
        });
      }
    } else {
      notifications.push({
        type: "error",
        service: "twitter",
        message: `We were unable to retrieve the video size. Please make sure the video is properly uploaded and try again.`
      });
    }
  }

  // Instagram video aspect ratio and resolution check
  if (enabledServices.includes("instagram") && hasVideo) {
    const metadata = videoAttachments[0]?.metaData;

    // Check for video resolution smaller than 1080x1920
    if (metadata?.videoWidth < 1080 || metadata?.videoHeight < 1920) {
      notifications.push({
        type: "warning",
        service: "instagram",
        message: (
          <span>
            Your video is currently sized at {metadata.videoWidth}x
            {metadata.videoHeight}. This resolution is lower than the
            recommended 1080x1920 for posting videos on Instagram, which may
            affect the quality of your post.
          </span>
        )
      });
    }

    // Check for invalid aspect ratio for Instagram
    if (
      metadata?.videoWidth &&
      metadata?.videoHeight &&
      !isValidAspectRatio(
        metadata.videoWidth,
        metadata.videoHeight,
        "instagram"
      )
    ) {
      notifications.push({
        type: "warning",
        service: "instagram",
        message: (
          <span>
            Your video is currently sized at {metadata.videoWidth}x
            {metadata.videoHeight}. This aspect ratio is not one of the
            recommended ratios (9:16 or 4:5) for Instagram, which may result in
            a failed or improperly formatted post.
          </span>
        )
      });
    }

    // Check for aspect ratio not between 0.01 and 10
    const aspectRatio = metadata?.videoWidth / metadata?.videoHeight;
    if (aspectRatio < 0.01 || aspectRatio > 10) {
      notifications.push({
        type: "error",
        service: "instagram",
        message: (
          <span>
            The aspect ratio of your video is {aspectRatio.toFixed(2)}, which is
            outside the acceptable range for Instagram (between 0.01 and 10).
            Please adjust the video dimensions to continue.
          </span>
        )
      });
    }

    // Instagram video info message (posting as Reels)
    notifications.push({
      type: "info",
      service: "instagram",
      message: `Video will be posted to Instagram as a reel.`
    });
  }

  // PDF Validation (LinkedIn only)
  if (attachmentType === "pdf" && !enabledServices.includes("linkedin")) {
    notifications.push({
      type: "error",
      service: "pdf",
      message: `Please note that PDF files can only be uploaded to LinkedIn. Please select a LinkedIn channel.`
    });
  } else if (
    attachmentType === "pdf" &&
    !!enabledServices.find(service => service !== "linkedin")
  ) {
    notifications.push({
      type: "error",
      service: "pdf",
      message: `Please note that PDF files can only be uploaded to LinkedIn. Please remove the other channels.`
    });
  } else if (attachmentType === "pdf") {
    notifications.push({
      type: "info",
      service: "pdf",
      message: `Please note that PDF files can only be 100MB in size with a maximum of 300 pages.`
    });
  }

  return notifications;
}

// Commenting this out till we figure out the best way to resolve the instagram video conundrum
// if (enabledServices.includes("instagram") && hasVideo) {
//   notifications.push({
//     type: "generic",
//     service: "instagram",
//     message: `How should it be posted to instagram?`,
//     actionComponent: <InstagramVideoTypeSelector />
//   });
// }

import {
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  PointElement,
  CategoryScale,
  Chart as ChartJS
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import { format, parseISO } from "date-fns";

import { options } from "./config";

ChartJS.register(
  Title,
  Legend,
  Tooltip,
  BarElement,
  LinearScale,
  PointElement,
  CategoryScale
);

export default function BarPlot({ data, metricId, series, color }) {
  const plotData = {
    labels: data.map(entry => format(parseISO(entry.date), "dd/MM")),
    datasets: [
      {
        backgroundColor: color,
        borderRadius: 8,
        label: metricId,
        data: data.map(entry => entry[series]),
        pointStyle: "dot",
        pointRadius: 5
      }
    ]
  };
  return <Bar data={plotData} options={{ ...options }} />;
}

import React from "react";

import { StatusBadgeWrapper } from "./styles";
import { Status, STATUS_PROPERTIES } from "./constants";

interface IStatusBadge {
  status: Status;
}

const StatusBadge: React.FC<IStatusBadge> = ({ status }) => {
  const { icon, fontColor, background } = STATUS_PROPERTIES[status];

  return (
    <StatusBadgeWrapper fontColor={fontColor} background={background}>
      <i className={icon} />
      <span>{status}</span>
    </StatusBadgeWrapper>
  );
};

export default StatusBadge;

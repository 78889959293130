import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToaster } from "@hellocontento/maillard";

import { callApi } from "utils/ContentoApi";
import { AuthLayout, AuthImage, AuthBody } from "./styles";
import NewPasswordForm from "components/auth/NewPasswordForm";

const NewPasswordPage = React.memo(() => {
  const history = useHistory();
  const addToast = useToaster();
  const currentUser = useSelector(state => state.auth.currentUser);

  const onSubmit = values => {
    const { id: userId } = currentUser;
    const { password } = values;

    return callApi({
      // the password-reset skips the requirement of sending
      // the current password as the user forgot it
      url: `users/${userId}?password-reset=true`,
      method: "put",
      data: { password }
    })
      .then(() => {
        addToast("Your password has been successfully updated!", "success");
        history.push(`/`);
      })
      .catch(error => {
        const msg =
          error.name === "ValidationError"
            ? error.message
            : "We were unable to update your password, please try again later.";
        addToast(msg, "error");
      });
  };

  return (
    <AuthLayout>
      <AuthImage />
      <AuthBody>
        <NewPasswordForm
          onSubmit={onSubmit}
          accountEmail={currentUser.email}
          initialValues={{ userId: currentUser.id }}
        />
      </AuthBody>
    </AuthLayout>
  );
});

export default NewPasswordPage;

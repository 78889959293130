import { Modifier, EditorState, convertToRaw } from "draft-js";
import getSearchText from "draft-js-mention-plugin/lib/utils/getSearchText";
import getTypeByTrigger from "draft-js-mention-plugin/lib/utils/getTypeByTrigger";

/**
 * Adds a provided mention and replaces it in the content of a given editorState
 *
 * @param {EditorState} editorState
 * @param {object} mention
 * @param {string} mentionPrefix
 * @param {string} mentionTrigger
 * @param {string} entityMutability
 * @param {boolean} [useId]
 * @returns
 */
export const addMention = (
  editorState,
  mention,
  mentionPrefix,
  mentionTrigger,
  entityMutability,
  useId = false
) => {
  const contentStateWithEntity = editorState
    .getCurrentContent()
    .createEntity(getTypeByTrigger(mentionTrigger), entityMutability, {
      mention
    });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const currentSelectionState = editorState.getSelection();
  const { begin, end } = getSearchText(editorState, currentSelectionState, [
    mentionTrigger
  ]);

  // get selection of the @mention search text
  const mentionTextSelection = currentSelectionState.merge({
    anchorOffset: begin,
    focusOffset: end
  });

  let mentionReplacedContent = Modifier.replaceText(
    editorState.getCurrentContent(),
    mentionTextSelection,
    `${mentionPrefix}${useId ? mention.id : mention.text}`,
    undefined, // no inline style needed
    entityKey
  );

  // If the mention is inserted at the end, a space is appended right after for
  // a smooth writing experience.
  const blockKey = mentionTextSelection.getAnchorKey();
  const blockSize = editorState
    .getCurrentContent()
    .getBlockForKey(blockKey)
    .getLength();
  if (blockSize === end) {
    try {
      mentionReplacedContent = Modifier.insertText(
        mentionReplacedContent,
        mentionReplacedContent.getSelectionAfter(),
        " "
      );
    } catch (_) {
      console.error("Selection range conflict");
    }
  }

  const newEditorState = EditorState.push(
    editorState,
    mentionReplacedContent,
    "insert-fragment"
  );
  return EditorState.forceSelection(
    newEditorState,
    mentionReplacedContent.getSelectionAfter()
  );
};

/**
 * Applies a provided mention for all the content in a given editorState
 *
 * @param {EditorState} editorState
 * @param {object} mention
 * @param {string} mentionPrefix
 * @param {string} mentionTrigger
 * @param {string} entityMutability
 * @param {boolean} [useId]
 * @returns
 */
export const applyMention = (
  editorState,
  mention,
  mentionPrefix,
  mentionTrigger,
  entityMutability,
  useId = false
) => {
  const contentStateWithEntity = editorState
    .getCurrentContent()
    .createEntity(getTypeByTrigger(mentionTrigger), entityMutability, {
      mention
    });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  let newEditorState = editorState;

  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;

  /**
   * Map through each of the blocks in the editorState
   * Blocks are separated in the editor through \n character
   */
  blocks.forEach(block => {
    let blockText = block.text;
    // Offset to track the position with respect to the full block text
    let offset = 0;

    /**
     * Looping throught the block text to check for mentions
     * Removing sections of block text after each loop until empty
     * */
    while (blockText.length > 0) {
      // The beginning position of the mention string as found in the block text
      const begin = blockText.indexOf(
        `${mentionPrefix}${useId ? mention.id : mention.text}`
      );
      // The end position of the mention string calculated using its length
      const end =
        mentionPrefix.length +
        (useId ? mention.id.length : mention.text.length);

      if (begin < 0) {
        // Stopping condition mechanism
        blockText = "";
      } else {
        // Get the editor selector object which initially points to the first block
        const currentSelectionState = newEditorState.getSelection();
        // Manually set the editor selector object with current block key and offsets
        const mentionTextSelection = currentSelectionState.merge({
          anchorKey: block.key,
          focusKey: block.key,
          anchorOffset: offset + begin,
          focusOffset: offset + begin + end
        });

        // Apply the entity for the current block in the editorState object
        const mentionReplacedContent = Modifier.applyEntity(
          newEditorState.getCurrentContent(),
          mentionTextSelection,
          entityKey
        );

        // Create a new editorState using the above content with the entity
        newEditorState = EditorState.push(
          newEditorState,
          mentionReplacedContent,
          "apply-entity"
        );

        // Remove the string upto the mention string and track the position using offset
        blockText = blockText.substring(begin + end, blockText.length);
        offset = offset + begin + end;
      }
    }
  });

  return newEditorState;
};

/**
 * Applies all the provided mentions for all the content in a given editorState
 *
 * @param {EditorState} editorState
 * @param {object[]} mentions
 * @param {string} mentionPrefix
 * @param {string} mentionTrigger
 * @param {string} entityMutability
 * @param {string[]} [useIdFor]
 * @returns
 */
export const applyAllMentions = (
  editorState,
  mentions,
  mentionPrefix,
  mentionTrigger,
  entityMutability,
  useIdFor = []
) => {
  let newEditorState = editorState;

  mentions.forEach(mention => {
    newEditorState = applyMention(
      newEditorState,
      mention,
      mentionPrefix,
      mentionTrigger,
      entityMutability,
      useIdFor.includes(mention.service)
    );
  });

  return newEditorState;
};

import { Flex } from "rebass";
import styled, { css } from "styled-components";

export const ItemGrid = styled.div<{ layout: string }>`
  margin: 40px 0px;
  display: grid;
  grid-template-columns: ${props => props.layout};
  grid-column-gap: 25px;
`;

export const Card = styled.div`
  position: relative;
  cursor: pointer;
  border-radius: 20px;
  background: ${props => props.theme.colors.grey02};
  border: ${props => props.theme.colors.grey01};
  box-shadow: ${props => props.theme.shadows.shadowS};
`;

export const SmallHeadline = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;
  color: ${props => props.theme.colors.text03};
  margin-bottom: 10px;
`;

export const FlairWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 10px;
`;

export const CardFooter = styled(Flex)<{ disabled: boolean }>`
  flex-direction: row;
  justify-content: space-between;
  padding: 16px;
  height: 68px;
  background: ${props => props.theme.colors.white};
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

export const StepInfo = styled(Flex)`
  gap: 8px;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.span<{ isDisabled: boolean }>`
  font-weight: 700;
  font-size: 18px;
  line-height: 125%;
  letter-spacing: -0.005em;
  color: ${props =>
    props.isDisabled ? props.theme.colors.text04 : props.theme.colors.text01};
`;

export const Numbering = styled.span<{ isDisabled: boolean }>`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-weight: 700;
  border-radius: 50%;
  background-color: ${props =>
    props.isDisabled ? props.theme.colors.text04 : props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const SourceIconWrapper = styled.div`
  height: 256px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonContentWrapper = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: ceter;
  gap: 4px;

  .icon {
    font-size: 18px;
  }
`;

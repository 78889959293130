import React from "react";

import { connect } from "react-redux";

import FollowingTopics from "./FollowingTopics";
import FollowingSources from "./FollowingSources";
import { ITopic } from "services/news";
import { IDomain, ISource } from "@hellocontento/contento-common";

interface IPreferencesProps {
  topics: Array<ITopic>;
  sources: Array<ISource | IDomain>;
}

const Preferences: React.FC<IPreferencesProps> = props => {
  return (
    <>
      {props.topics.length > 0 && (
        <FollowingTopics followingTopics={props.topics} />
      )}
      {props.sources.length > 0 && (
        <FollowingSources followingSources={props.sources} />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    topics:
      state.content.sections.find((section: any) => section.type === "topics")
        ?.details ?? [],
    sources:
      state.content.sections.find((section: any) => section.type === "sources")
        ?.details ?? []
  };
};

export default connect(mapStateToProps, {})(Preferences);

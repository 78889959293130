import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import {
  useAnalyticsState,
  useAnalyticsActions
} from "contextApi/analyticsContext";
import { NoDataForTheseCriteria } from "../styles";
import AllPostsTableHeader from "./AllPostsTableHeader";
import AllPostsTableContent from "./AllPostsTableContent";
import Loader from "../widgets/Loader";
import { capitalize } from "utils/string";

interface AccountState {
  data: {
    id: string;
    channels: Array<{
      id: string;
      avatar: string;
      username: string;
      service: string;
    }>;
  };
}

interface RootState {
  account: AccountState;
}

const AllPostsTable: React.FC = () => {
  const account = useSelector((state: RootState) => state.account.data);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "ascending" | "descending";
  } | null>(null);
  const isLoading = useAnalyticsState(state => state.allPosts.isLoading);
  const data = useAnalyticsState(state => state.allPosts.data);
  const setData = useAnalyticsActions(state => state.setAllPostsData);
  const period = useAnalyticsState(state => state.allPosts.period);
  const setPeriod = useAnalyticsActions(state => state.setAllPostsPeriod);
  const selectedChannels = useAnalyticsState(
    state => state.allPosts.channelIds
  );
  const setSelectedChannels = useAnalyticsActions(
    state => state.setAllPostsChannelIds
  );
  const refreshAllPosts = useAnalyticsActions(state => state.refreshAllPosts);
  const selectedCategories = useAnalyticsState(
    state => state.allPosts.categories
  );

  useEffect(() => {
    refreshAllPosts();
  }, [refreshAllPosts]);

  const filteredData = useMemo(
    () => data.filter(p => selectedCategories.includes(p.contentTypeId)),
    [data, selectedCategories]
  );

  const sortData = (key: string) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }

    const sortedData = [...data].sort((a, b) => {
      let aValue, bValue;

      if (key === "postedAt") {
        aValue = new Date(a[key]);
        bValue = new Date(b[key]);
      } else if (key === "engagements" || key === "engagementRate") {
        aValue = a.stats.calculated[key];
        bValue = b.stats.calculated[key];
      } else {
        aValue = a.stats.raw[key];
        bValue = b.stats.raw[key];
      }

      if (aValue < bValue) {
        return direction === "ascending" ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const extractTableData = () => {
    return data.map(post => ({
      Channel: post.channel.username,
      "Full Post Text": post.caption,
      "Attachment URL": post.attachment ? post.attachment.url : "N/A",
      "Created At": post.postedAt,
      Category: capitalize(post.contentTypeId),
      Views: post.stats.raw.impressions,
      Likes: post.stats.raw.likes,
      Comments: post.stats.raw.comments,
      Clicks: post.stats.raw.clicks,
      Shares: post.stats.raw.shares,
      "Total Engagement": post.stats.calculated.engagements,
      "Engagement Rate": `${post.stats.calculated.engagementRate}%`
    }));
  };

  return (
    <div>
      <AllPostsTableHeader
        period={period}
        setPeriod={setPeriod}
        loading={isLoading}
        selectedChannels={selectedChannels}
        setSelectedChannels={setSelectedChannels}
        account={account}
        extractTableData={extractTableData}
      />
      {isLoading && <Loader />}
      {/* {error && <div>{error}</div>} */}
      {!isLoading && /* !error && */ filteredData.length === 0 && (
        <NoDataForTheseCriteria>
          No posts available. Please adjust your filters or search criteria.
        </NoDataForTheseCriteria>
      )}
      {!isLoading && /* !error && */ filteredData.length > 0 && (
        <AllPostsTableContent
          data={filteredData}
          sortConfig={sortConfig}
          sortData={sortData}
        />
      )}
    </div>
  );
};

export default AllPostsTable;

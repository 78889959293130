import React, { PropsWithChildren } from "react";
import { TooltipContainer, TooltipTitle, TooltipDescription } from "./Metrics";

export default function WidgetTooltip({
  title,
  description,
  children
}: PropsWithChildren<{ title?: string; description?: string }>) {
  return (
    <TooltipContainer>
      {title && <TooltipTitle>{title}</TooltipTitle>}
      {description && <TooltipDescription>{description}</TooltipDescription>}
      {children && (
        <TooltipDescription as={"div"}>{children}</TooltipDescription>
      )}
    </TooltipContainer>
  );
}

import qs from "qs";
import { SubmissionError } from "redux-form";
import { useHistory } from "react-router-dom";
import { Box } from "rebass/styled-components";
import React, { useState, useEffect } from "react";
import { useToaster } from "@hellocontento/maillard";
import { useDispatch, useSelector } from "react-redux";
import { SwitchTransition, Transition } from "react-transition-group";

import {
  StepIndicator,
  OnboardingLayout,
  OnboardingHeader,
  OnboardingContainer
} from "./styles";
import { useContentoApi } from "utils/useContentoApi";
import { userInfoRequested } from "state/actions/AuthActions";
import willowLogo from "assets/images/willow-text-color@2x.png";
import { FormBox, FormTitle } from "components/common/form/styles";
import { ForgotBox, ForgotNativeLink } from "components/auth/styles";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import OnboardingInitialForm from "components/onboarding/OnboardingInitialForm";
import OnboardingDetailsForm from "components/onboarding/OnboardingDetailsForm";
import OnboardingChannelForm from "components/onboarding/OnboardingChannelForm";

const formStepTitles = [
  "Ready, set, can you check this?",
  "Let’s personalize the Willow brain…",
  "Time to connect your channels"
];

const steps = ["initial", "details", "channel"];

const OnboardingPage = React.memo(() => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const addToast = useToaster();
  const history = useHistory();
  const user = useSelector(state => state.auth.currentUser);
  const dispatch = useDispatch();

  const [createAccount] = useContentoApi(`/users/${user.id}/accounts`, "post");
  const [fetchAccount, cancelFetchAccount] = useContentoApi(
    `/accounts/${query.accountId}`,
    "get"
  );

  const [account, setAccount] = useState(null);
  const [formValues, setFormValues] = useState({});
  const [step, setStep] = useState(query.accountId ? 2 : 0);

  useEffect(() => {
    if (query.channelType) {
      dispatch(
        trackAnalyticsEvent("Channel Connected", {
          channelType: query.channelType,
          location: "onboarding"
        })
      );
    }
  }, [query.channelType, dispatch]);

  useEffect(() => {
    if (query.accountId) {
      fetchAccount()
        .then(setAccount)
        .catch(err => {
          addToast(err.message, "error");
        });
    }
    return cancelFetchAccount;
  }, [addToast, cancelFetchAccount, fetchAccount, query.accountId]);

  useEffect(() => {
    if (user.accounts.length > 0 && step < 2) {
      history.push(`/onboarding?accountId=${user.accounts[0].id}`);
      setStep(2);
    }
  }, [user.accounts, step, history]);

  const openHubSpotChat = e => {
    e.preventDefault();

    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    }
  };

  const handleNextStep = values => {
    setFormValues({
      ...formValues,
      ...values
    });
    setStep(step + 1);
  };

  const handleCreateAccount = values => {
    const submitValues = {
      ...formValues,
      ...values
    };

    return createAccount({
      data: submitValues
    })
      .then(res => {
        setStep(step + 1);
        //refresh user info as an account will be added, this helps prevent double onboardings
        dispatch(userInfoRequested());
        setAccount(res);
        dispatch(trackAnalyticsEvent("Trial Started"));
      })
      .catch(err => {
        console.error(err);
        addToast(err.message, "error");
        throw new SubmissionError({ _error: err.message });
      });
  };

  const handleFinish = () => {
    history.push(`/accounts/${account.id}/dashboard`);
  };

  const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <OnboardingLayout>
      <OnboardingHeader>
        <img src={willowLogo} alt="Willow" height="40" />
      </OnboardingHeader>
      <OnboardingContainer>
        <FormTitle>{formStepTitles[step]}</FormTitle>
        <FormBox marginTop={32}>
          <StepIndicator>Step {step + 1} of 3</StepIndicator>
          <Box width="100%" overflow="hidden">
            <SwitchTransition
              mode="out-in"
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
            >
              <Transition
                key={steps[step]}
                unmountOnExit
                mountOnEnter
                timeout={250}
              >
                {state =>
                  steps[step] === "initial" ? (
                    <OnboardingInitialForm
                      state={state}
                      onSubmit={handleNextStep}
                    />
                  ) : steps[step] === "details" ? (
                    <OnboardingDetailsForm
                      state={state}
                      onSubmit={handleCreateAccount}
                      initialValues={{ timezone: defaultTimeZone }}
                    />
                  ) : (
                    <OnboardingChannelForm
                      state={state}
                      account={account}
                      onSkip={handleFinish}
                    />
                  )
                }
              </Transition>
            </SwitchTransition>
          </Box>
        </FormBox>
        <ForgotBox marginBottom={32}>
          Having trouble or questions?{" "}
          <ForgotNativeLink href="#chat" onClick={openHubSpotChat}>
            We can help
          </ForgotNativeLink>
          .
        </ForgotBox>
      </OnboardingContainer>
    </OnboardingLayout>
  );
});

export default OnboardingPage;

import React, { useEffect, useState, useCallback } from "react";
import { useToaster } from "@hellocontento/maillard";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  RouteComponentProps
} from "react-router-dom";
import SidebarNew from "components/content/sidebar/SidebarNew";
import { Box } from "rebass";
import ContentRouter from "./ContentRouter";
import ContentHeader from "../content/ContentHeader";
import ShowOnRoutes from "hoc/ShowOnRoutes";
import ArticleSearchBar from "components/content/articleSearch/ArticleSearchBar";
import { useContentoApi } from "utils/useContentoApi";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { quickSearchByCategory } from "services/news";
import { NewsProvider } from "contextApi/newsContext/useNewsContext";
import { IdeasBody, ContentWrapper } from "components/ideas/styles";
import { MainContainer, MainWrapper } from "components/common/styles";

interface IdeasPageProps
  extends RouteComponentProps<{ postConceptSlug: string }> {}

const IdeasPage: React.FC<IdeasPageProps> = React.memo(({ match }) => {
  const addToast = useToaster();
  const dispatch = useDispatch();
  const account = useSelector((state: any) => state.account.data);
  const location = useLocation();

  const [postConcepts, setPostConcepts] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);


  const [fetchPostConcepts, cancelFetchPostConcepts] =
    useContentoApi("post-concepts");

  useEffect(() => {
    dispatch(trackAnalyticsEvent("Ideas Viewed"));
  }, [dispatch]);

  useEffect(() => {
    fetchPostConcepts()
      // @ts-ignore
      .then(setPostConcepts)
      .catch(error => {
        addToast(error.message, "error");
      });

    // return () => {
    //   cancelFetchPostConcepts();
    // };
  }, [cancelFetchPostConcepts, fetchPostConcepts, addToast]);

  const searchForSources = useCallback(async () => {
    try {
      const results = await quickSearchByCategory({
        searchTerm: searchValue
      });
      setSearchSuggestions(results);
    } catch (e) {
      addToast("There was an error searching for the sources", "error");
    }
  }, [searchValue, addToast]);

  useEffect(() => {
    const searchDelay = setTimeout(() => {
      if (searchValue !== "") searchForSources();
      else setSearchSuggestions([]);
    }, 200);
    return () => clearTimeout(searchDelay);
  }, [searchValue, searchForSources]);

  if (!postConcepts) {
    return null;
  }

  const shouldHideContentHeaderAndSearchBar =
    location.pathname.endsWith("/your-ideas") ||
    location.pathname.endsWith("/whatsapp");

  return (
    <IdeasBody>
      {/* @ts-ignore */}
      <SidebarNew match={match} accountId={account.id} />
      <NewsProvider>
        {/* @ts-ignore */}
        <ContentWrapper>
          {!shouldHideContentHeaderAndSearchBar && (
            <>
              <ContentHeader
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                searchSuggestions={searchSuggestions}
              />
              <ShowOnRoutes
                component={ArticleSearchBar}
                allowedRoutes={["/search"]}
              />
            </>
          )}
          <MainContainer>
            <MainWrapper>
              <Box flex={"1"}>
                <ContentRouter />
              </Box>
            </MainWrapper>
          </MainContainer>
        </ContentWrapper>
      </NewsProvider>
    </IdeasBody>
  );
});

export default IdeasPage;

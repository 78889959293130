import React, { useEffect } from "react";
import { Flex } from "rebass/styled-components";

import Button from "components/common/Button";
import {
  FocusInfo,
  SessionImage
} from "components/dashboard/onboarding/styles";
import { FormBox } from "components/common/form/styles";
import { telenetCoachingAddonMapping } from "constants/index";
import PersonalGrowthExpert from "assets/images/dashboard/personal-growth-expert.png";

export default function Booking({
  currency,
  onboarding,
  onBooking,
  onRedirect,
  onCancel
}) {
  const onboardingAddons = Object.values(
    telenetCoachingAddonMapping[currency]
  ).map(o => o.toLowerCase());

  useEffect(() => {
    if (!onboarding || !onboardingAddons.includes(onboarding.toLowerCase())) {
      onRedirect();
    }
  }, [onboarding, onboardingAddons, onRedirect]);

  return (
    <FormBox maxWidth="380px">
      <Flex my={32} flexDirection="column">
        <Flex width={"100%"} justifyContent={"center"}>
          <SessionImage src={PersonalGrowthExpert}></SessionImage>
        </Flex>
        <FocusInfo>
          Book your {onboarding === "LIGHT" ? "30" : "45"}min strategy session
          with our experts
        </FocusInfo>
      </Flex>
      <Flex
        mb={16}
        flexDirection="row"
        justifyContent="space-evenly"
        width="100%"
      >
        <Button variant="primary" size="lg" onClick={onBooking}>
          Book Now
        </Button>
        <Button variant="secondary" size="lg" onClick={onCancel}>
          I'll do it later
        </Button>
      </Flex>
    </FormBox>
  );
}

import {
  RESET_STATE,
  SET_IS_FETCHING,
  SET_POST_TYPE,
  SET_FOCUSED_DATE,
  SET_DATE_TO_BE_FOCUSED,
  UPDATE_TIMELINE_ACTIVITIES,
  SET_DATE_RANGE_TO_BE_FETCHED
} from "./actions";
import { PostType, ITimelineState } from "./reducer";

export interface IResetState {
  type: typeof RESET_STATE;
}

export const resetState = (): IResetState => ({
  type: RESET_STATE
});

export interface ISetIsFetching {
  type: typeof SET_IS_FETCHING;
  payload: boolean;
}

export const setIsFetching = (payload: boolean): ISetIsFetching => ({
  type: SET_IS_FETCHING,
  payload
});

export interface ISetPostType {
  type: typeof SET_POST_TYPE;
  payload: PostType;
}

export const setPostType = (payload: PostType): ISetPostType => ({
  type: SET_POST_TYPE,
  payload
});

export interface ISetFocusedDate {
  type: typeof SET_FOCUSED_DATE;
  payload: ITimelineState["focusedDate"];
}

export const setFocusedDate = (
  payload: ITimelineState["focusedDate"]
): ISetFocusedDate => ({
  type: SET_FOCUSED_DATE,
  payload
});

export interface ISetDateToBeFocused {
  type: typeof SET_DATE_TO_BE_FOCUSED;
  payload: ITimelineState["dateToBeFocused"];
}

export const setDateToBeFocused = (
  payload: ITimelineState["dateToBeFocused"]
): ISetDateToBeFocused => ({
  type: SET_DATE_TO_BE_FOCUSED,
  payload
});

export type UpdateTimelineActivitiesPayload = {
  postType: PostType;
  entries: any[];
  lastUpdated: Date;
  month: string;
};
export interface IUpdateTimelineActivities {
  type: typeof UPDATE_TIMELINE_ACTIVITIES;
  payload: UpdateTimelineActivitiesPayload;
}

export const updateTimelineActivities = (
  payload: UpdateTimelineActivitiesPayload
): IUpdateTimelineActivities => {
  return {
    type: UPDATE_TIMELINE_ACTIVITIES,
    payload
  };
};

export interface ISetDateRangeToBeFetched {
  type: typeof SET_DATE_RANGE_TO_BE_FETCHED;
  payload: ITimelineState["dateRangeToBeFetched"];
}

export const setDateRangeToBeFetched = (
  payload: ITimelineState["dateRangeToBeFetched"]
): ISetDateRangeToBeFetched => {
  return {
    type: SET_DATE_RANGE_TO_BE_FETCHED,
    payload
  };
};

export type IActions =
  | IResetState
  | ISetIsFetching
  | ISetPostType
  | ISetFocusedDate
  | ISetDateToBeFocused
  | IUpdateTimelineActivities
  | ISetDateRangeToBeFetched;

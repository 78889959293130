// @ts-nocheck
import React from "react";

import {
  MenuList,
  MenuDivider,
  MenuContainer
} from "components/navigation/Menu";
import { MenuItem } from "./styles";

interface IPublishedPostMenu {
  copyLink: (e: React.MouseEvent) => void;
  sharePost: (e: React.MouseEvent) => void;
  visitPost: (e: React.MouseEvent) => void;
  deletePost: (e: React.MouseEvent) => void;
  duplicatePost: (e: React.MouseEvent) => void;
}

const PublishedPostMenu: React.FC<IPublishedPostMenu> = ({
  copyLink,
  // sharePost,
  visitPost,
  deletePost,
  duplicatePost
}) => {
  return (
    <MenuContainer width={240}>
      <MenuList>
        <MenuItem label="Visit post" icon="external" onClick={visitPost} />
        <MenuItem label="Copy link" icon="clicks" onClick={copyLink} />
      </MenuList>
      <MenuList>
        <MenuItem label="Duplicate post" icon="copy" onClick={duplicatePost} />
        {/* <MenuItem
        icon="share"
        color="green"
        label="Share post"
        onClick={sharePost}
        /> */}
      </MenuList>
      {/* <MenuDivider my={2} /> */}
      <MenuDivider my={2} />
      <MenuList>
        <MenuItem
          icon="delete"
          color="danger"
          label="Delete post"
          onClick={deletePost}
        />
      </MenuList>
    </MenuContainer>
  );
};

export default PublishedPostMenu;

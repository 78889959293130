import React from "react";
import { connect } from "react-redux";
import * as accountActions from "../../state/actions/AccountActions";
import * as authActions from "../../state/actions/AuthActions";

import { UserSettings, CompanySettings } from "./forms";
import AvatarUpload from "components/common/form/AvatarUpload";
import { useToaster } from "@hellocontento/maillard";
import {
  SettingsTitle,
  SettingsDescription,
  SettingsHeader,
  SettingsSection,
  SettingsSubtitle,
  SettingsBrick,
  SettingsIconContainer,
  IconInfoContainer
} from "./styles";

function Account({ account, updateAccount, currentUser, updateUser }) {
  const addToast = useToaster();

  const handleImageUpload = images => {
    if (images.length > 0) {
      updateAccount({ logo: images[0].path });
      addToast("Logo updated successfully", "success");
    }
  };

  const handleUserUpdate = data => {
    try {
      updateUser(data);
      addToast("Your account details have been saved.", "success");
    } catch (e) {
      addToast("Your account details could not be saved.", "error");
    }
  };

  const handleAccountUpdate = data => {
    try {
      updateAccount(data);
      addToast("Your company details have been saved.", "success");
    } catch (e) {
      addToast("Your company details could not be saved.", "error");
    }
  };

  return (
    <>
      <SettingsHeader>
        <SettingsTitle>Account</SettingsTitle>
        <SettingsDescription>
          Here you can change your email address, company name, profile photo
          and many more.
        </SettingsDescription>
      </SettingsHeader>
      <SettingsSection>
        <SettingsIconContainer>
          <AvatarUpload
            label="Company logo"
            onUpload={handleImageUpload}
            value={account.logo}
            openFileModalByDefault={true}
          />
          <IconInfoContainer>
            <h3>Icon</h3>
            <p>
              Upload your company's logo. It will show up in your sidebar and
              notifications. Recommended size is 240x240.
            </p>
          </IconInfoContainer>
        </SettingsIconContainer>
      </SettingsSection>
      <SettingsSection>
        <SettingsSubtitle>Account information</SettingsSubtitle>
        <SettingsBrick>
          <UserSettings
            onSubmit={handleUserUpdate}
            initialValues={currentUser}
          />
        </SettingsBrick>
      </SettingsSection>
      <SettingsSection>
        <SettingsSubtitle>Company information</SettingsSubtitle>
        <SettingsBrick>
          <CompanySettings
            onSubmit={handleAccountUpdate}
            initialValues={account}
          />
        </SettingsBrick>
      </SettingsSection>
    </>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {
  updateAccount: accountActions.updateAccount,
  updateUser: authActions.updateUser
})(Account);

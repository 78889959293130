import styled, { css } from "styled-components";
import { darken } from "polished";
import { Flex, Box, Text } from "rebass/styled-components";
import { TextInput } from "../settings/styles";

import subscribeTestimonial from "../../assets/images/subscribe-testimonial.jpg";
import { SubTitle2 } from "../common/styles";
import Button from "../common/Button";

export const HeadLine4Web = styled(Text)`
  font-size: 30px;
  line-height: 38px;
  letter-spacing: -0.5px;
  color: #000000;
`;
export const HeadLine6Web = styled(Text)`
  font-size: 20px;
  line-height: 38px;
  letter-spacing: -0.3px;
  color: #000000;
  margin-bottom: 20px;
`;

export const BasketContainer = styled(Flex)`
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.08),
    0px 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
`;

export const BillingNotice = styled(Box)`
  background-color: ${props => props.theme.colors.grey01};
  border-radius: 8px;
  color: ${props => props.theme.colors.text01};
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.24px;
  padding: 16px;
`;

export const BasketLine = styled(Flex)`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 12px;
  font-size: 16px;
  line-height: 20px;
  font-weight: normal;
  padding: 4px 0;
`;

export const BasketLabel = styled.span`
  color: ${props => props.theme.colors.text01};
  font-weight: bold;
`;

export const BasketValue = styled.span`
  color: ${props => props.theme.colors.text02};
  text-align: right;

  ${props =>
    props.large &&
    css`
      color: ${props => props.theme.colors.text01};
      font-size: 22px;
      font-weight: bold;
      letter-spacing: -0.33px;
    `};

  & small {
    color: ${props => props.theme.colors.text02};
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
  }
`;

export const DiscountPriceContainer = styled.div`
  position: relative;
`;

export const DeleteDiscountContainer = styled.div`
  position: absolute;
  right: -25px;
  top: 0;
`;

export const SubscribeBanner = styled(Flex)`
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 60.95%,
      rgba(0, 0, 0, 0.22954) 74.18%,
      #000000 100%
    ),
    url(${subscribeTestimonial});
  background-size: cover;
  width: 245px;
  min-height: 100vh;
  position: sticky;
`;

export const BannerQuote = styled(Box)`
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  color: #ffffff;
`;

export const BannerName = styled(Box)`
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

export const BillingCycleContainer = styled(Flex)`
  cursor: pointer;
  border: 1px solid ${props => (props.selected ? "#0061FF" : "#EAECED")};
  box-sizing: border-box;
  border-radius: 8px;
  height: 77px;
  background: ${props => (props.selected ? "rgba(0, 97, 255, 0.08)" : "none")};
`;

export const BillingCycleTitle = styled(Text)`
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.1px;
  color: #494949;
`;

export const BillingCyclePrice = styled(Text)`
  font-size: 14px;
  line-height: 16px;
  color: #494949;
`;

export const BillingCycleSaving = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color: #0e9c8b;
`;

/**
 * Discount styling
 */

export const DiscountInput = styled(TextInput)`
  margin-right: 20px;
`;

export const LeadDiscountWrapper = styled(Flex)`
  background: #ffffff;
  box-shadow:
    0px 1.2px 3.6px rgba(0, 0, 0, 0.108),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.132);
  border-radius: 12px;
`;

export const LeadDiscountTitle = styled(SubTitle2)`
  color: #0e2482;
`;

/**
 * Currency Picker
 **/

export const CurrencyPickerWrapper = styled(Flex)`
  position: relative;
`;

export const CurrencyLink = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 2px;
`;

export const CurrencyPickerMenu = styled(Flex)`
  align-items: flex-end;
  flex-direction: column;
  position: absolute;
  top: 10px;
  right: 0px;
  padding: 8px;
  width: 100px;
  display: ${props => (props.visible ? "flex" : "none")};
`;

// Self signup 2021
// TODO: Cleanup traces of old onboarding and old checkout
export const PlanName = styled(Text)`
  color: ${props => props.color ?? "inherit"};
  font-size: 16px;
  font-weight: bold;
  font-family: ${props => props.theme.fonts.headline};
`;

export const PlanPrice = styled(Text)`
  color: ${props => props.color ?? props.theme.colors.text01};
  display: flex;
  font-size: 44px;
  font-weight: 500;
  line-height: 0.6;
  margin: 16px 0;
  position: relative;

  &::before {
    align-self: flex-start;
    content: "${props => (props.currency === "EUR" ? "€" : "$")}";
    display: block;
    font-size: 18px;
    margin-right: 2px;
  }
  &::after {
    align-self: flex-end;
    content: "${props =>
      props.period === "year" ? "/yr" : props.period === "once" ? "" : "/mo"}";
    display: block;
    font-size: 14px;
    opacity: 0.5;
  }
`;

export const PlanDescription = styled(Text)`
  color: ${props => props.color ?? props.theme.colors.text01};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.16px;
  margin: 12px 0 24px;
`;

export const PlanHeader = styled(Flex)`
  border-bottom: 1px solid
    ${props =>
      props.borderColor === "white"
        ? "rgba(235, 245, 245, 0.2)"
        : props.theme.colors.grey01};
  flex-direction: column;
  width: 100%;
`;

export const PlanFeatures = styled(Flex)`
  align-items: stretch;
  flex-direction: column;
  flex: 1;
  margin: 16px 0 24px;
  letter-spacing: -0.16px;
  width: 100%;
`;

export const PlanFeature = styled(Box)`
  align-items: flex-start;
  color: ${props => props.color ?? props.theme.colors.text01};
  display: grid;
  grid-template-columns: 18px 1fr;
  grid-column-gap: 4px;
  font-size: 16px;
  font-weight: ${props => (props.color === "white" ? "400" : "600")};
  line-height: 23px;
  margin: 7px 0;

  & > svg {
    margin-top: 6px;
  }
`;

export const ColorButton = styled(Button)`
  background-color: ${props =>
    props.backgroundColor ?? props.theme.colors.text01};

  &:hover {
    background-color: ${props =>
      darken(0.1, props.backgroundColor ?? props.theme.colors.text01)};
  }
`;

ColorButton.defaultProps = {
  variant: "primary"
};

export const PrimaryWhiteButton = styled(Button)`
  background-color: white;
  color: ${props => props.theme.colors.text01};

  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.5);
  }
  &:focus {
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5) !important;
  }
`;
export const SecondaryWhiteButton = styled(Button)`
  background-color: rgba(235, 235, 245, 0.25);
  color: white;

  &:hover {
    background-color: rgba(255, 255, 255, 0.33);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0.66);
  }
`;

export const RESET_STATE = "RESET_STATE";
export const SET_FILTER_RANGE = "SET_FILTER_RANGE";
export const UPDATE_ACTIVITIES = "UPDATE_ACTIVITIES";
export const UPDATE_ACTIVITIES_FETCHING_STATE =
  "UPDATE_ACTIVITIES_FETCHING_STATE";
export const UPDATE_SOCIAL_HEALTH = "UPDATE_SOCIAL_HEALTH";
export const UPDATE_SOCIAL_HEALTH_FETCHING_STATE =
  "UPDATE_SOCIAL_HEALTH_FETCHING_STATE";
export const UPDATE_STATS = "UPDATE_STATS";
export const UPDATE_STATS_FETCHING_STATE = "UPDATE_STATS_FETCHING_STATE";

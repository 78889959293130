import { debounce } from "lodash";
import { useSelector } from "react-redux";
import React, { useMemo, useEffect, useState, useCallback } from "react";

import {
  today,
  endOfMonth,
  startOfMonth,
  getDateLabel,
  parseStringToDate
} from "utils/date";
import {
  useTimelineState,
  setDateToBeFocused,
  useTimelineDispatch,
  setDateRangeToBeFetched
} from "../context";
import TimelineDatePicker from "../../common/timelineDatePicker";

const Calendar: React.FC<{}> = () => {
  const account = useSelector<any, any>(state => state.account.data);
  const timelineState = useTimelineState();
  const { focusedDate, postType, activities } = timelineState;
  const [previewDate, setPreviewDate] = useState<Date>(
    focusedDate ? parseStringToDate(focusedDate, "yyyy/M/d") : today()
  );
  const timelineDispatch = useTimelineDispatch();

  const currentDate = useMemo(
    () =>
      !!focusedDate ? parseStringToDate(focusedDate, "yyyy/M/d") : today(),
    [focusedDate]
  );

  const changePreviewDate = useCallback(
    (date: Date) => {
      timelineDispatch(
        setDateRangeToBeFetched({
          start: startOfMonth(date),
          end: endOfMonth(date)
        })
      );
    },
    [timelineDispatch]
  );

  const handlePreviewChange = useCallback(
    (date: Date) => {
      setPreviewDate(date);
      // debouncedChangePreviewDate(date); // ? debounce causes an issue with the scroll api call
      changePreviewDate(date);
    },
    [changePreviewDate]
  );

  useEffect(() => {
    setPreviewDate(today());
  }, [postType]);

  useEffect(() => {
    setPreviewDate(currentDate);
  }, [currentDate]);

  const handleDateChange = useCallback(
    (date: Date) => {
      timelineDispatch(setDateToBeFocused(getDateLabel(date)));
    },
    [timelineDispatch]
  );

  return (
    <div>
      <div>
        <TimelineDatePicker
          account={account}
          focusDate={currentDate}
          dateEnd={postType === "published" ? today() : null}
          previewDate={previewDate}
          repetitionMode={false}
          nthWeek={null}
          weeklyRange={[]}
          onDateChange={handleDateChange}
          onDatePreview={handlePreviewChange}
          disablePastDate={postType === "scheduled"}
          disableFutureDate={postType === "published"}
          inline
          size={"sm"}
          feedEntries={
            !!postType
              ? activities[getDateLabel(previewDate).slice(0, 7)]?.[postType]
                  ?.entries ?? []
              : []
          }
          period={{
            start: today(),
            end: today()
          }}
        />
      </div>
    </div>
  );
};

export default Calendar;

import Modal from "react-modal";
import { Flex, Box } from "rebass";
import styled, { css } from "styled-components";
import React, { useCallback, useMemo } from "react";

import {
  useNotificationState,
  useNotificationActions,
  IFeatureNotificationStepWithComponent,
  IFeatureNotificationStepWithoutComponent,
  IFeatureNotification
} from "contextApi/notificationContext";
import Button from "components/common/Button";
import { useAppState } from "contextApi/appContext";
import { Headline2 } from "components/common/styles";

const InfoImage = styled.img`
  width: 100%;
  max-height: 288px;
  object-fit: cover;
`;

const InfoWrapper = styled(Flex)`
  padding: 40px 56px;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  text-align: center;
  justify-content: center;
`;

const InfoPara = styled.p`
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: center;
  font-size: 16px;
  line-height: 22.4px;
  margin-bottom: 78px;
  padding: 0 35px;
  color: ${props => props.theme.colors.text01};
`;

interface IButtonGroup {
  layout: IFeatureNotification["buttonLayout"];
}

const ButtonGroup = styled(Flex)<IButtonGroup>`
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 8px;

  > button {
    width: 392px;
  }

  ${props =>
    !!props.layout &&
    props.layout === "horizontal" &&
    css`
      width: auto;
      flex-direction: row;
      gap: 10px;

      > button {
        width: auto;
      }
    `}
`;

const LearnMore = styled.a`
  color: ${props => props.theme.colors.blue};
  font-weight: 600;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const defaultModalMaxWidth = 520;

export const modalStyles = () => {
  return {
    // dark background behind all modals
    overlay: {
      background: "rgba(0,0,0,0.25)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      //overflowY: 'visible',
      overflowX: "hidden",
      zIndex: 10,
      padding: "15px 20px 15px 20px"
    },
    // modal root
    content: {
      overflowX: "hidden",
      overflowY: "auto",
      maxHeight: "95vh",
      boxSizing: "border-box",
      background: "#F7F8F9",
      backgroundClip: "padding-box",
      borderRadius: "24px",
      position: "relative",
      border: "1px",
      borderColor: "#EAEBEB",
      width: `${defaultModalMaxWidth}px`,
      top: "auto",
      bottom: "auto",
      left: "auto",
      right: "auto",
      backgroundColor: "white",
      boxShadow:
        "0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)",
      padding: 0
    }
  };
};

interface IFeatureNotificationModal {
  maxWidth?: number;
  showFooter?: boolean;
}

const FeatureNotificationModal: React.FC<IFeatureNotificationModal> =
  React.memo(() => {
    const size = useAppState(state => state.windowSize);
    const styles = useMemo(() => modalStyles(), []);
    const activeFeatureNotification = useNotificationState(
      state => state.activeFeatureNotification
    );
    const gotoNextStep = useNotificationActions(
      actions => actions.gotoNextStep
    );
    const gotoPrevStep = useNotificationActions(
      actions => actions.gotoPrevStep
    );
    const completeFeatureNotificationEntry = useNotificationActions(
      actions => actions.completeFeatureNotificationEntry
    );

    const maxWidth = useMemo(
      () => activeFeatureNotification?.maxWidth,
      [activeFeatureNotification]
    );

    if (size !== "large") {
      if (!!maxWidth && maxWidth > defaultModalMaxWidth) {
        styles.content.width = `calc(${maxWidth}px - 15%)`;
      } else {
        styles.content.width = `calc(${defaultModalMaxWidth}px - 10%)`;
      }
    } else if (!!maxWidth && maxWidth > defaultModalMaxWidth) {
      styles.content.width = `${maxWidth}px`;
    }

    const prevStepHandler = useCallback(() => {
      if (activeFeatureNotification) {
        const { steps, currentStep } = activeFeatureNotification;

        const currentStepItem = steps[currentStep];
        if (!!currentStepItem && !!currentStepItem.onReturn) {
          currentStepItem.onReturn();
        }
        gotoPrevStep();
      }
    }, [gotoPrevStep, activeFeatureNotification]);

    const nextStepHandler = useCallback(() => {
      if (activeFeatureNotification) {
        const { steps, currentStep } = activeFeatureNotification;

        const currentStepItem = steps[currentStep];
        if (!!currentStepItem && !!currentStepItem.onProceed) {
          currentStepItem.onProceed();
        }

        if (currentStep === steps.length - 1) {
          completeFeatureNotificationEntry(activeFeatureNotification);
        } else {
          gotoNextStep();
        }
      }
    }, [
      gotoNextStep,
      activeFeatureNotification,
      completeFeatureNotificationEntry
    ]);

    const onCancel = useCallback(() => {
      if (activeFeatureNotification) {
        const { steps, currentStep } = activeFeatureNotification;

        const currentStepItem = steps[currentStep];
        if (!!currentStepItem && !!currentStepItem.onCancel) {
          currentStepItem.onCancel();
        }

        completeFeatureNotificationEntry(activeFeatureNotification);
      }
    }, [activeFeatureNotification, completeFeatureNotificationEntry]);

    if (!activeFeatureNotification) {
      return null;
    }

    const { id, steps, currentStep, dismissible, buttonLayout } =
      activeFeatureNotification;

    const {
      banner,
      helpUrl,
      title = "",
      description = "",
      cancelButtonText,
      returnButtonText,
      proceedButtonText,
      cancelButtonVariant
    } = steps[currentStep] as IFeatureNotificationStepWithoutComponent;
    const StepComponent: any = (
      steps[currentStep] as IFeatureNotificationStepWithComponent
    ).component;

    return (
      <Modal
        name={id}
        ariaHideApp={false}
        isOpen={true}
        // @ts-ignore
        style={styles}
        onRequestClose={dismissible ? onCancel : undefined}
      >
        {!StepComponent && banner && <InfoImage src={banner} />}
        <InfoWrapper>
          {!!StepComponent ? (
            <StepComponent />
          ) : (
            <>
              <Box mb={16}>
                <Headline2>{title}</Headline2>
              </Box>
              <InfoPara>
                {description}{" "}
                {helpUrl && (
                  <LearnMore
                    target="_blank"
                    rel="noopener noreferrer"
                    href={helpUrl}
                  >
                    {" "}
                    Learn more
                  </LearnMore>
                )}
              </InfoPara>
            </>
          )}
          <ButtonGroup layout={buttonLayout}>
            {/* @ts-ignore */}
            <Button variant="primary" onClick={nextStepHandler}>
              {!!proceedButtonText
                ? proceedButtonText
                : steps.length - 1 === currentStep
                  ? "Get Started"
                  : "Next"}
            </Button>
            {(currentStep > 0 || steps.length === 1) &&
              returnButtonText !== null && (
                // @ts-ignore
                <Button variant="secondaryQuiet" onClick={prevStepHandler}>
                  {!!returnButtonText ? returnButtonText : "Back"}
                </Button>
              )}
            {(currentStep > 0 || steps.length === 1) &&
              returnButtonText === null &&
              !!cancelButtonText && (
                // @ts-ignore
                <Button
                  variant={cancelButtonVariant || "secondary"}
                  onClick={onCancel}
                >
                  {cancelButtonText}
                </Button>
              )}
          </ButtonGroup>
        </InfoWrapper>
      </Modal>
    );
  });

export default FeatureNotificationModal;

import React from "react";

import {
  PdfList,
  PostImage,
  ImageWrapper,
  AttachmentAction,
  AttachmentActions,
  AttachmentContainer
} from "./styles";
import PdfUpload from "./PdfUpload";
import { ToolTip } from "components/schedule/common/styles";

const PdfAttachment = ({
  pdfs,
  onError,
  onRemove,
  onUpload,
  isUploading,
  setIsUploading
}) => {
  return (
    <AttachmentContainer>
      <PdfList>
        {pdfs &&
          pdfs.length > 0 &&
          pdfs.map((pdf, index) => (
            <ImageWrapper
              className={"image"}
              data-tip={pdf.metaData.fileName}
              data-for="image-attachment"
              key={`pdf-${index}`}
            >
              <AttachmentActions>
                <AttachmentAction
                  onClick={() => onRemove(index)}
                  data-tip="Remove"
                  data-for="attachment-action"
                >
                  <i className="icon-cancel" />
                </AttachmentAction>
              </AttachmentActions>
              <PostImage src={pdf.metaData.preview} />
              <ToolTip id="attachment-action" place="top" className="tooltip" />
              <ToolTip
                id="image-attachment"
                place="bottom"
                size="medium"
                className="tooltip"
                delayShow={1000}
              />
            </ImageWrapper>
          ))}
        {(!pdfs || pdfs.length <= 0) && (
          <PdfUpload
            onUpload={onUpload}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
            onError={onError}
          />
        )}
      </PdfList>
    </AttachmentContainer>
  );
};

export default PdfAttachment;

import React from "react";
import {
  AttachmentContainer,
  PostVideo,
  VideoWrapper,
  VideoList
} from "./styles";
import VideoUpload from "./VideoUpload";
import { X } from "@styled-icons/feather";

const VideoAttachment = ({ video, onRemove, onUpload, onUploadStart }) => {
  return (
    <AttachmentContainer>
      <VideoList>
        {video && (
          <VideoWrapper key={video} className={"video"}>
            <PostVideo width={300} height={170} controls>
              <source src={video} type={"video/mp4"} />
            </PostVideo>
            <div className={"x"} onClick={() => onRemove(video)}>
              <X color={"#fff"} size={22} />
            </div>
          </VideoWrapper>
        )}
        {!video && (
          <VideoUpload onUpload={onUpload} onUploadStart={onUploadStart} />
        )}
      </VideoList>
    </AttachmentContainer>
  );
};

export default VideoAttachment;

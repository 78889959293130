import { Flex } from "rebass";
import styled from "styled-components";

export const NewsBody = styled.div`
  width: 100%;
  display: flex;
`;

export const ContentWrapper = styled.div`
  flex: 1;
  position: relative;
`;

export const ButtonContent = styled(Flex)`
  display: inline-flex;
  gap: 8px;
  align-items: center;
  color: ${props => props.theme.colors.text02};
  font-weight: normal;
  font-size: 14px;
  width: 240px;
  line-height: 140%;
  letter-spacing: -0.015em;
  font-family: Inter;

  > span {
    display: inline-flex;
    width: 20px;
    height: 20px;
    font-size: 16px;
    align-items: center;
    justify-content: center;
  }
`;

export const TopicStatus = styled(Flex)`
  gap: 10px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px;
  border-left: 1px solid ${props => props.theme.colors.grey03};
`;

export const TopicLabel = styled.span`
  color: ${props => props.theme.colors.text02};
  font-weight: 600;
  text-transform: capitalize;
`;

import moment from "moment";
import { useDispatch } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { Flex, Box } from "rebass/styled-components";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";

import { colors } from "theme";
import ContentDismissReasons, {
  ContentDismissWrapper
} from "../ContentDismissReasons";
import {
  Dislike,
  Bookmark,
  ArrowBack,
  LinkExternal
} from "styled-icons/boxicons-regular";
import Button from "components/common/Button";
import SidePanel from "components/modals/SidePanel";
import { Headline1 } from "components/common/styles";
import IconButton from "components/common/IconButton";
import { ArticleContentWrapper, ArticleMetaInfo } from "./styles";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { useAppState } from "contextApi/appContext";
import Loader from "components/common/loading/Loader";
import { saveContentArticleToIdeas } from "services/news";

const ArticlePreviewModal = ({
  saved,
  onSave,
  content,
  onDismiss,
  closeModal,
  onSchedule
}) => {
  const showIdeasPageRevamp = useAppState(state => state.showIdeasPageRevamp);
  const dispatch = useDispatch();
  const [isDismissVisible, setDismissVisible] = useState(false);
  const [isAddingToIdeas, setIsAddingToIdeas] = useState(false);
  const addToast = useToaster();

  useEffect(() => {
    dispatch(
      trackAnalyticsEvent("Article Read", {
        sourceType: content.sources[0].type
      })
    );
  }, [dispatch]);

  const allTags = Array.from(
    new Set((content.verified_tags || []).concat(content.predicted_tags || []))
  );

  const handleExternalLink = () => {
    window.open(content.url, "_blank");
  };

  const handleSaveArticleToIdeas = useCallback(async () => {
    setIsAddingToIdeas(true);
    
    try {
      await saveContentArticleToIdeas(content);

      addToast("Article Saved to Ideas", "success", "left");
      console.log("Should have added Success Toast by now")
    } catch {
      addToast("Error while Saving Article to Ideas ", "error");
      console.log("Should have added Error Toast by now");
    } finally {
      setIsAddingToIdeas(false);
    }    
  }, [content, setIsAddingToIdeas, addToast]);

  return (
    <SidePanel onClose={closeModal}>
      <Flex
        px={32}
        py={16}
        mb={16}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        bg="white"
        sx={{
          position: "sticky",
          top: 0,
          borderBottom: `1px solid ${colors.grey02}`
        }}
      >
        {/* @ts-ignore */}
        <IconButton onClick={closeModal} icon={ArrowBack} />
        <Flex alignItems="center">
          {!showIdeasPageRevamp && (
            <IconButton
              // @ts-ignore
              mr={8}
              icon={Dislike}
              variant="secondary"
              onClick={() => setDismissVisible(true)}
            />
          )}

          {!showIdeasPageRevamp && (
            <IconButton
              // @ts-ignore
              iconColor={saved ? "#ff652b" : null}
              mr={8}
              icon={Bookmark}
              variant="secondary"
              active={saved}
              onClick={onSave}
            />
          )}

          <IconButton
            // @ts-ignore
            icon={LinkExternal}
            onClick={() => handleExternalLink()}
            variant="secondary"
          />
          {showIdeasPageRevamp && <span>Visit Site</span>}
        </Flex>
        {showIdeasPageRevamp ? (
          <Button variant="primary" onClick={handleSaveArticleToIdeas} disabled={isAddingToIdeas} >
            {isAddingToIdeas && <Loader size={20} location="center" color="blue" />}
            Save Article to Ideas
          </Button>
        ) : (
          <Button variant="primary" onClick={onSchedule}>
            Post Now
          </Button>
        )}
      </Flex>
      <Flex justifyContent="center">
        {isDismissVisible ? (
          <ContentDismissWrapper>
            <ContentDismissReasons
              isModal="true"
              domain={content.domain}
              image={content.favicon || content.logo}
              name={content.publisher}
              topic={null}
              onSubmit={onDismiss}
              onCancel={() => setDismissVisible(false)}
            />
          </ContentDismissWrapper>
        ) : (
          <Box mx="5vw" mt={48} maxWidth={626} minHeight="100vh">
            <Headline1 mb={16}>{content.title}</Headline1>
            <ArticleMetaInfo>
              {content.domain} · Published{" "}
              {moment(content.published_date).fromNow()} · {allTags.join(" / ")}
            </ArticleMetaInfo>
            <ArticleContentWrapper
              dangerouslySetInnerHTML={{ __html: content.article_html }}
            />
          </Box>
        )}
      </Flex>
    </SidePanel>
  );
};

export default ArticlePreviewModal;

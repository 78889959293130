import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { Card, CardWrapper } from "./styles";
import attestant1 from "assets/images/milli-popup/attestant1.jpeg";
import attestant2 from "assets/images/milli-popup/attestant2.jpeg";
import attestant3 from "assets/images/milli-popup/attestant3.jpeg";
import { Body6, Body7, Headline5 } from "components/common/styles";

const CustomerTestimonials: React.FC<{}> = () => {
  return (
    // @ts-ignore
    <Carousel
      autoPlay={true}
      emulateTouch={false}
      infiniteLoop={true}
      interval={6000}
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      swipeable={false}
      width={"100%"}
    >
      <CardWrapper>
        <Card>
          <div className="attestant">
            <img src={attestant1} />
            <Headline5>Ilse Bosland</Headline5>
            <div className="designation">
              <Body7>Verzekeringsspecialist</Body7>
              <Body7>Juffrouw Polis</Body7>
            </div>
          </div>
          <div className="testimonial">
            <Headline5>
              De Willow coach tilt mijn social media naar een hoger niveau
            </Headline5>
            <Body6>
              Willow denkt niet alleen met mij mee, maar wil ook mijn
              branding/socials/bedrijf naar een hoger niveau tillen. Dat is heel
              tof! Super fijn contact met mijn coach ook, heel blij mee!
            </Body6>
          </div>
        </Card>
      </CardWrapper>
      <CardWrapper>
        <Card>
          <div className="attestant">
            <img src={attestant2} />
            <Headline5>Anton De Clercq</Headline5>
            <div className="designation">
              <Body7>Founder</Body7>
              <Body7>Immowi International</Body7>
            </div>
          </div>
          <div className="testimonial">
            <Headline5>Our coach, Virginie, did a great job</Headline5>
            <Body6>
              Our coach is great. She gives her unvarnished opinion and we can
              always spar with her. Willow is easy for content management and
              coaching. In addition, the pricing is very competitive.
            </Body6>
          </div>
        </Card>
      </CardWrapper>
      <CardWrapper>
        <Card>
          <div className="attestant">
            <img src={attestant3} />
            <Headline5>Vincent Neslany</Headline5>
            <div className="designation">
              <Body7>Associate Founder</Body7>
              <Body7>Boekhoudkantoor Sirejacob, Neslany & Partners</Body7>
            </div>
          </div>
          <div className="testimonial">
            <Headline5>Willow geeft me zeer bruikbare tips en tricks</Headline5>
            <Body6>
              Ik kreeg een zeer duidelijke en overzichtelijke uitleg van Laura.
              Tevens gaf ze me zeer bruikbare tips en tricks om mijn social
              media naar een next level te brengen. Een toppertje !
            </Body6>
          </div>
        </Card>
      </CardWrapper>
    </Carousel>
  );
};

export default CustomerTestimonials;

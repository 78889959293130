import {
  SET_WINDOW_SIZE,
  SET_IS_DRAFT_PANEL_OPEN,
  SET_CLIENT_SETTINGS
} from "./actions";
import { IClientSettings, WindowSize } from "./reducer";

export interface ISetWindowSizeAction {
  type: typeof SET_WINDOW_SIZE;
  payload: WindowSize;
}

export const setWindowSizeAction = (
  payload: WindowSize
): ISetWindowSizeAction => ({
  type: SET_WINDOW_SIZE,
  payload
});

export interface ISetIsDraftPanelOpenAction {
  type: typeof SET_IS_DRAFT_PANEL_OPEN;
  payload: boolean;
}

export const setIsDraftPanelOpenAction = (
  payload: boolean
): ISetIsDraftPanelOpenAction => ({
  type: SET_IS_DRAFT_PANEL_OPEN,
  payload
});

export interface ISetClientSettingsAction {
  type: typeof SET_CLIENT_SETTINGS;
  payload: Partial<IClientSettings>;
}

export const setClientSettingsAction = (
  payload: Partial<IClientSettings>
): ISetClientSettingsAction => ({
  type: SET_CLIENT_SETTINGS,
  payload
});

export type IActions =
  | ISetWindowSizeAction
  | ISetIsDraftPanelOpenAction
  | ISetClientSettingsAction;

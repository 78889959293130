import * as dragDropActions from "../actions/DragDropActions";

const initialState = {
  draggingItemId: null,
  isDragging: false
};

const dragDropReducer = (state = initialState, action) => {
  switch (action.type) {
    case dragDropActions.SET_DRAGGING_ITEM:
      return Object.assign({}, state, {
        draggingItemId: action.data,
        isDragging: true
      });
    case dragDropActions.REMOVE_DRAGGING_ITEM:
      return Object.assign({}, state, {
        draggingItemId: null,
        isDragging: false
      });
    default:
      return state;
  }
};

export default dragDropReducer;

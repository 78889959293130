import _ from "lodash";
import { Flex } from "rebass";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useMemo, useCallback } from "react";

import {
  SecondaryHeader,
  SecondaryHeaderContainer
} from "components/navigation/styles";
import {
  useNewsState,
  useNewsDispatch
} from "contextApi/newsContext/useNewsContext";
import Button from "components/common/Button";
import { follow, unfollow } from "services/news";
import Search from "components/navigation/Search";
import IconButton from "components/common/IconButton";
import { IKeywordData } from "services/keyword.types";
import { setContentAction } from "contextApi/newsContext";
import { IconBottonGroup } from "components/schedule/styles";
import { createKeyword, deleteKeyword } from "services/keyword";
import { ButtonContent, TopicStatus, TopicLabel } from "./styles";
import ExploreSearch from "components/content/widgets/ExploreSearch";

interface IContentHeaderProps {
  searchValue: string;
  searchSuggestions: Array<string>;
  setSearchValue: (value: string) => void;
}

const INTENAL = "internal";

const ContentHeader: React.FC<IContentHeaderProps> = React.memo(
  ({ searchValue, setSearchValue, searchSuggestions }) => {
    const history = useHistory();
    const { location } = history;
    const { id, isFollowing, title, type, meta } = useNewsState(
      state => state.content
    );
    const dispatch = useNewsDispatch();
    const account = useSelector((state: any) => state.account.data);

    const [paths, setPaths] = useState<Array<any>>([]);
    const [topPath, setTopPath] = useState<number>(-1);
    const isExplorePage = location.pathname.includes("explore");

    const isForYouPage = useMemo(
      () =>
        location.pathname.includes("for-you") ||
        !!location.pathname.match(/\W*(content\/topics\/[A-Za-z0-9-]*)\W*/g) ||
        !!location.pathname.match(/\W*(content\/domains\/[A-Za-z0-9-]*)\W*/g) ||
        !!location.pathname.match(
          /\W*(content\/influencers\/[A-Za-z0-9-]*)\W*/g
        ) ||
        !!location.pathname.match(/\W*(content\/rss\/[A-Za-z0-9-]*)\W*/g) ||
        !!location.pathname.match(/\W*(content\/keywords\/[A-Za-z0-9-]*)\W*/g),
      [location.pathname]
    );

    const [isSearchVisible, setSearchVisible] = useState(false);
    const [onHover, setOnHover] = useState<boolean>(false);

    useEffect(() => {
      if (history.location.state === INTENAL) return;

      setPaths(paths => {
        if (topPath + 1 === paths.length) {
          return [...paths, history.location];
        } else {
          return [...paths.slice(0, topPath + 1), history.location];
        }
      });
    }, [history.location, topPath]);

    useEffect(() => {
      if (history.location.state === INTENAL) return;

      setTopPath(topPath => topPath + 1);
    }, [history.location]);

    const goBack = useCallback(() => {
      if (topPath <= 0) return;

      const newTop = topPath - 1;
      setTopPath(newTop);

      history.push({
        ...paths[newTop],
        state: INTENAL
      });
    }, [history, paths, topPath]);

    const goForward = useCallback(() => {
      const newTop = topPath + 1;

      if (paths.length === 1 || newTop >= paths.length) return;

      setTopPath(newTop);
      history.push({
        ...paths[newTop],
        state: INTENAL
      });
    }, [history, paths, topPath]);

    const handleSearchChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
      },
      [setSearchValue]
    );

    const handleUnfollow = useCallback(async () => {
      if (!id) return;

      try {
        if (type === "keywords") {
          await deleteKeyword(id);
          history.push(`/accounts/${account.id}/content/for-you`);
        } else {
          await unfollow(type, id);
        }
        dispatch(
          setContentAction({
            isFollowing: false
          })
        );
      } catch (_) {}
    }, [account.id, dispatch, history, id, type]);

    const handleFollow = useCallback(async () => {
      if (!id) return;

      try {
        if (type === "keywords") {
          const keyword: IKeywordData = _.pick({ ...meta }, ["name", "rules"]);
          await createKeyword(keyword);
        } else {
          await follow(type, id);
        }

        dispatch(
          setContentAction({
            isFollowing: true
          })
        );
      } catch (_) {}
    }, [dispatch, id, meta, type]);

    return (
      <SecondaryHeader>
        {!isSearchVisible ? (
          <SecondaryHeaderContainer>
            <Flex alignContent={"center"} justifyContent={"flex-start"}>
              <IconBottonGroup>
                <IconButton
                  // @ts-ignore
                  icon="icon-arrowleft"
                  variant={"secondaryLoud"}
                  size={32}
                  iconSize={20}
                  iconColor={"#646769"}
                  onClick={goBack}
                  disabled={topPath <= 0}
                />
                <IconButton
                  // @ts-ignore
                  icon="icon-arrowright"
                  variant={"secondaryLoud"}
                  size={32}
                  iconSize={20}
                  iconColor={"#646769"}
                  onClick={goForward}
                  disabled={paths.length === 1 || topPath >= paths.length - 1}
                />
              </IconBottonGroup>
              {isForYouPage && !!id && (
                <TopicStatus>
                  {isFollowing ? (
                    // @ts-ignore
                    <Button
                      size="xs"
                      minWidth={94}
                      variant={onHover ? "dangerLite" : "secondary"}
                      onMouseEnter={() => setOnHover(true)}
                      onMouseLeave={() => setOnHover(false)}
                      onClick={handleUnfollow}
                    >
                      {onHover ? "Unfollow" : "Following"}
                    </Button>
                  ) : (
                    // @ts-ignore
                    <Button size="xs" variant="primary" onClick={handleFollow}>
                      Follow
                    </Button>
                  )}

                  <TopicLabel>{title}</TopicLabel>
                </TopicStatus>
              )}
            </Flex>
            {!isExplorePage ? (
              // @ts-ignore
              <Button
                variant="secondary"
                onClick={() => setSearchVisible(true)}
                py={"8px"}
                cursor="text"
              >
                <ButtonContent>
                  <span className="icon-search"></span>
                  Search an article
                </ButtonContent>
              </Button>
            ) : (
              <ExploreSearch />
            )}
          </SecondaryHeaderContainer>
        ) : (
          <Search
            placeholder={"Search an article"}
            searchValue={searchValue}
            suggestions={searchSuggestions}
            onChange={handleSearchChange}
            onRequestClose={() => {
              setSearchVisible(false);
              setSearchValue("");
            }}
          />
        )}
      </SecondaryHeader>
    );
  }
);

export default ContentHeader;

import styled from "styled-components";
import { Box } from "rebass";

export const RecommendationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 48px;
`;

export const RecommendationGrid = styled(Box)<{ perColoum?: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.perColoum ?? 3}, 1fr);
  gap: 16px 24px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 24px;
  }

  /* TODO: rewrite manual breakpoint with new standard ones */
  @media screen and (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 24px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints[3]}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 24px;
  }
`;

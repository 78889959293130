export const FILL_FEATURE_NOTIFICATION_ENTRIES =
  "FILL_FEATURE_NOTIFICATION_ENTRIES";
export const FEATURE_NOTIFICATION_ENQUEUE = "FEATURE_NOTIFICATION_ENQUEUE";
export const FEATURE_NOTIFICATION_DEQUEUE = "FEATURE_NOTIFICATION_DEQUEUE";
export const SET_ACTIVE_FEATURE_NOTIFICATION =
  "SET_ACTIVE_FEATURE_NOTIFICATION";
export const RESET_ACTIVE_FEATURE_NOTIFICATION =
  "RESET_ACTIVE_FEATURE_NOTIFICATION";
export const GOTO_NEXT_STEP = "GOTO_NEXT_STEP";
export const GOTO_PREV_STEP = "GOTO_PREV_STEP";

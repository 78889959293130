import React from "react";
import styled from "styled-components";

import Button from "components/common/Button";
import { ISavedPost } from "utils/useSavedPosts";
import { formatDistanceToNow } from "utils/date";

const IdeaCardContainer = styled.div`
  width: 267px;
  height: 289px;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  background: transparent;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 257px;
  height: 279px;
  padding: 15px 10px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid ${props => props.theme.colors.grey02};
  background: ${props => props.theme.colors.white};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  > i {
    font-size: 16px;
    cursor: pointer;
  }
`;

const Creator = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;

  > img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.grey02};
  }

  > span {
    color: ${props => props.theme.colors.text01};
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.21px;
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  > div {
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.21px;
    color: ${props => props.theme.colors.text02};

    display: -webkit-box;
    height: 120px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const AddedDate = styled.span`
  display: flex;
  color: ${props => props.theme.colors.grey08};
  font-size: 12px;
  font-weight: 400;
  line-height: 125%;
  vertical-align: middle;
  margin-top: 20px;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

interface ISavedPostCard {
  post: ISavedPost;
  removeSavedPost: (urn: string) => void;
  createFromSavedPosts: (post: ISavedPost, type: "post" | "task") => void;
}

const SavedPostCard: React.FC<ISavedPostCard> = ({
  post,
  removeSavedPost,
  createFromSavedPosts
}) => {
  return (
    <IdeaCardContainer>
      <Wrapper>
        <Header>
          <Creator>
            <img src={post.creatorAvatar} alt="Creator Avatar" />
            <span>{post.creatorName}</span>
          </Creator>
          <i
            className="icon-cancel"
            onClick={() => removeSavedPost(post.urn)}
          />
        </Header>
        <Content>
          <div>{post.content}</div>
          <AddedDate>Added {formatDistanceToNow(post.createdAt)} ago</AddedDate>
        </Content>
        <Actions>
          {/* @ts-ignore */}
          <Button
            size="sm"
            variant="primary"
            onClick={() => createFromSavedPosts(post, "post")}
          >
            Create Post
          </Button>
          {/* @ts-ignore */}
          <Button
            size="sm"
            variant="secondaryQuietAlt"
            onClick={() => createFromSavedPosts(post, "task")}
          >
            Create Task
          </Button>
        </Actions>
      </Wrapper>
    </IdeaCardContainer>
  );
};

export default SavedPostCard;

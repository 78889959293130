import _ from "lodash";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { ChevronDown } from "styled-icons/boxicons-regular";

import Popper from "components/common/Popper";
import ContentTypeBadge from "./ContentTypeBadge";
import { useComposerState } from "contextApi/composerContext";
import Menu, { MenuContainer } from "components/navigation/Menu";
import { ContentTypeContainer, ChannelToggleButton } from "./styles";

const ContentTypeSelect = ({
  selectedPostConcept,
  selectedPostIdea,
  offset,
  onChange,
  position = "bottom-start"
}) => {
  const menuRef = useRef(null);
  const postConcepts = useComposerState(state => state.postConcepts);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  return (
    <div ref={menuRef}>
      <ContentTypeContainer onClick={toggleShowOptions}>
        <ChannelToggleButton
          className="chevron"
          size={28}
          padding={2}
          icon={ChevronDown}
        />
        <ContentTypeBadge
          contentTypeId={selectedPostConcept ?? "educational"}
          postIdea={selectedPostIdea?.title}
          variant={"dark"}
          clickable
        />
      </ContentTypeContainer>
      <Popper
        offset={offset}
        placement={position}
        referenceElement={menuRef.current}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[menuRef.current]}
      >
        <MenuContainer minWidth={284}>
          {!!postConcepts && postConcepts.length > 0 && (
            <Menu
              hasArrow={true}
              items={postConcepts.map(c => ({
                value: c.slug,
                label: c.title,
                info: c.slug,
                subMenuItems: c.postIdeas.map(postIdea => ({
                  value: postIdea.id,
                  data: postIdea,
                  label: postIdea.title,
                  parentId: c.slug
                }))
              }))}
              selected={_.compact([selectedPostIdea?.id, selectedPostConcept])}
              onSelect={newValue => {
                onChange(newValue);
                setShowOptions(false);
              }}
            />
          )}
        </MenuContainer>
      </Popper>
    </div>
  );
};

ContentTypeSelect.propTypes = {
  selectedPostConcept: PropTypes.string.isRequired,
  selectedPostIdea: PropTypes.object,
  offset: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  position: PropTypes.string
};

export default ContentTypeSelect;

import styled, { css } from "styled-components";

export const MoodFilterWrapper = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
  justify-content: flex-start;
  align-items: center;
`;

export const DropdownContainer = styled.div`
  background-color: ${props => props.theme.colors.grey03};
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 8px 10px;

  > i {
    font-size: 20px;
    color: ${props => props.theme.colors.text02};
  }

  > span {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.005em;
    color: ${props => props.theme.colors.text02};
  }

  &:hover {
    background-color: ${props => props.theme.colors.grey04};
    cursor: pointer;

    > i,
    > span {
      color: ${props => props.theme.colors.text01};
    }
  }
`;

export interface IMenuEntry {
  isSelected: boolean;
  icon?: string;
  fontColor?: string;
  background?: string;
  description?: string;
}

export const MenuEntry = styled.div<IMenuEntry>`
  padding: 8px;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  align-self: stretch;
  justify-content: space-between;

  ${props =>
    props.isSelected &&
    css`
      background: ${props => props.theme.colors.grey01};
    `}

  &:hover {
    background: ${props => props.theme.colors.primary};

    .content > i,
    .content > .label > .title {
      color: ${props => props.theme.colors.white};
    }

    .content > .label > .description {
      color: ${props => props.theme.colors.darkText03};
    }

    > i {
      color: ${props => props.theme.colors.white};
    }
  }

  .content {
    gap: 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    i {
      width: 20px;
      height: 20px;
      font-size: 20px;
      color: ${props => props.fontColor ?? props.theme.colors.text01};
    }

    .label {
      gap: 4px;
      display: flex;
      max-width: 191px;
      flex-direction: column;

      .title {
        line-height: 17.5px;
        color: ${props => props.theme.colors.text01};
        font-weight: ${props => (props.isSelected ? 600 : 500)};
      }

      .description {
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        color: ${props => props.theme.colors.text03};
      }
    }
  }

  i {
    font-size: 20px;
  }
`;

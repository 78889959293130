import qs from "qs";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import { Flex } from "rebass/styled-components";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";

import { ContentoModal } from "../ContentoModal";
import IdeaDetail from "components/ideas/IdeaDetail";
import { useContentoApi } from "utils/useContentoApi";
import { closeModal } from "state/actions/ModalActions";
import { useEventListener } from "utils/useEventListener";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { getDefaultTaskStartDate } from "components/schedule/utils/taskUtils";

const IdeaDetailModal = ({
  account,
  idea,
  showScheduleButton = true,
  closeModal
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [fetchPostIdea] = useContentoApi(`post-ideas/${idea.id}`);
  const [ideaDetail, setIdeaDetail] = useState(idea);
  const [isLoading, setLoading] = useState(!idea.postConcept);

  const handleKeyDown = e => {
    if (e.keyCode === 27) closeModal();
  };

  useEventListener(window, "keydown", handleKeyDown);

  const handleScheduleIdea = () => {
    closeModal();

    const encodedQuery = qs.stringify({
      taskId: "new",
      date: getDefaultTaskStartDate(),
      contentTypeId: ideaDetail.postConcept.slug,
      title: ideaDetail.title
    });

    dispatch(
      trackAnalyticsEvent("Idea Used", {
        ideaType: ideaDetail.postConcept.slug
      })
    );

    history.push({
      pathname: `/accounts/${account.id}/schedule/month`,
      search: `?${encodedQuery}`,
      idea: ideaDetail
    });
  };

  useEffect(() => {
    if (!idea.postConcept) {
      fetchPostIdea()
        .then(res => {
          setIdeaDetail(res);
          setLoading(false);
        })
        .catch(err => console.error(err));
    }
  }, [idea, fetchPostIdea]);

  return (
    <ContentoModal
      title=""
      onClose={closeModal}
      maxWidth={840}
      showHeader={false}
      showFooter={true}
      borderRadius={24}
    >
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" height={320}>
          <ReactLoading type="spin" color="#bbb" />
        </Flex>
      ) : (
        <IdeaDetail
          idea={ideaDetail}
          onClose={closeModal}
          showScheduleButton={showScheduleButton}
          onSchedule={handleScheduleIdea}
        />
      )}
    </ContentoModal>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(IdeaDetailModal);

import React from "react";

import Tag from "./Tag";
import { ITag, ITags } from "./types";
import { TagGroupWrapper } from "./styles";

const TagGroup: React.FC<ITags> = React.memo(({ tags }) => (
  <TagGroupWrapper>
    {tags.map((tag: ITag, index: number) => (
      <Tag key={index} id={tag.id} title={tag.title} lang={tag.lang} />
    ))}
  </TagGroupWrapper>
));

export default TagGroup;

import config from "../config";

let cbInstance = null;

export const getChargebeeInstance = () => {
  if (!cbInstance) {
    cbInstance = window.Chargebee.init({ site: config.chargebee.site });
  }
  return cbInstance;
};

export const SUBMIT_ACCOUNT_DETAILS = "@@signup/SUBMIT_ACCOUNT_DETAILS";
export const SUBMIT_COMPANY_DETAILS = "@@signup/SUBMIT_COMPANY_DETAILS";
export const SUBMIT_TAGS = "@@signup/SUBMIT_TAGS";

export const submitAccountDetails = formValues => {
  return {
    type: SUBMIT_ACCOUNT_DETAILS,
    values: formValues
  };
};

export const submitCompanyDetails = formValues => {
  return {
    type: SUBMIT_COMPANY_DETAILS,
    values: formValues
  };
};

export const submitTags = formValues => {
  return {
    type: SUBMIT_TAGS,
    values: formValues
  };
};

import _ from "lodash";
import React, { useEffect, useState } from "react";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { useSelector, useDispatch } from "react-redux";

import {
  EmptyState,
  CaptionCard,
  CaptionList,
  SearchInput,
  ActionContainer,
  SearchContainer,
  CaptionCardShimmer
} from "./styles";
import { theme } from "theme";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import MoodFilter from "./moodFilter";
import Button from "components/common/Button";
import Loader from "components/common/loading/Loader";
import { ICaption } from "contextApi/composerContext";
import IconButton from "components/common/IconButton";
import { fetchCaptionsByPostIdea } from "services/post";
import { ToolTip } from "components/schedule/common/styles";
import { Body6, Headline6 } from "components/common/styles";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import emptyState from "assets/images/composer/empty-suggestions.png";
import { ToolContentHeader, ToolContentBody, FeatureTag } from "../styles";
import { fetchGeneratedCaptions, fetchCaptionVariation } from "services/nlp";

const CaptionSuggestion: React.FC<{}> = () => {
  const addToast = useToaster();
  const dispatch = useDispatch();
  const account = useSelector<any, any>(state => state.account.data);
  const moodFilter = useComposerState(state => state.moodFilter);
  const postConcepts = useComposerState(state => state.postConcepts);
  const visibleCaption = useComposerState(state => state.visibleCaption);
  const postIdea = useComposerState(state => state.postData.postIdea);
  const contentTypeId = useComposerState(state => state.postData.contentTypeId);
  const setPostIdeaCaptions = useComposerActions(
    actions => actions.setPostIdeaCaptions
  );
  const setChosenSuggestedCaption = useComposerActions(
    actions => actions.setChosenSuggestedCaption
  );

  const [searchKeywords, setSearchKeywords] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isStreamingData, setIsStreamingData] = useState<boolean>(false);
  const [suggestedCaptions, setSuggestedCaptions] = useState<any[]>([]);

  const handleSearchKeywordChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSearchKeywords(e.currentTarget.value);
  };

  const generateNewCaptions = async () => {
    try {
      if (!!postIdea) {
        const captions = await fetchCaptionsByPostIdea(postIdea.title);
        setPostIdeaCaptions(postIdea.id, captions);
      }
    } catch (error) {
      addToast((error as Error).message, "error");
    }
  };

  const generateNLPCaptions = async () => {
    try {
      const nlpParams = {
        topic: postIdea?.title ?? contentTypeId,
        keywords: searchKeywords.trim().split(" "),
        toneUsed: moodFilter.value,
        language: "en"
      };

      await fetchGeneratedCaptions(nlpParams, {
        onOpen: () => {
          setIsLoading(true);
          setSuggestedCaptions([]);
          dispatch(trackAnalyticsEvent("Caption Generated", nlpParams));
        },
        onMessage: data => {
          setIsLoading(false);
          setIsStreamingData(true);
          // !important : sometimes doubles arrive
          const regex = /\{.*?\}/g;
          const found = data.toString().match(regex);

          found.forEach(json => {
            const parsedData = JSON.parse(json);
            setSuggestedCaptions(captionSuggestions => {
              const newCaptionSuggestions = [...captionSuggestions];

              if (newCaptionSuggestions[parsedData.index]) {
                newCaptionSuggestions[parsedData.index] = {
                  id: parsedData.index,
                  caption:
                    newCaptionSuggestions[parsedData.index].caption +
                    parsedData.text
                };
                return newCaptionSuggestions;
              }

              newCaptionSuggestions[parsedData.index] = {
                id: parsedData.index,
                caption: parsedData.text
              };
              return newCaptionSuggestions;
            });
          });
        },
        onClose: () => {
          setIsLoading(false);
          setIsStreamingData(false);
        },
        onError: e => {
          setIsLoading(false);
          setIsStreamingData(false);
          addToast("Captions could not be generated.", "error");
        }
      });
    } catch (e) {
      addToast("Captions could not be generated.", "error");
    }
  };

  const generateCaptionVariation = async (
    e: React.MouseEvent<HTMLSpanElement>,
    caption: string,
    index
  ) => {
    e.stopPropagation();

    try {
      const nlpParams = {
        caption,
        moods: !!moodFilter ? [moodFilter.value] : []
      };

      await fetchCaptionVariation(nlpParams, {
        onOpen: () => {
          setSuggestedCaptions(captionSuggestions => {
            const newCaptionSuggestions = [...captionSuggestions];
            newCaptionSuggestions[index] = {
              id: index,
              caption: ""
            };

            return newCaptionSuggestions;
          });
          dispatch(
            trackAnalyticsEvent("Generate Caption Variation", nlpParams)
          );
        },
        onMessage: data => {
          setIsStreamingData(true);
          const parsedData = JSON.parse(data);

          setSuggestedCaptions(captionSuggestions => {
            const newCaptionSuggestions = [...captionSuggestions];

            newCaptionSuggestions[index] = {
              id: index,
              caption: newCaptionSuggestions[index].caption + parsedData.text
            };
            return newCaptionSuggestions;
          });
        },
        onClose: () => {
          setIsStreamingData(false);
        },
        onError: e => {
          setIsStreamingData(false);
          addToast("Captions could not be generated.", "error");
        }
      });
    } catch (e) {
      addToast("Captions could not be generated.", "error");
    }
  };

  const contentfulCaptions: ICaption[] =
    !!postConcepts && !!postIdea
      ? _.flatMap(postConcepts, postConcept => postConcept.postIdeas).find(
          idea => idea.id === postIdea.id
        )!.captions
      : !!postConcepts && postConcepts.length > 0 && !!contentTypeId
        ? postConcepts.find(postConcept => postConcept.slug === contentTypeId)
            ?.captions ?? []
        : [];

  useEffect(() => {
    if (!!postConcepts) {
      setSearchKeywords("");
      setIsStreamingData(false);

      setIsLoading(true);
      const timer = setTimeout(() => {
        setSuggestedCaptions(contentfulCaptions);
        setIsLoading(false);
      }, 700);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [contentTypeId, postIdea, postConcepts]);

  const isWillowAIEnabled = !account?.isTelenetCustomer;

  const isGenerateButtonDisabled =
    searchKeywords.trim().split(" ").length < 2 || !moodFilter;

  return (
    <>
      <ToolContentHeader>
        <Headline6>Caption generator</Headline6>
        {isWillowAIEnabled && (
          <FeatureTag isLoading={isLoading}>
            {isLoading || isStreamingData ? (
              <>
                <Loader size={20} color={theme.colors.primary} />
                <span>Generating...</span>
              </>
            ) : (
              <>
                <i className="icon-sparkle" />
                <span>Willow AI assist</span>
              </>
            )}
          </FeatureTag>
        )}
      </ToolContentHeader>
      <ToolContentBody>
        {isWillowAIEnabled && (
          <>
            <SearchContainer>
              <SearchInput
                placeholder="Start typing at least couple words to see Willow AI suggestions..."
                value={searchKeywords}
                onChange={handleSearchKeywordChange}
              />
            </SearchContainer>

            <ActionContainer>
              <MoodFilter />
              {/* @ts-ignore */}
              <Button
                size={"sm"}
                variant={"primary"}
                onClick={generateNLPCaptions}
                disabled={isLoading || isGenerateButtonDisabled}
              >
                Generate
              </Button>
            </ActionContainer>
          </>
        )}
        <CaptionList isEmpty={suggestedCaptions.length < 1}>
          {isLoading ? (
            <>
              {[...Array(3).fill(0)].map((_, index) => (
                <CaptionCardShimmer key={index} />
              ))}
            </>
          ) : suggestedCaptions.length < 1 ? (
            <EmptyState>
              <div>
                <span className="title">Nothing to suggest yet</span>
                <span className="subtitle">
                  Start typing at least couple words to see Willow AI
                  suggestions
                </span>
              </div>
              <img src={emptyState} alt="empty state image" />
            </EmptyState>
          ) : (
            suggestedCaptions.map((caption, index) => (
              <CaptionCard
                key={caption?.id}
                onClick={() => {
                  setChosenSuggestedCaption({
                    [visibleCaption]: caption?.caption?.trim()
                  });
                  dispatch(
                    trackAnalyticsEvent("Generated Caption Used", {
                      caption: caption?.caption?.trim(),
                      toneUsed: moodFilter.value
                    })
                  );
                }}
              >
                {!isStreamingData && (
                  <div className="regenerate-btn">
                    {/* @ts-ignore */}
                    <IconButton
                      // @ts-ignore
                      size={"sm"}
                      iconSize={16}
                      icon="icon-repeat"
                      disabled={isLoading}
                      onClick={e => {
                        if (caption?.caption?.length > 0) {
                          generateCaptionVariation(e, caption.caption, index);
                        }
                      }}
                      variant={"secondaryLoud"}
                      className="icon-repeat"
                      data-tip={"Regenerate"}
                      data-for="regenerate-btn"
                    />
                    <ToolTip
                      id="regenerate-btn"
                      place="bottom"
                      size="medium"
                      className="tooltip"
                    />
                  </div>
                )}
                <Body6>{caption?.caption?.trim()}</Body6>
              </CaptionCard>
            ))
          )}
        </CaptionList>
      </ToolContentBody>
    </>
  );
};

export default CaptionSuggestion;

import React from "react";

import CustomerTestimonials from "components/common/customerTestimonials";
import { Headline, HeadlineContainer, TestimonialsContainer } from "./styles";

const Testimonials: React.FC<{}> = () => {
  return (
    <TestimonialsContainer>
      <HeadlineContainer>
        <Headline>
          Over 1,000 customers have tried Willow. Here's what some of them have
          to say about us:
        </Headline>
      </HeadlineContainer>
      <CustomerTestimonials />
    </TestimonialsContainer>
  );
};

export default Testimonials;

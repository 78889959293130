import React, { useState, useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import useWindowSize from "../../utils/useWindowSize";
import IconButton from "../common/IconButton";
import { ChevronLeft, ChevronRight } from "@styled-icons/boxicons-regular";

const CarouselContainer = styled(Box)`
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    margin-left: -24px;
    max-width: none;
    overflow-y: hidden;
    overflow-x: scroll;
    width: 100vw;
  }
`;

const CarouselSlider = styled(Flex)`
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    height: 0;
  }
`;

const CarouselFigure = styled(Flex)`
  align-items: center;
  justify-content: center;
  height: 400px;
  max-width: ${props => props.width}px;
  width: 100%;
`;

const CarouselImage = styled.img`
  object-fit: contain;
  border-radius: 4px;
  max-height: calc(100% - 56px);
  box-shadow: 25px 25px 25px 0 rgba(0, 0, 0, 0.18);
  margin-bottom: 24px;
`;

const CarouselItem = styled(Box)`
  scroll-snap-align: start;
  width: 100%;
  min-width: ${props => props.width}px;
`;

const CarouselIndices = styled(Flex)`
  box-sizing: border-box;
  bottom: 0;
  justify-content: center;
  padding: 24px 0;
  position: absolute;
  width: 100%;
`;

const CarouselButton = styled(IconButton)`
  ${props => css`
    ${props.direction}: 16px;
  `};
  position: absolute;
  top: calc(50% - 20px);
  width: 40px;
  z-index: 10;

  &:hover {
    background: white;
    opacity: 1;
  }
`;

const CarouselIndex = styled(Box)`
  background-color: ${props =>
    props.isCurrent ? "white" : "rgba(255, 255, 255, 0.5)"};
  border-radius: 4px;
  height: 8px;
  margin: 0 4px;
  width: 8px;
`;

function IdeaCarousel({ photos, maxWidth = 840 }) {
  const windowSize = useWindowSize();
  const itemCount = photos.length;
  const carouselContainer = useRef(null);
  const carouselListRef = useRef(null);

  const [carouselWidth, setCarouselWidth] = useState(0);
  const [, setCarouselListWidth] = useState(0);
  const [carouselShift, setCarouselShift] = useState(0);
  // TODO: check for limits and hide arrows
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemWidth, setItemWidth] = useState(maxWidth);

  const handleCarouselShift = direction => {
    const shift = itemWidth;
    let current;

    // Snap to grid: floor or ceil to closes item in shift direction
    // in the case of uneven horizontal scroll or mobile drag
    if (direction === "left" && carouselShift >= shift) {
      current = Math.ceil(carouselShift / shift) * shift;
      setCarouselShift(current - shift);
      setCurrentIndex(Math.floor((current - shift) / maxWidth));
    } else if (
      direction === "right" &&
      carouselShift < shift * itemCount - carouselWidth
    ) {
      current = Math.floor(carouselShift / shift) * shift;
      setCarouselShift(current + shift);
      setCurrentIndex(Math.floor((current + shift) / maxWidth));
    }
  };

  const handleUpdateScroll = () => {
    if (!carouselListRef.current) return;
    setCurrentIndex(Math.floor(carouselListRef.current.scrollLeft / maxWidth));
  };

  useEffect(() => {
    if (!carouselListRef.current) return;
    carouselListRef.current.scrollLeft = carouselShift;
  }, [carouselShift]);

  useEffect(() => {
    // Reset carousel shift on props change (topics) or resize
    // Slightly shift it as initial position on mobile (aesthetics)
    if (windowSize.size === "xsmall") {
      setCarouselShift(-16);
      setCarouselListWidth(itemCount * itemWidth);
    } else {
      setCarouselShift(0);
      setCarouselListWidth(itemCount * itemWidth);
    }
  }, [itemCount, itemWidth, windowSize.size]);

  useEffect(() => {
    // Recalculate item width on resize depending on window
    if (windowSize.width < 990) {
      setItemWidth(Math.min(windowSize.width, 840));
    }

    setCarouselWidth(carouselContainer.current.clientWidth);
  }, [windowSize.width]);

  return (
    <CarouselContainer ref={carouselContainer}>
      <CarouselButton
        variant={"primary"}
        icon={ChevronLeft}
        direction={"left"}
        onClick={() => handleCarouselShift("left")}
      />
      <CarouselSlider
        itemCount={itemCount}
        itemWidth={itemWidth}
        ref={carouselListRef}
        onWheel={handleUpdateScroll}
      >
        {photos.map(item => (
          <CarouselItem width={itemWidth} key={item.id}>
            <CarouselFigure width={itemWidth}>
              <CarouselImage src={item.file.url} />
            </CarouselFigure>
          </CarouselItem>
        ))}
      </CarouselSlider>
      <CarouselIndices>
        {photos.map((item, i) => (
          <CarouselIndex
            key={`dot-${item.id}`}
            isCurrent={currentIndex === i}
          />
        ))}
      </CarouselIndices>
      <CarouselButton
        variant={"primary"}
        icon={ChevronRight}
        direction={"right"}
        onClick={() => handleCarouselShift("right")}
      />
    </CarouselContainer>
  );
}

export default IdeaCarousel;

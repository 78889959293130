import _ from "lodash";
import {
  Context,
  useContext,
  createContext,
  useContextSelector
} from "use-context-selector";
import qs from "query-string";
import { useHistory } from "react-router-dom";
import { useGranularEffect } from "granular-hooks";
import { useSelector, useDispatch } from "react-redux";
import React, { useReducer, useCallback, useMemo, useEffect } from "react";

import {
  ICaption,
  IPostData,
  initialValues,
  IComposerState,
  composerReducer,
  IComposerDispatch,
  IChannelAttachments,
  IUploadedAttachment,
  IScrappedAttachment,
  IScrappedAttachments,
  IUploadedAttachments
} from "./reducer";
import {
  today,
  startOf,
  isToday,
  getTime,
  tomorrow,
  addDuration
} from "utils/date";
import {
  scrapeUrlLink,
  fetchPostConcepts,
  fetchAvailableTags
} from "services/post";
import {
  openComposerAction,
  closeComposerAction,
  toggleProModeAction,
  setInDraftModeAction,
  setPostDataAction,
  resetPostDataAction,
  setTagsAction,
  setEditorStateObjAction,
  setVisibleCaptionAction,
  enableSplitModeAction,
  setChosenSuggestedCaptionAction,
  setPostConceptsAction,
  setPostIdeaCaptionsAction,
  setValidationErrorsAction,
  setAttachmentTypeAction,
  setIsUploadingAttachmentsAction,
  addAttachmentsAction,
  removeAttachmentAction,
  removeArticleAttachmentAction,
  reorderAttachmentAction,
  setAttachmentValidationAction,
  setAttachmentsAction,
  setAttachmentOptionsAction,
  setIsSubmittingAction,
  setIsDeletingAction,
  setWasScheduleChangedAction,
  setPickedDateAction,
  setMoodFilterAction,
  setIsLoadingSuggestedImagesAction,
  setNewImagesIndicationAction,
  setImagesSearchTermAction,
  setSuggestedImagesAction,
  setImagesQueryAction,
  setActiveToolkitAction,
  setConfirmationModalPropsAction,
  setIsComposerCloseConfirmationOpenAction,
  setCaptionAction,
  disableSplitModeAction,
  setComposerNotificationsAction
} from "./actionCreators";
import {
  removeDraftById,
  changeDraftCountByValue
} from "state/actions/DraftActions";
import { capitalize } from "utils/string";
import { callApi } from "utils/ContentoApi";
import { POST_STATUS } from "constants/post";
import { SERVICES } from "constants/services";
import * as storageService from "utils/storage";
import { openPostOnChannel } from "utils/channels";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { useQueryParams } from "utils/useQueryParams";
import { fetchImageSuggestions } from "services/post";
import {
  computeInitialVisibleCaption,
  constructInitialPostState
} from "./utils/post";
import { IChannel, IPost } from "@hellocontento/contento-common";
import TASK_TYPES from "components/schedule/constants/taskType";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import contentTypes from "components/common/contentTypes/data/content-types";
import { createUpdatePost, constructPostData, PostEvent } from "services/post";
import { useAppState } from "contextApi/appContext";
import { isBefore, parseISO } from "date-fns";

export const KEY_COMPOSER_PRO_MODE = "composer_pro_mode";

const ComposerStateContext = createContext<IComposerState | undefined>(
  undefined
);
ComposerStateContext.displayName = "ComposerStateContext";

const ComposerDispatchContext = createContext<IComposerDispatch | undefined>(
  undefined
);
ComposerDispatchContext.displayName = "ComposerDispatchContext";

export interface IComposerActions {
  toggleProMode: (overrideMode: IComposerState["inProMode"]) => void;
  setInDraftMode: (inDraftMODE: IComposerState["inDraftMode"]) => void;
  setEditorStateObj: (
    newEditorStateObj: IComposerState["editorStateObj"]
  ) => void;
  setVisibleCaption: (service: IComposerState["visibleCaption"]) => void;
  setValidationErrors: (
    validationErrors: IComposerState["validationErrors"]
  ) => void;
  setComposerNotifications: (
    composerNotifications: IComposerState["composerNotifications"]
  ) => void;
  setChosenSuggestedCaption: (
    caption: IComposerState["chosenSuggestedCaption"]
  ) => void;
  setPostIdeaCaptions: (postIdeaId: string, captions: ICaption[]) => void;
  setPostData: (postData: IComposerState["postData"]) => void;
  setAttachmentType: (
    attachmentType: IChannelAttachments["type"],
    channel?: string
  ) => void;
  setIsUploadingAttachments: (
    status: boolean,
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => void;
  addUploadedAttachments: (
    newAttachments: any[],
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => void;
  removeAttachment: (
    index: number,
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => void;
  removeArticleAttachment: (channel?: string) => void;
  reorderAttachment: (
    sourceIndex: number,
    destinationIndex: number,
    attachmentType: NonNullable<IChannelAttachments["type"]>,
    channel?: string
  ) => void;
  setAttachmentValidation: (validationErrors: any[], channel?: string) => void;
  setAttachmentOptions: (
    options: { [key: string]: any },
    channel?: string
  ) => void;
  setIsSubmitting: (
    isSubmitting: IComposerState["scheduler"]["isSubmitting"]
  ) => void;
  setIsDeleting: (
    isDeleting: IComposerState["scheduler"]["isDeleting"]
  ) => void;
  setWasScheduleChanged: (
    wasScheduleChanged: IComposerState["scheduler"]["wasScheduleChanged"]
  ) => void;
  setPickedDate: (
    pickedDate: IComposerState["scheduler"]["pickedDate"]
  ) => void;
  setMoodFilter: (mood: IComposerState["moodFilter"]) => void;
  setNewImagesIndication: (
    indication: IComposerState["suggestedImages"]["newImagesIndication"]
  ) => void;
  setImagesSearchTerm: (
    searchTerm: IComposerState["suggestedImages"]["searchTerm"]
  ) => void;
  setSuggestedImages: (
    suggestedImages: IComposerState["suggestedImages"]["images"]
  ) => void;
  setActiveToolkit: (toolkit: IComposerState["activeToolkit"]) => void;
  setConfirmationModalProps: (
    modalProps: IComposerState["confirmationModalProps"]
  ) => void;
  setIsComposerCloseConfirmationOpen: (
    isComposerCloseConfirmationOpen: IComposerState["isComposerCloseConfirmationOpen"],
    closeToRefreshChannels?: IComposerState["closeToRefreshChannels"]
  ) => void;

  pasteCaptionToComposer: (text: string) => void;

  closeComposer: VoidFunction;
  initializePostData: (post: any) => void;
  openComposer: (
    post?: null | IComposerState["postData"],
    initData?: any
  ) => void;
  scrapeUrl: (url: string, linkOnly?: boolean) => void;
  getEnabledServicesWithType: (
    selectedChannels: string[]
  ) => { service: string; serviceType: string }[];
  getEnabledServices: (selectedChannels: string[]) => string[];
  onCaptionChange: (text: string, service: string) => void;
  splitEditor: VoidFunction;
  onChannelSelected: (
    selectedChannels: string[],
    validationErrors: { [key: string]: any }
  ) => void;
  createPost: (
    scheduleTime: string,
    scheduledAt: null | Date,
    fromModal: boolean,
    callback?: Function
  ) => void;
  deletePost: (post: IPostData) => void;
  getImageSuggestions: (
    query?: string,
    useNlp?: boolean,
    refreshList?: boolean
  ) => void;
  handleImageSuggestionSearch: (query?: string, useNlp?: boolean) => void;
  openPost: (post: IPostData) => void;
  createTask: (date?: Date, initData?: { [key: string]: any }) => void;
  editTask: (
    task: { [key: string]: any },
    type: keyof typeof TASK_TYPES
  ) => void;
  createPostByDate: (date: Date, isDraft?: boolean) => void;
  createPostByTask: (task: { [key: string]: any }, isDraft?: boolean) => void;
  createPostByCalendarEvent: (
    calendarEvent: { [key: string]: any },
    isDraft?: boolean
  ) => void;
  deleteEntry: (entry: any) => void;
  openPublishedPostInComposer: (post: IPost) => void;
}

const ComposerActionsContext = createContext<IComposerActions | undefined>(
  undefined
);
ComposerActionsContext.displayName = "ComposerActionsContext";

export const ComposerProvider: React.FC<any> = React.memo(({ children }) => {
  const history = useHistory();
  const addToast = useToaster();
  const queryParams = useQueryParams();
  const reduxDispatch = useDispatch(); // ? dispatcher for the react-redux
  const isTelenetCustomer = useSelector<any, any>(
    state => state.account.data?.isTelenetCustomer
  );
  const accountId = useSelector<any, any>(state => state.account.data?.id);
  const accountChannels =
    useSelector<any, any>(state => state.account.data?.channels) ?? [];
  const modalState = useSelector<any, any>(state => state.modals);
  const [state, dispatch] = useReducer(composerReducer, {
    ...initialValues
  });
  const showWillowAIRevamp = useAppState(state => state.showWillowAIRevamp);

  const useComposerV2 = !isTelenetCustomer;

  const getProModePreference = () =>
    storageService.get(KEY_COMPOSER_PRO_MODE) === "false" ? false : true;

  // * <--State setters
  const toggleProMode = useCallback(
    (overrideMode?: IComposerState["inProMode"]) => {
      dispatch(toggleProModeAction(useComposerV2 && overrideMode));
    },
    [useComposerV2]
  );

  const setInDraftMode = useCallback(
    (inDraftMode: IComposerState["inDraftMode"]) => {
      // console.log("Post Data:: ", state.postData);
      // console.log("Picked Date:: ", state.scheduler.pickedDate);

      // if (inDraftMode === true) {
      //   // draft mode is true... set picked date to null
      //   setPickedDate(null);
      // } else {
      //   let pickedDate =
      //     state.scheduler.pickedDate ?? getSuitableSchedule(tomorrow());
      //   setWasScheduleChanged(true);
      //   setPickedDate(pickedDate);
      // }
      dispatch(setInDraftModeAction(inDraftMode));
    },
    []
  );

  const setEditorStateObj = useCallback(
    (newEditorStateObj: IComposerState["editorStateObj"]) => {
      dispatch(setEditorStateObjAction(newEditorStateObj));
    },
    []
  );

  const setVisibleCaption = useCallback(
    (service: IComposerState["visibleCaption"]) => {
      dispatch(setVisibleCaptionAction(service));
    },
    []
  );

  const setValidationErrors = useCallback(
    (validationErrors: IComposerState["validationErrors"]) => {
      dispatch(setValidationErrorsAction(validationErrors));
    },
    []
  );

  const setComposerNotifications = useCallback(
    (composerNotifications: IComposerState["composerNotifications"]) => {
      dispatch(setComposerNotificationsAction(composerNotifications));
    },
    []
  );

  const setChosenSuggestedCaption = useCallback(
    (caption: IComposerState["chosenSuggestedCaption"]) => {
      dispatch(setChosenSuggestedCaptionAction(caption));
    },
    []
  );

  const setPostIdeaCaptions = useCallback(
    (postIdeaId: string, captions: ICaption[]) => {
      dispatch(setPostIdeaCaptionsAction(postIdeaId, captions));
    },
    []
  );

  const setPostData = useCallback((postData: IComposerState["postData"]) => {
    dispatch(setPostDataAction(postData));
  }, []);

  const setAttachmentType = useCallback(
    (attachmentType: IChannelAttachments["type"], channel?: string) => {
      dispatch(setAttachmentTypeAction(attachmentType, channel));
    },
    []
  );

  const setIsUploadingAttachments = useCallback(
    (
      status: boolean,
      attachmentType: NonNullable<IChannelAttachments["type"]>,
      channel?: string
    ) => {
      dispatch(
        setIsUploadingAttachmentsAction(status, attachmentType, channel)
      );
    },
    []
  );

  const addUploadedAttachments = useCallback(
    (
      newAttachments: any[],
      attachmentType: Omit<NonNullable<IChannelAttachments["type"]>, "article">,
      channel?: string
    ) => {
      const attachments: any[] = newAttachments.map(attachment => ({
        url: attachment.path ?? attachment,
        metaData: attachment.metaData ?? null
      }));

      dispatch(
        addAttachmentsAction(
          attachments,
          attachmentType as NonNullable<IChannelAttachments["type"]>,
          channel
        )
      );
    },
    []
  );

  const removeAttachment = useCallback(
    (
      index: number,
      attachmentType: NonNullable<IChannelAttachments["type"]>,
      channel?: string
    ) => {
      dispatch(removeAttachmentAction(index, attachmentType, channel));
    },
    []
  );

  const removeArticleAttachment = useCallback((channel?: string) => {
    dispatch(removeArticleAttachmentAction(channel));
  }, []);

  const reorderAttachment = useCallback(
    (
      sourceIndex: number,
      destinationIndex: number,
      attachmentType: NonNullable<IChannelAttachments["type"]>,
      channel?: string
    ) => {
      dispatch(
        reorderAttachmentAction(
          sourceIndex,
          destinationIndex,
          attachmentType,
          channel
        )
      );
    },
    []
  );

  const setAttachmentValidation = useCallback(
    (validationErrors: any[], channel?: string) => {
      dispatch(setAttachmentValidationAction(validationErrors, channel));
    },
    []
  );

  const setAttachmentOptions = useCallback(
    (options: { [key: string]: any }, channel?: string) => {
      dispatch(setAttachmentOptionsAction(options, channel));
    },
    []
  );

  const setIsSubmitting = useCallback(
    (isSubmitting: IComposerState["scheduler"]["isSubmitting"]) =>
      dispatch(setIsSubmittingAction(isSubmitting)),
    []
  );

  const setIsDeleting = useCallback(
    (isDeleting: IComposerState["scheduler"]["isDeleting"]) =>
      dispatch(setIsDeletingAction(isDeleting)),
    []
  );

  const setWasScheduleChanged = useCallback(
    (wasScheduleChanged: IComposerState["scheduler"]["wasScheduleChanged"]) =>
      dispatch(setWasScheduleChangedAction(wasScheduleChanged)),
    []
  );

  const setPickedDate = useCallback(
    (pickedDate: IComposerState["scheduler"]["pickedDate"]) =>
      dispatch(setPickedDateAction(pickedDate)),
    []
  );

  const setMoodFilter = useCallback(
    (mood: IComposerState["moodFilter"]) => dispatch(setMoodFilterAction(mood)),
    []
  );

  const setNewImagesIndication = useCallback(
    (indication: IComposerState["suggestedImages"]["newImagesIndication"]) => {
      dispatch(setNewImagesIndicationAction(indication));
    },
    []
  );

  const setImagesSearchTerm = useCallback(
    (searchTerm: IComposerState["suggestedImages"]["searchTerm"]) => {
      dispatch(setImagesSearchTermAction(searchTerm));
    },
    []
  );

  const setSuggestedImages = useCallback(
    (suggestedImages: IComposerState["suggestedImages"]["images"]) => {
      dispatch(setSuggestedImagesAction(suggestedImages));
    },
    []
  );

  const setActiveToolkit = useCallback(
    (toolkit: IComposerState["activeToolkit"]) => {
      dispatch(setActiveToolkitAction(toolkit));
    },
    []
  );

  const setConfirmationModalProps = useCallback(
    (modalProps: IComposerState["confirmationModalProps"]) => {
      dispatch(setConfirmationModalPropsAction(modalProps));
    },
    []
  );

  const setIsComposerCloseConfirmationOpen = useCallback(
    (
      isComposerCloseConfirmationOpen: IComposerState["isComposerCloseConfirmationOpen"],
      closeToRefreshChannels?: IComposerState["closeToRefreshChannels"]
    ) => {
      dispatch(
        setIsComposerCloseConfirmationOpenAction({
          isComposerCloseConfirmationOpen,
          closeToRefreshChannels
        })
      );
    },
    []
  );
  // * State setters-->

  const getSuitableSchedule = useCallback((date: Date): Date => {
    const isCurrentDay = isToday(date);
    const { hours, minutes } = getTime(today());
    const suitableSchedule = addDuration(startOf(date, "day"), {
      hours: isCurrentDay ? hours + 1 : hours,
      minutes: isCurrentDay ? (minutes <= 30 ? 0 : 30) : minutes <= 30 ? 30 : 60
    });

    return suitableSchedule;
  }, []);

  const setInitialVisibleCaption = useCallback(() => {
    if (!!accountId) {
      const initialVisibleCaption = computeInitialVisibleCaption(
        state.postData,
        accountChannels
      );
      if (initialVisibleCaption !== "all") {
        dispatch(enableSplitModeAction());
      }
      dispatch(setVisibleCaptionAction(initialVisibleCaption));
    }
  }, [
    accountId,
    accountChannels,
    state.postData.id,
    state.postData.caption,
    state.postData.isDraft,
    state.postData.channels,
    state.postData.mentions,
    state.postData.isPostGroup
  ]);

  // * <--Action dispatch functions
  const closeComposer = useCallback(() => {
    const search = _.omit(queryParams, ["postGroupId", "postId", "draftId"]);
    const newQueryParams = new URLSearchParams();
    _.forEach(search, (value, key) => {
      newQueryParams.set(key, value as string);
    });
    if (!_.isEqual(queryParams, Object.fromEntries(newQueryParams.entries()))) {
      history.push({
        search: newQueryParams.toString()
      });
    }
    dispatch(closeComposerAction());
    dispatch(resetPostDataAction());
  }, [history, queryParams]);

  const initializeAttachments = useCallback((attachments: any) => {
    if (attachments.type === "photo") {
      let urls = Array.isArray(attachments.url)
        ? attachments.url
        : [attachments.url];
      let metaDatas = !attachments.metaData
        ? []
        : Array.isArray(attachments.metaData)
          ? attachments.metaData
          : [attachments.metaData];
      const zipped = _.zip(urls, metaDatas);
      const photoAttachments: IUploadedAttachments = {
        isUploading: false,
        attachments: zipped.map(zip => ({
          url: zip[0],
          metaData: zip[1]
        })) as IUploadedAttachment[]
      };

      dispatch(
        setAttachmentsAction({
          photoAttachments
        })
      );
      dispatch(setAttachmentTypeAction("photo"));
    } else if (attachments.type === "video") {
      const videoAttachments: IUploadedAttachments = {
        isUploading: false,
        attachments: [
          {
            url: attachments.url,
            metaData: attachments.metaData
          }
        ] as IUploadedAttachment[]
      };

      dispatch(
        setAttachmentsAction({
          videoAttachments
        })
      );
      dispatch(setAttachmentTypeAction("video"));

      if (!!attachments.options) {
        dispatch(setAttachmentOptionsAction(attachments.options));
      }
    } else if (attachments.type === "pdf") {
      const pdfAttachments: IUploadedAttachments = {
        isUploading: false,
        attachments: [
          {
            url: attachments.url,
            metaData: attachments.metaData
          }
        ] as IUploadedAttachment[]
      };

      dispatch(
        setAttachmentsAction({
          pdfAttachments
        })
      );
      dispatch(setAttachmentTypeAction("pdf"));

      if (!!attachments.options) {
        dispatch(setAttachmentOptionsAction(attachments.options));
      }
    } else if (attachments.type === "article") {
      const { type, ...attachmentData } = attachments;

      const articleAttachments: IScrappedAttachments = {
        isScrapping: false,
        attachment: attachmentData as IScrappedAttachment
      };

      dispatch(
        setAttachmentsAction({
          articleAttachments
        })
      );
      dispatch(setAttachmentTypeAction("article"));
    }
  }, []);

  /**
   * We decide if to show a warning message to the user.
   * This is neccessary because we allow users to republish their posts.
   * And since some of these posts might have been made outside Willow,
   * They sometimes contain unexpected syntax.
   *
   * We are deploying a fix that ensures that Posts created in willow are not overwritten by the contents of the social media sync
   */
  const triggerMentionHashtagWarning = (initData: any = {}) => {
    if (!!initData && initData.isFromPublishedPost) {
      const isPublishedOutsideWillow = initData?.source !== "api";

      const postPublishDate = initData?.postedAt;

      // The caption sync fix is deemed published by this date.
      // Posts made through Willow are not expected to continue to have this problem
      const captionSyncFixPublishDate = parseISO("2024-10-03");

      const isPublishedBeforeSyncFix = !!postPublishDate
        ? isBefore(parseISO(postPublishDate), captionSyncFixPublishDate)
        : false;

      if (isPublishedOutsideWillow || isPublishedBeforeSyncFix) {
        setComposerNotifications([
          {
            type: "warning",
            message:
              "Double-check your caption for any unusual characters, which might appear from copying text off social media."
          }
        ]);
      }
    }
  };

  const openPublishedPostInComposer = useCallback((post: IPost) => {
    const contentTypeIds = _.reduce(
      _.values(_.pickBy(contentTypes, i => i.isConcept)),
      (ids: any[], n) => {
        ids.push(n.id);
        return ids;
      },
      []
    );

    const postData: { [key: string]: any } = {
      initialContentTypeId: contentTypeIds.includes(post.contentTypeId)
        ? post.contentTypeId
        : "promotional",
      contentTypeLabel: post.contentTypeLabel ?? undefined,
      initialChannelIds: [post.channel!.id],
      postIdea: post.postIdea,
      initialContent: post.caption,
      ...(post.attachment ? { attachment: post.attachment } : {}),
      /// We now include these fields to enable us duplicate a published post properly
      // they are required to determine if the post might include unsupported syntax from the social media
      source: post.source,
      postedAt: post.postedAt,
      isFromPublishedPost: true
    };

    openComposer(null, postData);
  }, []);

  const initializePostData = useCallback(
    (post?: null | IPostData, initData: any = {}) => {
      const {
        isDraft,
        task,
        calendarEvent,
        initialScheduleTime,
        captions,
        isExtensionPost
      } = initData;

      if (task) {
        // slots may keep references to deleted channels
        const activeChannelIds = task.channels
          .filter(({ id: channelId }: any) => {
            return !!accountChannels.find(
              (channel: any) => channel.id === channelId
            );
          })
          .map((c: any) => c.id);

        initData.initialContentTypeId = task.contentTypeId;
        initData.contentTypeLabel =
          contentTypes[task.contentTypeId as keyof typeof contentTypes]?.title;
        initData.postIdea = task.postIdea;
        initData.initialChannelIds = activeChannelIds;
        initData.taskId = task.id;
      }

      if (!!post?.attachment) {
        initializeAttachments(post.attachment);
      } else if (!!initData?.attachment) {
        initializeAttachments(initData.attachment);
      }

      let pickedDate: Date | null;

      // if (isDraft || post?.isDraft) {
      //   pickedDate = null;
      // } else {
      pickedDate = !!post?.scheduledAt
        ? new Date(post.scheduledAt)
        : task?.date
          ? new Date(task.date)
          : calendarEvent?.time
            ? new Date(calendarEvent.time)
            : getSuitableSchedule(
                initialScheduleTime instanceof Date
                  ? initialScheduleTime
                  : tomorrow()
              );
      // }

      dispatch(setPickedDateAction(pickedDate));

      const postData = constructInitialPostState(
        post,
        accountChannels,
        initData
      );

      triggerMentionHashtagWarning(initData);

      const initialVisibleCaption = computeInitialVisibleCaption(
        postData,
        accountChannels
      );

      if (initialVisibleCaption !== "all") {
        dispatch(enableSplitModeAction());
      }

      dispatch(
        setPostDataAction({
          ...postData,
          metaData: {
            captions,
            isExtensionPost: !!isExtensionPost,
            calendarEventId: calendarEvent?.id,
            prevDate: !!post?.scheduledAt
              ? new Date(post.scheduledAt)
              : task?.date
                ? new Date(task.date)
                : undefined
          }
        })
      );
    },
    [
      accountChannels,
      getSuitableSchedule,
      initializeAttachments,
      enableSplitModeAction
    ]
  );

  const openComposer = useCallback(
    (post?: null | IPostData, initData: any = {}) => {
      const { isDraft } = initData;

      initializePostData(post, initData);

      if (isDraft || post?.isDraft) {
        setInDraftMode(true);
      }

      dispatch(openComposerAction());
      reduxDispatch(
        trackAnalyticsEvent("Post Editor Opened", {
          ...(!!post ? { id: post.id } : {}),
          location:
            history.location.pathname.match(
              /^.*\/accounts\/[^/]*\/([^/?]*).*$/
            )?.[1] ?? ""
        })
      );

      if (!!post) {
        const queryParams = new URLSearchParams();
        let paramKey = "postId";
        if (post.isPostGroup) {
          paramKey = "postGroupId";
        } else if (post.isDraft) {
          paramKey = "draftId";
        }

        queryParams.set(paramKey, post.id);
        history.push({
          search: queryParams.toString()
        });
      }
    },
    [history, initializePostData, reduxDispatch]
  );

  const openPostFromUrlId = useCallback(
    async (id, options: any = {}) => {
      try {
        let url = "";
        if (options.isPostGroup) {
          url = `/accounts/${accountId}/post-groups/${id}`;
        } else if (options.isDraft) {
          url = `/accounts/${accountId}/draft-posts/${id}`;
        } else {
          url = `/posts/${id}`;
        }

        let post: IPostData = {};
        const result = await callApi({
          method: "get",
          url
        });

        if (!result) {
          throw new Error("Invalid url parameters.");
        }

        post = {
          ...result,
          ...options
        };

        if (post.status === POST_STATUS.SENT) {
          openPostOnChannel(post);
          history.replace({});
        } else {
          openComposer(post);
        }
      } catch (err) {
        history.replace({});
        addToast("Invalid url parameters.", "error");
      }
    },
    [accountId, addToast, history, openComposer]
  );

  const scrapeUrl = useCallback(
    async (url: string) => {
      // if we dont need to scrape the article again
      const linkOnly =
        url === state.attachments["all"].articleAttachments.attachment?.url;
      try {
        dispatch(setIsUploadingAttachmentsAction(true, "article"));

        const urlInfo = await scrapeUrlLink(url, linkOnly);
        if (urlInfo.shortUrl || urlInfo.url) {
          dispatch(
            addAttachmentsAction(
              {
                ...urlInfo,
                url: urlInfo.shortUrl || urlInfo.url,
                image: urlInfo.image || urlInfo.thumbnail
              },
              "article"
            )
          );
          reduxDispatch(
            trackAnalyticsEvent("Scrapped Url", {
              url,
              shortUrl: urlInfo.shortUrl || ""
            })
          );
        } else {
          dispatch(removeArticleAttachmentAction());
        }

        return urlInfo;
      } catch (error) {
        addToast((error as Error).message, "error");
      } finally {
        dispatch(setIsUploadingAttachmentsAction(false, "article"));
      }
    },
    [addToast, reduxDispatch, state.attachments]
  );

  const getEnabledServicesWithType = useCallback(
    (selectedChannels?: string[]) => {
      const channels: string[] = !!selectedChannels
        ? selectedChannels
        : state.postData.channels ?? [];

      return _.uniq(
        channels.map(id => {
          const { service, serviceType } = (
            (accountChannels || []) as IChannel[]
          ).find(accountChannel => accountChannel.id === id)!;

          return { service, serviceType };
        })
      );
    },
    [state.postData.channels, accountChannels]
  );

  const getEnabledServices = useCallback(
    (selectedChannels?: string[]) => {
      return _.uniq(
        getEnabledServicesWithType(selectedChannels).map(s => s.service)
      );
    },
    [getEnabledServicesWithType]
  );

  const onCaptionChange = useCallback(
    (text: string, service: string) => {
      const caption = state.postData.caption;
      caption[service] = text;

      if (service === "all" && !state.inSplitMode) {
        const enabledServices = getEnabledServices();
        enabledServices.forEach(service => {
          caption[service] = text;
        });
      }

      dispatch(setPostDataAction({ caption }));
      dispatch(setCaptionAction(text));
    },
    [getEnabledServices, state.inSplitMode, state.postData.caption]
  );

  const pasteCaptionToComposer = useCallback(
    (text: string) => {
      setChosenSuggestedCaption({
        [state.visibleCaption]: text.trim()
      });
    },
    [state.visibleCaption, setChosenSuggestedCaption]
  );

  const splitEditor = useCallback(() => {
    const enabledServices = getEnabledServices();

    if (!state.inSplitMode) {
      // setWasCaptionUsed(true);
      const newEditorStateObj: IComposerState["editorStateObj"] = {};

      enabledServices.forEach(service => {
        newEditorStateObj[service] = state.editorStateObj["all"];
      });
      setEditorStateObj({ ...state.editorStateObj, ...newEditorStateObj });
    }

    // enabled customized captions
    if (state.visibleCaption === "all") {
      setVisibleCaption(enabledServices[0]);
      dispatch(enableSplitModeAction());
      reduxDispatch(trackAnalyticsEvent("Split Mode Enabled"));
    } else {
      setVisibleCaption("all");
      dispatch(disableSplitModeAction());
      reduxDispatch(trackAnalyticsEvent("Split Mode Disabled"));
    }
  }, [
    reduxDispatch,
    setEditorStateObj,
    setVisibleCaption,
    state.inSplitMode,
    getEnabledServices,
    state.editorStateObj,
    state.visibleCaption
  ]);

  const onChannelSelected = useCallback(
    (selectedChannels: string[], validationErrors: { [key: string]: any }) => {
      const newPostData: IComposerState["postData"] = {
        caption: state.postData.caption
      };

      const enabledServices = getEnabledServices(selectedChannels);
      enabledServices.forEach(service => {
        if (
          newPostData.caption[service] === undefined ||
          newPostData.caption[service] === ""
        ) {
          newPostData.caption[service] = newPostData.caption.all;
        }
      });
      dispatch(
        setPostDataAction({ ...newPostData, channels: selectedChannels })
      );
      dispatch(
        setValidationErrorsAction({
          ...state.validationErrors,
          ...validationErrors
        })
      );
    },
    [getEnabledServices, state.validationErrors, state.postData.caption]
  );

  const createPost = useCallback(
    (
      scheduleTime: string = "AUTO_SCHEDULE",
      scheduledAt: null | Date = null,
      fromModal: boolean = false,
      callback?: Function
    ) => {
      setIsSubmitting(true);

      const { metaData, attachment, ...postData } = state.postData;
      let post: { [key: string]: any } = {
        ...postData,
        scheduleTime,
        scheduledAt
      };

      if (state.attachments["all"].type === "photo") {
        const { type, photoAttachments } = state.attachments["all"];

        const { url, metaData } = photoAttachments.attachments.reduce(
          (acc, att) => {
            if (!!att.url) {
              acc.url.push(att.url);
              if (!!att.metaData) {
                acc.metaData.push(att.metaData);
              }
            }
            return acc;
          },
          { url: [], metaData: [] } as {
            url: string[];
            metaData: { [key: string]: any }[];
          }
        );

        if (url.length > 0) {
          post.attachment = {
            type,
            url,
            metaData
          };
        }
      } else if (state.attachments["all"].type === "video") {
        const { type, options, videoAttachments } = state.attachments["all"];

        const { url, metaData } = videoAttachments.attachments[0] ?? {};

        if (!!url) {
          post.attachment = {
            type,
            url,
            ...(Object.keys(options).length > 0 ? { options } : {}),
            metaData
          };
        }
      } else if (state.attachments["all"].type === "pdf") {
        const { type, options, pdfAttachments } = state.attachments["all"];

        const { url, metaData } = pdfAttachments.attachments[0] ?? {};

        if (!!url && !!metaData) {
          post.attachment = {
            type,
            url,
            ...(Object.keys(options).length > 0 ? { options } : {}),
            metaData
          };
        }
      } else if (state.attachments["all"].type === "article") {
        const { type, articleAttachments } = state.attachments["all"];

        if (!!articleAttachments.attachment) {
          post.attachment = {
            type,
            ..._.pick(articleAttachments.attachment, [
              "title",
              "description",
              "image",
              "url",
              "domain",
              "favicon",
              "embeddable",
              "publisher"
            ])
          };
        }
      }

      let postEvent: PostEvent | null = null;
      if (fromModal && !post.id) {
        postEvent = PostEvent.CREATE_DRAFT;
      } else if (state.inDraftMode) {
        if (postData.isPostGroup) {
          postEvent = PostEvent.POST_GROUP_TO_DRAFT;
        } else if (!post.isDraft && post.id) {
          postEvent = PostEvent.POST_TO_DRAFT;
        } else if (!post.isDraft && !post.id) {
          postEvent = PostEvent.CREATE_DRAFT;
        } else if (post.isDraft && post.id) {
          postEvent = PostEvent.UPDATE_DRAFT;
        }
      } else {
        if (postData.isPostGroup) {
          postEvent = PostEvent.UPDATE_POST_GROUP;
        } else if (post.isDraft && post.id) {
          postEvent = PostEvent.DRAFT_TO_POST;
        } else if (!post.isDraft && post.id) {
          postEvent = PostEvent.UPDATE_POST;
        } else if (!post.isDraft && !post.id) {
          postEvent = PostEvent.CREATE_POST;
        }
      }

      post = constructPostData(post);

      createUpdatePost(
        postEvent!,
        post,
        (response, successToast) => {
          addToast(successToast, "success");
          setIsSubmitting(false);

          const res = {
            ...response,
            isPostedNow: post.scheduleTime === "NOW"
          };

          if (state.inDraftMode || post.isDraft || (fromModal && !post.id)) {
            res.isDraft = true;
          }
          if (post.isPostGroup) {
            res.isPostGroup = true;
          }
          closeComposer();
          state.events.onPostedEvent.dispatch(res, { ...metaData, post });
          if (callback) {
            callback();
          }
        },
        (errorToast, closeComposerError = false) => {
          setIsSubmitting(false);
          addToast(errorToast, "error");

          // Only close the composer if the error is a closeComposerError
          if (closeComposerError) {
            closeComposer();
          }
        },
        {
          isCalendarEvent: !!metaData.calendarEventId,
          proMode: state.inProMode || showWillowAIRevamp ? "Yes" : "No",
          captionSplit: state.inSplitMode ? "Yes" : "No",
          isExtensionPost: !!metaData.isExtensionPost ? "Yes" : "No"
        }
      );
    },
    [
      addToast,
      closeComposer,
      state.postData,
      setIsSubmitting,
      state.inProMode,
      showWillowAIRevamp,
      state.attachments,
      state.inDraftMode,
      state.inSplitMode,
      state.events.onPostedEvent
    ]
  );

  const deletePost = useCallback(
    async post => {
      try {
        dispatch(setIsDeletingAction(true));
        const params = post.isPostGroup
          ? {
              method: "delete",
              url: `/accounts/${accountId}/post-groups/${post.id}`
            }
          : post.isDraft
            ? {
                method: "delete",
                url: `/accounts/${accountId}/draft-posts/${post.id}`
              }
            : { method: "delete", url: `/posts/${post.id}` };
        await callApi(params);
        await new Promise(resolve => setTimeout(resolve, 1000));
        addToast(
          `Successfully deleted ${post.isDraft ? "draft" : "post"}`,
          "success"
        );

        if (post.isDraft) {
          reduxDispatch(changeDraftCountByValue(-1));
          reduxDispatch(removeDraftById(post.id));
        }
        reduxDispatch(
          trackAnalyticsEvent("Deleted Post", {
            id: post.id,
            isDraft: !!post.isDraft,
            isGroup: !!post.isPostGroup
          })
        );
        state.events.onDeletedEvent.dispatch(post);
      } catch (error) {
        addToast((error as Error).message, "error");
      } finally {
        closeComposer();
        dispatch(setIsDeletingAction(false));
      }
    },
    [
      addToast,
      accountId,
      closeComposer,
      reduxDispatch,
      state.events.onDeletedEvent
    ]
  );

  const deleteTask = useCallback(
    async (task: any, mode: keyof typeof TASK_TYPES = "INSTANCE") => {
      try {
        dispatch(setIsDeletingAction(true));
        const params =
          mode === TASK_TYPES.SERIES && !!task.taskGroupId
            ? {
                method: "delete",
                url: `/accounts/${accountId}/task-groups/${task.taskGroupId}`
              }
            : {
                method: "delete",
                url: `/accounts/${accountId}/tasks/${task.id || task.taskId}`
              };
        await callApi(params);
        await new Promise(resolve => setTimeout(resolve, 1000));
        addToast("Task successfully deleted", "success");
        reduxDispatch(
          trackAnalyticsEvent("Deleted Task", {
            id: task.taskGroupId || task.id || task.taskId,
            mode:
              mode === TASK_TYPES.SERIES && !!task.taskGroupId
                ? "series"
                : "instance"
          })
        );
        state.events.onDeletedEvent.dispatch(task);
      } catch (error) {
        addToast(
          (error as Error).message || "There was an error deleting your task",
          "error"
        );
      } finally {
        closeComposer();
        dispatch(setIsDeletingAction(false));
      }
    },
    [
      addToast,
      accountId,
      closeComposer,
      reduxDispatch,
      state.events.onDeletedEvent
    ]
  );
  // * Action dispatch functions-->

  // * <--Common functions (with useEffect if required)
  const fetchTags = useCallback(async () => {
    try {
      const tags = await fetchAvailableTags();

      dispatch(setTagsAction(tags));
    } catch (error) {
      addToast((error as Error).message, "error");
    }
  }, [addToast]);

  const fetchPostConceptsAndIdeas = useCallback(async () => {
    try {
      const postConcepts = await fetchPostConcepts();

      dispatch(setPostConceptsAction(postConcepts));
    } catch (error) {
      dispatch(setPostConceptsAction([]));
      addToast((error as Error).message, "error");
    }
  }, [addToast]);

  useGranularEffect(
    () => {
      if (state.isComposerOpen) {
        toggleProMode(getProModePreference());
        fetchTags();
        setInitialVisibleCaption();
      }
    },
    [state.isComposerOpen],
    [fetchTags, setInitialVisibleCaption]
  );

  useGranularEffect(
    () => {
      if (state.isComposerOpen && (state.inProMode || showWillowAIRevamp)) {
        fetchPostConceptsAndIdeas();
      }
    },
    [state.isComposerOpen, state.inProMode, showWillowAIRevamp],
    [fetchPostConceptsAndIdeas]
  );

  const currentCaption = useMemo(
    () =>
      !!state.postData.caption
        ? state.postData.caption[state.visibleCaption] ?? ""
        : "",
    [state.postData.caption, state.visibleCaption]
  );

  const getImageSuggestions = useCallback(
    async (
      query: string = "",
      useNlp: boolean = true,
      refreshList: boolean = true
    ) => {
      let images: any[] = [];

      try {
        if (refreshList) {
          dispatch(setSuggestedImagesAction([]));
        }
        dispatch(setIsLoadingSuggestedImagesAction(true));
        images = await fetchImageSuggestions(query, useNlp);
        dispatch(
          setNewImagesIndicationAction(
            state.activeToolkit !== "imageSuggestion" && images.length > 0
          )
        );

        if (!!query) {
          reduxDispatch(
            trackAnalyticsEvent("Images Suggested", {
              searchPhrase: query,
              useNlp: useNlp ? "Yes" : "No"
            })
          );
        }
      } catch (error) {
        addToast((error as Error).message, "error");
      } finally {
        dispatch(setSuggestedImagesAction(images));
        dispatch(setIsLoadingSuggestedImagesAction(false));
      }
    },
    [addToast, reduxDispatch, state.activeToolkit]
  );

  const handleImageSuggestionSearch = useCallback(
    _.debounce(
      async (query: string = "", useNlp: boolean = true) =>
        getImageSuggestions(query, useNlp),
      2000,
      {
        leading: true,
        trailing: false
      }
    ),
    []
  );

  const imageSearchOnCaptionChange = useCallback(
    _.debounce(caption => {
      const queryDiff = Math.max(
        _.difference(
          caption.trim().split(" "),
          state.suggestedImages.query.trim().split(" ")
        ).length,
        _.difference(
          state.suggestedImages.query.trim().split(" "),
          caption.trim().split(" ")
        ).length
      );
      if (
        caption.length >= 20 &&
        (state.suggestedImages.query === "" || queryDiff >= 4)
      ) {
        dispatch(setImagesQueryAction(caption));
        handleImageSuggestionSearch(caption);
      }
    }, 2000),
    [handleImageSuggestionSearch, state.suggestedImages.query]
  );

  useGranularEffect(
    () => {
      if (state.isComposerOpen && (state.inProMode || showWillowAIRevamp)) {
        imageSearchOnCaptionChange(currentCaption);
      }
    },
    [currentCaption, state.isComposerOpen, state.inProMode, showWillowAIRevamp],
    [imageSearchOnCaptionChange]
  );

  useGranularEffect(
    () => {
      if ((state.isComposerOpen && state.inProMode) || showWillowAIRevamp) {
        handleImageSuggestionSearch();
      }
    },
    [state.isComposerOpen, state.inProMode, showWillowAIRevamp],
    [handleImageSuggestionSearch]
  );

  const openPost = useCallback(
    (post: IPostData) => {
      if (post.status === POST_STATUS.SENT) {
        openPostOnChannel(post);
      } else {
        openComposer(post);
      }
    },
    [openComposer]
  );

  useEffect(() => {
    if (!!accountId && !state.isComposerOpen && !modalState.isOpen) {
      const paramKeys = {
        postId: {},
        postGroupId: { isPostGroup: true },
        draftId: { isDraft: true }
      };
      const identifiedQueryParamKeys = _.keys(queryParams).filter(key =>
        _.keys(paramKeys).includes(key)
      );
      if (identifiedQueryParamKeys.length > 1) {
        history.replace({});
        addToast("Invalid url parameters.", "error");
      } else {
        const paramKey = identifiedQueryParamKeys[0];
        const paramValue = queryParams[paramKey];
        const paramOptions = paramKeys[paramKey];

        if (paramValue) {
          openPostFromUrlId(paramValue, paramOptions);
        }
      }
    }
  }, [
    history,
    addToast,
    accountId,
    queryParams,
    modalState.isOpen,
    openPostFromUrlId,
    queryParams.postId,
    queryParams.draftId,
    state.isComposerOpen,
    queryParams.postGroupId
  ]);

  const createTask = useCallback(
    (date?: Date, initData?: { [key: string]: any }) => {
      const taskDate = !!date ? getSuitableSchedule(date) : null;

      const query = qs.stringify({
        taskId: "new",
        ...(!!taskDate ? { date: taskDate.toISOString() } : {})
      });

      history.push({
        pathname: `/accounts/${accountId}/schedule/month`,
        search: `?${query}`,
        state: initData
      });
    },
    [accountId, history, getSuitableSchedule]
  );

  const editTask = useCallback(
    (task: { [key: string]: any }, type: keyof typeof TASK_TYPES) => {
      if (type === TASK_TYPES.SERIES) {
        history.push({
          pathname: `/accounts/${accountId}/schedule/month`,
          search: `?taskGroupId=${task.taskGroupId}`
        });
      } else {
        history.push({
          pathname: `/accounts/${accountId}/schedule/month`,
          search: `?taskId=${task.id}`
        });
      }
    },
    [accountId, history]
  );

  const createPostByDate = useCallback(
    (initialScheduleTime: Date, isDraft: boolean = false) => {
      openComposer(null, {
        initialScheduleTime,
        ...(isDraft ? { isDraft } : {})
      });
    },
    [openComposer]
  );

  const createPostByTask = useCallback(
    (task: { [key: string]: any }, isDraft: boolean = false) => {
      openComposer(null, {
        task,
        ...(isDraft ? { isDraft } : {})
      });
    },
    [openComposer]
  );

  const createPostByCalendarEvent = useCallback(
    (calendarEvent: { [key: string]: any }, isDraft: boolean = false) => {
      openComposer(null, {
        calendarEvent,
        ...(isDraft ? { isDraft } : {})
      });
    },
    [openComposer]
  );

  const deleteEntry = useCallback(
    (entry: any) => {
      let modalProps: IComposerState["confirmationModalProps"] = null;

      if (entry.task) {
        const { taskGroupId } = entry.task;

        if (!!taskGroupId) {
          modalProps = {
            title: "Deleting a series of tasks",
            description:
              "You're deleting a task that is part of a series. Please choose the tasks you want to delete.",
            type: TASK_TYPES.SERIES,
            showOptions: true,
            buttonProps: {
              variant: "danger",
              label: "Delete",
              action: (mode: keyof typeof TASK_TYPES) =>
                deleteTask(entry.task, mode)
            }
          };
        } else {
          modalProps = {
            title: "Delete task",
            description: "Are you sure you want to delete this task?",
            type: TASK_TYPES.INSTANCE,
            showOptions: false,
            buttonProps: {
              variant: "danger",
              label: "Delete",
              action: (mode: keyof typeof TASK_TYPES) =>
                deleteTask(entry.task, mode)
            }
          };
        }
      } else {
        const { isDraft, status } = entry.post;

        if (status === POST_STATUS.SENT) {
          modalProps = {
            title: `Delete ${capitalize(entry.channels[0].service)} post`,
            description:
              entry.channels[0].service === SERVICES.INSTAGRAM
                ? `In order to delete this post you need to go to Instagram and delete it there.`
                : `Are you sure you want to delete this post?`,
            showOptions: false,
            buttonProps: {
              variant:
                entry.channels[0].service === SERVICES.INSTAGRAM
                  ? `primary`
                  : `danger`,
              label:
                entry.channels[0].service === SERVICES.INSTAGRAM
                  ? `Go to Instagram`
                  : `Delete`,
              action: () => {
                if (entry.channels[0].service === SERVICES.INSTAGRAM) {
                  openPostOnChannel(entry.post);
                } else {
                  deletePost(entry.post);
                }
              }
            }
          };
        } else {
          modalProps = {
            title: `Delete ${isDraft ? "Draft" : "Post"}`,
            description: `Are you sure you want to delete this ${
              isDraft ? "draft" : "post"
            }?`,
            showOptions: false,
            buttonProps: {
              variant: "danger",
              label: "Delete",
              action: () => deletePost(entry.post)
            }
          };
        }
      }

      dispatch(setConfirmationModalPropsAction(modalProps));
    },
    [deletePost, deleteTask]
  );
  // * Common functions-->

  const actions = useMemo(
    () => ({
      toggleProMode,
      setInDraftMode,
      setEditorStateObj,
      setVisibleCaption,
      setValidationErrors,
      setComposerNotifications,
      setChosenSuggestedCaption,
      setPostIdeaCaptions,
      setPostData,
      setAttachmentType,
      setIsUploadingAttachments,
      pasteCaptionToComposer,
      addUploadedAttachments,
      removeAttachment,
      removeArticleAttachment,
      reorderAttachment,
      setAttachmentValidation,
      setAttachmentOptions,
      setIsSubmitting,
      setIsDeleting,
      setWasScheduleChanged,
      setPickedDate,
      setMoodFilter,
      setNewImagesIndication,
      setImagesSearchTerm,
      setSuggestedImages,
      setActiveToolkit,
      setConfirmationModalProps,
      setIsComposerCloseConfirmationOpen,

      closeComposer,
      initializePostData,
      openComposer,
      scrapeUrl,
      getEnabledServicesWithType,
      getEnabledServices,
      onCaptionChange,
      splitEditor,
      onChannelSelected,
      createPost,
      deletePost,
      getImageSuggestions,
      handleImageSuggestionSearch,
      openPost,
      createTask,
      editTask,
      createPostByDate,
      createPostByTask,
      createPostByCalendarEvent,
      deleteEntry,
      openPublishedPostInComposer
    }),
    [
      toggleProMode,
      setInDraftMode,
      setEditorStateObj,
      setVisibleCaption,
      setValidationErrors,
      setComposerNotifications,
      setChosenSuggestedCaption,
      setPostIdeaCaptions,
      setPostData,
      setAttachmentType,
      setIsUploadingAttachments,
      pasteCaptionToComposer,
      addUploadedAttachments,
      removeAttachment,
      removeArticleAttachment,
      reorderAttachment,
      setAttachmentValidation,
      setAttachmentOptions,
      setIsSubmitting,
      setIsDeleting,
      setWasScheduleChanged,
      setPickedDate,
      setMoodFilter,
      setNewImagesIndication,
      setImagesSearchTerm,
      setSuggestedImages,
      setActiveToolkit,
      setConfirmationModalProps,
      setIsComposerCloseConfirmationOpen,

      closeComposer,
      initializePostData,
      openComposer,
      scrapeUrl,
      getEnabledServicesWithType,
      getEnabledServices,
      onCaptionChange,
      splitEditor,
      onChannelSelected,
      createPost,
      deletePost,
      getImageSuggestions,
      handleImageSuggestionSearch,
      openPost,
      createTask,
      editTask,
      createPostByDate,
      createPostByTask,
      createPostByCalendarEvent,
      deleteEntry,
      openPublishedPostInComposer
    ]
  );

  return (
    <ComposerStateContext.Provider value={state}>
      <ComposerDispatchContext.Provider value={dispatch}>
        <ComposerActionsContext.Provider value={actions}>
          {children}
        </ComposerActionsContext.Provider>
      </ComposerDispatchContext.Provider>
    </ComposerStateContext.Provider>
  );
});

export const useComposerState = <T,>(
  selector: (state: IComposerState) => T
): T => {
  try {
    return useContextSelector(
      ComposerStateContext as Context<IComposerState>,
      selector
    );
  } catch (_) {
    throw new Error("useComposerState must be used within a ComposerProvider");
  }
};

export const useComposerDispatch = () => {
  const context = useContext(ComposerDispatchContext);

  if (context === undefined) {
    throw new Error(
      "useComposerDispatch must be used within a ComposerProvider"
    );
  }
  return context;
};

export const useComposerActions = <T,>(
  selector: (state: IComposerActions) => T
): T => {
  try {
    return useContextSelector(
      ComposerActionsContext as Context<IComposerActions>,
      selector
    );
  } catch (_) {
    throw new Error(
      "useComposerActions must be used within a ComposerProvider"
    );
  }
};

import React, { useRef, useState } from "react";

import Tooltip from "./Tooltip";
import { EmployeeAvatar } from "../styles";
import Popper from "components/common/Popper";

import DefaultAvatar from "assets/images/dashboard/avatar-empty-state.png";

interface IEmployeeAvatarBlock {
  employeeDetails: any;
}

const EmployeeAvatarBlock: React.FC<IEmployeeAvatarBlock> = ({
  employeeDetails
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { rank, posts, score, avatar, username, engagement } = employeeDetails;

  const handleMouseEnter = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (e.currentTarget === ref.current) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (e.currentTarget === ref.current) {
      setShowTooltip(false);
    }
  };

  return (
    <EmployeeAvatar
      ref={ref}
      score={score}
      avatar={avatar || DefaultAvatar}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Popper
        offset={[0, 8]}
        placement="top"
        usePortal={true}
        visible={showTooltip}
        exceptions={[ref.current]}
        referenceElement={ref.current}
        onClose={() => setShowTooltip(false)}
      >
        <Tooltip
          rank={rank}
          posts={posts}
          username={username}
          engagement={engagement}
        />
      </Popper>
    </EmployeeAvatar>
  );
};

export default EmployeeAvatarBlock;

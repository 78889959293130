import React from "react";
import ReactLoading from "react-loading";
import { Flex } from "rebass/styled-components";

import {
  EmptyStateHelper,
  EmptyStateHeading,
  EmptyStateContainer,
  EmptyStateDescription,
  EmptyStateIconContainer
} from "./styles";
import Button from "./Button";
import { LoadingContent } from "../content/styles";

interface IEmptyState {
  description?: string;
  header: string;
  icon?: {
    component: React.ComponentType<any>;
    width: string;
    height: string;
  };
  cta?: {
    text: string;
    variant?: string;
    onClick: VoidFunction;
  };
  image?: React.ComponentType;
  center?: boolean;
  loading?: boolean;
  helper?: {
    text: string;
    onClick: React.MouseEventHandler;
  };
}

const EmptyState: React.FC<IEmptyState> = ({
  description,
  header,
  icon,
  cta,
  image,
  center = true,
  loading,
  helper = null
}) => {
  if (loading) {
    return (
      <LoadingContent>
        <ReactLoading color="#bbb" width={32} type="spin" />
      </LoadingContent>
    );
  }

  const Icon = icon ? (
    <icon.component width={icon.width} height={icon.height} />
  ) : (
    image ?? null
  );

  return (
    <Flex marginTop={"132px"} alignItems={"center"} justifyContent={"center"}>
      <EmptyStateContainer>
        {/* @ts-ignore */}
        <EmptyStateIconContainer>{Icon}</EmptyStateIconContainer>
        <EmptyStateHeading>{header}</EmptyStateHeading>
        {description && (
          <EmptyStateDescription>
            {description}
            {helper && (
              <EmptyStateHelper onClick={helper.onClick}>
                {helper.text}
              </EmptyStateHelper>
            )}
          </EmptyStateDescription>
        )}
        {cta && (
          <Flex alignItems={"center"} justifyContent={"center"} mt={40}>
            {/* @ts-ignore */}
            <Button
              variant={cta.variant ?? "primary"}
              size="m"
              onClick={cta.onClick}
            >
              {cta.text}
            </Button>
          </Flex>
        )}
      </EmptyStateContainer>
    </Flex>
  );
};

export default EmptyState;

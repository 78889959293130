import React from "react";
import BuildingBlocks from "../buildingBlocks";
import Recommendations from "../recommendations";
import RecommendedDomains from "../recommendations/domains";
import RecommendedInfluencers from "../recommendations/influencers";
import RecommendedTopics from "../recommendations/topics";
import AddRSSFeed from "../rss/AddRSSFeed";

interface IExploreRecommendationsProps {
  type: string;
}

const ExploreRecommendations: React.FC<IExploreRecommendationsProps> = ({
  type
}) => {
  switch (type) {
    case "all":
      return (
        <>
          <BuildingBlocks soft={true} />
          <Recommendations hideSeeAll={false} />
        </>
      );
    case "topics":
      return (
        <>
          <RecommendedTopics hideSeeAll={true} showAll={true} />
        </>
      );
    case "domains":
      return <RecommendedDomains hideSeeAll={true} />;
    case "twitter-sources":
      return <RecommendedInfluencers hideSeeAll={true} />;
    case "rss-sources":
      return <AddRSSFeed />;
    default:
      return <></>;
  }
};

export default ExploreRecommendations;

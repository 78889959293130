import React from "react";
import { TimeFive } from "@styled-icons/boxicons-regular";
import { Forbid } from "@styled-icons/remix-fill";
import { EntryIndicator, SentPostIndicator } from "./styles";

import Icon from "components/common/Icon";

const statusIcons = {
  SCHEDULED: <TimeFive size={14} />,
  TASK_SCHEDULE: <TimeFive size={14} />,
  TASK_SCHEDULE_PREVIEW: <TimeFive size={14} />,
  PROCESSING: <TimeFive size={14} />,
  SENT: <SentPostIndicator size={14} />,
  STUCK: <Icon icon="icon-error" size={"sm"} />,
  PROCESSING_ERROR: <Forbid size={14} />,
  DRAFT: <Icon icon="icon-draft" variant={"progress"} size={"sm"} />
};

type StatusIconKeys = keyof typeof statusIcons;

function EntryStatus({ status }: {status: StatusIconKeys | "NEW"}) {
  if (!status || status === "NEW") return null;

  return (
    <EntryIndicator $status={status}>{statusIcons[status]}</EntryIndicator>
  );
}

export default EntryStatus;

/**
 * Returns integer or float with the set number of decimal points digits as received in `maxDecimalPoints`
 *
 * @param {number} num
 * @param {number} maxDecimalPoints
 * @returns {number}
 */
export const getNumWithMaxDecimals = (
  num: number,
  maxDecimalPoints: number
) => {
  const precisionNum = parseFloat(num.toFixed(maxDecimalPoints));
  const decimalDigits = parseInt(precisionNum.toString().split(".")[1] ?? 0);

  return decimalDigits > 0 ? precisionNum : Math.round(precisionNum);
};

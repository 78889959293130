import { useSelector } from "react-redux";
import React, { useRef, useLayoutEffect } from "react";

import Shelve from "./shelve";
import { useNewsState } from "contextApi/newsContext";

interface IFollowedContent {
  id: string;
  sectionType: string;
  section: { [key: string]: any };
  refreshContentFeed: VoidFunction;
  contents: Array<{ [key: string]: any }>;
}

const FollowedContent: React.FC<IFollowedContent> = React.memo(
  ({ id, section, contents, sectionType, refreshContentFeed }) => {
    const ref = useRef<HTMLDivElement>(null);
    const sectionRefs = useNewsState(state => state.sectionRefs);
    const accountId = useSelector<any, any>(state => state.account.id);

    useLayoutEffect(() => {
      if (!!ref.current) {
        Object.assign(sectionRefs, {
          [id]: {
            ref,
            id,
            ratio: 0
          }
        });
      }
    }, [id, sectionRefs]);

    return (
      <div ref={ref} id={section.id}>
        <Shelve
          section={section}
          type={sectionType}
          accountId={accountId}
          onRefresh={refreshContentFeed}
          contents={contents.slice(0, 6)}
        />
      </div>
    );
  }
);

export default FollowedContent;

import * as signupActions from "../actions/SignupActions";
import { take, takeLatest, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import * as accountActions from "../actions/AccountActions";
import map from "lodash/map";
import compact from "lodash/compact";

function* submitCompanyDetails(action) {
  yield put(
    accountActions.updateAccount({
      ...action.values,
      progress: "company_details"
    })
  );
  yield take(accountActions.ACCOUNT_UPDATED);
  yield put(push("/signup/tags"));
}

const parseTags = tags => {
  return compact(
    map(tags, tag => {
      return tag ? tag.tag : null;
    })
  );
};

function* submitTags(action) {
  //transform tags
  const parsedValues = {
    businessTags: parseTags(action.values.businessTags),
    productTags: parseTags(action.values.productTags),
    brandTags: parseTags(action.values.brandTags)
  };

  yield put(
    accountActions.updateAccount({
      ...parsedValues,
      progress: "tags"
    })
  );
  yield take(accountActions.ACCOUNT_UPDATED);
  yield put(push("/signup/connect"));
}

export default function* root() {
  yield takeLatest(signupActions.SUBMIT_COMPANY_DETAILS, submitCompanyDetails);
  yield takeLatest(signupActions.SUBMIT_TAGS, submitTags);
}

import * as fns from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { getDayEntries } from "./postUtils";

const DATE_MAP = {
  SUNDAY: 0,
  MONDAY: 6,
  TUESDAY: 5,
  WEDNESDAY: 4,
  THURSDAY: 3,
  FRIDAY: 2,
  SATURDAY: 1
};

export function getDate(params = {}) {
  const { format, timezone, date } = params;
  let now = date ? new Date(date) : new Date();

  if (timezone) now = utcToZonedTime(now, timezone);

  if (format)
    now = format === "ISO" ? now.toISOString() : getFormattedDate(now, format);

  return now;
}

export function getDateInterval(date, params = {}) {
  const { before, after, format } = params;
  const start = fns.startOfDay(fns.addDays(date, -before));
  const end = fns.endOfDay(fns.addDays(date, after));

  return {
    start: format ? getFormattedDate(start, format) : start,
    end: format ? getFormattedDate(end, format) : end
  };
}

export function getOffsetDate(date, params = {}) {
  const { days, months, weeks } = params;
  let offsetDate = date;

  if (months) offsetDate = fns.addMonths(offsetDate, months);
  if (weeks) offsetDate = fns.addWeeks(offsetDate, weeks);
  if (days) offsetDate = fns.addDays(offsetDate, days);

  return offsetDate;
}

export function getFormattedDate(date, format = "ISO") {
  let formattedDate = date;

  if (typeof date === "string") formattedDate = fns.parseISO(date);

  if (format !== "ISO") return fns.format(formattedDate, format);

  return date.toISOString();
}

export function getCalendarDays(date, entries, params) {
  const { timezone } = params;
  const today = getDate(new Date(), { timezone: timezone });
  const focusDate = fns.startOfDay(date);
  const first = fns.startOfMonth(focusDate);
  const last = fns.endOfMonth(focusDate);
  const firstWeekStart = fns.startOfWeek(first, { weekStartsOn: 1 });

  const daysPerWeek = 7;
  const weeksPerMonth = fns.getWeeksInMonth(focusDate, { weekStartsOn: 1 });

  const days = [];

  for (let i = 0; i < daysPerWeek * weeksPerMonth; i++) {
    let day = fns.addDays(firstWeekStart, i);

    days.push({
      date: day,
      dayOfMonth: getFormattedDate(day, "d"),
      dayOfWeek: getFormattedDate(day, "EEEE"),
      key: getFormattedDate(day),
      isOutsideMonth: fns.isBefore(day, first) || fns.isAfter(day, last),
      isPast: fns.isBefore(day, today),
      isToday: fns.isSameDay(day, today),
      entries: getDayEntries(day, entries)
    });
  }

  return days;
}

/**
 * Returns date of the last X (MONDAY, FIRDAY,...) day
 *
 * @param {date} date
 * @param {string} day
 * @returns {date}
 */
export function getDateOfLastXDay(date, day) {
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const num = DATE_MAP[day];

  const lastDate = new Date(
    lastDay.setDate(lastDay.getDate() - ((lastDay.getDay() + num) % 7))
  );

  return lastDate;
}

/**
 * Returns the week (in number) with respect to the month the date lies in
 *
 * @param {date} date
 * @returns {number}
 */
export function getTheWeekADateBelongsTo(date) {
  const weekStartsOn = fns.format(fns.startOfMonth(date), "e") - 1;
  const count = fns.getWeekOfMonth(date, {
    weekStartsOn
  });

  return count;
}

/**
 * Returns the week (in number) with respect to the month of the last X (Monday, Sunday, ...) day
 *
 * @param {date} date
 * @returns {number}
 */
export function getWeekOfTheLastXDayOfAMonth(date, day) {
  const lastDate = getDateOfLastXDay(date, day);

  return getTheWeekADateBelongsTo(lastDate);
}

/**
 * Retuns the uppercased day in words
 *
 * @param {date} date
 * @returns
 */
export function getDayInWords(date) {
  return fns.format(date, "eeee").toUpperCase();
}

/**
 * Returns date after n months
 *
 * @param {date} date
 * @param {number} n
 */
export function getDateAfterNMonths(date, n) {
  return fns.addMonths(date, n).toISOString();
}

const WEEK = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY"
];

/**
 * Returns whether the given day is before a date
 *
 * @param {string} dayName
 * @param {date} refDate
 * @returns {boolean}
 */
export function isDayBefore(dayName, refDate) {
  const dayOfWeek = WEEK.indexOf(dayName);
  if (dayOfWeek < 0) return;

  const selectedDay = refDate.getDay();

  return selectedDay > dayOfWeek;
}

/**
 * Returns the date of the upcoming day with respect to refDate
 *
 * @param {string} dayName
 * @param {boolean} excludeToday
 * @param {date} refDate
 * @returns {date}
 */

export function getNextDayOfTheWeek(
  dayName,
  excludeToday = true,
  refDate = new Date()
) {
  const dayOfWeek = WEEK.indexOf(dayName);
  if (dayOfWeek < 0) return;
  refDate.setHours(0, 0, 0, 0);
  refDate.setDate(
    refDate.getDate() +
      +!!excludeToday +
      ((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
  );
  return refDate;
}

import qs from "qs";
import ReactLoading from "react-loading";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useToaster } from "@hellocontento/maillard";
import React, { useEffect, useCallback } from "react";

import { callApi } from "utils/ContentoApi";
import { useAppState } from "contextApi/appContext";
import * as modalActions from "state/actions/ModalActions";
import * as accountActions from "state/actions/AccountActions";
import { getChannelConnectedRedirectPath } from "utils/channels";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

/**
 * On this page the user will select the specific facebook pages to connect to his contento account
 */
const ConnectLinkedinBusinessPage = React.memo(
  ({
    account,
    history,
    redirect,
    openModal,
    fetchAccount,
    linkedInAccessToken,
    linkedInRefreshToken
  }) => {
    const addToast = useToaster();
    const allowedChannels = useAppState(
      state => state.clientSettings.allowedChannels
    );
    const dispatch = useDispatch();

    const connectBusinesses = useCallback(
      async businesses => {
        const createChannel = async business => {
          const channelData = {
            service: "linkedin",
            serviceType: "business",
            username: business.vanityName,
            avatar: business.avatar,
            connection: {
              connectionType: "linkedin_business",
              connectionDetails: {
                businessId: business.id,
                businessUrn: business.urn,
                accessToken: linkedInAccessToken,
                refreshToken: linkedInRefreshToken
              }
            }
          };
          return callApi({
            method: "post",
            url: `/accounts/${account.id}/channels`,
            data: channelData
          });
        };

        try {
          const availableChannelSlots = Math.max(
            allowedChannels - account.channels.length,
            0
          );
          const allowedBusinesses = businesses.slice(0, availableChannelSlots);
          const pageLocation = history.location.pathname.includes("onboarding")
            ? "onboarding"
            : "settings";
          await Promise.all(
            businesses.map(async business => {
              const result = await createChannel(business);
              dispatch(
                trackAnalyticsEvent("Channel Connected", {
                  channelType: "linkedin_business",
                  location: pageLocation
                })
              );
              return result;
            })
          );
          //fetch account with new channels
          fetchAccount(account.id);
          history.push(getChannelConnectedRedirectPath(account, redirect));
        } catch (error) {
          addToast(
            "Something unexpected went wrong. Please refresh the page and try again.",
            "error"
          );
        }
      },
      [
        account,
        history,
        addToast,
        dispatch,
        redirect,
        fetchAccount,
        linkedInAccessToken,
        linkedInRefreshToken
      ]
    );

    useEffect(() => {
      callApi({
        url: `/accounts/${account.id}/connect/linkedin/organizations?token=${linkedInAccessToken}`
      })
        .then(async businesses => {
          if (businesses.length === 1) {
            await connectBusinesses(businesses);
          } else {
            openModal("SELECT_LINKEDIN_BUSINESS_MODAL", {
              pages: businesses,
              connectPages: connectBusinesses
            });
          }
        })
        .catch(error => {
          addToast(
            "Something went wrong while trying to fetch your facebook pages. Please try again or contact support.",
            "error"
          );
        });
    }, [
      account.id,
      addToast,
      connectBusinesses,
      linkedInAccessToken,
      openModal
    ]);

    return <ReactLoading color={"#bbb"} type={"cylon"} />;
  }
);

const mapStateToProps = (state, props) => {
  const queryParams = qs.parse(props.location.search, {
    ignoreQueryPrefix: true
  });
  return {
    account: state.account.data,
    linkedInAccessToken: queryParams.accessToken,
    linkedInRefreshToken: queryParams.refreshToken,
    redirect: queryParams.redirect
  };
};

export default withRouter(
  connect(mapStateToProps, {
    fetchAccount: accountActions.fetchAccount,
    openModal: modalActions.openModal
  })(ConnectLinkedinBusinessPage)
);

import { Flex } from "rebass";
import Modal from "react-modal";
import styled from "styled-components";
import React, { useEffect } from "react";

import Button from "components/common/Button";
import { Headline3 } from "components/common/styles";

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: auto;
  background: ${props => props.theme.colors.grey01};
  border-radius: 12px;

  > span {
    font-size: 18px;
  }
`;

const InfoWrapper = styled(Flex)`
  flex-direction: column;
  gap: 16px;
  text-align: center;
  justify-content: center;
`;

const InfoPara = styled.p`
  margin-top: 8px;
  line-height: 140%;
  font-weight: 400;
  color: ${props => props.theme.colors.text02};
`;

const ButtonGroup = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  margin-top: 32px;
`;

const ContentWrapper = styled.div`
  max-width: 272px;
  margin: auto;
`;

export const modalStyles = (maxWidth = 550, showFooter = false) => {
  return {
    // dark background behind all modals
    overlay: {
      background: "rgba(0,0,0,0.25)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      //overflowY: 'visible',
      overflowX: "hidden",
      zIndex: 15,
      padding: "15px 20px 15px 20px"
    },
    // modal root
    content: {
      overflow: "auto",
      maxHeight: "95vh",
      boxSizing: "border-box",
      background: "#F7F8F9",
      backgroundClip: "padding-box",
      borderRadius: "16px",
      position: "relative",
      border: "1px",
      borderColor: "#EAEBEB",
      width: "100%",
      maxWidth: `${maxWidth}px`,
      top: "auto",
      bottom: "auto",
      left: "auto",
      right: "auto",
      backgroundColor: "white",
      boxShadow:
        "0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)",
      padding: "24px"
    }
  };
};

interface ICloseComposerModalProps {
  isEdit: boolean;
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
  onCancel: () => void;
  isPostEmpty: boolean;
  closeToRefreshChannels: boolean;
}

const CloseComposerModal: React.FC<ICloseComposerModalProps> = ({
  isEdit,
  isOpen,
  onSave,
  onClose,
  onCancel,
  isPostEmpty,
  closeToRefreshChannels
}) => {
  const styles = modalStyles(360);

  const modalInfo = closeToRefreshChannels
    ? {
        title: "To refresh this connection, you must close this post.",
        description:
          "You can choose to save your post as a draft or not save. You can’t undo this action.",
        saveBtnLabel: "Save and refresh channels",
        dontSaveBtnLabel: "Discard and refresh channels"
      }
    : isEdit
      ? {
          title: "Want to save your changes?",
          description:
            "Your changes will be lost if you don't save them. This can't be undone.",
          saveBtnLabel: "Save Changes",
          dontSaveBtnLabel: "Don't save"
        }
      : {
          title: "Do you want to save this post?",
          description:
            "You can choose to save your post as draft or not save. You can't undo this action.",
          saveBtnLabel: "Save",
          dontSaveBtnLabel: "Don't save"
        };

  useEffect(() => {
    if (isOpen && isPostEmpty) {
      onClose();
    }
  }, [isOpen, isPostEmpty, onClose]);

  return (
    <Modal
      // @ts-ignore
      name={"close-modal"}
      ariaHideApp={false}
      isOpen={isOpen}
      // @ts-ignore
      style={styles}
      onRequestClose={() => {}}
    >
      <InfoWrapper>
        <IconWrapper>
          <span className="icon-edit" />
        </IconWrapper>
        <ContentWrapper>
          <Headline3>{modalInfo.title}</Headline3>
          <InfoPara>{modalInfo.description}</InfoPara>
        </ContentWrapper>
        <ButtonGroup>
          {/* @ts-ignore */}
          <Button variant="primary" onClick={onSave}>
            {modalInfo.saveBtnLabel}
          </Button>
          {/* @ts-ignore */}
          <Button variant="secondary" onClick={onClose}>
            {modalInfo.dontSaveBtnLabel}
          </Button>
          {/* @ts-ignore */}
          <Button variant="secondaryQuiet" onClick={onCancel}>
            Cancel
          </Button>
        </ButtonGroup>
      </InfoWrapper>
    </Modal>
  );
};

export default CloseComposerModal;

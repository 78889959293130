export const ADD_SECTIONS = "@@contents/ADD_SECTIONS";
export const FOLLOW_SECTION = "@@contents/FOLLOW_SECTION";
export const UNFOLLOW_SECTION = "@@contents/UNFOLLOW_SECTION";
export const UPDATE_SECTION = "@@activities/UPDATE_SECTION";

export const saveSections = data => {
  return {
    type: ADD_SECTIONS,
    data
  };
};

export const updateSection = data => {
  return {
    type: UPDATE_SECTION,
    data
  };
};

export const followSection = data => {
  return {
    type: FOLLOW_SECTION,
    data
  };
};

export const unfollowSection = data => {
  return {
    type: UNFOLLOW_SECTION,
    data
  };
};

/**
 * This is a component extracted from draft-js-mention-plugin v3.1.5
 * as a dependency for MentionSuggestions component. It involves the
 * positioning of the popup suggestions component.
 * draft-js-mention-plugin version => 3.1.5
 */

import clsx from "clsx";
import { usePopper } from "react-popper";
import React, { useEffect, useState } from "react";

const Popover = ({
  store,
  children,
  theme,
  popperOptions = { placement: "bottom-start" }
}) => {
  const [className, setClassName] = useState(() =>
    clsx(theme.mentionSuggestions, theme.mentionSuggestionsPopup)
  );
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(
    store.getReferenceElement(),
    popperElement,
    popperOptions
  );

  useEffect(() => {
    requestAnimationFrame(() =>
      setClassName(
        clsx(
          theme.mentionSuggestions,
          theme.mentionSuggestionsPopup,
          theme.mentionSuggestionsPopupVisible
        )
      )
    );
  }, [theme]);

  return (
    <div
      ref={setPopperElement}
      style={styles.popper}
      {...attributes.popper}
      className={className}
    >
      {children}
    </div>
  );
};

export default Popover;

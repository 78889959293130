import React from "react";
import { Box } from "rebass/styled-components";
import WidgetContainer, { WidgetHeader } from "./WidgetContainer";
import { colors } from "../../../theme";
import LinePlot from "./LinePlot";
import BarPlot from "./BarPlot";

const metricColors = {
  impressions: colors.blue,
  engagements: colors.orange,
  audience: colors.purple,
  followers: colors.greenActive
};

function AnalyticsSubplot({
  title = "Metric",
  period = 7,
  description = "",
  metricId,
  series,
  type = "bar",
  color = "#0063FB",
  indexBy = "date",
  data
}) {
  const PlotComponent = props => {
    if (type === "line")
      return <LinePlot data={data} indexBy={indexBy} {...props} />;
    else return <BarPlot data={data} indexBy={indexBy} {...props} />;
  };

  return (
    <WidgetContainer>
      <WidgetHeader
        title={title}
        description={`last ${period} days ${
          description.length > 0 ? `· ${description}` : ""
        }`}
      />
      <Box p={16}>
        <PlotComponent
          metricId={metricId}
          series={series}
          color={metricColors[metricId] ?? color}
        />
      </Box>
    </WidgetContainer>
  );
}

export default AnalyticsSubplot;

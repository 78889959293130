import PropTypes from "prop-types";
import { upperFirst } from "lodash/string";
import { Box } from "rebass/styled-components";
import React, { useState, useEffect } from "react";

import Caption from "./caption";
import { SystemMessage } from "../styles";
import { useContentoApi } from "utils/useContentoApi";
import { ChannelIcon, CustomizedCaptionTabs, PillButton } from "./styles";

const { useRef } = require("react");

const Captions = ({
  post,
  captions,
  wasSplit,
  accountId,
  onUrlAdded,
  visibleCaption,
  enabledServices,
  onCaptionChange,
  validationErrors,
  setVisibleCaption,
  enableCustomizedCaptions
}) => {
  const [availableTags, setAvailableTags] = useState([]);
  const [editorStateObj, setEditorStateObj] = useState({});
  const [wasCaptionUsed, setWasCaptionUsed] = useState(false);
  const [fetchAvailableTags] = useContentoApi(`/accounts/${accountId}/tags`);
  const prevEnabledServicesRef = useRef();

  useEffect(() => {
    prevEnabledServicesRef.current = enabledServices;
  });
  const prevEnabledServices = prevEnabledServicesRef.current;

  useEffect(() => {
    if (
      prevEnabledServices &&
      prevEnabledServices.length !== enabledServices.length &&
      visibleCaption !== "all"
    ) {
      setVisibleCaption(enabledServices[0]);
    }
  }, [prevEnabledServices, enabledServices, visibleCaption, setVisibleCaption]);

  const enabledCaptions = visibleCaption === "all" ? ["all"] : enabledServices;

  useEffect(() => {
    fetchAvailableTags()
      .then(setAvailableTags)
      .catch(err => console.error(err));
  }, [fetchAvailableTags]);

  const setDefaultEditorState = newState => {
    setEditorStateObj({
      ...editorStateObj,
      ...newState
    });
  };

  const splitEditor = () => {
    if (!wasSplit) {
      setWasCaptionUsed(true);
      const newEditorStateObj = {};
      enabledServices.forEach(service => {
        newEditorStateObj[service] = editorStateObj["all"];
      });
      setEditorStateObj({
        ...editorStateObj,
        ...newEditorStateObj
      });
    }
    enableCustomizedCaptions();
  };

  return (
    <div>
      {visibleCaption !== "all" && (
        <CustomizedCaptionTabs>
          {enabledServices.map(service => {
            return (
              <PillButton
                key={service}
                isActive={visibleCaption === service}
                onClick={() => setVisibleCaption(service)}
              >
                <div className="button-content">
                  <ChannelIcon channel={service} />
                  {visibleCaption === service && (
                    <span className="service-name">{upperFirst(service)}</span>
                  )}
                </div>
              </PillButton>
            );
          })}
        </CustomizedCaptionTabs>
      )}

      {enabledCaptions.map(service => {
        return (
          <Caption
            post={post}
            key={service}
            service={service}
            captions={captions}
            wasSplit={wasSplit}
            accountId={accountId}
            onUrlAdded={onUrlAdded}
            splitEditor={splitEditor}
            availableTags={availableTags}
            wasCaptionUsed={wasCaptionUsed}
            enabledServices={enabledServices}
            onCaptionChange={onCaptionChange}
            visible={visibleCaption === service}
            setWasCaptionUsed={setWasCaptionUsed}
            defaultEditorState={editorStateObj[service]}
            setDefaultEditorState={setDefaultEditorState}
          />
        );
      })}

      {!!validationErrors.charLimit && (
        <Box my={12}>
          <SystemMessage
            type="error"
            service={validationErrors.charLimit.service}
          >
            <span>
              Your caption has{" "}
              <em>
                {validationErrors.charLimit.characterCount -
                  validationErrors.charLimit.characterLimit}
              </em>{" "}
              characters too much for{" "}
              {upperFirst(validationErrors.charLimit.service)}
            </span>
          </SystemMessage>
        </Box>
      )}

      {!!validationErrors.tagLimit && (
        <Box my={12}>
          <SystemMessage type="error">
            <span>
              Your caption has{" "}
              <em>
                {validationErrors.tagLimit.tagCount -
                  validationErrors.tagLimit.tagLimit}
              </em>{" "}
              tags too many
            </span>
          </SystemMessage>
        </Box>
      )}
    </div>
  );
};

Captions.propTypes = {
  captions: PropTypes.array,
  post: PropTypes.object.isRequired,
  validationErrors: PropTypes.object,
  wasSplit: PropTypes.bool.isRequired,
  onUrlAdded: PropTypes.func.isRequired,
  onCaptionChange: PropTypes.func.isRequired,
  enabledServices: PropTypes.array.isRequired,
  visibleCaption: PropTypes.string.isRequired,
  setVisibleCaption: PropTypes.func.isRequired,
  enableCustomizedCaptions: PropTypes.func.isRequired
};

export default Captions;

import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "components/common/Button";
import { Wrapper, BannerImage, Subtitle, Title, ButtonWrapper } from "./styles";

const EmptyTaskList: React.FC<{}> = () => {
  const history = useHistory();
  const account = useSelector<any, any>(state => state.account.data);

  const handleClick = () => {
    history.push(`/accounts/${account.id}/settings/channels`);
  };

  return (
    <Wrapper>
      <BannerImage />
      <Title>First, connect your channels</Title>
      <Subtitle>
        Before you can create a content calendar, tasks or drafts you need to
        connect your socials.
      </Subtitle>
      <ButtonWrapper>
        {/* @ts-ignore */}
        <Button size="xs" variant="tertiary" onClick={handleClick}>
          Connect channels
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default EmptyTaskList;

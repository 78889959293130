import React, { useState } from "react";
import { useToaster } from "@hellocontento/maillard";

import {
  BlogSideColumn,
  SidePicture,
  SidePictureSmall,
  DeliveryTime,
  Price,
  Workflow,
  SentMessage,
  MainTitleSide
} from "./styles";

import SidePictureSrc from "../../assets/images/blog/side-picture.jpg";
import SidePictureSmallSrc from "../../assets/images/blog/side-picture-small.png";

import { connect } from "react-redux";
import { useContentoApi } from "../../utils/useContentoApi";
import Button from "../common/Button";

function BlogSide({ account, currentUser }) {
  const addToast = useToaster();
  const [requestBlogPost] = useContentoApi(
    `accounts/${account.id}/blog/request`,
    "post"
  );
  const [blogRequested, setBlogRequested] = useState(false);

  async function onProceed() {
    setBlogRequested(true);
    try {
      await requestBlogPost({ data: { email: currentUser.email } });
    } catch (error) {
      addToast(
        "There was an error sending your request, please contact your account manager.",
        "error"
      );
    }
  }

  return (
    <BlogSideColumn>
      <div>
        <SidePicture src={SidePictureSrc} alt={""} />
        <SidePictureSmall src={SidePictureSmallSrc} alt={""} />
      </div>

      <MainTitleSide>Articles & Blog Writing</MainTitleSide>

      {blogRequested ? (
        <SentMessage>We'll get in contact with you soon!</SentMessage>
      ) : null}

      <DeliveryTime>5 days delivery</DeliveryTime>
      <Price>
        <div>
          <em>Price</em>1 blog post (500 words)
        </div>
        <div>
          <em>&nbsp;</em>
          €250,00
        </div>
      </Price>
      <Workflow>
        <em>Workflow</em>
        <ul>
          <li className={blogRequested ? "done" : "current"}>
            <span>Request</span>
          </li>
          <li className={blogRequested ? "current" : ""}>
            <span>Briefing by phone or mail</span>
          </li>
          <li>
            <span>First version (3-5 business days)</span>
          </li>
          <li>
            <span>Feedback moment</span>
          </li>
          <li>
            <span>Final version (1-3 business days)</span>
          </li>
        </ul>
      </Workflow>

      <Button
        mt="32px"
        size="m"
        width="100%"
        onClick={onProceed}
        disabled={blogRequested}
      >
        {!blogRequested ? "Send Request" : "Request Sent!"}
      </Button>
    </BlogSideColumn>
  );
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser,
    account: state.account.data
  };
};

export default connect(mapStateToProps, {})(BlogSide);

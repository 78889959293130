import CaptionTooltip from "../CaptionTooltip";
import React, { useState, useEffect } from "react";

import { EntityWrapper } from "../styles";
import Popper from "components/common/Popper";

// The component we render when we encounter a caption in the text
const ReplaceableCaption = ({ children, captionHandler }) => {
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const [referenceElement, setReferenceElement] = useState(null);

  const handleGenerate = () => {
    captionHandler.showNextCaption();
  };

  const handleClose = () => {
    setTooltipVisible(false);
    captionHandler.removeCaptionEntity();
    captionHandler.setWasCaptionUsed(true); // To disable generator caption plugin after using a caption
  };

  useEffect(() => {
    // ? For re-rendering the position of tooltip when url shortening completes
    setTimeout(() => {
      setTooltipVisible(false);
      setTooltipVisible(true);
    }, 10);
  }, [captionHandler.lastReloadPosition]);

  return (
    <EntityWrapper id="entity" ref={setReferenceElement}>
      {children}
      <Popper
        placement={"top-start"}
        referenceElement={referenceElement}
        visible={tooltipVisible}
      >
        <CaptionTooltip
          onGenerate={handleGenerate}
          onClose={() => handleClose()}
        />
      </Popper>
    </EntityWrapper>
  );
};

export default ReplaceableCaption;

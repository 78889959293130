import {
  FEATURE_NOTIFICATION_ENQUEUE,
  FEATURE_NOTIFICATION_DEQUEUE,
  SET_ACTIVE_FEATURE_NOTIFICATION,
  RESET_ACTIVE_FEATURE_NOTIFICATION,
  GOTO_NEXT_STEP,
  GOTO_PREV_STEP
} from "./actions";
import { IFeatureNotification, IFeatureNotificationPayload } from "./reducer";

export interface IFeatureNotificationEnqueueAction {
  type: typeof FEATURE_NOTIFICATION_ENQUEUE;
  payload: IFeatureNotificationPayload;
}

export const featureNotificationEnqueueAction = (
  payload: IFeatureNotificationPayload
): IFeatureNotificationEnqueueAction => ({
  type: FEATURE_NOTIFICATION_ENQUEUE,
  payload
});

export interface IFeatureNotificationDequeueAction {
  type: typeof FEATURE_NOTIFICATION_DEQUEUE;
}

export const featureNotificationDequeueAction =
  (): IFeatureNotificationDequeueAction => ({
    type: FEATURE_NOTIFICATION_DEQUEUE
  });

export interface ISetActiveFeatureNotificationAction {
  type: typeof SET_ACTIVE_FEATURE_NOTIFICATION;
  payload: IFeatureNotification;
}

export const setActiveFeatureNotificationAction = (
  payload: IFeatureNotification
): ISetActiveFeatureNotificationAction => ({
  type: SET_ACTIVE_FEATURE_NOTIFICATION,
  payload
});

export interface IResetActiveFeatureNotificationAction {
  type: typeof RESET_ACTIVE_FEATURE_NOTIFICATION;
}

export const resetActiveFeatureNotificationAction =
  (): IResetActiveFeatureNotificationAction => ({
    type: RESET_ACTIVE_FEATURE_NOTIFICATION
  });

export interface IGotoNextStepAction {
  type: typeof GOTO_NEXT_STEP;
}

export const gotoNextStepAction = (): IGotoNextStepAction => ({
  type: GOTO_NEXT_STEP
});

export interface IGotoPrevStepAction {
  type: typeof GOTO_PREV_STEP;
}

export const gotoPrevStepAction = (): IGotoPrevStepAction => ({
  type: GOTO_PREV_STEP
});

export type IActions =
  | IFeatureNotificationEnqueueAction
  | IFeatureNotificationDequeueAction
  | ISetActiveFeatureNotificationAction
  | IResetActiveFeatureNotificationAction
  | IGotoNextStepAction
  | IGotoPrevStepAction;

import styled from "styled-components";

export const FeedAddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 20px;
  box-shadow:
    0 1px 0 0 rgba(0, 0, 0, 0.05),
    0 4px 8px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;

  > i {
    font-size: 20px;
    color: ${props => props.theme.colors.text02};
  }

  &:hover > i {
    color: ${props => props.theme.colors.text01};
  }
`;

import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { Box, Flex } from "rebass";
import IconButton from "../common/IconButton";
import { X } from "styled-icons/boxicons-regular";
import { Brick, Headline6, Paragraph } from "../common/styles";
import { topicLanguages } from "../../constants/content";
import Divider from "../common/Divider";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import { useEventListener } from "../../utils/useEventListener";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import useOutsideAlerter from "../../utils/useOutsideAlerter";

const SearchContainer = styled(Box)`
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 20px 1fr 36px;
  column-gap: 10px;
  max-width: 680px;
  flex: 1;
  height: 64px;
  margin: 0 32px;

  padding: 12px 0px;
`;

const SearchIcon = styled.span`
  color: ${props => props.theme.colors.text03};
  font-size: 18px;
  text-align: center;
  width: 20px;
`;

SearchIcon.defaultProps = {
  className: "icon-search"
};

const SearchInput = styled.input`
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: ${props => props.theme.colors.text01};
  font-family: ${props => props.theme.fonts.body};
  outline: none;
  font-size: 14px;
  line-height: 125%;
  font-weight: 600;
  width: 100%;
`;

const SearchOverlay = styled(Flex)`
  align-items: flex-start;
  /* background-color: ${props => props.theme.colors.backdrop}; */
  height: calc(100vh - 126px);
  justify-content: center;
  overflow: hidden;
  position: absolute;
  top: 56px;
  z-index: 2;
  width: 100%;
`;

const SearchSuggestions = styled(Brick)`
  box-shadow: ${props => props.theme.colors.shadowM};
  height: ${props => (props.isHidden ? "48px" : "max-content")};
  margin: 16px 0;
  max-width: 680px;
  opacity: ${props => (props.isHidden ? 0 : 1)};
  overflow: hidden;
  padding: 16px 8px;
  position: absolute;
  transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: translateY(${props => (props.isHidden ? -72 : 0)}px);
  width: 100%;
  z-index: 10;
`;

const SearchSuggestion = styled.button`
  align-items: center;
  appearance: none;
  background-color: white;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  column-gap: 12px;
  color: ${props => props.theme.colors.text02};
  cursor: pointer;
  display: grid;
  font-family: ${props => props.theme.fonts.body};
  font-size: 14px;
  grid-template-columns: 32px 1fr 20px;
  outline: none;
  padding: 8px 14px;
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.colors.blue};

    & * {
      color: white;
    }
  }
`;

const SearchSuggestionThumbnail = styled(Flex)`
  align-items: center;
  background-color: ${props => props.theme.colors.grey02};
  border-radius: 8px;
  height: 32px;
  justify-content: center;
  margin-right: 16px;
  overflow: hidden;
  width: 32px;

  & img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  & span {
    font-size: 20px;
  }
`;

const SearchSuggestionValue = styled(Paragraph)`
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 0;
  margin-right: 6px;
  margin-left: ${props => (props.variant === "keyword" ? 3 : 0)}px;
  text-align: left;

  ${props =>
    props.variant === "keyword" &&
    css`
      &:before,
      &:after {
        content: '"';
        opacity: 0.79;
        font-weight: 400;
      }
    `}
`;

const SearchSuggestionDescription = styled(SearchSuggestionValue)`
  color: ${props => props.theme.colors.text04};

  &::before {
    content: "·";
    margin-right: 6px;
  }
`;

export default function Search({
  placeholder,
  searchValue,
  suggestions = [],
  onChange,
  onRequestClose
}) {
  const account = useSelector(state => state.account.data);
  const history = useHistory();

  const suggestionRef = useRef(null);
  const searchBarRef = useRef(null);

  const storage = window.localStorage;
  const keywordHistory =
    storage.getItem("content_search_history")?.split(",") || [];

  const handleLinkClick = (type, item) => {
    let url = "";

    switch (type) {
      case "topics":
        url = `topics/${item.id}/${item.language}`;
        break;
      case "domains":
        url = `domains/${item.id}`;
        break;
      case "sources":
        if (item.type === "rss") {
          url = `rss/${item.id}/${item.language}`;
        } else if (item.type === "twitter") {
          url = `influencers/${item.id}`;
        }
        break;
      default:
        break;
    }

    history.push(`/accounts/${account.id}/content/${url}`);
    onRequestClose();
  };

  const handleKeywordClick = (historyValue = null) => {
    if (historyValue === null) {
      const storage = window.localStorage;
      const suggestions =
        storage.getItem("content_search_history")?.split(",") || [];
      if (!suggestions.includes(searchValue))
        storage.setItem(
          "content_search_history",
          [searchValue, ...suggestions].slice(0, 3).toString()
        );
    }
    history.push(
      `/accounts/${account.id}/content/search?query=${
        historyValue ?? searchValue
      }`
    );
    onRequestClose();
  };

  useOutsideAlerter([searchBarRef, suggestionRef], onRequestClose);

  useEventListener(window, "keydown", e => {
    // TODO: arrow navigation on search suggestions
    if (e.key === "Escape") onRequestClose();
  });

  return (
    <>
      <SearchContainer ref={searchBarRef}>
        <SearchIcon />
        <SearchInput
          type={"text"}
          onChange={onChange}
          placeholder={placeholder}
          autoFocus={true}
          onKeyPress={event => {
            if (event.key === "Enter") {
              handleKeywordClick();
            }
          }}
        />
        <IconButton icon={X} onClick={onRequestClose} />
      </SearchContainer>
      <SearchOverlay>
        <SearchSuggestions
          ref={suggestionRef}
          isHidden={searchValue === "" && keywordHistory.length === 0}
        >
          <Box px={16} py={1}>
            <Headline6>
              {searchValue !== "" ? "Keyword search" : "Search history"}
            </Headline6>
          </Box>
          <Box mt={2}>
            {searchValue !== "" && (
              <SearchSuggestion onClick={() => handleKeywordClick()}>
                <SearchSuggestionThumbnail>
                  <span className={"icon-search"} />
                </SearchSuggestionThumbnail>
                <Flex>
                  Search for
                  <SearchSuggestionValue variant={"keyword"}>
                    {searchValue}
                  </SearchSuggestionValue>
                </Flex>
              </SearchSuggestion>
            )}
            {keywordHistory.map(item => (
              <SearchSuggestion
                key={`history-${item}`}
                onClick={() => handleKeywordClick(item)}
              >
                <SearchSuggestionThumbnail>
                  <span className={"icon-search"} />
                </SearchSuggestionThumbnail>
                <Flex>{item}</Flex>
              </SearchSuggestion>
            ))}
          </Box>
          {suggestions.length > 0 && (
            <>
              <Divider my={3} />
              <Box px={16} py={1}>
                <Headline6>Quick links</Headline6>
              </Box>
              <Box mt={3} maxHeight={400} overflow={"scroll"}>
                {suggestions.map(({ type, detail }) => (
                  <SearchSuggestion
                    key={`${detail.id}-${detail.language ?? "no"}`}
                    onClick={() => handleLinkClick(type, detail)}
                  >
                    <SearchSuggestionThumbnail>
                      {detail.image && (
                        <img src={detail.image} alt={detail.title} />
                      )}
                    </SearchSuggestionThumbnail>
                    <Flex>
                      <SearchSuggestionValue>
                        {detail.title}
                      </SearchSuggestionValue>
                      {detail.language && (
                        <SearchSuggestionDescription>
                          {topicLanguages[detail.language]?.label ??
                            detail.language}
                        </SearchSuggestionDescription>
                      )}
                    </Flex>
                    <Box sx={{ transform: "rotate(-45deg)" }}>
                      <RightArrowAlt size={20} />
                    </Box>
                  </SearchSuggestion>
                ))}
              </Box>
            </>
          )}
        </SearchSuggestions>
      </SearchOverlay>
    </>
  );
}

import styled from "styled-components";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";

import {
  CALENDAR_VIEW,
  CALENDAR_VIEW_TYPES,
  KEY_LAST_CALENDAR_VIEW
} from "constants/calendar";
import { difference, today } from "utils/date";
import * as storageService from "utils/storage";
import Timeline from "components/schedule/timeline";
import Calendar from "components/schedule/calendar/Calendar";
import MonthComponent from "components/schedule/month/Month";
import { useNotificationActions } from "contextApi/notificationContext";
import { milliPopUpFeatureNotification } from "components/modals/pop-ups/featureNotifications";

const ScheduleContainer = styled.div`
  background-color: ${props => props.theme.colors.background1};
`;

const SchedulePage: React.FC<RouteComponentProps> = React.memo(({ match }) => {
  const addFeatureNotification = useNotificationActions(
    actions => actions.addFeatureNotification
  );
  const account = useSelector<any, any>(state => state.account.data);
  const preferredCalendarView = storageService.get(KEY_LAST_CALENDAR_VIEW);
  const defaultCalendarView = CALENDAR_VIEW_TYPES.includes(
    preferredCalendarView
  )
    ? preferredCalendarView
    : CALENDAR_VIEW.WEEK;

  useEffect(() => {
    window.scrollTo({ left: 0, top: 0 });
  }, []);

  useEffect(() => {
    if (
      !!account.id &&
      !account.billing?.willowCoachingPlan
        ?.toLowerCase()
        .includes("coaching") &&
      difference(today(), new Date(account.createdAt), "months") >= 1
    ) {
      addFeatureNotification(milliPopUpFeatureNotification);
    }
  }, [account.id]);

  return (
    <ScheduleContainer>
      <Switch>
        <Route
          path={match.url + `/${CALENDAR_VIEW.DAY}`}
          component={Timeline}
        />
        <Route
          path={match.url + `/${CALENDAR_VIEW.WEEK}`}
          component={Calendar}
        />
        <Route
          path={match.url + `/${CALENDAR_VIEW.MONTH}`}
          component={MonthComponent}
        />
        <Redirect to={match.url + `/${defaultCalendarView}`} />
      </Switch>
    </ScheduleContainer>
  );
});

export default SchedulePage;

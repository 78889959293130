import PropTypes from "prop-types";
import React, { useRef, useEffect } from "react";

/**
 * Hook that alerts clicks outside of the passed ref.
 */

function useOutsideClickDetector(ref, onClose, exceptions) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     * Except for nodes provided as exceptions
     */

    function handleClickOutside(event) {
      const skipClose = exceptions.some(ex => {
        let exception = ex;
        if (typeof ex === "string") {
          const element = document.getElementById(ex);
          exception = element;
        }

        return (
          exception &&
          (exception.contains(event.target) ||
            exception.isEqualNode(event.target))
        );
      });

      if (skipClose) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    }
    // Bind the event listener
    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref, onClose, exceptions]);
}

/**
 * Component that alerts if you click outside of it
 */

function OutsideClickDetector({ children, onClose, exceptions = [], ...rest }) {
  const wrapperRef = useRef(null);

  useOutsideClickDetector(wrapperRef, onClose, exceptions);

  return (
    <div ref={wrapperRef} {...rest}>
      {children}
    </div>
  );
}

OutsideClickDetector.propTypes = {
  children: PropTypes.element.isRequired,
  onClose: PropTypes.func
};

export default OutsideClickDetector;

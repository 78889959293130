import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useToaster } from "@hellocontento/maillard";
import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import { openModal } from "state/actions/ModalActions";
import { closeModal } from "state/actions/ModalActions";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import { channelConnectionHelpLinks } from "constants/index";
import FacebookPageList from "components/channelConnect/FacebookPageList";
import ChannelsConnectionModal from "components/modals/channelsConnectionModal";

const SelectFacebookPageModal = ({ pages, connectPages }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const addToast = useToaster();
  const account = useSelector<any, any>(state => state.account.data);

  let defaultSelectedPageIds = pages.map(page => page.id);
  if (pages.length > 5) {
    defaultSelectedPageIds = [];
  }
  const [selectedPageIds, setSelectedPageIds] = useState(
    defaultSelectedPageIds
  );

  const handleCloseModal = useCallback(() => {
    dispatch(closeModal());
    history.push(`/accounts/${account.id}/settings/channels`);
  }, []);

  const handleAddPage = useCallback(() => {
    const selectedPages = pages.filter(page =>
      selectedPageIds.includes(page.id)
    );
    if (selectedPages.length === 0) {
      return addToast("Please select one page at least.", "error");
    }
    connectPages(selectedPages);

    if (
      !account.onboardingProgress.includes(OnboardingStepsEnum.CONNECT_CHANNEL)
    ) {
      dispatch(
        openModal("ONBOARDING_INFO_MODAL", {
          id: OnboardingStepsEnum.CONNECT_CHANNEL,
          triggeredBy: OnboardingStepsEnum.CONNECT_CHANNEL,
          onCloseCallback: handleCloseModal
        })
      );
    }

    handleCloseModal();
  }, [
    pages,
    addToast,
    dispatch,
    openModal,
    connectPages,
    selectedPageIds,
    handleCloseModal,
    account.onboardingProgress
  ]);

  return (
    <ChannelsConnectionModal
      headerIcon="facebook"
      headerTitle="Connect a Facebook Account"
      description="Select the accounts you’d like to add from the box below and then click connect."
      onClose={handleCloseModal}
      cancelButtonLabel={pages.length < 1 ? "I’ll do this later" : "Cancel"}
      successButtonLabel="Connect"
      onSuccess={handleAddPage}
      helpLinks={_.values(
        _.pick(channelConnectionHelpLinks, [
          "cannotSeeChannel",
          "channelConnectionError"
        ])
      )}
    >
      <FacebookPageList
        pages={pages}
        selectedPageIds={selectedPageIds}
        setSelectedPageIds={setSelectedPageIds}
      />
    </ChannelsConnectionModal>
  );
};

export default SelectFacebookPageModal;

import { Box } from "rebass";
import React, { useRef, useState } from "react";
import { ChevronDown } from "styled-icons/boxicons-regular";

import {
  StatusDropdown,
  DropdownContainer,
  DropdownToggleButton
} from "./styles";
import Menu from "./Menu";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import { Status } from "./constants";
import StatusBadge from "./StatusBadge";
import Popper from "components/common/Popper";

const StatusSelector: React.FC<{}> = () => {
  const ref = useRef(null);
  const inDraftMode = useComposerState(state => state.inDraftMode);
  const setInDraftMode = useComposerActions(actions => actions.setInDraftMode);
  const [showOptions, setShowOptions] = useState(false);

  const toggleShowOptions = () => setShowOptions(!showOptions);

  const onOptionSelected = (postStatus: Status) => {
    setInDraftMode(postStatus === Status.DRAFT);
    setShowOptions(false);
  };

  const status = inDraftMode ? Status.DRAFT : Status.APPROVED;

  return (
    <StatusDropdown>
      <Box>
        <div ref={ref}>
          {/*  @ts-ignore */}
          <DropdownContainer onClick={toggleShowOptions}>
            <StatusBadge status={status} />
            <DropdownToggleButton
              // @ts-ignore
              className="chevron"
              size={28}
              padding={2}
              icon={ChevronDown}
            />
          </DropdownContainer>
          {/*  @ts-ignore */}
          <Popper
            placement="bottom-start"
            referenceElement={ref.current}
            visible={showOptions}
            onClose={() => setShowOptions(false)}
            exceptions={[ref.current]}
          >
            <Menu status={status} onStatusSelected={onOptionSelected} />
          </Popper>
        </div>
      </Box>
    </StatusDropdown>
  );
};

export default StatusSelector;

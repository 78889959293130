import decorateComponentWithProps from "decorate-component-with-props";

import ReplaceableCaption from "./components/ReplaceableCaption";

import CaptionHandler from "./CaptionHandler";
import findCaptionEntities from "./findCaptionEntities";

function createGeneratorPlugin({ captions, setWasCaptionUsed }) {
  const store = {
    getEditorState: undefined,
    setEditorState: undefined,
    getEditorRef: undefined
  };

  const captionHandler = new CaptionHandler(store, captions, setWasCaptionUsed);

  return {
    initialize: ({ getEditorState, setEditorState, getEditorRef }) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
      store.getEditorRef = getEditorRef;
    },
    decorators: [
      {
        strategy: findCaptionEntities,
        component: decorateComponentWithProps(ReplaceableCaption, {
          captionHandler
        })
      }
    ],
    captionHandler: captionHandler
  };
}

export default createGeneratorPlugin;

import qs from "qs";
import ReactLoading from "react-loading";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getChannelConnectedRedirectPath } from "utils/channels";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

const ConnectProfile: React.FC<{}> = React.memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const account = useSelector<any, any>(state => state.account.data);
  const queryParams = qs.parse(history.location.search, {
    ignoreQueryPrefix: true
  });

  useEffect(() => {
    const pageLocation = history.location.pathname.includes("onboarding")
      ? "onboarding"
      : "settings";

    dispatch(
      trackAnalyticsEvent("Channel Connected", {
        channelType: queryParams.channelType,
        location: pageLocation
      })
    );

    history.push(
      getChannelConnectedRedirectPath(account, queryParams.redirect)
    );
  }, [
    history,
    account,
    dispatch,
    queryParams.channelType,
    queryParams.redirect
  ]);

  //   @ts-ignore
  return <ReactLoading color={"#bbb"} type={"cylon"} />;
});

export default ConnectProfile;

import React from "react";

import MonthPost from "./MonthPost";
import MonthTask from "./MonthTask";
import MonthTopical from "./MonthTopical";
import { MonthGroup as Group } from "./styles";
import { POST_STATUS } from "../constants/postStatus";
import { getPostsGrouped } from "../utils/entryUtils";
import { ACTIVITY_TYPE } from "../constants/activityTypes";
import MonthGroupedSentPosts from "./MonthGroupedSentPosts";
import DraggableWrapper from "../common/drag-and-drop/DraggableWrapper";

function MonthGroup({ entries, day }) {
  const sorted = [
    ...entries.topical,
    ...entries.slot,
    ...entries.scheduled,
    ...entries.drafted,
    ...entries.grouped,
    ...entries.posted
  ].sort((a, b) =>
    b.type === ACTIVITY_TYPE.TOPICAL_CALENDAR
      ? 1
      : new Date(a.time) - new Date(b.time)
  );

  const grouped = getPostsGrouped(sorted);

  return (
    <Group>
      {grouped.map((item, index) =>
        item.length === 0 ? null : item.length === 1 ? (
          [
            ACTIVITY_TYPE.POST,
            ACTIVITY_TYPE.DRAFT_POST,
            ACTIVITY_TYPE.POST_GROUP
          ].includes(item[0].type) ? (
            <DraggableWrapper
              key={item[0].id}
              id={`${item[0].id}`}
              index={index}
              isDisabled={item[0].post?.status === POST_STATUS.SENT}
            >
              <MonthPost
                day={day}
                key={item[0].id}
                entry={
                  item[0].type === ACTIVITY_TYPE.POST
                    ? item[0]
                    : item[0].type === ACTIVITY_TYPE.POST_GROUP
                      ? {
                          ...item[0],
                          post: { ...item[0].post, isPostGroup: true }
                        }
                      : {
                          ...item[0],
                          draft: { ...item[0].draft, isDraft: true }
                        }
                }
              />
            </DraggableWrapper>
          ) : (
            <DraggableWrapper
              key={item[0].id}
              id={item[0].id}
              index={index}
              isDisabled={
                item[0].type === ACTIVITY_TYPE.TOPICAL_CALENDAR ||
                item[0].preview
              }
            >
              {item[0].type === ACTIVITY_TYPE.TOPICAL_CALENDAR ? (
                <MonthTopical
                  index={index}
                  day={day}
                  key={item[0].id}
                  entry={item[0]}
                />
              ) : (
                <MonthTask
                  index={index}
                  day={day}
                  key={item[0].id}
                  entry={item[0]}
                />
              )}
            </DraggableWrapper>
          )
        ) : (
          <MonthGroupedSentPosts key={item[0].id} posts={item} day={day} />
        )
      )}
    </Group>
  );
}

export default MonthGroup;

import React from "react";
import styled from "styled-components";
import { Box } from "rebass";

import { Star } from "styled-icons/boxicons-solid";

const StyledRatingGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 12px);
`;

const RatingStar = styled(Star)`
  color: ${props =>
    props.isActive ? props.theme.colors.yellow : props.theme.colors.text02};
  opacity: ${props => (props.isActive ? 1 : 0.33)};
`;

const Rating = ({ rating }) => (
  <StyledRatingGrid>
    {[1, 2, 3, 4].map(idx => (
      <RatingStar
        key={idx}
        isActive={Math.round(rating * 4) >= idx}
        size={12}
      />
    ))}
  </StyledRatingGrid>
);

export default Rating;

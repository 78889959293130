import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect, useCallback } from "react";

import { TooltipInfo } from "./styles";
import Popper from "components/common/Popper";
import DraftPanel from "components/draft/DraftPanel";
import IconButton from "components/common/IconButton";
import { useContentoApi } from "utils/useContentoApi";
import { saveDraftCount } from "state/actions/DraftActions";
import { useComposerState } from "contextApi/composerContext";
import { useAppActions, useAppState } from "contextApi/appContext";
import { DraftMenuWrapper, DraftsIndicatorWrapper } from "./styles";

export const DraftPost = React.memo(() => {
  const dispatch = useDispatch();
  const account = useSelector(state => state.account.data);
  const count = useSelector(state => state.draft.count);
  const isDraftPanelOpen = useAppState(state => state.isDraftPanelOpen);
  const setIsDraftPanelOpen = useAppActions(state => state.setIsDraftPanelOpen);

  const [offset, setOffset] = useState([104, 12]);
  const [referenceElement, setReferenceElement] = useState(null);
  const onPostedEvent = useComposerState(state => state.events.onPostedEvent);
  const onReloadEvent = useComposerState(state => state.events.onReloadEvent);
  const windowSize = useAppState(state => state.windowSize);

  const toggleShowOptions = useCallback(
    () => setIsDraftPanelOpen(!isDraftPanelOpen),
    [isDraftPanelOpen]
  );

  const [fetchDraftCount] = useContentoApi(
    `accounts/${account.id}/draft-posts?count=true`
  );

  const updateDraftCount = useCallback(() => {
    fetchDraftCount()
      .then(response => {
        dispatch(saveDraftCount(response));
      })
      .catch(e => {
        console.log(e, " draft count");
      });
  }, [fetchDraftCount, dispatch]);

  useEffect(() => {
    updateDraftCount();
  }, [updateDraftCount]);

  useEffect(() => {
    if (windowSize === "xsmall") {
      setOffset([104, 8]);
    } else {
      setOffset([104, 12]);
    }
  }, [windowSize]);

  const closeDraftPanel = useCallback(() => {
    setIsDraftPanelOpen(false);
  }, []);

  useEffect(
    () => onPostedEvent.listen(updateDraftCount),
    [onPostedEvent, updateDraftCount]
  );

  useEffect(
    () => onReloadEvent.listen(updateDraftCount),
    [onReloadEvent, updateDraftCount]
  );

  return (
    <DraftMenuWrapper ref={setReferenceElement} isOpen={isDraftPanelOpen}>
      <>
        <IconButton
          size={40}
          iconSize={24}
          icon="icon-draft"
          variant="primary"
          data-tip="Drafts"
          data-for="draft-button"
          onClick={toggleShowOptions}
        />
        {count > 0 && <DraftsIndicatorWrapper>{count}</DraftsIndicatorWrapper>}
      </>
      <TooltipInfo id="draft-button" effect={"solid"} place={"bottom"} />
      <Popper
        placement={"bottom-end"}
        offset={offset}
        strategy="fixed"
        referenceElement={referenceElement}
        visible={isDraftPanelOpen}
        exceptions={[referenceElement]}
        zIndex={9}
      >
        <DraftPanel
          onClose={closeDraftPanel}
          draftCount={count}
          fetchCount={updateDraftCount}
        />
      </Popper>
    </DraftMenuWrapper>
  );
});

export default DraftPost;

import React, { useState } from "react";

import ContentList from "../ContentList";
import { Headline2 } from "components/common/styles";

interface ISuggestedContentProps {
  contents: Array<any>;
  refreshContentFeed: () => void;
}

const SuggestedContents: React.FC<ISuggestedContentProps> = ({
  contents,
  refreshContentFeed
}) => {
  const [localContents, setLocalContents] = useState(contents);

  const onDismiss = (contentId: string, domain: string | null = null) => {
    if (domain) {
      setLocalContents(localContents.filter(item => item.domain !== domain));
    } else {
      setLocalContents(localContents.filter(item => item.id !== contentId));
    }
  };

  return (
    <div>
      <Headline2>Recommended for you</Headline2>
      {/* @ts-ignore */}
      <ContentList
        items={localContents}
        onScheduled={refreshContentFeed}
        onDismiss={onDismiss}
      />
    </div>
  );
};

export default SuggestedContents;

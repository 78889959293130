import React from "react";
import { useSelector } from "react-redux";
import ExploreTopic from "../widgets/ExploreTopic";
import { Box } from "rebass";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Headline2 } from "../../common/styles";

const ExploreTopicGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 24px;
`;

export default function FollowingTopics({ followingTopics }) {
  const account = useSelector(state => state.account.data);
  const history = useHistory();

  return (
    <Box my={64}>
      <Headline2>Following Topics</Headline2>
      <ExploreTopicGrid>
        {followingTopics.map(topic => (
          <ExploreTopic
            data={topic}
            key={topic.id + topic.language}
            onClick={() =>
              history.push(
                `/accounts/${account.id}/content/topics/${topic.id}/${topic.language}`
              )
            }
          />
        ))}
      </ExploreTopicGrid>
    </Box>
  );
}

import styled from "styled-components";
import { Flex, Box } from "rebass/styled-components";

export const OnboardingLayout = styled(Flex)`
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
`;

export const OnboardingContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  margin: ${props => props.marginTop ?? "8vh"} 16px 0;
  max-width: ${props => (props.size === "lg" ? "760px" : "576px")};
  min-width: max-content;
  width: 100%;
`;

export const OnboardingHeader = styled(Flex)`
  margin: 16px 0;
`;

export const StepIndicator = styled(Box)`
  background-color: ${props => props.theme.colors.green};
  border-radius: 16px;
  color: white;
  display: inline-block;
  font-size: 14px;
  letter-spacing: -0.16px;
  line-height: 16px;
  margin-top: calc(-32px - 16px);
  position: absolute;
  padding: 8px 16px;
  width: auto;
`;

import { useToaster } from "@hellocontento/maillard";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  upvoteCalendar,
  downvoteCalendar,
  fetchSubscriptions,
  fetchTopicalCalendars
} from "services/topicalCalendars";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

export interface ITopicalCalendarEvent {
  id: string;
  calendarId: string;
  name: string;
  startsAt: Date;
  endsAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface ITopicalCalendar {
  id: string;
  name: string;
  level: string;
  description: string;
  category: string;
  image: string;
  createdBy: string;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  startDate: Date;
  endDate: Date;
  calendarsEvents: ITopicalCalendarEvent[];
}

export interface IVote {
  id: string;
  calendarId: string;
}

export interface ISubscription {
  id: string;
  calendarId: string;
}

const useTopicalCalendars = () => {
  const addToast = useToaster();
  const reduxDispatch = useDispatch();
  const account = useSelector<any, any>(state => state.account.data);
  const [isFetchingCalendars, setIsFetchingCalendars] = useState<boolean>(true);
  const [isFetchingCalendarSubscriptions, setIsFetchingCalendarSubscriptions] =
    useState<boolean>(true);
  const [topicalCalendars, setTopicalCalendars] = useState<ITopicalCalendar[]>(
    []
  );
  const [votes, setVotes] = useState<IVote[]>([]);
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);

  const getTopicalCalendars = useCallback(async () => {
    if (!!account?.id) {
      try {
        setIsFetchingCalendars(true);
        const topicalCalendars = await fetchTopicalCalendars();

        setTopicalCalendars(topicalCalendars);
      } catch (error) {
        addToast((error as Error).message, "error");
      } finally {
        setIsFetchingCalendars(false);
      }
    }
  }, [addToast, account]);

  const getCalendarSubscriptions = useCallback(async () => {
    if (!!account?.id) {
      try {
        setIsFetchingCalendarSubscriptions(true);
        const { votes, subscriptions } = await fetchSubscriptions();

        setVotes(votes);
        setSubscriptions(subscriptions);
      } catch (error) {
        addToast((error as Error).message, "error");
      } finally {
        setIsFetchingCalendarSubscriptions(false);
      }
    }
  }, [addToast, account]);

  useEffect(() => {
    getTopicalCalendars();
  }, [getTopicalCalendars]);

  useEffect(() => {
    getCalendarSubscriptions();
  }, [getCalendarSubscriptions]);

  const updateVote = useCallback(
    (topicalCalendarId: string, newVote?: IVote) => {
      setVotes(votes => [
        ...votes.filter(vote => vote.calendarId !== topicalCalendarId),
        ...(!!newVote ? [newVote] : [])
      ]);
    },
    []
  );

  const updateSubscription = useCallback(
    (topicalCalendarId: string, newSubscription?: ISubscription) => {
      setSubscriptions(subscriptions => [
        ...subscriptions.filter(
          subscription => subscription.calendarId !== topicalCalendarId
        ),
        ...(!!newSubscription ? [newSubscription] : [])
      ]);
    },
    []
  );

  const handleButtonAction = useCallback(
    async (
      topicalCalendarId: string,
      calendarName: string,
      level: string,
      upvote: boolean,
      location: string
    ) => {
      try {
        if (upvote) {
          const { vote, subscription } =
            await upvoteCalendar(topicalCalendarId);

          updateVote(topicalCalendarId, vote);
          updateSubscription(topicalCalendarId, subscription);

          if (level === "voting") {
            reduxDispatch(
              trackAnalyticsEvent("Calendar Voted", {
                calendarName
              })
            );
          } else {
            reduxDispatch(
              trackAnalyticsEvent("Calendar Subscribed", {
                location,
                calendarName
              })
            );
          }
        } else {
          await downvoteCalendar(topicalCalendarId);

          updateVote(topicalCalendarId);
          updateSubscription(topicalCalendarId);
          reduxDispatch(
            trackAnalyticsEvent("Calendar Unsubscribed", {
              calendarName
            })
          );
        }
      } catch (error) {
        throw error;
      }
    },
    [updateVote, updateSubscription, reduxDispatch]
  );

  const votedCalendars = useMemo(
    () => votes.map(cal => cal.calendarId),
    [votes]
  );

  const subscribedCalendars = useMemo(
    () => subscriptions.map(cal => cal.calendarId),
    [subscriptions]
  );

  return useMemo(
    () => ({
      votes,
      subscriptions,
      votedCalendars,
      topicalCalendars,
      handleButtonAction,
      isFetchingCalendars,
      subscribedCalendars,
      isFetchingCalendarSubscriptions
    }),
    [
      votes,
      subscriptions,
      votedCalendars,
      topicalCalendars,
      handleButtonAction,
      isFetchingCalendars,
      subscribedCalendars,
      isFetchingCalendarSubscriptions
    ]
  );
};

export default useTopicalCalendars;

import React from "react";
import { Flex } from "rebass";

import styled from "styled-components";

const Button = styled.button`
  border-radius: 4px;
  padding: 8px;
  color: ${props => props.theme.colors.text02};
  font-weight: 600;
  border: none;
  outline: none;
  margin: 0;
  background: transparent;

  :hover {
    background-color: ${props => props.theme.colors.grey01};
    color: ${props => props.theme.colors.text01};
  }
`;

const ButtonContent = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

const OrButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <ButtonContent>
        <span className="icon-plus" />
        OR
      </ButtonContent>
    </Button>
  );
};

export default OrButton;

import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { Headline2, Paragraph, IconButton } from "../common/styles";

export const ModalContainer = styled(Box)`
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

export const ModalHeader = styled(Flex)`
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 8px;
  align-items: center;
  height: 56px;
`;

export const ModalTitleWrapper = styled(Flex)`
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ModalDescription = styled.span`
  color: ${props => props.theme.colors.text02};
  font-weight: 400;
  font-size: 14px;
  padding-right: 34px;
  line-height: 140%;
`;

export const ModalBorderHeader = styled(Flex)`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey02};
  color: #000000;
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  letter-spacing: 0.3px;
  margin: -8px -20px 0;
  padding: 0 20px 12px;
`;

export const ModalCloseIconWrapper = styled(IconButton)`
  :hover {
    background-color: #f1f2f3;
  }
`;

export const ModalEmojiContainer = styled(Flex)`
  justify-content: center;
  margin-top: -40px;
`;

export const ModalEmoji = styled(Flex)`
  align-items: center;
  background-color: #f1f1f1;
  box-shadow: 0 0 0 4px white;
  border-radius: 40px;
  height: 80px;
  justify-content: center;
  width: 80px;
  object-fit: cover;
  overflow: hidden;

  & span {
    font-size: 35px;
    text-align: center;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ModalTitle = styled(Headline2)`
  color: #111111;
  font-size: 28px;
  line-height: 35.2px;
  text-align: center;
  max-width: 560px;
`;

export const ModalSubtitle = styled(Paragraph)`
  color: ${props => props.theme.colors.text02};
  font-size: 16px;
  line-height: 24px;
  max-width: 560px;
  text-align: center;
`;

export const ModalBody = styled(Paragraph)`
  color: ${props => props.theme.colors.text01};
  font-size: 16px;
  line-height: 24px;
  max-width: 480px;
  text-align: center;
  margin-bottom: 16px;
`;

export const ModalOverflowContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  justify-content: stretch;
  padding: 32px;
  max-height: calc(100vh - 104px);
  overflow-y: auto;
`;

export const sidePanelStyles = () => {
  return {
    // dark background behind all modals
    overlay: {
      background: "rgba(0,0,0,0.25)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      //overflowY: 'visible',
      overflowX: "hidden",
      zIndex: 10,
      padding: 0
    },
    // modal root
    content: {
      overflow: "auto",
      height: "100vh",
      background: "#F7F8F9",
      backgroundClip: "padding-box",
      borderRadius: "none",
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      border: "1px",
      borderColor: "#EAEBEB",
      minWidth: `60vw`,
      top: "0",
      bottom: "0",
      left: "auto",
      right: "0",
      backgroundColor: "white",
      boxShadow: "0 20px 40px 0 rgba(0, 0, 0, 0.08)",
      padding: 0
    }
  };
};

export const modalStyles = (maxWidth = 550, showFooter = false) => {
  return {
    // dark background behind all modals
    overlay: {
      background: "rgba(0,0,0,0.25)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      //overflowY: 'visible',
      overflowX: "hidden",
      zIndex: 10,
      padding: "15px 20px 15px 20px"
    },
    // modal root
    content: {
      overflow: "auto",
      maxHeight: "95vh",
      boxSizing: "border-box",
      background: "#F7F8F9",
      backgroundClip: "padding-box",
      borderRadius: "16px",
      position: "relative",
      border: "1px",
      borderColor: "#EAEBEB",
      width: "100%",
      maxWidth: `${maxWidth}px`,
      top: "auto",
      bottom: "auto",
      left: "auto",
      right: "auto",
      backgroundColor: "white",
      boxShadow:
        "0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)",
      padding: showFooter ? "20px 20px 0" : "24px"
    }
  };
};

export const emojiModalStyles = () => {
  const styles = modalStyles();
  return {
    overlay: {
      ...styles.overlay,
      backgroundColor: "rgba(0, 0, 0, 0.4)"
    },
    content: {
      ...styles.content,
      borderRadius: 24,
      maxWidth: 744,
      padding: 0,
      overflow: "visible"
    }
  };
};

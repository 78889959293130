import React from "react";
import Modal from "react-modal";
import {
  ModalEmojiContainer,
  ModalOverflowContainer,
  ModalEmoji,
  emojiModalStyles
} from "./styles";

export const EmojiModal = ({
  onClose,
  children,
  emoji = "👋🏻",
  avatar = null
}) => {
  const styles = emojiModalStyles();

  return (
    <Modal
      ariaHideApp={false}
      isOpen={true}
      style={styles}
      onRequestClose={onClose}
    >
      <ModalEmojiContainer bgImg={avatar}>
        <ModalEmoji>
          {!avatar ? (
            <span aria-hidden="true" role="img">
              {emoji}
            </span>
          ) : (
            <img src={avatar} alt="Avatar" />
          )}
        </ModalEmoji>
      </ModalEmojiContainer>
      <ModalOverflowContainer>{children}</ModalOverflowContainer>
    </Modal>
  );
};

import React from "react";
import Modal from "react-modal";

import { Video } from "./style";

export const modalStyles = (maxWidth = 550) => {
  return {
    // dark background behind all modals
    overlay: {
      background: "rgba(0,0,0,0.70)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      //overflowY: 'visible',
      overflowX: "hidden",
      zIndex: 10
    },
    // modal root
    content: {
      background: "transparent",
      overflow: "auto",
      height: "66vh",
      borderColor: "transparent",
      boxSizing: "border-box",
      backgroundClip: "padding-box",
      position: "relative",
      width: "100%",
      maxWidth: `${maxWidth}px`,
      top: "auto",
      bottom: "auto",
      left: "auto",
      right: "auto",
      padding: 0
    }
  };
};

const VideoPlayerModal: React.FC<{
  videoUrl: string;
  isOpen: boolean;
  onClose: () => void;
}> = ({ videoUrl, isOpen, onClose }) => {
  const styles = modalStyles(800);

  return (
    <Modal
      name={"video-player"}
      ariaHideApp={false}
      isOpen={isOpen}
      // @ts-ignore
      style={styles}
      onRequestClose={onClose}
    >
      <Video
        title="Youtube player"
        sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
        src={videoUrl}
      ></Video>
    </Modal>
  );
};

export default VideoPlayerModal;

// @ts-nocheck
import { nanoid } from "nanoid";
import { KEYWORD_CATEGORY } from "../constants/category";
import { ConjunctionData, IKeywordAlertState } from "../constants/types";

export const composeFeedObject = (keywordAlert: Array<IKeywordAlertState>) => {
  return keywordAlert.reduce((acc: any, alert: IKeywordAlertState) => {
    let copyAcc = { ...acc };

    if (!copyAcc[alert.category.value]) copyAcc[alert.category.value] = {};

    if (alert.conjunction === ConjunctionData.AND) {
      copyAcc[alert.category.value].include = copyAcc[alert.category.value]
        .include
        ? [
            ...copyAcc[alert.category.value].include,
            { values: [...alert.keywords.map(x => x.keyword)] }
          ]
        : [{ values: [...alert.keywords.map(x => x.keyword)] }];
    } else {
      copyAcc[alert.category.value].exclude = copyAcc[alert.category.value]
        .exclude
        ? [
            ...copyAcc[alert.category.value].exclude,
            ...alert.keywords.map(x => x.keyword)
          ]
        : [...alert.keywords.map(x => x.keyword)];
    }
    return copyAcc;
  }, {});
};

export const makeStateFromFeed = (data: IKeywordData) => {
  const { rules } = _.omit({ ...data }, ["id", "name"]);

  const state = Object.keys(rules).reduce((acc, value) => {
    if (!!rules[value].include) {
      rules[value].include.forEach(item => {
        const keyword = {
          id: nanoid(),
          keywords: item.values.map((val: string) => ({
            id: nanoid(),
            keyword: val
          })) as Array<{ id: string; keyword: string }>,
          category:
            KEYWORD_CATEGORY.find(x => x.value === value) ||
            KEYWORD_CATEGORY[0],
          conjunction: ConjunctionData.AND
        };
        acc.push(keyword);
      });
    }

    if (!!rules[value].exclude) {
      const keyword = {
        id: nanoid(),
        keywords: rules[value].exclude.map((val: string) => ({
          id: nanoid(),
          keyword: val
        })) as Array<{ id: string; keyword: string }>,
        category:
          KEYWORD_CATEGORY.find(x => x.value === value) || KEYWORD_CATEGORY[0],
        conjunction: ConjunctionData.NOT
      };
      acc.push(keyword);
    }

    return acc;
  }, [] as Array<IKeywordAlertState>);

  return state;
};

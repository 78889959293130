import { Box, Flex } from "rebass";
import styled from "styled-components";

export const EntryArticle = styled(Box)`
  border-radius: 8px;
  background-color: rgb(243, 244, 245);
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;

  &:hover {
    box-shadow: ${props => props.theme.shadows.shadowXS};
  }
`;

export const ThumbnailWrapper = styled.div`
  position: relative;
`;

interface IEntryThumbnail {
  imgSrc: string;
}

export const EntryThumbnail = styled(Box)<IEntryThumbnail>`
  border-radius: 8px 8px 0 0;
  background-color: ${props => props.theme.colors.grey01};
  background-size: cover;
  background-image: url("${props => props.imgSrc}");
  display: inline-block;
  width: 100%;
  height: 55vw;
  max-height: 250px;
  background-position: center;
`;

export const EntryArticleText = styled(Box)`
  padding: 6px 8px;
  flex: 0;
  box-sizing: border-box;

  & p {
    color: ${props => props.theme.colors.text01};
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    margin: 0;
    /* height: 15px; */
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    ${EntryArticle}:hover & {
      text-decoration: underline;
    }
  }
`;

export const EntryArticleSource = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0px;
  gap: 6px;
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.text03};
  span {
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const EntryArticleSourceIcon = styled.img`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
`;

export const ClickStats = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 24px;
  bottom: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  color: ${props => props.theme.colors.text02};
  font-size: 14px;
  padding: 8px 16px;
  position: absolute;
  right: 16px;
  z-index: 1;

  span {
    font-weight: 700;
    margin-left: 4px;
  }
`;

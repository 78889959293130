import React from "react";

import { theme } from "theme";
import Button from "components/common/Button";
import { SeeMoreWrapper, SeeMoreContent } from "./styles";

interface ISeeMore extends React.HTMLProps<HTMLDivElement> {
  text?: string;
  iconClass?: string;
  minWidth?: number;
  handleClick: VoidFunction;
  variant?: keyof (typeof theme)["variants"];
}

const SeeMore: React.FC<ISeeMore> = ({
  text = "See more",
  iconClass = "icon-list-filled",
  variant = "secondary",
  handleClick,
  ...rest
}) => {
  return (
    <SeeMoreWrapper>
      {/* @ts-ignore */}
      <Button variant="secondary" onClick={handleClick} {...rest}>
        <SeeMoreContent>
          <span className={iconClass} />
          {text}
        </SeeMoreContent>
      </Button>
    </SeeMoreWrapper>
  );
};

export default SeeMore;

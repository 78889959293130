import { IArticleAttachment, IAttachment, IPdfAttachment, IPhotoAttachment, IVideoAttachment } from "@hellocontento/contento-common";
import React from "react";
import PostAttachmentImage from "../common/postAttachmentImage";
import { ATTACHMENT_TYPES } from "constants/post";
import PostAttachmentVideo from "../common/postAttachmentVideo";
import EmptyArticleImage from "assets/images/article/empty-article-image.png";
import EmptySourceImage from "assets/images/article/empty-source.png";
import {
  CalendarEntryArticle,
  CalendarEntryThumbnail,
  CalendarEntryArticleText,
  CalendarEntryArticleSource,
  CalendarEntryArticleSourceIcon
} from "./styles";

const CalendarPostAttachment = ({
  title,
  attachment,
  autoHeight,
  attachmentHeight,
  fullAttachmentHeight,
  videoLess,
  channels,
  picture
}: {
  title?: string;
  attachment: IAttachment;
  videoLess: boolean;
  autoHeight?: boolean;
  attachmentHeight?: number;
  fullAttachmentHeight?: boolean;
  channels?: any[];
  picture?: string;
}) => {
  let content: React.ReactNode;

  if (attachment.type === ATTACHMENT_TYPES.PHOTO && !title) {
    attachment = attachment as IPhotoAttachment;
    content = (
      <PostAttachmentImage
        attachment={attachment}
        maxHeight={
          autoHeight
            ? !attachment.metaData?.[0]?.height ||
              !attachment.metaData?.[0]?.width
              ? undefined
              : (attachment.metaData[0].height / attachment.metaData[0].width) *
                498
            : attachmentHeight
        }
        height={
          fullAttachmentHeight
            ? "100%"
            : attachmentHeight
              ? attachmentHeight / 2
              : undefined
        }
      />
    );
  }

  if (attachment.type === ATTACHMENT_TYPES.PDF && !title) {
    attachment = attachment as IPdfAttachment;
    content = (
      <PostAttachmentImage
        attachment={{
          url: (attachment as IPdfAttachment).metaData?.preview || ""
        }}
        maxHeight={
          autoHeight
            ? !attachment.metaData?.[0]?.height ||
              !attachment.metaData?.[0]?.width
              ? undefined
              : (attachment.metaData[0].height / attachment.metaData[0].width) *
                498
            : attachmentHeight
        }
        height="unset"
      />
    );
  }

  if (attachment.type === ATTACHMENT_TYPES.VIDEO) {
    attachment = attachment as IVideoAttachment;
    content = (
      <PostAttachmentVideo
        height={
          autoHeight ? undefined : (channels?.length ?? 0) > 3 ? 118 : 136
        }
        attachment={attachment}
        videoLess={videoLess}
      />
    );
  }

  if (title || attachment.type === ATTACHMENT_TYPES.ARTICLE) {
    attachment = attachment as IArticleAttachment;
    content = (
      <CalendarEntryArticle>
        <CalendarEntryThumbnail
          $imgSrc={picture || EmptyArticleImage}
        />
        <CalendarEntryArticleText>
          <p>{title || ""}</p>
          <CalendarEntryArticleSource>
            <CalendarEntryArticleSourceIcon
              src={
                (attachment as IArticleAttachment)?.favicon || EmptySourceImage
              }
              alt={(attachment as IArticleAttachment).domain}
              onError={e => {
                (e.target as any).onerror = null;
                (e.target as any).src = EmptySourceImage;
              }}
            />
            <span>{(attachment as IArticleAttachment).domain}</span>
          </CalendarEntryArticleSource>
        </CalendarEntryArticleText>
      </CalendarEntryArticle>
    );
  }
  return <>{content}</>;
};

export default CalendarPostAttachment;

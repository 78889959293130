import React from "react";

import RecommendedTopics from "./topics";
import RecommendedDomains from "./domains";
import RecommendedInfluencers from "./influencers";

interface IRecommendationsProps {
  hideSeeAll?: boolean;
}

const Recommendations: React.FC<IRecommendationsProps> = ({ hideSeeAll }) => {
  return (
    <>
      <RecommendedTopics hideSeeAll={hideSeeAll} />
      <RecommendedInfluencers hideSeeAll={hideSeeAll} />
      <RecommendedDomains hideSeeAll={hideSeeAll} />
    </>
  );
};

export default Recommendations;

import React, { useState, useEffect, useRef, PropsWithChildren } from "react";
import { TooltipContainer, TooltipTitle } from "./styles";

interface TooltipProps {
  title: string;
  width?: number | null;
  placement?: string;
  hidden?: boolean;
}

function Tooltip({
  title,
  children,
  width = null,
  placement = "top",
  hidden = false
}: PropsWithChildren<TooltipProps>) {
  const [isHovered, setIsHovered] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });

  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setOffset({
      x: (ref?.current?.clientWidth ?? 0) / 2,
      y: (ref?.current?.clientHeight ?? 0) / 2
    });
  }, [isHovered]);

  return (
    <TooltipContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setIsHovered(false)}
    >
      <TooltipTitle
        ref={ref}
        $placement={placement}
        style={{
          left: `calc(-${offset.x}px + 18px)`,
          top: `calc((-2*${offset.y}px - 6px) * ${
            placement === "bottom" ? "-1.5" : "1"
          })`
        }}
        $visible={!hidden && isHovered}
        //@ts-ignore
        width={width}
      >
        <span>{title}</span>
      </TooltipTitle>
      {children}
    </TooltipContainer>
  );
}

export default Tooltip;

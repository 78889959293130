import { IContent, ISearch } from "./reducer";
import { RESET_STATE, SET_CONTENT, SET_SEARCH } from "./actions";

export interface IResetStateAction {
  type: typeof RESET_STATE;
}

export const resetStateAction = (): IResetStateAction => ({
  type: RESET_STATE
});

export interface ISetContentAction {
  type: typeof SET_CONTENT;
  payload: Partial<IContent>;
}

export const setContentAction = (
  payload: Partial<IContent>
): ISetContentAction => ({
  type: SET_CONTENT,
  payload
});

export interface ISetSearchAction {
  type: typeof SET_SEARCH;
  payload: Partial<ISearch>;
}

export const setSearchAction = (
  payload: Partial<ISearch>
): ISetSearchAction => ({
  type: SET_SEARCH,
  payload
});

export type IActions = IResetStateAction | ISetContentAction | ISetSearchAction;

import { Box } from "rebass";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { ChevronDown } from "styled-icons/boxicons-regular";

import {
  StatusDropdown,
  DropdownContainer,
  DropdownToggleButton
} from "./styles";
import Menu from "./Menu";
import StatusBadge from "./StatusBadge";
import Popper from "components/common/Popper";

const StatusSelector = ({ status, onStatusSelected }) => {
  const ref = useRef(null);
  const [showOptions, setShowOptions] = useState(false);

  const toggleShowOptions = () => setShowOptions(!showOptions);

  const onOptionSelected = value => {
    onStatusSelected(value);
    setShowOptions(false);
  };

  return (
    <StatusDropdown>
      <Box>
        <div ref={ref}>
          <DropdownContainer onClick={toggleShowOptions}>
            <StatusBadge status={status} />
            <DropdownToggleButton
              className="chevron"
              size={28}
              padding={2}
              icon={ChevronDown}
            />
          </DropdownContainer>
          <Popper
            placement="bottom-start"
            referenceElement={ref.current}
            visible={showOptions}
            onClose={() => setShowOptions(false)}
            exceptions={[ref.current]}
          >
            <Menu status={status} onStatusSelected={onOptionSelected} />
          </Popper>
        </div>
      </Box>
    </StatusDropdown>
  );
};

StatusSelector.propTypes = {
  status: PropTypes.string.isRequired,
  onStatusSelected: PropTypes.func.isRequired
};

export default StatusSelector;

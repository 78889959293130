import styled from "styled-components";

export const AddSlackButton = styled.button`
  align-items: center;
  color: #000;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  display: inline-flex;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  text-decoration: none;
  width: 236px;
  cursor: pointer;
`;

export const DisconnectSlackButton = styled.button`
  display: block;
  padding: 8px;
  background-color: white;
  cursor: pointer;
  z-index: 10;
  margin-top: 10px;
  border: 0px;
  white-space: nowrap;
  color: red;
  align-items: center;
  & span {
    margin-right: 8px;
  }
`;

export const WhatsappWrapper = styled.form`
  display: block;
  min-height: 100% !important;
  height: 100%;
  flex-direction: column;
  width: 100%;
  max-width: 719px;
  border-radius: 8px;
`;

export const WhatsappForm = styled.div`
  display: block;
  background: #f7f9fa !important;
  min-height: 100% !important;
  height: 100%;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  border-radius: 8px;
  padding: 15px 10px 15px 10px;
`;

export const WhatsappSubGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  min-height: 15px !important;
  max-height: 55px !important;
  margin-top: 5px;

  p {
    margin-top: 5px;
  }
`;

export const WhatsappGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  margin-bottom: 10px;
  max-width: 719px;
  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    display: block;
  }
`;

export const WhatsappPhoneInputContainer = styled.div`
  width: 100%;
  .phone-input > .PhoneInputInput {
    height: 44px;
    border: none;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    border-radius: 8px;
    border-color: #d0d5dd;
    border-width: 2px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    padding-left: 10px;
    margin-left: 5px;
  }
  .phone-input > .PhoneInputCountrySelect {
    width: 50px;
  }
`;

export const WhatsappConnectedWrapper = styled.div`
  width: 100%;
  background: #f7f9fa !important;
  border-radius: 9px;
  border-width: 1px;
  border-color: #d0d5dd;
  .phone-number {
    position: relative;
    top: 13px;
  }
`;

export const WhatsappSubText = styled.p`
  color: ${props => props.color || "#808082 !important"};
  font-size: 14px !important;
  line-height: 1.2rem;
  max-width: 719px;
  margin-top: 10px;
  font-weight: 500;
  margin-bottom: 5px;
`;

export const SettingsSubTitle = styled.h3`
  color: black;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 6px;
`;

export const SettingsSubText = styled.p`
  color: #808082 !important;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5rem;
`;

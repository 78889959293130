import styled from "styled-components";

import { DEVICES, DEVICES_HEIGHT } from "theme";

export const DraftsWrapper = styled.div`
  background: white;
  box-shadow:
    inset 0 0 0 1px ${props => props.theme.colors.grey02},
    0px 30px 60px -30px rgba(0, 0, 0, 0.15),
    0px 50px 100px -20px rgba(0, 0, 0, 0.2),
    0px 0px 1px rgba(0, 0, 0, 0.08);
  transition: 0.25s height;
  width: 350px;
  position: relative;
  min-height: 100vh;

  @media screen and ${DEVICES.mobileL} {
    width: 100vw;
  }
`;

export const SubHeadline = styled.span`
  margin-left: 4px;
  color: ${props => props.theme.colors.text03};
`;

export const DraftFooterWrapper = styled.div`
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: calc(350px - 32px);
  padding: 16px 0;
  background: white;
  border-top: 1px solid ${props => props.theme.colors.grey02};
  left: 50%;
  bottom: 60px;
  transform: translateX(-50%);
  @media screen and ${DEVICES.mobileL} {
    width: calc(100% - 24px);
    padding: 12px 0;
  }
`;

export const DraftHeaderWrapper = styled.div`
  padding: 13px 24px;
  border-bottom: 1px solid rgba(96, 116, 128, 0.12);

  @media screen and ${DEVICES.mobileL} {
    padding: 7px 24px;
  }
`;

export const DraftHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DraftBodyWrapper = styled.div`
  /* min-height: 30vh */
  max-height: 70vh;
  padding: 18px 24px;
  overflow: hidden;
  overflow-y: scroll;

  @media screen and ${DEVICES_HEIGHT.tablet} {
    max-height: 76vh;
  }

  @media screen and ${DEVICES_HEIGHT.mobileM} {
    max-height: 70vh;
  }

  @media screen and ${DEVICES_HEIGHT.mobileS} {
    max-height: 68vh;
  }
  @media screen and ${DEVICES_HEIGHT.laptopM} {
    max-height: 72vh;
  }
`;

/**
 * This is a component extracted from draft-js-mention-plugin v3.1.5
 * for the purposes of modifying the content of the suggestion popup
 * which would otherwise only let Entry component to be modified but
 * not the whole component itself.
 * draft-js-mention-plugin version => 3.1.5
 */

import { genKey } from "draft-js";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Transition } from "react-transition-group";
import getSearchText from "draft-js-mention-plugin/lib/utils/getSearchText";
import defaultPositionSuggestions from "draft-js-mention-plugin/lib/utils/positionSuggestions";

import {
  TransitionView,
  ProfileListSwitch,
  EmptyResultsWrapper,
  MentionSuggestionList
} from "./styles";
import Popover from "./Popover";
import { addMention } from "../utils/addMention";
import { Headline6 } from "components/common/styles";
import IconButton from "components/common/IconButton";
import StackedAvatars from "components/common/StackedAvatars";
import MentionSuggestionEntry from "./MentionSuggestionEntry";
import getTriggerForMention from "../utils/getTriggerForMention";
import DropdownProfileEntryLoader from "components/common/loading/dropdownProfileEntry";

export class MentionSuggestions extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    onOpenChange: PropTypes.func.isRequired,
    entityMutability: PropTypes.oneOf(["SEGMENTED", "IMMUTABLE", "MUTABLE"]),
    entryComponent: PropTypes.func,
    onAddMention: PropTypes.func,
    suggestions: PropTypes.array.isRequired
  };

  state = {
    focusedOptionIndex: 0
  };

  key = genKey();
  popover;
  activeOffsetKey;
  lastSearchValue;
  lastActiveTrigger = "";
  lastSelectionIsInsideWord;

  constructor(props) {
    super(props);
    this.props.callbacks.onChange = this.onEditorStateChange;
  }

  componentDidUpdate() {
    if (this.popover) {
      // In case the list shrinks there should be still an option focused.
      // Note: this might run multiple times and deduct 1 until the condition is
      // not fullfilled anymore.
      const size = this.props.suggestions.length;
      if (size > 0 && this.state.focusedOptionIndex >= size) {
        this.setState({
          focusedOptionIndex: size - 1
        });
      }

      // Note: this is a simple protection for the error when componentDidUpdate tries
      // to get new getPortalClientRect, but the key already was deleted by previous action
      if (!this.props.store.getAllSearches().has(this.activeOffsetKey)) {
        return;
      }

      const decoratorRect = this.props.store.getPortalClientRect(
        this.activeOffsetKey
      );
      const positionSuggestions =
        this.props.positionSuggestions || defaultPositionSuggestions;
      const newStyles = positionSuggestions({
        decoratorRect,
        props: this.props,
        popover: this.popover
      });
      for (const [key, value] of Object.entries(newStyles)) {
        this.popover.style[key] = value;
      }
    }
  }

  componentWillUnmount() {
    this.props.callbacks.onChange = undefined;
  }

  onEditorStateChange = editorState => {
    const searches = this.props.store.getAllSearches();
    // if no search portal is active there is no need to show the popover
    if (searches.size === 0) {
      return editorState;
    }

    const removeList = () => {
      this.props.store.resetEscapedSearch();
      this.closeDropdown();
      return editorState;
    };

    const triggerForMention = getTriggerForMention(
      editorState,
      searches,
      this.props.mentionTriggers
    );

    if (!triggerForMention) {
      return removeList();
    }

    const lastActiveOffsetKey = this.activeOffsetKey;
    this.activeOffsetKey = triggerForMention.activeOffsetKey;

    // If none of the above triggered to close the window, it's safe to assume
    // the dropdown should be open. This is useful when a user focuses on another
    // input field and then comes back: the dropdown will show again.
    if (
      !this.props.open &&
      !this.props.store.isEscaped(this.activeOffsetKey || "")
    ) {
      this.openDropdown();
    }

    this.onSearchChange(
      editorState,
      editorState.getSelection(),
      this.activeOffsetKey,
      lastActiveOffsetKey,
      triggerForMention.activeTrigger
    );

    // make sure the escaped search is reseted in the cursor since the user
    // already switched to another mention search
    if (!this.props.store.isEscaped(this.activeOffsetKey || "")) {
      this.props.store.resetEscapedSearch();
    }

    // makes sure the focused index is reseted every time a new selection opens
    // or the selection was moved to another mention search
    if (lastActiveOffsetKey !== this.activeOffsetKey) {
      this.setState({
        focusedOptionIndex: 0
      });
    }

    return editorState;
  };

  onSearchChange = (
    editorState,
    selection,
    activeOffsetKey,
    lastActiveOffsetKey,
    trigger
  ) => {
    const { matchingString: searchValue } = getSearchText(
      editorState,
      selection,
      [trigger]
    );

    if (
      this.lastActiveTrigger !== trigger ||
      this.lastSearchValue !== searchValue ||
      activeOffsetKey !== lastActiveOffsetKey
    ) {
      this.lastActiveTrigger = trigger;
      this.lastSearchValue = searchValue;
      this.props.onSearchChange({ trigger, value: searchValue });
      //reset focus item if search is changed
      this.setState({
        focusedOptionIndex: 0
      });
    }
  };

  onDownArrow = keyboardEvent => {
    keyboardEvent.preventDefault();
    keyboardEvent.stopPropagation();

    const newIndex = this.state.focusedOptionIndex + 1;
    this.onMentionFocus(
      newIndex >= this.props.suggestions.length ? 0 : newIndex
    );
  };

  onTab = keyboardEvent => {
    keyboardEvent.preventDefault();
    keyboardEvent.stopPropagation();

    this.commitSelection();
  };

  onUpArrow = keyboardEvent => {
    keyboardEvent.preventDefault();
    keyboardEvent.stopPropagation();

    if (this.props.suggestions.length > 0) {
      const newIndex = this.state.focusedOptionIndex - 1;
      this.onMentionFocus(
        newIndex < 0 ? this.props.suggestions.length - 1 : newIndex
      );
    }
  };

  onEscape = keyboardEvent => {
    keyboardEvent.preventDefault();
    keyboardEvent.stopPropagation();

    this.props.store.escapeSearch(this.activeOffsetKey || "");
    this.props.setShowConnectedChannels(false);
    this.closeDropdown();

    // to force a re-render of the outer component to change the aria props
    this.props.store.setEditorState(this.props.store.getEditorState());
  };

  onMentionSelect = mention => {
    // Note: This can happen in case a user typed @xxx (invalid mention) and
    // then hit Enter. Then the mention will be undefined.
    if (!mention) {
      return;
    }

    if (this.props.onAddMention) {
      this.props.onAddMention(mention);
    }

    this.props.setShowConnectedChannels(false);
    this.closeDropdown();
    this.props.setSuggestedSocialMentions([]);
    const newEditorState = addMention(
      this.props.store.getEditorState(),
      mention,
      this.props.mentionPrefix,
      this.lastActiveTrigger || "",
      this.props.entityMutability,
      ["twitter", "instagram"].includes(mention.service)
    );
    this.props.store.setEditorState(newEditorState);
  };

  onMentionFocus = index => {
    const descendant = `mention-option-${this.key}-${index}`;
    this.props.ariaProps.ariaActiveDescendantID = descendant;
    this.setState({
      focusedOptionIndex: index
    });

    // to force a re-render of the outer component to change the aria props
    this.props.store.setEditorState(this.props.store.getEditorState());
  };

  commitSelection = () => {
    const suggested = this.props.suggestions.filter(
      item => !!item && !item.isChannelConnection
    );
    const mention = suggested[this.state.focusedOptionIndex];
    if (!this.props.store.getIsOpened() || !mention) {
      return "not-handled";
    }

    this.onMentionSelect(mention);
    return "handled";
  };

  openDropdown = () => {
    this.props.callbacks.handleReturn = this.commitSelection;
    this.props.callbacks.keyBindingFn = keyboardEvent => {
      // arrow down
      if (keyboardEvent.keyCode === 40) {
        this.onDownArrow(keyboardEvent);
      }
      // arrow up
      if (keyboardEvent.keyCode === 38) {
        this.onUpArrow(keyboardEvent);
      }
      // escape
      if (keyboardEvent.keyCode === 27) {
        this.onEscape(keyboardEvent);
      }
      // tab
      if (keyboardEvent.keyCode === 9) {
        this.onTab(keyboardEvent);
      }
      return undefined;
    };

    const descendant = `mention-option-${this.key}-${this.state.focusedOptionIndex}`;
    this.props.ariaProps.ariaActiveDescendantID = descendant;
    this.props.ariaProps.ariaOwneeID = `mentions-list-${this.key}`;
    this.props.ariaProps.ariaHasPopup = "true";
    this.props.ariaProps.ariaExpanded = true;
    this.props.onOpenChange(true);
  };

  closeDropdown = () => {
    // make sure none of these callbacks are triggered
    this.props.callbacks.handleReturn = undefined;
    this.props.callbacks.keyBindingFn = undefined;
    this.props.ariaProps.ariaHasPopup = "false";
    this.props.ariaProps.ariaExpanded = false;
    this.props.ariaProps.ariaActiveDescendantID = undefined;
    this.props.ariaProps.ariaOwneeID = undefined;
    this.props.onOpenChange(false);
  };

  toggleConnectedChannels = e => {
    this.props.setShowConnectedChannels(!this.props.showConnectedChannels);
  };

  preventDropdownCollapse = e => {
    e.preventDefault();
  };

  render() {
    if (!this.props.open) {
      return null;
    }

    const { popperOptions, theme = {} } = this.props;

    if (
      !this.props.renderEmptyPopup &&
      this.props.suggestions.length === 0 &&
      !this.props.isFetchingSocialMentions
    ) {
      return null;
    }

    const connected = this.props.suggestions.filter(
      item => !!item && item.isChannelConnection
    );
    const suggested = this.props.suggestions.filter(
      item => !!item && !item.isChannelConnection
    );

    return (
      <>
        <Transition
          in={!this.props.showConnectedChannels}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          unmountOnExit
          mountOnEnter
          timeout={125}
        >
          {state => (
            <Popover
              store={this.props.store}
              popperOptions={popperOptions}
              theme={theme}
            >
              <TransitionView
                state={state}
                onMouseDown={this.preventDropdownCollapse}
              >
                {this.props.isFetchingSocialMentions ? (
                  <MentionSuggestionList isLoading>
                    <DropdownProfileEntryLoader />
                    <DropdownProfileEntryLoader />
                    <DropdownProfileEntryLoader />
                    <DropdownProfileEntryLoader />
                    <DropdownProfileEntryLoader />
                  </MentionSuggestionList>
                ) : !suggested.length ? (
                  <>
                    <EmptyResultsWrapper showGuide={!!this.props.mentionGuide}>
                      {this.props.mentionGuide && (
                        <div className="guide-container">
                          <img
                            src={this.props.mentionGuide}
                            alt="Mention guide"
                          />
                        </div>
                      )}
                      <div className="info">
                        <div className="header">{this.props.emptyTitle}</div>
                        <div className="content">
                          {this.props.emptyContent}
                          {this.props.emptySubcontent && (
                            <div className="subcontent">
                              {this.props.emptySubcontent}
                            </div>
                          )}
                        </div>
                      </div>
                    </EmptyResultsWrapper>
                    {connected.length > 0 && (
                      <ProfileListSwitch
                        secondaryMenu={
                          this.props.secondaryMenu || connected.length > 1
                        }
                      >
                        {this.props.secondaryMenu || connected.length > 1 ? (
                          <div
                            className="content-wrapper"
                            onClick={this.toggleConnectedChannels}
                          >
                            <div className="content">
                              <StackedAvatars
                                size={28}
                                avatars={connected
                                  .slice(0, 3)
                                  .map(mention => mention.image)}
                              />
                              <Headline6 color="inherit">
                                Tag connected profile
                              </Headline6>
                            </div>
                            <i className="icon-arrowright" />
                          </div>
                        ) : (
                          <MentionSuggestionList>
                            <MentionSuggestionEntry
                              index={connected[0].id}
                              key={connected[0].id}
                              mention={connected[0]}
                              onMentionFocus={this.onMentionFocus}
                              onMentionSelect={this.onMentionSelect}
                              isFocused={
                                this.state.focusedOptionIndex ===
                                connected[0].id
                              }
                            />
                          </MentionSuggestionList>
                        )}
                      </ProfileListSwitch>
                    )}
                  </>
                ) : (
                  <>
                    <MentionSuggestionList>
                      {suggested.map((mention, index) => (
                        <MentionSuggestionEntry
                          index={index}
                          key={mention.id}
                          mention={mention}
                          onMentionFocus={this.onMentionFocus}
                          onMentionSelect={this.onMentionSelect}
                          isFocused={this.state.focusedOptionIndex === index}
                        />
                      ))}
                    </MentionSuggestionList>
                    {connected.length > 0 && (
                      <ProfileListSwitch
                        secondaryMenu={
                          this.props.secondaryMenu || connected.length > 1
                        }
                      >
                        {this.props.secondaryMenu || connected.length > 1 ? (
                          <div
                            className="content-wrapper"
                            onClick={this.toggleConnectedChannels}
                          >
                            <div className="content">
                              <StackedAvatars
                                size={28}
                                avatars={connected
                                  .slice(0, 3)
                                  .map(mention => mention.image)}
                              />
                              <Headline6 color="inherit">
                                Tag connected profile
                              </Headline6>
                            </div>
                            <i className="icon-arrowright" />
                          </div>
                        ) : (
                          <MentionSuggestionList>
                            <MentionSuggestionEntry
                              index={connected[0].id}
                              key={connected[0].id}
                              mention={connected[0]}
                              onMentionFocus={this.onMentionFocus}
                              onMentionSelect={this.onMentionSelect}
                              isFocused={
                                this.state.focusedOptionIndex ===
                                connected[0].id
                              }
                            />
                          </MentionSuggestionList>
                        )}
                      </ProfileListSwitch>
                    )}
                  </>
                )}
              </TransitionView>
            </Popover>
          )}
        </Transition>
        <Transition
          in={this.props.showConnectedChannels}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          unmountOnExit
          mountOnEnter
          timeout={125}
        >
          {state => (
            <Popover
              store={this.props.store}
              popperOptions={popperOptions}
              theme={theme}
            >
              <TransitionView
                state={state}
                onMouseDown={this.preventDropdownCollapse}
              >
                <ProfileListSwitch backSwitch secondaryMenu={true}>
                  <div className="content-wrapper">
                    <IconButton
                      icon="icon-return"
                      variant={"secondary"}
                      size={36}
                      iconSize={20}
                      iconColor={"#646769"}
                      onClick={this.toggleConnectedChannels}
                    />
                    <div className="content">
                      <Headline6>Connected profiles</Headline6>
                    </div>
                  </div>
                </ProfileListSwitch>
                <MentionSuggestionList>
                  {connected.map((mention, index) => (
                    <MentionSuggestionEntry
                      index={index}
                      key={mention.id}
                      mention={mention}
                      onMentionFocus={this.onMentionFocus}
                      onMentionSelect={this.onMentionSelect}
                      isFocused={this.state.focusedOptionIndex === index}
                    />
                  ))}
                </MentionSuggestionList>
              </TransitionView>
            </Popover>
          )}
        </Transition>
      </>
    );
  }
}

export default MentionSuggestions;

import React, {
  RefObject,
  useEffect,
  useContext,
  useReducer,
  useCallback,
  createContext
} from "react";
import { useDispatch } from "react-redux";

import {
  today,
  endOfMonth,
  startOfDay,
  difference,
  startOfMonth,
  getDateLabel,
  parseStringToDate
} from "utils/date";
import {
  PostType,
  DateRange,
  initialValues,
  ITimelineState,
  timelineReducer,
  ITimelineDispatch
} from "./reducer";
import {
  resetState,
  setIsFetching,
  setDateToBeFocused,
  setDateRangeToBeFetched,
  updateTimelineActivities
} from "./actionCreators";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { useAppActions, useAppState } from "contextApi/appContext";
import { useComposerState } from "contextApi/composerContext";
import { fetchTimelineActivities } from "services/activities";

export interface ITimelineValues {
  dateRefs: {
    [key in PostType]: {
      [key: string]: RefObject<HTMLDivElement>;
    };
  };
}

const initialDateRefs: ITimelineValues["dateRefs"] = {
  timeline: {},
  scheduled: {},
  published: {},
  drafts: {}
};

const TimelineStateContext = createContext<
  (ITimelineState & ITimelineValues) | undefined
>(undefined);
TimelineStateContext.displayName = "TimelineStateContext";

const TimelineDispatchContext = createContext<ITimelineDispatch | undefined>(
  undefined
);
TimelineDispatchContext.displayName = "TimelineDispatchContext";

export interface ITimelineActions {
  onEntryDeleted: (post: any) => void;
}

const TimelineActionsContext = createContext<ITimelineActions | undefined>(
  undefined
);
TimelineActionsContext.displayName = "TimelineActionsContext";

export const TimelineProvider: React.FC<any> = ({ children }: any) => {
  const addToast = useToaster();
  const reduxDispatch = useDispatch();
  const setIsDraftPanelOpen = useAppActions(state => state.setIsDraftPanelOpen);
  const onPostedEvent = useComposerState(state => state.events.onPostedEvent);
  const onReloadEvent = useComposerState(state => state.events.onReloadEvent);
  const onDeletedEvent = useComposerState(state => state.events.onDeletedEvent);
  const dateRefs: ITimelineValues["dateRefs"] = {
    ...initialDateRefs
  };
  const showIdeasPageRevamp = useAppState(state => state.showIdeasPageRevamp);

  const [state, dispatch] = useReducer(timelineReducer, {
    ...initialValues
  });
  const { postType, activities, dateRangeToBeFetched } = state;

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  const updateActivities = useCallback(
    async (postType: PostType, params: { [key: string]: any }) => {
      try {
        dispatch(setIsFetching(true));
        let activities = await fetchTimelineActivities(postType, params);
        // Hide drafts from other results if showIdeasPageRevamp is true
        if (postType !== "drafts" && showIdeasPageRevamp) {
          activities.entries = activities.entries.filter(
            entry => entry.type !== "DRAFT_POST"
          );
        }
        dispatch(setDateRangeToBeFetched(null));
        dispatch(updateTimelineActivities(activities));
      } catch (error) {
        addToast((error as any).message, "error");
      } finally {
        dispatch(setIsFetching(false));
      }
    },
    [addToast, showIdeasPageRevamp]
  );

  useEffect(() => {
    if (dateRangeToBeFetched !== null && !!postType) {
      const monthLabel = getDateLabel(dateRangeToBeFetched.start).slice(0, 7);
      const lastUpdated = activities[monthLabel]?.[postType]?.lastUpdated;

      let dateRange: DateRange = dateRangeToBeFetched;
      if (postType === "published") {
        const diff = difference(
          startOfMonth(dateRangeToBeFetched.start),
          startOfMonth(today()),
          "months"
        );
        if (diff > 0) {
          dateRange = {
            start: startOfMonth(today()),
            end: endOfMonth(today())
          };
        }
      } else if (postType === "scheduled") {
        const diff = difference(
          startOfMonth(dateRangeToBeFetched.start),
          startOfMonth(today()),
          "months"
        );

        if (diff <= 0) {
          dateRange = {
            start: startOfDay(today()),
            end: endOfMonth(today())
          };
        }
      }

      if (
        !state.isFetching &&
        (dateRange.override ||
          !lastUpdated ||
          difference(today(), lastUpdated, "minutes") >= 1)
      ) {
        updateActivities(postType, {
          fromDate: dateRange.start,
          toDate: dateRange.end
        });
      }
    }
  }, [
    updateActivities,
    dateRangeToBeFetched,
    postType,
    activities,
    state.isFetching
  ]);

  const refetchEntries = useCallback(
    async (date?: string) => {
      const day = date || state.focusedDate;
      let dateRange: DateRange | undefined;

      if (!!day) {
        dateRange = {
          start: startOfMonth(parseStringToDate(day, "yyyy/M/d")),
          end: endOfMonth(parseStringToDate(day, "yyyy/M/d"))
        };
      }
      if (
        !!dateRange &&
        state.postType === "scheduled" &&
        difference(
          startOfMonth(dateRange.start),
          startOfMonth(today()),
          "months"
        ) === 0
      ) {
        dateRange = {
          start: startOfDay(today()),
          end: endOfMonth(today())
        };
      }

      if (!!state.postType && !!dateRange) {
        await updateActivities(state.postType, {
          fromDate: dateRange.start,
          toDate: dateRange.end
        });
      }
    },
    [state.focusedDate, state.postType, updateActivities]
  );

  const onPosted = useCallback(
    async (post: any, metaData?: any) => {
      const postData = !!post.posts ? post.posts[0] : post.post ?? post;

      const date = postData.scheduledAt
        ? getDateLabel(new Date(postData.scheduledAt))
        : undefined;

      const oldDate = !!metaData?.prevDate
        ? getDateLabel(new Date(metaData.prevDate))
        : undefined;

      if (!!date && !!oldDate && date.slice(0, 7) !== oldDate.slice(0, 7)) {
        await refetchEntries(oldDate);
      }

      await refetchEntries(date);

      if (date) {
        dispatch(setDateToBeFocused(date));
      }

      if (!date && postData.isDraft) {
        setIsDraftPanelOpen(true);
      }
    },
    [refetchEntries]
  );

  const onEntryDeleted = useCallback(() => {
    // TODO: if post has taskId, then fetch taskId and get it's date
    // TODO: use this date to refetchEntries of another month like for onPosted
    refetchEntries();
  }, [refetchEntries, reduxDispatch]);

  useEffect(() => onPostedEvent.listen(onPosted), [onPostedEvent, onPosted]);

  useEffect(
    () => onReloadEvent.listen(refetchEntries),
    [onReloadEvent, refetchEntries]
  );

  useEffect(
    () => onDeletedEvent.listen(onEntryDeleted),
    [onDeletedEvent, onEntryDeleted]
  );

  return (
    <TimelineStateContext.Provider value={{ ...state, dateRefs }}>
      <TimelineDispatchContext.Provider value={dispatch}>
        <TimelineActionsContext.Provider
          value={{
            onEntryDeleted
          }}
        >
          {children}
        </TimelineActionsContext.Provider>
      </TimelineDispatchContext.Provider>
    </TimelineStateContext.Provider>
  );
};

export const useTimelineState = () => {
  const context = useContext(TimelineStateContext);

  if (context === undefined) {
    throw new Error("useTimelineState must be used within a TimelineProvider");
  }
  return context;
};

export const useTimelineDispatch = () => {
  const context = useContext(TimelineDispatchContext);

  if (context === undefined) {
    throw new Error(
      "useTimelineDispatch must be used within a TimelineProvider"
    );
  }
  return context;
};

export const useTimelineActions = () => {
  const context = useContext(TimelineActionsContext);

  if (context === undefined) {
    throw new Error(
      "useTimelineActions must be used within a TimelineProvider"
    );
  }
  return context;
};

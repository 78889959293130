import React, { useState } from "react";
import { SubmissionError } from "redux-form";
import ForgotPasswordForm from "./ForgotPasswordForm";

import { callApi } from "utils/ContentoApi";
import { AuthLayout, AuthImage, AuthBody } from "../styles";
import bgImage from "assets/images/willow-signin-banner.jpg";

const ForgotPasswordPage = React.memo(({ resetPasswordError }) => {
  const [resetPasswordSent, setResetPasswordSent] = useState(false);

  const handleResetPassword = ({ email }) => {
    return callApi({
      url: "auth/password-reset",
      method: "post",
      data: { email }
    })
      .then(() => {
        setResetPasswordSent(true);
      })
      .catch(error => {
        let errorMessage = "Something went wrong.";
        if (error.message === "User not found.") {
          errorMessage = "We didn't recognize that email address.";
        } else if (error.response?.status === 429) {
          errorMessage = "Too many attempts, please try again later.";
        }
        throw new SubmissionError({ _error: errorMessage });
      });
  };

  return (
    <AuthLayout>
      <AuthBody>
        <ForgotPasswordForm
          onSubmit={handleResetPassword}
          resetError={resetPasswordError}
          sent={resetPasswordSent}
        />
      </AuthBody>
      <AuthImage bgImage={bgImage} />
    </AuthLayout>
  );
});

export default ForgotPasswordPage;

import { EditorState, Modifier } from "draft-js";
import {
  createSelectionFromRange,
  getEntityRange,
  replaceText
} from "../../../utils/editor";

export const findCaption = (contentState, captions) => {
  for (let contentBlock of contentState.getBlockMap()) {
    const text = contentBlock[1].getText();

    let data = null;
    for (let i = 0; i < captions.length; i++) {
      const index = text.indexOf(captions[i]);
      if (index !== -1) {
        data = {
          caption: captions[i],
          position: i,
          range: {
            start: index,
            end: index + captions[i].length
          },
          contentBlockKey: contentBlock[1].getKey()
        };
        break;
      }
    }

    if (
      data !== null &&
      contentBlock[1].getEntityAt(data.range.start) !== null
    ) {
      continue;
    }

    if (data !== null) {
      return data;
    }
  }
  return false;
};

export const addCaptionEntity = (editorState, selection, entityData) => {
  let contentState = editorState.getCurrentContent();

  contentState = contentState.createEntity(
    "GENERATED_CAPTION",
    "IMMUTABLE",
    entityData
  );

  const entityKey = contentState.getLastCreatedEntityKey();
  const currentContent = editorState.getCurrentContent();

  const contentStateWithEntity = Modifier.applyEntity(
    currentContent,
    selection,
    entityKey
  );

  return EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
    lastChangeType: "apply-entity"
  });
};

export const replaceCaptionEntity = (
  editorState,
  blockKey,
  entityKey,
  oldCaption,
  newCaption
) => {
  const range = getEntityRange(editorState, entityKey, blockKey);
  if (!range) {
    return editorState;
  }

  const selection = createSelectionFromRange(blockKey, range);
  const newEditorState = replaceText(
    editorState,
    selection,
    oldCaption,
    newCaption
  );

  const newRange = { start: range.start, end: range.start + newCaption.length };
  const newSelection = createSelectionFromRange(blockKey, newRange);

  return addCaptionEntity(newEditorState, newSelection, {});
};

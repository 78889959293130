import styled from "styled-components";
import { Box } from "rebass/styled-components";
import { LifeRing } from "@styled-icons/fa-regular";
import React, { useCallback, useState } from "react";

import { TooltipInfo } from "./styles";
import { MenuList, MenuItem } from "./Menu";
import Popper from "components/common/Popper";
import IconButton from "../common/IconButton";

const UserMenuButtonWrapper = styled(Box)`
  box-shadow: ${props =>
    props.isOpen
      ? `0 0 0 2px white, 0 0 0 4px ${props.theme.colors.primary}`
      : `0 0 0 0 white, 0 0 0 -2px ${props.theme.colors.primary}`};
  border-radius: 50%;
`;

const UserMenuContainer = styled(Box)`
  background: white;
  border-radius: 16px;
  box-shadow:
    inset 0 0 0 1px ${props => props.theme.colors.grey02},
    0px 30px 60px -30px rgba(0, 0, 0, 0.15),
    0px 50px 100px -20px rgba(0, 0, 0, 0.2),
    0px 0px 1px rgba(0, 0, 0, 0.08);
  max-height: calc(100vh - 96px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px 0;
  transition: 0.25s height;
  width: 265px;
`;

const UserMenuSection = styled(Box)`
  border-bottom: ${props =>
    !props.last ? `1px solid ${props.theme.colors.grey02}` : "none"};
  padding: ${props => (!props.first ? "8px" : "2px")} 0
    ${props => (!props.last ? "8px" : "2px")} 0;
`;

const HelpMenu = React.memo(({ account }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  const toggleShowOptions = useCallback(
    () => setShowOptions(showOptions => !showOptions),
    []
  );

  const handleOpenPublicLink = useCallback(path => {
    window.open(path, "_blank");
    setShowOptions(false);
  }, []);

  const handleOpenChat = useCallback(() => {
    if (window.HubSpotConversations) {
      if (window.HubSpotConversations) {
        window.history.pushState({}, "hs_support", "?hs_support=true");
        window.HubSpotConversations.widget.refresh({
          openToNewThread: true
        });
        window.HubSpotConversations.widget.open();
      }
    }

    setShowOptions(false);
  }, []);

  return (
    <div ref={setReferenceElement}>
      <UserMenuButtonWrapper onClick={toggleShowOptions} isOpen={showOptions}>
        <IconButton
          data-tip="Support"
          icon={LifeRing}
          isActive={showOptions}
          size={36}
          variant="primary"
        />
        <TooltipInfo effect={"solid"} place={"bottom"} />
      </UserMenuButtonWrapper>
      <Popper
        offset={[-200, 10]}
        referenceElement={referenceElement}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[referenceElement]}
      >
        <Box>
          <UserMenuContainer>
            <UserMenuSection first last>
              <MenuList>
                <MenuItem
                  label={`Chat with Customer Success`}
                  icon="chat"
                  onClick={handleOpenChat}
                />
                <MenuItem
                  label="Help center"
                  icon="help"
                  onClick={() =>
                    handleOpenPublicLink("https://support.willow.co/knowledge")
                  }
                />
                <MenuItem
                  label="Product updates"
                  icon="present"
                  onClick={() =>
                    handleOpenPublicLink(
                      "https://www.willow.co/product-updates?ref=app"
                    )
                  }
                />
              </MenuList>
            </UserMenuSection>
          </UserMenuContainer>
        </Box>
      </Popper>
    </div>
  );
});

export default HelpMenu;

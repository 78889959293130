import styled, { css } from "styled-components";

const imageGridAreas = (rowHeight?: number | string | null) => ({
  "grid-1": css`
    grid-template-areas: "grid-item-1";
    grid-template-rows: repeat(1, ${rowHeight ? `calc(${rowHeight})` : "auto"});
  `,
  "grid-2": css`
    grid-template-areas: "grid-item-1 grid-item-2";
    grid-template-columns: repeat(2, 1fr);
  `,
  "grid-3": css`
    grid-template-areas: "grid-item-1 grid-item-2" "grid-item-1 grid-item-3";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(
      2,
      ${rowHeight ? `calc(${rowHeight} / 2 - 1px)` : "auto"}
    );
  `,
  "grid-4": css`
    grid-template-areas: "grid-item-1 grid-item-2" "grid-item-3 grid-item-4";
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(
      2,
      ${rowHeight ? `calc(${rowHeight} / 2 - 1px)` : "auto"}
    );
  `
});

export const PostAttachmentImage = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
`;

// FIXME: no correlation between size and other variant properties,
// properly define once externalized
export const ImageGrid = styled.div<{
  $size?: string;
  $rowHeight?: number | string;
  $gridSize: number;
}>`
  display: grid;
  max-width: 100%;
  height: 100%;
  grid-gap: ${props => (props.$size === "sm" ? "2px" : "4px")};
  ${props => imageGridAreas(props.$rowHeight)[`grid-${props.$gridSize}`]};

  ${props =>
    props.$size === "sm" &&
    css`
      border-radius: 8px;
      overflow: hidden;
    `}
`;

export const ImageArea = styled.div<{
  $maxHeight?: number;
  $gridArea: string;
  $overlay?: boolean;
}>`
  background: ${props => props.theme.colors.grey02};
  display: block;
  width: 100%;
  height: 100%;
  align-self: center;
  ${props =>
    props.$maxHeight &&
    css`
      max-height: ${props.$maxHeight}px;
    `};
  grid-area: ${props => props.$gridArea};

  ${props =>
    props.$overlay &&
    css`
      align-items: center;
      background: ${props => props.theme.colors.text02};
      display: flex;
      justify-content: center;

      & span {
        color: ${props => props.theme.colors.text05};
        font-size: 20px;
        font-weight: 500;
      }
    `}
`;

export const AttachmentImage = styled.img`
  object-fit: cover;
  object-position: center;
  display: block;
  width: 100%;
  height: 100%;
`;

import React from "react";
import { Box, Flex } from "rebass/styled-components";
import { Field, reduxForm } from "redux-form";
import TextInput from "components/common/form/TextInput";
import { required, validEmail } from "utils/Validation";
import { ForgotBox, ForgotLink } from "components/auth/styles";

import {
  FormBox,
  FormLogo,
  FormContainer,
  RoundedButton,
  FieldWrapper
} from "../styles";

import {
  Form,
  FormTitle,
  FormDescription,
  IconExclamationCircle,
  AlertMessage
} from "components/common/form/styles";

import willowLogo from "assets/images/willow-text-color@2x.png";
import Loader from "components/common/loading/Loader";

const ForgotPasswordForm = ({
  handleSubmit,
  submitting,
  sent,
  error,
  valid
}) => {
  if (!sent) {
    return (
      <FormContainer>
        <FormLogo>
          <img src={willowLogo} alt="Willow" height="40" />
        </FormLogo>
        <FormTitle>Forgot your password?</FormTitle>
        <FormDescription>
          Enter your email address below and we’ll send you password reset
          instructions.
        </FormDescription>
        <FormBox>
          <Form onSubmit={handleSubmit}>
            <FieldWrapper>
              <Field
                validate={[required, validEmail]}
                placeholder="you@email.com"
                component={TextInput}
                name="email"
              />
            </FieldWrapper>
            {error && (
              <AlertMessage>
                <h5>
                  <IconExclamationCircle color="#ffba00" />
                  {error}
                </h5>
              </AlertMessage>
            )}
            <Box pb={8}>
              <RoundedButton
                size="lg"
                width="100%"
                type="submit"
                disabled={submitting || !valid}
              >
                <Flex justifyContent={"center"} alignItems={"center"}>
                  {submitting && <Loader size={16} />}
                  Send reset link
                </Flex>
              </RoundedButton>
            </Box>
          </Form>
        </FormBox>
        <ForgotBox mt={16}>
          <ForgotLink to={"login"}>Never mind, go back to login</ForgotLink>
        </ForgotBox>
      </FormContainer>
    );
  } else {
    return (
      <FormContainer>
        <FormLogo>
          <img src={willowLogo} alt="Willow" height="40" />
        </FormLogo>
        <FormTitle>Check your email</FormTitle>
        <FormDescription>
          We sent you the password reset instructions. Make sure to check your
          spam folder.
        </FormDescription>
      </FormContainer>
    );
  }
};

export default reduxForm({
  // a unique name for the form
  form: "loginForm",
  enableReinitialize: true
})(ForgotPasswordForm);

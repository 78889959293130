// @ts-nocheck
import React from "react";

import {
  MenuList,
  MenuDivider,
  MenuContainer
} from "components/navigation/Menu";
import { MenuItem } from "./styles";

interface IPostMenu {
  editPost: (e: React.MouseEvent) => void;
  deletePost: (e: React.MouseEvent) => void;
  postToDraft: (e: React.MouseEvent) => void;
  duplicatePost: (e: React.MouseEvent) => void;
}

const PostMenu: React.FC<IPostMenu> = ({
  editPost,
  deletePost,
  postToDraft
  // duplicatePost
}) => {
  return (
    <MenuContainer width={240}>
      <MenuList>
        <MenuItem label="Edit post" icon="edit" onClick={editPost} />
        {/* <MenuItem label="Duplicate post" icon="copy" onClick={duplicatePost} /> */}
      </MenuList>
      <MenuDivider my={2} />
      <MenuList>
        <MenuItem label="Turn into draft" icon="draft" onClick={postToDraft} />
      </MenuList>
      <MenuDivider my={2} />
      <MenuList>
        <MenuItem
          icon="delete"
          color="danger"
          label="Delete post"
          onClick={deletePost}
        />
      </MenuList>
    </MenuContainer>
  );
};

export default PostMenu;

export default {
  article: {
    id: "article",
    title: "Article",
    description: "Grow your status as a thought leader",
    color: "#0063fb",
    bgColor: "rgb(0,99,251,0.15)",
    overColor: "rgb(0,99,251,0.25)"
  },
  customer_testimonial: {
    id: "customer_testimonial",
    title: "Customer Testimonial",
    description: "Highlight satisfied buyers",
    color: "#f97f50",
    bgColor: "rgba(249,127,80,0.13)",
    overColor: "rgba(249,127,80,0.25)"
  },
  job_opening: {
    id: "job_opening",
    title: "Job Opening",
    description: "Hire someone new to your team",
    color: "#ffba00",
    bgColor: "rgba(225,146,0,0.15)",
    overColor: "rgba(225,146,0,0.25)"
  },
  employee_story: {
    id: "employee_story",
    title: "Employee Story",
    description: "Showcase yourcompany culture",
    color: "#7c3aed",
    bgColor: "rgba(124,58,237,0.13)",
    overColor: "rgba(124,58,237,0.25)"
  },
  blog_post: {
    id: "blog_post",
    title: "Blog Post",
    description: "Share a blog post from your website",
    color: "#0891b2",
    bgColor: "rgba(8,145,178,0.15)",
    overColor: "rgba(8,145,178,0.25)"
  },
  product_highlight: {
    id: "product_highlight",
    title: "Product Highlight",
    description: "Showcase your products or services",
    color: "#ec4899",
    bgColor: "rgba(236,72,153,0.13)",
    overColor: "rgba(236,72,153,0.25)"
  },
  custom: {
    id: "custom",
    title: "Custom",
    description: "Add a custom content type",
    color: "#64748B",
    bgColor: "rgba(100,116,139,0.15)",
    overColor: "rgba(100,116,139,0.25)"
  },
  educational: {
    id: "educational",
    title: "Educational",
    description: "Blog, article, whitepaper, e-book, tip of the week",
    color: "#003399",
    bgColor: "#E2E9F6",
    overColor: "#EBF0FC",
    previewColor: "#003399",
    border: "1px solid #00339908",
    isConcept: true
  },
  entertain: {
    id: "entertain",
    title: "Entertain & Inspire",
    description: "GIF, meme, viral trends",
    color: "#047857",
    bgColor: "#CDE4DD",
    overColor: "#E6F2EE",
    previewColor: "#047857",
    border: "1px solid #04785708",
    isConcept: true
  },
  personal: {
    id: "personal",
    title: "Personal",
    description: "Personal story, achievement, quote, vlog",
    color: "#F59E0B",
    bgColor: "#FDECCE",
    overColor: "#FEF5E7",
    previewColor: "#F59E0B",
    border: "1px solid #F59E0B08",
    isConcept: true
  },
  promotional: {
    id: "promotional",
    title: "Promotional",
    description: "Company news, customer review, service announcement",
    color: "#0891B2",
    bgColor: "#CEE9F0",
    overColor: "#E6F4F7",
    previewColor: "#0891B2",
    border: "1px solid #0891B208",
    isConcept: true
  },
  employees: {
    id: "employees",
    title: "Emp. & Workplace",
    description: "Employee testimonial, job vacancies, behind the scenes",
    color: "#FF652B",
    bgColor: "#FFE0D5",
    overColor: "#FFF0EA",
    previewColor: "#FF652B",
    border: "1px solid #FF652B08",
    isConcept: true
  }
};

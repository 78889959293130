import React from "react";
import styled from "styled-components";

import { SourceIconWrapper } from "../style";

import RSSIcon from "assets/images/contents/RSS.png";
import TopicIcon from "assets/images/contents/Topic.png";
import WebsiteIcon from "assets/images/contents/Website.png";
import TwitterIcon from "assets/images/contents/Twitter.png";
import KeywordIcon from "assets/images/contents/Keyword-Monitor.png";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .center {
    z-index: 3;
  }
  .left {
    z-index: 2;
  }
  .right {
    z-index: 1;
  }
`;

const IconImage = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  margin-right: -12px;
  border: 4px solid #eceef0;
`;

const FollowSourceCard: React.FC<{}> = () => {
  return (
    <SourceIconWrapper>
      <Wrapper>
        <IconImage src={KeywordIcon} />
        <IconImage className="left" src={RSSIcon} />
        <IconImage className="center" src={TopicIcon} />
        <IconImage className="right" src={TwitterIcon} />
        <IconImage src={WebsiteIcon} />
      </Wrapper>
    </SourceIconWrapper>
  );
};

export default FollowSourceCard;

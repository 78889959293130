import React, { useState } from "react";
import styled, { css, keyframes } from "styled-components";

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
  margin: 0px;
  margin-left: ${props => props.marginLeft || "0"}px;
`;

const popIn = keyframes`
from {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.5) ;
}
to {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1) ;
}
`;

const Label = styled.label`
  position: relative;
  display: inline-flex;
  flex-direction: row;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  font-size: 14px;
  align-items: center;
  gap: ${props => props.gap || "10"}px;

  ${props =>
    props.type === "descriptive" &&
    css`
      padding: 16px;

      background: ${props => props.theme.colors.white};
      border: 1.3px solid
        ${props =>
          props.isActive ? props.theme.colors.blue : props.theme.colors.grey04};
      box-sizing: border-box;
      border-radius: 12px;

      ${props =>
        props.isActive &&
        css`
          color: ${props => props.theme.colors.blue};
        `};

      &:hover {
        font-weight: 600;
      }

      ${props =>
        !props.disabled &&
        css`
          &:hover {
            border-color: ${props =>
              props.isActive
                ? props.theme.colors.blue
                : props.theme.colors.black};
            color: ${props =>
              props.isActive
                ? props.theme.colors.blue
                : props.theme.colors.black};
          }
        `}
    `};
`;

const Indicator = styled.div`
  border: 2px solid ${props => props.theme.colors.text04};
  border-radius: 50%;
  width: 18px;
  height: 18px;
  position: relative;

  ${Input}:checked + & {
    border-color: ${props => props.theme.colors.blue};
  }

  &::after {
    content: "";
    display: none;
  }

  ${props =>
    props.hover &&
    css`
      border-color: ${props => props.theme.colors.black};

      &::after {
        display: block;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.black};
        width: 12px;
        height: 12px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        animation-name: ${popIn};
        animation-duration: 0.3s;
        animation-fill-mode: forwards;
        position: absolute;
      }
    `}

  ${Input}:checked + &::after {
    display: block;
    border-radius: 50%;
    background-color: ${props => props.theme.colors.blue};
    width: 12px;
    height: 12px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation-name: ${popIn};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    position: absolute;
  }

  ${Input}:disabled + & {
    pointer-events: none;
    opacity: 0.6;
    background: ${props => props.theme.colors.white};
  }
`;

export const ContentWrapper = styled.div`
  margin-left: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-weight: 500;

  ${props =>
    props.type === "descriptive" &&
    css`
      ${Input}:checked ~ & {
        color: ${props => props.theme.colors.blue};
      }
    `};
`;

export default function RadioButton({
  value,
  onChange,
  name,
  id,
  label,
  disabled,
  checked,
  children,
  defaultChecked,
  gap = 10,
  marginLeft,
  type = "normal"
}) {
  const [hover, setHover] = useState(false);

  return (
    <Label
      htmlFor={id}
      type={type}
      disabled={disabled}
      isActive={checked}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      gap={gap}
    >
      <Input
        id={id}
        type="radio"
        role="radio"
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        defaultChecked={defaultChecked}
        marginLeft={marginLeft}
      />
      <Indicator hover={hover} />
      <ContentWrapper type={type} isActive={checked}>
        {children}
        {label}
      </ContentWrapper>
    </Label>
  );
}

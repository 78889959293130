import { Box } from "rebass";
import HelperButton from "components/common/form/HelperButton";
import Loader from "components/common/loading/Loader";
import { Headline3 } from "components/common/styles";
import ExploreTopics from "components/content/explore/topics";
import React, { useEffect, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Flex } from "rebass";
import {
  getRecommendedTopics,
  getTopicsGroupedByParent,
  ITopic
} from "services/news";
import ExploreTopic from "../../widgets/ExploreTopic";

import { RecommendationContainer, RecommendationGrid } from "../styles";

interface IRecommendedTopicsProps {
  hideSeeAll?: boolean;
  showAll?: boolean;
}

const RecommendedTopics: React.FC<IRecommendedTopicsProps> = ({
  hideSeeAll,
  showAll = false
}) => {
  const account = useSelector((state: any) => state.account.data);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [topics, setTopics] = useState<any>([]);
  const [allTopics, setAllTopics] = useState<Array<any>>([]);

  const fetchAllTopics = useCallback(async () => {
    try {
      const topics = await getTopicsGroupedByParent();
      setAllTopics(topics);
    } catch (_) {}
  }, []);

  const fetchRecommendedTopics = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getRecommendedTopics();
      setTopics(response);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const navigateTo = (url: string) => {
    history.push(`/accounts/${account.id}/content/${url}`);
  };

  useEffect(() => {
    if (showAll) fetchAllTopics();
    fetchRecommendedTopics();
  }, [fetchRecommendedTopics, fetchAllTopics, showAll]);

  return (
    <>
      <RecommendationContainer>
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Headline3>Recommended Topics</Headline3>
          {!hideSeeAll && (
            <HelperButton
              label={"See all"}
              onClick={() => navigateTo("explore?type=topics")}
            />
          )}
        </Flex>
        {isLoading ? (
          <Box
            height={150}
            sx={{
              position: "relative"
            }}
          >
            <Loader location="center" size={32} />
          </Box>
        ) : (
          <RecommendationGrid>
            {topics.map((topic: ITopic) => (
              <ExploreTopic
                data={topic}
                key={topic.id + topic.language}
                onClick={() =>
                  navigateTo(`topics/${topic.id}/${topic.language}`)
                }
              />
            ))}
          </RecommendationGrid>
        )}
      </RecommendationContainer>
      {allTopics.map(topic => {
        if (topic.children.length < 1) return null;
        return (
          <ExploreTopics
            key={topic.title}
            title={topic.parent.title}
            changeSearchContext={() => {}}
            topics={topic.children}
            searchContext={""}
          />
        );
      })}
    </>
  );
};

export default RecommendedTopics;

import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  DateSection,
  GreetingSection,
  DashboardGreeting,
  DashboardHeaderStats,
  DashboardHeaderWrapper,
  DashboardHeaderOptions
} from "./styles";
import DashboardFilter from "./dashboardFilter";
import Loader from "components/common/loading/Loader";
import { useDashboardState } from "contextApi/dashboardContext";
import { today, formatTime, formatForDashboard } from "utils/date";

const DashboardHeader: React.FC<{ isOnboardingActive: boolean }> = ({
  isOnboardingActive
}) => {
  const stats = useDashboardState(state => state.stats.data);
  const isFetching = useDashboardState(state => state.stats.isFetching);
  const account = useSelector<any, any>(state => state.account.data);
  const currentUser = useSelector<any, any>(state => state.auth.currentUser);
  const { channels } = useSelector<any, any>(state => state.account.data);
  const currentDate = formatForDashboard(today());
  const dayAndMonth = useMemo(() => currentDate.slice(0, -3), [currentDate]);
  const date = useMemo(() => currentDate.slice(-2), [currentDate]);

  const currentHour = parseInt(formatTime(today()).slice(0, 2));
  const greeting = useMemo(() => {
    if (currentHour >= 6 && currentHour < 12) {
      return "Good morning";
    } else if (currentHour >= 12 && currentHour < 17) {
      return "Good afternoon";
    } else if (currentHour >= 17 && currentHour < 24) {
      return "Good evening";
    } else {
      return "Good night";
    }
  }, [currentHour]);

  const justHasLinkedinProfile =
    !!channels &&
    channels.length === 1 &&
    channels[0].service === "linkedin" &&
    channels[0].serviceType === "profile";

  const isTrialOrSmallBiz = useMemo(
    () =>
      account?.billing?.willowPlan === "SMALL_BIZ" ||
      account?.billing?.billingStatus === "in_trial",
    [account]
  );

  const onboardingProgressPercentage = Math.ceil(
    (account.onboardingProgress.length /
      (account?.isTelenetCustomer ? 3 : isTrialOrSmallBiz ? 7 : 6)) *
      100
  );

  const followersProgress = (() => {
    if (
      !stats?.followers ||
      typeof stats.followers.previousTotal !== "number" ||
      typeof stats.followers.total !== "number"
    ) {
      return null;
    } else {
      return stats.followers.total - stats.followers.previousTotal;
    }
  })();

  return (
    <DashboardHeaderWrapper>
      <DashboardGreeting>
        <DateSection>
          {dayAndMonth}
          <em>{date}</em>
        </DateSection>
        <GreetingSection>
          {greeting}, {currentUser.firstName}
        </GreetingSection>
      </DashboardGreeting>
      <DashboardHeaderOptions>
        <DashboardFilter />
        <DashboardHeaderStats
          disabled={!stats?.posts || typeof stats.posts.total !== "number"}
        >
          <i className="icon-check" />
          {isOnboardingActive ? (
            `${onboardingProgressPercentage}% progress`
          ) : isFetching ? (
            <Loader type="bubbles" size={24} />
          ) : !!stats?.posts && typeof stats.posts.total === "number" ? (
            <span>
              <em>{stats.posts.total}</em>{" "}
              {stats.posts.total === 1 ? "post" : "posts"} created
            </span>
          ) : (
            <span>
              <em>-</em> posts created
            </span>
          )}
        </DashboardHeaderStats>
        <DashboardHeaderStats
          disabled={
            justHasLinkedinProfile ||
            followersProgress === null ||
            followersProgress < 0
          }
        >
          <i className="icon-audience-filled" />
          {isFetching ? (
            <Loader type="bubbles" size={24} />
          ) : !justHasLinkedinProfile &&
            followersProgress !== null &&
            followersProgress >= 0 ? (
            <span>
              <em>{followersProgress}</em> new
              {followersProgress === 1 ? " follower" : " followers"}
            </span>
          ) : (
            <span>
              <em>-</em> new followers
            </span>
          )}
        </DashboardHeaderStats>
      </DashboardHeaderOptions>
    </DashboardHeaderWrapper>
  );
};

export default DashboardHeader;

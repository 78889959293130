import styled, { css } from "styled-components";
import { Box, Flex, Image } from "rebass/styled-components";

import { ATTACHMENT_TYPES } from "constants/post";
import IconRemoveArticle from "assets/images/composer/remove-article.png";
import IconAttachImage from "assets/images/composer/toolbar-attach-image.png";
import IconAttachVideo from "assets/images/composer/toolbar-attach-video.png";

export const PostImage = styled(Image)`
  width: 120px;
  height: 120px;
  object-fit: cover;
  min-width: auto;
  border-radius: 12px;
  align-self: center;
  border: 1px solid ${props => props.theme.colors.grey03};

  &.article {
    width: 130px;
    height: 130px;
    border-radius: 8px 0 0 8px;
    margin-right: 0;
    flex-shrink: 0;
  }
`;

export const PostVideo = styled.video`
  width: 300px;
  height: 170px;
  min-width: auto;
  border-radius: 4px;
  border: solid #ccc 1px;
`;

export const LoadingBox = styled(Box)`
  margin-top: 20px;
  font-size: 10px;
  color: #999;
  text-align: center;

  & > div {
    margin: auto;
  }

  & > span {
    display: inline-block;
    margin: 0 5px;
  }
`;

export const ImageWrapper = styled.div<{
  $progress?: number;
  $isDragging?: boolean;
}>`
  position: relative;
  min-width: 120px;
  padding: 2px;

  &:hover > img {
    filter: brightness(60%);
  }

  & > img {
    margin-right: 0 !important;
  }

  & .x {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 4px;
    z-index: 100;
    visibility: hidden;

    &.video {
      right: 10px;
    }

    &:hover {
      & + img {
        filter: brightness(65%);
      }
    }
  }

  &:hover {
    & .x {
      visibility: visible;
    }
  }

  ${props =>
    !!props.$progress &&
    css`
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: ${_ => `${props.$progress}%`};
        background-color: ${props => props.theme.colors.white};
        opacity: 80%;
      }
    `}

  ${props =>
    props.$isDragging &&
    css`
      border: 2px solid ${props => props.theme.colors.blue};
      border-radius: 14px;
      box-shadow: ${props => props.theme.shadows.shadowM};

      img {
        padding-bottom: 2px;
      }
    `};
`;

export const AttachmentActions = styled.div`
  display: none;
  position: absolute;
  top: 8px;
  right: 6px;
  gap: 4px;
  z-index: 999;

  ${ImageWrapper}:hover & {
    display: flex;
  }
`;

export const AttachmentAction = styled.div`
  position: relative;
  width: 28px;
  height: 28px;
  background: rgba(28, 30, 31, 0.33);
  border: 1.3px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border-color: ${props => props.theme.colors.white};
  }

  > i {
    font-size: 20px;
    color: ${props => props.theme.colors.white};
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  min-width: 100px;

  & > img {
    margin-right: 0 !important;
  }

  & .x {
    cursor: pointer;
    position: absolute;
    border-radius: 6px;
    top: 8px;
    right: 8px;
    width: 24px;
    height: 24px;
    z-index: 9;
    background: rgba(28, 30, 33, 0.6);
    padding: 2px 0 0 2px;
  }
`;

export const AttachmentContainer = styled(Flex)<{
  $background?: boolean;
  $shadow?: boolean;
}>`
  border-radius: 16px;
  background-color: ${props => (props.$background ? "#f1f2f3" : "transparent")};
  position: relative;
  ${props =>
    props.$shadow &&
    css`
      box-shadow: ${props => props.theme.shadows.shadowS};
    `}

  &:hover {
    .underline {
      text-decoration: underline;
    }
  }
`;

export const AttachmentLink = styled.a`
  display: flex;
`;

export const AttachmentLoading = styled(Flex)`
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 104px;

  & > div {
    margin-top: 40px;
  }

  & p {
    color: #606770;
  }
`;

export const AttachmentDetail = styled(Flex)`
  flex-direction: column;
  padding: 20px 12px;
`;

export const AttachmentTitle = styled(Flex)`
  max-height: 18px;
  overflow: hidden;
  font-weight: bold;
  line-height: 20px;
  font-size: 14px;
  text-overflow: ellipsis;
  color: #161616;
`;

export const AttachmentTextWrapper = styled(Flex)`
  flex-direction: column;
  gap: 4px;
`;

export const CloseIcon = styled.div`
  /* background: url("${IconRemoveArticle}") no-repeat center center; */
  /* background-size: 10px; */
  border-radius: 50%;
  /* height: 28px; */
  /* width: 28px; */
  position: absolute;
  top: 6px;
  right: 8px;
  /* cursor: pointer; */
`;

export const AttachmentDescription = styled(Flex)`
  max-height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #8b93a6;
`;

export const AttachmentDomainWrapper = styled(Flex)`
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0px;
  gap: 6px;
  font-weight: 500;
  font-size: 12px;
  color: ${props => props.theme.colors.text03};
`;

export const AttachmentDomainIcon = styled.img`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  overflow: hidden;
`;

export const AttachmentDomain = styled(Box)`
  color: ${props => props.theme.colors.text03};
  font-size: 12px;
`;

export const AttachmentFooter = styled(Flex)`
  border-top: 1px solid #d0d6da;
  margin-top: 20px;
  padding-top: 10px;
`;

export const AttachmentButton = styled(Flex)`
  align-items: center;
  cursor: pointer;
  color: #8b93a6;
`;

export const DropZoneContainer = styled(Flex)<{
  $isDragActive?: boolean;
  $isDragAccept?: boolean;
  $isDragReject?: boolean;
}>`
  height: ${props =>
    props.type === ATTACHMENT_TYPES.VIDEO ? "170px" : props.height || "100px"};
  width: ${props =>
    props.type === ATTACHMENT_TYPES.VIDEO ? "300px" : props.width || "100px"};
  min-width: ${props => props.minHeight || "100px"};
  cursor: pointer;
  background-color: #ffffff;
  border: 1px dashed
    ${props =>
      props.$isDragActive && props.$isDragAccept
        ? props.theme.colors.blue
        : props.$isDragActive && props.$isDragReject
          ? props.theme.colors.red
          : props.theme.colors.text04};
  box-sizing: border-box;
  border-radius: ${props =>
    props.type === ATTACHMENT_TYPES.VIDEO ? "4px" : "13px"};

  .hover {
    display: none;
  }
  .nohover {
    display: block;
  }

  &:hover {
    border-color: ${props => props.theme.colors.text03};
    .hover {
      display: block;
    }
    .nohover {
      display: none;
    }
  }

  &:focus-visible {
    outline: unset;
    border-color: ${props => props.theme.colors.text03};
  }
`;

export const UploadArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > i {
    font-size: 18px;
  }

  .content {
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.005em;
    color: ${props => props.theme.colors.text01};

    > em {
      font-weight: 600;
    }

    > span {
      color: ${props => props.theme.colors.text03};
    }
  }
`;

export const ImageList = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollerButton = styled.div<{
  $size?: number;
  $iconSize?: number;
  $direction?: string;
}>`
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.grey03};
  box-sizing: border-box;
  height: ${props => props.$size ?? 32}px;
  width: ${props => props.$size ?? 32}px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  box-shadow: 0px 0px 0px 1px rgba(28, 30, 31, 0.05);
  filter: drop-shadow(0px 3px 6px rgba(28, 30, 31, 0.05));

  > i {
    font-size: ${props => props.$iconSize ?? 16}px;
    color: ${props => props.theme.colors.text02};
    :hover {
      color: ${props => props.theme.colors.text01};
    }
  }

  ${props =>
    props.$direction === "left"
      ? css`
          left: -16px;
        `
      : props.$direction === "right"
        ? css`
            right: -16px;
          `
        : null};
`;

export const Scroller = styled.div<{ $direction?: string }>`
  height: 100%;
  width: 48px;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 9;

  ${props =>
    props.$direction === "left"
      ? css`
          left: 0;
          justify-content: flex-start;
          background: linear-gradient(
            90deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
          );
        `
      : props.$direction === "right"
        ? css`
            right: 0;
            justify-content: flex-end;
            background: linear-gradient(
              270deg,
              #ffffff 0%,
              rgba(255, 255, 255, 0) 100%
            );
          `
        : null};
`;

export const VideoList = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: center;
  width: 100%;
`;

export const PdfList = styled(Flex)`
  overflow-x: auto;
  overflow-y: hidden;
  justify-content: flex-start;
  width: 100%;
`;

export const DropZoneSubTitle = styled(Box)`
  font-weight: 600;
  line-height: normal;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.1px;

  color: #8b93a6;
`;

export const MoreMenu = styled(Flex)<{ $visible?: boolean }>`
  position: absolute;
  top: 50px;
  left: 165px;
  padding: 10px;
  width: 250px;
  background: #ffffff;
  border: 1px solid #eaebeb;
  border-radius: 8px;
  display: ${props => (props.$visible ? "block" : "none")};
`;

export const MoreMenuButton = styled(Flex)`
  cursor: pointer;
  :hover {
    background-color: #f2f2f2;
  }
`;

export const PostAttachment = styled(Box)<{ $inProMode?: boolean }>`
  ${props =>
    props.$inProMode &&
    css`
      max-width: 550px;
      margin-bottom: -6px;
    `}
`;

const toolbarIcons = {
  image: IconAttachImage,
  video: IconAttachVideo
};

export const AttachmentToolbarButton = styled(Box)<{$icon: string; $active?: boolean}>`
  height: 40px;
  width: 40px;
  background: url("${props => toolbarIcons[props.$icon]}") no-repeat center
    center;
  background-color: ${props => (props.$active ? "#d8d9da" : "#f1f2f3")};
  background-size: 24px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;

  &:hover {
    background-color: #d8d9da;
  }
`;

import React from "react";
import { Flex, Box, Text } from "rebass/styled-components";
import Button from "../common/Button";
import ProgressBar from "./ProgressBar";
import { Display01 } from "../common/styles";
import { colors } from "../../theme";

const WizardContainer = ({
  header,
  steps = {},
  activeStep,
  title,
  description,
  children,
  onNext,
  onBack,
  onExit,
  exitButtonText,
  nextButtonText
}) => {
  return (
    <Flex minHeight="100vh" bg={colors.background2} flexDirection="column">
      {header && (
        <Flex
          px={20}
          py={15}
          justifyContent="space-between"
          alignItems="center"
          bg={colors.background1}
          sx={{
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.13);",
            position: "sticky",
            top: 0
          }}
        >
          {header}
          <Box>
            {onExit && (
              <Button variant="tertiary" onClick={onExit}>
                {exitButtonText || "Save & exit"}
              </Button>
            )}
          </Box>
        </Flex>
      )}
      <Flex flex={1} flexDirection="column" alignItems="center">
        <Flex
          maxWidth={title || description ? 526 : 1384}
          flexDirection="column"
          width={1}
        >
          {title && <Display01>{title}</Display01>}
          {description && (
            <Text as="p" mt={10} mb={20}>
              {description}
            </Text>
          )}

          {children}
        </Flex>
      </Flex>
      <Flex
        px={20}
        py={15}
        justifyContent="space-between"
        alignItems="center"
        bg={colors.background1}
        sx={{
          boxShadow: "2px 0 0 1px rgba(0, 0, 0, 0.13);",
          position: "sticky",
          bottom: 0
        }}
      >
        <Box>
          {onBack && (
            <Button variant="secondary" onClick={onBack}>
              Back
            </Button>
          )}
        </Box>
        <ProgressBar steps={steps} activeStep={activeStep} />
        <Box>
          {onNext && (
            <Button variant="primary" onClick={onNext}>
              {nextButtonText}
            </Button>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default WizardContainer;

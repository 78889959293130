import styled from "styled-components";

import { Headline6, Paragraph } from "components/common/styles";
import breadcrumbsDivider from "assets/images/breadcrumbs_divider.svg";

export const PaymentDetailsContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1 0 0;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 10px;
  gap: 20px;
  background-color: ${props => props.theme.colors.white};

  > span.quote_deadline {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    color: #757575;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 125%;
  }
`;

export const CompanyLogos = styled.div`
  display: flex;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
`;

export const GridItem = styled.div`
  display: flex;
  width: 100%;
  max-width: calc(684px / 2);
  flex-direction: column;
  padding: 10px;
  gap: 10px;

  > p {
    color: #1f1f1f;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    text-transform: capitalize;

    &.subtitle {
      color: #a4a4a4;
    }
  }
`;

export const PaymentGrid = styled.div`
  width: 100%;
  display: grid;
  padding-block: 8px;
  padding-inline: min(99px, 10%);
  grid-row-gap: 20px;
  grid-template-columns: 1fr 1fr;

  ${GridItem} {
    &:nth-child(odd) {
      justify-self: flex-end;
    }

    &:nth-child(even) {
      text-align: right;
      justify-self: flex-start;
    }
  }
`;

export const GreetingContainer = styled.div`
  width: 100%;
  display: flex;
  padding-inline: min(99px, 10%);
  justify-content: center;
`;

export const Greeting = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  max-width: 684px;
  border: 1px solid ${props => props.theme.colors.grey04};

  ${Paragraph} {
    > b {
      font-weight: 700;
    }
  }
`;

export const BreadcrumbNumber = styled.span`
  width: 24px;
  height: 24px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.text04};

  > ${Headline6} {
    color: ${props => props.theme.colors.white};
  }
`;

export const Breadcrumb = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child)::after {
    display: inline-block;
    content: "";
    width: 6.25px;
    height: 10px;
    mask: url(${breadcrumbsDivider}) no-repeat 50% 50%;
    mask-size: contain;
    padding-inline: 24px;
    background-color: ${props => props.theme.colors.text04};
  }

  > ${Headline6} {
    margin-left: 8px;
    color: ${props => props.theme.colors.text03};
  }
`;

export const Breadcrumbs = styled.div<{ selectedStep: number }>`
  display: flex;
  align-items: center;
  padding-block: 30px;

  ${Breadcrumb} {
    &:nth-child(${props => props.selectedStep}) {
      ${BreadcrumbNumber} {
        background-color: ${props => props.theme.colors.text01};
      }

      > ${Headline6} {
        color: ${props => props.theme.colors.text01};
      }
    }
  }
`;

import React from "react";
import styled from "styled-components";
import { Box } from "rebass/styled-components";
import { Headline3, Paragraph } from "../../common/styles";

const WidgetContainer = styled(Box)`
  background-color: white;
  border-radius: 12px;
  box-shadow: ${props => props.theme.shadows.shadowXS};
`;

const StyledWidgetHeader = styled(Box)`
  display: grid;
  grid-template-rows: 24px 20px;
  grid-row-gap: 8px;
  padding: 16px;
`;

export function WidgetHeader({ title, description }) {
  return (
    <StyledWidgetHeader>
      <Headline3>{title}</Headline3>
      <Paragraph variant="light">{description}</Paragraph>
    </StyledWidgetHeader>
  );
}

export default WidgetContainer;

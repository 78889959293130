import React from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass";
import { closeModal } from "../../../state/actions/ModalActions";
import { EmojiModal } from "../EmojiModal";
import AvailableService from "../../channelConnect/services/AvailableService";
import Button from "../../common/Button";
import { connectChannel } from "../../../utils/channels";
import { ModalTitle, ModalBody } from "../styles";
import avatar from "../../../assets/images/avatar-ludwig.jpg";
import signature from "../../../assets/images/signature-ludwig.svg";

const ChannelReconnectModal = ({ closeModal, account, currentUser }) => {
  const channels = [...account.channels.filter(c => c.needsReconnection)];

  const handleChannelConnect = service => {
    connectChannel(account, service);
  };

  return (
    <EmojiModal maxWidth={840} title="" avatar={avatar} onClose={closeModal}>
      <Flex justifyContent="center">
        <img
          src={signature}
          width="253"
          height="21"
          alt="Ludwig Dumont, Founder Willow"
        />
      </Flex>
      <Box marginTop={48} marginBottom={24}>
        <ModalTitle>
          Thanks for signing up, {currentUser.firstName}!
          <br />
          We’re here to help.
        </ModalTitle>
        <Box>
          <ModalBody>Hi {currentUser.firstName},</ModalBody>
          <ModalBody>
            First and foremost, I'd like to personally welcome you to Willow. It
            means a lot that you decided to put your trust in us, and I can
            assure you that we'll do everything we can to keep earning that
            trust.{" "}
          </ModalBody>
          <ModalBody>
            Might there be a glitch or might you feel as if things are just not
            as you'd like them to be, then please know that you can always reach
            out to me. I'm here to help you in any way that I can!
          </ModalBody>
          <ModalBody>
            Really hope that you'll enjoy your Willow experience! <br />
            Here's to your social media success{" "}
            <span role="img" aria-label="cheers!">
              🥂
            </span>
          </ModalBody>
          <ModalBody>Ludwig</ModalBody>
        </Box>
        <Box width="100%" marginY={24}>
          {channels.map(channel => (
            <AvailableService
              key={channel.id}
              service={`${channel.service}_${channel.serviceType}`}
              channel={channel}
              status="toReconnect"
              onConnect={() =>
                handleChannelConnect(
                  `${channel.service}_${channel.serviceType}`
                )
              }
            />
          ))}
        </Box>
      </Box>
      <Button variant="primary" size="lg" onClick={closeModal}>
        Continue to Willow
      </Button>
    </EmojiModal>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(ChannelReconnectModal);

import React from "react";
import config from "../../config";
import { Box } from "rebass/styled-components";
import ChannelConnectionList from "../channelConnect/ChannelConnectionList";
import { connectChannel } from "../../utils/channels";
import { Form } from "../common/form/styles";
import Button from "../common/Button";

const OnboardingChannelForm = ({ onSkip, account, state }) => {
  const handleConnectChannel = service => {
    connectChannel(
      account,
      service,
      `${config.rootUrl}/onboarding?accountId=${account.id}`
    );
  };

  if (!account) {
    return null;
  }

  return (
    <Form isTransitioned={true} state={state}>
      <div>
        <Box>
          <ChannelConnectionList
            account={account}
            onConnect={handleConnectChannel}
            currentView="onboarding"
          />
        </Box>
        <Box pt={16} pb={16}>
          {account.channels && account.channels.length > 0 ? (
            <Button onClick={onSkip} size="lg" variant="primary" width="100%">
              I'm done!
            </Button>
          ) : (
            <Button onClick={onSkip} size="lg" variant="secondary" width="100%">
              I'll do it later
            </Button>
          )}
        </Box>
      </div>
    </Form>
  );
};

export default OnboardingChannelForm;

import { useSelector } from "react-redux";
import { upperFirst } from "lodash/string";
import { Box } from "rebass/styled-components";
import React, { useRef, useState, useEffect } from "react";

import {
  PillButton,
  ChannelIcon,
  CaptionAreaWrapper,
  CustomizedCaptionTabs
} from "./styles";
import Caption from "./caption";
import { useAppState } from "contextApi/appContext";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import { SystemMessage } from "components/composerv2/styles";

const Captions = () => {
  const account = useSelector(state => state.account.data);
  const tags = useComposerState(state => state.tags);
  const postData = useComposerState(state => state.postData);
  const inProMode = useComposerState(state => state.inProMode);
  const inSplitMode = useComposerState(state => state.inSplitMode);
  const attachments = useComposerState(state => state.attachments);
  const editorStateObj = useComposerState(state => state.editorStateObj);
  const visibleCaption = useComposerState(state => state.visibleCaption);
  const validationErrors = useComposerState(state => state.validationErrors);
  const chosenSuggestedCaption = useComposerState(
    state => state.chosenSuggestedCaption
  );
  const showWillowAIRevamp = useAppState(state => state.showWillowAIRevamp);

  const scrapeUrl = useComposerActions(actions => actions.scrapeUrl);
  const setEditorStateObj = useComposerActions(
    actions => actions.setEditorStateObj
  );
  const getEnabledServices = useComposerActions(
    actions => actions.getEnabledServices
  );
  const onCaptionChange = useComposerActions(
    actions => actions.onCaptionChange
  );
  const splitEditor = useComposerActions(actions => actions.splitEditor);
  const setVisibleCaption = useComposerActions(
    actions => actions.setVisibleCaption
  );
  const setValidationErrors = useComposerActions(
    actions => actions.setValidationErrors
  );

  const [wasCaptionUsed, setWasCaptionUsed] = useState(false);
  const prevEnabledServicesRef = useRef();
  const enabledServices = getEnabledServices();
  const channelAttachments = attachments["all"];

  useEffect(() => {
    prevEnabledServicesRef.current = enabledServices;
  });
  const prevEnabledServices = prevEnabledServicesRef.current;

  useEffect(() => {
    if (
      prevEnabledServices &&
      prevEnabledServices.length !== enabledServices.length &&
      visibleCaption !== "all"
    ) {
      setVisibleCaption(enabledServices[0]);
    }
  }, [prevEnabledServices, enabledServices, visibleCaption, setVisibleCaption]);

  const enabledCaptions = visibleCaption === "all" ? ["all"] : enabledServices;

  const setDefaultEditorState = newState => {
    setEditorStateObj({
      ...editorStateObj,
      ...newState
    });
  };

  const onCaptionUpdate = (text, service, validationErrors) => {
    setValidationErrors(validationErrors);
    onCaptionChange(text, service);
  };

  const enabledChannels = account.channels.filter(ch =>
    postData.channels.includes(ch.id)
  );

  return (
    <CaptionAreaWrapper>
      {visibleCaption !== "all" && (
        <CustomizedCaptionTabs>
          {enabledServices.map(service => {
            return (
              <PillButton
                key={service}
                isActive={visibleCaption === service}
                onClick={() => setVisibleCaption(service)}
              >
                <div className="button-content">
                  <ChannelIcon channel={service} />
                  {visibleCaption === service && (
                    <span className="service-name">{upperFirst(service)}</span>
                  )}
                </div>
              </PillButton>
            );
          })}
        </CustomizedCaptionTabs>
      )}

      {enabledCaptions.map(service => {
        const enabledServiceChannels = enabledChannels.filter(
          ch => ch.service === service
        );

        return (
          <Caption
            key={service}
            post={postData}
            service={service}
            availableTags={tags}
            inProMode={inProMode || showWillowAIRevamp}
            wasSplit={inSplitMode}
            accountId={account.id}
            onUrlAdded={scrapeUrl}
            splitEditor={splitEditor}
            wasCaptionUsed={wasCaptionUsed}
            enabledServices={enabledServices}
            onCaptionChange={onCaptionUpdate}
            visible={visibleCaption === service}
            setWasCaptionUsed={setWasCaptionUsed}
            captions={postData.metaData?.captions ?? []}
            defaultEditorState={editorStateObj[service]}
            setDefaultEditorState={setDefaultEditorState}
            imageAttachments={
              channelAttachments.type === "photo"
                ? channelAttachments.photoAttachments.attachments ?? []
                : []
            }
            pdfAttachments={
              channelAttachments.type === "pdf"
                ? channelAttachments.pdfAttachments.attachments ?? []
                : []
            }
            chosenSuggestedCaption={chosenSuggestedCaption[service]}
            willowAiEnabled={!account?.isTelenetCustomer && showWillowAIRevamp}
            hasLinkedinV1Connections={
              (account?.channels ?? []).findIndex(
                c =>
                  c.service === "linkedin" &&
                  !(c.linkedinVersion ?? "").includes("v2")
              ) > -1
            }
            serviceChannels={enabledServiceChannels}
          />
        );
      })}

      {!!validationErrors.charLimit && (
        <Box mt={12}>
          <SystemMessage
            type="error"
            service={validationErrors.charLimit.service}
          >
            <span>
              Your caption has{" "}
              <em>
                {validationErrors.charLimit.characterCount -
                  validationErrors.charLimit.characterLimit}
              </em>{" "}
              characters too much for{" "}
              {upperFirst(validationErrors.charLimit.service)}
            </span>
          </SystemMessage>
        </Box>
      )}

      {!!validationErrors.tagLimit && (
        <Box mt={12}>
          <SystemMessage type="error">
            <span>
              Your caption has{" "}
              <em>
                {validationErrors.tagLimit.tagCount -
                  validationErrors.tagLimit.tagLimit}
              </em>{" "}
              tags too much
            </span>
          </SystemMessage>
        </Box>
      )}
    </CaptionAreaWrapper>
  );
};

export default Captions;

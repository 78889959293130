import { differenceInMinutes } from "date-fns";
import * as storageService from "utils/storage";
import * as sessionStorageService from "utils/sessionStorage";

import { Status, KEY_ALERT_MODAL } from "../types/ModalStatus";

export interface IAlertData {
  id: string;
  status: string;
  holdTill?: Date;
}

export function getModalStatus(id: string, persist: boolean = false) {
  const alertData: Array<IAlertData> | unknown = persist
    ? sessionStorageService.get(KEY_ALERT_MODAL)
    : storageService.get(KEY_ALERT_MODAL);

  if (alertData) {
    const kw = (alertData as Array<IAlertData>).find(
      (data: IAlertData) => data.id === id
    );

    if (!kw) {
      return {
        status: Status.INCOMPLETE,
        isPaused: false
      };
    } else if (kw.status === Status.COMPLETE) {
      return {
        status: Status.COMPLETE,
        isPaused: true
      };
    } else if (kw.status === Status.INCOMPLETE && kw.holdTill) {
      return differenceInMinutes(new Date(), new Date(kw.holdTill)) > 0
        ? {
            status: Status.INCOMPLETE,
            isPaused: false
          }
        : {
            status: Status.INCOMPLETE,
            isPaused: true
          };
    }
  } else
    return {
      status: Status.INCOMPLETE,
      isPaused: false
    };
}

// @ts-nocheck
import _ from "lodash";
import { useToaster } from "@hellocontento/maillard";
import React, { useEffect, useRef, useState } from "react";

import {
  ungroupPost,
  postToDraft,
  approveDraft,
  postGroupToDraft
} from "services/post";
import PostMenu from "./PostMenu";
import TaskMenu from "./TaskMenu";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import DraftMenu from "./DraftMenu";
import StuckPostMenu from "./StuckPostMenu";
import PostGroupMenu from "./PostGroupMenu";
import { POST_STATUS } from "constants/post";
import Popper from "components/common/Popper";
import PublishedPostMenu from "./PublishedPostMenu";
import CalendarEventMenu from "./CalendarEventMenu";
import TASK_TYPES from "components/schedule/constants/taskType";
import { openPostOnChannel, getExternalPostUrl } from "utils/channels";
import { PostContextMenuButton, PostContextMenuWrapper } from "./styles";
import contentTypes from "components/common/contentTypes/data/content-types";

interface IPostContextMenu {
  entry: any;
  closeMenu: VoidFunction;
}

const PostContextMenu: React.FC<IPostContextMenu> = ({ entry, closeMenu }) => {
  const addToast = useToaster();
  const onReloadEvent = useComposerState(state => state.events.onReloadEvent);
  const openPublishedPostInComposer = useComposerActions(
    actions => actions.openPublishedPostInComposer
  );
  const openPost = useComposerActions(actions => actions.openPost);
  const editTask = useComposerActions(actions => actions.editTask);
  const deleteEntry = useComposerActions(actions => actions.deleteEntry);
  const createPostByCalendarEvent = useComposerActions(
    actions => actions.createPostByCalendarEvent
  );
  const createPostByTask = useComposerActions(
    actions => actions.createPostByTask
  );

  const handleEditEntry = () => {
    closeMenu();
    openPost(entry.post);
  };

  const handleUngroupPost = async () => {
    closeMenu();

    try {
      const successToast = await ungroupPost(entry.post.id);
      addToast(successToast, "success");
      onReloadEvent.dispatch();
    } catch (error) {
      addToast(error.message, "error");
    }
  };

  const handleApproveDraft = async () => {
    closeMenu();

    try {
      const successToast = await approveDraft(entry.post);
      addToast(successToast, "success");
      onReloadEvent.dispatch();
    } catch (error) {
      addToast(error.message, "error");
    }
  };

  const handleDuplicateEntry = async () => {
    closeMenu();

    const { post } = entry;

    openPublishedPostInComposer(post);
  };

  const handleSharePost = async () => {
    closeMenu();
  };

  const handlePostToDraft = async () => {
    closeMenu();

    try {
      const successToast = entry.post.isPostGroup
        ? await postGroupToDraft(entry.post.id)
        : await postToDraft(entry.post.id);
      addToast(successToast, "success");
      onReloadEvent.dispatch();
    } catch (error) {
      addToast(error.message, "error");
    }
  };

  const handleDeleteEntry = async () => {
    closeMenu();

    if (deleteEntry) {
      deleteEntry(entry);
    }
  };

  const handleVisitPost = () => {
    closeMenu();
    openPostOnChannel(entry.post);
  };

  const handleCopyLink = () => {
    closeMenu();
    const url = getExternalPostUrl(entry.post);

    if (url) {
      navigator.clipboard.writeText(url);
      addToast("Copied url to clipboard.", "success");
    } else {
      addToast("Could not copy url.", "error");
    }
  };

  const handleWritePostFromTask = () => {
    closeMenu();
    createPostByTask(entry.task);
  };

  const handleWriteDraftFromTask = () => {
    closeMenu();
    createPostByTask(entry.task, true);
  };

  const handleEditTask = () => {
    closeMenu();
    editTask(entry.task, TASK_TYPES.INSTANCE);
  };

  const handleEditTaskSeries = () => {
    closeMenu();
    editTask(entry.task, TASK_TYPES.SERIES);
  };

  const handleWritePostFromCalendarEvent = () => {
    closeMenu();
    createPostByCalendarEvent(entry);
  };

  const handleWriteDraftFromCalendarEvent = () => {
    closeMenu();
    createPostByCalendarEvent(entry, true);
  };

  return (
    <div onClick={e => e.stopPropagation()}>
      {entry.calendarEvent ? (
        <CalendarEventMenu
          writePost={handleWritePostFromCalendarEvent}
          writeDraft={handleWriteDraftFromCalendarEvent}
        />
      ) : entry.task ? (
        <TaskMenu
          editTask={handleEditTask}
          deleteTask={handleDeleteEntry}
          writePost={handleWritePostFromTask}
          writeDraft={handleWriteDraftFromTask}
          editTaskSeries={handleEditTaskSeries}
          isTaskSeries={!!entry.task.taskGroupId}
        />
      ) : entry.post.isPostGroup ? (
        <PostGroupMenu
          editPost={handleEditEntry}
          deletePost={handleDeleteEntry}
          ungroupPost={handleUngroupPost}
          duplicatePost={handleDuplicateEntry}
          postGroupToDraft={handlePostToDraft}
        />
      ) : entry.post.isDraft ? (
        <DraftMenu
          editDraft={handleEditEntry}
          deleteDraft={handleDeleteEntry}
          duplicateDraft={handleDuplicateEntry}
          approveDraft={
            !!entry.post.scheduledAt ? handleApproveDraft : handleEditEntry
          }
        />
      ) : entry.post.status === POST_STATUS.SENT ? (
        <PublishedPostMenu
          copyLink={handleCopyLink}
          sharePost={handleSharePost}
          visitPost={handleVisitPost}
          deletePost={handleDeleteEntry}
          duplicatePost={handleDuplicateEntry}
        />
      ) : entry.post.isStuck ? (
        <StuckPostMenu
          deletePost={handleDeleteEntry}
          reschedulePost={handleEditEntry}
        />
      ) : (
        <PostMenu
          editPost={handleEditEntry}
          deletePost={handleDeleteEntry}
          postToDraft={handlePostToDraft}
          duplicatePost={handleDuplicateEntry}
        />
      )}
    </div>
  );
};

interface IRightClickPostContextMenu extends IPostContextMenu {
  contextMenuPos: number[];
}

export const RightClickPostContextMenu: React.FC<
  IRightClickPostContextMenu
> = ({ contextMenuPos, ...rest }) => {
  const { closeMenu } = rest;

  const createVirtualReference = ({ clientX = 0, clientY = 0 }) => ({
    getBoundingClientRect() {
      return {
        top: clientY,
        bottom: clientY,
        left: clientX,
        right: clientX,
        width: 0,
        height: 0
      };
    }
  });

  return (
    <Popper
      usePortal={true}
      onClose={closeMenu}
      placement="bottom-start"
      visible={!!contextMenuPos.length}
      referenceElement={createVirtualReference(
        contextMenuPos.length
          ? { clientX: contextMenuPos[0], clientY: contextMenuPos[1] }
          : {}
      )}
    >
      <PostContextMenu {...rest} />
    </Popper>
  );
};

interface IPostContextMenuWithButton
  extends Omit<IPostContextMenu, "closeMenu"> {
  contextWithPortal: boolean;
}

export const PostContextMenuWithButton: React.FC<
  IPostContextMenuWithButton
> = ({ contextWithPortal, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);

  const closeMenu = () => {
    setShowMenu(false);
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const handleMouseEnter = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (e.currentTarget === ref.current) {
      setIsButtonVisible(true);
    }
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (e.currentTarget === ref.current) {
      setIsButtonVisible(false);
    }
  };

  useEffect(() => {
    // Close menu when the button is not visible
    const isVisible = ref.current
      ? window.getComputedStyle(ref.current).getPropertyValue("display") ===
        "none"
        ? false
        : true
      : false;

    if (!isVisible) {
      closeMenu();
      setIsButtonVisible(false);
    }
  });

  return (
    <PostContextMenuWrapper
      ref={ref}
      isVisible={isButtonVisible}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <PostContextMenuButton
        size={32}
        iconSize={20}
        variant="secondary"
        onClick={handleButtonClick}
        icon={() => <i className="icon-dots" />}
      />
      <Popper
        offset={[0, 8]}
        visible={showMenu}
        onClose={closeMenu}
        placement="bottom-start"
        exceptions={[ref.current]}
        usePortal={contextWithPortal}
        referenceElement={ref.current}
      >
        <PostContextMenu {...rest} closeMenu={closeMenu} />
      </Popper>
    </PostContextMenuWrapper>
  );
};

import store from "state/store";
import { callApi } from "utils/ContentoApi";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

import {
  saveSections,
  followSection,
  unfollowSection
} from "state/actions/ContentActions";
import { IExternSourceRequest } from "./news.types";
import { openModal } from "state/actions/ModalActions";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import { IComputedContent } from "@hellocontento/contento-common";
import { PostDraftDTO } from "types/post.types";
import { createDraft } from "./post";

export interface ITopicParent {
  id: string;
  language: string;
  image: string;
  title: string;
  description: string;
  keywords: Array<string>;
  isFollowed: boolean;
}

export interface ITopic extends ITopicParent {
  parent?: ITopicParent;
}

export interface ISource {
  id: string;
  title: string;
  type: string;
  image: string;
  url: string;
  handle: string;
  isVerified: boolean;
  isFollowed: boolean;
}

export interface IExternalTwitterSource {
  handle: string;
  image: string;
  isFollowed: boolean;
  isVerified: boolean;
  title: string;
  type: string;
}

export interface IDomain {
  id: string;
  domain: string;
  tld: string;
  title: string;
  image: string;
  isFollowed: boolean;
}

export interface IKeyword {
  id: string;
  isFollowed: true;
  name: string;
  rules: any;
  accountId: string;
  createdAt: string;
  updatedAt: string;
  type?: string;
}

export type IFeed =
  | { type: "topics"; detail: ITopic }
  | { type: "sources"; detail: ISource }
  | { type: "domains"; detail: IDomain }
  | { type: "keywords"; detail: IKeyword };

export interface ISectionData {
  type: string;
  details: IFeed;
}

const TOPICS_LIST = ["topics"];
const SOURCES_LIST = ["domains", "sources", "keywords"];

export enum NewsEvent {
  FOLLOWED = "Followed",
  UNFOLLOWED = "Unfollowed",
  LIKED = "Liked Content",
  DISLIKED = "Disliked Content",
  BOOKMARKED = "Article Bookmarked",
  BOOKMARK_REMOVED = "Article Bookmark Removed"
}

function trackingObject({ type, detail }: IFeed, external?: boolean) {
  const sourceType =
    type === "topics"
      ? "topic"
      : "domain" in detail
        ? "domain"
        : (detail as ISource).type;

  return {
    sourceType,
    title: "rules" in detail ? detail.name : detail.title,
    id: detail.id,
    ...(external ? { external } : {}),
    ...(sourceType === "rss" ? { url: (detail as ISource).url } : {}),
    ...(sourceType === "twitter" ? { handle: (detail as ISource).handle } : {}),
    ...(sourceType === "domain" ? { url: (detail as IDomain).domain } : {}),
    ...(sourceType === "keyword" ? { rule: (detail as IKeyword).rules } : {})
  };
}

function mapSection(data: Array<IFeed>) {
  const topics = data
    .filter((item: IFeed) => TOPICS_LIST.includes(item.type))
    .map((item: IFeed) => item.detail);

  const sources = data
    .filter((item: IFeed) => SOURCES_LIST.includes(item.type))
    .map((item: IFeed) => {
      if (item.type === "keywords") {
        return {
          ...item.detail,
          type: item.type,
          title: (item.detail as IKeyword).name
        };
      } else return item.detail;
    });

  return [
    {
      type: "topics",
      details: topics
    },
    {
      type: "sources",
      details: sources
    }
  ];
}

export const fetchSections = async (): Promise<Array<any>> => {
  try {
    const account = store.getState().account.data;
    const sections = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/sections`
    });
    const mapped = mapSection(sections);
    store.dispatch(saveSections(mapped));
    return mapped;
  } catch (error) {
    throw new Error("There was an error when fetching the content sections.");
  }
};

export const unfollow = async (
  sectionType: string,
  sectionId: string
): Promise<any> => {
  try {
    const account = store.getState().account.data;

    const data = await callApi({
      method: "delete",
      url: `/accounts/${account.id}/news/sections/${sectionType}/${sectionId}`
    });
    store.dispatch(unfollowSection({ type: sectionType, id: sectionId }));

    store.dispatch(
      trackAnalyticsEvent(NewsEvent.UNFOLLOWED, trackingObject(data))
    );

    return data;
  } catch (error) {
    throw new Error(
      `There was an error when unfollowing the ${sectionType.toLowerCase()}.`
    );
  }
};

export const follow = async (
  sectionType: string,
  sectionId: string
): Promise<any> => {
  try {
    const account = store.getState().account.data;

    const data = await callApi({
      method: "post",
      url: `/accounts/${account.id}/news/sections/${sectionType}/${sectionId}`
    });
    store.dispatch(followSection(data));

    store.dispatch(
      trackAnalyticsEvent(NewsEvent.FOLLOWED, trackingObject(data))
    );

    store.dispatch(
      openModal("ONBOARDING_INFO_MODAL", {
        id: OnboardingStepsEnum.FOLLOW_SECTION,
        triggeredBy: OnboardingStepsEnum.FOLLOW_SECTION
      })
    );

    return data;
  } catch (error) {
    throw new Error(
      `There was an error when following the ${sectionType.toLowerCase()}.`
    );
  }
};

export const followExternalSources = async (
  req: IExternSourceRequest
): Promise<any> => {
  try {
    const account = store.getState().account.data;

    const data = await callApi({
      method: "post",
      url: `/accounts/${account.id}/news/sections/sources`,
      data: req
    });

    store.dispatch(followSection({ type: "sources", detail: data }));
    store.dispatch(
      trackAnalyticsEvent(
        NewsEvent.FOLLOWED,
        trackingObject({ type: "sources", detail: data }, "image" in req)
      )
    );

    store.dispatch(
      openModal("ONBOARDING_INFO_MODAL", {
        id: OnboardingStepsEnum.FOLLOW_SECTION,
        triggeredBy: OnboardingStepsEnum.FOLLOW_SECTION
      })
    );

    return data;
  } catch (error) {
    throw new Error(`There was an error when following the X profile.`);
  }
};

export const reorderSection = async (
  sourceType: string,
  sourceId: string,
  afterId: string
): Promise<any> => {
  try {
    const account = store.getState().account.data;

    const data = await callApi({
      method: "PUT",
      url: `/accounts/${account.id}/news/sections/${sourceType}/${sourceId}/order`,
      data: {
        insertBeforeSectionId: afterId
      }
    });

    return data;
  } catch (error) {
    throw new Error(
      `There was an error when reordering the ${sourceType.toLowerCase()}.`
    );
  }
};

export const fetchSectionById = async (
  sectionType: string,
  sectionId: string
): Promise<Array<any>> => {
  try {
    const account = store.getState().account.data;
    const section = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/sections/${sectionType}/${sectionId}`
    });
    return section;
  } catch (error) {
    throw new Error(`There was an error when fetching contents.`);
  }
};

export const getRecommendedTopics = async (): Promise<Array<ITopic>> => {
  try {
    const account = store.getState().account.data;
    const topics = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/sections/topics/recommended`
    });

    return topics;
  } catch (error) {
    throw new Error(`There was an error when fetching recommended topics.`);
  }
};

export const getRecommendedDomains = async (): Promise<Array<IDomain>> => {
  try {
    const account = store.getState().account.data;
    const domains = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/sections/domains/recommended`
    });

    return domains;
  } catch (error) {
    throw new Error(`There was an error when fetching recommended domains.`);
  }
};

export const getRecommendedTwitterAccounts = async (): Promise<Array<any>> => {
  try {
    const account = store.getState().account.data;
    const twitterAccounts = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/sections/sources/twitter/recommended`
    });

    return twitterAccounts;
  } catch (error) {
    throw new Error(`There was an error when fetching X accounts.`);
  }
};

export const likeContent = async (sectionId: string) => {
  try {
    const account = store.getState().account.data;
    const content = await callApi({
      method: "post",
      url: `/accounts/${account.id}/contents/like/${sectionId}`
    });

    store.dispatch(
      trackAnalyticsEvent("Article Voted On", {
        voteType: "Liked",
        sectionId
      })
    );

    return content;
  } catch (error) {
    throw new Error(`There was an error when liking the content.`);
  }
};

export const unlikeContent = async (sectionId: string) => {
  try {
    const account = store.getState().account.data;
    const content = await callApi({
      method: "delete",
      url: `/accounts/${account.id}/contents/like/${sectionId}`
    });

    store.dispatch(
      trackAnalyticsEvent("Article Voted On", {
        voteType: "Unliked",
        sectionId
      })
    );

    return content;
  } catch (error) {
    throw new Error(`There was an error when unliking the content.`);
  }
};

export const likeCounts = async () => {
  try {
    const account = store.getState().account.data;
    const content = await callApi({
      method: "get",
      url: `/accounts/${account.id}/contents/liked`
    });

    return content;
  } catch (error) {
    throw new Error(`There was an error when fetching the liked content.`);
  }
};

export interface ISearchContentParams {
  text: string;
  limit?: number;
  page?: number;
  language: string;
  from?: string;
  to?: string;
  tags?: string;
}

export const searchArticlesByOptions = async (params: ISearchContentParams) => {
  const account = store.getState().account.data;
  try {
    const results = await callApi({
      method: "get",
      url: `/accounts/${account.id}/contents/search`,
      params: { ...params }
    });

    return results;
  } catch (error) {
    throw new Error(`There was an error when searching for contents.`);
  }
};

export const sectionSearchCategories = {
  ALL: "all",
  TOPICS: "topics",
  TWITTER_SOURCES: "twitter-sources",
  RSS_SOURCES: "rss-sources",
  DOMAINS: "domains"
};

type SearchCategory = typeof sectionSearchCategories;

export interface SearchByCategoryParams {
  limit?: number;
  searchTerm: string;
}

export const searchByCategory = async (
  category: SearchCategory,
  params: SearchByCategoryParams
) => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/sections/search/${category}`,
      params: { ...params }
    });

    return results;
  } catch (error) {
    throw new Error(`There was an error when searching for contents.`);
  }
};

export const getFeedsByType = async (
  type: string,
  params?: { limit: number }
): Promise<Array<any>> => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/feeds/${type}`,
      params: { ...params }
    });

    return results;
  } catch (error) {
    throw new Error(`There was an error when fetching the feeds.`);
  }
};

export const getTopicsGroupedByParent = async (): Promise<Array<any>> => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/sections/topics`
    });

    return results;
  } catch (error) {
    throw new Error(`There was an error when fetching the feeds.`);
  }
};

interface IPaginationParams {
  page: number;
  limit: number;
}

export const getTopicById = async (id: string, params: IPaginationParams) => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/sections/topics/${id}`,
      params: { ...params }
    });

    return results;
  } catch (error) {
    throw new Error(`There was an error when fetching the contents.`);
  }
};

export const getSourceById = async (
  id: string,
  source: string,
  params: IPaginationParams
) => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/sections/${source}/${id}`,
      params: { ...params }
    });

    return results;
  } catch (error) {
    throw new Error(`There was an error when fetching the contents.`);
  }
};

export const quickSearchByCategory = async (params: SearchByCategoryParams) => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "get",
      url: `/accounts/${account.id}/news/sections/quick-search`,
      params: { ...params }
    });

    return results;
  } catch (error) {
    throw new Error(`There was an error when searching for contents.`);
  }
};

interface IBlockDomainData {
  domain: string;
  name?: string;
  image?: string;
}

export const blockDomain = async (domain: IBlockDomainData) => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "post",
      url: `/accounts/${account.id}/news/settings/blocked-domains`,
      data: {
        ...domain
      }
    });

    return results;
  } catch (error) {
    throw new Error(`There was an error muting the domains.`);
  }
};

export const saveContent = async (content: any, newsType) => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "post",
      url: `/accounts/${account.id}/contents/saved/${content.id}`
    });

    store.dispatch(
      trackAnalyticsEvent(NewsEvent.BOOKMARKED, {
        newsType,
        sourceType: content.sources[0].type
      })
    );

    return results;
  } catch (error) {
    throw new Error(`There was an error saving the content.`);
  }
};

export const unsaveContent = async (content: any, newsType: string) => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "delete",
      url: `/accounts/${account.id}/contents/saved/${content.id}`
    });

    store.dispatch(
      trackAnalyticsEvent(NewsEvent.BOOKMARK_REMOVED, {
        newsType,
        sourceType: content.sources[0].type
      })
    );

    return results;
  } catch (error) {
    throw new Error(`There was an error removing the content from saved.`);
  }
};

export const dismissContent = async (contentId: string, data: any) => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "delete",
      url: `/accounts/${account.id}/contents/${contentId}`,
      data: { ...data }
    });

    return results;
  } catch (error) {
    throw new Error(`There was an error reporting the content.`);
  }
};

export const requestForRecommendedContents = async () => {
  const account = store.getState().account.data;

  try {
    const results = await callApi({
      method: "get",
      url: `/accounts/${account.id}/refresh-recommendation`
    });

    return results;
  } catch (error) {
    throw new Error(`There was an error refreshing the recommendation.`);
  }
};

export const saveContentArticleToIdeas = async (content: IComputedContent) => {
  const account = store.getState().account.data;
  const activeChannelIds = account.channels
    .filter((c: any) => c.active && !c.needsReconnection)
    .map((c: any) => c.id);

  const draftData: PostDraftDTO = {
    contentTypeId: "article",
    contentId: content.id,
    caption: { all: "" },
    channels: activeChannelIds,
    attachment: {
      type: "article",
      url: content.url,
      title: content.title,
      domain: content.domain,
      image: content.thumbnail || (content as any).image || content.logo,
      description: content.description,
      favicon: content.favicon,
      publisher: content.publisher
    }
  };

  await createDraft(draftData, account.id);
};

import { Box } from "rebass";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import React, { useEffect, useState } from "react";
import {
  Headline6,
  PropertyField,
  PropertyTable
} from "components/common/styles";
import {
  ModalHeader,
  modalStyles,
  ModalDescription,
  ModalTitleWrapper
} from "components/modals/styles";
import Button from "components/common/Button";
import Popper from "components/common/Popper";

import { Actions, DatePickerWrapper } from "./styles";
import TimeSelect from "components/schedule/tasks/TimeSelect";
import { getDate, setDate, formatToFormalDate } from "utils/date";
import ScheduleButton from "components/schedule/form/ScheduleButton";
import TimelineDatePicker from "components/schedule/common/timelineDatePicker";
import { getDate as getDateFrom } from "components/schedule/common/timelineDatePicker/utils/dateUtils";

const SchedulerModal = ({
  post,
  account,
  onClose,
  pickedDate,
  setCustomDate
}) => {
  const { colors } = useTheme();
  const styles = modalStyles(420);
  styles.overlay.background = colors.backdrop;

  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  const [trigger, setTrigger] = useState(false);
  const [selectedDate, setSelectedDate] = useState(pickedDate);
  const [previewDate, setPreviewDate] = useState(
    getDateFrom({ date: selectedDate, timezone: account.timezone })
  );

  const onDateChange = date => {
    const option = getDate(date);
    const newDate = setDate(selectedDate, option);

    setSelectedDate(newDate);
    setTrigger(true);
  };

  const onTimeChange = ({ date }) => {
    setSelectedDate(new Date(date));
  };

  const handleSave = () => {
    setCustomDate(selectedDate);
    onClose();
  };

  /**
   * To trigger the tooltip to close manually,
   * we need to reset the tooltip value to false after every dismissal of the tooltip
   */
  useEffect(() => {
    if (trigger) {
      setTrigger(false);
    }
  }, [trigger]);

  return (
    <Modal
      isOpen={true}
      style={styles}
      ariaHideApp={false}
      onRequestClose={onClose}
    >
      <ModalHeader>
        <ModalTitleWrapper>
          <span>Set date & time</span>
        </ModalTitleWrapper>
      </ModalHeader>
      <ModalDescription>
        Select a date and time in the future for your post to be published.
      </ModalDescription>
      <PropertyTable>
        <PropertyField>
          <Headline6 $isLight>When</Headline6>
          <Box ref={setReferenceElement}>
            <ScheduleButton
              color="text01"
              variant="tertiary"
              icon="icon-calendar"
              onClick={toggleShowOptions}
            >
              {formatToFormalDate(selectedDate)}
            </ScheduleButton>
            <Popper
              referenceElement={referenceElement}
              visible={showOptions}
              exceptions={[referenceElement]}
              onClose={() => setShowOptions(false)}
            >
              <DatePickerWrapper>
                <TimelineDatePicker
                  inline
                  size="sm"
                  disablePastDate
                  account={account}
                  focusDate={selectedDate}
                  previewDate={previewDate}
                  onDateChange={date => {
                    onDateChange(date);
                    setShowOptions(false);
                  }}
                  onDatePreview={setPreviewDate}
                />
              </DatePickerWrapper>
            </Popper>
          </Box>
        </PropertyField>
        <PropertyField>
          <Headline6 $isLight>Time</Headline6>
          <Box>
            <TimeSelect
              settings={null}
              showInfo={false}
              date={selectedDate}
              onChange={onTimeChange}
              channels={account.channels}
              selectedChannels={post.channels}
            />
          </Box>
        </PropertyField>
      </PropertyTable>
      <Actions>
        <Button size="m" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button size="m" variant="primary" onClick={handleSave}>
          Save
        </Button>
      </Actions>
    </Modal>
  );
};

SchedulerModal.propTypes = {
  post: PropTypes.object,
  account: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  pickedDate: PropTypes.instanceOf(Date),
  setCustomDate: PropTypes.func.isRequired
};

export default SchedulerModal;

import { Flex } from "rebass/styled-components";
import React from "react";

const IdeaTypeItem: React.FC<{ filter: IdeaTypeFilter; active?: boolean, onClick: (filter: IdeaTypeFilter) => void }> = ({
  filter,
  active = false,
  onClick
}) => {
  return (
    <Flex
      padding={"6px 20px"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        background: active ? "white" : "inherit",
        color: active ? "black" : "#8F9095",
        border: "1px solid #606E801F",  
        borderRadius: "8px",
        boxShadow: "0px 2px 6px 0px #0000000D",
        cursor: "pointer"
      }}
      onClick={() => onClick(filter)}
    >
      {filter}
    </Flex>
  );
};

export enum IdeaTypeFilter {
    All = "All",
    Links= "With Links",
    Images= "With Images",
    Videos = "With Videos",
    PDFs = "With PDFs"
}

const IdeaTypeFilters: React.FC<{
  currFilter?: IdeaTypeFilter;
  onChange: (filter: IdeaTypeFilter) => void;
}> = ({ currFilter = IdeaTypeFilter.All, onChange }) => {
  return (
    <Flex
      padding="6px 10px"
      sx={{
        borderRadius: "10px",
        gap: "10px",
        background: "#F8F8F8",
        border: "1px solid #606E801F"
      }}
    >
      {Object.values(IdeaTypeFilter).map(filter => (
        <IdeaTypeItem key={filter} filter={filter} active={filter === currFilter} onClick={onChange}/>
      ))}
    </Flex>
  );
};

export default IdeaTypeFilters;

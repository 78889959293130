import * as modalActions from "../actions/ModalActions";

const initialState = {
  modalType: null,
  modalProps: {},
  isOpen: false
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case modalActions.OPEN_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
        isOpen: true
      };
    case modalActions.CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export default modalReducer;

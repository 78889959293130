import _ from "lodash";
// @ts-ignore
import moment from "moment-timezone";

import store from "state/store";
import { callApi } from "utils/ContentoApi";
import {
  PostType,
  UpdateTimelineActivitiesPayload
} from "components/schedule/timeline/context";
import { ACTIVITY_TYPE } from "components/schedule/constants/activityTypes";
import { getDateLabel, today, addDuration, subtractDuration } from "utils/date";

export const structureActivityData = (activity: any, account: any) => {
  const momentTZ = (date: Date | string) => {
    return moment.tz(date, account.timezone);
  };

  const type =
    activity.type === ACTIVITY_TYPE.TASK
      ? ACTIVITY_TYPE.TASK
      : ACTIVITY_TYPE.POST;

  if (type === ACTIVITY_TYPE.TASK) {
    const task = {
      ...activity.task,
      isPhantom: activity.isPhantom
    };
    const date = momentTZ(task.date);

    return {
      id: `slot-${task.id}`,
      cardId: task.id,
      type: ACTIVITY_TYPE.TASK,
      dateTime: date,
      time: date.format("HH:mm"),
      channels: [...task.channels],
      task: task,
      isPast: !date.isAfter(today()),
      isPhantom: task.isPhantom
    };
  } else {
    const post = (() => {
      if (activity.type === ACTIVITY_TYPE.POST) {
        return {
          ...activity.post,
          isPhantom: activity.isPhantom
        };
      } else if (
        activity.type === ACTIVITY_TYPE.DRAFT_POST &&
        activity.draft.scheduledAt !== null
      ) {
        return {
          ...activity.draft,
          isPhantom: activity.isPhantom,
          isDraft: true
        };
      } else if (activity.type === ACTIVITY_TYPE.POST_GROUP) {
        return {
          ...activity.group,
          isPostGroup: true,
          isPhantom: activity.isPhantom
        };
      }
    })();

    let date =
      post.status === "SENT"
        ? momentTZ(post.postedAt)
        : momentTZ(post.scheduledAt);
    let picture;
    if (post.attachment) {
      if (post.attachment.type === "photo") {
        picture = _.isArray(post.attachment.url)
          ? post.attachment.url[0]
          : post.attachment.url;
      } else if (post.attachment.type === "article") {
        picture = post.attachment.image;
      }
    }

    return {
      id: `post-${post.id}`,
      cardId: post.id,
      type: post.isDraft
        ? ACTIVITY_TYPE.DRAFT_POST
        : `POST${post.channels?.length > 1 ? "_GROUP" : ""}`,
      dateTime: date,
      time: date.format("HH:mm"),
      channels:
        post.isDraft || post.isPostGroup ? post.channels : [post.channel],
      text:
        post.isDraft || post.isPostGroup
          ? Object.values(post.caption).sort((a: any, b: any) => {
              return b.length - a.length;
            })[0]
          : post.caption,
      picture: picture,
      title: post.attachment ? post.attachment.title : undefined,
      post: post,
      isPhantom: post.isPhantom
    };
  }
};

export const fetchTimelineActivities = async (
  postType: PostType,
  params: any
): Promise<UpdateTimelineActivitiesPayload> => {
  const account = store.getState().account.data;

  let feedName = "calendar";
  if (postType === "scheduled") {
    feedName = "scheduled-timeline";
  } else if (postType === "published") {
    feedName = "posted-timeline";
  } else if (postType === "drafts") {
    feedName = "draft-timeline";
  }

  try {
    const entries = await callApi({
      method: "get",
      url: `/accounts/${account.id}/activities/${feedName}`,
      params
    });

    const dateLabel = getDateLabel(params.fromDate);

    return Promise.resolve({
      postType: postType,
      entries,
      lastUpdated: today(),
      month: dateLabel.slice(0, 7)
    });
  } catch (error) {
    console.error(error);
    throw new Error("Posts could not be fetched.");
  }
};

export const fetchDashboardActivities = async (): Promise<any[]> => {
  const account = store.getState().account.data;
  const currentDate = today();

  try {
    const entries = await callApi({
      method: "get",
      url: `/accounts/${account.id}/activities/calendar`,
      params: {
        fromDate: currentDate,
        toDate: addDuration(currentDate, { days: 14 })
      }
    });

    const scheduled = await callApi({
      method: "get",
      url: `/accounts/${account.id}/activities/scheduled-timeline`,
      params: {
        fromDate: currentDate,
        toDate: addDuration(currentDate, { years: 1 })
      }
    });

    const published = await callApi({
      method: "get",
      url: `/accounts/${account.id}/activities/posted-timeline`,
      params: {
        fromDate: subtractDuration(currentDate, { days: 30 }),
        toDate: currentDate
      }
    });

    const structuredEntries = [
      ...entries.filter(
        (entry: any) =>
          entry.type === ACTIVITY_TYPE.TASK ||
          (entry.type === ACTIVITY_TYPE.DRAFT_POST &&
            !!entry.draft?.scheduledAt)
      ),
      ...scheduled.filter(
        (entry: any) =>
          entry.type === ACTIVITY_TYPE.POST ||
          entry.type === ACTIVITY_TYPE.POST_GROUP
      ),
      ..._.reverse(published)
    ]
      .map((entry: any) => structureActivityData(entry, account))
      .sort((a, b) => {
        const currentItemDate = a.task ? a.task.date : a.post.scheduledAt;
        const nextItemDate = b.task ? b.task.date : b.post.scheduledAt;
        return Date.parse(currentItemDate) - Date.parse(nextItemDate);
      });

    return Promise.resolve(structuredEntries);
  } catch (error) {
    console.error(error);
    throw new Error("Posts could not be fetched.");
  }
};

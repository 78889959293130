import React, { useRef, useState } from "react";

import Tooltip from "./Tooltip";
import { ContentMixBlock } from "../styles";
import Popper from "components/common/Popper";

interface IContentMixItem {
  ratio: number;
  metaData: any;
  contentType: string;
}

const ContentMixItem: React.FC<IContentMixItem> = ({
  ratio,
  metaData,
  contentType
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (e.currentTarget === ref.current) {
      setShowTooltip(true);
    }
  };

  const handleMouseLeave = (e: React.MouseEvent) => {
    e.stopPropagation();

    if (e.currentTarget === ref.current) {
      setShowTooltip(false);
    }
  };

  return (
    <ContentMixBlock
      ref={ref}
      ratio={ratio}
      contentType={contentType}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Popper
        offset={[0, 8]}
        placement="top"
        usePortal={true}
        visible={showTooltip}
        exceptions={[ref.current]}
        referenceElement={ref.current}
        onClose={() => setShowTooltip(false)}
      >
        <Tooltip data={metaData} />
      </Popper>
    </ContentMixBlock>
  );
};

export default ContentMixItem;

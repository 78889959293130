import React from "react";
import { Route, Switch, Redirect, RouteComponentProps } from "react-router-dom";

import {
  MenuWrapper,
  ContentWrapper,
  TimelineHeader,
  TimelineLayout,
  CalendarWrapper,
  TimelineWrapper,
  TimelineContentLayout
} from "./styles";
import Calendar from "./calendar";
import { TimelineProvider } from "./context";
import TimelineFeed from "./timelineFeed/index";
import ScheduleViewToggle from "../ScheduleViewToggle";
import SidebarNavigation, { menuItem } from "./sidebarNavigation";
import { SidebarNavigationLayout } from "./sidebarNavigation/styles";

const menuItems: menuItem[] = [
  {
    label: "Timeline",
    to: "/timeline",
    icon: "icon-timeline",
    activeIcon: "icon-timeline-filled"
  },
  {
    label: "Scheduled posts",
    to: "/scheduled",
    icon: "icon-clock",
    activeIcon: "icon-clock-filled"
  },
  {
    label: "Published posts",
    to: "/published",
    icon: "icon-send",
    activeIcon: "icon-send-filled"
  },
  {
    label: "Drafts",
    to: "/drafts",
    icon: "icon-draft",
    activeIcon: "icon-draft-filled"
  }
];

const Timeline: React.FC<RouteComponentProps> = ({ match }) => {
  return (
    <TimelineProvider>
      <TimelineLayout>
        <TimelineHeader>
          <ScheduleViewToggle defaultValue="day" />
        </TimelineHeader>
        <ContentWrapper>
          <SidebarNavigationLayout>
            <MenuWrapper>
              <SidebarNavigation currentUrl={match.url} menuItems={menuItems} />
            </MenuWrapper>
            <TimelineContentLayout>
              <TimelineWrapper>
                <Switch>
                  <Route
                    path={`${match.url}/timeline`}
                    component={TimelineFeed}
                  />
                  <Route
                    path={`${match.url}/scheduled`}
                    component={TimelineFeed}
                  />
                  <Route
                    path={`${match.url}/published`}
                    component={TimelineFeed}
                  />
                  <Route
                    path={`${match.url}/drafts`}
                    component={TimelineFeed}
                  />
                  <Redirect to={`${match.url}/timeline`} />
                </Switch>
              </TimelineWrapper>
              <CalendarWrapper>
                <Calendar />
              </CalendarWrapper>
            </TimelineContentLayout>
          </SidebarNavigationLayout>
        </ContentWrapper>
      </TimelineLayout>
    </TimelineProvider>
  );
};

export default Timeline;

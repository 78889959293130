import _ from "lodash";
import {
  Context,
  createContext,
  useContextSelector
} from "use-context-selector";
import { useSelector } from "react-redux";
import { useGranularCallback } from "granular-hooks";
import React, { useMemo, useEffect, useReducer, useCallback } from "react";

import {
  STORAGE_KEY,
  initialValues,
  INotificationState,
  notificationReducer,
  FeatureNotificationStatus,
  IFeatureNotificationPayload
} from "./reducer";
import {
  gotoPrevStepAction,
  gotoNextStepAction,
  featureNotificationDequeueAction,
  featureNotificationEnqueueAction,
  setActiveFeatureNotificationAction,
  resetActiveFeatureNotificationAction
} from "./actionCreators";
import { saveToSessionStorage } from "./utils/notifications";
import * as sessionStorageService from "utils/sessionStorage";
import { updateFeatureNotificationEntry } from "services/notifications";

const NotificationStateContext = createContext<INotificationState | undefined>(
  undefined
);
NotificationStateContext.displayName = "NotificationStateContext";

export interface INotificationActions {
  addFeatureNotification: (
    featureNotification: IFeatureNotificationPayload,
    overrideCompletedStatus?: boolean
  ) => void;
  gotoPrevStep: VoidFunction;
  gotoNextStep: VoidFunction;

  completeFeatureNotificationEntry: (
    featureNotification: NonNullable<
      INotificationState["activeFeatureNotification"]
    >
  ) => void;
}

const NotificationActionsContext = createContext<
  INotificationActions | undefined
>(undefined);
NotificationActionsContext.displayName = "NotificationActionsContext";

export const NotificationProvider: React.FC<any> = React.memo(
  ({ children }) => {
    const account = useSelector<any, any>(state => state.account.data);
    const [state, dispatch] = useReducer(notificationReducer, {
      ...initialValues
    });

    useEffect(() => {
      if (
        !state.activeFeatureNotification &&
        state.featureNotificationQueue.length > 0
      ) {
        dispatch(
          setActiveFeatureNotificationAction(state.featureNotificationQueue[0])
        );
      }
    }, [state.featureNotificationQueue, state.activeFeatureNotification]);

    // * STATE MODIFIERS
    const sessionStorageData = sessionStorageService.get(STORAGE_KEY);

    const addFeatureNotification = useGranularCallback(
      (
        featureNotification: IFeatureNotificationPayload,
        overrideCompletedStatus: boolean = false
      ) => {
        if (!!account.id) {
          const canBeShown =
            overrideCompletedStatus ||
            !_.uniq([
              ...(Array.isArray(sessionStorageData) ? sessionStorageData : []),
              ...state.featureNotificationQueue,
              ...(!!account?.accountMetadata &&
              Array.isArray(JSON.parse(account.accountMetadata))
                ? JSON.parse(account.accountMetadata)
                : [])
            ])
              .filter(n => n.status === FeatureNotificationStatus.COMPLETE)
              .map(n => n.id)
              .includes(featureNotification.id);

          if (canBeShown) {
            dispatch(featureNotificationEnqueueAction(featureNotification));
          }
        }
      },
      [account, sessionStorageData],
      [state.featureNotificationQueue]
    );

    const gotoPrevStep = useCallback(() => {
      dispatch(gotoPrevStepAction());
    }, []);

    const gotoNextStep = useCallback(() => {
      dispatch(gotoNextStepAction());
    }, []);

    // * HANDLERS
    const completeFeatureNotificationEntry = useCallback(
      async (
        featureNotification: NonNullable<
          INotificationState["activeFeatureNotification"]
        >
      ) => {
        const { id, persist } = featureNotification;

        dispatch(featureNotificationDequeueAction());
        dispatch(resetActiveFeatureNotificationAction());

        if (persist) {
          saveToSessionStorage({
            id,
            status: FeatureNotificationStatus.COMPLETE
          });
        }

        await updateFeatureNotificationEntry(
          id,
          persist
            ? FeatureNotificationStatus.INCOMPLETE
            : FeatureNotificationStatus.COMPLETE
        );
      },
      []
    );

    const actions = useMemo(
      () => ({
        addFeatureNotification,
        gotoPrevStep,
        gotoNextStep,

        completeFeatureNotificationEntry
      }),
      [
        gotoPrevStep,
        gotoNextStep,
        addFeatureNotification,

        completeFeatureNotificationEntry
      ]
    );

    return (
      <NotificationStateContext.Provider value={state}>
        <NotificationActionsContext.Provider value={actions}>
          {children}
        </NotificationActionsContext.Provider>
      </NotificationStateContext.Provider>
    );
  }
);

export const useNotificationState = <T,>(
  selector: (state: INotificationState) => T
): T => {
  try {
    return useContextSelector(
      NotificationStateContext as Context<INotificationState>,
      selector
    );
  } catch (_) {
    throw new Error(
      "useNotificationState must be used within a NotificationProvider"
    );
  }
};

export const useNotificationActions = <T,>(
  selector: (action: INotificationActions) => T
): T => {
  try {
    return useContextSelector(
      NotificationActionsContext as Context<INotificationActions>,
      selector
    );
  } catch (_) {
    throw new Error(
      "useNotificationAction must be used within a NotificationProvider"
    );
  }
};

import React, { useRef, useMemo, useState } from "react";

import { FeedAddButton } from "./styles";
import Popper from "components/common/Popper";
import { parseStringToDate } from "utils/date";
import NewActivityOptions from "components/schedule/common/NewActivityOptions";

interface IDayContextMenu {
  date: string;
}

const DayContextMenu: React.FC<IDayContextMenu> = ({ date }) => {
  const [showMenu, setShowMenu] = useState(false);
  const referenceElement = useRef<HTMLDivElement>(null);
  const toggleShowMenu = () => setShowMenu(!showMenu);

  const day = useMemo(() => parseStringToDate(date, "yyyy/M/d"), [date]);

  return (
    <div className="add" ref={referenceElement}>
      <FeedAddButton onClick={toggleShowMenu}>
        <i className="icon-plus" />
      </FeedAddButton>
      {/* @ts-ignore */}
      <Popper
        visible={showMenu}
        placement="bottom-start"
        onClose={() => setShowMenu(false)}
        exceptions={[referenceElement.current]}
        referenceElement={referenceElement.current}
      >
        <NewActivityOptions date={day} close={() => setShowMenu(false)} />
      </Popper>
    </div>
  );
};

export default DayContextMenu;

import React, { useState } from "react";

import {
  CalendarContent,
  CalendarDayColumn,
  CalendarScheduleList
} from "./styles";
import CalendarEntry from "./CalendarEntry";
import { POST_STATUS } from "../constants/postStatus";
import ColumnContextMenu from "./ColumnContextMenu";
import DraggableWrapper from "../common/drag-and-drop/DraggableWrapper";

const DayColumn = ({ day, isAddNewButtonVisible, height }) => {
  const [showAddNewBtnOnHover, setShowAddNewBtnOnHover] = useState(false);

  const [showContentMenu, setShowContextMenu] = useState(false);
  const toggleShowContextMenu = () => setShowContextMenu(!showContentMenu);

  return (
    <CalendarDayColumn
      $isWeekend={day.isWeekend}
      $blank={day.entries.length < 1}
      key={day.date.toISOString()}
      onMouseEnter={() => setShowAddNewBtnOnHover(true)}
      onMouseLeave={() => {
        setShowAddNewBtnOnHover(false);
        setShowContextMenu(false);
      }}
    >
      <CalendarContent
        isWeekend={day.isWeekend}
        $isBeforeToday={day.isBeforeToday}
        $isBlank={day.entries.length < 1}
      >
        <CalendarScheduleList>
          {day.entries.map((entry, index) => {
            return (
              <DraggableWrapper
                id={`${entry.type}/${entry.cardId}`}
                key={entry.id}
                index={index}
                isDisabled={entry.post?.status === POST_STATUS.SENT}
              >
                <CalendarEntry
                  key={entry.id}
                  entry={entry}
                  height={height}
                  fullAttachmentHeight
                />
              </DraggableWrapper>
            );
          })}
          <ColumnContextMenu
            day={day}
            showContentMenu={showContentMenu}
            toggleShowContextMenu={toggleShowContextMenu}
            isVisible={showAddNewBtnOnHover && isAddNewButtonVisible}
          />
        </CalendarScheduleList>
      </CalendarContent>
    </CalendarDayColumn>
  );
};

export default DayColumn;

export const OPEN_MODAL = "@@modal/OPEN_MODAL";
export const CLOSE_MODAL = "@@modal/CLOSE_MODAL";

export const openModal = (name, props) => {
  return {
    type: OPEN_MODAL,
    modalType: name,
    modalProps: props
  };
};

export const closeModal = () => {
  return {
    type: CLOSE_MODAL
  };
};

import React from "react";
import styled from "styled-components";

import { SourceIconWrapper } from "../style";

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: center;
`;

const ReactBox = styled.div`
  height: 64px;
  width: 64px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 6px rgba(0, 90, 143, 0.1);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-size: 32px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LikeArticle: React.FC<{}> = () => {
  return (
    <SourceIconWrapper>
      <Wrapper>
        <BoxWrapper>
          <ReactBox>
            <span className="icon-like-24" />
          </ReactBox>
          <ReactBox>
            <span className="icon-disliked-24" />
          </ReactBox>
        </BoxWrapper>
      </Wrapper>
    </SourceIconWrapper>
  );
};

export default LikeArticle;

import React from "react";

import {
  MenuList,
  MenuContainer,
  MenuItemContainer
} from "components/navigation/Menu";
import { MenuEntry, IMenuEntry } from "./styles";
import { IMoodFilter } from "contextApi/composerContext";

interface IOption extends Omit<IMenuEntry, "isSelected"> {
  options: IMoodFilter[];
  selected: IMoodFilter;
  onOptionSelected: (option: IMoodFilter) => void;
}

const Option: React.FC<IOption> = ({ options, selected, onOptionSelected }) => (
  <MenuContainer minWidth={180}>
    <MenuList>
      {options.map(option => {
        const isSelected = !!selected && selected.label === option.label;

        return (
          <MenuItemContainer key={option.label}>
            <MenuEntry
              isSelected={isSelected}
              onClick={() => onOptionSelected(option)}
            >
              <div className="content">
                <div className="label">
                  <div className="title">{option.label}</div>
                </div>
              </div>
              {isSelected && <i className="icon-check" />}
            </MenuEntry>
          </MenuItemContainer>
        );
      })}
    </MenuList>
  </MenuContainer>
);

export default Option;

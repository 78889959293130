import styled, { css } from "styled-components";

export const FilterButton = styled.button<{
  variant: string;
  minWidth?: string;
}>`
  outline: none;
  border: none;
  font-weight: 600;
  padding: 6px 14px;
  cursor: pointer;
  border-radius: 48px;
  line-height: 24px;
  background: transparent;
  color: ${props => props.theme.colors.text02};

  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}

  &:hover {
    color: ${props => props.theme.colors.text01};
  }

  ${props => {
    switch (props.variant) {
      case "primary":
        return css`
          color: ${props => props.theme.colors.blue};

          span {
            color: ${props => props.theme.colors.text01};
          }

          &:hover {
            background-color: ${props => props.theme.colors.primaryHover};
            color: ${props => props.theme.colors.white};

            span {
              color: ${props => props.theme.colors.white};
            }
          }
        `;
      case "secondary":
        return css`
          &:hover {
            background-color: ${props => props.theme.colors.grey03};
          }
        `;
    }
  }}
`;

import styled, { css } from "styled-components";

export const DayFeedLayout = styled.div`
  position: relative;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  &:before {
    content: "";
    position: absolute;
    top: 14px;
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.grey02};
  }
`;

export const FeedHeader = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  top: 8px;
  width: 100%;
  z-index: 9;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 16px;
    height: 28px;
    font-size: 12px;
    font-weight: 600;
    line-height: 130%;
    color: ${props => props.theme.colors.text02};
    border-radius: 24px;
    border: 1px solid ${props => props.theme.colors.grey02};
    background-color: ${props => props.theme.colors.white};
  }

  > div.add {
    position: absolute;
    top: -2px;
    right: 0px;
  }
`;

interface IFeedContent {
  isEmpty: boolean;
}

export const FeedContent = styled.div<IFeedContent>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${props =>
    props.isEmpty &&
    css`
      min-height: 172px;
    `};
  ${props =>
    !props.isEmpty &&
    css`
      margin-top: 10px;
    `};

  > span.empty {
    margin: auto;
  }
`;

export const FeedEntry = styled.div`
  border-radius: 20px;
  height: 475px;
  background-color: rgb(255 255 255);
  box-shadow:
    rgb(0 0 0 / 3%) 0px 1px 1px,
    rgb(96 116 128 / 8%) 0px 2px 4px,
    rgb(96 116 128 / 8%) 0px 5px 10px;

  &:hover {
    box-shadow:
      rgb(0 0 0 / 13%) 0px 6px 14px 0px,
      rgb(0 0 0 / 11%) 0px 1px 3px 0px;
  }
`;

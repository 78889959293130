import React from "react";

import { Headline6 } from "components/common/styles";
import { Breadcrumb, BreadcrumbsContainer, BreadcrumbNumber } from "./styles";

interface IBreadcrumbs {
  selectedStep: number;
}

const Breadcrumbs: React.FC<IBreadcrumbs> = ({ selectedStep }) => {
  return (
    <BreadcrumbsContainer selectedStep={selectedStep}>
      <Breadcrumb>
        <BreadcrumbNumber>
          <Headline6>1</Headline6>
        </BreadcrumbNumber>
        <Headline6>Quote</Headline6>
      </Breadcrumb>
      <Breadcrumb>
        <BreadcrumbNumber>
          <Headline6>2</Headline6>
        </BreadcrumbNumber>
        <Headline6>Payment</Headline6>
      </Breadcrumb>
      <Breadcrumb>
        <BreadcrumbNumber>
          <Headline6>3</Headline6>
        </BreadcrumbNumber>
        <Headline6>Onboarding</Headline6>
      </Breadcrumb>
      <Breadcrumb>
        <BreadcrumbNumber>
          <Headline6>4</Headline6>
        </BreadcrumbNumber>
        <Headline6>Account Setup</Headline6>
      </Breadcrumb>
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;

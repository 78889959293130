import moment from "moment";
import * as fns from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { getDayEntries } from "./entryUtils";

export function getDate(params = {}) {
  const { format, timezone } = params;
  let now = new Date();

  if (timezone) now = utcToZonedTime(now, timezone);

  if (format)
    now = format === "ISO" ? now.toISOString() : getFormattedDate(now, format);

  return now;
}

export function getDateInterval(date, params = {}) {
  const { before, after, format } = params;
  const start = fns.startOfDay(fns.addDays(date, -before));
  const end = fns.endOfDay(fns.addDays(date, after));

  return {
    start: format ? getFormattedDate(start, format) : start,
    end: format ? getFormattedDate(end, format) : end
  };
}

export function getOffsetDate(date, params = {}) {
  const { hours, days, months, weeks } = params;
  let offsetDate = date;

  if (hours) offsetDate = fns.addHours(offsetDate, hours);
  if (months) offsetDate = fns.addMonths(offsetDate, months);
  if (weeks) offsetDate = fns.addWeeks(offsetDate, weeks);
  if (days) offsetDate = fns.addDays(offsetDate, days);

  return offsetDate;
}

export function getFormattedDate(date, format = "ISO") {
  let formattedDate = date;

  if (typeof date === "string") formattedDate = fns.parseISO(date);

  if (format !== "ISO") return fns.format(formattedDate, format);

  return date.toISOString();
}

export function getCalendarDays(date, entries, params) {
  const { timezone } = params;
  const today = getDate(new Date(), { timezone: timezone });
  const focusDate = fns.startOfDay(date);
  const first = fns.startOfMonth(focusDate);
  const last = fns.endOfMonth(focusDate);
  const firstWeekStart = fns.startOfWeek(first, { weekStartsOn: 1 });

  const daysPerWeek = 7;
  const weeksPerMonth = fns.getWeeksInMonth(focusDate, { weekStartsOn: 1 });

  const days = [];

  for (let i = 0; i < daysPerWeek * weeksPerMonth; i++) {
    let day = fns.addDays(firstWeekStart, i);

    days.push({
      date: day,
      dayOfMonth: getFormattedDate(day, "d"),
      dayOfWeek: getFormattedDate(day, "EEE"),
      key: getFormattedDate(day),
      isOutsideMonth: fns.isBefore(day, first) || fns.isAfter(day, last),
      isToday: fns.isSameDay(day, today),
      isPast: fns.isBefore(day, fns.startOfDay(today)),
      entries: getDayEntries(day, entries)
    });
  }

  return days;
}

/**
 * Removes the timezone offset from the date keeping the times
 */
export function removeTimezoneOffsetFromDate(date) {
  const dateInBrowserTz = date instanceof Date ? date : new Date(date);
  const momentInBrowserTz = moment(date);

  return momentInBrowserTz
    .utc()
    .set({
      minute: dateInBrowserTz.getMinutes(),
      hours: dateInBrowserTz.getHours(),
      date: dateInBrowserTz.getDate(),
      month: dateInBrowserTz.getMonth(),
      year: dateInBrowserTz.getFullYear()
    })
    .toDate();
}

/**
 * Removes the timezone offset from the date keeping the times
 */
export function removeTimezoneOffsetFromDateAsISO(date) {
  return removeTimezoneOffsetFromDate(date).toISOString();
}

/**
 * Returns the string representation of the date without the timezone information.
 */
export function removeTimezoneFromDate(date) {
  if (!date) {
    return date;
  }

  const value =
    typeof date === "object"
      ? date.toISOString()
      : new Date(date).toISOString();
  return value.replace("Z", "");
}

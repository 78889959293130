export enum SECTION_TYPES {
  TOPICS = "topics",
  DOMAINS = "domains",
  SOURCES = "sources",
  KEYWORDS = "keywords"
}

export enum SOURCE_TYPE {
  TOPICS = "topics",
  DOMAINS = "domains",
  TWITTER = "twitter",
  KEYWORD = "keyword",
  RSS = "rss"
}

export enum EXPLORE_SELECTION {
  ALL = "all",
  TOPICS = "topics",
  WEBSITE = "domains",
  TWITTER = "twitter-sources",
  RSS = "rss-sources"
}

export type IExternSourceRequest =
  | { type: string; url: string }
  | { type: string; handle: string; image?: string; title?: string };

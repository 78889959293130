import React from "react";

import {
  CalendarHeaderTime,
  CalendarEntryChannels,
  CalendarHeaderInfoWrapper,
  CalendarEntryHeader as Header
} from "./styles";
import EntryStatus from "components/common/contentTypes/EntryStatus";
import { ChannelAvatar, Avatar, Service } from "components/common/styles";
import defaultAvatar from "assets/images/dashboard/avatar-empty-state.png";
import { useAppState } from "contextApi/appContext";

function CalendarEntryHeader({
  status = "NEW",
  isStuck = false,
  time,
  channels,
  parent,
  date,
  dashboardEntry
}) {
  const showIdeasPageRevamp = useAppState(state => state.showIdeasPageRevamp);
  
  return (
    <Header>
      <CalendarHeaderInfoWrapper>
        <EntryStatus status={isStuck ? "STUCK" : (status as any)} />
        <CalendarHeaderTime $isDraft={status === "DRAFT"}>
          {dashboardEntry ? (
            <>
              <span className="date">{date}</span>
              <span className="time">| {time}</span>
            </>
          ) : (
            !showIdeasPageRevamp && (
              <>
                {!!time && time}
                {status === "DRAFT" && parent !== "draft-panel" && (
                  <>
                    {!!time && <span>&#183;</span>}
                    <span>Draft</span>
                  </>
                )}
              </>
            )
          )}
        </CalendarHeaderTime>
      </CalendarHeaderInfoWrapper>
      <CalendarEntryChannels>
        {channels.map(channel => (
          <ChannelAvatar key={channel.id} $enabled={true}>
            <Avatar
              src={channel.avatar ?? defaultAvatar}
              width={24}
              height={24}
              $noShadow={true}
              $isRounded={true}
              onError={(event: any) => {
                event.target.src = defaultAvatar;
                event.onerror = null;
              }}
            />
            <Service
              key={channel.id}
              title={channel.username}
              $type={channel.service}
              $size={16}
            />
          </ChannelAvatar>
        ))}
      </CalendarEntryChannels>
    </Header>
  );
}

export default CalendarEntryHeader;

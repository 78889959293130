import styled from "styled-components";

import { Headline1, Headline5 } from "components/common/styles";

export const DashboardHeaderWrapper = styled.div`
  display: flex;
  margin: 32px 0 54px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const DashboardGreeting = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

export const DateSection = styled(Headline5)`
  line-height: 20px;
  letter-spacing: -0.005em;

  em {
    margin-left: 4px;
    line-height: 17.5px;
    font-size: 14px;
    color: ${props => props.theme.colors.white};
    width: 28px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.colors.text01};
    border-radius: 4px;
  }
`;

export const GreetingSection = styled(Headline1)`
  line-height: 34px;
  letter-spacing: -0.03em;
  color: ${props => props.theme.colors.darkBlack};
`;

export const DashboardHeaderOptions = styled.div`
  height: 48px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 6px 24px 6px 16px;
  background: ${props => props.theme.colors.grey01};
  border-radius: 24px;
`;

interface IDashboardHeaderStats {
  disabled?: boolean;
}

export const DashboardHeaderStats = styled.div<IDashboardHeaderStats>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.005em;
  color: ${props =>
    props.disabled ? props.theme.colors.text04 : props.theme.colors.text02};

  > i {
    font-size: 24px;
  }

  span {
    > em {
      font-weight: 600;
      line-height: 17.5px;
      letter-spacing: -0.005em;
    }
  }
`;

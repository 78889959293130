import React from "react";
import { IArticleAttachment } from "@hellocontento/contento-common";
import { Flex, Image, Text } from "rebass/styled-components";
import { theme } from "theme";
import { Tag } from "@styled-icons/fluentui-system-regular";

import MoreOptionsButton from "./MoreOptionsButton";
import styled from "styled-components";
import IdeaEntryAttachment from "./IdeaEntryAttachment";
import WhatsAppSquareIcon from "./WhatsAppSquareIcon";
import { type IUserIdea } from "state/reducers/IdeasReducer";

const IdeaEntryHeader = ({ idea }: { idea: IUserIdea }) => {
  return (
    <Flex justifyContent={"space-between"} height={"20px"}>
      {!idea.isWhatsappAsset ? (
        <div></div>
      ) : (
        <Flex
          padding={"5px 8px"}
          alignItems={"center"}
          sx={{
            background: "#32D95157",
            color: theme.colors.green,
            gap: "5px",
            borderRadius: "30px",
            border: "1px solid #A3E7B0",
            fontSize: "8px"
          }}
        >
          <WhatsAppSquareIcon size={12} />
          <span>via whatsapp</span>
        </Flex>
      )}
      <MoreOptionsButton idea={idea} />
    </Flex>
  );
};

const StyledText = styled(Text)<{ $color?: string; $italic?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 16px;
  font-style: ${props => (props.$italic ? "italic" : "inherit")};
  color: ${props => props.$color ?? "black"};
  height: 100%;
  white-space: pre-line;
`;

const ArticlePublisher = ({
  attachment
}: {
  attachment: IArticleAttachment;
}) => {
  return (
    <Flex sx={{ gap: "10px", alignItems: "center" }}>
      <Image
        width={"20px"}
        height={"20px"}
        src={attachment?.favicon}
        alt={"Article Domain Favicon"}
        sx={{ objectFit: "contain" }}
      />
      <Text
        color={"#646769"}
        maxWidth={"120px"}
        sx={{
          textTransform: "capitalize",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis"
        }}
      >
        {attachment?.publisher || ""}
      </Text>
    </Flex>
  );
};

export default function IdeaEntry({ idea }: { idea: IUserIdea }) {
  const { contentTypeId: category, attachment, caption } = idea;

  const isWithArticle = attachment?.type === "article";

  return (
    <Flex
      flexDirection={"column"}
      width={"349px"}
      height={"329px"}
      sx={{
        padding: "20px",
        borderRadius: "8px",
        gap: "12px",
        border: "1px solid #606E801F",
        boxShadow: "0px 1px 2px 0px #1C1E1F0A",
        "box-shadow": "0px 4px 24px 0px #1C1E1F14"
      }}
    >
      <IdeaEntryHeader idea={idea} />
      <IdeaEntryAttachment attachment={idea.attachment} />
      {caption && caption["all"] ? (
        <StyledText>{`${caption["all"]}`}</StyledText>
      ) : (
        <StyledText $color="#A6A6A6" $italic={true}>
          No caption set
        </StyledText>
      )}

      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        marginTop={"19px"}
      >
        {isWithArticle && (
          <ArticlePublisher attachment={attachment as IArticleAttachment} />
        )}
        <Flex sx={{ gap: "10px", alignItems: "center" }}>
          <Tag size={16} color="#3C3F4254" />
          <Text
            color={"#646769"}
            sx={{
              textTransform: "capitalize",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            }}
            maxWidth={"120px"}
          >
            {category || "No category set"}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}

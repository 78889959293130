export const USER_INFO_REQUESTED = "@@auth/USER_INFO_REQUESTED";
export const USER_INFO_FETCHED = "@@auth/USER_INFO_FETCHED";
export const RENEW_TOKEN = "@@auth/RENEW_TOKEN";
export const TOKEN_RENEWED = "@@auth/TOKEN_RENEWED";
export const AUTHENTICATION_REQUIRED = "@@auth/AUTHENTICATION_REQUIRED";
export const LOGOUT = "@@auth/LOGOUT";
export const LOGIN = "@@auth/LOGIN";
export const START_ONBOARDING = "@@auth/START_ONBOARDING";
export const START_ONBOARDING_FAILED = "@@auth/START_ONBOARDING_FAILED";
export const SHOW_SETUP_ACCOUNT_MODAL = "@@auth/SHOW_SETUP_ACCOUNT_MODAL";
export const LOGIN_FAILED = "@@auth/LOGIN_FAILED";
export const RESET_PASSWORD = "@@auth/RESET_PASSWORD";
export const RESET_PASSWORD_SENT = "@@auth/RESET_PASSWORD_SENT";
export const RESET_PASSWORD_FAILED = "@@auth/RESET_PASSWORD_FAILED";
export const NONCE_LOGIN = "@@auth/NONCE_LOGIN";
export const NONCE_LOGIN_FAILED = "@@auth/NONCE_LOGIN_FAILED";
export const NONCE_LOGIN_SUCCESS = "@@auth/NONCE_LOGIN_SUCCESS";
export const RESET_START_ERRORS = "@@auth/RESET_START_ERRORS";
export const UPDATE_USER = "@@auth/UPDATE_USER";
export const UPDATE_PHONE = " @@auth/UPDATE_PHONE";
export const UPDATE_PHONE_FAILED = "@@auth/UPDATE_PHONE_FAILED";

export const login = credentials => {
  return {
    type: LOGIN,
    credentials
  };
};

export const logout = () => {
  return {
    type: LOGOUT
  };
};

export const startOnboarding = accountData => {
  return {
    type: START_ONBOARDING,
    accountData
  };
};

export const setShowSetupAccountModal = value => {
  return {
    type: SHOW_SETUP_ACCOUNT_MODAL,
    value
  };
};

export const startOnboardingFailed = errorMessage => {
  return {
    type: START_ONBOARDING_FAILED,
    errorMessage
  };
};

export const updatePhoneFailed = errorMessage => {
  return {
    type: UPDATE_PHONE_FAILED,
    errorMessage
  };
};

export const resetStartErrors = () => {
  return {
    type: RESET_START_ERRORS
  };
};

export const renewToken = () => {
  return {
    type: RENEW_TOKEN
  };
};

export const tokenRenewed = () => {
  return {
    type: TOKEN_RENEWED
  };
};

export const userInfoRequested = () => {
  return {
    type: USER_INFO_REQUESTED
  };
};

export const userInfoFetched = userInfo => {
  return {
    type: USER_INFO_FETCHED,
    userInfo
  };
};

export const loginFailed = errorMessage => {
  return {
    type: LOGIN_FAILED,
    errorMessage
  };
};

export const authenticationRequired = () => {
  return {
    type: AUTHENTICATION_REQUIRED
  };
};

export const resetPassword = ({ email }) => {
  return {
    type: RESET_PASSWORD,
    email
  };
};

export const resetPasswordSent = () => {
  return {
    type: RESET_PASSWORD_SENT
  };
};

export const resetPasswordFailed = errorMessage => {
  return {
    type: RESET_PASSWORD_FAILED,
    errorMessage
  };
};

export const nonceLogin = nonce => {
  return {
    type: NONCE_LOGIN,
    nonce
  };
};

export const nonceLoginFailed = () => {
  return {
    type: NONCE_LOGIN_FAILED
  };
};

export const nonceLoginSuccess = () => {
  return {
    type: NONCE_LOGIN_SUCCESS
  };
};

export const updateUser = values => {
  return {
    type: UPDATE_USER,
    values
  };
};

export const updatePhone = values => {
  return {
    type: UPDATE_PHONE,
    values
  };
};

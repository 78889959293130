import { colors } from "../../../theme";

export const options = {
  scales: {
    x: {
      grid: {
        display: false
      },
      ticks: {
        color: colors.text04,
        font: {
          weight: "600"
        },
        autoSkip: true,
        maxRotation: 0,
        minRotation: 0
      }
    },
    y: {
      grid: {
        borderDash: [2, 2],
        borderColor: colors.grey03
      },
      ticks: {
        callback: val => (val % 1 === 0 ? val : null),
        color: colors.text04,
        maxTicksLimit: 8
      }
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};

import styled, { css } from "styled-components";
import { Flex, Text } from "rebass/styled-components";

import searchIcon from "assets/images/search-icon.png";
import { CheckboxTrigger } from "components/common/form/Checkbox";

export const ChannelList = styled(Flex)`
  margin: 0 -8px 0 -8px;
`;

export const Page = styled(Flex)`
  display: flex;
  padding: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.grey01};

  ${CheckboxTrigger} {
    width: 21px;
    height: 21px;
    border-radius: 2px;
    border: 2px solid ${props => props.theme.colors.backdrop};

    &,
    &:focus {
      box-shadow: unset;
    }

    ${props =>
      props.checked &&
      css`
        border: unset;
      `};
  }
`;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  position: relative;

  > img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.backdrop};
  }
`;

export const PageName = styled(Text)`
  color: ${props => props.theme.colors.black};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.16px;
`;

export const ConnectionListWrapper = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
`;

export const SearchInput = styled.input`
  position: relative;
  border-radius: 8px;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  background: ${props => props.theme.colors.grey01} url(${searchIcon}) no-repeat
    16px center;
  background-size: 16px 16px;
  padding: 13px 16px 13px 38px;
  color: ${props => props.theme.colors.black};
  font-size: 15px;
  line-height: 20px;
  border: none;
  margin-top: 4px;
  transition: width 0.5s;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px ${props => props.theme.colors.blue};
    outline: none;
  }

  &::placeholder {
    color: #9494a0;
  }
`;

export const ConnectionList = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  height: auto;
  max-height: 240px;
  scrollbar-gutter: stable;
`;

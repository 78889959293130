export const COLLAPSE_MENU = "@@layout/COLLAPSE_MENU";
export const EXPAND_MENU = "@@layout/EXPAND_MENU";

export const expandMenu = () => {
  return {
    type: EXPAND_MENU
  };
};

export const collapseMenu = () => {
  return {
    type: COLLAPSE_MENU
  };
};

import React from "react";

import { HelperText } from "./styles";

interface IHelperButton {
  label?: string;
  onClick?: () => void;
}

const HelperButton: React.FC<IHelperButton> = ({ onClick, label }) => {
  return (
    <HelperText>
      <button onClick={onClick}>{label}</button>
    </HelperText>
  );
};

export default HelperButton;

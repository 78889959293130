import React from "react";

import {
  TooltipTitle,
  StatListItem,
  TooltipContent,
  TooltipContainer
} from "../styles";
import Divider from "components/common/Divider";
import { formatForDashboard } from "utils/date";
import { ICustomTooltip } from "../ExternalTooltipHandler";

const Tooltip: React.FC<ICustomTooltip> = ({ data }) => {
  const {
    date,
    variation,
    breakdown: { likes, comments, shares, clicks }
  } = data;

  return (
    <TooltipContainer>
      <TooltipTitle>{formatForDashboard(new Date(date))}</TooltipTitle>
      <TooltipContent>
        <StatListItem bgcolor={"transparent"}>
          <div className="title">
            <span className="icon-like-filled-20 icon" />
            Likes
          </div>
          <div className="stats">{likes ?? 0}</div>
        </StatListItem>
        <StatListItem bgcolor={"transparent"}>
          <div className="title">
            <span className="icon-comment icon" />
            Comments
          </div>
          <div className="stats">{comments ?? 0}</div>
        </StatListItem>
        <StatListItem bgcolor={"transparent"}>
          <div className="title">
            <span className="icon-share icon" />
            Shares
          </div>
          <div className="stats">{shares ?? 0}</div>
        </StatListItem>
        <StatListItem bgcolor={"transparent"}>
          <div className="title">
            <span className="icon-clicks icon" />
            Clicks
          </div>
          <div className="stats">{clicks ?? 0}</div>
        </StatListItem>
        <Divider my={0} mx={-12} />
        <StatListItem bgcolor="transparent">
          <div className="title alternate">Totals</div>
          <div className="stats">{variation ?? 0}</div>
        </StatListItem>
      </TooltipContent>
    </TooltipContainer>
  );
};

export default Tooltip;

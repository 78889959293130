import React from "react";
import styled from "styled-components";
import { Button as RebassButton } from "rebass/styled-components";

export const PublisherButton = styled(RebassButton)`
  border: none;
  margin: 0;
  padding: 0;
  outline: none;
  background: none;
  text-decoration: none;
  font-weight: 500;
  color: ${props => props.theme.colors.text02};

  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    color: ${props => props.theme.colors.text01};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const PublisherText = styled.span`
  font-weight: 500;
  color: ${props => props.theme.colors.text02};

  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IPublisherProps {
  hasDomain: boolean;
  onClick: () => void;
  text: string;
}

const Publisher: React.FC<IPublisherProps> = ({ hasDomain, onClick, text }) => {
  if (hasDomain) {
    return <PublisherButton onClick={onClick}>{text}</PublisherButton>;
  } else {
    return <PublisherText>{text}</PublisherText>;
  }
};

export default Publisher;

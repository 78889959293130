import { useDispatch } from "react-redux";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import Isotope, { IsotopeOptions } from "isotope-layout";
import { useGranularCallback, useGranularEffect } from "granular-hooks";
import React, { useRef, useState, useLayoutEffect, useEffect } from "react";

import {
  EmptyList,
  ImageCard,
  ImageList,
  SearchIcon,
  SearchInput,
  ImageLinkMeta,
  ImageUserMeta,
  SearchContainer,
  ImageCardShimmer
} from "./styles";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import Button from "components/common/Button";
import { Headline6 } from "components/common/styles";
import { ToolContentHeader, ToolContentBody } from "../styles";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { downloadUnsplashImage, uploadImageByUrl } from "services/post";

const isotopeOptions: IsotopeOptions = {
  itemSelector: ".image-suggestion-grid-item",
  layoutMode: "masonry",
  masonry: {
    gutter: 10
  }
};

const ImageSuggestion: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const [endOfListRef, setEndOfListRef] = useState<HTMLDivElement | null>(null);
  const scrollObserver = useRef<IntersectionObserver | null>(null);
  const addToast = useToaster();
  const suggestedImages = useComposerState(
    state => state.suggestedImages.images
  );
  const isLoading = useComposerState(state => state.suggestedImages.isLoading);
  const searchTerm = useComposerState(
    state => state.suggestedImages.searchTerm
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const setSuggestedImages = useComposerActions(
    actions => actions.setSuggestedImages
  );
  const getImageSuggestions = useComposerActions(
    actions => actions.getImageSuggestions
  );
  const setImagesSearchTerm = useComposerActions(
    actions => actions.setImagesSearchTerm
  );
  const setNewImagesIndication = useComposerActions(
    actions => actions.setNewImagesIndication
  );
  const addUploadedAttachments = useComposerActions(
    actions => actions.addUploadedAttachments
  );
  const setIsUploadingAttachments = useComposerActions(
    actions => actions.setIsUploadingAttachments
  );
  const handleImageSuggestionSearch = useComposerActions(
    actions => actions.handleImageSuggestionSearch
  );

  useLayoutEffect(() => {
    new Isotope(".image-suggestion-grid", isotopeOptions);
  });

  useEffect(() => {
    dispatch(trackAnalyticsEvent("Images Opened"));
  }, [dispatch]);

  useGranularEffect(
    () => {
      setNewImagesIndication(false);
    },
    [],
    [setNewImagesIndication]
  );

  const generateNewImages = useGranularCallback(
    (refreshList: boolean = true) => {
      if (refreshList) {
        handleImageSuggestionSearch(searchTerm, false);
      } else {
        getImageSuggestions(searchTerm, false, false);
      }
    },
    [searchTerm],
    [handleImageSuggestionSearch]
  );

  const handleImageUpload = async (image: any) => {
    const index = suggestedImages.findIndex(i => image === i);

    try {
      setIsUploadingAttachments(true, "photo");
      setIsUploading(true);
      setSuggestedImages([
        ...suggestedImages.slice(0, index),
        { ...suggestedImages[index], isUploading: true },
        ...suggestedImages.slice(index + 1)
      ]);

      // add download endpoint
      downloadUnsplashImage(image.id);

      const uploadedImage = await uploadImageByUrl(image.urls.full);
      const imageAttachment = {
        path: uploadedImage.path,
        metaData: {
          height: image.height,
          width: image.width
        }
      };

      addUploadedAttachments([imageAttachment], "photo");
      dispatch(trackAnalyticsEvent("Suggested Image Used"));
    } catch (error) {
      addToast((error as Error).message, "error");
    } finally {
      setIsUploadingAttachments(false, "photo");
      setIsUploading(false);
      setSuggestedImages([
        ...suggestedImages.slice(0, index),
        { ...suggestedImages[index], isUploading: false },
        ...suggestedImages.slice(index + 1)
      ]);
    }
  };

  const handleKeyPress = useGranularCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleImageSuggestionSearch(searchTerm, false);
      } else {
        setImagesSearchTerm(e.currentTarget.value);
      }
    },
    [searchTerm],
    [handleImageSuggestionSearch]
  );

  const gotoExternalLink = (
    e: React.MouseEvent<HTMLSpanElement>,
    profileUrl: string
  ) => {
    e.stopPropagation();

    if (!!profileUrl) {
      window.open(
        profileUrl + "?utm_source=Willow&utm_medium=referral",
        "_blank"
      );
    }
  };

  const cleanObservers = () => {
    if (scrollObserver.current) {
      scrollObserver.current.disconnect();
    }
  };

  useLayoutEffect(() => {
    cleanObservers();

    scrollObserver.current = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          generateNewImages(false);
        }
      });
    }, {});

    if (endOfListRef) {
      scrollObserver.current.observe(endOfListRef);
    }

    return () => {
      cleanObservers();
    };
  }, [generateNewImages, endOfListRef]);

  return (
    <>
      <ToolContentHeader>
        <Headline6>Image suggestion</Headline6>
        {/* // TODO: Re-enable this button to be used with a better version of caption generation */}
        {/* @ts-ignore */}
        <Button size={"sm"} variant={"secondary"} onClick={generateNewImages}>
          Generate new
        </Button>
      </ToolContentHeader>
      <ToolContentBody>
        <SearchContainer>
          <SearchIcon />
          <SearchInput
            type="text"
            placeholder="Search images"
            onKeyUp={handleKeyPress}
          />
        </SearchContainer>
        <ImageList className="image-suggestion-grid">
          {suggestedImages.length <= 0 && isLoading ? (
            <>
              {[...Array(10).fill(0)].map((_, index) => (
                <ImageCardShimmer
                  className="image-suggestion-grid-item"
                  key={index}
                  index={index}
                />
              ))}
            </>
          ) : suggestedImages.length < 1 ? (
            <EmptyList>No photos found</EmptyList>
          ) : (
            <>
              {suggestedImages.map((image, index) => {
                return (
                  <ImageCard
                    key={`${image.id}-${index}`}
                    src={image.urls.thumb}
                    disabled={isUploading}
                    isUploading={image.isUploading}
                    ratio={image.height / image.width}
                    className="image-suggestion-grid-item"
                    onClick={() => !isUploading && handleImageUpload(image)}
                  >
                    <ImageLinkMeta
                      className="icon-external"
                      onClick={e => gotoExternalLink(e, image.links?.html)}
                    />
                    {!!image.user?.username && (
                      <ImageUserMeta
                        onClick={e =>
                          gotoExternalLink(e, image.user?.links?.html)
                        }
                      >
                        {image.user.username}
                      </ImageUserMeta>
                    )}
                  </ImageCard>
                );
              })}
              {isLoading ? (
                [...Array(3).fill(0)].map((_, index) => (
                  <ImageCardShimmer
                    className="image-suggestion-grid-item"
                    key={index}
                    index={index}
                  />
                ))
              ) : (
                <div
                  className="image-suggestion-grid-item end-of-list"
                  ref={setEndOfListRef}
                />
              )}
            </>
          )}
        </ImageList>
      </ToolContentBody>
    </>
  );
};

export default ImageSuggestion;

import React from "react";
import { Image } from "rebass/styled-components";
import {
  CTADisclaimer,
  CTAAction,
  CTAContainer,
  CTAContent,
  CTAEmailInput,
  CTAHeader,
  CTAParagraph,
  CTATitle,
  PreviewContainer
} from "./styles";

function CTAPreview(props) {
  const {
    cta: {
      companyName,
      companyLogo,
      ctaType,
      ctaTitle,
      ctaContent,
      ctaButtonText,
      ctaButtonLink
    }
  } = props;

  return (
    <PreviewContainer flexDirection="column">
      <CTADisclaimer>
        This is a preview{" "}
        <span role="img" aria-label="Finger pointing down">
          👇🏻
        </span>
      </CTADisclaimer>
      <CTAContainer>
        <CTAHeader>
          <Image src={companyLogo} width={24} height={24} mr={17} />
          {companyName}
        </CTAHeader>
        <CTAContent flexDirection="column">
          <CTATitle>{ctaTitle}</CTATitle>
          <CTAParagraph>{ctaContent}</CTAParagraph>

          {ctaType === "form" && (
            <CTAEmailInput placeholder="name@company.com" />
          )}
          {ctaType === "form" && (
            <CTAAction onClick={() => alert()}>{ctaButtonText}</CTAAction>
          )}
          {ctaType === "button" && ctaButtonLink && (
            <CTAAction href={ctaButtonLink} target="_blank">
              {ctaButtonText !== "" ? ctaButtonText : "Visit our website"}
            </CTAAction>
          )}
        </CTAContent>
      </CTAContainer>
      {/*
       <Box mt={20} alignSelf="center">
       <a href="http://google.com">Show Live Preview</a>
       </Box>
       */}
    </PreviewContainer>
  );
}

export default CTAPreview;

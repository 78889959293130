import styled, { css } from "styled-components";

import { Button, Flex } from "rebass/styled-components";
import { Input } from "components/common/form/styles";

export const Wrapper = styled.div`
  max-width: 678px;
  margin: auto;
`;

export const KeywordHeader = styled(Flex)`
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
  align-items: center;
`;

export const HeaderIcon = styled.img`
  width: 48px;
  height: 48px;
  background: #ffffff;
  box-shadow: ${props => props.theme.shadows.shadowXS};
  border-radius: 10px;
`;

export const TitleInput = styled(Input)`
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.015em;
  flex: 1;
  box-sizing: border-box;
  outline: none;

  ::placeholder {
    color: ${props => props.theme.colors.text04};
  }
`;

export const ConditionWrapper = styled.div`
  background-color: ${props => props.theme.colors.grey01};
  padding: 16px;
  border-radius: 12px;
`;

export const Condition = styled(Flex)`
  justify-content: space-between;
  align-items: center;
`;

export const ConditionContent = styled(Flex)`
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const ConditionOptions = styled.div`
  display: grid;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  grid-template-columns: 24px 1fr 24px;
  column-gap: 6px;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: ${props => props.theme.colors.grey01};

  .icon {
    font-size: 20px;
    color: ${props => props.theme.colors.text01};
  }

  .rotate {
    transform-origin: center;
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
  }

  :hover {
    background-color: ${props => props.theme.colors.grey02};
  }
`;

export const CategoryLabel = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${props => props.theme.colors.text01};
`;

export const Label = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  color: ${props => props.theme.colors.text03};
`;

export const KeywordMainWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  min-height: 40px;
`;

export const KeywordInput = styled(Input)<{ isInvalid: boolean }>`
  font-weight: 500;
  width: auto;
  box-sizing: border-box;
  padding: 12px 0px 12px 6px;
  border-radius: 6px;
  background: none;
  min-width: 200px;
  line-height: 16px;

  :hover {
    background: ${props => props.theme.colors.grey01};

    ::placeholder {
      color: ${props => props.theme.colors.text01};
    }
  }

  ::placeholder {
    font-weight: 500;
    color: ${props => props.theme.colors.text03};
  }

  :not(:placeholder-shown) {
    background-color: ${(props: any) => props.theme.colors.grey01};
  }
  :focus {
    ${props => {
      if (props.isInvalid) {
        return css`
          outline: none;
          box-shadow:
            0 0 1px ${props => props.theme.colors.red},
            0 0 0 1px ${props => props.theme.colors.red},
            0 0 0 2px ${props => props.theme.colors.red} !important;
        `;
      } else {
        return css`
          box-shadow:
            0 0 1px ${props => props.theme.colors.background1},
            0 0 0 1px ${props => props.theme.colors.background1},
            0 0 0 2px ${props => props.theme.colors.iconFill4} !important;
        `;
      }
    }}
    background-color: ${(props: any) => props.theme.colors.grey01};
    border: none;
  }
`;

export const PreviewInfo = styled.div`
  display: flex;
  padding: 10px 12px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.orangeSubtle};
  color: ${props => props.theme.colors.orange};
  gap: 4px;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 20px;

  > span {
    font-size: 18px;
  }
`;

export const NoResult = styled.span`
  text-align: center;
  color: ${props => props.theme.colors.text02};
  font-weight: 400;
`;

export const TipsButton = styled.a`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  outline: none;
  border: none;
  color: ${props => props.theme.colors.text02};
  font-weight: 600;
  gap: 6px;
  background: ${props => props.theme.colors.grey01};
  border-radius: 16px;
  :hover {
    color: ${props => props.theme.colors.text01};
    background: ${props => props.theme.colors.grey02};
  }
`;

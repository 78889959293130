import React, { useState } from "react";
import {
  WidgetMetric,
  WidgetMetricValue,
  WidgetMetricLabel
} from "../../analytics/widgets/Metrics";

import WidgetTooltip from "../../analytics/widgets/WidgetTooltip";
import Popper from "components/common/Popper";

const Metric = ({ metric, value, label, description }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);

  return (
    <>
      <WidgetMetric
        centered
        onMouseEnter={() => setShowInfo(true)}
        onMouseLeave={() => setShowInfo(false)}
        ref={setReferenceElement}
      >
        <WidgetMetricValue metric={metric}>{value ?? "-"}%</WidgetMetricValue>
        <WidgetMetricLabel>{label}</WidgetMetricLabel>
      </WidgetMetric>
      <Popper referenceElement={referenceElement} visible={showInfo}>
        <WidgetTooltip description={description} />
      </Popper>
    </>
  );
};

export default Metric;

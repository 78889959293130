import styled from "styled-components";

export const ThumbnailImage = styled.div<{
  thumbnail?: string;
}>`
  position: relative;
  border-radius: 12px;
  padding-top: 60%;

  background: url("${props =>
      !props.thumbnail ? props.theme.colors.grey02 : props.thumbnail}")
    no-repeat center center ${props => props.theme.colors.grey02};
  background-size: cover;
  margin-bottom: 20px;
`;

import React from "react";
import { Box } from "rebass";
import { connect, useSelector } from "react-redux";
import { ExploreSectionHeader } from "../styles";

import {
  unfollow,
  follow,
  followExternalSources,
  ISource,
  IDomain,
  IExternalTwitterSource
} from "services/news";

import Button from "components/common/Button";
import { Headline2 } from "components/common/styles";
import ExploreSource from "../../widgets/ExploreSource";
import { RecommendationGrid } from "../../recommendations/styles";
import { IExternSourceRequest } from "services/news.types";
import { useHistory } from "react-router-dom";

interface IExploreSourcesProps {
  title: string;
  type: string;
  sources: any;
  followedSources: Array<any>;
  searchContext: string;
  changeSearchContext: (context: string) => void;
}

const ExploreSources: React.FC<IExploreSourcesProps> = ({
  title,
  type,
  sources,
  followedSources,
  searchContext,
  changeSearchContext
}) => {
  const history = useHistory();
  const account = useSelector((state: any) => state.account.data);

  const addRssSource = async (source: ISource) => {
    const twitterSubscribeData = {
      type: "rss",
      url: source.url
    };

    try {
      await followExternalSources(twitterSubscribeData);
    } catch (_) {}
  };

  const addTwitterSource = async (source: ISource | IExternalTwitterSource) => {
    let twitterSubscribeData: IExternSourceRequest = {
      type: "twitter",
      handle: source.handle
    };
    if (!("id" in source)) {
      const tempSource = source as IExternalTwitterSource;
      twitterSubscribeData = {
        ...twitterSubscribeData,
        image: tempSource.image,
        title: tempSource.title
      };
    }

    try {
      await followExternalSources(twitterSubscribeData);
    } catch (_) {}
  };

  const addSource = async (source: IDomain) => {
    try {
      await follow("domains", source.id);
    } catch (_) {}
  };

  const deleteSource = async (
    source: IDomain | ISource | IExternalTwitterSource
  ) => {
    try {
      if ("id" in source) {
        if ("domain" in source) {
          await unfollow("domains", source.id);
        } else {
          await unfollow("sources", source.id);
        }
      } else {
        const storedSource: ISource = followedSources.find(
          src => src.handle === source.handle
        );
        if (storedSource) {
          await unfollow("sources", storedSource.id);
        }
      }
    } catch (_) {}
  };

  const addCallBack = (function () {
    switch (type) {
      case "rss":
        return addRssSource;
      case "twitter":
        return addTwitterSource;
      default:
        return addSource;
    }
  })();

  return (
    <Box my={32}>
      <ExploreSectionHeader>
        <Headline2>{title}</Headline2>
        {searchContext === "all" && (
          <Box>
            {/* @ts-ignore */}
            <Button
              size={"xs"}
              variant={"tertiary"}
              onClick={changeSearchContext}
            >
              Discover all
            </Button>
          </Box>
        )}
      </ExploreSectionHeader>
      <RecommendationGrid perColoum={5}>
        {(searchContext !== "all" ? sources : sources.slice(0, 5)).map(
          ({
            detail
          }: {
            detail: ISource | IDomain | IExternalTwitterSource;
          }) => {
            let type = "domains";

            if ("type" in detail && ["rss", "twitter"].includes(detail.type)) {
              type = detail.type === "twitter" ? "influencers" : detail.type;
            }

            return (
              <ExploreSource
                data={detail}
                type={type}
                key={"id" in detail ? detail.id : detail.handle}
                onFollowSource={addCallBack}
                onUnfollowSource={deleteSource}
                onClick={() => {
                  if (!("id" in detail)) return;

                  history.push(
                    `/accounts/${account.id}/content/${type}/${detail.id}`
                  );
                }}
              />
            );
          }
        )}
      </RecommendationGrid>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    followedSources:
      state.content.sections.find((section: any) => section.type === "sources")
        ?.details ?? []
  };
};

export default connect(mapStateToProps, {})(ExploreSources);

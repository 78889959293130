import {
  Title,
  Legend,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale,
  Chart as ChartJS
} from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";
import { format, parseISO } from "date-fns";

import { options } from "./config";

ChartJS.register(
  Title,
  Legend,
  Tooltip,
  LinearScale,
  LineElement,
  PointElement,
  CategoryScale
);

export default function LinePlot({ data, metricId, series, color }) {
  const plotData = {
    labels: data.map(entry => format(parseISO(entry.date), "dd/MM")),
    datasets: [
      {
        backgroundColor: color,
        borderRadius: 8,
        width: 2,
        label: metricId,
        data: data.map(entry => entry[series]),
        tension: 0.25,
        pointStyle: "dot",
        pointRadius: 0,
        borderColor: color
      }
    ]
  };
  return <Line data={plotData} options={{ ...options }} />;
}

import styled from "styled-components";

export const TooltipWrapper = styled.div`
  * {
    box-sizing: border-box;
  }

  display: flex;
  padding-top: 6px;
  box-sizing: border-box;
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  transform: ${props => (props.isVisible ? `scale(1)` : `scale(0)`)};
  transition: transform 0.15s cubic-bezier(0.3, 1.2, 0.2, 1);
`;

export const Tooltip = styled.div`
  width: 322px;
  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 100px;
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.shadowM};
  border: 1px solid ${props => props.theme.colors.grey02};
`;

export const Link = styled.div`
  gap: 10px;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 0px 8px 0px 18px;

  > i {
    font-size: 20px;
    color: ${props => props.theme.colors.text03};
  }

  > span {
    font-size: 14px;
    overflow: hidden;
    font-weight: 600;
    line-height: 17.5px;
    white-space: nowrap;
    align-self: flex-start;
    text-overflow: ellipsis;
    color: ${props => props.theme.colors.text04};
  }
`;

export const ButtonWrapper = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  padding: 6px 8px;
  align-items: center;
  border-left: 1px solid ${props => props.theme.colors.grey04};

  button {
    height: 100%;
    white-space: nowrap;
  }
`;

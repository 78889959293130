import { Dispatch } from "react";

import {
  SET_CHANNELS_IS_LOADING,
  SET_CHANNELS_PERIOD,
  SET_CHANNELS_DATA,
  SET_CHANNELS_EMPLOYEES_DATA,
  SET_CHANNELS_PER_CHANNEL_DATA,
  SET_CHANNELS_CHANNEL_IDS,
  SET_ALL_POSTS_IS_LOADING,
  SET_ALL_POSTS_PERIOD,
  SET_ALL_POSTS_DATA,
  SET_ALL_POSTS_CHANNEL_IDS,
  SET_ALL_POSTS_CATEGORIES,
  SET_REPURPOSE_POSTS_ENGAGEMENT_RATE,
  SET_REPURPOSE_POSTS_DATA,
  SET_REPURPOSE_POSTS_IS_LOADING,
  SET_REPURPOSE_POSTS_PERIOD,
  SET_REPURPOSE_POSTS_CHANNEL_IDS,
  SET_REPURPOSE_POSTS_CATEGORIES,
  SET_LINKS_IS_LOADING,
  SET_LINKS_PERIOD,
  SET_LINKS_DATA,
  SET_LINKS_CHANNEL_IDS,
  SET_LINKS_CATEGORIES
} from "./actions";
import { IActions } from "./actionCreators";
import { periodMenuItems } from "components/analytics/widgets/PeriodSelect";

interface IChannelStatPoints {
  date: string;
  total: number | null;
  variation: number | null;
}

interface IChannelStats {
  total: number | null;
  points: IChannelStatPoints[];
  period: number;
  previous: number | null;
  previousTotal: number | null;
}

interface IChannelsData {
  engagements?: IChannelStats;
  impressions?: IChannelStats;
  followers?: IChannelStats;
}

interface IPerChannel {
  service: string;
  serviceType: string;
  username: string;
  avatar: string;
  id: string;
  stats: {
    engagements: IChannelStats;
    impressions: IChannelStats;
    followers: IChannelStats;
    allPosts: Omit<IChannelStats, "points">;
    engagementRate: Omit<IChannelStats, "period,points">;
  };
}

export interface IAnalyticsState {
  channels: {
    isLoading: boolean;
    period: (typeof periodMenuItems)[number]["value"];
    channelIds: string[];
    data: null | IChannelsData;
    employeesData: IPerChannel[];
    perChannelData: IPerChannel[];
  };
  allPosts: {
    isLoading: boolean;
    period: (typeof periodMenuItems)[number]["value"];
    channelIds: string[];
    categories: string[];
    data: any[];
  };
  repurposePosts: {
    isLoading: boolean;
    period: (typeof periodMenuItems)[number]["value"];
    channelIds: string[];
    categories: string[];
    engagementRate: number;
    data: any[];
  };
  links: {
    isLoading: boolean;
    period: (typeof periodMenuItems)[number]["value"];
    channelIds: string[];
    categories: string[];
    data: any[];
  };
}

export type IAnalyticsDispatch = Dispatch<IActions>;

export const initialValues: IAnalyticsState = {
  channels: {
    isLoading: true,
    period: 30,
    channelIds: [],
    data: null,
    employeesData: [],
    perChannelData: []
  },
  allPosts: {
    isLoading: true,
    period: 30,
    channelIds: [],
    categories: [],
    data: []
  },
  repurposePosts: {
    isLoading: true,
    period: 365,
    channelIds: [],
    categories: [],
    engagementRate: 3,
    data: []
  },
  links: {
    isLoading: true,
    period: 30,
    channelIds: [],
    categories: [],
    data: []
  }
};

export const analyticsReducer = (
  state: IAnalyticsState = initialValues,
  action: IActions
): IAnalyticsState => {
  switch (action.type) {
    case SET_CHANNELS_IS_LOADING: {
      return {
        ...state,
        channels: {
          ...state.channels,
          isLoading: action.payload
        }
      };
    }

    case SET_CHANNELS_PERIOD: {
      return {
        ...state,
        channels: {
          ...state.channels,
          period: action.payload
        }
      };
    }

    case SET_CHANNELS_DATA: {
      return {
        ...state,
        channels: {
          ...state.channels,
          data: action.payload
        }
      };
    }

    case SET_CHANNELS_EMPLOYEES_DATA: {
      return {
        ...state,
        channels: {
          ...state.channels,
          employeesData: action.payload
        }
      };
    }

    case SET_CHANNELS_PER_CHANNEL_DATA: {
      return {
        ...state,
        channels: {
          ...state.channels,
          perChannelData: action.payload
        }
      };
    }

    case SET_CHANNELS_CHANNEL_IDS: {
      return {
        ...state,
        channels: {
          ...state.channels,
          channelIds: action.payload
        }
      };
    }

    case SET_ALL_POSTS_IS_LOADING: {
      return {
        ...state,
        allPosts: {
          ...state.allPosts,
          isLoading: action.payload
        }
      };
    }

    case SET_ALL_POSTS_PERIOD: {
      return {
        ...state,
        allPosts: {
          ...state.allPosts,
          period: action.payload
        }
      };
    }

    case SET_ALL_POSTS_DATA: {
      return {
        ...state,
        allPosts: {
          ...state.allPosts,
          data: action.payload
        }
      };
    }

    case SET_ALL_POSTS_CHANNEL_IDS: {
      return {
        ...state,
        allPosts: {
          ...state.allPosts,
          channelIds: action.payload
        }
      };
    }

    case SET_ALL_POSTS_CATEGORIES: {
      return {
        ...state,
        allPosts: {
          ...state.allPosts,
          categories: action.payload
        }
      };
    }

    case SET_REPURPOSE_POSTS_IS_LOADING: {
      return {
        ...state,
        repurposePosts: {
          ...state.repurposePosts,
          isLoading: action.payload
        }
      };
    }

    case SET_REPURPOSE_POSTS_PERIOD: {
      return {
        ...state,
        repurposePosts: {
          ...state.repurposePosts,
          period: action.payload
        }
      };
    }

    case SET_REPURPOSE_POSTS_ENGAGEMENT_RATE: {
      return {
        ...state,
        repurposePosts: {
          ...state.repurposePosts,
          engagementRate: action.payload
        }
      };
    }

    case SET_REPURPOSE_POSTS_DATA: {
      return {
        ...state,
        repurposePosts: {
          ...state.repurposePosts,
          data: action.payload
        }
      };
    }

    case SET_REPURPOSE_POSTS_CHANNEL_IDS: {
      return {
        ...state,
        repurposePosts: {
          ...state.repurposePosts,
          channelIds: action.payload
        }
      };
    }

    case SET_REPURPOSE_POSTS_CATEGORIES: {
      return {
        ...state,
        repurposePosts: {
          ...state.repurposePosts,
          categories: action.payload
        }
      };
    }

    case SET_LINKS_IS_LOADING: {
      return {
        ...state,
        links: {
          ...state.links,
          isLoading: action.payload
        }
      };
    }

    case SET_LINKS_PERIOD: {
      return {
        ...state,
        links: {
          ...state.links,
          period: action.payload
        }
      };
    }

    case SET_LINKS_DATA: {
      return {
        ...state,
        links: {
          ...state.links,
          data: action.payload
        }
      };
    }

    case SET_LINKS_CHANNEL_IDS: {
      return {
        ...state,
        links: {
          ...state.links,
          channelIds: action.payload
        }
      };
    }

    case SET_LINKS_CATEGORIES: {
      return {
        ...state,
        links: {
          ...state.links,
          categories: action.payload
        }
      };
    }

    default: {
      throw new Error("Unhandled action type");
    }
  }
};

import store from "state/store";
import { willowExtensionLink } from "constants/index";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import FeatureGrid from "components/modals/pop-ups/milliPopUp/FeatureGrid";
import milliAnalytics from "assets/images/milli-popup/milli-analytics.png";
import { IFeatureNotificationPayload } from "contextApi/notificationContext";
import milliGetStarted from "assets/images/milli-popup/milli-get-started.png";
import TestimonialVideo from "components/modals/pop-ups/milliPopUp/TestimonialVideo";
import ExtensionIntro from "components/modals/pop-ups/willowExtensionPopUp/ExtensionIntro";
import ExpertsAndTestimonials from "components/modals/pop-ups/milliPopUp/ExpertsAndTestimonials";

export const milliPopUpFeatureNotification: IFeatureNotificationPayload = {
  id: "milli_pop_up",
  maxWidth: 782,
  dismissible: false,
  steps: [
    {
      title: "Looking to take your social media to the next level?",
      description:
        "You can have great-looking socials without any social media knowledge or paying huge consulting fees. All you need is 30 minutes and a dedicated Social Media Expert to strategize, collaborate, and create with, at your own pace.",
      proceedButtonText: "Next",
      banner: milliAnalytics
    },
    {
      component: FeatureGrid,
      proceedButtonText: "Next",
      returnButtonText: null
    },
    {
      component: ExpertsAndTestimonials,
      proceedButtonText: "Next",
      returnButtonText: null
    },
    {
      component: TestimonialVideo,
      proceedButtonText: "Next",
      returnButtonText: null
    },
    {
      title: "Become more successful on social media",
      description:
        "Elevate your social media game with an exclusive offer: Get a FREE 1-on-1 social media inspiration session with our CEO! Discover the secrets to success directly from the industry's top expert and gain invaluable insights tailored specifically to your brand.",
      banner: milliGetStarted,
      onProceed: async () => {
        try {
          const hubspotCoachingLink =
            "https://meetings.hubspot.com/brent-schepers";

          window.open(hubspotCoachingLink, "_blank");
          store.dispatch(
            trackAnalyticsEvent("Get Started with Coaching clicked")
          );
        } catch (_) {}
      },
      returnButtonText: null,
      cancelButtonText: "Keep working by myself"
    }
  ]
};

export const willowExtensionFeatureNotification: IFeatureNotificationPayload = {
  id: "willow_extension",
  maxWidth: 799,
  dismissible: false,
  buttonLayout: "horizontal",
  steps: [
    {
      component: ExtensionIntro,
      onProceed: async () => {
        try {
          window.open(willowExtensionLink, "_blank");
          store.dispatch(
            trackAnalyticsEvent("Extension Download Link Clicked")
          );
        } catch (_) {}
      },
      proceedButtonText: "Download Now",
      returnButtonText: null,
      cancelButtonText: "Dismiss",
      cancelButtonVariant: "secondaryQuiet",
      onCancel: async () => {
        try {
          store.dispatch(trackAnalyticsEvent("Extension Link Dismissed"));
        } catch (_) {}
      }
    }
  ]
};

import React, { useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { closeModal } from "../../../state/actions/ModalActions";
import { ContentoModal } from "../ContentoModal";
import { Box, Flex } from "rebass";
import { IconButton, InlineButton } from "../../common/styles";
import {
  ErrorMessage,
  StyledInput,
  StyledInputLabel
} from "../../common/form/styles";
import Button from "../../common/Button";
import { useToaster } from "@hellocontento/maillard";
import { ModalBorderHeader } from "../styles";
import { X } from "styled-icons/feather";
import { Rss } from "styled-icons/heroicons-solid";
import isUrl from "is-url";

import { followExternalSources } from "services/news";

const DialogButtonGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 12px;
`;

const ModalTitle = styled(Box)`
  align-items: center;
  column-gap: 8px;
  display: grid;
  grid-template-columns: 24px 1fr;
`;

const RssIcon = styled(Flex)`
  align-items: center;
  background-color: ${props => props.theme.colors.orange};
  border-radius: 16px;
  color: white;
  height: 24px;
  justify-content: center;
  width: 24px;
`;

const AddRSSFeedModal = ({ closeModal }) => {
  const [feedUrl, setFeedUrl] = useState("");
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const addToast = useToaster();

  const handleAddFeed = async e => {
    e.preventDefault();
    setSubmitDisabled(true);

    if (!isUrl(feedUrl.trim())) {
      setInvalidUrl(true);
      setSubmitDisabled(false);
      return;
    }

    try {
      await followExternalSources({
        type: "rss",
        url: feedUrl.trim()
      });
      setFeedUrl("");
      closeModal();
      addToast("Feed successfully added", "success");
    } catch (err) {
      if (err.name === "ValidationError") {
        addToast(err.message, "error");
      } else {
        addToast(
          "There was an error adding the feed. Please try again later or contact support.",
          "error"
        );
      }
    } finally {
      setSubmitDisabled(false);
    }
  };

  return (
    <ContentoModal showHeader={false} onClose={closeModal}>
      <ModalBorderHeader>
        <ModalTitle>
          <RssIcon>
            <Rss size={18} />
          </RssIcon>
          Add RSS feed
        </ModalTitle>
        <IconButton onClick={() => closeModal()}>
          <X size={32} />
        </IconButton>
      </ModalBorderHeader>
      <Box>
        <Box mt={24} mb={4}>
          <StyledInputLabel>
            URL
            <small>
              Add the RSS feeds of your favorite sources.{" "}
              <InlineButton>Learn more.</InlineButton>
            </small>
          </StyledInputLabel>
          <StyledInput
            type={"url"}
            placeholder={"https://"}
            onChange={e => setFeedUrl(e.target.value)}
            hasError={invalidUrl}
            autoFocus
            required
          />
          {invalidUrl && (
            <ErrorMessage>Sorry, this doesn't look like a URL</ErrorMessage>
          )}
        </Box>
        <DialogButtonGrid>
          <Button variant={"secondary"} size={"m"} onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={handleAddFeed} size={"m"} disabled={submitDisabled}>
            Save
          </Button>
        </DialogButtonGrid>
      </Box>
    </ContentoModal>
  );
};

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(AddRSSFeedModal);

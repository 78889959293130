// @ts-nocheck
import React from "react";

import {
  MenuList,
  MenuDivider,
  MenuContainer
} from "components/navigation/Menu";
import { MenuItem } from "./styles";

interface ITaskMenu {
  isTaskSeries: boolean;
  editTask: (e: React.MouseEvent) => void;
  writePost: (e: React.MouseEvent) => void;
  writeDraft: (e: React.MouseEvent) => void;
  deleteTask: (e: React.MouseEvent) => void;
  editTaskSeries: (e: React.MouseEvent) => void;
}

const TaskMenu: React.FC<ITaskMenu> = ({
  editTask,
  writePost,
  writeDraft,
  deleteTask,
  isTaskSeries,
  editTaskSeries
}) => {
  return (
    <MenuContainer width={240}>
      <MenuList>
        <MenuItem
          icon="write"
          color="green"
          label="Write post"
          onClick={writePost}
        />
        <MenuItem label="Write draft" icon="draft" onClick={writeDraft} />
      </MenuList>
      <MenuDivider my={2} />
      <MenuList>
        <MenuItem label="Edit this task" icon="edit" onClick={editTask} />
        {isTaskSeries && (
          <MenuItem
            icon="edit-series"
            onClick={editTaskSeries}
            label="Edit all tasks in this series"
          />
        )}
      </MenuList>
      <MenuDivider my={2} />
      <MenuList>
        <MenuItem
          icon="delete"
          color="danger"
          label="Delete task"
          onClick={deleteTask}
        />
      </MenuList>
    </MenuContainer>
  );
};

export default TaskMenu;

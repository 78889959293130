import { Box, Flex } from "rebass";
import styled from "styled-components";

export const SearchBarWrapper = styled.div`
  width: calc(100% + 6px);
  margin-left: -6px;
  background-color: #f4f5f6;
  position: sticky;
  top: 128px;
  z-index: 3;
  backdrop-filter: blur(20px);
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GridWrapper = styled.div`
  display: flex;
  margin: auto;
  flex: 1;
  justify-content: space-between;
  max-width: 928px;
  margin: 0 32px;
  padding: 10px 0px;
`;

export const SearchInputWrapper = styled(Flex)`
  padding: 0px 20px;
  height: 48px;
  border-radius: 100px;
  background: ${props => props.theme.colors.white};
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-right: 16px !important;
  box-shadow:
    0px 0px 0px 1px rgba(28, 30, 31, 0.05),
    0px 3px 6px rgba(28, 30, 31, 0.05);
`;

export const SearchInputLeft = styled(Flex)`
  gap: 10px;
  flex: 1;
  > span {
    font-size: 20px;
  }
`;

export const SearchInputRight = styled(Flex)`
  > span {
    font-size: 20px;
  }
`;

export const SearchInput = styled.input`
  appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
  display: block;
  flex: 1;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  outline: none;
`;

export const SearchOptions = styled(Box)<{ items?: number }>`
  display: grid;
  grid-template-columns: repeat(${props => props.items || 4}, max-content);
  column-gap: 12px;
  margin-left: 12px;
  padding: 8px 0 8px 20px;
  border-left: 1px solid rgba(126, 126, 126, 0.16);
`;

import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { useState } from "react";
import { Box, Flex } from "rebass";
import Button from "../../common/Button";
import {
  MenuContainer,
  MenuDivider,
  MenuFooter,
  MenuList
} from "../../navigation/Menu";
import Checkbox from "../../common/form/Checkbox";
import SimpleBar from "simplebar-react";
import Popper from "components/common/Popper";

import { FilterButton } from "./styles";

const SearchSelectItem = styled(Box)`
  align-items: center;
  border-radius: 12px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 12px;
  margin: 0 8px;
  padding: 8px;

  &:hover {
    background-color: ${props => props.theme.colors.grey01};
  }
`;

const ContentSearchBarInput = styled.input`
  appearance: none;
  background-color: ${props => props.theme.colors.grey01};
  border-radius: 24px;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  color: ${props => props.theme.colors.text01};
  font-family: ${props => props.theme.fonts.body};
  font-size: 14px;
  outline: none;
  padding: 10px 16px;
  width: 100%;
`;

const ButtonContentWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: 4px;

  span {
    font-size: 18px;
    transform: rotate(90deg);
  }
`;

function SearchBarTopics({ selected = [], topics, onChange }) {
  const [locallySelected, setLocallySelected] = useState(selected);
  const [topicFilter, setTopicFilter] = useState("");

  const handleChangeTopics = topic => {
    if (locallySelected.includes(topic))
      setLocallySelected(locallySelected.filter(t => t !== topic));
    else setLocallySelected([...locallySelected, topic]);
  };

  const handleSubmit = () => {
    onChange(locallySelected);
  };

  return (
    <MenuContainer>
      <Box px={3} py={1}>
        <ContentSearchBarInput
          onChange={e => setTopicFilter(e.target.value)}
          placeholder={"Filter tags"}
          value={topicFilter}
        />
      </Box>
      <MenuDivider my={2} />
      <SimpleBar style={{ maxHeight: "300px", minWidth: "300px" }}>
        <MenuList>
          {topics
            .filter(t => t.value.includes(topicFilter))
            .map(topic => (
              <SearchSelectItem
                key={topic.value}
                onClick={() => handleChangeTopics(topic.value)}
              >
                <Checkbox
                  onChange={() => handleChangeTopics(topic.value)}
                  checked={locallySelected.includes(topic.value)}
                />
                <span>
                  {topic.value} ({topic.total})
                </span>
              </SearchSelectItem>
            ))}
        </MenuList>
      </SimpleBar>
      <MenuFooter>
        <Flex>
          <Button
            size={"sm"}
            variant={"tertiary"}
            onClick={() => setLocallySelected([])}
          >
            Clear all
          </Button>
        </Flex>
        <Button
          size={"sm"}
          onClick={handleSubmit}
          disabled={_.isEqual(locallySelected, selected)}
        >
          Show results
        </Button>
      </MenuFooter>
    </MenuContainer>
  );
}

const SearchByTopics = ({ selectedTopics, topics, onSetTopics }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  return (
    <div ref={setReferenceElement}>
      <FilterButton
        onClick={toggleShowOptions}
        variant={selectedTopics.length > 0 ? "primary" : "secondary"}
        minWidth={"120px"}
      >
        <ButtonContentWrapper>
          {selectedTopics.length === 0
            ? "All topics"
            : selectedTopics.length === 1
              ? "1 topic"
              : `${selectedTopics.length} topics`}
          <span className="icon-arrowright" />
        </ButtonContentWrapper>
      </FilterButton>
      <Popper
        referenceElement={referenceElement}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[referenceElement]}
      >
        <SearchBarTopics
          selected={selectedTopics}
          topics={topics}
          onChange={onSetTopics}
        />
      </Popper>
    </div>
  );
};

export default SearchByTopics;

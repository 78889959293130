import styled from "styled-components";

import { Flex } from "rebass";

export const ExploreSectionHeader = styled(Flex)`
  align-items: flex-start;
  justify-content: space-between;
  height: 54px;
  margin-top: 12px;
  margin-bottom: 16px;
`;

import {
    Home,
    Bookmark,
    PurchaseTag,
    DonateHeart,
  } from "styled-icons/boxicons-solid";
  import { Slider, Rss, Bulb } from "styled-icons/boxicons-regular";
  import { Twitter } from "styled-icons/boxicons-logos";
import WhatsAppSquareIcon from "components/ideas/UserIdeas/WhatsAppSquareIcon";
  
  
  export const contentSideNav = [
    {
      type: "link",
      label: "For you",
      icon: Home,
      to: "/for-you"
    },
    {
      type: "link",
      label: "Personalize",
      icon: Slider,
      to: "/preferences"
    },
    {
      type: "divider",
      label: "Filters"
    },
    {
      type: "link",
      label: "Saved for later",
      icon: Bookmark,
      to: "/saved-for-later"
    },
    {
      type: "link",
      label: "Topics",
      icon: PurchaseTag,
      to: "/topics"
    },
    {
      type: "link",
      label: "Influencers",
      icon: Twitter,
      to: "/influencers"
    },
    {
      type: "link",
      label: "RSS Feeds",
      icon: Rss,
      to: "/rss"
    },
    {
      type: "link",
      label: "Recommended",
      icon: DonateHeart,
      to: "/suggested"
    }
  ];
  
  export const settingsSideNav = [
    {
      type: "link",
      label: "Account",
      to: "/account",
      icon: "icon-user"
    },
    {
      type: "link",
      label: "Channels",
      to: "/channels",
      icon: "icon-send"
    },
    {
      type: "link",
      label: "Team",
      to: "/team",
      icon: "icon-team"
    },
    {
      type: "link",
      label: "Hashtags",
      to: "/hashtags",
      icon: "icon-hashtag"
    },
    {
      type: "link",
      label: "Notifications",
      to: "/notifications",
      icon: "icon-notification"
    },
    {
      type: "link",
      label: "Subscription",
      to: "/billing",
      icon: "icon-billing"
    },
    {
      type: "link",
      label: "Security",
      to: "/security",
      icon: "icon-security"
    },
    {
      type: "link",
      label: "Call to action",
      to: "/call-to-action",
      icon: "icon-call-to-action",
      requiredFeature: "cta"
    },
    {
      type: "link",
      label: "Referral",
      to: "/referral",
      icon: "icon-present"
    },
    {
      type: "link",
      label: "Willow+ Extension",
      to: "/willow+",
      icon: "icon-sparkle"
    }
  ];
  
  export const newsSideNavNew = [
    {
      type: "link",
      label: "Your ideas",
      to: "/your-ideas",
      icon: Bulb,
      activeIcon: Bulb
    },
    {
      type: "link",
      label: "Shared via WhatsApp",
      to: "/whatsapp",
      icon: WhatsAppSquareIcon,
      activeIcon: WhatsAppSquareIcon
    },
    {
      type: "link",
      label: "Settings",
      to: "/preferences",
      icon: "icon-settings",
      activeIcon: "icon-settings-filled"
    }
  ];
  
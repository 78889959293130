import React from "react";
import { Switch, Route, Redirect, RouteComponentProps } from "react-router-dom";
import { useAppState } from "../../contextApi/appContext";

import LinksPage from "components/analytics/LinksPage";
import PostsPage from "components/analytics/PostsPage";
import AnalyticsOverview from "components/analytics/AnalyticsOverview";

const AnalyticsRoutes: React.FC<RouteComponentProps> = ({ match }) => {
  const showPostLinksTable = useAppState(state => state.showPostLinksTable);

  return (
    <Switch>
      <Route path={match.url + "/overview"} component={AnalyticsOverview} />
      <Route path={match.url + "/posts"} component={PostsPage} />
      {showPostLinksTable && (
        <Route path={match.url + "/links"} component={LinksPage} />
      )}
      <Redirect to={match.url + "/overview"} />
    </Switch>
  );
};

export default AnalyticsRoutes;

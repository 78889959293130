import styled from "styled-components";

export const TagWrapper = styled.span`
  background: rgba(96, 116, 128, 0.08);
  border-radius: 24px;
  padding: 8px 12px;

  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
  color: ${props => props.theme.colors.text03};

  :hover {
    background: rgba(102, 119, 128, 0.16);
    color: ${props => props.theme.colors.text01};
    cursor: pointer;
  }
`;

export const TagGroupWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: max-content;
`;

import React, { useEffect, useRef } from "react";
import { theme } from "../../theme";
import * as d3 from "d3";

export default function HealthDials({ data = {}, previous = null }) {
  const chartRef = useRef(null);
  const size = 120;

  const dials = [
    {
      name: "consistency",
      currentEndAngle: previous ? previous.consistency / 100.0 : 0.0,
      targetEndAngle: data.consistency ? data.consistency / 100.0 : 0.0
    },
    {
      name: "contentMix",
      currentEndAngle: previous ? previous.contentMix / 100.0 : 0.0,
      targetEndAngle: data.contentMix ? data.contentMix / 100.0 : 0.0
    },
    {
      name: "employeeEngagement",
      currentEndAngle: previous ? previous.employeeEngagement / 100.0 : 0.0,
      targetEndAngle: data.employeeEngagement
        ? data.employeeEngagement / 100.0
        : 0.0
    }
  ];

  const arcs = [
    {
      name: "consistency",
      innerRadius: size / 2 - 24,
      outerRadius: size / 2 - 32
    },
    {
      name: "contentMix",
      innerRadius: size / 2 - 12,
      outerRadius: size / 2 - 20
    },
    {
      name: "employeeEngagement",
      innerRadius: size / 2,
      outerRadius: size / 2 - 8
    }
  ];

  useEffect(() => {
    if (!chartRef.current || !data || Object.keys(data).length === 0) return;
    const i = d3.interpolate(0.0, 6.29);

    const figures = {};

    const initArc = (ir, or) =>
      d3.arc().innerRadius(ir).outerRadius(or).cornerRadius(16);

    const arcTween = (transition, el, newStartAngle, newFinishAngle) => {
      transition.attrTween("d", function (d) {
        var interpolateEnd = d3.interpolate(d.endAngle, newFinishAngle);
        return function (t) {
          d.startAngle = newStartAngle;
          d.endAngle = interpolateEnd(t);
          return el(d);
        };
      });
    };

    let svg;

    if (!chartRef.current.firstChild) {
      svg = d3
        .select(chartRef.current)
        .append("svg")
        .attr("width", size)
        .attr("height", size);
    } else {
      svg = d3.select(chartRef.current).select("svg");
    }

    for (let arc of arcs) {
      if (data[arc.name] === null) continue;

      svg
        .append("path")
        .attr("transform", `translate(${size / 2}, ${size / 2})`)
        .attr(
          "d",
          initArc(arc.innerRadius, arc.outerRadius)
            .startAngle(0.0)
            .endAngle(6.29)
        )
        .attr("fill", theme.colors.grey01);
    }

    for (let dial of dials) {
      const dialArc = arcs.find(arc => arc.name === dial.name);

      if (!dialArc || data[dial.name] === null) continue;

      figures[dial.name] = svg
        .append("path")
        .datum({
          endAngle: i(dial.currentEndAngle),
          startAngle: -i(0.66) / (0.5 * dialArc.innerRadius)
        })
        .attr("transform", `translate(${size / 2}, ${size / 2})`)
        .attr("d", initArc(dialArc.innerRadius, dialArc.outerRadius))
        .attr("fill", theme.colors.socialHealth[dial.name]);

      setTimeout(() => {
        figures[dial.name]
          .transition()
          .duration(500)
          .call(
            arcTween,
            initArc(dialArc.innerRadius, dialArc.outerRadius),
            -i(0.66) / (0.5 * dialArc.innerRadius),
            i(dial.targetEndAngle)
          );
      }, 450);
    }
  }, [chartRef, data, dials, arcs]);

  return <div ref={chartRef} />;
}

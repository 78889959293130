export const SET_CHANNELS_IS_LOADING =
  "@@analyticsContext/SET_CHANNELS_IS_LOADING";
export const SET_CHANNELS_PERIOD = "@@analyticsContext/SET_CHANNELS_PERIOD";
export const SET_CHANNELS_DATA = "@@analyticsContext/SET_CHANNELS_DATA";
export const SET_CHANNELS_EMPLOYEES_DATA =
  "@@analyticsContext/SET_CHANNELS_EMPLOYEES_DATA";
export const SET_CHANNELS_PER_CHANNEL_DATA =
  "@@analyticsContext/SET_CHANNELS_PER_CHANNEL_DATA";
export const SET_CHANNELS_CHANNEL_IDS =
  "@@analyticsContext/SET_CHANNELS_CHANNEL_IDS";
export const SET_ALL_POSTS_IS_LOADING =
  "@@analyticsContext/SET_ALL_POSTS_IS_LOADING";
export const SET_ALL_POSTS_PERIOD = "@@analyticsContext/SET_ALL_POSTS_PERIOD";
export const SET_ALL_POSTS_DATA = "@@analyticsContext/SET_ALL_POSTS_DATA";
export const SET_ALL_POSTS_CHANNEL_IDS =
  "@@analyticsContext/SET_ALL_POSTS_CHANNEL_IDS";
export const SET_ALL_POSTS_CATEGORIES =
  "@@analyticsContext/SET_ALL_POSTS_CATEGORIES";
export const SET_REPURPOSE_POSTS_IS_LOADING =
  "@@analyticsContext/SET_REPURPOSE_POSTS_IS_LOADING";
export const SET_REPURPOSE_POSTS_PERIOD =
  "@@analyticsContext/SET_REPURPOSE_POSTS_PERIOD";
export const SET_REPURPOSE_POSTS_ENGAGEMENT_RATE =
  "SET_REPURPOSE_POSTS_ENGAGEMENT_RATE";
export const SET_REPURPOSE_POSTS_DATA =
  "@@analyticsContext/SET_REPURPOSE_POSTS_DATA";
export const SET_REPURPOSE_POSTS_CHANNEL_IDS =
  "SET_REPURPOSE_POSTS_CHANNEL_IDS";
export const SET_REPURPOSE_POSTS_CATEGORIES = "SET_REPURPOSE_POSTS_CATEGORIES";
export const SET_LINKS_IS_LOADING = "@@analyticsContext/SET_LINKS_IS_LOADING";
export const SET_LINKS_PERIOD = "@@analyticsContext/SET_LINKS_PERIOD";
export const SET_LINKS_DATA = "@@analyticsContext/SET_LINKS_DATA";
export const SET_LINKS_CHANNEL_IDS = "@@analyticsContext/SET_LINKS_CHANNEL_IDS";
export const SET_LINKS_CATEGORIES = "@@analyticsContext/SET_LINKS_CATEGORIES";

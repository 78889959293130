import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { Flex, Box } from "rebass/styled-components";

const Hr = styled.hr<{ $orientation?: string }>`
  ${props => {
    switch (props.$orientation) {
      case "verticle":
        return css`
          height: 36px;
          width: 1px;
        `;
      case "horizontal":
      default:
        return css`
          width: 100%;
        `;
    }
  }}
`;

const Divider: React.FC<
  PropsWithChildren<{ mx?: number; my?: number; orientation?: string }>
> = ({ children, my = 20, mx = 0, orientation = "horizontal" }) => {
  return (
    <Flex justifyContent="space-between" alignItems="center" my={my} mx={mx}>
      <Hr $orientation={orientation} />
      {children && (
        <Box mx={10} minWidth="auto">
          {children}
        </Box>
      )}
      <Hr $orientation={orientation} />
    </Flex>
  );
};

export default Divider;

import { takeLatest, put, select } from "redux-saga/effects";

import * as scheduledPostActions from "../actions/ScheduledPostActions";

import { callApi } from "../../utils/ContentoApi";

const getUserAccount = state => {
  return state.auth.currentUser.accounts[0];
};

function* fetchScheduledPosts() {
  const account = yield select(getUserAccount);
  const scheduledPosts = yield callApi({
    method: "get",
    url: `accounts/${account.id}/scheduled-posts`
  });
  yield put(scheduledPostActions.scheduledPostsFetched(scheduledPosts));
}

function* schedulePost({ post, time, channels }) {
  const account = yield select(getUserAccount);

  const scheduledPost = yield callApi({
    method: "post",
    url: `accounts/${account.id}/scheduled-posts`,
    data: {
      postId: post.id,
      time,
      channels
    }
  });

  console.log("scheduled", scheduledPost);
  yield put(scheduledPostActions.fetchScheduledPosts());
}

export default function* root() {
  yield takeLatest(
    scheduledPostActions.FETCH_SCHEDULED_POSTS,
    fetchScheduledPosts
  );
  yield takeLatest(scheduledPostActions.SCHEDULE_POST, schedulePost);
}

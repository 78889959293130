import React from "react";
import { Box } from "rebass/styled-components";

import { SystemMessage } from "components/composerv2/styles";
import { useComposerState } from "contextApi/composerContext";
import { useAppState } from "contextApi/appContext";

const ComposerNotifications = () => {
    const inProMode = useComposerState(state => state.inProMode);
    const composerNotifications = useComposerState(
      state => state.composerNotifications
    );
    const showWillowAIRevamp = useAppState(state => state.showWillowAIRevamp);

    return (
      <Box mb={inProMode || showWillowAIRevamp ? 0 : 12} mt={10} maxWidth={"550px"}>
        {composerNotifications.map(notification => (
          <Box key={notification.message?.toString()} mb={12}>
            <SystemMessage
              type={notification.type}
              service={notification.service}
            >
              {notification.message}
            </SystemMessage>
          </Box>
        ))}
      </Box>
    );
}

export default ComposerNotifications;
import styled from "styled-components";
import { ModalHeader } from "../styles";
import { Box } from "rebass/styled-components";

export const ModalHeaderComposer = styled(ModalHeader)`
  padding: 20px;
  border-radius: 14px 14px 0 0;
  border-bottom: #e5e7e9 1px solid;
`;

export const ModalBodyComposer = styled(Box)`
  padding: 24px;
`;

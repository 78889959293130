import React from "react";
import { Box, Flex } from "rebass/styled-components";

import { Label } from "../../common/styles";
import AnalyticsWidgetMetric from "./AnalyticsWidgetMetric";

const metricLabelMapping = {
  clicks: "Clicks",
  comments: "Comments",
  likes: "Likes",
  shares: "Shares",
  video_views: "Views"
};

export const availableMetrics = [
  {
    name: "engagements",
    source: "calculated",
    type: "number",
    label: "Engagement",
    details: {
      children: s => (
        <Box>
          {Object.entries(metricLabelMapping).map(([key, val]) =>
            s[key] ? (
              <Flex
                key={key}
                minWidth={140}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <span>{val}</span>
                <Label>{s[key]}</Label>
              </Flex>
            ) : null
          )}
        </Box>
      )
    }
  },
  {
    name: "impressions",
    source: "raw",
    type: "number",
    label: "Impressions"
  },
  {
    name: "engagementRate",
    source: "calculated",
    type: "percentage",
    label: "Eng. rate"
  },
  {
    name: "score",
    source: "calculated",
    type: "score",
    label: "Score",
    details: {
      title: "Post score",
      description:
        "How well your post scored in engagement compared to other posts on the same channel."
    }
  }
];

export default function PostStats({
  stats,
  channel,
  metrics = ["engagements", "impressions", "engagementRate", "score"],
  centered = false
}) {
  return (
    <>
      {availableMetrics.map(
        metric =>
          metrics.includes(metric.name) && (
            <AnalyticsWidgetMetric
              key={metric.name}
              centered={centered}
              channel={channel}
              metric={metric}
              stats={stats}
            />
          )
      )}
    </>
  );
}

import styled, { css } from "styled-components";
import { Link, NavLink } from "react-router-dom";

import {
  Flex,
  Box,
  Button as RebassButton,
  Image
} from "rebass/styled-components";
import { Headline3, Headline5, Headline6 } from "../common/styles";
import EmptyNewIcon from "../../assets/images/suggestions/empty-new.png";
import EmptySavedIcon from "../../assets/images/suggestions/empty-saved.png";

export const EmptyContent = styled.div`
  background: url("${props =>
      props.type === "new" ? EmptyNewIcon : EmptySavedIcon}")
    no-repeat center top;
  background-size: 379px 209px;
  padding-top: 233px;
  margin: 80px auto 0 auto;
  max-width: 379px;
  width: 90%;
  text-align: center;
`;

export const EmptyTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  color: #1c1e21;
  margin-bottom: 8px;
`;

export const EmptyBody = styled.div`
  color: #606770;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.16px;
`;

export const ContentWrapper = styled(Box)`
  margin-bottom: 48px;
  display: grid;
  grid-gap: 24px 24px;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 24px;
  }

  /* TODO: rewrite manual breakpoint with new standard ones */
  @media screen and (max-width: 990px) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 48px 24px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints[3]}) {
    grid-template-columns: 1fr;
    grid-gap: 32px 24px;
  }
`;

export const ContentHeader = styled.div`
  margin-bottom: 16px;

  h2 {
    margin-bottom: 4px;
  }
  p {
    font-size: 16px;
  }
`;

export const LoadingContent = styled.div`
  text-align: center;
  margin-top: 80px;

  & > div {
    display: inline-block;
  }
`;

export const ContentItemWrapper = styled(Flex)`
  height: 100%;
  flex-direction: column;
  scroll-snap-align: start;
  border-bottom: 1px solid rgba(96, 116, 128, 0.12);
  justify-content: space-between;
  ${props =>
    props.orientation === "landscape" &&
    css`
      column-gap: 24px;
      display: grid;
      grid-template-columns: 264px 1fr;
      margin: 16px 0;
    `};
`;

export const SuggestedItemContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  ${props =>
    props.variant === "cover" &&
    css`
      padding: 40px 32px;
    `};
  ${props =>
    props.orientation === "landscape" &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    `};
`;

export const ItemFavicon = styled(Image)`
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-right: 8px;
  border: 1px solid rgba(102, 119, 128, 0.16);
`;

export const ItemDomain = styled.div`
  color: ${props => props.theme.colors.text02};
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  text-align: left;
  margin-bottom: 12px;
  letter-spacing: -0.01px;
`;

export const CoverDomain = styled.div`
  color: white;
  opacity: 0.4;
`;

export const ItemNewDot = styled.span`
  background-color: ${props => props.theme.colors.blue};
  border-radius: 8px;
  display: inline-block;
  height: 8px;
  margin: 0 4px 2px 0;
  width: 8px;
`;

export const ItemTitle = styled.a`
  color: ${props => props.theme.colors.text01};
  display: block;
  ${props => {
    if (props.size === "sm") {
      return css`
        font-size: 17px;
      `;
    } else {
      return css`
        font-size: clamp(18px, 1.38889vw, 21px);
      `;
    }
  }}
  font-family: ${props => props.theme.fonts.headline};
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  letter-spacing: -0.48px;
  line-height: 133%;
  margin-bottom: ${props => (props.showDescription ? "8px" : "32px")};
  overflow: hidden;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;

  &:hover {
    color: #1e62e0;
  }
`;

export const CoverImageSource = styled.img``;

export const CoverTitle = styled.a`
  color: white;
  font-family: ${props => props.theme.fonts.headline};
  font-size: 28px;
  font-weight: 700;
  letter-spacing: -0.42px;
  line-height: 32px;

  &:hover {
    color: white;
    opacity: 0.75;
  }
`;

export const ItemStatus = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 10px;
  color: ${props =>
    props.status === "saved"
      ? props.theme.colors.orange
      : props.theme.colors.blue};
  > .icon {
    font-size: 16px;
  }
`;

export const ItemText = styled.p`
  color: ${props => props.theme.colors.text02};
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.32px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 66px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 60px;
  }
`;

export const ActionZone = styled(Flex)`
  position: absolute;
  top: 0;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  display: flex;
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  background-color: ${props =>
    props.isVisible ? props.theme.colors.backdrop : "transparent"};
`;

export const TopicIcon = styled(Flex)`
  color: #ffffff;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.primary};

  > span {
    font-size: 20px;
    stroke: 3px;
  }
`;

export const TopicTitle = styled(Link)`
  color: ${props => props.theme.colors.text01};
  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

export const TopicCard = styled.a`
  background-color: ${props => props.theme.colors.text01};
  border-radius: 12px;
  cursor: pointer;
  padding: 16px;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  opacity: ${props => (props.isActive ? "0.7" : "1")};
  overflow: hidden;
  scroll-snap-align: start;

  &:hover {
    background-color: ${props => props.theme.colors.text02};
    box-shadow:
      0px 8px 16px -8px rgba(28, 30, 31, 0.12),
      0px 18px 27px -5px rgba(28, 30, 31, 0.15),
      0px 0px 0px 1px rgba(28, 30, 31, 0.05);
  }

  ${props =>
    props.bgImg &&
    css`
      background-image: ${props.bgImg ? `url('${props.bgImg}')` : "none"};
      background-size: cover;
      background-position: center;

      &:before {
        background-color: rgba(21, 21, 21, 0.6);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transition: 0.16s;
        width: 100%;
      }
      &:hover {
        &:before {
          background-color: rgba(21, 21, 21, 0.75);
        }
      }
    `}
`;
export const TopicCardName = styled.h4`
  color: white;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 700;
  height: 100%;
  justify-content: center;
  letter-spacing: -0.12px;
  line-height: 1.25;
  position: relative;
  text-align: center;
  text-shadow: 0 4px 8px ${props => props.theme.colors.grey01};
  z-index: 1;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.m}) {
    font-size: 16px;
  }
`;
export const TopicCardMeta = styled.div`
  bottom: 20px;
  position: relative;
  text-align: center;
  z-index: 1;
  & * {
    display: block;
  }
`;
export const TopicCardTag = styled.span`
  color: rgba(255, 255, 255, 0.66);
  font-size: 11px;
  font-weight: ${props => (props.bold ? "bold" : "400")};
  letter-spacing: 0.25px;
  text-transform: uppercase;
  margin: 4px 0;
`;
export const TopicCardCheck = styled.span`
  position: absolute;
  right: 10px;
  top: 10px;
`;

export const ContentScheduleButton = styled(RebassButton)`
  display: flex;
  font-size: 16px;
  min-width: 140px;
  align-items: center;
  outline: none;
  border: none;
  cursor: pointer;
  font-weight: 700;
  line-height: 24px;
  border-radius: 28px;
  padding: 16px 30px;
  gap: 8px;
  color: ${props => props.theme.colors.text01};
  background-color: ${props => props.theme.colors.text05};
  justify-content: center;

  &:hover {
    background-color: rgba(243, 244, 245, 0.95);
    transition: background-color 300ms ease;
  }
  &:active {
    background-color: ${props => props.theme.colors.text05};
  }

  &:focus {
    outline: 2px solid ${props => props.theme.colors.text05};
    outline-offset: 2px;
  }
  &:disabled {
    pointer-events: none;
    background-color: ${props => props.theme.colors.grey01};
    color: ${props => props.theme.colors.textDisabled};
  }
`;

export const ContentIconButtonGroup = styled(Flex)`
  justify-content: flex-end;
  gap: 5px;
`;

export const ContentIconButton = styled(RebassButton)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;

  background-color: ${props => {
    if (props.active) return props.theme.colors.green;
    else if (props.saved) return props.theme.colors.orange;
    else return "rgba(28, 30, 31, 0.33)";
  }};

  border: 1.3px solid rgba(255, 255, 255, 0.5);
  /* border: solid 1px ${props => props.theme.colors.text05}; */
  border-radius: 50%;
  backdrop-filter: blur(8px);
  --webkit-backdrop-filter: blur(8px);
  &:hover {
    border: 1.3px solid #ffffff;
    background-color: ${props => {
      if (props.active) return props.theme.colors.green;
      else if (props.saved) return props.theme.colors.orange;
      else return "rgba(28, 30, 31, 0.33)";
    }};
  }

  &:active {
    border: 1.3px solid #ffffff;
  }

  &:focus {
    border: 1.3px solid #ffffff;
  }

  &:disabled {
    background: rgba(28, 30, 31, 0.33);
    border: 1.3px solid rgba(255, 255, 255, 0.33);

    > .icon {
      color: rgba(230, 246, 255, 0.27);
    }
  }

  > .icon {
    font-size: 20px;
  }
`;

export const ContentIconButtonIcon = styled.span`
  font-size: 20px;
`;

export const MainContentWrapper = styled(Flex)`
  flex-direction: column;
`;

export const MetaInformationWrapper = styled(Flex)``;

export const ContentItemThumbnail = styled(Box)`
  position: relative;
  border-radius: 12px;
  padding-top: 60%;
  /* height: ${props =>
    props.thumbnailHeight
      ? props.thumbnailHeight
      : props.size === "sm"
        ? 180
        : 229}px; */
  background: url("${props => props.thumbnail}") no-repeat center center
    ${props => props.theme.colors.grey02};
  background-size: cover;
  margin-bottom: 20px;
`;

export const ContentItemCover = styled(Flex)`
  background: url("${props => props.thumbnail}") no-repeat center center
    ${props => props.theme.colors.grey02};
  background-size: cover;
  border-radius: 12px;
  color: white;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;

  &::before {
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    );
    bottom: 0;
    content: "";
    display: block;
    height: 50%;
    position: absolute;
    width: 100%;
  }
`;

export const StyledDismissReasons = styled(Box)`
  border-radius: 12px;
  background-color: rgba(17, 17, 17, 0.03);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: ${props => (props.isModal ? "auto" : "100%")};
  min-width: ${props => (props.isModal ? "325px" : "none")};
  padding: ${props => (props.isModal ? "32px 24px" : "24px 16px")};
`;

export const DismissTitle = styled(Headline5)`
  color: ${props => props.theme.colors.text01};
  font-size: 16px;
  font-weight: bold;
`;
export const DismissList = styled.ul`
  margin: 24px 0 16px;
`;

export const DismissItem = styled.li`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey02};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 0;
`;

export const DismissLabel = styled.span`
  color: ${props => props.theme.colors.text02};
  font-size: 14px;

  & span {
    font-weight: bold;
  }
`;

export const DismissButton = styled.button`
  background: none;
  border: none;
  box-shadow: none;
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    color: ${props => props.theme.colors.darkBlue};
  }
`;

export const DismissFooter = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
`;

export const MutedSourceList = styled.ul`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 48px;
  max-width: 1076px;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.m}) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const MutedSourceItem = styled.li`
  align-items: center;
  border-radius: 12px;
  border: 1px solid ${props => props.theme.colors.grey02};
  display: flex;
  flex-direction: row;
  padding: 28px 32px;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    padding: 20px 24px;
  }
`;

export const MutedSourceContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 16px;
`;

export const MutedSourceTitle = styled.span`
  color: ${props => props.theme.colors.text01};
  font-size: 16px;
  font-weight: 700;
`;

export const MutedSourceUrl = styled.span`
  color: ${props => props.theme.colors.text02};
  font-size: 14px;
`;

export const MutedSourceFavicon = styled.figure`
  border-radius: 24px;
  box-shadow: 0 0 0 1px ${props => props.theme.colors.grey02};
  background-color: ${props => props.theme.colors.grey01};
  height: 48px;
  object-fit: contain;
  overflow: hidden;
  width: 48px;

  & img {
    height: 100%;
    width: 100%;
  }
`;

export const RegionFilter = styled.div`
  margin: 32px 0 24px;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    margin: 0 0;
  }
`;
export const RegionFilterButton = styled.div`
  border-radius: 8px;
  cursor: pointer;
  background-color: ${props =>
    props.isFocus ? props.theme.colors.grey01 : "white"};
  padding: 8px 16px;
  & p {
    line-height: 1;
    margin: 0 0 5px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.grey01};
  }
`;
export const RegionFilterValue = styled.span`
  color: ${props =>
    props.isActive ? props.theme.colors.blue : props.theme.colors.text01};
  font-size: 14px;
  font-weight: 600;
`;
export const RegionFilterDropdown = styled.ul`
  background-color: white;
  border-radius: 8px;
  box-shadow:
    0 1px 8px 0 rgba(17, 17, 17, 0.05),
    0 2px 4px rgba(0, 0, 0, 0.04);
  padding: 8px;
`;
export const RegionFilterOption = styled.li`
  border-radius: 6px;
  color: ${props => props.theme.colors.text01};
  cursor: pointer;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-template-areas: "icon label";
  grid-gap: 10px;
  justify-content: center;
  padding: 10px;
  & span {
    display: block;
    grid-area: label;
    font-size: 16px;
    font-weight: 600;
  }
  &:hover {
    background-color: ${props => props.theme.colors.blue};
    color: white;
  }
  ${props => props.isActive && css``}
`;

export const ContentItemDateWrapper = styled.span`
  ${props =>
    !props.borderless &&
    css`
      border-left: 1px solid ${props => props.theme.colors.text04};
    `}
  padding-left: 8px;
  margin-left: 8px;
  color: ${props => props.theme.colors.text04};
`;

export const ContentSectionWrapper = styled(Flex)`
  justify-content: center;
  border-radius: 16px;
  background: ${props => props.color ?? props.theme.colors.text01};
  /* min-height: 242px; */
  padding: 32px 0px;
`;

export const ContentTopicImage = styled(Image)`
  width: 80px;
  height: 80px;
  box-shadow:
    0px 8px 16px -8px rgba(28, 30, 31, 0.12),
    0px 18px 27px -5px rgba(28, 30, 31, 0.15),
    0px 0px 0px 1px rgba(28, 30, 31, 0.05);
  border-radius: 16px;
  margin-bottom: 16px;
`;

export const MetaInformation = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => (props.hasTags || props.isSaved ? 12 : 0)}px;
  ${props =>
    props.isSaved &&
    !props.hasTags &&
    css`
      height: 28px;
    `}
`;

export const RecommendedShelveTitle = styled(Headline3)`
  margin-bottom: 0;
`;

export const ShelveImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: ${props => (props.type === "sources" ? "50%" : "16px")};
  outline: 1px solid ${props => props.theme.colors.grey03};
`;

export const RecommendedShelveButtonGroup = styled(Flex)`
  flex-direction: row;
`;

export const TopicInfoWrapper = styled(Flex)`
  gap: 16px;
  align-items: center;
`;

export const EmptyImageWrapper = styled.img`
  width: 48px;
  height: 48px;
`;

export const RestrictedRegionsNavigationItem = styled(NavLink)`
  &:hover {
    color: ${props => props.theme.colors.white};
    text-decoration: underline;
  }
`;

export const RestrictedRegionsText = styled(Headline6)`
  color: ${props => props.theme.colors.white};
`;

import { Dispatch } from "react";

import { IActions } from "./actionCreators";
import {
  FEATURE_NOTIFICATION_ENQUEUE,
  FEATURE_NOTIFICATION_DEQUEUE,
  SET_ACTIVE_FEATURE_NOTIFICATION,
  RESET_ACTIVE_FEATURE_NOTIFICATION,
  GOTO_NEXT_STEP,
  GOTO_PREV_STEP
} from "./actions";

export enum FeatureNotificationStatus {
  COMPLETE = "complete",
  INCOMPLETE = "incomplete"
}

export const STORAGE_KEY = "feature_notifications";

interface IBaseFeatureNotificationStep {
  onProceed?: () => void;
  onReturn?: () => void;
  onCancel?: () => void;
  returnButtonText?: string | null;
  proceedButtonText?: string | null;
  cancelButtonText?: string | null;
  cancelButtonVariant?: string;
}

export interface IFeatureNotificationStepWithoutComponent
  extends IBaseFeatureNotificationStep {
  title: string;
  description: string;
  banner?: string;
  helpUrl?: string;
}

export interface IFeatureNotificationStepWithComponent
  extends IBaseFeatureNotificationStep {
  component: React.ComponentType;
}

type IFeatureNotificationStep =
  | IFeatureNotificationStepWithoutComponent
  | IFeatureNotificationStepWithComponent;

type INonEmptyFeatureNotificationSteps = IFeatureNotificationStep[] & {
  0: IFeatureNotificationStep;
};

export interface IFeatureNotification {
  id: string;
  persist: boolean;
  steps: INonEmptyFeatureNotificationSteps;
  currentStep: number;
  maxWidth?: number;
  dismissible?: boolean;
  buttonLayout?: "horizontal" | "vertical";
}

export interface INotificationState {
  featureNotificationQueue: IFeatureNotification[];
  activeFeatureNotification: IFeatureNotification | null;
}

export type IFeatureNotificationPayload = Required<
  Pick<IFeatureNotification, "id" | "steps">
> &
  Partial<IFeatureNotification>;

export type IAppDispatch = Dispatch<IActions>;

export const initialValues: INotificationState = {
  featureNotificationQueue: [],
  activeFeatureNotification: null
};

export const notificationReducer = (
  state: INotificationState = initialValues,
  action: IActions
): INotificationState => {
  switch (action.type) {
    case FEATURE_NOTIFICATION_ENQUEUE: {
      return {
        ...state,
        featureNotificationQueue: [
          ...state.featureNotificationQueue,
          {
            persist: false,
            currentStep: 0,
            ...action.payload
          }
        ]
      };
    }

    case FEATURE_NOTIFICATION_DEQUEUE: {
      return {
        ...state,
        featureNotificationQueue: state.featureNotificationQueue.slice(1)
      };
    }

    case SET_ACTIVE_FEATURE_NOTIFICATION: {
      return {
        ...state,
        activeFeatureNotification: {
          ...action.payload,
          currentStep: action.payload.currentStep || 0,
          persist: action.payload.persist ?? false,
          dismissible: action.payload.dismissible ?? true
        }
      };
    }

    case RESET_ACTIVE_FEATURE_NOTIFICATION: {
      return {
        ...state,
        activeFeatureNotification: null
      };
    }

    case GOTO_NEXT_STEP:
      return {
        ...state,
        ...(!state.activeFeatureNotification
          ? {}
          : {
              activeFeatureNotification: {
                ...state.activeFeatureNotification,
                currentStep: state.activeFeatureNotification.currentStep + 1
              }
            })
      };

    case GOTO_PREV_STEP:
      return {
        ...state,
        ...(!state.activeFeatureNotification
          ? {}
          : {
              activeFeatureNotification: {
                ...state.activeFeatureNotification,
                currentStep: state.activeFeatureNotification.currentStep - 1
              }
            })
      };

    default: {
      throw new Error("Unhandled action type");
    }
  }
};

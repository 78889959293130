import styled from "styled-components";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import { useToaster } from "@hellocontento/maillard";
import { Box, Flex } from "rebass/styled-components";
import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserPlus } from "styled-icons/boxicons-regular";

import {
  useAnalyticsState,
  useAnalyticsActions
} from "contextApi/analyticsContext";
import Metrics from "./widgets/Metrics";
import Tooltip from "components/common/Tooltip";
import Leaderboard from "./widgets/Leaderboard";
import PeriodSelect from "./widgets/PeriodSelect";
import { generatePdf } from "utils/pdfGeneration";
import ChannelToggle from "../common/ChannelToggle";
import { Headline2 } from "components/common/styles";
import EmptyState from "components/common/EmptyState";
import WidgetContainer from "./widgets/WidgetContainer";
import { AvatarWrapper } from "components/common/styles";
import { DownloadButton, RightContainer } from "./styles";
import AnalyticsSubplot from "./widgets/AnalyticsSubplot";
import ChannelBreakdown from "./widgets/ChannelBreakdown";
import { BreakdownContainer } from "./widgets/ChannelBreakdown";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { ChannelToggleContainer } from "components/common/ChannelToggle";

const AnalyticsView = styled(Flex)`
  flex-direction: column;
  margin: 16px 24px;
  max-width: 1128px;
  width: 100%;
  margin: 0 auto;

  .report {
    & * {
      box-shadow: unset !important;
    }

    ${WidgetContainer}, ${BreakdownContainer}, ${AvatarWrapper} {
      border: 1px solid ${props => props.theme.colors.grey04};
    }

    ${ChannelToggleContainer}, ${RightContainer} {
      visibility: hidden;
    }
  }
`;

const AnalyticsSection = styled(Box)`
  flex: 1;
  margin: 24px 0;
`;

const AnalyticsPlotGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px 16px;
`;

const AnalyticsBreakdownGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px 16px;
`;

const AnalyticsOverview: React.FC<{}> = () => {
  const addToast = useToaster();
  const dispatch = useDispatch();
  const history = useHistory();
  const account = useSelector<any, any>(state => state.account.data);
  const { channels } = account;
  const isLoading = useAnalyticsState(state => state.channels.isLoading);
  const period = useAnalyticsState(state => state.channels.period);
  const setPeriod = useAnalyticsActions(state => state.setChannelsPeriod);
  const data = useAnalyticsState(state => state.channels.data);
  const employees = useAnalyticsState(state => state.channels.employeesData);
  const perChannel = useAnalyticsState(state => state.channels.perChannelData);
  const selectedChannels = useAnalyticsState(
    state => state.channels.channelIds
  );
  const setSelectedChannels = useAnalyticsActions(
    state => state.setChannelsChannelIds
  );
  const refreshOverviewStats = useAnalyticsActions(
    state => state.refreshOverviewStats
  );

  useEffect(() => {
    refreshOverviewStats();
  }, [refreshOverviewStats]);

  const pdfDownload = useCallback(() => {
    try {
      generatePdf("analytics-report", "analytics-report-page");

      dispatch(
        trackAnalyticsEvent("Analytics Downloaded", {
          type:
            history.location.pathname.match(
              /^.*\/accounts\/[^/]*\/analytics\/([^/?]*).*$/
            )?.[1] ?? ""
        })
      );
    } catch (_) {
      addToast(
        "There was an error downloading the report. Please try again later or contact support.",
        "error"
      );
    }
  }, [addToast, dispatch, history.location.pathname]);

  const isLinkedinOnly =
    channels.length > 0 &&
    channels.every(c => `${c.service}_${c.serviceType}` === "linkedin_profile");

  return (
    <AnalyticsView>
      {isLoading ? (
        <Flex justifyContent={"center"} my={32}>
          <ReactLoading type={"spin"} color={"#bbb"} width={32} />
        </Flex>
      ) : account.channels.length === 0 || !data || !perChannel ? (
        <EmptyState
          loading={isLoading}
          header={"Connect your social channels"}
          description={
            "To see your analytics, please connect one social media account first."
          }
          icon={{
            component: UserPlus,
            width: "48px",
            height: "48px"
          }}
          cta={{
            text: "Add channel",
            onClick: () =>
              history.push(`/accounts/${account.id}/settings/channels`)
          }}
        />
      ) : (
        <div id="analytics-report">
          <div className="analytics-report-page">
            <AnalyticsSection>
              <Flex
                flex="1"
                justifyContent={"space-between"}
                alignItems="center"
                height={40}
              >
                <Headline2>Analytics Summary</Headline2>
                <RightContainer>
                  <Tooltip title="Download report" placement="bottom">
                    <DownloadButton onClick={pdfDownload} disabled={isLoading}>
                      <i className="icon-download" />
                    </DownloadButton>
                  </Tooltip>
                  <PeriodSelect selected={period} onSelect={setPeriod} />
                  {/* @ts-ignore */}
                  <ChannelToggle
                    channels={channels}
                    selectedIds={selectedChannels}
                    onSave={setSelectedChannels}
                  />
                </RightContainer>
              </Flex>
            </AnalyticsSection>

            <AnalyticsSection>
              <Metrics isLinkedinOnly={isLinkedinOnly} data={data} />
            </AnalyticsSection>
            <AnalyticsSection>
              <AnalyticsPlotGrid>
                <AnalyticsSubplot
                  title="Impressions"
                  data={data?.impressions?.points ?? []}
                  metricId="impressions"
                  series="variation"
                  period={period}
                  description="Total times someone saw a post"
                />
                <AnalyticsSubplot
                  title="Engagement"
                  metricId="engagements"
                  series="variation"
                  data={data?.engagements?.points ?? []}
                  period={period}
                  description="Likes, shares & clicks"
                />
                {!isLinkedinOnly && (
                  <>
                    <AnalyticsSubplot
                      title="Total audience"
                      data={data?.followers?.points ?? []}
                      metricId="audience"
                      period={period}
                      series="total"
                      type="line"
                    />
                    <AnalyticsSubplot
                      title="New followers"
                      data={data?.followers?.points ?? []}
                      metricId="followers"
                      period={period}
                      series="variation"
                      type="bar"
                    />
                  </>
                )}
              </AnalyticsPlotGrid>
            </AnalyticsSection>
          </div>
          {!isLoading && !isLinkedinOnly && (
            <div className="analytics-report-page">
              <AnalyticsSection>
                <Flex flexDirection="column">
                  <Headline2>Channel Breakdown</Headline2>
                </Flex>
                <AnalyticsBreakdownGrid>
                  {perChannel
                    .filter(
                      c =>
                        `${c.service}_${c.serviceType}` !== "linkedin_profile"
                    )
                    .map(channel => (
                      <ChannelBreakdown key={channel.id} data={channel} />
                    ))}
                </AnalyticsBreakdownGrid>
              </AnalyticsSection>
            </div>
          )}
          {!isLoading && employees.length > 0 && (
            <div className="analytics-report-page">
              <AnalyticsSection>
                <Flex flexDirection="column">
                  <Headline2>Employee Engagement</Headline2>
                </Flex>
                <Flex>
                  <Leaderboard data={employees} period={30} />
                </Flex>
              </AnalyticsSection>
            </div>
          )}
        </div>
      )}
    </AnalyticsView>
  );
};

export default AnalyticsOverview;

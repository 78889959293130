import _ from "lodash";
import React, { useState, useCallback } from "react";

import MonthPost from "./MonthPost";
import { PostGroup, PostList } from "./styles";
import DraggableWrapper from "../common/drag-and-drop/DraggableWrapper";

const MonthGroupedSentPosts = ({ posts, day }) => {
  const [isExpanded, setExpanded] = useState(false);

  const channels = posts.map(entry => entry.post.channel);

  const handleExpandGroup = () => {
    setExpanded(true);
  };

  return posts.length === 1 || isExpanded ? (
    <PostGroup>
      <PostList>
        {posts.map((item, index) => (
          <DraggableWrapper
            key={item.id}
            id={item.id}
            index={index}
            isDisabled={item.post?.postedAt}
          >
            <MonthPost key={item.id} day={day} entry={item} />
          </DraggableWrapper>
        ))}
      </PostList>
    </PostGroup>
  ) : (
    <MonthPost
      day={day}
      entry={posts[0]}
      customChannels={channels}
      handleExpandGroup={handleExpandGroup}
    />
  );
};

const ChannelGroupedPosts = ({ posts, day }) => {
  const [isExpanded, setExpanded] = useState(false);

  const channels = posts.map(entry => entry.post.channel);

  const handleExpandGroup = useCallback(() => {
    setExpanded(true);
  }, []);

  const services = _.groupBy(posts, "post.channel.service");

  return Object.keys(services).length === 1 || isExpanded ? (
    <PostGroup>
      <PostList>
        {Object.keys(services).map((key, index) => (
          <DraggableWrapper key={key} id={key} index={index} isDisabled={true}>
            <MonthGroupedSentPosts key={key} posts={services[key]} day={day} />
          </DraggableWrapper>
        ))}
      </PostList>
    </PostGroup>
  ) : (
    <MonthPost
      day={day}
      entry={posts[0]}
      customChannels={channels}
      handleExpandGroup={handleExpandGroup}
    />
  );
};

export default ChannelGroupedPosts;

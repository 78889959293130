import { EditorState, RichUtils } from "draft-js";
import { getSelectedBlock } from "draftjs-utils";

import {
  createSelectionFromRange,
  getEntityRange,
  getEntityBlocks
} from "../../utils/editor";
import {
  addCaptionEntity,
  findCaption,
  replaceCaptionEntity
} from "./utils/captionEntity";

class CaptionHandler {
  constructor(store, captions, setWasCaptionUsed) {
    this.store = store;
    this.captions = captions;
    this.setWasCaptionUsed = setWasCaptionUsed;
  }

  processCaptions = () => {
    //Sometimes the content isn't immediately ready so let's wait to the next iteration of the event loop.
    setImmediate(() => {
      const contentState = this.store.getEditorState().getCurrentContent();
      const captionData = findCaption(contentState, this.captions);

      if (!captionData) {
        return;
      }

      //Always get the last one.
      const editorState = this.store.getEditorState();
      const selection = createSelectionFromRange(
        captionData.contentBlockKey,
        captionData.range
      );
      const newEditorState = addCaptionEntity(editorState, selection, {});

      this.captionEntityKey = newEditorState
        .getCurrentContent()
        .getLastCreatedEntityKey();
      this.captionData = captionData;

      this.store.setEditorState(newEditorState);
    });
  };

  showNextCaption() {
    let nextPosition = this.captionData.position + 1;
    if (nextPosition >= this.captions.length) {
      nextPosition = 0; //Start again when finished cycling
    }

    const nextCaption = this.captions[nextPosition];

    const editorState = this.store.getEditorState();
    const entityBlocks = getEntityBlocks(editorState, this.captionEntityKey);

    if (entityBlocks?.length < 1) {
      return;
    }

    const newEditorState = replaceCaptionEntity(
      editorState,
      entityBlocks[0].getKey(),
      this.captionEntityKey,
      this.captionData.caption,
      nextCaption
    );

    this.captionEntityKey = newEditorState
      .getCurrentContent()
      .getLastCreatedEntityKey();

    this.captionData.caption = nextCaption;
    this.captionData.position = nextPosition;

    this.store.setEditorState(newEditorState);
  }

  removeCaptionEntity() {
    if (!this.captionEntityKey) {
      return;
    }

    const editorState = this.store.getEditorState();
    const originalSelection = editorState.getSelection();

    const selectedBlock = getSelectedBlock(editorState);
    const entityRange = getEntityRange(editorState, this.captionEntityKey);

    if (!entityRange) {
      return editorState;
    }

    const rangeSelection = createSelectionFromRange(
      selectedBlock.getKey(),
      entityRange
    );

    //Remove entity in current cursor position.
    let newEditorState = RichUtils.toggleLink(
      editorState,
      rangeSelection,
      null
    );

    //Return cursor to its original position.
    newEditorState = EditorState.set(newEditorState, {
      selection: originalSelection
    });

    this.store.setEditorState(newEditorState);
    this.captionEntityKey = null;

    return newEditorState;
  }
}

export default CaptionHandler;

import React from "react";
import { Box } from "rebass";
import PropTypes from "prop-types";

import { Categories } from "./styles";
import ContentTypeSelect from "components/common/contentTypes/ContentTypeSelect";

const CategorySelector = ({ contentTypeId, onContentTypeSelected }) => {
  return (
    <Categories>
      <Box>
        <ContentTypeSelect
          position="top-start"
          value={contentTypeId}
          onChange={value => onContentTypeSelected(value)}
        />
      </Box>
    </Categories>
  );
};

CategorySelector.propTypes = {
  contentTypeId: PropTypes.string.isRequired,
  onContentTypeSelected: PropTypes.func.isRequired
};

export default CategorySelector;

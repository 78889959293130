import styled, { css } from "styled-components";

import { Loading } from "components/common/styles";

export const QuoteContainer = styled.div<{
  fullWidth: boolean;
  blurScreen?: boolean;
}>`
  box-sizing: border-box;
  display: grid;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: unset;

  > section {
    width: 100vw;
    height: 100%;
  }

  ${props =>
    !props.fullWidth &&
    css`
      grid-template-rows: unset;
      grid-template-columns: 1fr 1fr;

      > section {
        width: 50vw;
        height: 100%;
      }

      @media screen and (max-width: ${props => props.theme.breakpoints[2]}) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: unset;

        > section {
          width: 100vw;
          height: 100%;
        }
      }
    `};

  ${Loading} {
    width: 100vw;
  }

  ${props =>
    props.blurScreen &&
    css`
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: fixed;
        background: rgba(28, 30, 31, 0.4);
        backdrop-filter: blur(2.5px);
        z-index: 9;
      }
    `};

  * {
    box-sizing: border-box;
  }
`;

import React from "react";
import styled from "styled-components";
import { Box } from "rebass/styled-components";
import { colors } from "../../theme";
import { InfoCircle } from "@styled-icons/boxicons-solid";

const variants = {
  warning: {
    backgroundColor: "rgba(255, 186, 0, 0.11)",
    borderColor: colors.yellow,
    iconColor: colors.darkYellow,
    iconElement: InfoCircle
  },
  info: {
    backgroundColor: "rgba(0, 99, 251, 0.11)",
    borderColor: colors.blue,
    iconColor: colors.blue,
    iconElement: InfoCircle
  }
};

const StyledAlertMessage = styled(Box)`
  box-sizing: border-box;
  border-radius: 6px 12px 12px 6px;
  margin: 8px 0;
  padding: 24px;
  position: relative;
  overflow: hidden;
  line-height: 1.5;

  &::after {
    background-color: ${props => props.borderColor};
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }
`;

const AlertTitle = styled.h5`
  align-items: center;
  color: ${props => props.theme.colors.text01};
  display: flex;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
  margin: 4px 0 12px;

  & svg {
    margin-right: 6px;
  }
`;

const AlertBody = styled(Box)`
  color: ${props => props.theme.colors.text01};
`;

export default function AlertMessage({
  variant = "warning",
  icon,
  title,
  children,
  ...props
}) {
  const IconElement = () =>
    React.createElement(icon ?? variants[variant].iconElement, {
      size: 16,
      color: variants[variant].iconColor
    });

  return (
    <StyledAlertMessage
      backgroundColor={variants[variant].backgroundColor}
      borderColor={variants[variant].borderColor}
      {...props}
    >
      <AlertTitle>
        <IconElement /> {title}
      </AlertTitle>
      <AlertBody>{children}</AlertBody>
    </StyledAlertMessage>
  );
}

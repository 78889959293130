/**
 * This is a utility function extracted from @draft-js-plugins/mention v4.1.4
 * as a dependency for MentionSuggestions component. 
 * It is designed to decode or parse a string (offsetKey) into a more structured object format, specifically a DecodedOffset object.
 * It is useful for parsing a key that encodes multiple pieces of information into a single string.
 * @draft-js-plugins/mention version => @4.14
 */

export interface DecodedOffset {
  blockKey: string;
  decoratorKey: number;
  leafKey: number;
}

const decodeOffsetKey = (offsetKey: string): DecodedOffset => {
  const [blockKey, decoratorKey, leafKey] = offsetKey.split("-");
  return {
    blockKey,
    decoratorKey: parseInt(decoratorKey, 10),
    leafKey: parseInt(leafKey, 10)
  };
};

export default decodeOffsetKey;

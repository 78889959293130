import styled, { css, keyframes } from "styled-components";
import { theme } from "../../../../../theme";
import { MagicWand } from "@styled-icons/boxicons-solid/MagicWand";
import { ArrowRight } from "@styled-icons/feather/ArrowRight";
import { Stars } from "@styled-icons/bootstrap/Stars";
import { FileCopy } from "@styled-icons/remix-line/FileCopy";
import { ArrowHookUpLeft } from "@styled-icons/fluentui-system-filled/ArrowHookUpLeft";
import { ThreeDots } from "@styled-icons/bootstrap/ThreeDots";

interface ResponseSectionProps {
  $hasResponse: boolean;
}

interface ButtonProps {
  $primary?: boolean;
  $disabled?: boolean;
}

export const OuterFrame = styled.div`
  border-radius: 15px;
  padding: 15px;
  background-color: ${theme.colors.grey05};
  width: 90%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  height: 100%;
`;

export const InnerFrame = styled.div`
  border-radius: 10px;
  padding: 6px;
  background-color: ${theme.colors.background2};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  height: 100%;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

export const IconFrame = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  background: ${theme.colors.newAIFeature};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-right: 10px;
`;

export const Icon = styled(MagicWand)`
  color: #adf9f8;
  width: 24px;
  height: 24px;
`;

export const BetaLabel = styled.span`
  background-color: ${theme.colors.grey03};
  padding: 10px 10px;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 14px;
  font-family: ${theme.fonts.body};
`;

export const Divider = styled.hr`
  width: 100%;
  border: 0;
  border-top: 1px solid ${theme.colors.grey05};
`;

export const InfoText = styled.p`
  color: ${theme.colors.text03};
  text-align: center;
  font-family: ${theme.fonts.body};
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: -4px;
  padding: 0 10px 0 10px;
`;

export const PlaceholderText = styled.p`
  color: ${theme.colors.text03};
  text-align: center;
  font-family: ${theme.fonts.body};
  font-size: 14px;
`;

export const ResponseSection = styled.div<ResponseSectionProps>`
  margin-top: 10px;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ $hasResponse: hasResponse }) =>
    hasResponse ? "rgba(85, 163, 179, 0.1)" : "transparent"};
  color: ${({ $hasResponse: hasResponse }) =>
    hasResponse ? "#13567B" : "inherit"};
  & > ${PlaceholderText} {
    color: ${({ $hasResponse: hasResponse }) =>
      hasResponse ? "#13567B" : theme.colors.text03};
  }
  display: flex;
  flex-direction: column;
`;

export const ResponseContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectedPromptContainer = styled.div`
  border: 2px solid ${theme.colors.grey05};
  border-radius: 8px;
  padding: 10px;
  margin-top: 10px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: calc(100% - 140px); // Adjust height to fit within parent
`;

export const SelectedPrompt = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: ${theme.colors.background2};
  font-family: ${theme.fonts.body};
  font-size: 14px;
  color: ${theme.colors.text03};
  border-bottom: 2px solid ${theme.colors.grey05};
`;

export const SelectedPromptIcon = styled(ArrowRight)`
  color: ${theme.colors.darkBlack};
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const InputField = styled.input`
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid ${theme.colors.grey05};
  width: calc(100% - 6px);
  box-sizing: border-box;
  font-family: ${theme.fonts.body};
  font-size: 14px;

  &:focus {
    border-color: #1c6476;
    outline: none;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  gap: 10px;
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-family: ${theme.fonts.body};
  color: ${props => (props.$primary ? "#ADF9F8" : theme.colors.text03)};
  background: ${props =>
    props.$primary ? theme.colors.newAIFeature : "transparent"};
  transition: all 0.3s ease;
  opacity: ${props => (props.$disabled ? "0.5" : "1")};
  cursor: ${props => (props.$disabled ? "not-allowed" : "pointer")};

  &:hover {
    background: ${props =>
      props.$primary ? theme.colors.newAIFeature : theme.colors.grey01};
    box-shadow: ${props =>
      props.$primary ? "0 6px 12px rgba(0, 0, 0, 0.1)" : "none"};
  }
`;

const twinkle = keyframes`
  0%, 20%, 50%, 80%, 100% {
    opacity: 1;
  }
  40% {
    opacity: 0.5;
  }
  60% {
    opacity: 0.3;
  }
`;

export const GenerateButtonIcon = styled(Stars)<ButtonProps>`
  color: ${props =>
    props.$primary
      ? "#ADF9F8"
      : props.$disabled
        ? theme.colors.text03
        : "#ADF9F8"};
  width: 20px;
  height: 20px;
  margin-right: 10px;
  &.twinkle {
    animation: ${twinkle} 1.5s infinite;
  }
`;

export const TryAgainButtonIcon = styled(ArrowHookUpLeft)<ButtonProps>`
  color: ${props => (props.$primary ? "#ADF9F8" : theme.colors.text03)};
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const PasteToComposerButtonIcon = styled(FileCopy)<ButtonProps>`
  color: ${props => (props.$primary ? "#ADF9F8" : theme.colors.text03)};
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const PromptSection = styled.div`
  margin-top: 10px;
  border: 2px solid ${theme.colors.grey05};
  border-radius: 8px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const PromptItem = styled.div<{ $opacity?: number }>`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  opacity: ${props => props.$opacity ?? 1};
  &:hover {
    background-color: ${theme.colors.grey01};
  }
  &:not(:last-child) {
    border-bottom: 2px solid ${theme.colors.grey05};
  }
`;

export const PromptText = styled.span`
  flex-grow: 1;
  font-size: 14px;
  font-family: ${theme.fonts.body};
  margin-left: 10px;
`;

export const PromptIcon = styled(ArrowRight)`
  color: ${theme.colors.darkBlack};
  width: 20px;
  height: 20px;
`;

export const OtherOptions = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  border-top: 2px solid ${theme.colors.grey05};
  &:hover {
    background-color: ${theme.colors.grey01};
  }
  margin-top: auto;
`;

export const OtherOptionsText = styled.span`
  flex-grow: 1;
  font-size: 14px;
  font-family: ${theme.fonts.body};
  margin-left: 10px;
`;

export const OtherOptionsIcon = styled(ThreeDots)`
  color: ${theme.colors.darkBlack};
  width: 20px;
  height: 20px;
`;

import { Flex } from "rebass";
import styled from "styled-components";

export const AddRSSWrapper = styled.div`
  max-width: 580px;
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  margin-top: 132px;
`;

export const IconWrapper = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 12px;
`;

export const InformationWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 16px;
  > a {
    font-weight: 600;

    :hover {
      text-decoration: underline;
    }
  }
`;

export const ButtonContent = styled(Flex)`
  align-items: center;
  gap: 6px;
`;

import { Headline1 } from "components/common/styles";
import styled from "styled-components";

export const BuildingBlockWrapper = styled.div<{ soft: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${props => (props.soft ? "24px" : "40px")};
  margin-bottom: 48px;
`;

export const BuildingBlockLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    grid-template-columns: repeat(3, 1fr);

    gap: 16px 16px;
  }

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.m}) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 16px;
  }
`;

export const BlockImage = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 12px;
  margin-bottom: 24px;
`;

export const DefaultBlockImage = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 12px;
  margin-bottom: 24px;
  background: #bc6446;
`;

export const BlockTitleWrapper = styled.div`
  display: inline-flex;
  flex-grow: 0;
  gap: 6px;
  margin-bottom: 8px;
  color: ${props => props.theme.colors.text01};
  font-weight: 600;
`;

export const BlockTitle = styled.span``;

export const BlockLayout = styled.div<{ active: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 24px 20px;
  background: ${props => props.theme.colors.grey01};
  :hover {
    background: ${props => props.theme.colors.grey03};
    ${BlockTitle} {
      text-decoration: underline;
    }
  }
  cursor: ${props => (props.active ? "pointer" : "default")};
`;

export const BlockDescription = styled.span`
  color: ${props => props.theme.colors.text03};
  font-size: 12px;
  font-weight: 400;
  line-height: 130%;
`;

export const BlockReturnIcon = styled.span`
  transform-origin: center;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
`;

const BlockInfo = styled.span`
  position: absolute;
  font-size: 12px;
  border-radius: 24px;
  padding: 4px 10px;
  text-transform: uppercase;
  right: 16px;
  top: 0;
  font-weight: 600;
  transform: translateY(-50%);
`;

export const ComingSoonInfo = styled(BlockInfo)`
  background: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
`;

export const NewInfo = styled(BlockInfo)`
  background: ${props => props.theme.colors.green};
  color: ${props => props.theme.colors.white};
`;

export const Headline = styled(Headline1)`
  max-width: 580px;
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  margin: auto;
`;

import React from "react";
import { connect } from "react-redux";
import ConfirmationDialog from "./ConfirmationDialog";
import { closeModal } from "../../../state/actions/ModalActions";

const ConfirmationModal = ({ closeModal, onCancel, onConfirm, ...props }) => {
  return (
    <ConfirmationDialog
      isOpen={true}
      onCancel={() => {
        closeModal();
        if (onCancel) {
          onCancel();
        }
      }}
      onConfirm={() => {
        closeModal();
        if (onConfirm) {
          onConfirm();
        }
      }}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(ConfirmationModal);

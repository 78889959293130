import styled, { css } from "styled-components";
import { Flex, Box, Image, Text } from "rebass/styled-components";
import { ExclamationCircle, Check } from "styled-icons/fa-solid";
import { QuestionCircle } from "styled-icons/fa-regular";
import { Paragraph } from "../styles";
import chevronDownIcon from "../../../assets/images/chevron-down.png";
import ReactLoading from "react-loading";

import Button from "components/common/Button";

export const FormBox = styled(Box)`
  align-items: center;
  background: white;
  border-radius: 16px;
  box-shadow:
    0 1px 10px rgba(0, 0, 0, 0.05),
    0 1px 4px rgba(17, 17, 17, 0.1),
    0 10px 30px ${props => props.theme.colors.grey01};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: ${props => props.maxWidth ?? "480px"};
  padding: 32px ${props => (props.paddingX === 0 ? "0" : "32px")} 16px;
  position: relative;
  width: 100%;

  ${props =>
    props.isTransitioned &&
    css`
      transition: 0.5s;
      opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
      transform: ${({ state }) =>
        state === "entering"
          ? "translateX(64px)"
          : state === "exiting"
            ? "translateX(-64px)"
            : "translateX(0)"};
      display: ${({ state }) => (state === "exited" ? "none" : "block")};
    `};

  ${props =>
    props.variant === "black" &&
    css`
      background-color: #111;
      color: white;
    `};

  & form {
    margin-top: 0;
    width: 100%;
  }
`;

export const FormBoxImage = styled(Box)`
  top: 24px;
  right: 32px;
  position: absolute;
  z-index: 0;
`;

export const FormTitle = styled.h1`
  color: #111111;
  font-size: ${props => props.size ?? 40}px;
  font-family: ${props => props.theme.fonts.headline};
  letter-spacing: -0.42px;
  margin-bottom: 16px;
  position: relative;
  text-align: center;

  & small {
    display: block;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.body};
    letter-spacing: -0.24px;
  }
`;

export const FormDescription = styled(Paragraph)`
  text-align: center;
  max-width: 420px;
  color: ${props => props.theme.colors.text02};
  margin-bottom: 24px;
`;

FormDescription.defaultProps = {
  isLight: true
};

export const FormTitleIcon = styled(Image)`
  width: 36px;
  height: auto;
  position: absolute;
  top: calc(-20px - 28px);
  left: calc(50% - 18px);
`;

export const FormSubtitle = styled.h2`
  margin-top: 28px;
  margin-bottom: 8px;
  line-height: 22px;
  font-size: 16px;
`;

export const FormSubheader = styled(Box)`
  text-align: center;
  font-size: 16px;
  line-height: 26px;
`;

export const Form = styled.form`
  margin-top: ${props => (props.fullWidth ? "0" : "39px")};
  width: ${props => (props.fullWidth ? "100%" : "auto")};

  ${props =>
    props.isTransitioned &&
    css`
      transition: 0.5s;
      opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
      transform: ${({ state }) =>
        state === "entering"
          ? "translateX(64px)"
          : state === "exiting"
            ? "translateX(-64px)"
            : "translateX(0)"};
      display: ${({ state }) => (state === "exited" ? "none" : "block")};
    `};

  .dim-input__field {
    margin-bottom: 2px;
  }
`;

export const InputWrapper = styled(Flex)`
  flex-direction: column;
  border: 1px solid
    ${props => {
      if (props.error && props.touched) {
        return props.theme.colors.red;
      } else if (props.touched) {
        return props.theme.colors.blue;
      } else {
        return "#EAEBEB";
      }
    }};
  border-radius: 8px;
`;

export const InputLabel = styled.label`
  flex: 1 0 auto;
  width: 100%;
  line-height: 16px;
  font-size: 12px;
  color: ${props => {
    if (props.error && props.touched) {
      return props.theme.colors.red;
    } else if (props.touched) {
      return props.theme.colors.blue;
    } else {
      return "inherit";
    }
  }};
`;

export const Input = styled.input`
  flex: 1 0 auto;
  width: 100%;
  line-height: 19px;
  font-size: 14px;
  letter-spacing: 0.1px;
  border: none;
  color: #1b1d1f;
`;

export const StyledSelect = styled.select`
  appearance: none;
  background-image: url(${chevronDownIcon});
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 16px);
  margin-top: 8px;
  border: 1px solid
    ${props =>
      props.hasError ? props.theme.colors.danger : props.theme.colors.grey02};
  border-radius: 8px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 12px 16px 12px 16px;
  line-height: 22px;
  font-size: 16px;
  transition: border-color 0.2s ease-in-out 0s;

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }
`;

const inputSizes = {
  md: {
    borderRadius: 8,
    padding: "12px 16px",
    fontSize: 16,
    marginTop: 8
  },
  sm: {
    borderRadius: 8,
    padding: "8px 12px",
    fontSize: 14,
    marginTop: 0
  }
};

export const StyledInputWrapper = styled.div`
  position: relative;
`;

export const StyledInputIconWrapper = styled.div`
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  width: 28px;
  top: 18px;
  right: 10px;
  border-radius: 50%;
  top: 18px;
  cursor: pointer;
  color: ${props => props.theme.colors.text03};

  span {
    font-size: 18px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.grey01};
    color: ${props => props.theme.colors.text01};
  }
`;

export const StyledInput = styled.input`
  margin-top: ${props => inputSizes[props.size].marginTop}px;
  border: 1px solid
    ${props =>
      props.hasError ? props.theme.colors.danger : props.theme.colors.grey02};
  border-radius: ${props => inputSizes[props.size].borderRadius}px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: ${props => inputSizes[props.size].padding};
  line-height: 140%;
  font-family: ${props => props.theme.fonts.body};
  font-size: ${props => inputSizes[props.size].fontSize}px;
  transition: border-color 0.2s ease-in-out 0s;

  padding-right: 36px;

  &::placeholder {
    color: ${props => props.theme.colors.text04};
  }

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.grey01};
    color: ${props => props.theme.colors.text02};
  }
`;

StyledInput.defaultProps = {
  size: "md"
};

export const StyledInputLabel = styled.span`
  color: ${props => props.theme.colors.text01};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;

  & small {
    color: ${props => props.theme.colors.text04};
    display: block;
    margin-bottom: 2px;
  }
`;

StyledInputLabel.defaultProps = {
  as: "label"
};

export const StyledInputFieldset = styled(Box)`
  margin-bottom: 16px;
`;

export const StyledTextArea = styled.textarea`
  resize: none;
  margin-top: 8px;
  border: 1px solid #b9bbbd;
  border-radius: 4px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 12px 16px 12px 16px;
  line-height: 22px;
  font-size: 16px;
  transition: border-color 0.2s ease-in-out 0s;

  ${props =>
    props.variant === "secondary" &&
    css`
      background: transparent;
      border-radius: 0;
      border: none;
      color: ${props => props.theme.colors.text02};
      font-family: inherit;
      font-size: 14px;
      padding: 0;
    `}

  &:focus {
    outline: none;
    border-color: ${props => props.theme.colors.primary};
  }
`;

export const HelperText = styled(Text)`
  color: ${props => props.theme.colors.text03};
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  letter-spacing: -0.16px;
  button {
    color: ${props => props.theme.colors.blue};
    background: none;
    margin: 0;
    padding: 2px 0px;
    border: none;
    cursor: pointer;
    font-weight: 700;
    border-bottom: 1px solid transparent;

    &:hover {
      color: ${props => props.theme.colors.linkBlueHover};
      border-bottom: 1px solid ${props => props.theme.colors.linkBlueHover};
    }
    &:active {
      color: ${props => props.theme.colors.linkBlueActive};
      border-bottom: 1px solid ${props => props.theme.colors.linkBlueActive};
    }
  }
`;

export const Checkbox = styled.input`
  margin-right: 10px;
`;

export const Logo = styled.img`
  border-radius: 96px;
  box-shadow: 0 0 0 1px ${props => props.theme.colors.grey02};
`;

export const ErrorMessage = styled(Box)`
  box-sizing: border-box;
  color: ${props => props.theme.colors.red};
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin: 6px 0;
`;

export const AlertMessage = styled(Box)`
  background-color: rgba(255, 186, 0, 0.11);
  box-sizing: border-box;
  border-radius: 4px 12px 12px 4px;
  margin: 8px 0;
  padding: 24px 16px;
  position: relative;
  overflow: hidden;

  & h5 {
    color: ${props => props.theme.colors.text01};
    display: flex;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  &::after {
    background-color: #ffba00;
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
  }
`;

export const IconExclamationCircle = styled(ExclamationCircle)`
  width: 15px;
  height: 15px;
  margin-right: 5px;
`;

export const IconCheck = styled(Check)`
  width: 15px;
  color: #0d5eff;
`;

export const IconQuestionCircle = styled(QuestionCircle)`
  width: 15px;
`;

export const ButtonLoading = styled(ReactLoading)`
  position: absolute;
  left: 16px;
  top: 12px;
`;

export const BigCheckBoxWrapper = styled(Flex)`
  cursor: pointer;
  border: 1px solid
    ${props =>
      props.selected ? props.theme.colors.text01 : props.theme.colors.text03};
  box-sizing: border-box;
  border-radius: 12px;
  height: ${props => (props.small ? "48px" : props.icon ? "auto" : "72px")};
  padding: ${props => (props.icon ? "24px 12px" : "0")};
  position: relative;
  text-align: center;
`;

export const BigCheckboxTitle = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.19;
  letter-spacing: -0.32px;
  text-align: center;
  color: ${props => props.theme.colors.text01};
`;

export const BigCheckboxIcon = styled.img`
  height: 45px;
  padding: 16px 0;
  width: 72px;
`;

export const BigCheckboxDescription = styled(Text)`
  font-size: 14px;
  line-height: 18px;
  color: ${props => props.theme.colors.text02};
`;

export const CheckCircle = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.text01};
  position: absolute;
  top: 12px;
  right: 12px;
`;

export const LocationSuggestions = styled(Flex)`
  position: absolute;
  width: 100%;
  z-index: 10;
  flex-direction: column;
  border-radius: 12px;
  background-color: ${props => props.theme.colors.text05};
  box-shadow: ${props => props.theme.colors.grey02} 0px 10px 37px !important;
`;

export const LocationSuggestion = styled(Box)`
  padding: 16px;
  cursor: pointer;
`;

export const LocationItemWrapper = styled(Flex)`
  border-radius: 4px;
  align-items: center;
`;

export const LocationRemoveButton = styled(Flex)`
  cursor: pointer;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  &:hover {
    border-radius: 2px;
    background: rgba(235, 235, 245, 0.1);
  }
`;

export const OnlyIconButton = styled(Button)`
  transform: translate(150%, 65%);
  position: absolute;
  border: 2px solid #ffffff;
  height: 32px;
  width: 32px;
  padding: 0px;
  border-radius: 50px;
`;

export const SuccessIndicator = styled(Check)`
  background: ${props => props.theme.colors.green};
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const SuccessIndicatorWrapper = styled.div`
  background: ${props => props.theme.colors.green};
  border-radius: 50%;
  height: 16px;
  width: 16px;

  /* color: ${props => props.theme.colors.white}; */
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

import styled from "styled-components";

import { Headline1 } from "components/common/styles";
import { CardWrapper } from "components/common/customerTestimonials/styles";

export const TestimonialsContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1 0 0;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  gap: 53px;
  padding: 10px;
  background-color: #203352;

  ${CardWrapper} {
    width: 100%;
    padding: 53px 70.5px;

    @media screen and (max-width: ${props => props.theme.stdBreakpoints.lg}) {
      padding: 53px 20px;
    }
  }

  .carousel-root {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const HeadlineContainer = styled.div`
  max-width: 745px;
  padding: 0 70.5px;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.lg}) {
    padding: 0 20px;
  }
`;

export const Headline = styled(Headline1)`
  text-align: center;
  color: ${props => props.theme.colors.white};
`;

import React, { useEffect } from "react";
import * as accountActions from "../../state/actions/AccountActions";
import * as authActions from "../../state/actions/AuthActions";

import {
  SettingsTitle,
  SettingsHeader,
  SettingsSection,
  SettingsDescription,
  SettingsBrick
} from "./styles";

import { updatePhoneFailed } from "state/actions/AuthActions";

import { NativeLink } from "components/common/Link";
import { EnclosedContent } from "components/common/styles";
import { ConnectionSettingsForm } from "./forms";
import { useToaster } from "@hellocontento/maillard";
import {
  SettingsSubText,
  SettingsSubTitle,
  WhatsappConnectedWrapper,
  WhatsappGrid,
  WhatsappSubGrid,
  WhatsappSubText
} from "./styles/notifications";
import WhatsappLogo from "assets/images/whatsapp-image-icon.png";
import { connect, useSelector, useDispatch } from "react-redux";

const Connections: React.FC = ({ currentUser, updatePhone }: any) => {
  const addToast = useToaster();
  const dispatch = useDispatch();
  const phoneError = useSelector((state: any) => state.auth.updatePhoneFailed);

  useEffect(() => {
    if (phoneError) {
      addToast(phoneError, "error");
      dispatch(updatePhoneFailed(null));
    }
  }, [addToast, phoneError, dispatch]);

  const handleWhatsAppUpdate = data => {
    try {
      updatePhone(data);
    } catch (e) {
      addToast("Your account details could not be saved.", "error");
    }
  };

  return (
    <EnclosedContent>
      <SettingsHeader>
        <SettingsTitle>Connections</SettingsTitle>
        <SettingsDescription>
          Here you can connect your Willow account to external services that
          help you create content
          <br />
          Visit our{" "}
          <NativeLink
            $underline={true}
            href="https://support.willow.co/knowledge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Help Center</strong>
          </NativeLink>{" "}
          or talk to us through the chat.
        </SettingsDescription>
      </SettingsHeader>
      <SettingsSection>
        <SettingsBrick>
          <SettingsSubTitle>Connect To Whatsapp</SettingsSubTitle>
          <SettingsSubText>
            With Willow for WhatsApp, you can brainstorm content on the go.
            Share links, ideas, and pictures to Willow and we’ll store them for
            you to work on later.
          </SettingsSubText>
          {currentUser && currentUser.phone && (
            <WhatsappConnectedWrapper>
              <WhatsappGrid>
                <WhatsappSubGrid>
                  <img
                    src={WhatsappLogo}
                    width={40}
                    height={40}
                    alt={"Whatsapp Integration"}
                  />
                  <WhatsappSubText color={"black"}>Connected</WhatsappSubText>
                </WhatsappSubGrid>
                <SettingsSubText className="phone-number">
                  {currentUser.phone}
                </SettingsSubText>
              </WhatsappGrid>
            </WhatsappConnectedWrapper>
          )}
          <ConnectionSettingsForm
            onSubmit={handleWhatsAppUpdate}
            initialValues={{}}
          />
        </SettingsBrick>
      </SettingsSection>
    </EnclosedContent>
  );
};
const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {
  updateAccount: accountActions.updateAccount,
  updatePhone: authActions.updatePhone
})(Connections);

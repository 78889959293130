import { isPast } from "date-fns";
import React, { useRef, useEffect, useState, useMemo } from "react";

import { TopicalEntry, TopicalContent } from "./styles";
import { RightClickPostContextMenu } from "../common/postContextMenu/PostContextMenu";

function MonthTopical({ day, entry, dashboardEntry }) {
  const { calendarEvent, time } = entry;
  const ref = useRef(null);
  const [contextMenuPos, setContextMenuPos] = useState([]);
  const isPastEvent = useMemo(() => isPast(new Date(time)), [time]);

  const closeMenu = () => {
    setContextMenuPos([]);
  };

  // Context menu handler on right click
  useEffect(() => {
    if (!isPastEvent) {
      const element = ref.current;

      const handleContextMenu = e => {
        e.preventDefault();
        setContextMenuPos([e.clientX, e.clientY]);
      };

      element.addEventListener("click", handleContextMenu);
      element.addEventListener("contextmenu", handleContextMenu);

      return () => {
        element.removeEventListener("click", handleContextMenu);
        element.removeEventListener("contextmenu", handleContextMenu);
      };
    }
  }, [isPastEvent]);

  // Remove context menu on scroll
  useEffect(() => {
    if (!isPastEvent) {
      const handleScroll = () => {
        setContextMenuPos([]);
      };

      if (!!contextMenuPos.length) {
        document.addEventListener("scroll", handleScroll);
      }

      return () => {
        document.removeEventListener("contextmenu", handleScroll);
      };
    }
  }, [contextMenuPos, isPastEvent]);

  return (
    <div>
      <TopicalEntry
        ref={ref}
        isPast={isPastEvent}
        isOtherMonth={day.isOutsideMonth}
        isContextMenuOpen={contextMenuPos.length > 0}
      >
        <TopicalContent>
          <i className="icon-calendar-filled" />
          {calendarEvent.name}
        </TopicalContent>
        {!isPastEvent && (
          <RightClickPostContextMenu
            entry={entry}
            closeMenu={closeMenu}
            contextMenuPos={contextMenuPos}
          />
        )}
      </TopicalEntry>
    </div>
  );
}

export default MonthTopical;

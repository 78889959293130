import React, { useMemo } from "react";
import Carousel from "react-grid-carousel";
import { Box, Flex } from "rebass/styled-components";

import {
  IdeaPageTitle,
  IdeaPageHeader,
  IdeaPageDescription
} from "components/ideas/styles";
import { Headline5 } from "components/common/styles";
import Loader from "components/common/loading/Loader";
import useTopicalCalendars from "utils/useTopicalCalendars";
import TopicalCalendarCard from "components/ideas/topicalCalendars/TopicalCalendarCard";

const TopicalCalendars: React.FC<{}> = () => {
  const {
    votedCalendars,
    topicalCalendars,
    handleButtonAction,
    isFetchingCalendars,
    subscribedCalendars,
    isFetchingCalendarSubscriptions
  } = useTopicalCalendars();

  const isLoading = useMemo(
    () => isFetchingCalendars || isFetchingCalendarSubscriptions,
    [isFetchingCalendars, isFetchingCalendarSubscriptions]
  );

  return (
    <>
      <IdeaPageHeader>
        <IdeaPageTitle mb={12}>Topical Calendars</IdeaPageTitle>
        <IdeaPageDescription>
          Subscribe to a calendar and Willow will add events to your content
          calendar as a reminder to create a post. Vote for calendars that
          aren’t yet in the product and we’ll let you know when we release them.
        </IdeaPageDescription>
      </IdeaPageHeader>
      <Box className="carousel-box">
        <Carousel
          cols={isLoading || topicalCalendars.length === 0 ? 1 : 3}
          rows={1}
          gap={0}
          hideArrow={isLoading || topicalCalendars.length === 0}
          mobileBreakpoint={0}
          containerStyle={{ margin: "0 -30px" }}
          responsiveLayout={[
            {
              breakpoint: 990,
              cols: isLoading || topicalCalendars.length === 0 ? 1 : 2,
              rows: isLoading || topicalCalendars.length <= 2 ? 1 : 2,
              gap: 0
            }
          ]}
        >
          {isLoading ? (
            <Carousel.Item>
              <Flex justifyContent={"center"}>
                <Loader />
              </Flex>
            </Carousel.Item>
          ) : topicalCalendars.length === 0 ? (
            <Carousel.Item>
              <Flex justifyContent={"center"}>
                <Headline5>
                  Sorry! No Topical Calendars available right now.
                </Headline5>
              </Flex>
            </Carousel.Item>
          ) : (
            topicalCalendars.map(tc => (
              <Carousel.Item key={tc.id}>
                <TopicalCalendarCard
                  id={tc.id}
                  coverImg={tc.image}
                  title={tc.name}
                  tooltip={tc.description}
                  startDate={tc.startDate}
                  endDate={tc.endDate}
                  status={tc.level}
                  isVoted={votedCalendars.includes(tc.id)}
                  isSubscribed={subscribedCalendars.includes(tc.id)}
                  handleButtonAction={handleButtonAction}
                />
              </Carousel.Item>
            ))
          )}
        </Carousel>
      </Box>
    </>
  );
};

export default TopicalCalendars;

import React from "react";
import {
  ErrorMessage,
  HelperText,
  StyledInputWrapper,
  StyledInput,
  StyledInputLabel,
  StyledInputFieldset,
  StyledInputIconWrapper
} from "./styles";

const PasswordInput = ({
  input,
  label,
  placeholder,
  meta,
  type,
  helperText,
  autofocus = false,
  disabled,
  autocomplete = null,
  icon = null,
  supportLink = null,
  ...props
}) => {
  const { touched, error } = meta;
  const hasError = error && touched;

  const [inputType, setInputType] = React.useState(type);

  const INPUT_TYPE_PASSWORD = "password";
  const INPUT_TYPE_TEXT = "text";

  const toggle = () => {
    if (inputType === INPUT_TYPE_PASSWORD) setInputType(INPUT_TYPE_TEXT);
    else setInputType(INPUT_TYPE_PASSWORD);
  };

  return (
    <StyledInputFieldset width={1} mb={16} {...props}>
      <StyledInputLabel>
        {label}
        {supportLink}
      </StyledInputLabel>
      {helperText && <HelperText>{helperText}</HelperText>}
      <StyledInputWrapper>
        <StyledInput
          type={inputType}
          hasError={hasError}
          placeholder={placeholder}
          autoFocus={autofocus}
          disabled={disabled}
          autoComplete={autocomplete}
          rightPadding={36}
          {...input}
        />
        <StyledInputIconWrapper onClick={toggle}>
          <span
            className={`${
              inputType === INPUT_TYPE_PASSWORD ? "icon-eye-hidden" : "icon-eye"
            }`}
            id="togglePassword"
          ></span>
        </StyledInputIconWrapper>
      </StyledInputWrapper>
      {hasError && <ErrorMessage>{error}</ErrorMessage>}
    </StyledInputFieldset>
  );
};

export default PasswordInput;

import styled from "styled-components";
import { Body6, Headline5 } from "components/common/styles";

export const CardWrapper = styled.div`
  padding: 40px 56px;
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px;
  gap: 16px;
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.shadowS};
  border-radius: 16px;

  .attestant {
    display: grid;
    grid-template-columns: 56px auto;
    grid-template-rows: 20px auto;
    grid-template-areas: "avatar fullname" "avatar designation";
    grid-row-gap: 4px;
    grid-column-gap: 8px;

    img {
      grid-area: avatar;
      display: flex;
      border-radius: 50%;
      flex-shrink: 0;
      width: 56px;
      height: 56px;
      object-fit: cover;
    }

    > ${Headline5} {
      grid-area: fullname;
    }

    .designation {
      grid-area: designation;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .testimonial {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    gap: 8px;

    ${Headline5} {
      font-weight: 700;
    }

    ${Body6} {
      color: ${props => props.theme.colors.text01};
    }
  }
`;

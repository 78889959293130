import PropTypes from "prop-types";
import React, { useState } from "react";
import { ChevronDown } from "styled-icons/boxicons-regular";

import Popper from "components/common/Popper";
import contentTypes from "./data/content-types";
import ContentTypeBadge from "./ContentTypeBadge";
import Menu, { MenuContainer } from "components/navigation/Menu";
import { ContentTypeContainer, ChannelToggleButton } from "./styles";

const ContentTypeSelect = ({
  value,
  offset,
  onChange,
  position = "bottom-start"
}) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  return (
    <div ref={setReferenceElement}>
      <ContentTypeContainer onClick={toggleShowOptions}>
        <ChannelToggleButton
          className="chevron"
          size={28}
          padding={2}
          icon={ChevronDown}
        />
        <ContentTypeBadge
          contentTypeId={value ?? "educational"}
          variant={"dark"}
          clickable
        />
      </ContentTypeContainer>
      <Popper
        offset={offset}
        placement={position}
        referenceElement={referenceElement}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[referenceElement]}
      >
        <MenuContainer minWidth={284}>
          <Menu
            items={Object.values(contentTypes)
              .filter(c => c.isConcept)
              .map(c => ({
                value: c.id,
                label: c.title,
                info: c.id
              }))}
            selected={value ?? "educational"}
            onSelect={newValue => {
              onChange(newValue);
              setShowOptions(false);
            }}
          />
        </MenuContainer>
      </Popper>
    </div>
  );
};

ContentTypeSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ContentTypeSelect;

import styled, { css } from "styled-components";
import { Flex, Box } from "rebass/styled-components";
import { default as CustomButton } from "../common/Button";
import { Label } from "../common/styles";

import arrowPrev from "../../assets/images/calendar-arrow-prev.png";
import arrowNext from "../../assets/images/calendar-arrow-next.png";

export const ScheduleLayout = styled(Flex)``;

export const ScheduleBody = styled.div`
  flex: 1;
  position: relative;
`;

export const ScheduleSidebar = styled.div`
  position: relative;
  z-index: 1;
`;

export const SchedulePanelContainer = styled(Flex)`
  background: white;
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.08);
  flex-direction: column;
  box-sizing: border-box;
  min-height: calc(100vh - 72px);
  margin-bottom: 72px;
  height: 100%;
  transition: 0.5s;
`;

export const SchedulePanelFixer = styled.div`
  /* position: sticky; */
  /* top: 64px; */
`;

export const SchedulePanelHeader = styled(Flex)`
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.grey02};
  justify-content: space-between;
  padding: 14px 24px;
`;

export const SchedulePanelFooter = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: calc(440px - 40px);
  bottom: 0;
  padding: 20px 0;
  background: white;
  border-top: 1px solid ${props => props.theme.colors.grey02};
`;

export const ScheduleIdeaNotice = styled(Box)<{ $color?: string }>`
  background-color: ${props => props.theme.colors.grey01};
  border-radius: 12px;
  box-sizing: border-box;
  margin: 24px 0;
  padding: 16px 20px 16px 16px;

  & h5 {
    color: ${props => props.theme.colors.text01};
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 16px;
    margin: 2px 0 10px;
  }
  & p {
    color: ${props => props.theme.colors.text02};
    margin-bottom: 24px;
  }
  & span {
    color: ${props => props.$color};
    font-size: 14px;
  }
`;

export const ScheduleFieldset = styled(Flex)`
  margin: 8px 0;

  ${props =>
    props.flexDirection !== "column" &&
    css`
      align-items: center;
    `}
`;

export const ScheduleField = styled.div<{ $isFlex?: boolean }>`
  display: ${props => (props.$isFlex ? "grid" : "block")};
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 8px;
  flex: 3;
`;

export const ScheduleInputWrapper = styled(Flex)`
  align-items: center;
  flex: 1;
  justify-content: stretch;

  & .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const ScheduleLabel = styled(Label)`
  flex: 2;

  ${props =>
    props.size === "lg" &&
    css`
      color: ${props.theme.colors.text02};
      margin: 8px 0;
      line-height: 21px;
      text-transform: uppercase;
    `};
`;

export const ScheduleFrequency = styled(Flex)`
  align-items: center;
  justify-content: center;

  & > * {
    flex: 1;
    max-height: 32px;
  }
  & span {
    color: ${props => props.theme.colors.text01};
    flex: 1;
    font-size: 14px;
    margin: 0 8px;
    text-align: center;
  }
`;

export const Header = styled(Flex)`
  background-color: ${props => props.theme.colors.background2};
  padding: 14px 16px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 9;
  top: 0;
  box-shadow: ${props => props.theme.shadows.shadowXS};
`;

export const HeaderDate = styled.h2`
  color: ${props => props.theme.colors.text01};
  display: flex;
  flex: 1;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

export const HeaderControls = styled(Flex)`
  align-items: flex-end;
  flex: 1;
  justify-content: flex-end;
  /* margin-right: 0px !important; */
`;

export const CalendarPeriod = styled(Flex)`
  align-items: center;
  flex: 1;

  &:last-child {
    border-right: none;
    margin-right: none;
  }
`;

export const ButtonToggle = styled.div<{
  $maxOptions: number;
  $minWidth?: number;
}>`
  background-color: ${props => props.theme.colors.grey01};
  border-radius: 32px;
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 2px;
  grid-template-columns: repeat(
    ${props => props.$maxOptions},
    minmax(${props => props.$minWidth || 72}px, max-content)
  );
  padding: 3px;
`;

export const Button = styled(CustomButton).attrs(props => ({
  size: props.size || "sm",
  variant: props.isActive ? "tertiary" : "secondary"
}))`
  border-radius: 24px;
  line-height: 18px;

  ${props =>
    !props.isActive
      ? css`
          background-color: transparent;
          color: ${props.theme.colors.text03};

          &:hover {
            color: ${props.theme.colors.text01};
          }
        `
      : css`
          background-color: white;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
          color: ${props => props.theme.colors.text01};

          &:hover {
            background-color: white;
          }
        `};
`;

export const TodayButton = styled(CustomButton).attrs(() => ({
  size: "sm",
  variant: "secondary"
}))`
  background-color: transparent;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 700;
  height: 32px;
  line-height: 14px;
  line-height: 14px;
  margin: 0 4px;
  padding: 0 16px;
`;

export const PeriodButton = styled.div<{ $direction?: string }>`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 24px;
  background: none no-repeat center center;
  margin: 0 4px;
  background-image: url(${props =>
    props.$direction === "next" ? arrowNext : arrowPrev});
  background-size: 7px 12px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.grey02};
  }
`;

export const TooltipOption = styled.div<{ $variant: string; $size?: number }>`
  padding: 12px 8px;
  font-weight: 600;
  list-style: none;
  cursor: pointer;
  margin: 0px 8px;

  display: flex;
  gap: 10px;

  ${props =>
    ["primary", "danger"].includes(props.$variant) &&
    css`
      color: ${props.theme.variants[props.$variant].fontColor};
    `};

  &:hover {
    color: ${props => props.theme.colors.white};
    border-radius: 10px;
    background-color: ${props => props.theme.colors.blue};
  }
  span {
    font-size: ${props => (props.$size ? props.$size : 16)}px;
  }
`;

export const TooltipMenu = styled.div<{ $radius?: number; $float?: boolean }>`
  background-color: white;
  border-radius: ${props => props.$radius || 8}px;
  box-shadow:
    0px 8px 16px -8px rgba(28, 30, 31, 0.12),
    0px 18px 27px -5px rgba(28, 30, 31, 0.15),
    0px 0px 0px 1px rgba(28, 30, 31, 0.05);
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  z-index: 5;

  ${props =>
    !props.$float &&
    css`
      transform: translate(-24px, 8px);
    `};
`;

export const TooltipMenuWrapper = styled.div<{ $minWidth?: number }>`
  min-width: ${props => props.$minWidth || 240}px;
`;

export const RadioButtonGroup = styled(Flex)`
  flex-direction: column;
  gap: 10px;
  margin-top: 24px;
  font-weight: 600;
`;

export const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 24px;
`;

export const IconBottonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5px;
  margin-right: 10px;
`;

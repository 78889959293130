import * as scheduledPostActions from "../actions/ScheduledPostActions";

const initialState = {
  data: [],
  isFetching: false
};

const scheduledPostReducer = (state = initialState, action) => {
  switch (action.type) {
    case scheduledPostActions.FETCH_SCHEDULED_POSTS:
      return Object.assign({}, state, {
        data: [],
        isFetching: true
      });
    case scheduledPostActions.SCHEDULED_POSTS_FETCHED:
      return Object.assign({}, state, {
        data: action.scheduledPosts,
        isFetching: false
      });
    default:
      return state;
  }
};

export default scheduledPostReducer;

// Based on https://stackoverflow.com/a/52749807

import { Image, ImageProps } from "rebass/styled-components";
import React, { useState, useCallback, useMemo } from "react";

interface IFallbackImageProps extends ImageProps {
  fallbackSrc?: string
}

const FallbackImage = React.memo(
  ({ src, fallbackSrc, ...props }: IFallbackImageProps) => {
    const [errored, setErrored] = useState(false);

    const onError = useCallback(() => {
      //Only mark as errored once
      if (!errored) {
        setErrored(true);
      }
    }, [errored]);

    const imgSrc = useMemo(
      () => (errored || !src ? fallbackSrc : src),
      [src, errored, fallbackSrc]
    );

    return <Image src={imgSrc} onError={onError} {...props} />;
  }
);

export default FallbackImage;

import React from "react";
import { Flex } from "rebass";
import { Check } from "@styled-icons/fa-solid";

import {
  PlanName,
  PlanPrice,
  PlanHeader,
  ColorButton,
  PlanFeature,
  PlanFeatures,
  PlanDescription
} from "./styles";
import { FormBox } from "components/common/form/styles";
import { telenetPlans, telenetPlanMapping } from "constants/index";

const BillingPlan = ({ plan, price, onSelect }) => {
  return (
    <FormBox mx={20} my={20}>
      <PlanHeader>
        <PlanName color={plan.color}>{plan.title}</PlanName>
        <PlanPrice currency={price.currency} period={price.period}>
          {parseInt(price.amount) / 100}
        </PlanPrice>
        <PlanDescription>{plan.subTitle}</PlanDescription>
      </PlanHeader>
      <PlanFeatures>
        {plan.features.map((feature, index) => (
          <PlanFeature key={index}>
            <Check size={12} />
            <span>{feature}</span>
          </PlanFeature>
        ))}
      </PlanFeatures>
      <Flex width="100%" flexDirection="column" mb={16}>
        <ColorButton
          backgroundColor={plan.color}
          variant="primary"
          size="lg"
          onClick={() => onSelect()}
        >
          Choose this plan
        </ColorButton>
      </Flex>
    </FormBox>
  );
};

export default function BillingPlans({ cbPlans, currency, onSelect }) {
  return (
    <Flex width="100%">
      {Object.entries(telenetPlans).map(([planId, plan]) => {
        const internalPlanId = telenetPlanMapping[currency][planId];
        const cbPlan = cbPlans.find(p => p.id === internalPlanId);
        if (!cbPlan) return null;
        const price = {
          currency: cbPlan.currency_code,
          amount: cbPlan.price,
          period: cbPlan.period_unit
        };

        return (
          <BillingPlan
            key={planId}
            plan={plan}
            price={price}
            onSelect={() => onSelect(planId)}
          />
        );
      })}
    </Flex>
  );
}

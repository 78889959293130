import {
  Title,
  Legend,
  Tooltip,
  ChartData,
  BarElement,
  LineElement,
  LinearScale,
  ChartOptions,
  PointElement,
  CategoryScale,
  Chart as ChartJS
} from "chart.js";
import { Chart } from "react-chartjs-2";
import React, { useState, useEffect } from "react";

import {
  WidgetTitle,
  WidgetStatsLabel,
  WidgetHeaderContent,
  AnalyticsWidgetHeader,
  AnalyticsContentWrapper,
  AnalyticsWidgetContainer
} from "../styles";
import { colors } from "theme";
import PostStreakTooltip from "./Tooltip";
import Loader from "components/common/loading/Loader";
import { useDashboardState } from "contextApi/dashboardContext";
import { getChartData, getBarChartOptions } from "utils/charts";
import { today, difference, subtractDuration } from "utils/date";
import { externalTooltipHandler } from "../ExternalTooltipHandler";

ChartJS.register(
  Title,
  Legend,
  Tooltip,
  BarElement,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
);

const tipUrl =
  "https://www.willow.co/course-content/how-to-be-consistent-on-social-media";

interface IChartConfig {
  chartData: ChartData<"bar" | "line">;
  chartOptions: ChartOptions<"bar" | "line">;
}

const PostStreakAnalytics: React.FC<{}> = () => {
  const periodInDays = useDashboardState(
    state => state.filterRange.periodInDays
  );
  const stats = useDashboardState(state => state.socialHealth.data);
  const isFetching = useDashboardState(state => state.socialHealth.isFetching);
  const minDataPoints: null | number =
    typeof periodInDays === "number"
      ? difference(
          today(),
          subtractDuration(today(), {
            days: periodInDays
          }),
          "weeks"
        )
      : null;
  const [{ chartData, chartOptions }, setChartConfig] = useState<IChartConfig>({
    chartData: { labels: [], datasets: [] },
    chartOptions: getBarChartOptions({
      scales: { y: { display: false }, x: { display: false } }
    })
  });

  useEffect(() => {
    if (!isFetching && !!stats?.postStreak?.data) {
      const formattedData = stats.postStreak.data
        .sort((a: any, b: any) => Date.parse(a.date) - Date.parse(b.date))
        .map((point: any) => ({
          ...point,
          total: point.combinedData.score
        }));
      const { data, dates } = getChartData(
        formattedData,
        false,
        formattedData.length >= 1 && !!minDataPoints ? minDataPoints : 10
      );
      const options = getBarChartOptions({
        plugins: {
          tooltip: {
            enabled: false,
            filter: item => item.datasetIndex === 2,
            ...(stats.postStreak.data.length >= 1
              ? { external: externalTooltipHandler(PostStreakTooltip) }
              : {})
          }
        }
      });

      setChartConfig({
        chartData: {
          labels: dates,
          datasets: [
            {
              type: "line",
              data: data.map(_ => 100)
            },
            {
              type: "line",
              borderColor: "transparent",
              data: data.map(_ => 200)
            },
            {
              type: "bar",
              data,
              minBarLength: 10,
              maxBarThickness: 8,
              hoverBorderColor: item => {
                return ((item.raw as any).total as number) >= 100
                  ? "rgba(60, 63, 66, 0.66)"
                  : "rgba(102, 119, 128, 0.66)";
              },
              borderColor: item => {
                return ((item.raw as any).total as number) >= 100
                  ? colors.text04
                  : colors.grey04;
              }
            }
          ]
        },
        chartOptions: options
      });
    }
  }, [isFetching, minDataPoints, stats]);

  const handleTipClick = () => {
    window.open(tipUrl, "_blank");
  };

  const isPostStreakLessThanHalf = stats?.postStreak?.score < 50;

  return (
    <AnalyticsWidgetContainer
      spaced
      colspan={1}
      rowspan={1}
      bgColor={colors.white}
    >
      <AnalyticsWidgetHeader>
        <WidgetTitle>Post streak</WidgetTitle>
        {!isFetching && (
          <WidgetHeaderContent>
            <WidgetStatsLabel>
              {!!stats?.postStreak?.score
                ? `${stats?.postStreak?.score}%`
                : "No data"}
            </WidgetStatsLabel>
            {!!stats?.postStreak?.score && isPostStreakLessThanHalf && (
              <WidgetStatsLabel variant="highlight" onClick={handleTipClick}>
                <i className="icon-sparkle" />
                Tip
              </WidgetStatsLabel>
            )}
          </WidgetHeaderContent>
        )}
      </AnalyticsWidgetHeader>
      <AnalyticsContentWrapper>
        {isFetching ? (
          <Loader size={36} />
        ) : (
          <Chart
            type="bar"
            width="100%"
            height="100%"
            data={chartData}
            options={chartOptions}
          />
        )}
        {!isFetching &&
          (!chartData.datasets[0] || chartData.datasets[0].data.length < 1) && (
            <span className="no-data inverted">Not enough data</span>
          )}
      </AnalyticsContentWrapper>
    </AnalyticsWidgetContainer>
  );
};

export default PostStreakAnalytics;

import ReactLoading from "react-loading";
import Loader from "components/common/loading/Loader";
import React, { useState, useEffect, useRef, useCallback } from "react";

import ContentItem from "./ContentItem";
import { ContentWrapper } from "./styles";
import { LoadingContent } from "./styles";

const GetSpinner = React.memo(() => {
  return (
    <LoadingContent>
      <ReactLoading color="#bbb" type="cylon" />
    </LoadingContent>
  );
});

const ScrollableContentList = React.memo(
  ({ pageData, onSaveChange, onNextPage, emptyState, sourceId }) => {
    const observer = useRef(null);
    const [allItems, setAllItems] = useState(pageData?.items || []);
    const [lastElement, setLastElement] = useState(null);

    useEffect(() => {
      if (pageData?.items) {
        setAllItems(pageData.items);
      }
    }, [pageData]);

    useEffect(() => {
      observer.current = new IntersectionObserver(entries => {
        const first = entries[0];
        if (first.isIntersecting && !pageData.isFinished) {
          onNextPage(pageData.page + 1);
        }
      });

      const currentElement = lastElement;
      const currentObserver = observer.current;

      if (currentElement) {
        currentObserver.observe(currentElement);
      }

      return () => {
        if (currentElement) {
          currentObserver.unobserve(currentElement);
        }
      };
    }, [lastElement, pageData, onNextPage]);

    const handleDimissed = useCallback(
      (contentId, domain = null) => {
        if (domain) {
          setAllItems(allItems.filter(item => item.domain !== domain));
        } else {
          setAllItems(allItems.filter(item => item.id !== contentId));
        }
      },
      [allItems]
    );

    return (
      <div>
        {!pageData ? (
          <Loader location="center" size={64} />
        ) : allItems.length < 1 ? (
          emptyState
        ) : (
          <>
            <ContentWrapper>
              {allItems.map((item, index) => {
                if (allItems.length - 1 === index) {
                  return (
                    <div ref={setLastElement} key={`${item.id}-wrapper`}>
                      <ContentItem
                        key={index}
                        content={item}
                        onDismiss={handleDimissed}
                        onSaveChange={onSaveChange}
                        sourceId={sourceId}
                      />
                    </div>
                  );
                } else {
                  return (
                    <ContentItem
                      key={index}
                      content={item}
                      onDismiss={handleDimissed}
                      onSaveChange={onSaveChange}
                      sourceId={sourceId}
                    />
                  );
                }
              })}
            </ContentWrapper>
            {!pageData.isFinished && <GetSpinner />}
          </>
        )}
      </div>
    );
  }
);

export default ScrollableContentList;

import React from "react";
import Modal from "react-modal";
import { Flex, Box } from "rebass/styled-components";
import { modalStyles } from "../styles";
import { ModalConfirmationHeader } from "./styles";
import Button from "../../common/Button";

const ConfirmationDialog = ({
  isOpen,
  onConfirm,
  onCancel,
  title,
  description,
  cancelLabel,
  confirmLabel
}) => {
  const styles = modalStyles(550);
  styles.overlay.zIndex = 11;

  return (
    <Modal ariaHideApp={false} isOpen={isOpen} style={styles}>
      <ModalConfirmationHeader mx={20} mt={10} py={10}>
        {title}
      </ModalConfirmationHeader>
      <Box mx={20} mt={20}>
        {description}
        <Flex flexDirection="row" justifyContent="flex-end" mt={50} mb={10}>
          <Button variant="secondary" mr={20} onClick={() => onCancel()}>
            {cancelLabel}
          </Button>
          <Button variant="primary" onClick={() => onConfirm()}>
            {confirmLabel}
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ConfirmationDialog;

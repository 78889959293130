import { connect } from "react-redux";
import React, { useState } from "react";

import { Flex } from "rebass/styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { IEnrichedContent } from "@hellocontento/contento-common";

import { follow, unfollow } from "services/news";

import {
  ShelveImage,
  TopicInfoWrapper,
  RecommendedShelveTitle,
  RecommendedShelveButtonGroup
} from "../styles";
import ContentList from "../ContentList";
import Divider from "components/common/Divider";
import SeeMore from "components/common/seeMore";

interface IRecommendationTopicShelveProps {
  sections: any;
  section: any;
  sectionType: string;
  contents: Array<any>;
  refreshContentFeed: () => void;
}

const RecommendationTopicShelve: React.FC<IRecommendationTopicShelveProps> = ({
  sections,
  section,
  sectionType,
  contents,
  refreshContentFeed
}) => {
  const [onHover, setOnHover] = useState<boolean>(false);

  const [localContents, setLocalContents] =
    useState<Array<IEnrichedContent>>(contents);

  const account = useSelector((state: any) => state.account.data);
  const history = useHistory();

  const navigateTo = (url: string) => {
    history.push(`/accounts/${account.id}/content/${url}`);
  };

  const topics =
    sections.find((section: any) => section.type === "topics")?.details ?? [];

  const isFollowed = !!topics.find((source: any) => source.id === section.id);

  const followSection = async (sectionType: string, id: string) => {
    try {
      await follow(sectionType, id);
    } catch (_) {}
  };

  const unfollowSection = async (sectionType: string, id: string) => {
    try {
      await unfollow(sectionType, id);
    } catch (_) {}
  };

  function handleDimissed(contentId: string, domain: string | null = null) {
    if (domain) {
      setLocalContents(localContents.filter(item => item.domain !== domain));
    } else {
      setLocalContents(localContents.filter(item => item.id !== contentId));
    }
  }

  return (
    <div id={section.id}>
      <Flex mb={20} justifyContent="space-between" alignItems="center">
        <TopicInfoWrapper>
          <ShelveImage src={section.image} />
          <RecommendedShelveTitle
            onClick={() =>
              navigateTo(`${sectionType}/${section.id}/${section.language}`)
            }
          >
            {section.title}
          </RecommendedShelveTitle>
        </TopicInfoWrapper>
        <RecommendedShelveButtonGroup display="inline-flex" alignItems="center">
          {!isFollowed ? (
            <SeeMore
              text="Follow"
              minWidth={94}
              iconClass="icon-plus"
              handleClick={() => followSection(sectionType, section.id)}
            />
          ) : (
            <SeeMore
              minWidth={94}
              text={onHover ? "Unfollow" : "Following"}
              variant={onHover ? "dangerLite" : "secondary"}
              iconClass={onHover ? "icon-cancel" : "icon-check"}
              handleClick={() => unfollowSection(sectionType, section.id)}
              onMouseEnter={() => setOnHover(true)}
              onMouseLeave={() => setOnHover(false)}
            />
          )}

          {/* @ts-ignore */}
          <Divider orientation="verticle" mx={8} my={0} />
          <SeeMore
            handleClick={() =>
              navigateTo(`${sectionType}/${section.id}/${section.language}`)
            }
          />
        </RecommendedShelveButtonGroup>
      </Flex>
      {/* @ts-ignore */}
      <ContentList
        items={localContents.slice(0, 6)}
        onScheduled={refreshContentFeed}
        onDismiss={handleDimissed}
        viewOnly={false}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    sections: state.content.sections
  };
};

export default connect(mapStateToProps, {})(RecommendationTopicShelve);

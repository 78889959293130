import styled from "styled-components";
import { Box } from "rebass/styled-components";

import { ModalHeader } from "../styles";
import { ModalTitle } from "components/common/styles";

export const ModalHeaderComposer = styled(ModalHeader)`
  margin-bottom: 0;
  padding: 9.5px 16px 9.5px 24px;
  border-radius: 14px 14px 0px 0px;
  border-bottom: #e5e7e9 1px solid;

  .action-btn {
    margin-right: 12px;

    > i {
      margin-right: 4px;
    }
  }
`;

export const ModalBodyComposer = styled(Box)`
  padding: 0px;
  overflow: hidden;
`;

export const ModalTitleComposer = styled(ModalTitle)`
  margin-bottom: 0;
`;

export const TimeDisplay = styled.span`
  font-weight: 600;
`;

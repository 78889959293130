import React from "react";
import {
  ToolkitTools,
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import ImageSuggestion from "./ImageSuggestion";
import CaptionSuggestion from "./CaptionSuggestion";
import AskWillowAI from "./AskWillowAI/willowAI";
import IconButton from "components/common/IconButton";
import { ToolkitContainer, Toolbar, ToolContent, ToolIcon } from "./styles";
import { MagicWand } from "@styled-icons/boxicons-solid/MagicWand";
import { Image } from "@styled-icons/evaicons-solid/Image";
import styled from "styled-components";
import { theme } from "../../../../theme";
import { useAppState } from "contextApi/appContext";

const StyledMagicWand = styled(MagicWand)`
  color: currentColor;
`;

const WhiteMagicWand = styled(MagicWand)`
  color: ${theme.colors.white};
`;

const StyledImageIcon = styled(Image)`
  color: currentColor;
`;

const WhiteImageIcon = styled(Image)`
  color: ${theme.colors.white};
`;

interface ITool {
  order: number;
  icon: React.ComponentType;
  activeIcon: React.ComponentType;
  component: React.ComponentType;
}

const Toolkit: React.FC<{}> = () => {
  const activeToolkit = useComposerState(state => state.activeToolkit);
  const setActiveToolkit = useComposerActions(
    actions => actions.setActiveToolkit
  );
  const showWillowAIRevamp = useAppState(state => state.showWillowAIRevamp);

  // Define TOOLS object conditionally based on the feature flag
  const TOOLS: { [key in ToolkitTools]?: ITool } = {
    imageSuggestion: {
      order: 1,
      icon: StyledImageIcon,
      activeIcon: WhiteImageIcon,
      component: ImageSuggestion
    },
    captionSuggestion: {
      order: 0,
      icon: StyledMagicWand,
      activeIcon: WhiteMagicWand,
      component: showWillowAIRevamp ? AskWillowAI : CaptionSuggestion
    }
  };

  const ActiveContent: any = TOOLS[activeToolkit]?.component;

  return (
    <ToolkitContainer>
      <Toolbar>
        {(Object.keys(TOOLS) as (keyof typeof TOOLS)[])
          .sort((a, b) => TOOLS[a]!.order - TOOLS[b]!.order)
          .map(key => (
            <ToolIcon key={key} isActive={activeToolkit === key}>
              <IconButton
                //@ts-ignore
                size={"sm"}
                iconSize={20}
                icon={
                  activeToolkit === key
                    ? TOOLS[key]!.activeIcon
                    : TOOLS[key]!.icon
                }
                isActive={activeToolkit === key}
                onClick={() => setActiveToolkit(key)}
                variant={"secondaryLoud"}
              />
            </ToolIcon>
          ))}
      </Toolbar>
      {ActiveContent && (
        <ToolContent>
          <ActiveContent />
        </ToolContent>
      )}
    </ToolkitContainer>
  );
};

export default Toolkit;

import Modal from "react-modal";
import { X } from "styled-icons/feather";
import Carousel from "react-grid-carousel";
import React, { useState, useEffect, useMemo } from "react";
import { Box, Flex } from "rebass/styled-components";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { useSelector, useDispatch } from "react-redux";
import { IAccount } from "@hellocontento/contento-common";

import AccountForm from "./AccountForm";
import { modalStyles } from "../styles";
import Loader from "components/common/loading/Loader";
import IconButton from "components/common/IconButton";
import useTopicalCalendars from "utils/useTopicalCalendars";
import { updateAccount } from "state/actions/AccountActions";
import { ModalBody, ModalTitle, ModalHeader } from "./styles";
import { setShowSetupAccountModal } from "state/actions/AuthActions";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { Body6, Headline6, Headline5 } from "components/common/styles";
import TopicalCalendarMiniCard from "components/ideas/topicalCalendars/TopicalCalendarMiniCard";

export const SetupAccountModal: React.FC<{}> = () => {
  const addToast = useToaster();
  const dispatch = useDispatch();
  const account = useSelector<any, any>(state => state.account.data);
  const {
    votedCalendars,
    topicalCalendars,
    handleButtonAction,
    isFetchingCalendars,
    subscribedCalendars,
    isFetchingCalendarSubscriptions
  } = useTopicalCalendars();
  const { showSetupAccountModal } = useSelector<any, any>(state => state.auth);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const styles = modalStyles(step === 0 ? 550 : 820) as Required<Modal.Styles>;
  styles.content.padding = 0;
  styles.overlay.background = "rgba(28, 30, 31, 0.4)";
  styles.overlay.backdropFilter = "blur(5px)";

  const isLoading = useMemo(
    () => isFetchingCalendars || isFetchingCalendarSubscriptions,
    [isFetchingCalendars, isFetchingCalendarSubscriptions]
  );

  useEffect(() => {
    if (account && !account.setupComplete && showSetupAccountModal) {
      setShowModal(true);
    }
  }, [account, showSetupAccountModal]);

  const closeModal = () => {
    setShowModal(false);
    dispatch(setShowSetupAccountModal(false));
  };

  const handleSubmit = async (values: IAccount) => {
    try {
      await new Promise((res, rej) =>
        dispatch(updateAccount(values, res, rej))
      );

      dispatch(
        trackAnalyticsEvent("Sign Up Complete", {
          // TODO: add source: "organic" | "sales"
        })
      );
      addToast("Account setup complete", "success");

      setStep(step => step + 1);
    } catch (e) {
      addToast("Account details could not be saved.", "error");
    }
  };

  return (
    <>
      {showModal && (
        <Modal
          bodyOpenClassName="overflow-hidden"
          ariaHideApp={false}
          style={styles}
          isOpen={true}
          onRequestClose={closeModal}
        >
          <ModalHeader>
            <ModalTitle as="div">
              <Headline5>Setup Account</Headline5>
            </ModalTitle>
            <IconButton
              // @ts-ignore
              variant={"secondary"}
              size={"sm"}
              onClick={closeModal}
              icon={X}
            />
          </ModalHeader>
          <ModalBody>
            {step === 0 ? (
              <Box overflow="hidden" marginY={20} marginX={40}>
                <AccountForm
                  onSubmit={handleSubmit}
                  initialValues={{ ...account }}
                />
              </Box>
            ) : (
              <Box overflow="visible" marginY={20} marginX={40}>
                <Headline5>Subscribe to a Topical Calendar</Headline5>
                <Body6 mt={10} mb={40}>
                  Start your social media journey off in the easiest way
                  possible by adding a topical calendar to your profile. We’ll
                  show events that you can write posts about - simple!
                </Body6>
                <Carousel
                  cols={isLoading || topicalCalendars.length === 0 ? 1 : 3}
                  rows={isLoading || topicalCalendars.length <= 3 ? 1 : 2}
                  gap={22}
                  hideArrow={isLoading || topicalCalendars.length === 0}
                  containerStyle={{ margin: "0 -34px" }}
                  mobileBreakpoint={0}
                  responsiveLayout={[
                    {
                      breakpoint: 767,
                      cols: isLoading || topicalCalendars.length === 0 ? 1 : 2,
                      rows: isLoading || topicalCalendars.length <= 2 ? 1 : 2,
                      gap: 10
                    }
                  ]}
                >
                  {isLoading ? (
                    <Carousel.Item>
                      <Flex justifyContent={"center"}>
                        <Loader />
                      </Flex>
                    </Carousel.Item>
                  ) : topicalCalendars.length === 0 ? (
                    <Carousel.Item>
                      <Flex justifyContent={"center"}>
                        <Headline6>
                          Sorry! No Topical Calendars available right now.
                        </Headline6>
                      </Flex>
                    </Carousel.Item>
                  ) : (
                    topicalCalendars.map(tc => (
                      <Carousel.Item key={tc}>
                        <TopicalCalendarMiniCard
                          id={tc.id}
                          title={tc.name}
                          startDate={tc.startDate}
                          endDate={tc.endDate}
                          status={tc.level}
                          featured={
                            tc.category.trim().toLowerCase() ===
                            (account.industry ?? "").trim().toLowerCase()
                          }
                          isVoted={votedCalendars.includes(tc.id)}
                          isSubscribed={subscribedCalendars.includes(tc.id)}
                          handleButtonAction={handleButtonAction}
                        />
                      </Carousel.Item>
                    ))
                  )}
                </Carousel>
              </Box>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  );
};

export default SetupAccountModal;

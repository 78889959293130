import React, { useState } from "react";

import WidgetTooltip from "./WidgetTooltip";
import Popper from "components/common/Popper";
import WidgetMetricScore from "./WidgetMetricScore";
import { WidgetMetric, WidgetMetricLabel, WidgetMetricValue } from "./Metrics";

const AnalyticsWidgetMetric = ({ centered, channel, metric, stats }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  return (
    <WidgetMetric
      centered={centered}
      ref={setReferenceElement}
      onMouseEnter={() => setShowInfo(true)}
      onMouseLeave={() => setShowInfo(false)}
    >
      {metric.type !== "score" ? (
        <WidgetMetricValue
          isAvailable={stats[metric.source][metric.name] !== null}
        >
          {stats[metric.source][metric.name] !== null
            ? `${stats[metric.source][metric.name]}${
                metric.type === "percentage" ? "%" : ""
              }`
            : "n/a"}
        </WidgetMetricValue>
      ) : (
        <WidgetMetricScore
          score={
            stats[metric.source][metric.name] !== null
              ? stats[metric.source][metric.name] / 100
              : null
          }
        />
      )}
      <WidgetMetricLabel align={centered ? "center" : "left"}>
        {metric.label}
      </WidgetMetricLabel>
      {metric.details && stats[metric.source][metric.name] ? (
        <Popper
          referenceElement={referenceElement}
          visible={showInfo}
          exceptions={[referenceElement]}
        >
          <WidgetTooltip
            title={metric.details.title}
            description={metric.details.description}
          >
            {metric.details.children && metric.details.children(stats.raw)}
          </WidgetTooltip>
        </Popper>
      ) : ["engagements", "impressions"].includes(metric.name) &&
        `${channel.service}_${channel.serviceType}` === "linkedin_profile" ? (
        <Popper
          referenceElement={referenceElement}
          visible={showInfo}
          exceptions={[referenceElement]}
        >
          <WidgetTooltip
            title={"Unavailable"}
            description={`We cannot obtain data from ${metric.name} for LinkedIn Profiles`}
          />
        </Popper>
      ) : (
        <div />
      )}
    </WidgetMetric>
  );
};

export default AnalyticsWidgetMetric;
